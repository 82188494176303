import { DeviceType } from '@constants/Device';

export enum StepsEnum {
  NoProducts,
  SelectDevice,
  MorePetFeeder,
  AddPets,
  FoundPets,
  PetsNotNamed,
  AssignPets,
  IncompatibleChip,
  RemovePets,
  IndoorMode,
  Success,
  Loader,
}

 export enum AssignPetDevices  {
  CatFlapConnect = DeviceType.CatFlapConnect,
  Cerberus= DeviceType.Cerberus,
  FeederConnect = DeviceType.FeederConnect,
  PetDoorConnect = DeviceType.PetDoorConnect,
  FelaquaConnect = DeviceType.FelaquaConnect,
}
