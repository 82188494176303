import * as yup from 'yup';
import { WeightUnits } from '@constants/WeightUnits';
import { PetSpecie } from '@constants/Pet';
import { useTranslation } from 'react-i18next';

const useValidation = () => {
  const { t } = useTranslation();
  const requiredError = 'this_field_cannot_be_empty';
  const emailError = 'email_error';
  const emailNameLengthError = 'email_name_max_length_error';
  const emailDomainLengthError = 'email_domain_max_length_error';
  const numberError = 'number_error';
  const required = 'required';

  const MAX_EMAIL_NAME_LENGTH = 64;
  const MAX_EMAIL_DOMAIN_LENGTH = 189;

  const commonNameSchema = (label?: string, fieldName?: string) =>
    yup.object({
      [fieldName || 'name']: yup
        .string()
        .trim()
        .required(requiredError)
        .max(50, 'name_max_length_error'),
    });

  const commonNumSchema = (fieldName: string, isRequired?: boolean) =>
    yup.object({
      [fieldName]: yup
        .number()
        .typeError(numberError)
        .required(isRequired ? requiredError : ''),
    });

  const emailSchema = () => {
    return yup.object({
      email: yup
        .string()
        .required(requiredError)
        .email(emailError)
        .min(7, 'email_too_short')
        .test(
          'email_name',
          emailNameLengthError,
          (data: string) => data?.split('@')[0]?.length <= MAX_EMAIL_NAME_LENGTH,
        )
        .test(
          'email_domain',
          emailDomainLengthError,
          (data: string) => data?.split('@')[1]?.length <= MAX_EMAIL_DOMAIN_LENGTH,
        ),
    });
  };

  const simplePasswordSchema = yup.object().shape({
    password: yup
      .string()
      .required(requiredError)
      .min(8, 'password_length_error_short')
      .max(128, 'password_length_error_long'),
  });

  const passwordStepSchema = yup.object().shape({
    password: yup
      .string()
      .required('this_field_cannot_be_empty')
      .matches(/[A-Z]/, 'all_checks_requirement')
      .matches(/[0-9]/, 'all_checks_requirement')
      .matches(/[^A-Za-z0-9]/, 'all_checks_requirement')
      .min(8, 'all_checks_requirement')
      .max(40, 'all_checks_requirement'),
    retypePassword: yup
      .string()
      .required('please_retype_your_password')
      .oneOf([yup.ref('password'), null], 'password_must_match'),
  });

  const countryStepSchema = yup.object({
    country: yup.string().required(required),
  });

  // seems like this code is not used anywhere
  // export const amountStepSchema = yup.object({
  //   amount: yup.number().min(0).max(100),
  // });
  // seems like this code is not used anywhere
  // export const otherInfoSchema = yup.object({
  //   date_of_birth: yup.string().nullable().required(required),
  //   breed_id: yup.number().required(required),
  //   conditions: yup.array().min(1).required(required),
  //   food_type_id: yup.string().required(required),
  //   comments: yup.string().max(200, i18n.t('comments_max_length_error')),
  // });

  const commentsScheme = yup.object({
    comments: yup.string().test('max', 'comments_max_length_error', item => item.length < 200),
  });

  const sendInviteSchema = yup.object({
    email: yup.string().email(emailError).required(requiredError),
    permissionLevel: yup.number().required(requiredError),
  });

  const getWeightScheme = (
    inputType: 'single' | 'both' | 'pet',
    animalType: PetSpecie,
    weightUnit: WeightUnits,
  ) => {
    const { min, max, unit } = getMinMaxValues(inputType, animalType, weightUnit);
    return yup.object({
      weight: yup
        .string()
        .required(requiredError)
        .test('isNumber', 'must_be_number', item => {
          if (item === undefined) return true;
          return /^-?\d*\.?\d+$/.test(item);
        })
        .test(
          'max',
          t('max_value_weight_error {{value}} {{unit}}', {
            value: max,
            unit,
          }),
          item => transformValue(item) <= max,
        )
        .test(
          'min',
          t('min_value_weight_error {{value}} {{unit}}', {
            value: min,
            unit,
          }),
          item => transformValue(item) > 0 && transformValue(item) >= min,
        )
        .test('empty', 'please_enter_a_weight', item => {
          return (item || '').toString().length !== 0;
        })
        .test('maxDecimalPlaces', 'error_2_decimal_places', item => {
          const nums = item?.split(' ')?.[0] || '';
          const [digits, decimals] = nums.split('.');
          if (digits?.length > 4 || decimals?.length > 2) return false;
          return true;
        }),
    });
  };

  function transformValue(value: string) {
    if (!value) {
      return 0;
    }
    const [data, unit] = value.split(' ');
    const result = +data;
    switch (unit) {
      case t('UNIT_WEIGHT_LBS'):
        return result;
      case t('UNIT_WEIGHT_KG'):
        return result;
      case t('UNIT_WEIGHT_GR'):
        return result / 1000;
      case t('UNIT_WEIGHT_OZ'):
        return result / 16;
      default:
        return result;
    }
  }

  function getMinMaxValues(
    inputType: 'single' | 'both' | 'pet',
    animalType: PetSpecie,
    weightUnit: WeightUnits,
  ): { min: number; max: number; unit: string } {
    const isMetric = weightUnit === WeightUnits.Metric;

    const getMin = () => {
      switch (inputType) {
        case 'pet': {
          return isMetric ? 0.05 : 0.1;
        }
        case 'both':
        case 'single':
        default: {
          return isMetric ? 10 : 0.35;
        }
      }
    };
    const getMax = () => {
      switch (inputType) {
        case 'pet': {
          if (animalType === PetSpecie.cat) {
            return isMetric ? 25 : 55;
          }
          return isMetric ? 200 : 440;
        }
        case 'both': {
          return isMetric ? 100 : 3.53;
        }
        case 'single':
        default: {
          return isMetric ? 250 : 8.82;
        }
      }
    };

    const getUnit = () => {
      if (inputType !== 'pet') {
        return t(isMetric ? 'UNIT_WEIGHT_GR' : 'UNIT_WEIGHT_OZ');
      }
      return t(isMetric ? 'UNIT_WEIGHT_KG' : 'UNIT_WEIGHT_LBS');
    };

    return {
      min: getMin(),
      max: getMax(),
      unit: getUnit(),
    };
  }

  return {
    getWeightScheme,
    commonNameSchema,
    commonNumSchema,
    emailSchema,
    simplePasswordSchema,
    passwordStepSchema,
    sendInviteSchema,
    commentsScheme,
    countryStepSchema,
  };
};

export default useValidation;
