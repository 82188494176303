import SelectButton from 'src/components/SpSelectButton';
import { CloseDelays } from '@constants/Device';
import i18n from '@utils/i18n';
import React from 'react';
import { CloseDelayOptions } from '../../constants/LidDelay';

interface DelayOptionsProps {
  active: CloseDelays;
  onChange: (data: CloseDelays) => void;
}

export const DelayOptions = ({ active, onChange }: DelayOptionsProps) => {
  return (
    <>
      {CloseDelayOptions.map(id => {
        if ((id as number) === 40) id = 20;
        const name = i18n.t(`${CloseDelays[id].toLowerCase()}`);
        return (
          <SelectButton
            key={id}
            isActive={active === id}
            option={{
              id,
              name,
            }}
            withIcon
            setActive={onChange}
          />
        );
      })}
    </>
  );
};
