import { SpView } from 'src/components/SpView';
import { DeviceModel } from '@models/Device';
import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { SpText } from '../../../../../components/SpText/SpText';
import { SpDeviceOffline } from 'src/components/SpDeviceOffline';
import { SpDeviceConnection } from 'src/components/SpDeviceConnection';
import { SpProductCardImage } from 'src/components/SpProductCardImage';
import SpDeviceBattery from 'src/components/SpDeviceBattery';

const PRODUCT_IMAGE_SIZE = Math.min(Dimensions.get('screen').height * 0.1, 96);
interface DeviceStatusHeaderProps {
  data: DeviceModel;
}

export const DeviceStatusHeader = ({ data }: DeviceStatusHeaderProps) => {
  const online = data?.status.online;
  return (
    <SpView
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      padding={18}>
      <SpView style={styles.wrapper}>
        <SpView style={[styles.wifi]}>
          {online && <SpDeviceConnection data={data?.status?.signal?.device_rssi} />}
          {!online && <SpDeviceOffline useImage />}
        </SpView>
        <SpProductCardImage
          width={PRODUCT_IMAGE_SIZE}
          height={PRODUCT_IMAGE_SIZE}
          data={data.product_id}
        />
        <SpView style={[styles.battery]}>
          <SpView style={{ opacity: data?.status.battery && online ? 1 : 0 }}>
            <SpDeviceBattery
              type={data.product_id}
              battery={data?.status.battery || 5}
            />
          </SpView>
        </SpView>
      </SpView>
      <SpView style={styles.name}>
        <SpText
          size="xxl"
          fontFamily="Rubik_SemiBold"
          align="center">
          {data.name || data.serial_number || data.mac_address}
        </SpText>
        <View style={styles.offlineContainer}>
          {!online && <SpDeviceOffline useImage={false} />}
        </View>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  offlineContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  wifi: {
    marginRight: 16,
  },
  battery: {
    marginLeft: 16,
  },
  name: {
    marginTop: 8,
    width: '100%',
  },
});
