import React, { useMemo } from 'react';
import { ConsumptionInsightOutcome } from '@models/ConsumptionInsight';
import InfoRedSVG from '../../../../../components/SvgIcons/InfoRedSVG';
import InfoYellowSVG from '../../../../../components/SvgIcons/InfoYellowSVG';
import CheckGreenSVG from '../../../../../components/SvgIcons/CheckGreenSVG';
import { StyleSheet } from 'react-native';
import { SpView } from '../../../../../components/SpView';

interface Props {
  isAvailable: boolean;
  isAlert: boolean;
  outcome: ConsumptionInsightOutcome;
}

export const useConsumptionPopupHandle = ({ isAvailable, isAlert, outcome }: Props) => {
  return useMemo(() => {
    if (!isAvailable) {
      return {};
    }

    const iconProps = {
      width: 56,
      height: 56,
      viewBox: '0 0 32 32',
    };

    const ICONS = {
      [ConsumptionInsightOutcome.Ok]: <CheckGreenSVG {...iconProps} />,
      [ConsumptionInsightOutcome.Decreased]: <InfoYellowSVG {...iconProps} />,
      [ConsumptionInsightOutcome.WasDecreased]: <CheckGreenSVG {...iconProps} />,
      [ConsumptionInsightOutcome.Elevated]: <InfoYellowSVG {...iconProps} />,
      [ConsumptionInsightOutcome.WasElevated]: <CheckGreenSVG {...iconProps} />,
      [ConsumptionInsightOutcome.WasFluctuating]: <CheckGreenSVG {...iconProps} />,
    };

    return {
      handleComponent: () => (
        <SpView style={styles.wrapper}>
          <SpView style={styles.iconWrapper}>
            {isAlert ? <InfoRedSVG {...iconProps} /> : ICONS[outcome]}
          </SpView>
        </SpView>
      ),
    };
  }, [isAvailable, isAlert, outcome]);
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  iconWrapper: {
    position: 'relative',
    top: -25,
  },
});
