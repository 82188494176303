import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const CircleChevronBottomSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    {...props}
  >
    <Path
      d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0Z"
      fill="#263A43"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0281 13.2403V10.0283C18.0281 8.90819 17.1199 8 15.9998 8C14.8796 8 13.9725 8.90819 13.9725 10.0283V13.2403H9.97623C8.21617 13.2403 7.33767 15.371 8.58579 16.6119L14.6093 22.5966C15.3783 23.3614 16.6213 23.3614 17.3902 22.5966L23.4138 16.6119C24.6629 15.371 23.7834 13.2403 22.0233 13.2403H18.0281Z"
      fill="white"
    />
  </Svg>
);
export default CircleChevronBottomSVG;
