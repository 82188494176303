import { SpView } from 'src/components/SpView';
import colors from '@styles/colors';
import * as d3 from 'd3-shape';
import React, { useCallback } from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory-native';

const windowWidth = Dimensions.get('window').width;
const startLineX = 10;

export type LineGraphPointModel = {
  x: number;
  y: number;
};

interface LineGraphProps {
  lastData: LineGraphPointModel[];
  thisData: LineGraphPointModel[];
  yAxisData: string[] | number[];
  labels: string[];
  lastLabels: string[];
  thisLabels: string[];
  padding: number;
  height?: number;
}

export const LineGraph = ({
  lastData,
  thisData,
  yAxisData,
  lastLabels,
  thisLabels,
  labels,
  padding = 0,
  height = 100,
}: LineGraphProps) => {
  const countAverage = useCallback(arr => {
    const res = arr.reduce((acc, item) => {
      acc.push(item.y);
      return acc;
    }, []);
    const sum = res.reduce((a, b) => a + b, 0);
    const avg = sum / arr.length || 0;

    return Math.floor(avg);
  }, []);

  const lineLength = useCallback(() => {
    const lastVisits = countAverage(lastData);
    const thisVisits = countAverage(thisData);
    const max = Math.max(lastVisits, thisVisits);
    const step = 100 / max;
    return [lastVisits * step, thisVisits * step];
  }, [lastData, thisData]);

  const converter = useCallback(
    arr => {
      const length = Math.max(lastData.length, thisData.length);
      const xStep = (windowWidth - padding - startLineX * 2) / (length - 1);
      const arrCopy = [...lastData, ...thisData];
      const sorted = arrCopy.sort((a, b) => {
        return b.y - a.y;
      })[0];
      const offset = height - 20;

      let yStep = offset / sorted.y;
      yStep = yStep === Infinity ? 0 : yStep;
      yStep /= 2;

      return arr.map(item => {
        return [(item.x - 1) * xStep, offset - (item.y - 1) * yStep];
      });
    },
    [lastData, thisData, height, windowWidth],
  );

  return (
    <SpView width={windowWidth - padding}>
      <VictoryChart
        height={height}
        width={windowWidth - padding}
        padding={0}
        style={{
          background: {
            fill: '#dedede',
          },
        }}
        minDomain={{ y: 0 }}
        maxDomain={{ y: 300 }}>
        <VictoryAxis
          tickValues={[1, 2, 3, 4, 5, 6, 7]}
          tickFormat={t => ''}
          axisComponent={<View />}
        />

        <VictoryLine
          dataComponent={
            <Svg
              x={0}
              width={windowWidth - padding - startLineX}
              height="100%">
              <Path
                d={d3.line().curve(d3.curveCardinal.tension(0.6))(converter(lastData))}
                x={10}
                y={0}
                stroke="darkgrey"
                strokeWidth={3}
                fill="none"
              />
            </Svg>
          }
        />

        <VictoryLine
          dataComponent={
            <Svg
              x={0}
              width={windowWidth - padding - startLineX}
              height="100%">
              <Path
                d={d3.line().curve(d3.curveCardinal.tension(0.6))(converter(thisData))}
                x={10}
                y={0}
                stroke={colors.placeholderGrey.color}
                strokeWidth={3}
                fill="none"
              />
            </Svg>
          }
        />
      </VictoryChart>
      {labels.length ? (
        <View style={styles.labels}>
          {labels.map((item, index) => {
            return (
              <MyCustomVictoryLabel
                key={index}
                text={item}
                index={index}
              />
            );
          })}
        </View>
      ) : null}
      {yAxisData.length ? (
        <View style={styles.yAxisWrapper}>
          {yAxisData.map((i, index) => {
            return (
              <Text
                key={i + String(index)}
                style={styles.yAxisText}>
                {i}
              </Text>
            );
          }, [])}
        </View>
      ) : null}

      <View style={styles.infoWrapper}>
        {lastLabels.length ? (
          <>
            <Text style={styles.grey}>{lastLabels[0]}</Text>
            <Text style={styles.grey}>{lastLabels[1]}</Text>
            {lineLength()[0] ? (
              <View style={[styles.lineWrapper, { width: `${lineLength()[0]}%` }]}>
                <View style={[styles.line, { backgroundColor: 'darkgrey' }]} />
                <Text style={[styles.lineText, styles.grey]}>
                  {countAverage(lastData)} visits/day
                </Text>
              </View>
            ) : (
              <View style={[styles.lineWrapper, { width: '100%' }]}>
                <Text style={[styles.lineText, styles.grey]}>
                  {countAverage(lastData)} visits/day
                </Text>
              </View>
            )}
          </>
        ) : null}

        {thisLabels.length ? (
          <>
            <Text style={[styles.grey, { marginTop: 15 }]}>{thisLabels[0]}</Text>
            <Text style={styles.grey}>{thisLabels[1]}</Text>
            {lineLength()[1] ? (
              <View style={[styles.lineWrapper, { width: `${lineLength()[1]}%` }]}>
                <View style={[styles.line, { backgroundColor: colors.placeholderGrey.color }]} />
                <Text style={[styles.lineText, styles.grey]}>
                  {countAverage(thisData)} visits/day
                </Text>
              </View>
            ) : (
              <View style={[styles.lineWrapper, { width: `100%` }]}>
                <Text style={[styles.lineText, styles.grey]}>
                  {countAverage(thisData)} visits/day
                </Text>
              </View>
            )}
          </>
        ) : null}
      </View>
    </SpView>
  );
};

const MyCustomVictoryLabel = ({ text, index }: { text: string; index: number }) => {
  return (
    <SpView style={styles.victoryLabel}>
      <SpView
        style={[
          styles.circle,
          {
            backgroundColor: index === 0 ? 'darkgrey' : colors.placeholderGrey.color,
          },
        ]}
      />
      <Text style={styles.victoryLabelText}>{text}</Text>
    </SpView>
  );
};

const styles = StyleSheet.create({
  grey: {
    color: colors.placeholderGrey.color,
  },
  yAxisWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: startLineX,
    marginTop: 10,
  },
  yAxisText: { fontSize: 14, color: colors.placeholderGrey.color },
  infoWrapper: { marginTop: 15 },
  lineWrapper: { flexDirection: 'row', marginTop: 10, width: '100%' },
  line: {
    flex: 1,
    borderRadius: 20,
    marginRight: 20,
  },
  lineText: {
    fontWeight: 'bold',
  },
  labels: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: startLineX,
    paddingVertical: 10,
    backgroundColor: '#dedede',
  },
  victoryLabel: {
    width: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  victoryLabelText: {
    paddingLeft: 6,
    fontSize: 14,
    color: colors.placeholderGrey.color,
  },
  circle: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: 'red',
  },
});
