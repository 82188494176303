import { SpView } from 'src/components/SpView';
import { FeederFoodType } from '@constants/Device';
import {
  DeviceFeederBowlModel,
  DeviceFeederBowlSettingModel,
  DeviceFeederControlModel,
} from '@models/Device';
import { SpEditMenuStack } from 'src/components/SpEditMenuStack';
import React, { useCallback, useMemo } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { cloneDeep } from 'lodash-es';
import { useDeviceById } from '@hooks/useDeviceById';
import useBoundStore from 'src/store/store';
import useToastEffect from '@hooks/useToastEffect';
import { DeviceStackParamList } from '../..';
import { getActions } from '../../constants/FeederBowlSetup';

interface BowlSetupProps {
  data: DeviceFeederBowlModel;
  confirmChangeModal: (callback: () => void) => void;
  id: number;
}

export const FeederBowlSetup = ({ data, confirmChangeModal, id }: BowlSetupProps) => {
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();

  const device = useDeviceById(id);

  const { updateFoodTypeAsync, changeFoodTypeResult, updateProperty } = useBoundStore(
    s => s.deviceStore,
  );

  const changeFoodTypeAction = useCallback(
    ($event: FeederFoodType, direction: 'left' | 'right' | 'double') => {
      let bowls: DeviceFeederBowlModel = cloneDeep(
        (device.control as DeviceFeederControlModel).bowls,
      );
      if (!bowls || !bowls.settings || bowls.settings.length === 0) {
        bowls = {};
        bowls.settings = [
          { food_type: null } as DeviceFeederBowlSettingModel,
          { food_type: null } as DeviceFeederBowlSettingModel,
        ];
      }
      if (direction === 'left') {
        bowls.settings[0].food_type = $event;
      } else if (direction === 'right') {
        bowls.settings[1].food_type = $event;
      } else {
        bowls.settings.at(-1).food_type = $event;
      }
      confirmChangeModal(() => updateFoodTypeAsync(device.id, bowls));
    },
    [device, confirmChangeModal, updateFoodTypeAsync],
  );

  const actions = useMemo(() => {
    return getActions(
      data,
      ($event, direction) =>
        navigation.navigate('DeviceBowlFoodSelect', {
          changeFoodTypeAction,
          $event,
          direction,
          bowlTypeValue: data?.type,
          id,
        }),
      () =>
        navigation.navigate('DeviceBowlTypeSelect', {
          confirmChangeModal,
          bowlTypeValue: data?.type,
          id,
        }),
    );
  }, [data, device]);

  useToastEffect({
    resetter: () => updateProperty({ changeFoodTypeResult: 'none' }),
    successCondition: changeFoodTypeResult === 'success',
    errorCondition: changeFoodTypeResult === 'error',
    successToastMessage: 'success_food_type_id',
    errorToastMessage: 'error_food_type_id',
    translate: true,
  });

  return (
    <SpView>
      <SpEditMenuStack buttons={actions} />
    </SpView>
  );
};
