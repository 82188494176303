import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { SpView } from 'src/components/SpView';
import SpTitle from 'src/components/SpTitle';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveAccountCases } from '../constants/RemoveAccountCases';
import { useRemoveCase } from '../hooks/useRemoveCase';
import { RemoveAccountParamList } from '../index';
import { Layout } from './Layout';
import { WhatWillRemoved } from './WhatWillRemoved';

export const PreviewStep = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RemoveAccountParamList>>();
  const currentCase = useRemoveCase();
  const nextPage = useCallback(() => {
    if (currentCase === RemoveAccountCases.Viewer) {
      navigation.navigate('ConfirmStep');
      return;
    }

    navigation.navigate(
      currentCase === RemoveAccountCases.Owner ? 'OwnerStep' : 'OwnerMultipleStep',
    );
  }, [currentCase]);

  return (
    <Layout
      withBackButton
      onNext={nextPage}
      nextButtonText={t('continue')}>
      <>
        <SpView alignItems="center">
          <SpTitle
            align="center"
            text={t('ad_explanation_delete_account_title')}
          />
        </SpView>
        <SpView marginBottom={24}>
          <WhatWillRemoved short />
        </SpView>

        <SpRoundedHeaderButton
          title={t('more_information')}
          backgroundColor={colors.darkGrey.color}
          onPress={() => navigation.navigate('RemoveAccountInformationDialog')}
        />
      </>
    </Layout>
  );
};
