import DeviceApi from '@api/DeviceApi';
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';
import { useDoorLockedStatus } from '@hooks/useDoorLockedStatus';
import { useEnabledCurfews } from '@hooks/useEnabledCurfews';
import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { DeviceCatFlapControlModel, DeviceModel, DevicePetDoorControlModel } from '@models/Device';
import colors from '@styles/colors';
import { getCurfewStatusText } from '@utils/curfew';
import noop from '@utils/noop';
import IconButton from 'src/components/IconButton';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef } from 'react';
import CurfewEnabledSVG from 'src/components/SvgIcons/CurfewEnabledSVG';
import LockSVG from 'src/components/SvgIcons/LockSVG';
import UnlockSVG from 'src/components/SvgIcons/UnlockSVG';
import useBoundStore from 'src/store/store';

import LoadingSpinner from '../../../../../../components/Loader/Loader';
import { LockOptionInfo } from '../../constants/LockOption';
import useCurfewActive from '../../hooks/useCurfewActive';

import { isEqual } from 'lodash-es';

type Props = {
  data: DeviceCatFlapControlModel | DevicePetDoorControlModel;
  deviceId: number;
  device: DeviceModel;
};

const LockInfo: React.FC<Props> = ({ data, deviceId, device }) => {
  const locked = useDoorLockedStatus(data);
  const { loadingControl, updateDevice, setUpdateCurfewLoading } = useBoundStore(
    state => state.deviceStore,
  );
  const { activeHousehold } = useBoundStore(s => s.householdStore);
  const enabledCurfews = useEnabledCurfews(data); // what curfew are enabled currently ?
  const { t } = useTranslation();
  const { isCurfewActive, lockUnlockTime } = useCurfewActive(enabledCurfews);
  const loaderBeforeFetch = useRef(false);

  const lockTitle = useMemo(() => {
    return locked ? t('locked') : t('unlocked');
  }, [locked, t]);

  useEffect(() => {
    if (loadingControl.curfew?.length) {
      loaderBeforeFetch.current = true;
      setUpdateCurfewLoading(true);
    } else if (!loadingControl.curfew?.length && !loaderBeforeFetch) {
      setUpdateCurfewLoading(false);
    }
  }, [loadingControl?.curfew?.length]);

  useEffect(() => {
    if (!loadingControl.curfew?.length && !loaderBeforeFetch.current) {
      setUpdateCurfewLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetching = async () => {
      const allDevices = await DeviceApi.getDevices(activeHousehold?.id);
      const currentFlap = allDevices.filter(e => e.id === deviceId)[0];
      if (!isEqual(currentFlap, device)) {
        const newDevice = {
          ...device,
          control: { ...device.control, curfew: currentFlap.control?.curfew },
        };
        await updateDevice(newDevice);
        loaderBeforeFetch.current = false;
        setUpdateCurfewLoading(false);
      }
    };
    if (!loadingControl.curfew?.length && loaderBeforeFetch.current) {
      fetching();
    }
  }, [loaderBeforeFetch.current, loadingControl.curfew?.length]);

  const lockDescription = useMemo(() => {
    return LockOptionInfo[data.locking]?.label;
  }, [data?.locking, locked]);
  const userTimeUnits = useUserTimeUnits();
  const { timeToFormat } = useTimeTransform();

  const convertTimeToUnits = (time: string) => {
    return timeToFormat(time, userTimeUnits);
  };

  const { updateCurfewLoading } = useBoundStore(s => s.deviceStore);
  let isLongLang = false;

  if (useCurrentLanguageCode() === 'pt-br' || useCurrentLanguageCode() === 'fr') isLongLang = true;

  const lockUnlockTimeView = useMemo(() => {
    if (!lockUnlockTime) return null;
    const splitedData = lockUnlockTime?.split(' ');
    const [lockTime, unlockTime] = [
      convertTimeToUnits(splitedData[0]),
      convertTimeToUnits(splitedData[1]),
    ];
    return (
      <SpView
        style={[
          styles.timeContainer,
          styles.mainContainer,
          { flexDirection: isLongLang ? 'column' : 'row' },
        ]}>
        <SpView style={styles.timeContainer}>
          <LockSVG
            color={colors.primary.color}
            width={16}
            height={16}
            viewBox="0 0 28 28"
          />
          <SpText style={styles.textLockUnlock}>{lockTime}</SpText>
        </SpView>
        <SpView style={styles.timeContainer}>
          <UnlockSVG
            color={colors.primary.color}
            width={16}
            height={16}
            viewBox="0 0 28 28"
          />
          <SpText style={styles.textLockUnlock}>{unlockTime}</SpText>
        </SpView>
      </SpView>
    );
  }, [loadingControl.curfew, lockUnlockTime]);
  const icon = useMemo(() => {
    const props = {
      color: colors.primary.color,
      width: 28,
      height: 28,
      viewBox: '0 0 28 28',
    };
    return locked ? <LockSVG {...props} /> : <UnlockSVG {...props} />;
  }, [locked]);
  return (
    <SpView style={styles.curfews}>
      <SpView style={styles.curfewItem}>
        <IconButton
          width={64}
          height={64}
          action={noop}
          rounded
          svgIcon={icon}
          componentName="faLock"
          iconColor={colors.primary.color}
          backgroundColor={colors.lightGrey.color}
        />
        <SpText
          size="md"
          style={styles.title}
          align="center">
          {lockTitle}
        </SpText>
        {lockDescription && (
          <SpText
            size="sm"
            style={styles.description}
            align="center">
            {lockDescription}
          </SpText>
        )}
      </SpView>
      {(!!enabledCurfews.length || loadingControl.curfew || updateCurfewLoading) && (
        <SpView style={styles.curfewItem}>
          {loadingControl.curfew || loaderBeforeFetch.current ? (
            <SpView style={styles.loader}>
              <LoadingSpinner />
            </SpView>
          ) : (
            <SpView style={styles.curfewItem}>
              <SpView style={styles.curfewButton}>
                <CurfewEnabledSVG
                  color={colors.greyText.color}
                  width={30}
                  height={30}
                  viewBox="0 0 30 30"
                />
              </SpView>
              <SpText
                size="md"
                style={styles.title}
                numberOfLines={1}>
                {isCurfewActive
                  ? t(getCurfewStatusText(!!enabledCurfews.length))
                  : t('curfew_next')}
              </SpText>
              {lockUnlockTimeView}
            </SpView>
          )}
        </SpView>
      )}
    </SpView>
  );
};

export default LockInfo;

const styles = StyleSheet.create({
  loader: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: 8,
    textAlign: 'center',
    minWidth: 100,
  },
  description: {
    marginTop: 4,
    color: 'rgba(0, 0, 0, 0.59)',
  },
  timeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 3,
    justifyContent: 'center',
  },
  mainContainer: {
    gap: 10,
    maxWidth: 150,
    alignItems: 'center',
  },
  curfews: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 10,
  },
  textLockUnlock: {
    fontSize: 14,
    lineHeight: 20,
    color: 'rgba(0, 0, 0, 0.59)',
  },
  curfewItem: {
    alignItems: 'center',
    width: '50%',
  },
  curfewButton: {
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    backgroundColor: colors.lightGrey.color,
  },
});
