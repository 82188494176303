import { FeederFoodType } from '@constants/Device';
import { Selector } from '@constants/Navigation';
import { lastScreenOptions } from '@constants/Screen';
import { DeviceFeederBowlModel, DeviceFeederBowlSettingModel } from '@models/Device';
import CustomHeader from 'src/components/CustomHeader';
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from 'src/services/ModalBox';
import { DeviceEventEmitter } from 'react-native';
import { SpView } from 'src/components/SpView';
import styles from '@styles/atoms';
import { RootStackParamList } from '../../../index';
import { DeviceAssignPet } from './DeviceSetting/components/DeviceAssignPet';
import { DeviceBowlSetup } from './DeviceSetting/components/DeviceBowlSetup';
import { DeviceSubstanceSetup } from './DeviceSetting/components/DeviceSubstanceSetup';
import { DeviceDetails } from './DeviceDetails';
import { TransitionPresets } from '@react-navigation/stack';

import { DeviceLidDelay } from './DeviceSetting/components/DeviceLidDelay';
import { DeviceName } from './DeviceSetting/components/DeviceName';
import { DevicePortioning } from './DeviceSetting/components/DevicePortioning';
import { DeviceModalTaringNeeded } from './DeviceSetting/components/DeviceModalTaringNeeded';
import DeviceSetPortioning from './DeviceSetting/components/DeviceSetPortioning';
import { DeviceSetting } from './DeviceSetting/DeviceSetting';
import DeviceWiFiSetup from './DeviceSetting/components/DeviceWiFiSetup';
import { SelectSSID } from '../../../Flows/DogBowl/SelectSSID';
import { DeviceFoodSetup } from './DeviceSetting/components/DeviceFoodSetup';
import DeviceBowlFoodSelect from './components/DeviceBowlSetup/DeviceBowlFoodSelect';
import DeviceBowlTypeSelect from './components/DeviceBowlSetup/DeviceBowlTypeSelect';
import { DeviceFirmware } from './components/DeviceFirmware';
import { navigationRef } from '../../../RootNavigation';
import { MultiplePet } from './DeviceSetting/components/MultiplePet';

export type DeviceStackParamList = {
  DeviceDetails: Selector;
  DeviceModalTaringNeeded: Selector;
  DeviceSetting: Selector;
  DeviceAssignPet: Selector;
  DeviceName: Selector;
  DeviceBowlSetup: Selector;
  DeviceWiFiSetup: Selector;
  DeviceFoodSetup: Selector;
  DeviceSubstanceSetup: Selector;
  MultiplePet: undefined;

  DeviceBowlTypeSelect: {
    confirmChangeModal: (callback: () => void) => void;
    bowlTypeValue: number;
    id: number;
  };
  DeviceBowlFoodSelect: {
    changeFoodTypeAction: (data: FeederFoodType, direction: 'left' | 'right' | 'double') => void;
    $event: DeviceFeederBowlSettingModel;
    direction: 'left' | 'right' | 'double';
    bowlTypeValue: number;
    id: number;
  };
  DevicePortioning: Selector;
  DeviceSetPortioning: {
    bowlData: DeviceFeederBowlModel | any;
    isLeft: boolean;
    onChangeTarget: ($event: number[]) => Promise<void>;
  };
  DeviceLidDelay: Selector;
  selectSSID: {
    label: string;
    inputName: string;
    inputValue: any;
  };
};

const Stack = createStackNavigator<DeviceStackParamList>();

export default function DeviceNavigation({
  route,
}: StackScreenProps<RootStackParamList, 'DeviceNavigation'>) {
  const { t } = useTranslation();
  const [activeModalButtons, setActiveModalButtons] = React.useState({
    infoModal: false,
  });
  const currentDeviceId = useMemo(() => {
    // @ts-ignore
    return route.params.params.id || null;
  }, [route.params]);

  return (
    <SpView style={styles.screenWrapper}>
      <Stack.Navigator>
        <Stack.Screen
          name="DeviceDetails"
          component={DeviceDetails}
          options={{
            // ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }),

            header: () =>
              CustomHeader({
                title: ' ',
                headerRightElement: <DeviceFirmware id={currentDeviceId} />,
                goBackFunc: () =>
                  navigationRef.navigate('DashboardNavigation', {
                    screen: 'Products',
                  }),
              }),
          }}
        />
        <Stack.Screen
          name="DeviceSetting"
          component={DeviceSetting}
          options={{
            header: () =>
              CustomHeader({
                title: t('settings'),

                goBackFunc: () =>
                  navigationRef.navigate('DeviceNavigation', {
                    screen: 'DeviceDetails',
                    params: {
                      id: currentDeviceId,
                    },
                  }),
              }),
          }}
        />
        <Stack.Screen
          name="DeviceModalTaringNeeded"
          component={DeviceModalTaringNeeded}
          options={{ ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }) }}
        />
        <Stack.Screen
          name="DeviceWiFiSetup"
          component={DeviceWiFiSetup}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="selectSSID"
          component={SelectSSID}
          options={{ ...lastScreenOptions() }}
        />
        <Stack.Screen
          name="DeviceSubstanceSetup"
          component={DeviceSubstanceSetup}
          options={{ header: () => CustomHeader({ title: ' ' }) }}
        />
        <Stack.Screen
          name="DeviceAssignPet"
          component={DeviceAssignPet}
          options={{ ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }) }}
        />
        <Stack.Screen
          name="DeviceName"
          component={DeviceName}
          options={{ ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }) }}
        />
        <Stack.Screen
          name="DeviceBowlSetup"
          component={DeviceBowlSetup}
          options={{ header: () => CustomHeader({ title: t('bowls') }) }}
        />
        <Stack.Screen
          name="DeviceBowlTypeSelect"
          component={DeviceBowlTypeSelect}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DeviceBowlFoodSelect"
          component={DeviceBowlFoodSelect}
          options={{
            header: () => CustomHeader({ withCross: true, withTitle: false }),
          }}
        />

        <Stack.Screen
          name="DeviceFoodSetup"
          component={DeviceFoodSetup}
          options={{ ...lastScreenOptions() }}
        />
        <Stack.Screen
          name="DevicePortioning"
          component={DevicePortioning}
          options={{
            header: () => CustomHeader({ title: t('portion'), withArrowBack: true }),
          }}
        />
        <Stack.Screen
          name="DeviceSetPortioning"
          component={DeviceSetPortioning}
          options={{
            header: () => CustomHeader({ withTitle: false, withArrowBack: true }),
          }}
        />
        <Stack.Screen
          name="DeviceLidDelay"
          component={DeviceLidDelay}
          options={{
            ...TransitionPresets.ModalPresentationIOS,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="MultiplePet"
          component={MultiplePet}
          options={{
            ...lastScreenOptions(),
          }}
        />
      </Stack.Navigator>
    </SpView>
  );
}
