import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const BowlSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}>
    <G
      fill="#263A43"
      fillRule="nonzero">
      <Path d="m15.28 13.03-.361-5.048H1.08l-.36 5.047H0V14h16v-.97h-.72ZM6.03 10.02H9.97v.971H6.03v-.97ZM14.141 6.835c0-1.393-.836-2.538-1.894-2.645-.324-.945-1.05-1.572-1.878-1.572-.277 0-.546.07-.797.207-.42-.516-.984-.813-1.572-.813-.588 0-1.152.297-1.572.813a1.658 1.658 0 0 0-.797-.207c-.828 0-1.554.627-1.878 1.572-1.058.107-1.894 1.252-1.894 2.645 0 .059.002.118.005.177h12.272a3.48 3.48 0 0 0 .005-.177Z" />
    </G>
  </Svg>
);
export default BowlSVG;
