import React, { useCallback } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  Text,
  View,
  Image,
  FlatList,
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { PetModel } from '@models/Pet';
import { testProperties } from '@utils/testProperties';

interface SoundItemProps {
  pet: PetModel;
}

const SoundSettings = () => {
  const pets = usePetsByHousehold();
  const renderItem = useCallback((pet: any) => <SoundItem pet={pet.item} />, [pets]);

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={pets}
        renderItem={renderItem}
        keyExtractor={(event, index) => event + index.toString()}
      />
    </SafeAreaView>
  );
};

const SoundItem = ({ pet }: SoundItemProps) => {
  return (
    <TouchableOpacity>
      <View style={styles.content}>
        <Image
          style={styles.image}
          {...testProperties('pet photo', 'image')}
          source={{
            uri: pet?.photo?.location,
          }}
        />
        <View style={styles.infoContainer}>
          <Text
            style={styles.name}
            numberOfLines={1}>
            {pet.name}
          </Text>
          <FontAwesomeIcon
            style={styles.icon}
            size={20}
            icon={faChevronRight}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  // Item
  content: {
    paddingHorizontal: 48,
    flex: 1,
    flexDirection: 'row',
    marginTop: 20,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    flexDirection: 'column',
    marginLeft: 15,
  },
  image: {
    height: 50,
    width: 50,
    borderRadius: 50,
  },
  icon: {
    color: '#616578',
  },
});

export default SoundSettings;
