import React from 'react';
import { Dimensions, StyleSheet } from 'react-native';

import colors from '@styles/colors';
import { SpView } from '../SpView';
import SkeletonLoader from 'expo-skeleton-loader';

export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = Math.min(Math.round(SLIDER_WIDTH * 0.65), 350);

const SpPetSkeletonLoader = () => {
  return (
    <SpView style={styles.container}>
      <SkeletonLoader.Container style={styles.header}>
        <SkeletonLoader.Item style={styles.avatar} />
        <SkeletonLoader.Container>
          <SkeletonLoader.Item style={styles.bigLine} />
          <SkeletonLoader.Item style={styles.smallLine} />
        </SkeletonLoader.Container>
      </SkeletonLoader.Container>
      <SkeletonLoader.Container style={styles.body}>
        <SkeletonLoader.Container>
          <SkeletonLoader.Item style={{ ...styles.bigLine, ...styles.bigFlex }} />
          <SkeletonLoader.Item style={styles.smallLine} />
        </SkeletonLoader.Container>
        <SkeletonLoader.Container>
          <SkeletonLoader.Item style={{ ...styles.bigLine, ...styles.bigFlex }} />
          <SkeletonLoader.Item style={styles.smallLine} />
        </SkeletonLoader.Container>
        <SkeletonLoader.Container>
          <SkeletonLoader.Item style={{ ...styles.bigLine, ...styles.bigFlex }} />
          <SkeletonLoader.Item style={styles.smallLine} />
        </SkeletonLoader.Container>
      </SkeletonLoader.Container>
      <SkeletonLoader.Item style={styles.footer as any} />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 24,
    padding: 20,
    backgroundColor: colors.white.color,
    height: '100%',
    justifyContent: 'space-between',
    width: ITEM_WIDTH,
  },
  avatar: {
    width: 92,
    height: 92,
    borderRadius: 46,
    backgroundColor: '#E1E9EE',
  },
  bigLine: {
    height: 28,
    backgroundColor: '#E1E9EE',
    width: 150,
    borderRadius: 24,
  },
  smallLine: {
    height: 20,
    backgroundColor: '#E1E9EE',
    width: 100,
    borderRadius: 24,
    marginTop: 8,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20,
  },
  bigFlex: {
    width: 200,
  },
  body: {
    justifyContent: 'space-between',
    flex: 1,
    marginVertical: '9%',
  },
  footer: {
    height: 76,
  },
});

export default SpPetSkeletonLoader;
