import { useMemo } from 'react';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { WeightUnits } from '@constants/WeightUnits';
import { PetSpecie } from '@constants/Pet';

interface Props {
  specie: number;
  weight: number;
}

const usePetValidInsightData = ({ specie, weight }: Props) => {
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);

  const VALID_WEIGHTS = {
    [WeightUnits.Metric]: {
      [PetSpecie.cat]: {
        min: 0.05,
        max: 25,
      },
      [PetSpecie.dog]: {
        min: 0.05,
        max: 200,
      },
    },
    [WeightUnits.Imperial]: {
      [PetSpecie.cat]: {
        min: 0.1,
        max: 55,
      },
      [PetSpecie.dog]: {
        min: 0.1,
        max: 440,
      },
    },
  };

  return useMemo(() => {
    if (!specie || !weight) {
      return false;
    }
    const convertedWeight = convertWithUnits(+weight, true, true).split(' ')[0];

    return (
      convertedWeight >= VALID_WEIGHTS[userWeightUnits][specie].min &&
      convertedWeight <= VALID_WEIGHTS[userWeightUnits][specie].max
    );
  }, [specie, weight, userWeightUnits]);
};

export default usePetValidInsightData;
