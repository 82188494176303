import { StyleSheet, Text, View } from 'react-native';
import React, { useMemo } from 'react';
import { SpView } from 'src/components/SpView';
import { SpText } from '../../../../../../components/SpText/SpText';
import colors from '@styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import useAdaptiveFontSize from '@hooks/useAdaptiveFontSize';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { testProperties } from '@utils/testProperties';
import { useTranslation } from 'react-i18next';
import useBoundStore from 'src/store/store';

type PetInfoSectionProps = {
  icon?: any;
  svgIcon?: any;
  title: string;
  avarageMl?: number;
  data: string;
  fontSizeForNumbers?: number;
  fontSizeForText?: number;
  timeMarker?: string;
  type?: 'count' | 'time' | string | undefined;
  disablePadding?: boolean;
  rightSideFlexEnd?: boolean;
  viewStyle?: 'normal' | 'compact' | 'subCompact';
};

const PetInfoSection = ({
  icon,
  title,
  data,
  fontSizeForNumbers = 24,
  fontSizeForText = 16,
  avarageMl,
  timeMarker,
  type,
  svgIcon,
  disablePadding = false,
  rightSideFlexEnd = false,
  viewStyle = 'normal',
}: PetInfoSectionProps) => {
  const { t } = useTranslation();
  const [sizes, layoutHandler] = useAdaptiveFontSize(
    {
      numbers: fontSizeForNumbers,
      text: fontSizeForText,
    },
    [data, type],
  );
  const { user } = useBoundStore((state) => state.accountStore);

  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const formattedData = useMemo(() => {
    let splitData;
    if (data === '-') {
      splitData = [data];
    } else if (type === 'count' || type === 'time') {
      splitData = data?.split(' ');
    } else {
      const value = data?.split(' ')[0];
      const convertedData = convertWithUnits(Number(value), type === 'weight', false);
      splitData = convertedData?.split(' ');
    }

    return splitData.map((item, index) => {
      const size = item === '-' ? (viewStyle === 'normal' ? 30 : 15) : sizes.numbers;

      if (Number.isNaN(Number(item))) {
        return (
          <React.Fragment key={`${item}${index}prefix`}>
            <SpText
              style={[
                styles.data,
                { fontSize: sizes.text, lineHeight: size },
                viewStyle !== 'normal' ? { fontFamily: 'Rubik', fontSize: size } : {},
              ]}>
              {item}
            </SpText>
            <SpView width={5} />
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={`${item}${index}main`}>
          <SpText
            style={[
              styles.data,
              { fontSize: size, lineHeight: size },
              viewStyle !== 'normal' ? { fontFamily: 'Rubik' } : {},
            ]}>
            {item}
          </SpText>
          <SpView width={5} />
        </React.Fragment>
      );
    });
  }, [data, user.weight_units, sizes]);

  const isOnlyText = avarageMl === undefined && timeMarker === undefined;

  switch (viewStyle) {
    case 'compact':
      return (
        <SpView alignItems="center">
          <SpView alignItems="center" justifyContent="center">
            <SpView
              marginBottom={5}
              flexDirection="row"
              alignItems="center"
              justifyContent="center">
              <View {...testProperties(icon?.iconName, 'icon')}>
                {icon && <FontAwesomeIcon icon={icon} size={16} color={colors.primary.color} />}
                {svgIcon && svgIcon}
              </View>
              <SpText style={styles.compactTitle} numberOfLines={1}>
                {title}
              </SpText>
            </SpView>
          </SpView>
          <SpView alignItems="center" justifyContent="center" marginTop={5}>
            <SpView style={{ flexDirection: 'row' }}>{formattedData}</SpView>
          </SpView>
        </SpView>
      );
      break;
    case 'subCompact':
      return (
        <SpView
          marginBottom={5}
          flexDirection="row"
          style={{ alignItems: 'center', justifyContent: 'center' }}>
          <SpView {...testProperties(icon?.iconName, 'icon')} style={{}}>
            {icon && <FontAwesomeIcon icon={icon} size={16} color={colors.primary.color} />}
            {svgIcon && svgIcon}
          </SpView>
          <SpView
            style={{
              height: 16,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 8,
            }}>
            {formattedData}
          </SpView>
        </SpView>
      );
      break;
    case 'normal':
      return (
        <SpView flexDirection="row">
          <SpView
            flexDirection="row"
            width="100%"
            paddingVertical={disablePadding ? 0 : 10}
            alignItems={rightSideFlexEnd ? 'flex-end' : 'center'}>
            <SpView width={isOnlyText ? '100%' : undefined}>
              <SpView marginBottom={5} flexDirection="row" alignItems="center">
                <View {...testProperties(icon?.iconName, 'icon')}>
                  {icon && <FontAwesomeIcon icon={icon} size={16} color={colors.primary.color} />}
                  {svgIcon && svgIcon}
                </View>
                <SpText style={styles.title} numberOfLines={2}>
                  {title}
                </SpText>
              </SpView>
              <SpView
                width={isOnlyText ? undefined : 180}
                style={{ flexWrap: 'wrap' }}
                alignItems="flex-end"
                flexDirection="row">
                <Text onTextLayout={layoutHandler}>{formattedData}</Text>
              </SpView>
            </SpView>

            {avarageMl === 0 || avarageMl ? (
              <SpView marginLeft="auto" justifyContent="space-between">
                <SpText align="right" style={styles.avarageStyles}>
                  {convertWithUnits(avarageMl)}
                </SpText>
                <SpText align="right" style={styles.avarageStyles}>
                  {t('7_days_avg')}
                </SpText>
              </SpView>
            ) : null}

            {timeMarker && (
              <SpView marginLeft="auto" maxWidth={90} justifyContent="flex-end">
                <SpText align="right" style={styles.avarageStyles}>
                  {timeMarker}
                </SpText>
              </SpView>
            )}
          </SpView>
        </SpView>
      );
  }
  // } === 'subCompact' ? (
  //   <SpView marginBottom={5} flexDirection="row" alignItems="center" justifyContent="center">
  //     <View {...testProperties(icon?.iconName, 'icon')}>
  //       {icon && <FontAwesomeIcon icon={icon} size={16} color={colors.primary.color} />}
  //       {svgIcon && svgIcon}
  //     </View>
  //     <SpText style={styles.title} numberOfLines={1}>
  //       {formattedData}
  //     </SpText>
  //   </SpView>
  // ) : compact ? (
  //   <SpView alignItems="center">
  //     <SpView alignItems="center" justifyContent="center">
  //       <SpView marginBottom={5} flexDirection="row" alignItems="center" justifyContent="center">
  //         <View {...testProperties(icon?.iconName, 'icon')}>
  //           {icon && <FontAwesomeIcon icon={icon} size={16} color={colors.primary.color} />}
  //           {svgIcon && svgIcon}
  //         </View>
  //         <SpText style={styles.title} numberOfLines={1}>
  //           {title}
  //         </SpText>
  //       </SpView>
  //     </SpView>
  //     <SpView alignItems="center" justifyContent="center">
  //       <Text onTextLayout={layoutHandler}>{formattedData}</Text>
  //     </SpView>
  //   </SpView>
  // ) : (
  //   <SpView flexDirection="row">
  //     <SpView
  //       flexDirection="row"
  //       width="100%"
  //       paddingVertical={disablePadding ? 0 : 10}
  //       alignItems={rightSideFlexEnd ? 'flex-end' : 'center'}>
  //       <SpView width={isOnlyText ? '100%' : undefined}>
  //         <SpView marginBottom={5} flexDirection="row" alignItems="center">
  //           <View {...testProperties(icon?.iconName, 'icon')}>
  //             {icon && <FontAwesomeIcon icon={icon} size={16} color={colors.primary.color} />}
  //             {svgIcon && svgIcon}
  //           </View>
  //           <SpText style={styles.title} numberOfLines={2}>
  //             {title}
  //           </SpText>
  //         </SpView>
  //         <SpView
  //           width={isOnlyText ? undefined : 180}
  //           style={{ flexWrap: 'wrap' }}
  //           alignItems="flex-end"
  //           flexDirection="row">
  //           <Text onTextLayout={layoutHandler}>{formattedData}</Text>
  //         </SpView>
  //       </SpView>
  //
  //       {avarageMl === 0 || avarageMl ? (
  //         <SpView marginLeft="auto" justifyContent="space-between">
  //           <SpText align="right" style={styles.avarageStyles}>
  //             {convertWithUnits(avarageMl)}
  //           </SpText>
  //           <SpText align="right" style={styles.avarageStyles}>
  //             {t('7_days_avg')}
  //           </SpText>
  //         </SpView>
  //       ) : null}
  //
  //       {timeMarker && (
  //         <SpView marginLeft="auto" maxWidth={90} justifyContent="flex-end">
  //           <SpText align="right" style={styles.avarageStyles}>
  //             {timeMarker}
  //           </SpText>
  //         </SpView>
  //       )}
  //     </SpView>
  //   </SpView>
  // );
};

export default PetInfoSection;

const styles = StyleSheet.create({
  compactTitle: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyTitle.color,
    marginLeft: 5,
    maxWidth: '90%',
  },
  title: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
    marginLeft: 5,
    maxWidth: '90%',
  },
  data: {
    color: colors.greyText.color,
    textTransform: 'lowercase',
    fontSize: 24,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
  },
  avarageStylesContainer: {
    marginVertical: 20,
  },
  avarageStyles: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyOpacity.color,
  },
});
