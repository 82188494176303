import { useEffect, useState } from 'react';
import { Keyboard, Platform, KeyboardEvent, EmitterSubscription } from 'react-native';

const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  useEffect(() => {
    let keyboardDidShowListener: EmitterSubscription | null = null;
    let keyboardDidHideListener: EmitterSubscription | null = null;
    const updateKeyboardHeight = (event: KeyboardEvent) => {
      setKeyboardHeight(event.endCoordinates.height);
    };
    if (Platform.OS === 'android') {
      keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', updateKeyboardHeight);

      keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardHeight(0);
      });
    } else {
      keyboardDidShowListener = Keyboard.addListener('keyboardWillShow', updateKeyboardHeight);

      keyboardDidHideListener = Keyboard.addListener('keyboardWillHide', () => {
        setKeyboardHeight(0);
      });
    }
    return () => {
      if (keyboardDidShowListener) {
        keyboardDidShowListener.remove();
      }
      if (keyboardDidHideListener) {
        keyboardDidHideListener.remove();
      }
    };
  }, [setKeyboardHeight]);
  return keyboardHeight;
};

export default useKeyboardHeight;
