import { SpProfileImage } from 'src/components/SpProfileImage';
import { TimelineEventModel } from '@models/Timeline';
import colors from '@styles/colors';
import { get } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import useBoundStore from 'src/store/store';

export const TimelineUserPhoto = ({
  event,
  active,
  showPhoto = false,
}: {
  event: TimelineEventModel;
  active: boolean;
  showPhoto?: boolean;
}) => {
  const activeHousehold = useBoundStore(state => state.householdStore.activeHousehold);

  const userImage = useMemo(() => {
    if (!showPhoto) return null;
    const userId = get(event, 'users[0].id', null);
    if (userId === null) return null;
    const activeUser = activeHousehold?.users?.find(elem => elem.id === userId);
    return activeUser?.user?.photo?.location || null;
  }, [event, activeHousehold]);

  return (
    <SpProfileImage
      backgroundColor="#dde0e2"
      isUser
      imageUrl={userImage}
      width={active ? 56 : 48}
      height={active ? 56 : 48}
      withBorder={active}
      borderWidth={2}
      borderColor={colors.green.color}
    />
  );
};
