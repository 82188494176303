import { StyleSheet, View } from 'react-native';
import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { FieldsObj } from '@models/FormikTypes';
import { closeModal, openModal } from 'src/services/ModalBox';
import SpModal from 'src/components/SpModal';
import HouseholdCountriesWrapper from 'src/pages/Household/components/HouseholdCountriesWrapper';
import useBoundStore from 'src/store/store';
import SpEditMenuItem from 'src/components/SpEditMenuItem';
import SpTitle from 'src/components/SpTitle';
import { stepFormikValid } from '@utils/stepFormikValid';
import CustomHeader from 'src/components/CustomHeader';
import { useTranslation } from 'react-i18next';

interface FormValues {
  country: string;
}

interface ICountryFormProps {
  formik: FormikProps<FormValues>;
  submitErrors: FieldsObj;
}
const CountryForm = ({ formik, submitErrors }: ICountryFormProps) => {
  const { getCountryByID } = useBoundStore(state => state.countryStore);
  const { t } = useTranslation();

  const firstNameValue = useMemo(() => {
    const notSelected = stepFormikValid('country', submitErrors, formik.errors);
    return notSelected || getCountryByID(Number(formik.values?.country))?.name || t('not_selected');
  }, [formik.errors, submitErrors, formik.values]);

  return (
    <View>
      <SpTitle text={t('register_country_select_title')} />
      <SpEditMenuItem
        label={t('country')}
        value={firstNameValue}
        action={() => {
          openModal('RegisterCountrySelect');
        }}
      />
      <SpModal
        backdropColor="white"
        bottomAreaView={false}
        modalName="RegisterCountrySelect"
        onRequestClose={() => closeModal('RegisterCountrySelect')}>
        <View style={styles.countriesModalWrapper}>
          <CustomHeader
            withTitle={false}
            goBackFunc={() => closeModal('RegisterCountrySelect')}
          />
          <HouseholdCountriesWrapper
            withButton
            handleChange={id => {
              if (id) {
                formik.setTouched({ country: true });
                return formik.handleChange('country')(String(id));
              }
              return null;
            }}
            handleClose={() => closeModal('RegisterCountrySelect')}
            selectedItemId={Number(formik.values?.country)}
          />
        </View>
      </SpModal>
    </View>
  );
};

export default CountryForm;

const styles = StyleSheet.create({
  countriesModalWrapper: {
    flex: 1,
    width: '100%',
  },
});
