import React from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import HowToFactoryResetYourCDB from '../HowToFactoryResetYourCDB';
import colors from '@styles/colors';

const CDBHowToFactoryReset = () => {
  return (
    <SafeAreaView style={styles.screen}>
      <HowToFactoryResetYourCDB />
    </SafeAreaView>
  );
};

export default CDBHowToFactoryReset;

const styles = StyleSheet.create({
  screen: {
    height: '100%',
    backgroundColor: colors.white.color,
    flex: 1,
  },
});
