import {
  AuthModel,
  LoginFormModel,
  PasswordResetModel,
  RegisterFormModel,
  RequestPasswordResetModel,
} from '../models/Auth';
import Http from './Http';

class AuthApi {
  static login(data: LoginFormModel, skipUnauthorizedStatus = false): Promise<AuthModel> {
    return Http.post(`/api/auth/login`, data, {
      headers: {
        skipUnauthorizedStatus: Number(skipUnauthorizedStatus),
      },
    }).then(response => {
      return response.data.data;
    });
  }
  static logout(data: { client_uid: string }): Promise<AuthModel> {
    return Http.post(`/api/auth/logout`, data, {}).then(response => {
      return response.data.data;
    });
  }
  static requestPasswordReset(data: RequestPasswordResetModel): Promise<number> {
    return Http.post(`/api/auth/reset-password/request`, data).then(response => response.status);
  }

  static resetPassword(data: PasswordResetModel): Promise<any> {
    return Http.post(`/api/auth/reset-password`, data).then(response => response.data.data);
  }

  static register(data: RegisterFormModel): Promise<AuthModel> {
    return Http.post(`/api/auth/register`, data).then(response => {
      return response.data.data;
    });
  }
}

export default AuthApi;
