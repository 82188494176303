import React from 'react';
import { ButtonProps, Pressable, StyleProp, TextStyle } from 'react-native';
import { SpCenter } from '../SpCenter';
import { SpText } from '../SpText/SpText';
import { testProperties } from '@utils/testProperties';

interface SpButtonProps extends ButtonProps {
  width?: string;
  textStyle?: StyleProp<TextStyle>;
  color?: string;
  style?: StyleProp<TextStyle>;
  buttonStyle?: StyleProp<TextStyle>;
  borderRadius?: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  fontFamily?: string;
}

export const SpButton = ({
  title,
  width = '100%',
  color = 'dodgerblue',
  textStyle = {},
  style = {},
  buttonStyle = {},
  borderRadius = 6,
  paddingVertical = 10,
  paddingHorizontal = 0,
  fontFamily = 'Rubik_Medium',
  ...props
}: SpButtonProps) => {
  return (
    <Pressable
      style={style}
      accessible={false}
      {...testProperties(title, 'SpButton')}
      {...props}
    >
      <SpCenter
        width={width}
        backgroundColor={color}
        paddingVertical={paddingVertical}
        paddingHorizontal={paddingHorizontal}
        borderRadius={borderRadius}
      >
        <SpText
          fontFamily={fontFamily}
          style={textStyle || null}
          color="white"
        >
          {title || ''}
        </SpText>
      </SpCenter>
    </Pressable>
  );
};
