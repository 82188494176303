import { SpText } from './SpText/SpText';
import { SpView } from './SpView';
import colors from '@styles/colors';
import React from 'react';
import { StyleSheet } from 'react-native';
import { MenuItem } from '@models/Menu';
import { SpMenuStack } from './SpMenuStack';

type ActionPickerProps = {
  title?: string;
  buttons: MenuItem[];
};

const ActionPicker = ({ buttons, title }: ActionPickerProps) => {
  return (
    <SpView>
      {title && <SpText style={styles.title}>{title}</SpText>}
      <SpView style={styles.container}>
        <SpMenuStack buttons={buttons} />
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  icon: {
    marginRight: 24,
  },
  label: {
    marginLeft: 24,
    fontSize: 16,
  },
  btn: {
    borderRadius: 14,
    backgroundColor: colors.white.color,
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
    paddingVertical: 16,
  },
  centerBetween: {
    flexDirection: 'row',
  },
  container: {
    gap: 24,
    flex: 1,
    marginTop: 14,
    marginBottom: 35,
  },
  title: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
  },
});

export default ActionPicker;
