import { HouseholdModel } from '@models/Household';
import useBoundStore from '../../../../store/store';

export const useGroupedHouseholds = (): {
  householdsAsOwner: HouseholdModel[];
  householdsAsViewer: HouseholdModel[];
} => {
  const households = useBoundStore(s => s.householdStore.households);
  const user = useBoundStore(s => s.accountStore.user);

  return households.reduce(
    (acc, household) => {
      const meOwner = household.users.find(
        item => item.owner && item.write && item.id === user?.id,
      );
      if (meOwner) {
        acc.householdsAsOwner.push(household);
      } else {
        acc.householdsAsViewer.push(household);
      }
      return acc;
    },
    { householdsAsOwner: [], householdsAsViewer: [] },
  );
};
