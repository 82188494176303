import { StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { SpView } from 'src/components/SpView';
import { SpText } from '../../../components/SpText/SpText';
import colors from '@styles/colors';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { useTranslation } from 'react-i18next';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { RouteProp, useRoute } from '@react-navigation/native';
import { FlowStackParamList } from '../index';

interface QRWiFiPasswordProps {
  setPasswordByQR?: (text: string) => void;
}

export const QRWiFiPassword = () => {
  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);

  const route = useRoute<RouteProp<FlowStackParamList, 'QRWiFiPassword'>>();
  const setPasswordByQR = route.params.setPasswordByQR || '';

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };
    getBarCodeScannerPermissions();
  }, []);

  // @ts-ignore
  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    setPasswordByQR(data);
    alert(`Bar code with type ${type} and data ${data} has been scanned!`);
  };

  if (hasPermission === null) {
    return <SpText>Requesting for camera permission</SpText>;
  }
  if (hasPermission === false) {
    return <SpText>No access to camera</SpText>;
  }

  return (
    <SpView style={styles.wrapper}>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
      {scanned && (
        <SpRoundedHeaderButton
          title="Tap to Scan Again"
          onPress={() => setScanned(false)}
        />
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  titlePassword: {
    marginTop: '10%',
    marginBottom: '15%',
    fontFamily: 'Rubik',
    fontSize: 20,
    color: colors.black.color,
    alignItems: 'center',
    textAlign: 'center',
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
  },
  qrCode: {
    marginTop: '15%',
    fontFamily: 'Rubik_Medium',
    fontSize: 22,
    fontWeight: 'bold',
    color: colors.black.color,
    textAlign: 'center',
  },
  marginFive: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  bottomText: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
  headerBottom: {
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
    color: colors.white.color,
  },
  btnTxtFont: {
    maxWidth: 180,
    marginTop: 20,
  },
  bottomTextFont: {
    fontFamily: 'Rubik',
    fontSize: 20,
    color: colors.black.color,
    paddingBottom: 50,
  },
});

export default QRWiFiPassword;
