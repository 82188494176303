import { EditMenuItem } from '@models/Menu';
import colors from '@styles/colors';
import React, { useCallback, useMemo } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { SpView } from '../SpView';
import SpEditMenuItemValue from '../SpEditMenuItemValue';
import { SpSelectArrow } from '../SpSelectArrow';
import { SpMenuIcon } from '../RoundIcons/SpMenuIcon';
import { SpDivider } from '../SpDivider';



export const SpSelectBoxItem = ({
  label,
  value,
  actionIcon,
  icon,
  action,
  disabled,
  viewOnly = false,
}: EditMenuItem) => {
  const onAction = useCallback(() => {
    if (viewOnly) {
      return;
    }
    if (disabled) {
      return;
    }
    action();
  }, [action, viewOnly, disabled]);

  const valueRenderItem = useMemo(() => {
    if (value) {
      return typeof value === 'string' ? (
        <SpView style={styles.valueLeft}>
          <SpEditMenuItemValue
            ellipsizeMode="tail"
            numberOfLines={1}
            value={value}
          />
        </SpView>
      ) : (
        <SpView>{value}</SpView>
      );
    }
    return null;
  }, [value]);

  const actionIconRender = useMemo(() => {
    if (viewOnly) {
      return null;
    }

    return actionIcon || <SpSelectArrow color={colors.greyText.color} />;
  }, [actionIcon, viewOnly]);

  return (
    <Pressable
      onPress={onAction}
      style={styles.processable}>
      {icon ? (
        <SpView style={styles.icon}>
          <SpMenuIcon
            icon={icon}
            action={action}
          />
        </SpView>
      ) : null}
      <SpView>
        <SpView>
          <SpView>
            <Text style={styles.label}>{label}</Text>
          </SpView>
          <SpView style={styles.wrapper}>
            {valueRenderItem}
            <SpView style={styles.valueRight}>{actionIconRender}</SpView>
          </SpView>
        </SpView>
      </SpView>
      <SpDivider />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    paddingBottom: 10,
  },
  processable: {
    width: '90%',
    borderStyle: 'solid',
  },
  label: {
    color: colors.greyText.color,
    fontSize: 14,
    opacity: 0.6,
    lineHeight: 20,
    fontFamily: 'Rubik',
  },
  valueLeft: {
    width: '45%',
    alignItems: 'flex-start',
    paddingTop: 5,
  },
  valueRight: {
    width: '50%',
    alignItems: 'flex-end',
  },
  icon: {
    alignItems: 'flex-end',
  },
});
