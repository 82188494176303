import {
  DeviceControlResponseStatus,
  DeviceFeederTareType,
  DeviceHubLedMode
} from '@constants/Device';
import {
  DeviceCatFlapControlModel,
  DeviceCerberusControlModel,
  DeviceFeederBowlModel,
  DeviceFeederControlModel,
  DeviceFeederLidModel,
  DeviceHubControlModel,
  DeviceModel,
  DevicePendingStatusModel,
  DevicePetDoorControlModel,
  LockUnlockRequestParamsModel,
  ServerControlResponseModel,
  UpdateCurfewRequestParamsModel
} from '@models/Device';
import { DeviceTagModel } from '@models/DeviceTag';
import qs from 'qs';

import Http, { MemoizedHttp } from './Http';

class DeviceApi {
  static readonly httpParams = {
    with: ['children', 'tags', 'control', 'status'],
  };

  static putDeviceControlAsync<T>(id: string | number, data: T) {
    return Http.put(`/api/device/${id}/control/async`, data);
  }
  static parseDeviceControlAsync(id: number, data: object) {
    return DeviceApi.putDeviceControlAsync(id, data).then((response) =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }
  static putDeviceControl<T>(id: string | number, data: T) {
    return Http.put(`/api/device/${id}/control`, data).then(response => {
      return DeviceApi.parseResponse(response.data);
    });
  }

  static putDeviceTagAsync<T>(
    id: number,
    data:
      | { tag_id: number; request_action?: number; profile?: number }
      | { tag_id: number; request_action?: number; profile?: number }[],
  ) {
    return Http.put(`/api/v2/device/${id}/tag/async`, data);
  }

  static deleteDeviceTagAsync<T>(id: number, tagId: number) {
    return Http.delete(`/api/device/${id}/tag/${tagId}/async`, {});
  }

  static getDeviceTags(id: number): Promise<DeviceTagModel[]> {
    return Http.get(`/api/device/${id}/tag`, {
      params: this.httpParams,
    }).then(response => {
      return response.data.data;
    });
  }

  // TODO filter by household and reload whenever user changes household
  static getDevices(householdId?: number): Promise<DeviceModel[]> {
    return Http.get(`/api/device`, {
      params: {  householdid: householdId },
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static deleteDevice(deviceId: DeviceModel['id']): Promise<DeviceModel[]> {
    return Http.delete(`/api/device/${deviceId}`).then(
      response => DeviceApi.parseResponse(response.data) as Promise<DeviceModel[]>,
    );
  }

  static allPairingAsync(): Promise<DeviceModel[] | any> {
    return Http.get(`/api/device/pairing`, {
      timeout: 2000,
    })
      .then(response => DeviceApi.parseResponse(response.data) as Promise<DeviceModel[]>)
      .catch(err => {
        return err;
      });
  }

  static pairWithCode(
    pairingCode: string,
    householdId: DeviceModel['household_id'],
  ): Promise<DeviceModel> {
    return Http.post(
      `/api/device/pair/${householdId}`,
      {
        pairing_code: pairingCode,
      },
      {
        signal: DeviceApi.newAbortSignal(4000),
      },
    ).then(response => {
      return DeviceApi.parseResponse(response.data);
    });
  }

  static pairAsync(
    deviceId: DeviceModel['id'],
    householdId: DeviceModel['household_id'],
  ): Promise<DeviceModel> {
    return Http.post(`/api/device/${deviceId}/pair/${householdId}`);
  }

  static getDeviceStatus(deviceId: DeviceModel['id']): Promise<DevicePendingStatusModel[]> {
    return MemoizedHttp.get(`/api/device/${deviceId}/control/status`, {
      signal: DeviceApi.newAbortSignal(5000),
    })
      .then(response => response.data.data)
      .catch(error => {
        console.log('device status error', error);
        return error;
      });
  }

  static getAllDevicesStatus(householdId: number): Promise<DevicePendingStatusModel[]> {
    return MemoizedHttp.get(`api/household/${householdId}/device/control/status`, {
      signal: DeviceApi.newAbortSignal(5000),
    })
      .then(response => response.data.data)
      .catch(error => {
        console.log('device status error', error);
        return error;
      });
  }

  static getDeviceStatusByID(deviceId: DeviceModel['id'], requestID: string): Promise<DevicePendingStatusModel> {
    return MemoizedHttp.get(`/api/device/${deviceId}/control/status/${requestID}`, {
      signal: DeviceApi.newAbortSignal(5000),
    })
      .then(response => response.data.data)
      .catch(error => {
        console.log('device status error', error);
        return error;
      });
  }
  // TODO: we need to prevent the user from controlling the product if its offline

  static lockUnlock(
    deviceId: number,
    data: LockUnlockRequestParamsModel,
  ): Promise<DeviceCatFlapControlModel | DevicePetDoorControlModel> {
    return DeviceApi.putDeviceControl(deviceId, data);
  }

  static lockUnlockAsync(id: number, data: LockUnlockRequestParamsModel) {
    return Http.put(`/api/device/${id}/control/async`, data).then(response =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }



  static updateCurfewAsync(id: number, data: UpdateCurfewRequestParamsModel) {
    return Http.put(`/api/device/${id}/control/async`, data).then(response =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }

  static updateName(id: number, data: DeviceModel): Promise<DeviceModel> {
    return Http.put(`/api/device/${id}`, data).then(response => response.data.data);
  }

  static updateNameAsync(deviceId: number, data: DeviceModel): Promise<DeviceModel> {
    return DeviceApi.putDeviceControlAsync(deviceId, data).then(response => response.data.data);
  }

  static assignPetAsync(
    id: number,
    data: { tag_id: number; request_action?: number; profile?: number }[],
  ): Promise<any> {
    return DeviceApi.putDeviceTagAsync(id, data).then(response => {
      return response.data;
    });
  }

  static updateZeroScalesAsync(deviceId: number, tare: DeviceFeederTareType | boolean) {
    return DeviceApi.putDeviceControlAsync(deviceId, { tare }).then(response => {
      const someError = (response?.data.results || []).some(
        item =>
          item.status === DeviceControlResponseStatus.DeviceError ||
          item.status === DeviceControlResponseStatus.ServerError ||
          item.status === DeviceControlResponseStatus.DeviceTimeout,
      );
      return new Promise<{ data: any; results: any }>((resolve, reject) => {
        if (someError) {
          reject(response.data);
        } else {
          resolve(response.data);
        }
      });
    });
  }


  static updateFoodTypeAsync(deviceId: number, bowls: DeviceFeederBowlModel) {
    return DeviceApi.putDeviceControlAsync(deviceId, { bowls }).then(response =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }

  static updateCerberusControl(
    deviceId: number,
    data: DeviceCerberusControlModel,
  ): Promise<DeviceCerberusControlModel> {
    // @ts-expect-error: Types should be updated
    return DeviceApi.putDeviceControlAsync(deviceId, data);
  }

  static updateCerberusControlAsync(
    deviceId: number,
    bowls: DeviceCerberusControlModel,
  ): Promise<DeviceCerberusControlModel> {
    return DeviceApi.putDeviceControlAsync(deviceId, bowls).then(response =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }


  static updateCloseDelayAsync(deviceId: number, lid: DeviceFeederLidModel) {
    return DeviceApi.putDeviceControlAsync(deviceId, { lid }).then(response =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }


  static updateTargetAsync(deviceId: number, bowls: DeviceFeederBowlModel) {
    return DeviceApi.putDeviceControlAsync(deviceId, { bowls }).then(response =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }


  static updateBowlTypeAsync(deviceId: number, bowls: DeviceFeederBowlModel) {
    return DeviceApi.putDeviceControlAsync(deviceId, { bowls }).then(response =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }



  static updateTareAsync(id: number, tare: boolean) {
    return Http.put(`/api/device/${id}/control/async`, {
      tare,
      // @ts-expect-error: Types should be updated
    }).then(response => DeviceApi.parseResponse(response));
  }



  static learnMode(id: number, learn_mode: boolean): Promise<DeviceHubControlModel> {
    return Http.put(`/api/device/${id}/control`, {
      learn_mode,
    }).then(response => {
      return DeviceApi.parseResponse(response.data);
    });
  }
  static learnModeAsync(id: number, learn_mode: boolean) {
    return DeviceApi.putDeviceControlAsync(id, { learn_mode }).then((response) =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }
  static enableFastPolling(deviceId: number) {
    return DeviceApi.putDeviceControlAsync(deviceId, { fast_polling: true }).then((response) =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }

  static updateLedModeAsync(deviceId: number, led_mode: DeviceHubLedMode) {
    return DeviceApi.putDeviceControlAsync(deviceId, { led_mode }).then(response =>
      // @ts-expect-error: Types should be updated
      DeviceApi.parseResponse(response),
    );
  }

  static updateHubPairingMode(deviceId: number, pairing_mode = 2) {
    return DeviceApi.putDeviceControlAsync(deviceId, { pairing_mode }).then(response => {
      return DeviceApi.parseResponse(response.data);
    });
  }

  private static newAbortSignal(timeoutMs: number) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);
    return abortController.signal;
  }

  private static parseResponse(response: ServerControlResponseModel): Promise<any> {
    const someError = (response?.results || []).some(
      item =>
        item.status === DeviceControlResponseStatus.DeviceError ||
        item.status === DeviceControlResponseStatus.ServerError ||
        item.status === DeviceControlResponseStatus.DeviceTimeout,
    );

    return new Promise((resolve, reject) => {
      if (someError) {
        reject(response);
      } else {
        resolve(response.data);
      }
    });
  }
}

export default DeviceApi;
