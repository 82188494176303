import React, { PropsWithChildren } from 'react';
import { SpText, SpTextProps } from '../SpText/SpText';

export const SpErrorText = ({ children, ...props }: PropsWithChildren<SpTextProps>) => (
  <SpText
    {...props}
    color="red"
  >
    {children}
  </SpText>
);
