import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

/* SVGR has dropped some elements not supported by react-native-svg: title */
const CIIncreasedSVG = (props: any) => (
  <Svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.91465 10.4187C1.15187 10.4187 0.245919 8.30849 1.46004 7.03047L7.54573 0.624483C8.33674 -0.20816 9.66394 -0.208161 10.455 0.624482L16.5406 7.03047C17.7548 8.30849 16.8488 10.4187 15.086 10.4187L10.99 10.4187L10.99 13.9408C10.99 15.0781 10.0992 16 9.00034 16C7.90147 16 7.01065 15.0781 7.01065 13.9408L7.01065 10.4187L2.91465 10.4187Z"
      fill="#263A43"
    />
  </Svg>
);
export default CIIncreasedSVG;
