/* eslint-disable react/no-unused-prop-types */
import { SpView } from 'src/components/SpView';
import React, { useCallback } from 'react';
import colors from '@styles/colors';
import SpTextButton from 'src/components/SpTextButton';
import { Pressable, StyleSheet } from 'react-native';
import { SpSwitch } from 'src/components/SpSwitch';
import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';

export type TimeValue = {
  lock_time: string;
  unlock_time: string;
  id: string;
  time?: string;
  enabled: boolean;
};

type Props = {
  isEditable: boolean;
  setSelectedValue: (data: unknown) => void;
  selectedValue: TimeValue;
  index: number;
  item: TimeValue;
  changeCurfewAction: (id: number) => void;
};

type WrapperProps = { isFirst: boolean };

const CurfewTimeItem: React.FC<Props> = ({
  isEditable,
  setSelectedValue,
  item,
  selectedValue,
  index,
  changeCurfewAction,
}) => {
  const { timeToFormat } = useTimeTransform();
  const userTimeFormat = useUserTimeUnits();

  const TimeWrapper = useCallback(
    ({ isFirst }: WrapperProps) => {
      const data = item[isFirst ? 'lock_time' : 'unlock_time'];
      const condition = item.id === selectedValue?.id && data === selectedValue.time;

      const pressHandler = () => {
        setSelectedValue({
          time: data,
          id: item.id,
          isLockedTime: isFirst,
          index,
          item,
        });
      };

      return (
        <SpView
          accessible={false}
          flexDirection="row"
          alignItems="center"
          style={{ flex: 1, justifyContent: 'center' }}
        >
          <Pressable
            onPress={pressHandler}
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              paddingVertical: 15,
            }}
            accessible={false}
          >
            <SpTextButton
              accessible={false}
              disabled={!isEditable}
              textStyle={[
                styles.textTime,
                condition ? styles.textTimeSelected : styles.textTimeDefault,
              ]}
              text={timeToFormat(data, userTimeFormat)}
              onPress={pressHandler}
            />
            {isEditable && (
              <Pressable
                accessible={false}
                style={styles.triangle}
                onPress={pressHandler}
              />
            )}
          </Pressable>
        </SpView>
      );
    },
    [isEditable, selectedValue, item],
  );
  return (
    <SpView
      accessible={false}
      flex={1}
      marginTop={18}
      width="100%"
    >
      <SpView
        flexDirection="row"
        backgroundColor={!isEditable ? colors.secondary3.color : colors.lightGrey.color}
        flex={1}
        width="100%"
        justifyContent="space-between"
        // paddingVertical={12}
        borderRadius={16}
      >
        <TimeWrapper
          isFirst
          accessible={false}
        />
        <TimeWrapper
          isFirst={false}
          accessible={false}
        />
        {isEditable && (
          <SpView
            style={{ flex: 1 / 2, justifyContent: 'center', paddingVertical: 12 }}
            accessible={false}
          >
            <SpSwitch
              accessible={false}
              checked={item.enabled}
              onChange={() => {
                changeCurfewAction(index);
              }}
            />
          </SpView>
        )}
      </SpView>
    </SpView>
  );
};

export default CurfewTimeItem;

const styles = StyleSheet.create({
  textTime: {
    fontFamily: 'Rubik',
    fontSize: 17,
  },
  textTimeDefault: {
    color: colors.black.color,
  },
  textTimeSelected: {
    color: colors.primary.color,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderBottomWidth: 0,
    borderRightWidth: 7,
    borderTopWidth: 7,
    borderLeftWidth: 7,
    borderRadius: 2,
    borderTopColor: colors.greyText.color,
    marginLeft: 5,
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
  },
});
