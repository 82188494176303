import React, { FC, createElement, useEffect, useRef, useState } from 'react';
import { SpText } from '../SpText/SpText';
import { ISpDatePickerProps } from '.';
import { SpRoundedHeaderButton } from '../SpRoundedHeaderButton';
import { Dimensions, Modal, Pressable, StyleSheet } from 'react-native';
import atomStyles from '@styles/atoms';
import { testProperties } from '@utils/testProperties';
import { SpView } from '../SpView';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { useCustomToast } from '@hooks/useToast';

const SpDatePicker: FC<ISpDatePickerProps> = ({
  mode,
  date,
  modal,
  onConfirm,
  onCancel,
  maximumDate,
  open,
}) => {
  const { t } = useTranslation();
  const localDate = useRef(date);
  const [pickerValue, setPickerValue] = useState<string>('');
  const toast = useCustomToast();
  useEffect(() => {
    switch (mode) {
      case 'time':
        const hours = localDate.current.getUTCHours().toString().padStart(2, '0');
        const minutes = localDate.current.getUTCMinutes().toString().padStart(2, '0');
        const timeString = `${hours}:${minutes}`;
        setPickerValue(timeString);
        break;
      case 'date':
        setPickerValue(localDate.current.toISOString().split('T')[0]);
        break;
      default:
        return;
    }
  }, [mode, date]);

  const onChangeHandler = ({ target: { value } }) => {
    if (!value) return;
    setPickerValue(value);
    if (mode === 'time') {
      const [hours, minutes] = value.split(':').map(Number);
      localDate.current.setUTCHours(hours);
      localDate.current.setUTCMinutes(minutes);
    } else if (mode === 'date') {
      if (maximumDate <= new Date(value)) {
        toast.show({
          description: 'wrong date',
          isError: true,
        });
        localDate.current.setTime(date.getTime());
        setPickerValue(localDate.current.toISOString().split('T')[0]);
        return;
      }
      if (value.length > 10) return;
      localDate.current = new Date(value);
    }
    return;
  };
  if (modal) {
    return (
      <>
        <Modal
          transparent
          animationType="fade"
          visible={open}
        >
          <Pressable
            onPress={onCancel}
            style={styles.datepickerBackdrop}
          >
            {createElement('input', {
              /////////
              type: mode,
              title: t('select {{value}}', { value: t(mode) }),
              value: pickerValue,
              autoFocus: true,
              style: styles.picker,
              onChange: onChangeHandler,
              onClick: event => event.stopPropagation(),
            })}
            <SpRoundedHeaderButton
              onPress={() => {
                onConfirm(localDate.current);
              }}
              title="Confirm"
            />
          </Pressable>
        </Modal>
      </>
    );
  }

  return (
    <SpView
      style={styles.container}
      {...testProperties('view', 'calendarContainer')}
    >
      <Pressable
        style={atomStyles.flex1}
        onPress={onCancel}
        {...testProperties('button', 'closeCalendar')}
      />
      <SpView style={[styles.max, styles.calendarContainer]}>
        <SpView style={[styles.max, styles.header]}>
          <Pressable
            onPress={() => onConfirm(localDate.current)}
            {...testProperties('button', 'confirmDateSelect')}
          >
            <SpText style={styles.doneBtn}>{t('done')}</SpText>
          </Pressable>
        </SpView>
        <SpView style={styles.max}>
          {createElement('input', {
            type: mode,
            value: pickerValue,
            autoFocus: true,
            style: styles.picker,
            onChange: onChangeHandler,
          })}
        </SpView>
      </SpView>
    </SpView>
  );
};
export default SpDatePicker;

const styles = StyleSheet.create({
  picker: {
    height: 30,
    padding: 5,
    borderWidth: 2,
    borderColor: '#677788',
    borderRadius: 5,
    width: 250,
    marginBottom: 5,
  },
  calendarContainer: {
    backgroundColor: colors.white.color,
  },
  max: {
    width: '100%',
    backgroundColor: colors.white.color,
  },
  pickerWidth: {
    width: Dimensions.get('window').width,
    height: 250,
  },
  doneBtn: {
    color: colors.primary.color,
    alignSelf: 'flex-end',
    marginRight: 21,
    fontSize: 16,
    fontWeight: '900',
  },
  header: {
    backgroundColor: '#fafaf8',
    height: 44,
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  datepickerBackdrop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
