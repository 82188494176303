import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';

type HavingIssuesFindingWiFiProps = {};

const HavingIssuesFindingWiFi: React.FC<HavingIssuesFindingWiFiProps> = () => {
  const { t } = useTranslation();

  return (
    <SpView style={styles.container}>
      <SpView style={styles.paragraphText}>
        <SpText
          color="#fff"
          size="sm"
          style={styles.textTitle}>
          {t('cdb_issue_finding_wifi')}
        </SpText>
        <SpText
          color="#fff"
          size="sm"
          style={styles.paragraph}>
          {t('setup_cdb_pairing_paragraph_1')}
        </SpText>
      </SpView>

      <SpView style={styles.paragraphText}>
        <SpView>
          <SpText
            style={styles.textTitle}
            color={'white'}>
            {t('2-4-only')}
          </SpText>
        </SpView>
        <SpView>
          <SpText
            style={styles.textDescription}
            color={'white'}>
            {t('make-sure-your-wi-fi-network-is')}
          </SpText>
        </SpView>
      </SpView>
      <SpView style={styles.paragraphText}>
        <SpView>
          <SpText
            style={styles.textTitle}
            color={'white'}>
            {t('check-connection')}
          </SpText>
        </SpView>
        <SpView>
          <SpText
            style={styles.textDescription}
            color={'white'}>
            {t('make-sure-your-wi-fi-router-is-turned-on-and-has-a-good-connection')}
          </SpText>
        </SpView>
      </SpView>
      <SpView style={styles.paragraphText}>
        <SpView>
          <SpText
            style={styles.textTitle}
            color={'white'}>
            {t('router-distance')}
          </SpText>
        </SpView>
        <SpView>
          <SpText
            style={styles.textDescription}
            color={'white'}>
            {t('try-getting-closer-to-your-wi-fi-router')}
          </SpText>
        </SpView>
      </SpView>
      <SpView style={styles.paragraphText}>
        <SpView>
          <SpText
            style={styles.textTitle}
            color={'white'}>
            {t('cdb_help_network_security_title')}
          </SpText>
        </SpView>
        <SpView>
          <SpText
            style={styles.textDescription}
            color={'white'}>
            {t('cdb_help_network_security_description')}
          </SpText>
        </SpView>
      </SpView>
      <SpView style={styles.paragraphText}>
        <SpView>
          <SpText
            style={styles.textDescription}
            color={'white'}>
            {t('setup_cdb_battery_paragraph_2')}
          </SpText>
        </SpView>
        <SpView>
          <SpText
            style={[styles.textTitle, { marginBottom: 0, marginTop: 30 }]}
            color={'white'}>
            {t('setup_cdb_pairing_link')}
          </SpText>
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.helpScreenColor.color,
    color: 'white',
    height: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  paragraph: {
    color: 'white',
    fontSize: 16,
    marginTop: 15,
  },
  paragraph2: {
    color: 'white',
    fontSize: 16,
    marginTop: 55,
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 20,
    marginBottom: 5,
  },
  paragraphDesc: {
    color: 'white',
    fontSize: 14,
  },
  link: {
    color: 'white',
    marginTop: 25,
    fontWeight: 'bold',
    fontSize: 16,
  },
  question: {
    fontSize: 20,
  },
  wrapperIconInText: {
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 20,
  },
  iconStyle: {
    backgroundColor: colors.helpScreenColor.color,
    flex: 1,
  },
  textIcon: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    fontSize: 22,
  },
  textTitle: {
    fontSize: 18,
    fontFamily: 'Rubik_SemiBold',
    marginBottom: 8,
  },
  textDescription: {
    fontSize: 16,
  },
  paragraphText: {
    marginBottom: 30,
  },
});
export default HavingIssuesFindingWiFi;
