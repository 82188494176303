import { useEffect, useRef } from 'react';

const useUpdate = (action: () => void, deps: unknown[]) => {
  const isFirstInit = useRef(true);
  useEffect(() => {
    if (!isFirstInit.current) action();
    else isFirstInit.current = false;
  }, deps);
};

export default useUpdate;
