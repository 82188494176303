import { Dimensions, Image, ImageURISource, StyleSheet, View } from 'react-native';
import React from 'react';
import { SpText } from '../../../../components/SpText/SpText';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import colors from '@styles/colors';

import { SpView } from 'src/components/SpView';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import LoadingComponent from '../../components/LoadingComponent';

type IAddProduct = {
  tip?: string;
  subtitle?: string;
  connected: boolean;
  imageUrl: ImageURISource;
  searchMsg: string;
  connectedMsg: string;
  withSubtitle?: boolean;
  maxRetriesError?: boolean;
};

const AddProduct = ({
  subtitle,
  tip,
  connected,
  imageUrl,
  searchMsg,
  connectedMsg,
  withSubtitle = true,
  maxRetriesError = false,
}: IAddProduct) => {
  const windowWidth = Dimensions.get('window').width;
  const { t } = useTranslation();
  return (
    <SpView style={styles.container}>
      <Image
        style={[
          {
            width: windowWidth,
          },
          styles.image,
        ]}
        source={imageUrl}
        resizeMode="contain"
        {...testProperties(subtitle, 'image')}
      />
      <View style={styles.textContainer}>
        <SpText
          {...testProperties('PressGearButton', 'Text')}
          style={styles.title}
          align="center">
          {t('press')}
          <View {...testProperties('faGear', 'icon')}>
            <FontAwesomeIcon
              style={{ marginHorizontal: 8 }}
              color={colors.greyText.color}
              icon={faGear}
            />
          </View>
          {t('button')}
        </SpText>
        {tip && <SpText style={styles.tip}>{tip}</SpText>}

        <SpText
          {...testProperties(
            `${
              subtitle && withSubtitle
                ? `${subtitle} ${t('add_product_subtitle1')}`
                : `${t('add_product_subtitle1').charAt(0).toUpperCase()}${t(
                    'add_product_subtitle1',
                  ).slice(1)}`
            }`,
            'text',
          )}
          style={styles.subtitle}>
          {subtitle && withSubtitle
            ? `${subtitle} ${t('add_product_subtitle1')}`
            : `${t('add_product_subtitle1').charAt(0).toUpperCase()}${t(
                'add_product_subtitle1',
              ).slice(1)}`}
          <View {...testProperties('faGear', 'icon')}>
            <FontAwesomeIcon
              style={{ marginHorizontal: 5 }}
              color={colors.greyText.color}
              icon={faGear}
            />
          </View>
          {t('add_product_subtitle2')}
        </SpText>
      </View>

      <SpView style={styles.loaderContainer}>
        <LoadingComponent
          searchMsg={searchMsg}
          isConnected={connected || maxRetriesError}
          connectedMsg={connectedMsg}
          error={maxRetriesError}
        />
      </SpView>
    </SpView>
  );
};

export default AddProduct;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    color: colors.greyText.color,
    fontSize: 20,
    fontFamily: 'Rubik_Medium',
    marginBottom: 16,
  },
  tip: {
    fontSize: 16,
    color: colors.greyText.color,
    textAlign: 'center',
    fontFamily: 'Rubik',
  },
  loadingText: {
    marginTop: 16,
    fontSize: 20,
    color: colors.greyText.color,
    fontFamily: 'Rubik_Medium',
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Rubik',
    color: colors.black.color,
    textAlign: 'center',
    marginBottom: 44,
  },
  loaderContainer: {
    alignItems: 'center',
  },
  image: {
    marginTop: 32,
    marginBottom: 37,
    maxHeight: 200,
    height: 150,
    resizeMode: 'center',
  },
  textContainer: {
    width: '90%',
    alignSelf: 'center',
  },
});
