import { EventEmitter } from 'events';

export const ModalBoxEmitter = new EventEmitter();

export const openModal = (name: string) => {
  ModalBoxEmitter.emit('openModal', { modalBoxType: name });
};

export const closeModal = (name: string) => {
  ModalBoxEmitter.emit('closeModal', { modalBoxType: name });
};
