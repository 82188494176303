import { SpLoading } from 'src/components/SpLoading';
import { useDeviceById } from '@hooks/useDeviceById';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { SpView } from 'src/components/SpView';
import colors from '@styles/colors';
import { t } from 'i18next';
import { MergedInterfaces } from '../../../../../../store/models';
import useBoundStore from '../../../../../../store/store';
import { DeviceStackParamList } from '../../index';
import { SpText } from '../../../../../../components/SpText/SpText';
import text from '@styles/text';
import { Image, StyleSheet } from 'react-native';
import { SpRoundedHeaderButton } from '../../../../../../components/SpRoundedHeaderButton';
import { SafeAreaView } from 'react-native-safe-area-context';
import { AppImages } from '@constants/AppImages';
import RedTimer5sSVG from '../../../../../../components/SvgIcons/RedTimer5sSVG';
import { navigationRef } from '../../../../../RootNavigation';

export const DeviceModalTaringNeeded = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceModalTaringNeeded'>) => {

  const { id } = route.params;
  const device = useDeviceById(id);

  const {
    loadingDevice,
  } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
    };
  });

  useEffect(() => {
    console.log('coming here');
  }, []);

  if (loadingDevice) {
    return <SpLoading />;
  }

  return (
    <SafeAreaView
      accessible={false}
      style={styles.screen}
      edges={['top']}>
      <SpView style={[styles.container]}>
        <SpView style={[styles.header]}>
          <SpText style={[text.title]}>{t('zero_required')}</SpText>
          <SpText style={[styles.text]}>{t('cdb_zero_required', { devicename: device?.name })}</SpText>
        </SpView>
        <SpView accessible style={styles.item}>
          <SpView style={[styles.instructions]}>
              <Image
                accessible
                style={[styles.image]}
                source={AppImages.zeroRequiredCDB}
                resizeMode="contain"
              />
          </SpView>
        </SpView>
        <SpView style={[styles.hint]}>
          <SpView style={[styles.left]}>
            <RedTimer5sSVG />
          </SpView>
          <SpView style={[styles.right]}>
            <SpText>{t('cdb_remove_metal')}</SpText>
          </SpView>
        </SpView>
        <SpView style={styles.buttonWrapper}>
          <SpRoundedHeaderButton
            backgroundColor={colors.primary.color}
            h={56}
            title={t('done')}
            onPress={() => navigationRef.goBack()}
            stylesForContainer={styles.button}
          />
        </SpView>
      </SpView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  screen: {
    height: '100%',
    backgroundColor: colors.white.color,
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    maxHeight: '85%',
    paddingHorizontal: 20,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignSelf: 'center',
    width: '100%',
  },
  header: {
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: '10%',
  },
  text: {
    marginTop: '5%',
    marginBottom: '5%',
    fontSize: 16,
  },
  image: {
    width: 240,
    height: 140,
  },
  item: {
    flex: 1,
    paddingHorizontal: 18,
    paddingVertical: 30,
    justifyContent: 'space-around',
    marginVertical: 30,
    alignItems: 'center',
    marginBottom: '20%',
    marginTop: '20%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingVertical: 10,
  },
  description: {
    fontSize: 14,
    paddingBottom: 20,
    textAlign: 'left',
  },
  button: {
    width: '100%',
    marginTop: 'auto',
  },
  buttonWrapper: {
    marginTop: '10%',
    textAlignVertical: 'bottom',
    width: '100%',
  },
  hint: {
    width: '100%',
    alignItems: 'flex-start',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: colors.greyAccent.color,
    borderRadius: 15,
    paddingHorizontal: 5,
    paddingVertical: 50,
    height: 200,
    marginTop: 50,
    marginBottom: '20%',
  },
  left: {
    width: '30%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  right: {
    width: '50%',
    alignContent: 'center',
    justifyContent: 'center',
    height: 100,
    marginTop: -50,
    marginLeft: 20,
  },
  instructions: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary.color,
    height: 40,
    width: 40,
    position: 'absolute',
    top: -15,
    left: -15,
    borderRadius: 50,
  },
  itemIndex: {
    top: 2,
    right: 1,
    fontSize: 22,
    color: colors.white.color,
    fontFamily: 'Rubik_Medium',
  },
  linkTextBlack: {
    color: colors.black.color,
    textDecorationLine: 'none',
  },
});
