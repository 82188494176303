import { SpView } from 'src/components/SpView';
import { Image, StyleSheet, View } from 'react-native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../components/SpText/SpText';
import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import InstructionsSteps from '../components/InstructionsSteps';

const ZeroFeeder = () => {
  const { t } = useTranslation();
  const zeroFeederText = [
    t('zero_feeder_setup_instruction_1'),
    t('zero_feeder_setup_instruction_2'),
    t('zero_feeder_setup_instruction_3'),
  ];

  return (
    <>
      <SpTitle
        text={t('zero_feeder')}
        align="center"
      />
      <SpText align="center">{t('zero_feeder_subtitle')}</SpText>
      <View style={styles.zeroFeederImageContainer}>
        <Image
          source={AppImages.imgZeroFeeder}
          style={styles.zeroFeederImage}
          resizeMode="contain"
          {...testProperties('imgZeroFeeder', 'image')}
        />
      </View>
      <InstructionsSteps
        arr={zeroFeederText}
        withStepNums
        space={16}
        centerNumCircle
      />
      <SpView style={styles.feederZeroLightsAnimationContainer}>
        <Image
          source={AppImages.feederZeroLightsAnimation}
          style={styles.feederZeroLightsAnimation}
          resizeMode="stretch"
          {...testProperties('imgFeederZeroLightsAnimation', 'image')}
        />
      </SpView>
    </>
  );
};

const styles = StyleSheet.create({
  zeroFeederImageContainer: {
    marginTop: 24,
    alignItems: 'center',
    marginBottom: 32,
  },
  zeroFeederImage: {
    height: 113,
  },
  feederZeroLightsAnimationContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 19,
  },
  feederZeroLightsAnimation: {
    height: 60,
    width: 248,
  },
});

export default ZeroFeeder;
