import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import InviteApi from '@api/InviteApi';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { InviteSlice, MergedInterfaces } from '../models';

const createInviteSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  InviteSlice
> = (set, get) => {
  return {
    inviteStore: lens((subSet, subGet) => ({
      loadingInvites: false,
      acceptInvite: async (inviteCode: string) => {
        try {
          AnalyticsService.logEvent(`household_acceptInvite`);

          subSet({ loadingInvites: true });
          await InviteApi.acceptInvite(inviteCode);
          await get().householdStore.getHouseholds();
          const households = await get().householdStore.households;
          const lastHousehold = households[households.length - 1];
          await get().householdStore.setActiveHousehold(lastHousehold);
          await get().petStore.loadPet(true);
          await get().deviceStore.loadDevice(true);
        } catch (err) {
          subSet({ loadingInvites: false });
          throw err;
        } finally {
          subSet({ loadingInvites: false });
        }
      },
    })),
  };
};

export default createInviteSlice;
