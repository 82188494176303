import { SpText } from '../../../../../../components/SpText/SpText';
import { AppImages } from '@constants/AppImages';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';
import { SpView } from 'src/components/SpView';
import { testProperties } from '@utils/testProperties';

interface BowlTypeSideProps {
  type: 'right' | 'left' | 'single';
  label?: string;
}

export const BowlTypeSide = ({ type, label }: BowlTypeSideProps) => {
  const { t } = useTranslation();

  if (type === 'left') {
    return (
      <SpView style={styles.wrapper}>
        <Image
          style={[styles.offset, styles.bowlImage]}
          source={AppImages.leftBowl}
          {...testProperties('left bowl', 'image')}
        />
        <SpText
          size="md"
          bold
          style={styles.text}
          fontFamily="Rubik_SemiBold">
          {label || t('left')}
        </SpText>
      </SpView>
    );
  }

  if (type === 'right') {
    return (
      <SpView style={styles.wrapper}>
        <SpText
          size="md"
          bold
          style={[styles.text, styles.offset]}
          fontFamily="Rubik_SemiBold">
          {label || t('right')}
        </SpText>
        <Image
          style={styles.bowlImage}
          source={AppImages.rightBowl}
          {...testProperties('right bowl', 'image')}
        />
      </SpView>
    );
  }

  return (
    <SpView style={styles.wrapper}>
      <Image
        style={styles.singleBowl}
        source={AppImages.bowl}
        resizeMode="contain"
        {...testProperties('single bowl', 'image')}
      />
      <SpView marginLeft={8}>
        <SpText
          size="md"
          bold
          style={styles.text}
          fontFamily="Rubik_SemiBold">
          {label || t('left')}
        </SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  singleWrapper: {
    alignItems: 'center',
  },
  wrapper: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    color: '#263a43',
  },
  bowlImage: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  offset: {
    marginRight: 8,
  },
  singleBowl: {
    width: 48,
    height: 36,
  },
});
