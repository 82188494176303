import persistStorage from '@utils/persistStorage';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

export async function requestPermissionsForPushNotificationAsync(): Promise<boolean> {
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;

  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
    await persistStorage.setItem('PUSH_NOTIFICATION_PERMISSION', {
      granted: status === 'granted',
    });
  }
  if (finalStatus !== 'granted') {
    // eslint-disable-next-line
    // alert('Failed to get push token for push notification!');
    return false;
  }
  return true;
}

export async function registerForPushNotificationsAsync(): Promise<{
  expoToken: string;
  deviceToken: string;
}> {
  let token;
  let deviceToken;
  // the || Device.brand == 'google' is for testing on android simulators
  if (Device.isDevice || Device.brand == 'google') {
    Notifications.setNotificationHandler({
      handleNotification: async notification => {
        return {
          shouldShowAlert: false,
          shouldPlaySound: false,
          shouldSetBadge: false,
        };
      },
    });
    const deviceType = await Device.getDeviceTypeAsync();
    if (deviceType === Device.DeviceType.DESKTOP) {
      return { expoToken: null, deviceToken: null };
    }
    const isGranted = await requestPermissionsForPushNotificationAsync();
    if (!isGranted) {
      return { expoToken: null, deviceToken: null };
    }

    token = '';
    deviceToken = (await Notifications.getDevicePushTokenAsync()).data;
    console.log('DEVICE TOKEN', deviceToken);
  } else {
    // eslint-disable-next-line
    // alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === 'android') {
    // TODO enable default channel, might be best to omit this altogether
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
    // TODO enable dog channel
    await Notifications.setNotificationChannelAsync('dog', {
      name: 'dog',
      importance: Notifications.AndroidImportance.MAX,
      sound: 'dog.mp3',
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
    await Notifications.setNotificationChannelAsync('cat', {
      name: 'cat',
      importance: Notifications.AndroidImportance.MAX,
      sound: 'cat.mp3',
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
    await Notifications.setNotificationChannelAsync('fcm_fallback_notification_channel', {
      name: 'Miscs',
      importance: Notifications.AndroidImportance.MAX,
      sound: 'cat.mp3',
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
    await Notifications.setNotificationChannelAsync('com.sureflap.surepetcare.notification', {
      name: 'Notification',
      importance: Notifications.AndroidImportance.MAX,
      sound: 'cat.mp3',
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
    await Notifications.setNotificationChannelAsync('expo', {
      name: 'expo',
      importance: Notifications.AndroidImportance.MAX,
      sound: 'cat.mp3',
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return { expoToken: token, deviceToken };
}
