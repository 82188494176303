import { AvailableNetwork } from '@models/WiFiDevice';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import text from '@styles/text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import LoadingSpinner from 'src/components/Loader/Loader';
import { SpSelectBoxItem } from 'src/components/SpSelectBoxItem';
import { SpText } from 'src/components/SpText/SpText';
import { SpView } from 'src/components/SpView';

interface WiFiConnectToDeviceProps {
  deviceSSIDS: AvailableNetwork[];
  targetNetworkID: number;
  loading: boolean;
  currentStep: boolean;
}

export const WiFiSSIDSelect = ({
  deviceSSIDS,
  targetNetworkID,
  loading,
  currentStep,
}: WiFiConnectToDeviceProps) => {
  const navigation = useNavigation<NavigationProp<any>>();
  const { t } = useTranslation();
  // const loading = false;
  const [hasTriedOnce, setHasTriedOnce] = useState(false);

  useEffect(() => {
    if (loading && currentStep && !hasTriedOnce) {
      console.log('wifi page loading');

      setHasTriedOnce(true);
    }
    // openSelectSSID if loading is false and deviceSSIDS is empty
    if (deviceSSIDS.length === 0 && !loading && hasTriedOnce && currentStep) {
      console.log('wifi page no networks found');
      // openSelectSSID();
    }
  }, [deviceSSIDS, currentStep, hasTriedOnce]);

  const openSelectSSID = () => {
    navigation.navigate('FlowNavigation', {
      screen: 'SelectSSID',
      params: {
        label: 'WiFi Network',
        inputName: 'WiFi Network',
        inputValue: targetNetworkID || -99,
      },
    });
  };
  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpText style={[text.body]}>{t('we_now_need_to_find_your_home_wifi_and_connect_the')}</SpText>

      {loading ? (
        <>
          <SpText style={text.subtitleLoading}>{t('loading_available_network_list')}</SpText>
          <LoadingSpinner />
        </>
      ) : (
        <SpView style={[styles.center]}>
          <SpSelectBoxItem
            label={t('wifi_network')}
            value={deviceSSIDS[targetNetworkID || 0]?.ssid || t('no_selected')}
            action={() =>
              navigation.navigate('FlowNavigation', {
                screen: 'SelectSSID',
                params: {
                  label: 'WiFi Network',
                  inputName: 'WiFi Network',
                  inputValue: targetNetworkID || 0,
                },
              })
            }
          />
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: '10%',
    minWidth: '100%',
    paddingBottom: 20,
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
    marginTop: 30,
  },
});

export default WiFiSSIDSelect;
