import { Platform } from 'react-native';
import LinearGradient, { LinearGradientProps } from 'react-native-linear-gradient';
import { Line } from 'react-native-svg';
import { SpView } from './SpView';

interface SpLinearGradientProps extends LinearGradientProps {}
export const SpLinearGradient = ({ ...props }: SpLinearGradientProps) => {
  return (
    <SpView
      style={[props.style, { backgroundColor: props.colors[0] }]}
      children={props.children}
    />
  );
};
