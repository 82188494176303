import { Pressable, StyleSheet, View } from 'react-native';
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { testProperties } from '@utils/testProperties';
import { SpView, SpViewProps } from './SpView';

type IconButtonProps = {
  backgroundColor: string;
  icon?: any;
  width?: number;
  height?: number;
  action: any;
  iconSize?: number;
  iconColor: string;
  rounded?: boolean;
  componentName?: string;
  svgIcon?: React.ReactNode;
};

const IconButton = ({
  backgroundColor,
  iconColor,
  width = 112,
  height = 56,
  icon,
  action,
  iconSize = 24,
  rounded,
  componentName,
  svgIcon,
}: IconButtonProps) => {
  const borderRadius = useMemo(() => {
    return rounded ? 50 : 28;
  }, [rounded]);
  return (
    <Pressable
      accessible={false}
      onPress={action}
      {...testProperties('button', 'view', componentName)}
      style={[styles.container, { width, height, backgroundColor, borderRadius }]}>
      {icon && (
        <View {...testProperties(icon.iconName, 'icon', componentName)}>
          <FontAwesomeIcon
            color={iconColor}
            icon={icon}
            size={iconSize}
          />
        </View>
      )}
      {svgIcon && <SpView {...testProperties('svg', 'icon', componentName)}>{svgIcon}</SpView>}
    </Pressable>
  );
};

export default IconButton;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
