import { SpLoading } from 'src/components/SpLoading';
import { useDeviceById } from '@hooks/useDeviceById';
import { useCustomToast } from '@hooks/useToast';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import i18n from '@utils/i18n';
import React, { useCallback, useEffect } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { MergedInterfaces } from '../../../../../../store/models';
import useBoundStore from '../../../../../../store/store';
import { DeviceStackParamList } from '../../index';
import { ChangeNameForm } from '../../components/DeviceName/ChangeNameForm';

export const DeviceName = ({ route }: StackScreenProps<DeviceStackParamList, 'DeviceName'>) => {
  const { id } = route.params;
  const toast = useCustomToast();
  const device = useDeviceById(id);
  const { loadingDevice, updateNameRequest, updateNameError, updateNameSuccess, updateProperty } =
    useBoundStore((state: MergedInterfaces) => {
      const { deviceStore } = state;
      return {
        loadingDevice: deviceStore.loadingDevice,
        updateNameRequest: deviceStore.updateNameRequest,
        updateNameError: deviceStore.updateNameError,
        updateNameSuccess: deviceStore.updateNameSuccess,
        updateProperty: deviceStore.updateProperty,
      };
    });
  const navigation = useNavigation();

  const saveAction = useCallback(
    async (data: string) => {
      await updateNameRequest(device.id, { ...device, name: data }, false);
      navigation.goBack();
    },
    [device],
  );
  const reset = () =>
    updateProperty({
      updateNameSuccess: null,
      updateNameError: null,
    });
  useEffect(() => {
    if (updateNameError) {
      toast.show({
        description: i18n.t('sorry_try_again'),
        isError: true,
      });
    }

    if (updateNameSuccess) {
      toast.show({
        description: i18n.t('success_update_device_name'),
      });
    }

    reset();
  }, [updateNameError, updateNameSuccess]);

  useEffect(() => {
    return reset;
  }, []);

  if (loadingDevice) {
    return <SpLoading />;
  }

  return (
    <SafeAreaView style={styles.view}>
      <ChangeNameForm
        name={device?.name}
        error={updateNameError}
        onSave={saveAction}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  view: {
    height: '100%',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
  },
});
