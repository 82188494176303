import { ConsumptionAlertOutcome, ConsumptionInsightOutcome } from '@models/ConsumptionInsight';

export const ConsumptionInsightWarningOutcomes = [
  ConsumptionInsightOutcome.Decreased,
  ConsumptionInsightOutcome.Elevated,
];

export const ConsumptionInsightAlertStatuses = [
  ConsumptionInsightOutcome.Decreased,
  ConsumptionInsightOutcome.WasDecreased,
  ConsumptionInsightOutcome.Elevated,
  ConsumptionInsightOutcome.WasElevated,
  ConsumptionInsightOutcome.WasFluctuating,
];

export const ConsumptionAlertsWarningOutcomes = [
  ConsumptionAlertOutcome.Problematic,
  ConsumptionAlertOutcome.StillProblematic,
];
