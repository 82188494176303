import { useEffect, useState } from 'react';
import { AnalyticsService } from '../services/AnalyticsService';
import useBoundStore from '../store/store';
import { useRefreshAnalyticsData } from './useRefreshAnalyticsData';

export const useSendAnalyticsDataAfterLogin = (): void => {
  const [firstInit, setFirstInit] = useState(true);
  const allowAnalyticsRefresh = useRefreshAnalyticsData();

  const { user, languages, getLanguageByID, countries, getCountryByID } = useBoundStore(s => ({
    user: s.accountStore.user,
    languages: s.languageStore.languages,
    getLanguageByID: s.languageStore.getLanguageByID,
    countries: s.countryStore.countries,
    getCountryByID: s.countryStore.getCountryByID,
  }));

  const setUserProperties = async () => {
    await AnalyticsService.setUserId(user.id);
    await AnalyticsService.setUserProperties({
      userLanguage: getLanguageByID(user.language_id).code,
      userCountry: getCountryByID(user.country_id).code,
    });
  };

  useEffect(() => {
    if (user && countries.length && languages.length && firstInit) {
      setFirstInit(false);
      setUserProperties().then();
      allowAnalyticsRefresh();
    }
  }, [user, countries, languages, firstInit]);

  useEffect(() => {
    if (!user) {
      setFirstInit(true);
    }
  }, [user]);
};
