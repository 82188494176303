import { FlatList, StyleSheet } from 'react-native';

import { PetGridCard } from './components/PetGridCard';
import { SpView } from '../../../../components/SpView';

interface PetGridCardProps {
  pets: any[];
}

export const PetGridCards = ({ pets }: PetGridCardProps) => {
  return (
    <FlatList
      style={styles.flatListContainer}
      data={pets}
      renderItem={({ item }) => (
        <SpView style={styles.item}>
          <SpView style={{ padding: 10 }}>
            <PetGridCard pet={item} />
          </SpView>
        </SpView>
      )}
      keyExtractor={item => item.name}
      numColumns={2}
      contentContainerStyle={styles.contentContainer}
    />
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: 20,
    flexGrow: 1, // Ensures that the FlatList fills the available space
  },
  flatListContainer: {
    width: '100%',
    flexGrow: 1, // Ensures that the FlatList fills the available space
    marginTop: 5,
    padding: 10,
  },
  item: {
    flex: 1 / 2, // Takes up 1/2 of the space for 2 columns
    height: 260, // Fixed height for each item
  },
  text: {
    fontSize: 16,
  },
});
