import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const NotificationSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={24}
    {...props}>
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="M11 0c4.843 0 8.77 3.855 8.77 8.612v5.976c.002.314.1.62.284.88l1.463 2.067c.496.54.622 1.316.322 1.981a1.874 1.874 0 0 1-1.707 1.094h-4.79l-.019.084c-.48 1.87-2.152 3.21-4.112 3.301l-.21.005c-2.05 0-3.826-1.37-4.324-3.306l-.02-.085-4.788.001a1.881 1.881 0 0 1-1.557-.824l-.08-.131-.07-.139a1.808 1.808 0 0 1 .31-1.967l1.475-2.082c.183-.258.282-.565.283-.88V8.613C2.23 3.855 6.157 0 11 0Zm2.21 20.61H8.79l.062.125A2.442 2.442 0 0 0 10.82 22l.18.007c.911-.003 1.733-.5 2.15-1.272l.06-.126ZM11 1.99c-3.728 0-6.75 2.965-6.75 6.622v5.976a3.49 3.49 0 0 1-.648 2.015L2.174 18.62h17.65L18.4 16.604a3.494 3.494 0 0 1-.64-1.776l-.009-.24V8.612c0-3.657-3.021-6.623-6.75-6.623Z"
    />
  </Svg>
);
export default NotificationSVG;
