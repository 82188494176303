import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { TimelineEventModel } from '@models/Timeline';
import { map } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import TimelineDevicePhoto from './TimelineDevicePhoto';

export const WaterRemoved = ({ event, active }: { event: TimelineEventModel; active: boolean }) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);

  const text = useMemo(() => {
    const weight = event.weights ? event.weights[0] : null;
    let total = 0;

    if (weight?.frames) {
      const totalInitial = map(weight.frames, f => f.current_weight - f.change).reduce(
        (c, f) => c + f,
      );
      const totalFinal = map(weight.frames, f => f.current_weight).reduce((c, f) => c + f);
      total = Math.round(totalFinal - totalInitial);
    }

    const tags = event?.tags || false;
    return t(`poseidon_water_removed${tags ? '_unkown_pet' : ''}`, {
      devicename: deviceName,
      ml: convertWithUnits(-total, false),
    });
  }, [event, deviceName, t]);

  return (
    <TimelineCard
      active={active}
      text={text}
      date={event.created_at}
      image={
        <TimelineDevicePhoto
          active={active}
          event={event}
        />
      }
    />
  );
};
