import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import React from 'react';
import { StyleSheet } from 'react-native';

export interface SetupInstructionHeaderProps {
  image: JSX.Element;
  title: string;
}

export const SetupInstructionHeader = ({ image, title }: SetupInstructionHeaderProps) => {
  return (
    <SpView>
      <SpView style={styles.imageWrap}>{image}</SpView>
      <SpView style={styles.titleWrap}>
        <SpText
          style={styles.title}
          size="xl"
          bold
          color="#000">
          {title}
        </SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  imageWrap: {
    alignItems: 'center',
    marginBottom: 16,
  },
  titleWrap: {
    alignItems: 'center',
    marginBottom: 32,
  },
  title: {
    textAlign: 'center',
    marginBottom: 10,
  },
});
