import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';

interface ISpSettingBadgeProps {
  title: string;
  value: string;
}

const SpSettingBadge = ({ title, value }: ISpSettingBadgeProps) => {
  return (
    <View
      {...testProperties(title, 'badge', 'SpSettingsBadge')}
      style={styles.container}>
      <Text
        {...testProperties(title, 'text', 'SpSettingsBadge')}
        style={styles.title}>
        {title}
      </Text>
      <Text
        {...testProperties(value, 'text', 'SpSettingsBadge')}
        style={styles.value}>
        {value || '-'}
      </Text>
    </View>
  );
};

export default SpSettingBadge;

const styles = StyleSheet.create({
  container: {
    width: 128,
    height: 72,
    backgroundColor: colors.secondary3.color,
    borderRadius: 16,
    paddingVertical: 12,
    alignItems: 'center',
    opacity: 0.8,
  },
  title: {
    color: colors.secondary.color,
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
    lineHeight: 20,
    opacity: 1,
  },
  value: {
    color: colors.greyText.color,
    fontSize: 20,
    fontFamily: 'Rubik',
    lineHeight: 24,
    marginTop: 4,
  },
});
