import React from 'react';
import SkeletonLoader from 'expo-skeleton-loader';
import { SpDeviceStatus } from '../SpDeviceStatus';

const SpDeviceCardSkeleton = () => {
  return (
    <SkeletonLoader.Container
      style={{
        width: '100%',
        borderRadius: 50,
        paddingHorizontal: 20,
        paddingVertical: 14,
        height: 80,
        flexDirection: 'row',
        backgroundColor: 'white',
        alignItems: 'center',
      }}>
      <SkeletonLoader.Item
        style={{
          borderRadius: 10,
          width: 40,
          height: 40,
          backgroundColor: '#E1E9EE',
        }}
      />
      <SkeletonLoader.Container style={{ marginLeft: 10 }}>
        <SkeletonLoader.Item
          style={{
            borderRadius: 10,
            width: 70,
            height: 20,
            backgroundColor: '#E1E9EE',
          }}
        />
        <SpDeviceStatus data />
      </SkeletonLoader.Container>
    </SkeletonLoader.Container>
  );
};

export default SpDeviceCardSkeleton;
