import { DeviceCatFlapCurfew, DevicePetDoorCurfew } from '@models/Device';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { TimeService } from '../../../../../services/TimeService';
import useBoundStore from '../../../../../store/store';

const useCurfewActive = (enabledCurfews: DevicePetDoorCurfew[] | DeviceCatFlapCurfew[]) => {
  const [lockUnlockTime, setLockUnlockTime] = useState(null);
  const activeHousehold = useBoundStore((s) => s.householdStore.activeHousehold);
  const { isBefore, dateTimeUtcToTimezoneFormatter, diffInMillis } = TimeService;
  const toLocal = useCallback(
    dateTimeUtcToTimezoneFormatter(
      'HH:mm',
      activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta'),
    ),
    [activeHousehold],
  );
  const currentTime = toLocal(DateTime.utc().toFormat('HH:mm'));
  const isCurfewActive = useMemo(() => {
    if (!enabledCurfews.length) return false;
    const curfewTimes = enabledCurfews
      .map((range: DevicePetDoorCurfew | DeviceCatFlapCurfew) => ({
        ...range,
        lock_time: toLocal(range.lock_time),
        unlock_time: toLocal(range.unlock_time),
      }))
      .sort(({ lock_time: first_lockTime }, { lock_time: second_lockTime }) =>
        diffInMillis(first_lockTime, second_lockTime),
      );
    setLockUnlockTime(
      `${curfewTimes[0].lock_time.toFormat('HH:mm')} ${curfewTimes[0].unlock_time.toFormat('HH:mm')}`,
    );
    for (const { lock_time: lockTime, unlock_time: unLockTime } of curfewTimes) {
      if (
        (isBefore(lockTime, currentTime) && isBefore(currentTime, unLockTime)) ||
        (isBefore(unLockTime, lockTime) &&
        isBefore(currentTime, unLockTime))
      ) {
        setLockUnlockTime(`${lockTime.toFormat('HH:mm')} ${unLockTime.toFormat('HH:mm')}`);
        return true;
      } else if (isBefore(currentTime, lockTime)) {
        setLockUnlockTime(`${lockTime.toFormat('HH:mm')} ${unLockTime.toFormat('HH:mm')}`);
        return false;
      }
    }
    return false;
  }, [enabledCurfews, activeHousehold, currentTime.toFormat('HH:mm')]);

  return { isCurfewActive, lockUnlockTime };
};

export default useCurfewActive;
