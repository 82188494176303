import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimelineEventModel } from '@models/Timeline';
import { TimelineCard } from '../TimelineCard';
import TimelineDevicePhoto from '../TimelineDevicePhoto';
import { getTimelineDeviceName } from '../../../hooks/useTimelineDeviceName';

type ITarringRequiredProps = {
  active: boolean;
  event: TimelineEventModel;
};

const TaringRequired = ({active, event}: ITarringRequiredProps) => {
  const { t } = useTranslation();
  const deviceName = getTimelineDeviceName(event?.devices);
  return (
    <TimelineCard
      active={active}
      date={event.created_at}
      image={
        <TimelineDevicePhoto
          active={active}
          event={event}
        />
      }
      text={t('taring_required', {
        devicename: deviceName,
      })}
    />
  );
}

export default TaringRequired;
