import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const FocusSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    {...props}>
    <G
      fill="#263A43"
      fillRule="evenodd">
      <Path d="M8.95 0H1.053C.47 0 0 .473 0 1.054v7.895C0 9.53.47 10 1.054 10c.58 0 1.05-.47 1.05-1.05V2.104H8.95A1.053 1.053 0 0 0 8.95 0M28.949 0h-7.895C20.47 0 20 .473 20 1.054c0 .581.47 1.05 1.054 1.05h6.84V8.95c0 .581.471 1.051 1.055 1.051.58 0 1.05-.47 1.05-1.05V1.053C30 .474 29.53 0 28.95 0M8.95 30H1.053A1.054 1.054 0 0 1 0 28.946v-7.895C0 20.47.47 20 1.054 20c.58 0 1.05.47 1.05 1.05v6.845H8.95a1.053 1.053 0 0 1 0 2.105M28.949 30h-7.895A1.054 1.054 0 0 1 20 28.946c0-.581.47-1.05 1.054-1.05h6.84V21.05c0-.581.471-1.051 1.055-1.051.58 0 1.05.47 1.05 1.05v7.896c0 .58-.47 1.054-1.05 1.054" />
    </G>
  </Svg>
);
export default FocusSVG;
