import WiFiDeviceAPI from '@api/WifiDeviceConnector';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet } from 'react-native';
import StepFormCreator from 'src/components/StepFormCreator';
import useBoundStore from 'src/store/store';

import { SpSuccessTick } from '../../../../../../components/SpSuccessTick';
import { AnalyticsService } from '../../../../../../services/AnalyticsService';
import { openModal as openModalBox } from '../../../../../../services/ModalBox';
import HowToFactoryResetYourCDB from '../../../../../Flows/DogBowl/HowToFactoryResetYourCDB';
import { InstructionStep3 } from '../../../../../Flows/DogBowl/InstructionStep3';
import PairingFailed from '../../../../../Flows/DogBowl/PairingFailed';
import PairingToHouseholdStep from '../../../../../Flows/DogBowl/PairingToHouseholdStep';
import ThereSeemsToBeAProblem from '../../../../../Flows/DogBowl/ThereSeemsToBeAProblem';
import ThereSeemsToBeAProblemContactCS from '../../../../../Flows/DogBowl/ThereSeemsToBeAProblemContactCS';
import WeHaveNotManageToConnectToCDB from '../../../../../Flows/DogBowl/WeHaveNotManageToConnectToCDB';
import WiFiConnectToDevice from '../../../../../Flows/DogBowl/WiFiConnectToDevice';
import WiFiPasswordInput from '../../../../../Flows/DogBowl/WiFiPasswordInput';
import WiFiSSIDSelect from '../../../../../Flows/DogBowl/WiFiSSIDSelect';
import { navigationRef } from '../../../../../RootNavigation';
import { RootStackParamList } from '../../../../../index';
import { DeviceStackParamList } from '../../index';
import WiFiResetInstructions from '../../../../../Flows/DogBowl/WiFiResetInstructions';

export enum stepsEnums {
  WiFiReset = 1,
  InstructionStep3,
  WiFiConnectToDevice,
  WiFiSSIDSelect,
  WiFiPasswordInput,
  PairingToHouseholdStep,
  PairingFailed,
  ThereSeemsToBeAProblem,
  ThereSeemsToBeAProblemContactCS,
  WeHaveNotManageToConnectToCDB,
  HowToFactoryResetYourCDB,
  Paired,
}

enum dogBowlStillFlashingOptions {
  no_its_not = 0,
  yes_it_is = 1,
}

const DeviceWiFiSetup = ({ route }: StackScreenProps<DeviceStackParamList, 'DeviceWiFiSetup'>) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { id } = route.params;
  const {
    setSettingUpWiFiDevice,
    loading,
    error,
    success,
    stopSearch,
    startScanDevice,
    deviceSSIDS,
    targetNetworkID,
    targetNetworkPassword,
    setTargetNetworkPassword,
    startCheckDeviceStatus,
    connectDeviceToWifi,
    WiFiDeviceConnectedToServer,
    connectedToWiFiDevice,
    startSearchForProduct,
    setTimeoutForWiFiProduct,
    pairedDeviceDeviceID,
    WiFiDeviceConnectedToNetwork,
    reset,
  } = useBoundStore(s => s.WiFiDeviceStore);
  const [searchForDeviceAttempts, setSearchForDeviceAttempts] = useState(0);
  const [submitValues, setSubmitValues] = useState<any>();
  const [step, setStep] = useState<stepsEnums>(1);
  const [returnToStep, setReturnToStep] = useState<stepsEnums>(1);

  const [bowlFlashingOrange, setBowlFlashingOrange] = useState<dogBowlStillFlashingOptions>(null);
  const [retryConnectToDevice, setRetryConnectToDevice] = useState<boolean>(false);
  const [wifiConnectToDeviceTimerEnds, setWifiConnectToDeviceTimerEnds] = useState(false);

  useEffect(() => {
    if (step !== stepsEnums.WiFiConnectToDevice) return;
    const timer = setTimeout(() => {
      setWifiConnectToDeviceTimerEnds(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
      setWifiConnectToDeviceTimerEnds(false);
    };
  }, [step]);

  useEffect(() => {
    setSettingUpWiFiDevice(true);
    return () => setSettingUpWiFiDevice(false);
  }, [setSettingUpWiFiDevice]);

  useEffect(() => {
    return () => {
      stopSearch(true);
      reset();
    };
  }, []);

  useEffect(() => {
    if (step === stepsEnums.WiFiConnectToDevice) {
      if (connectedToWiFiDevice) {
        AnalyticsService.logEvent('DeviceWiFiSetup - useEffect - WiFiConnectToDevice');
        console.log('connected to wifi device, will skip searching for device');
        return;
      }
      // increment search attempts and start searching
      if ((!loading && searchForDeviceAttempts < 3) || retryConnectToDevice) {
        if (error) {
          console.log(
            'error connecting to device, will ask user if they want to try again',
            searchForDeviceAttempts,
          );
          AnalyticsService.logEvent(
            'DeviceWiFiSetup - useEffect - searchForDeviceAttempts - ThereSeemsToBeAProblemContactCS',
          );
          setReturnToStep(stepsEnums.InstructionStep3);
          setStep(stepsEnums.ThereSeemsToBeAProblem);
          stopSearch();
          return;
        }
        console.log('no error so will search for devices', searchForDeviceAttempts);
        setSearchForDeviceAttempts(searchForDeviceAttempts + 1);
        setTimeoutForWiFiProduct(14000);
        startSearchForProduct('CDB');
        setRetryConnectToDevice(false);
        AnalyticsService.logEvent(
          'DeviceWiFiSetup - useEffect - searchForDeviceAttempts - searchAgain',
        );
      }
      if (!loading && searchForDeviceAttempts >= 3 && error) {
        console.log('error connecting to device, wont try again');
        setStep(stepsEnums.ThereSeemsToBeAProblemContactCS);
        AnalyticsService.logEvent(
          'DeviceWiFiSetup - useEffect - searchForDeviceAttempts >=3 - ThereSeemsToBeAProblem',
        );
        stopSearch();
      }
    }
  }, [
    step,
    loading,
    searchForDeviceAttempts,
    loading,
    connectedToWiFiDevice,
    retryConnectToDevice,
  ]);
  useEffect(() => {
    if (step === stepsEnums.WiFiSSIDSelect) {
      startScanDevice();
      AnalyticsService.logEvent('DeviceWiFiSetup - useEffect - WiFiSSIDSelect');
    }
  }, [step]);

  useEffect(() => {
    if (step === stepsEnums.WiFiPasswordInput && !loading && !error && success) {
      AnalyticsService.logEvent('DeviceWiFiSetup - useEffect - WiFiPasswordInput - success');
      setTimeout(() => {
        setReturnToStep(stepsEnums.WiFiPasswordInput);
        startCheckDeviceStatus();
        setStep(stepsEnums.PairingToHouseholdStep);
      }, 300);
    }
  }, [step, setStep, success, error, loading]);

  useEffect(() => {
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !pairedDeviceDeviceID &&
      !loading &&
      !error &&
      WiFiDeviceConnectedToServer
    ) {
      console.log('wifi change success');
      WiFiDeviceAPI.closeConnection().then(() => {
        setTimeout(() => {
          setStep(stepsEnums.Paired);
        }, 6000);
      });
    }
    // We stopped loading, there was an error before we could connect to wifi so its probably a problem with the SSID or Password
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !loading &&
      error &&
      !WiFiDeviceConnectedToNetwork
    ) {
      // error was a timeout, probably issue connecting to dog bowl
      if (error === 'ECONNABORTED') {
        AnalyticsService.logEvent(
          'DeviceWiFiSetup - useEffect - PairingToHouseholdStep - error - ECONNABORTED',
        );
        setStep(stepsEnums.PairingFailed);
      } else {
        AnalyticsService.logEvent('DeviceWiFiSetup - useEffect - PairingToHouseholdStep - error');
        setStep(stepsEnums.WiFiPasswordInput);
      }
    }
    // We stopped loading, there was an error after we connected to wifi so its probably a problem with the server
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !loading &&
      error &&
      WiFiDeviceConnectedToNetwork
    ) {
      setStep(stepsEnums.PairingFailed);
      AnalyticsService.logEvent(
        'DeviceWiFiSetup - useEffect - PairingToHouseholdStep - error - WiFiDeviceConnectedToNetwork',
      );
    }
    // We stopped loading, there was an error before we connected to wifi so its probably a problem with the creds or network
    if (
      step === stepsEnums.PairingToHouseholdStep &&
      !loading &&
      !error &&
      !WiFiDeviceConnectedToNetwork
    ) {
      console.log('error connecting to wifi, will go to step 20');
      AnalyticsService.logEvent(
        'DeviceWiFiSetup - useEffect - PairingToHouseholdStep - error - !WiFiDeviceConnectedToNetwork',
      );
      setStep(stepsEnums.PairingFailed);
    }
  }, [
    step,
    setStep,
    pairedDeviceDeviceID,
    error,
    loading,
    WiFiDeviceConnectedToNetwork,
    WiFiDeviceConnectedToServer,
  ]);

  const onOpenGetHelpModal = useCallback(() => {
    AnalyticsService.logEvent('DeviceWiFiSetup - onOpenGetHelpModal', { step });
    switch (step) {
      case stepsEnums.WiFiReset:
        rootNavigation.navigate('FlowNavigation', { screen: 'CDBHelpPairingMode' });
        break;
      case stepsEnums.InstructionStep3:
        rootNavigation.navigate('FlowNavigation', { screen: 'CDBHelpWifiNetwork' });
        break;
      case stepsEnums.WiFiSSIDSelect:
        rootNavigation.navigate('FlowNavigation', { screen: 'HavingIssuesFindingWiFi' });
        break;
      case stepsEnums.PairingFailed:
        rootNavigation.navigate('FlowNavigation', { screen: 'CDBHelpFailedPairing' });
        break;
      case stepsEnums.ThereSeemsToBeAProblemContactCS:
        Linking.openURL('https://www.surepetcare.com/support');
        break;
      default:
        Linking.openURL('https://www.surepetcare.com/support');
    }
    return false;
  }, [openModalBox, step]);

  const headerProps = useMemo(
    () => ({
      withLeaveButton: true,
      withRightButton: true,
      rightButtonText: t('get_help'),
      rightButtonAction: () => {
        onOpenGetHelpModal();
      },
    }),
    [t, onOpenGetHelpModal],
  );

  const stopSearchLeaveAction = useCallback(() => {
    stopSearch();
    navigation.goBack();
  }, [navigation, stopSearch]);
  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('cdb_device_wifi_setup_title')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      safeAreaView={false}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        [stepsEnums.WiFiReset]: {
          ui: WiFiResetInstructions,
          customHeaderProps: headerProps,
          backBottomButton: true,
        },
        [stepsEnums.InstructionStep3]: {
          ui: InstructionStep3,
          props: {
            setStep,
            nextStep: stepsEnums.WiFiConnectToDevice,
            screenActive: step === stepsEnums.InstructionStep3,
          },
          customHeaderProps: headerProps,
          hideButton: true,
          backBottomButton: true,
          hideBackBottomButton: false,
        },
        [stepsEnums.WiFiConnectToDevice]: {
          ui: WiFiConnectToDevice({
            connectedToWiFiDevice: connectedToWiFiDevice && wifiConnectToDeviceTimerEnds,
            setRetryConnectToDevice,
          }),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            withArrowBack: false,
            withTitle: false,
            withCross: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          backBottomButton: true,
          hideButton: !connectedToWiFiDevice,
          interceptor: () => {
            if (connectedToWiFiDevice) {
              return true;
            }
            // startSearch();
            return false;
          },
        },
        [stepsEnums.WiFiSSIDSelect]: {
          ui: WiFiSSIDSelect({
            deviceSSIDS,
            targetNetworkID,
            loading,
            currentStep: step === stepsEnums.WiFiSSIDSelect,
          }),
          customHeaderProps: {
            ...headerProps,
            leaveButtonAction: stopSearchLeaveAction,
          },
          buttonDisabled: deviceSSIDS.length === 0 || targetNetworkID === -99,
          interceptor: () => {
            if (deviceSSIDS.length > 0 || targetNetworkID === 999) {
              AnalyticsService.logEvent('DeviceWiFiSetup - WiFiSSIDSelect - interceptor - true');
              // stopSearch();
              return true;
            }
            AnalyticsService.logEvent('DeviceWiFiSetup - WiFiSSIDSelect - interceptor - false');
            // startSearch();
            return false;
          },
          handleAndroidBackPress: () => {
            const routeName = navigationRef.current.getCurrentRoute()?.name;
            if (routeName === 'SelectSSID') {
              navigationRef.goBack();
              return true;
            }
            if (routeName === 'DeviceWiFiSetup') {
              stopSearch();
              setStep(stepsEnums.WiFiConnectToDevice);
              return true;
            }
            return 'goBack';
          },
        },
        [stepsEnums.WiFiPasswordInput]: {
          ui: WiFiPasswordInput({
            SelectedWiFiSSID: deviceSSIDS[targetNetworkID]?.ssid || '',
            setTargetNetworkPassword,
            targetNetworkPassword,
            loading,
            error: error ? t(String(error)) : null,
          }),
          customHeaderProps: {
            ...headerProps,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          backBottomButton: true,
          hideButton: loading,
          buttonDisabled: targetNetworkPassword?.length < 5,
          interceptor: () => {
            if (!targetNetworkPassword) {
              AnalyticsService.logEvent(
                'DeviceWiFiSetup - WiFiPasswordInput - interceptor - false',
              );
              return false;
            }
            console.log('Telling CDB to connectDeviceToWifi from the interceptor');
            AnalyticsService.logEvent(
              'DeviceWiFiSetup - WiFiPasswordInput - interceptor - connectDeviceToWifi',
            );
            connectDeviceToWifi();
            return false;
          },
          handleAndroidBackPress: () => {
            const routeName = navigationRef.current.getCurrentRoute()?.name;
            console.log(routeName);
            if (routeName === 'SelectSSID') {
              navigation.goBack();
              return true;
            }
            if (routeName === 'DeviceWiFiSetup') {
              stopSearch();
              setStep(stepsEnums.WiFiSSIDSelect);
              return true;
            }
            return 'goBack';
          },
        },
        [stepsEnums.PairingToHouseholdStep]: {
          // @ts-ignore
          ui: PairingToHouseholdStep({
            isLoading: loading,
          }),
          hideButton: true,
          customHeaderProps: {
            withLeaveButton: false,
            goBackFunc: () => {
              setStep(stepsEnums.WiFiPasswordInput);
              stopSearch();
            },
          },
        },
        [stepsEnums.Paired]: {
          ui: SpSuccessTick,
          props: { text: t('cdb_wifi_update_success') },
          invisibleHeader: true,
          customStyleProgressHeader: {
            marginVertical: '10%',
          },
          interceptor: () => {
            navigation.navigate('DeviceSetting', { id });
            return true;
          },
        },
        [stepsEnums.PairingFailed]: {
          ui: PairingFailed,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          interceptor: () => {
            // needs logic here to work out the state of the dogbowl, check if we still have a connection
            setStep(returnToStep);
            stopSearch();
            return false;
          },
        },

        [stepsEnums.ThereSeemsToBeAProblem]: {
          ui: ThereSeemsToBeAProblem,
          props: {
            bowlFlashingOrange,
            setIsFlashingOrange: setBowlFlashingOrange,
          },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            withRightButton: false,
            leaveButtonAction: stopSearchLeaveAction,
          },
          buttonDisabled: bowlFlashingOrange === null,
          interceptor: () => {
            setStep(bowlFlashingOrange ? returnToStep : stepsEnums.WeHaveNotManageToConnectToCDB);
            setBowlFlashingOrange(null);
            return false;
          },
        },

        [stepsEnums.ThereSeemsToBeAProblemContactCS]: {
          ui: ThereSeemsToBeAProblemContactCS,
          props: { getHelpAction: onOpenGetHelpModal },
          buttonText: t('setup_cdb_failed_to_pair_button'),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigation.goBack,
          },
          interceptor: () => {
            setStep(stepsEnums.InstructionStep3);
            setBowlFlashingOrange(null);
            setSearchForDeviceAttempts(0);
            return false;
          },
        },
        [stepsEnums.WeHaveNotManageToConnectToCDB]: {
          ui: WeHaveNotManageToConnectToCDB,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigation.goBack,
          },
          hideProgressHeader: true,
          buttonText: t('try_again'),
          interceptor: () => {
            setStep(stepsEnums.HowToFactoryResetYourCDB);
            return false;
          },
        },
        [stepsEnums.HowToFactoryResetYourCDB]: {
          ui: HowToFactoryResetYourCDB,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigation.goBack,
          },
          hideProgressHeader: true,
          interceptor: () => {
            setStep(stepsEnums.WiFiReset);
            return false;
          },
        },
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  stepWrapper: {
    marginTop: '40%',
  },
  formWrapper: { paddingHorizontal: 24 },
  center: {
    alignItems: 'center',
  },
});

export default DeviceWiFiSetup;
