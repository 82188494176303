import { SpView } from 'src/components/SpView';
import colors from '@styles/colors';
import React, { ReactElement } from 'react';
import { Image, ImageSourcePropType, ImageStyle, StyleSheet } from 'react-native';

interface TimelineCardImageProps {
  image: ImageSourcePropType;
  active: boolean;
}

export const TimelineCardImage = ({ image, active }: TimelineCardImageProps) => {
  const width = active ? 56 : 48;
  const height = active ? 56 : 48;
  const borderRadius = width / 2;
  const sizeStyle: ImageStyle = {
    minWidth: width,
    minHeight: height,
    width,
    height,
  };

  return (
    <SpView style={[styles.container, sizeStyle, { borderRadius }]}>
      <Image
        style={[
          sizeStyle,
          active
            ? {
                borderColor: colors.green.color,
                borderWidth: 2,
                borderRadius,
              }
            : null,
        ]}
        source={image}
        resizeMode="contain"
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#dde0e2',
  },
});
