import { StyleSheet, View } from 'react-native';
import React from 'react';
import colors from '@styles/colors';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { testProperties } from '@utils/testProperties';

interface SpSentIconProps {
  size?: number;
  iconSize?: number;
}

const SpTimesIcon = ({ size = 56, iconSize = 36 }: SpSentIconProps) => {
  return (
    <View
      {...testProperties('success sent', 'icon')}
      style={[styles.circle, { width: size, height: size }]}>
      <FontAwesomeIcon
        color={colors.white.color}
        size={iconSize}
        icon={faTimes}
      />
    </View>
  );
};

export default SpTimesIcon;

const styles = StyleSheet.create({
  circle: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    backgroundColor: colors.errorRed.color,
  },
});
