import { testProperties } from '@utils/testProperties';
import React from 'react';
import { ActivityIndicator, ColorValue, StyleSheet, View } from 'react-native';

interface Props {
  color?: ColorValue;
  size?: 'small' | 'large';
}

const LoadingSpinner = ({ color = '#999999', size = 'large' }: Props): JSX.Element => {
  return (
    <View
      {...testProperties('loading spinner', 'indicator')}
      style={styles.container}
      accessibilityRole="progressbar">
      <ActivityIndicator
        size={size}
        color={color}
      />
    </View>
  );
};

const height = 36;

const styles = StyleSheet.create({
  container: {
    width: height,
    height,
    justifyContent: 'center',
    alignItems: 'center',
  },
  small: {
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  large: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },

  progress: {
    width: '100%',
    height: '100%',
    borderBottomColor: 'transparent',
    borderWidth: 3,
  },
});

export default LoadingSpinner;
