import { SpView } from 'src/components/SpView';
import { useCustomToast } from '@hooks/useToast';
import ResetPasswordForm from 'src/components/ResetPasswordForm';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import { RootStackParamList } from '../../../index';
import { RemoveAccountParamList } from '../index';

type Props = NativeStackScreenProps<RemoveAccountParamList, 'ResetPassword'>;

const ResetPassword = ({ route }: Props) => {
  const toast = useCustomToast();
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const submitSuccessAction = useCallback(() => {
    const navObj = {
      parentNavigatorName: 'LoginNavigation',
      screenName: 'Login',
      makeLogout: true,
    };
    navigation.navigate('SuccessScreen', {
      title: t('email_sent'),
      msg: t('email_sent_msg'),
      withButton: true,
      withHeader: true,
      buttonText: t('continue'),
      buttonNavObject: navObj,
      headerNavObject: navObj,
    });
  }, [route.params.selectedHouseholds]);

  const submitErrorAction = useCallback(() => {
    toast.show({
      description: t('email_address_error'),
      isError: true,
    });
  }, [t]);

  return (
    <SpView
      paddingHorizontal={19}
      paddingBottom={34}
      flex={1}
      backgroundColor={colors.white.color}>
      <ResetPasswordForm
        onSubmitSuccess={submitSuccessAction}
        onSubmitError={submitErrorAction}
      />
    </SpView>
  );
};

export default ResetPassword;
