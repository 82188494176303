import * as React from 'react';
import { G, Path, Svg } from 'react-native-svg';

const NotificationFilledSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}>
    <G
      fill="#263A43"
      fillRule="nonzero">
      <Path d="M7.997 0C5.16 0 2.86 2.442 2.86 5.455v4.254l-1.136 1.818a1.563 1.563 0 0 0-.058 1.51c.241.478.709.777 1.218.781h10.223a1.388 1.388 0 0 0 1.227-.783 1.563 1.563 0 0 0-.067-1.519L13.132 9.71V5.455c0-3.013-2.3-5.455-5.135-5.455ZM7.997 16c.87-.001 1.644-.584 1.934-1.455H6.062C6.352 15.416 7.127 16 7.997 16Z" />
    </G>
  </Svg>
);
export default NotificationFilledSVG;
