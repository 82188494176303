import { BottomSheetBackgroundProps } from '@gorhom/bottom-sheet';
import { BottomSheetBackdropProps } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetBackdrop';
import { BottomSheetHandleProps } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetHandle';
import React, { PropsWithChildren } from 'react';
import { Dimensions, StyleProp, StyleSheet, TouchableOpacity, View } from 'react-native';

export interface CustomBottomSheetModalProps {
  opened: boolean;
  style?: StyleProp<unknown>;
  index?: number;
  handleComponent?: React.FC<BottomSheetHandleProps> | null;
  backdropComponent?: React.FC<BottomSheetBackdropProps> | null;
  snapPoints: (string | number)[];
  onDismiss?: () => void;
  backgroundComponent?: React.FC<BottomSheetBackgroundProps>;
  inScrollView?: boolean;
  scrollViewStyle?: any;
}

const CustomBottomSheetModal = ({
  children,
  ...props
}: PropsWithChildren<CustomBottomSheetModalProps>) => {
  return props.opened ? (
    <TouchableOpacity
      accessible={false}
      onPress={props.onDismiss}
      style={styles.background}
    >
      <View
        accessible={false}
        style={styles.view}
      >
        {children}
      </View>
    </TouchableOpacity>
  ) : null;
};

export default CustomBottomSheetModal;
const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    // the commented out may be needed to make it work on web
    // top:0,
    // bottom: 0,
    // left:0,
    height: Dimensions.get('window').height,
    width: '100%',
    backgroundColor: 'transparent',
    // zIndex: 100,
  },
  view: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    // height: '250px',
    padding: 10,
  },
});
