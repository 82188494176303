import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const PortionSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}>
    <G
      fill="#263A43"
      fillRule="nonzero">
      <Path d="M12.627 2.823c.982-.506 1.779-1.234 2.305-2.103.09-.148.09-.33.004-.48a.489.489 0 0 0-.423-.24H1.487a.489.489 0 0 0-.423.24.468.468 0 0 0 .004.48c.526.87 1.323 1.597 2.305 2.103a7.006 7.006 0 0 0 2.774.752V4.8h-2.3c-.86 0-1.588.63-1.693 1.466l-.983 7.862c-.06.474.092.951.416 1.309.323.358.788.563 1.276.563h10.274c.488 0 .953-.205 1.277-.563.323-.358.474-.835.415-1.31l-.983-7.86c-.104-.837-.832-1.467-1.692-1.467h-2.3V3.576c.979-.061 1.93-.318 2.773-.753Zm-.638 7.577c0 2.154-1.79 3.906-3.989 3.906-2.2 0-3.989-1.752-3.989-3.906s1.79-3.906 3.99-3.906c2.199 0 3.988 1.752 3.988 3.906Z" />
      <Path d="M8 7.447c-1.663 0-3.015 1.325-3.015 2.953S6.337 13.353 8 13.353s3.016-1.325 3.016-2.953S9.663 7.447 8 7.447Zm.344 3.29-1.172 1.148a.49.49 0 0 1-.688 0 .47.47 0 0 1 0-.674l1.172-1.148a.494.494 0 0 1 .688 0 .47.47 0 0 1 0 .674Z" />
    </G>
  </Svg>
);
export default PortionSVG;
