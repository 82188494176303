import { ConsumptionInsight } from '@models/ConsumptionInsight';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { SpView } from '../../../../../../../components/SpView';
import { SpText } from '../../../../../../../components/SpText/SpText';
import CustomBottomSheetModal from '../../../../../../../components/CustomBottomSheetModal';
import React, { StyleSheet, View } from 'react-native';
import { useMemo } from 'react';
import { TimeService } from '../../../../../../../services/TimeService';
import InsightAlertShort from '../InsightAlertShort';
import { WithoutTimeDateFormat } from '@constants/DateFormat';
import InfoYellowSVG from '../../../../../../../components/SvgIcons/InfoYellowSVG';

interface InsightAlertsBottomSheetProps {
  habits: ConsumptionInsight[];
  alerts: ConsumptionInsight[];
  opened: boolean;
  onDismiss: () => void;
  onSelect: (date: string) => void;
}

interface InsightWithAlertStatus extends ConsumptionInsight {
  isAlert?: boolean;
}

export const InsightAlertsBottomSheet = ({
  alerts,
  habits,
  opened,
  onDismiss,
  onSelect,
}: InsightAlertsBottomSheetProps) => {
  const snapPoints = useMemo(() => ['40%'], []);
  const notifications = useMemo((): InsightWithAlertStatus[] => {
    const res: Record<string, InsightWithAlertStatus> = {};

    alerts.forEach(alert => {
      const date = TimeService.toLocal(alert.last_drinking_event_utc).toFormat(
        WithoutTimeDateFormat,
      );
      res[date] = { ...alert, isAlert: true };
    });

    habits.forEach(habit => {
      const date = TimeService.toLocal(habit.last_drinking_event_utc).toFormat(
        WithoutTimeDateFormat,
      );

      if (!res[date]) {
        res[date] = habit;
      }
    });

    return Object.values(res).sort(
      (a, b) =>
        TimeService.toLocal(b.last_drinking_event_utc).toMillis() -
        TimeService.toLocal(a.last_drinking_event_utc).toMillis(),
    );
  }, [alerts, habits]);

  const onSelectAlert = (date: string) => {
    onSelect(date);
    onDismiss();
  };

  return (
    <CustomBottomSheetModal
      opened={opened}
      backdropComponent={BottomSheetBackdrop}
      index={0}
      snapPoints={snapPoints}
      inScrollView
      onDismiss={onDismiss}
    >
      <SpView style={styles.content}>
        <View style={styles.title}>
          <View style={styles.alertsIcon}>
            <InfoYellowSVG
              width={24}
              height={24}
              viewBox="0 0 32 32"
              color="#dedede"
            />
          </View>
          <SpText
            fontFamily="Rubik_Medium"
            size="xl"
            align="center"
          >
            Alerts
          </SpText>
        </View>
        <SpView style={styles.alerts}>
          {notifications.map(insight => (
            <InsightAlertShort
              insight={insight}
              key={insight.last_drinking_event_utc}
              isAlert={insight.isAlert}
              onSelect={onSelectAlert}
              fromDetailsAlert={opened}
            />
          ))}
        </SpView>
      </SpView>
    </CustomBottomSheetModal>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 16,
    paddingBottom: 34,
    paddingTop: 14,
    minHeight: 234,
  },
  alerts: {
    marginTop: 10,
  },
  alert: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 14,
    borderColor: 'rgba(38, 58, 67, 0.4)',
    paddingHorizontal: 20,
    paddingVertical: 20,
    marginTop: 24,
  },
  date: {
    marginRight: 20,
  },
  alertsIcon: {
    marginRight: 10,
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
