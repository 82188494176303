import {
  ConsumptionAlertOutcome,
  ConsumptionInsight,
  ConsumptionInsightWithType,
} from '@models/ConsumptionInsight';
import { useMemo } from 'react';
import { TimeService } from '../../../../../../services/TimeService';
import { WithoutTimeDateFormat } from '@constants/DateFormat';
import { ConsumptionAlertsWarningOutcomes } from '@constants/ConsumptionInsightTypes';

interface TodayPetInsightProps {
  habits: ConsumptionInsight[];
  alerts: ConsumptionInsight<ConsumptionAlertOutcome>[];
}

export const useTodayPetInsight = ({ habits, alerts }: TodayPetInsightProps) => {
  return useMemo((): ConsumptionInsightWithType => {
    const today = TimeService.toLocal().toFormat(WithoutTimeDateFormat);
    const habit = (habits || []).find(h => {
      return (
        TimeService.toLocal(h.last_drinking_event_utc).toFormat(WithoutTimeDateFormat) === today
      );
    });
    const alert = (alerts || [])
      .filter(a => ConsumptionAlertsWarningOutcomes.includes(a.outcome))
      .find(a => {
        return (
          TimeService.toLocal(a.last_drinking_event_utc).toFormat(WithoutTimeDateFormat) === today
        );
      });

    return {
      insight: (alert || habit) as ConsumptionInsight,
      isAlert: !!alert,
    };
  }, [alerts, habits]);
};
