import { Image, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import text from '@styles/text';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';

interface ThereSeemsToBeAProblemContactCSProps {
  getHelpAction: () => void;
}

export const ThereSeemsToBeAProblemContactCS = ({
  getHelpAction,
}: ThereSeemsToBeAProblemContactCSProps) => {
  const { t } = useTranslation();

  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={{ flexDirection: 'column' }}>
        <SpView style={[{ flex: 1 }, styles.center]}>
          <Image
            style={{ maxWidth: 60 }}
            source={AppImages.cdbFail}
            resizeMode="contain"
            {...testProperties('cdbPairingHousehold', 'image')}
          />
          <SpText style={[text.title, styles.title]}>
            {t('there_seems_to_have_been_a_problem')}
          </SpText>
          <SpText style={[text.body, styles.text]}>
            {t('we_have_noticed_you_are_struggling')}
          </SpText>
          <SpText style={[text.body, styles.text]}>{t('check_out_our_support_page')}</SpText>
          <SpRoundedHeaderButton
            stylesForContainer={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
              width: '100%',
            }}
            w="100%"
            backgroundColor={colors.greyText.color}
            textStyles={{
              fontSize: 16,
              fontFamily: 'Rubik_Medium',
              color: colors.white.color,
            }}
            onPress={() => getHelpAction()}
            title={
              // t('wifi_settings')
              'Contact Support'
            }
          />
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: '10%',
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
  title: {
    marginBottom: '5%',
  },
  text: {
    marginBottom: '3%',
  },
});

export default ThereSeemsToBeAProblemContactCS;
