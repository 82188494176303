import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const InfoYellowSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    {...props}>
    <G>
      <Path
        d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0Z"
        fill="#FF9E63"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 25.6C14.8267 25.6 13.8667 24.64 13.8667 23.4667C13.8667 22.2933 14.8267 21.3333 16 21.3333C17.1734 21.3333 18.1334 22.2933 18.1334 23.4667C18.1334 24.64 17.1734 25.6 16 25.6Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4426 9.05599L17.7279 17.6107C17.6533 18.5067 16.9066 19.2 15.9999 19.2C15.0933 19.2 14.3466 18.5067 14.2719 17.6107L13.5573 9.05599C13.4399 7.62666 14.5599 6.39999 15.9999 6.39999C17.3653 6.39999 18.4533 7.50933 18.4533 8.85333C18.4533 8.91733 18.4533 8.99199 18.4426 9.05599Z"
        fill="white"
      />
    </G>
  </Svg>
);
export default InfoYellowSVG;
