import { Alert, AlertButton, AlertOptions, Platform } from 'react-native';

const alertPolyfill = (
  title: string,
  description: string,
  options?: AlertButton[],
  extra?: AlertOptions,
) => {
  // eslint-disable-next-line no-alert
  const result = window.confirm([title, description].filter(Boolean).join('\n'));
  if (!options) {
    return;
  }
  if (result) {
    const confirmOption = options.find(({ style }) => style !== 'cancel');
    if (confirmOption) {
      confirmOption.onPress();
    }
  } else {
    const cancelOption = options.find(({ style }) => style === 'cancel');
    if (cancelOption) {
      cancelOption.onPress?.();
    }
  }
};

const SpAlert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export default SpAlert;
