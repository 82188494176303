
import { RemoteConfigKeys } from '@constants/RemoteConfig';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { useRemoteConfigBooleanValue } from '@hooks/useRemoteConfigBooleanValue';
import { format } from 'date-fns';

import * as React from 'react';
import { SpView } from 'src/components/SpView';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { SpCenter } from 'src/components/SpCenter';
import { TimeService } from 'src/services/TimeService';
import { SpText } from '../../../components/SpText/SpText';
import { testProperties } from '@utils/testProperties';
import { SpHStack } from 'src/components/SpHStack';
import i18n from '@utils/i18n';


import useBoundStore from '../../../store/store';
import CarouselViewSVG from 'src/components/SvgIcons/CarouselViewSVG';
import GridViewSVG from 'src/components/SvgIcons/GridViewSVG';

export const DateHeader = () => {
  const dateNow = TimeService.parseDate(new Date()).toLocal().setLocale(i18n.language).toFormat('EEE d MMM');

  const { setGridView, gridView } = useBoundStore((state) => state.accountStore);

  const onGridViewPress = () => {
    setGridView(true);
  };
  const onCarouselViewPress = () => {
    setGridView(false);
  };
  const pets = usePetsByHousehold();
  const multiPetView = useRemoteConfigBooleanValue(RemoteConfigKeys.multiPetView);

  return (
    <SpView style={styles.container}>
      <SpView style={styles.center}>
        <SpHStack
          space={4}
          style={
            pets.length > 1 && multiPetView
              ? { justifyContent: 'space-between' }
              : { justifyContent: 'center' }
          }
          alignItems="center"
          marginVertical={5}>
          <SpView
            style={
              pets.length > 1 && multiPetView
                ? { paddingLeft: 20, justifyContent: 'center' }
                : { justifyContent: 'center' }
            }>
            <SpText {...testProperties('today date', 'text')} style={styles.text}>
              {dateNow}
            </SpText>
          </SpView>
          {pets.length > 1 && multiPetView ? (
            <SpView justifyContent="flex-end" style={{ paddingRight: 20 }}>
              <SpHStack space={4} alignItems="center">
                <TouchableOpacity onPress={onCarouselViewPress}>
                  <CarouselViewSVG active={!gridView} />
                </TouchableOpacity>
                <TouchableOpacity onPress={onGridViewPress}>
                  <GridViewSVG active={gridView} />
                </TouchableOpacity>
              </SpHStack>
            </SpView>
          ) : null}
        </SpHStack>
      </SpView>
    </SpView>
  );
};
const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: '#263a43',
    height: '100%',
  },
  center: {
    height: '100%',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
  },
});
