import { SpText } from './SpText/SpText';
import { SpView } from './SpView';
import useAdaptiveFontSize from '@hooks/useAdaptiveFontSize';
import colors from '@styles/colors';
import React from 'react';
import { TextStyle, Text, Platform } from 'react-native';

type SpTransformStringToMultipleTextsProps = {
  data: string[];
  offsetBetween?: number;
  mainStyle?: TextStyle;
  prefixStyle?: TextStyle;
  ignoreOpacity?: boolean;
};

const SpTransformStringToMultipleTexts = ({
  data,
  offsetBetween = 5,
  ignoreOpacity = Platform.OS === 'web',
  mainStyle = {
    fontSize: 28,
    color: colors.greyText.color,
    fontFamily: 'Rubik_SemiBold',
  },
  prefixStyle = {
    fontSize: 16,
    color: colors.greyText.color,
    fontFamily: 'Rubik_SemiBold',
  },
}: SpTransformStringToMultipleTextsProps) => {
  const filteredData = data.filter(Boolean);
  const [sizes, layoutHandler, opacityStyle] = useAdaptiveFontSize(
    {
      main: mainStyle.fontSize,
      prefix: prefixStyle.fontSize,
    },
    [mainStyle.fontSize, prefixStyle.fontSize],
  );

  return (
    <Text
      onTextLayout={layoutHandler}
      style={!ignoreOpacity && opacityStyle}>
      {filteredData.map((item, index) => {
        if (Number.isNaN(Number(item))) {
          return (
            <React.Fragment key={`${item}${index}prefix`}>
              <SpView width={offsetBetween} />
              <SpText style={[prefixStyle, { fontSize: sizes.prefix, lineHeight: sizes.main }]}>
                {item}
              </SpText>
              {index !== filteredData.length - 1 && <SpView width={offsetBetween} />}
            </React.Fragment>
          );
        }
        return (
          <SpText
            key={`${item}${index}main`}
            style={[
              mainStyle,
              {
                fontSize: item === '-' ? 30 : sizes.main,
                lineHeight: sizes.main,
              },
            ]}>
            {item}
          </SpText>
        );
      })}
    </Text>
  );
};

export default SpTransformStringToMultipleTexts;
