import { ReportModel } from '@models/ReportModel';

import Http from './Http';

class ReportApi {
  static getReports(
    householdId: number,
    petId: number,
    from: string,
    to: string,
  ): Promise<ReportModel> {
    return Http.get(`/api/v2/report/household/${householdId}/pet/${petId}/aggregate`, {
      params: {
        from,
        to,
      },
    }).then(response => {
      return response.data.data;
    });
  }
}

export default ReportApi;
