import React, { useMemo } from 'react';
import { Switch, StyleSheet, ViewStyle } from 'react-native';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';

interface SpSwitchProps {
  checked: boolean;
  onChange: (state: boolean) => void;
  index: number;
  disabled?: boolean;
  customStyle?: ViewStyle;
}

export const SpSwitch = ({
  checked,
  onChange,
  disabled,
  index,
  customStyle = {},
}: SpSwitchProps): JSX.Element => {
  const trackColor = useMemo(() => {
    return { false: '#C1C2CF', true: colors.toxicGreen.color };
  }, []);

  return (
    <Switch
    accessible={false}
    {...testProperties('CurfewSwitch', `${checked}`, `${index ?? 'Main'}`)}
      disabled={disabled}
      style={[styles.switch, disabled && { opacity: 0.5 }, customStyle]}
      trackColor={trackColor}
      thumbColor={colors.white.color}
      onValueChange={() => onChange(!checked)}
      ios_backgroundColor={checked ? trackColor.true : trackColor.false}
      value={checked}
    />
  );
};

const styles = StyleSheet.create({
  switch: {
    width: 52,
  },
});
