import { PetModel } from '@models/Pet';
import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import text from '@styles/text';
import { format } from 'date-fns';
import { isEqual } from 'lodash-es';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Animated, Pressable, StyleSheet } from 'react-native';
import { GestureHandlerRootView, Swipeable } from 'react-native-gesture-handler';
import { closeModal, openModal } from 'src/services/ModalBox';
import CustomHeader from '../../../components/CustomHeader';
import DestructiveButton from '../../../components/DestructiveButton';
import SpModal from '../../../components/SpModal';
import { SpRoundedHeaderButton } from '../../../components/SpRoundedHeaderButton';
import { TimeService } from 'src/services/TimeService';
import useBoundStore from 'src/store/store';
import { RootStackParamList } from 'src/pages';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from '../../../components/SpView';
import PetItem from '../components/PetItem';

type FoundPetsProps = {
  isRemovable?: boolean;
  continueButtonAction: () => void;
  assignedPets?: PetModel[];
  addAnotherPetAction: () => void;
  setUnnamedPets: any;
  setAssignedPets: any;
};

const FoundPets = ({
  isRemovable,
  continueButtonAction,
  assignedPets,
  setAssignedPets,
  addAnotherPetAction,
  setUnnamedPets,
}: FoundPetsProps) => {
  const { t } = useTranslation();
  // temporary mocked data before we implement logic for found pets
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { deletePet, loadPet, petData } = useBoundStore(state => state.petStore);
  const swipeableRefs = useRef([]);
  const [currentId, setCurrentId] = useState(null);
  const [currentTag, setCurrentTag] = useState(null);
  const [needLoad, setNeedLoad] = useState(true);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!assignedPets?.length && isFocused && needLoad) {
      loadPet(true);
      const petsAll = assignedPets
        .map(assignedPet => petData.find(pet => pet?.id === assignedPet?.id))
        .filter(function (element) {
          return element !== undefined;
        });
      if (isEqual(assignedPets, petsAll)) {
        setNeedLoad(false);
        return;
      }
      setAssignedPets(petsAll);
    }
  }, [isFocused, assignedPets]);

  useLayoutEffect(() => {
    const result = assignedPets?.find(item => {
      return item?.name === item?.tag?.tag;
    });
    setUnnamedPets(!!result);
  }, [assignedPets, setUnnamedPets]);

  const onPress = (item: PetModel) => () => {
    if (typeof (item.gender && item.species_id && item.spayed) === 'number') {
      navigate('PetsNavigation', {
        screen: 'PetProfileSettings',
        params: { id: item.id },
      });
    } else {
      navigate('FlowNavigation', {
        screen: 'CreatePetProfile',
        params: { id: item.id },
      });
    }
  };

  const petDelletting = async id => {
    await deletePet(id);
    setAssignedPets((prevPets: PetModel[]) => prevPets.filter(pet => pet.id !== id)); ////
    swipeableRefs?.current?.forEach(ref => ref && ref.close());
  };

  const triggerAlert = (id: number, tag: number) => {
    Alert.alert(t('remove_pet'), t('remove_pet_subtitle'), [
      {
        text: t('cancel'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('remove'),
        style: 'destructive',
        onPress: () => {
          petDelletting(id);
        },
      },
    ]);
  };
  const renderRightActions = (
    progress: Animated.AnimatedInterpolation<any>,
    dragAnimatedValue: Animated.AnimatedInterpolation<any>,
  ) => {
    const opacity = dragAnimatedValue.interpolate({
      inputRange: [-35, 0],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    });
    return (
      <Animated.View style={[{ opacity }]}>
        <SpView style={styles.destructiveButtonWrapper}>
          <DestructiveButton
            onPress={() => triggerAlert(currentId, currentTag)}
            text={t('remove')}
          />
        </SpView>
      </Animated.View>
    );
  };

  return (
    <SpView>
      <SpText style={[text.title, styles.titleMargin]}>
        {t('found_pets_title {{count}}', { count: assignedPets.length })}
      </SpText>
      <SpText style={[text.subtitle, styles.subtitleMargin]}>{t('found_pets_subtitle')}</SpText>
      <Pressable onPress={() => openModal('foundPetsModal')}>
        <SpText style={[text.linkText, styles.linkMargin]}>{t('found_pets_link')}</SpText>
      </Pressable>
      {assignedPets.length === 0 || !assignedPets
        ? null
        : assignedPets?.map((item, index) => {
            if (!item || !item.tag) return null;
            const created = format(
              TimeService.convertOrUnconvertDateByUTC(new Date(item.tag.created_at), true),
              'dd/MM/yy HH:mm',
            );
            if (isRemovable) {
              return (
                <GestureHandlerRootView key={item.id}>
                  <Swipeable
                    ref={ref => (swipeableRefs.current[index] = ref)}
                    onSwipeableWillOpen={() => {
                      setCurrentTag(item.tag.id);
                      setCurrentId(item.id);
                    }}
                    renderRightActions={renderRightActions}>
                    <PetItem
                      imageUrl={item.photo?.location}
                      name={item.name}
                      created={created}
                      withAssignedIcon={false}
                      handlePress={onPress(item)}
                    />
                  </Swipeable>
                </GestureHandlerRootView>
              );
            }

            return (
              <PetItem
                imageUrl={item.photo?.location}
                name={item.name}
                created={created}
                withAssignedIcon={false}
                handlePress={onPress(item)}
                key={item.id}
              />
            );
          })}
      <SpView style={styles.addAnotherPet}>
        <SpRoundedHeaderButton
          backgroundColor={colors.darkGrey.color}
          title={t('add_another_pet')}
          textStyles={styles.anotherPetText}
          stylesForContainer={styles.anotherPetButton}
          onPress={addAnotherPetAction}
        />
      </SpView>

      <SpModal
        modalName="foundPetsModal"
        backdropColor={colors.white.color}
        bottomAreaView={false}
        onRequestClose={() => closeModal('foundPetsModal')}>
        <SpView style={styles.modalContainer}>
          <CustomHeader
            withCross
            withCrossMargin
            withTitle={false}
            goBackFunc={() => closeModal('foundPetsModal')}
          />
          <SpText style={[text.title, styles.titleMargin]}>{t('found_pets_modal_title')}</SpText>
          <SpText style={[text.subtitle, styles.subtitleMargin]}>
            {t('found_pets_modal_subtitle')}
          </SpText>
          <SpView style={styles.buttonWrapper}>
            <SpRoundedHeaderButton
              title={t('continue')}
              onPress={continueButtonAction}
              backgroundColor={colors.primary.color}
              h={56}
              stylesForContainer={styles.buttonContinueContainer}
            />
          </SpView>
        </SpView>
      </SpModal>
    </SpView>
  );
};

export default FoundPets;

const styles = StyleSheet.create({
  titleMargin: {
    marginTop: 56,
  },
  subtitleMargin: {
    marginTop: 24,
    marginBottom: 24,
  },
  linkMargin: {
    marginBottom: 24,
  },
  destructiveButtonWrapper: {
    marginTop: 10,
  },
  addAnotherPet: {
    marginBottom: 70,
  },
  buttonWrapper: {
    flex: 1,
  },
  modalContainer: {
    paddingHorizontal: 19,
  },
  reminder: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
    color: colors.greyText.color,
    paddingHorizontal: 10,
  },
  buttonContinueContainer: { marginTop: 'auto', marginBottom: 68 },
  anotherPetButton: {
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 35,
  },
  anotherPetText: {
    fontSize: 16,
    lineHeight: 16,
    color: '#fff',
    fontWeight: 'bold',
  },
});
