import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimelineEventModel } from '@models/Timeline';
import { TimelineCard } from '../TimelineCard';
import TimelineDevicePhoto from '../TimelineDevicePhoto';
import { getTimelineDeviceName } from '../../../hooks/useTimelineDeviceName';

type IDeviceBackOnlineProps = {
  active: boolean;
  event: TimelineEventModel;
};

const DeviceBackOnline = ({ active, event }: IDeviceBackOnlineProps) => {
  const { t } = useTranslation();
  const deviceName = getTimelineDeviceName(event?.devices);
  const { online } = JSON.parse(event.data) as { online: boolean; from: string };
  const messageKey = online ? 'device_back_online' : 'device_is_offline';
  return (
    <TimelineCard
      active={active}
      date={event.created_at}
      image={
        <TimelineDevicePhoto
          active={active}
          event={event}
        />
      }
      text={t(messageKey, {
        devicename: deviceName,
      })}
    />
  );
};

export default DeviceBackOnline;
