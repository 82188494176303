import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { SpVStack } from 'src/components/SpVStack';
import { HouseholdModel } from '@models/Household';
import SpTitle from 'src/components/SpTitle';
import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import useBoundStore from '../../../../store/store';
import { ConfirmRemoveHouseholdDialog } from './ConfirmRemoveHouseholdDialog';
import { ConfirmRemoveHouseholdDialogModes } from '../constants/ConfirmRemoveHouseholdDialogModes';
import { ConfirmRemoveHouseholdDialogContext } from '../contexts/ConfirmRemoveHouseholdDialogContext';
import { useGroupedHouseholds } from '../hooks/useGroupedHouseholds';
import { RemoveAccountParamList } from '../index';
import { Layout } from './Layout';
import { HouseholdSelect } from './HouseholdSelect';
import { WhatWillRemoved } from './WhatWillRemoved';
import { findHouseholdsWithViewersAndOtherCreated } from '../utils/findHouseholdsWithViewersAndOtherCreated';
import { getConfirmRemoveHouseholdMode } from '../utils/getConfirmRemoveHouseholdMode';

export const OwnerMultipleStep = () => {
  const navigation = useNavigation<NavigationProp<RemoveAccountParamList>>();
  const { t } = useTranslation();
  const { user } = useBoundStore(s => s.accountStore);
  const groupedHouseholds = useGroupedHouseholds();
  const [selectedHouseholdsCollection, setSelectedHouseholdsCollection] = useState<
    Record<number, HouseholdModel>
  >({});
  const { setDialogConfig } = useContext(ConfirmRemoveHouseholdDialogContext);

  const householdGroups = useMemo(() => {
    return groupedHouseholds.householdsAsOwner.reduce<{
      onlyMeHouseholds: HouseholdModel[];
      withOtherOwnersHouseholds: HouseholdModel[];
    }>(
      (acc, household) => {
        if (household.users.filter(item => item.owner && item.write).length > 1) {
          acc.withOtherOwnersHouseholds.push(household);
        } else {
          acc.onlyMeHouseholds.push(household);
        }

        return acc;
      },
      { onlyMeHouseholds: [], withOtherOwnersHouseholds: [] },
    );
  }, [groupedHouseholds.householdsAsOwner]);

  const selectHouseholdAction = useCallback(
    ($event: HouseholdModel) => {
      const state = !selectedHouseholdsCollection[$event.id];

      if (state) {
        if ($event.created_user_id !== user.id) {
          setDialogConfig({
            households: [$event],
            mode: ConfirmRemoveHouseholdDialogModes.NotOriginallyCreatedAndPermission,
            onConfirm: () => {
              navigation.navigate('OwnerMultipleStep');
              setSelectedHouseholdsCollection({
                ...selectedHouseholdsCollection,
                [$event.id]: $event,
              });
            },
          });
          navigation.navigate('ConfirmRemoveHouseholdDialog');
          return;
        } 
        setSelectedHouseholdsCollection({
          ...selectedHouseholdsCollection,
          [$event.id]: $event,
        });
      } else {
        setSelectedHouseholdsCollection({
          ...selectedHouseholdsCollection,
          [$event.id]: null,
        });
      }
    },
    [selectedHouseholdsCollection],
  );

  const confirmAction = useCallback(() => {
    const selectedHouseholds = Object.values(selectedHouseholdsCollection).filter(Boolean);

    const removeHouseholds = [...selectedHouseholds, ...householdGroups.onlyMeHouseholds];

    const continueAction = () => {
      navigation.navigate('ConfirmStep', {
        selectedHouseholds: removeHouseholds,
      });
    };

    const { viewers, notOriginallyCreated } = findHouseholdsWithViewersAndOtherCreated(
      householdGroups.onlyMeHouseholds,
      user,
    );

    if (
      (householdGroups.onlyMeHouseholds.length && viewers.length) ||
      notOriginallyCreated.length
    ) {
      setDialogConfig({
        households: householdGroups.onlyMeHouseholds,
        mode: getConfirmRemoveHouseholdMode(viewers, notOriginallyCreated),
        onConfirm: () => {
          navigation.dispatch(StackActions.pop(1));
          continueAction();
        },
      });
      navigation.navigate('ConfirmRemoveHouseholdDialog');
      return;
    }

    continueAction();
  }, [householdGroups.onlyMeHouseholds, selectedHouseholdsCollection, user]);

  return (
    <Layout
      withBackButton
      nextButtonText={t('continue')}
      onNext={confirmAction}>
      <SpVStack
        marginTop={24}
        space={12}>
        <SpText
          align="center"
          color={colors.errorRed.color}>
          {t(
            householdGroups.onlyMeHouseholds.length
              ? 'ad_delete_households_with_my_households'
              : 'ad_delete_households',
          )}
        </SpText>

        <SpText
          align="center"
          color={colors.errorRed.color}>
          {t('ad_delete_households_confirm')}
        </SpText>
      </SpVStack>

      <SpVStack
        marginTop={28}
        space={8}>
        {householdGroups.onlyMeHouseholds.map(item => {
          return (
            <SpView
              key={item.id}
              style={styles.myHousehold}>
              <SpText
                color={colors.greyText.color}
                fontFamily="Rubik_SemiBold">
                {item.name}
              </SpText>
              <SpView
                marginTop={8}
                width="70%">
                <SpText
                  size="sm"
                  color={colors.greyText.color}>
                  {t('ad_delete_households_my_household')}
                </SpText>
              </SpView>
            </SpView>
          );
        })}
        {householdGroups.withOtherOwnersHouseholds.map((item: HouseholdModel) => {
          return (
            <HouseholdSelect
              key={item.id}
              text={item.name}
              active={!!selectedHouseholdsCollection[item.id]}
              onActive={() => {
                selectHouseholdAction(item);
              }}
            />
          );
        })}
      </SpVStack>

      <SpView marginTop={20}>
        <SpTitle
          align="center"
          text={t('ad_explanation_delete_household_title')}
        />
      </SpView>

      <SpView marginBottom={24}>
        <WhatWillRemoved short={false} />
      </SpView>

      <SpRoundedHeaderButton
        title={t('more_information')}
        backgroundColor={colors.primary.color}
        onPress={() => navigation.navigate('RemoveHouseholdInformationDialog')}
      />
    </Layout>
  );
};

const styles = StyleSheet.create({
  myHousehold: {
    borderRadius: 14,
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    padding: 14,
  },
});
