import { StepsEnum } from '../misc/enums';
import React, { useCallback } from 'react';
import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../../../index';
import useBoundStore from '../../../../../store/store';
import { DeviceType } from '@constants/Device';
import { TagProfile } from '@constants/Tag';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { DeviceModel } from '@models/Device';
import { PetModel, PetWithTagModel } from '@models/Pet';

type UseStepInterceptorProps = {
  petService: any;
  assignUpdateData: (PetWithTagModel & {isAssigned:boolean})[];
  updatesPetsShared: (PetWithTagModel & {isAssigned:boolean})[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDeviceRef: React.MutableRefObject<DeviceModel>;
  setAssignedPets: (pets: PetModel[]) => void;
  setUpdatesPetsShared: (pets: (PetWithTagModel & {isAssigned:boolean})[]) => void;
  checkToLeavePetFlow: () => void;
  showPetsAddedRef: React.MutableRefObject<boolean>;
  handleSetStep: (step: number) => void;
  assignedPets: PetModel[];
  indoorModeEnabledPets: PetModel[];
  setIncompatiblePetAssociated: (val: number|null) => void;
  comingFromSettings: boolean;
  comingFromIncompatibleAssignPage: boolean;
  existingDevice: boolean;
  foundedPetsTagIds: number[];
  backFromCreatePetProfile: React.MutableRefObject<boolean>;
};

const useStepInterceptor = ({
  petService,
  assignUpdateData,
  updatesPetsShared,
  setLoading,
  selectedDeviceRef,
  setAssignedPets,
  setUpdatesPetsShared,
  checkToLeavePetFlow,
  showPetsAddedRef,
  handleSetStep,
  assignedPets,
  indoorModeEnabledPets,
  setIncompatiblePetAssociated,
  comingFromSettings,
  comingFromIncompatibleAssignPage,
  existingDevice,
  foundedPetsTagIds,
  backFromCreatePetProfile,
}: UseStepInterceptorProps) => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const pets = usePetsByHousehold();
  const { unassignAssignMultiplePetsRequest, davidsUpdateTagProfile, getPetByTagId } =
    useBoundStore(({ deviceStore, petStore }) => ({
      unassignAssignMultiplePetsRequest: deviceStore.unassignAssignMultiplePetsRequest,
      davidsUpdateTagProfile: deviceStore.davidsUpdateTagProfile,
      getPetByTagId: petStore.getPetByTagId,
    }));
  const applyInterceptor = useCallback(
    (step: StepsEnum) => {
      switch (step) {
        case StepsEnum.NoProducts /**step 0*/:
          return () => {
            navigation.navigate('FlowNavigation', { screen: 'SelectProduct' });
            return true;
          };
        case StepsEnum.SelectDevice /**step 1*/:
          return () => {
            return true;
          };
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case StepsEnum.MorePetFeeder /**step 2*/:
          return () => {
            return true;
          };
        case StepsEnum.AddPets /**step 3*/:
          return async () => {
            petService.stop();
            setIncompatiblePetAssociated(null);
            // Checking if an existing Device, so we diverge to a different flow
            if (comingFromSettings) {
              navigation.dispatch(
                StackActions.push('DeviceNavigation', {
                  screen: 'DeviceSetting',
                  params: {
                    id: selectedDeviceRef.current?.id,
                  },
                }),
              );
              return false;
            }
            if (comingFromIncompatibleAssignPage) {
              handleSetStep(StepsEnum.AssignPets);
              return false;
            }

            if (existingDevice || (foundedPetsTagIds.length === 1 &&
                pets.some(e => e?.tag_id === foundedPetsTagIds?.[0] && +e?.version === 0))) {
              backFromCreatePetProfile.current = true;
              const newPet = getPetByTagId(foundedPetsTagIds[0]);
              navigation.navigate('FlowNavigation', {
                screen: 'CreatePetProfile',
                params: {
                  id: newPet.id,
                  //@ts-ignore
                  existingDevice,
                  foundJustOnePet: !existingDevice,
                  deviceType:
                    foundedPetsTagIds.length === 1 && assignedPets.length === 0
                      ? selectedDeviceRef.current?.product_id
                      : null,
                },
              });
              return false;
            }
            return true;
          };
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case StepsEnum.FoundPets /**step 4*/:
          return () => {
            return true;
          };
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case StepsEnum.PetsNotNamed /**step 5*/:
          return () => {
            return true;
          };
        case StepsEnum.AssignPets /**step 6*/:
          return async () => {
            setLoading(true);
            const differences = assignUpdateData.filter(({ pet, isAssigned }, ind) => {
              return (
                pet.id === updatesPetsShared[ind].pet.id &&
                isAssigned !== updatesPetsShared[ind].isAssigned
              );
            });
            const result = await unassignAssignMultiplePetsRequest(
              selectedDeviceRef.current?.id,
              differences.map(elem => ({
                assign: elem.isAssigned,
                tagId: elem.pet.tag_id,
                pet: elem.pet,
              })),
            );
            if (result) {
              // Updating AssignedPets
              const newAssignedPets = assignUpdateData
                .filter(petAssigned => petAssigned.isAssigned)
                .map(petInfo => petInfo.pet);
              setAssignedPets(newAssignedPets);
              setUpdatesPetsShared(
                assignUpdateData.map(updatePetData => {
                  return {
                    pet: updatePetData.pet,
                    tag: updatePetData.tag,
                    isAssigned: updatePetData.isAssigned,
                  };
                }),
              );
              if (!newAssignedPets.length) {
                checkToLeavePetFlow();
                return false;
              }
              return true;
            }
          };
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case StepsEnum.IncompatibleChip /**step 7*/:
          return () => {
            return true;
          };
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case StepsEnum.RemovePets /**step 8*/:
          return () => {
            return true;
          };
        case StepsEnum.IndoorMode /**step 9*/:
          return () => {
            try {
              assignedPets.forEach(async pet => {
                const petIsIndoorMode = indoorModeEnabledPets.find(p => p.id === pet.id);
                if (pet.profile === TagProfile.Safe && !petIsIndoorMode) {
                  await davidsUpdateTagProfile(selectedDeviceRef.current?.id, pet, TagProfile.Permitted);
                }
                if (pet.profile !== TagProfile.Safe && petIsIndoorMode) {
                  await davidsUpdateTagProfile(selectedDeviceRef.current?.id, pet, TagProfile.Safe);
                }
              });
            } catch (err) {
              console.log(`[ERROR] [useStepInterceptor] [StepsEnum.IndoorMode]`, err);
            }
            return true;
          };
        case StepsEnum.Success /**step 10*/:
          return () => {
            if (
              selectedDeviceRef.current?.product_id === DeviceType.CatFlapConnect &&
              showPetsAddedRef.current
            ) {
              showPetsAddedRef.current = false;
              handleSetStep(StepsEnum.IndoorMode);
              return false;
            }
            checkToLeavePetFlow();
            return false;
          };
        default:
          return () => {
            console.log('[DEBUG] [useStepInterceptor] Step interceptor not found');
            return true;
          };
      }
    },
    [
      navigation,
      petService,
      setIncompatiblePetAssociated,
      comingFromSettings,
      comingFromIncompatibleAssignPage,
      existingDevice,
      foundedPetsTagIds,
      assignedPets,
      pets?.length,
      selectedDeviceRef,
      handleSetStep,
      backFromCreatePetProfile,
      getPetByTagId,
      assignUpdateData,
      setLoading,
      unassignAssignMultiplePetsRequest,
      updatesPetsShared,
      setAssignedPets,
      setUpdatesPetsShared,
      checkToLeavePetFlow,
      indoorModeEnabledPets,
      davidsUpdateTagProfile,
      showPetsAddedRef,
    ],
  );
  return {
    applyInterceptor,
  };
};

export default useStepInterceptor;
