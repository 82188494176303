import text from '@styles/text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { SpDivider } from 'src/components/SpDivider';
import { SpView } from 'src/components/SpView';

import { SpText } from '../../../components/SpText/SpText';

const DogBowlReady = () => {
  const { t } = useTranslation();
  return (
    <SpView style={styles.container}>
      <SpView>
        <SpText style={styles.title}>{t('dog_bowl_ready')}</SpText>
      </SpView>
      <SpDivider style={styles.divider} />
      <SpView>
        <SpText style={styles.description}>{t('dog_can_now_eat')}</SpText>
      </SpView>
    </SpView>
  );
};
export default DogBowlReady;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: '5%',
    marginBottom: 100,
    alignItems: 'center',
    maxHeight: '85%',
    paddingHorizontal: '5%',
  },
  title: {
    ...text.title,
  },
  description: {
    ...text.body,
  },
  divider: {
    height: '5%',
  },
});
