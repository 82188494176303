import { DateTime } from 'luxon';

export const hasLeapYearFebruary = (startDate, endDate) => {
  for (let year = startDate.year; year <= endDate.year; year++) {
    if (DateTime.fromObject({ year, month: 2, day: 29 }).isValid) {
      const februaryStart = DateTime.fromObject({ year, month: 2, day: 1 });
      if (februaryStart >= startDate && februaryStart <= endDate) {
        return true;
      }
    }
  }

  return false;
};
