import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const ReportPathIcon = (props: any) => (
  <Svg
    width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}>
        <Path id="Path 3" fill-rule="evenodd" clip-rule="evenodd" d="M2.24526 1.10493C1.3937 0.306595 0 0.91039 0 2.07764L0 10.9224C0 12.0896 1.3937 12.6934 2.24525 11.8951L6.96244 7.47272C7.52432 6.94595 7.52432 6.05405 6.96244 5.52728L2.24526 1.10493Z" fill="white" />
  </Svg>
);
export default ReportPathIcon;