export type Descripted<T> = {
  [K in keyof T]: {
    readonly id: T[K];
    readonly name: string;
  };
}[keyof T];

export function enumToDescriptedArray<T>(
  enumeration: T,
  separatorRegex = /_/g,
  shouldIdStartFrom1 = false,
): Descripted<T>[] {
  return (Object.keys(enumeration) as (keyof T)[])
    .filter(key => Number.isNaN(Number(key)))
    .filter(key => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
    .map(key => {
      const id = enumeration[key];

      return {
        id:
          shouldIdStartFrom1 && !Number.isNaN(Number(id)) && typeof id === 'number'
            ? (((id as number) + 1) as T[keyof T])
            : id,
        name: String(key).replace(separatorRegex, ' '),
      };
    });
}
