import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedConsumptionStats,
  MappedPoseidonReportDataModel,
  PetGroupedStatistic,
} from '@models/ReportModel';
import { getDateRangeOfMonth, getDateRangeOfWeek } from '@utils/date';
import { getConsumptionStats, getConsumptionStatsByRange } from '@utils/report';
import { endOfDay, subDays, subMonths } from 'date-fns';
import { useMemo } from 'react';

export function useGroupedConsumptionStats(
  data: GroupReportPointModel<MappedConsumptionReportDataModel | MappedPoseidonReportDataModel>[],
  pet: PetModel,
  devices: DeviceModel[],
  date: number,
  isFelaqua = false,
): PetGroupedStatistic<MappedConsumptionStats> {
  return useMemo(() => {
    if (!date) {
      return null;
    }

    const currentWeek = getDateRangeOfWeek(date);
    const previousWeek = getDateRangeOfWeek(subDays(currentWeek.start, 1).getTime());
    const currentMonth = getDateRangeOfMonth(date);
    const previousMonth = getDateRangeOfWeek(subMonths(currentMonth.start, 1).getTime());

    const events = data.find(item => item.date === endOfDay(date).getTime());

    return {
      currentDay: getConsumptionStats(events ? [events] : [], pet.tag_id, devices, date, isFelaqua),
      currentWeek: getConsumptionStatsByRange(
        data,
        pet.tag_id,
        devices,
        currentWeek.start,
        currentWeek.end,
        isFelaqua,
      ),
      currentWeekRange: currentWeek,
      previousWeek: getConsumptionStatsByRange(
        data,
        pet.tag_id,
        devices,
        previousWeek.start,
        previousWeek.end,
        isFelaqua,
      ),
      previousWeekRange: previousWeek,
      currentMonth: getConsumptionStatsByRange(
        data,
        pet.tag_id,
        devices,
        currentMonth.start,
        currentMonth.end,
        isFelaqua,
      ),
      currentMonthRange: currentMonth,
      previousMonth: getConsumptionStatsByRange(
        data,
        pet.tag_id,
        devices,
        previousMonth.start,
        previousMonth.end,
        isFelaqua,
      ),
      previousMonthRange: previousMonth,
    };
  }, [date, pet, devices, data]);
}
