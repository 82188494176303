import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const CIExclamationSVG = (props: any) => (
  <Svg
    width="6"
    height="20"
    viewBox="0 0 6 20"
    fill="none"
  >
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.99887 12.8571C3.9232 12.8571 4.68441 12.1607 4.76053 11.2607L5.48913 2.66786C5.5 2.60357 5.5 2.52857 5.5 2.46429C5.5 1.11429 4.3908 0 2.99887 0C1.53081 0 0.38899 1.23214 0.50861 2.66786L1.2372 11.2607C1.31332 12.1607 2.07454 12.8571 2.99887 12.8571ZM0.857143 17.8571C0.857143 19.0357 1.82143 20 3 20C4.17857 20 5.14286 19.0357 5.14286 17.8571C5.14286 16.6786 4.17857 15.7143 3 15.7143C1.82143 15.7143 0.857143 16.6786 0.857143 17.8571Z"
      fill="#263A43"
    />
  </Svg>
);
export default CIExclamationSVG;
