import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const WCIAlertHigh = (props: any) => (
  <Svg
    width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <G>
      <Path d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0Z" fill="#ED0055" />
      <Path transform={"translate(8, 8)"} fill-rule="evenodd" clip-rule="evenodd" d="M5.9719 9.93014V13.1421C5.9719 14.2622 6.88009 15.1704 8.00023 15.1704C9.12036 15.1704 10.0275 14.2622 10.0275 13.1421V9.93014H14.0238C15.7838 9.93014 16.6623 7.79943 15.4142 6.55848L9.39067 0.573843C8.62173 -0.191002 7.37873 -0.191002 6.60979 0.573843L0.586245 6.55848C-0.662901 7.79943 0.216619 9.93014 1.97669 9.93014L5.9719 9.93014Z" fill="white"/>
    </G>
  </Svg>
);
export default WCIAlertHigh;

