import React, { memo, useMemo } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import SearchTimeout from './SearchTimeout';
import { SpCenter } from '../../../../../../components/SpCenter';
import { SpView } from '../../../../../../components/SpView';
import { testProperties } from '@utils/testProperties';
import LoadingComponent from '../../../../components/LoadingComponent';
import { useTranslation } from 'react-i18next';
import DoItLater from './DoItLater';
import PetFound from './PetFound';


type AddPetsLoadingProps = {
  maxRetriesError: boolean;
  comingFromIncompatibleAssignPage: boolean;
  existingDevice: boolean;
  comingFromSettings: boolean;
  checkToLeavePetFlow: () => void;
  foundedPetsTagIds: number[];
  incompatiblePets: any[];
};

const AddPetsLoading = ({
  maxRetriesError,
  comingFromIncompatibleAssignPage,
  existingDevice,
  comingFromSettings,
  checkToLeavePetFlow,
  foundedPetsTagIds,
  incompatiblePets,
}: AddPetsLoadingProps) => {
  const { t } = useTranslation();

  const message = useMemo(() => {
    if (foundedPetsTagIds.length < 1) {
      return t('looking_for_pet');
    }
    if (existingDevice || comingFromSettings || comingFromIncompatibleAssignPage) {
      return t('found_pet');
    }
    return `${t('pets_found')} ${foundedPetsTagIds.length}`;
  }, [
    comingFromIncompatibleAssignPage,
    comingFromSettings,
    existingDevice,
    foundedPetsTagIds.length,
    t,
  ]);

  const shouldShowLoadingComponent = () =>
    (foundedPetsTagIds.length < 1 ||
    (!existingDevice && foundedPetsTagIds.length > 0 && !comingFromIncompatibleAssignPage) ||
    (!existingDevice &&
      !comingFromSettings &&
      !comingFromIncompatibleAssignPage &&
      incompatiblePets.length === 0));
  

  const shouldShowDoItLater = () =>
    !existingDevice && !comingFromSettings && !comingFromIncompatibleAssignPage;

  const shouldShowFoundPet = () =>
    (existingDevice || comingFromSettings || comingFromIncompatibleAssignPage) &&
    foundedPetsTagIds.length === 1;

  return (
    <View style={styles.loaderWrap}>
      {maxRetriesError ? (
        <SearchTimeout />
      ) : (
        <SpCenter>
          {shouldShowLoadingComponent() && (
            <SpView
              {...testProperties('loading element', 'container')}
              accessible={false}
            >
              <LoadingComponent
                searchMsg={message}
                direction="row"
                type="insideCircle"
              />
              {shouldShowDoItLater() && <DoItLater onPress={checkToLeavePetFlow} />}
            </SpView>
          )}
          {shouldShowFoundPet() && <PetFound message={message} />}
        </SpCenter>
      )}
    </View>
  );
};

export default memo(AddPetsLoading);

const styles = StyleSheet.create({
  loaderWrap: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
