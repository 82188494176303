import { TimeUnits } from '@constants/DateFormat';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Duration, DurationObjectUnits } from 'luxon';
import { useTranslation } from 'react-i18next';
import useBoundStore from 'src/store/store';
import { useTimeAMPMTransform } from './useTimeAMPMTransform';
import { ReportRangeType } from '../pages/Dashboard/constants/ReportRangeType';

export const useTimeTransform = (): {
  to24H: (data: string) => string;
  timeToFormat: (time: string, timeFormat: TimeUnits) => string;
  convertSecondToFormat: (
    data: number,
    rangeType?: ReportRangeType,
  ) => {
    time: string;
    unit: string;
  };
  convertSecondToTwoDigitFormat: (data: number) => {
    timeFirst: string;
    unitFirst: string;
    timeSecond: string;
    unitSecond: string;
  };
  convertDateToTime: (data: any) => string;
  concatTwoDigitFormatToOne: (data: {
    timeFirst: string;
    unitFirst: string;
    timeSecond: string;
    unitSecond: string;
  }) => string;
} => {
  const { t } = useTranslation();
  const { timeToAMPM } = useTimeAMPMTransform();
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);

  const iOS12Version = (): boolean | number => {
    // TODO Check appVersion
    // const match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    // @ts-ignore
    const match = undefined;
    if (match !== undefined && match !== null) {
      return parseFloat(match[1]);
    }

    return false;
  };
  const zeroPad = (data: number) => (data <= 9 ? `0${data}` : data.toString());

  const to24H = (time: string): string => {
    let translation = `(${t('TIME_AM_UNIT')})|(${t('TIME_PM_UNIT')})`
      .replace(/\./gi, '\\.')
      .replace(/\s/gi, '\\s');

    // HAck to fix the IOS Dutch issue (problem not taking the right units format a.m. / p.m. and instead AM / PM)
    const forceAMPM = iOS12Version();
    translation = forceAMPM ? '(AM)|(PM)' : translation;

    const ampmRegex = new RegExp(translation, 'i');
    const amOrPm = time.match(ampmRegex);
    const timeParts = time.trim().replace(ampmRegex, '').split(/[.:]/).map(parseFloat);

    if (!amOrPm) {
      return `${zeroPad(timeParts[0] === 24 ? 0 : timeParts[0])}:${zeroPad(timeParts[1])}`;
    }
    // HAck to fix the IOS Dutch issue (problem not taking the right units format a.m. / p.m. and instead AM / PM)
    const pm = forceAMPM ? 'PM' : t('TIME_PM_UNIT').replace(/\s/g, '');
    const am = forceAMPM ? 'AM' : t('TIME_AM_UNIT').replace(/\s/g, '');

    const offset =
      (amOrPm[0].replace(/\s/g, '') === pm && timeParts[0] < 12) ||
      (amOrPm[0].replace(/\s/g, '') === am && timeParts[0] === 12)
        ? 12
        : 0;

    return `${zeroPad((timeParts[0] + offset) % 24)}:${zeroPad(timeParts[1])}`;
  };

  const getTimeFrames = (amount: number) => {
    const d = Number(amount);

    return {
      h: Math.floor(d / 3600),
      m: Math.round((d % 3600) / 60),
      s: Math.round((d % 3600) % 60),
    };
  };

  const parseSecondsToFrames = (
    seconds: number,
    frames: (keyof DurationObjectUnits)[],
  ): DurationObjectUnits => {
    return Duration.fromDurationLike(seconds * 1000)
      .shiftTo(...frames)
      .toObject();
  };

  const convertSecondToFormat = (
    amount: number,
    rangeType?: ReportRangeType,
  ): {
    time: string;
    unit: string;
  } => {
    let time = '';
    let unit = '';
    if (amount <= 0) return { time, unit };
    const { days, hours, minutes, seconds } = parseSecondsToFrames(amount, [
      'days',
      'hours',
      'minutes',
      'seconds',
    ]);
    if (
      days &&
      (rangeType === ReportRangeType.OneYear || rangeType === ReportRangeType.SixMonths)
    ) {
      time = `${days}`;
      unit = t('days_unit_1');
    } else if (days) {
      time = `${zeroPad(days * 24)}:${zeroPad(0)}`;
      unit = t('hours_unit_1');
    } else if (hours) {
      time = `${zeroPad(hours)}:${zeroPad(minutes)}`;
      unit = t('hours_unit_1');
    } else if (minutes) {
      time = `${zeroPad(minutes)}:${zeroPad(seconds)}`;
      unit = t('minutes_unit_1');
    } else if (seconds) {
      time = `${zeroPad(seconds)}`;
      unit = t('seconds_unit_1');
    }
    return { time, unit };
  };

  const concatTwoDigitFormatToOne = (data: {
    timeFirst: string;
    unitFirst: string;
    timeSecond: string;
    unitSecond: string;
  }): string => {
    return [`${data.timeFirst}${data.unitFirst}`, `${data.timeSecond}${data.unitSecond}`]
      .filter(Boolean)
      .join(' ');
  };

  const convertSecondToTwoDigitFormat = (
    amount: number,
  ): {
    timeFirst: string;
    unitFirst: string;
    timeSecond: string;
    unitSecond: string;
  } => {
    const { s, m, h } = getTimeFrames(amount);

    let objResult = {
      timeFirst: '',
      unitFirst: '',
      timeSecond: '',
      unitSecond: '',
    };

    if (h > 0) {
      objResult = {
        timeFirst: h.toString(),
        unitFirst: t('hours_unit_1'),
        timeSecond: m ? m.toString() : '',
        unitSecond: m ? t('minutes_unit_2') : '',
      };
    } else if (m > 0) {
      objResult = {
        timeFirst: m.toString(),
        unitFirst: t('minutes_unit_1'),
        timeSecond: s ? s.toString() : '',
        unitSecond: s ? t('seconds_unit_2') : '',
      };
    } else if (s >= 0) {
      objResult = {
        timeFirst: s.toString(),
        unitFirst: t('seconds_unit_2'),
        timeSecond: '',
        unitSecond: '',
      };
    }

    return objResult;
  };

  const convertSecondToHours = (amount: number): string => {
    const d = Number(amount);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);

    if (h > 0) {
      return `${zeroPad(h)}:${zeroPad(m)}`;
    }
    if (m > 0) {
      return `00:${zeroPad(m)}`;
    }

    return '00:00';
  };

  const timeToFormat = (time: string, timeFormat: TimeUnits): string => {
    return (timeFormat || TimeUnits.ISO) === TimeUnits.AMPM ? timeToAMPM(time) : time;
  };

  const convertDateToTime = date =>
    format(utcToZonedTime(date, activeHousehold?.timezone?.timezone), 'HH:mm');

  return {
    to24H,
    timeToFormat,
    convertSecondToFormat,
    convertDateToTime,
    convertSecondToTwoDigitFormat,
    concatTwoDigitFormatToOne,
  };
};
