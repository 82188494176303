import { DeviceType } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import { useEffect, useState } from 'react';
import useBoundStore from '../store/store';
import { useDevicesByHousehold } from './useDevicesByHousehold';

export const useCurrentHub = (): DeviceModel[] => {
  const [filteredData, setFilteredData] = useState<DeviceModel[]>([]);
  const devices = useDevicesByHousehold();
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);

  useEffect(() => {
    setFilteredData(
      devices.filter(
        item => item.product_id === DeviceType.Hub || item.product_id === DeviceType.Cerberus,
      ),
    );
  }, [activeHousehold, devices]);

  return filteredData;
};
