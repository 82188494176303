import { SpText } from '../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { DeviceType } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import SpModal from 'src/components/SpModal';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet } from 'react-native';
import { openModal } from '../../../../../services/ModalBox';

interface DeviceFirmwareProps {
  id: number;
}

export const DeviceFirmware = ({ id }: DeviceFirmwareProps) => {
  const [active, setActive] = useState(false);
  const device = useDeviceById(id);
  const { t } = useTranslation();

  const openVersion = useCallback(() => {
    setActive(true);
  }, [active]);

  useEffect(() => {
    if (active) {
      openModal('firmware');
    }
  }, [active]);

  if (
    !device?.status?.version?.device?.firmware ||
    device.product_id === DeviceType.PetDoorConnect
  ) {
    return null;
  }

  return (
    <SpView style={styles.wrapper}>
      <Pressable
        style={[styles.label, active && styles.active]}
        onPress={openVersion}
        {...testProperties(active ? 'Version' : 'DeviceFirmware', 'button')}>
        <SpText
          color={active && colors.white.color}
          align="center">
          {t('version')}
        </SpText>
      </Pressable>
      <SpModal
        modalName="firmware"
        onModalCloseHandler={() => setActive(false)}
        backdropColor="transparent">
        <SpView style={styles.versionWrapper}>
          <SpView style={styles.version}>
            <SpText
              align="center"
              color={colors.white.color}>
              {t('firmwareVersion')}
            </SpText>
            <SpText
              size="xl"
              align="center"
              color={colors.white.color}>
              {device?.status?.version?.device?.firmware}
            </SpText>
          </SpView>
        </SpView>
      </SpModal>
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexShrink: 1,
  },
  label: {
    paddingHorizontal: 15,
    paddingVertical: 4,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'rgba(38, 58, 67, 0.25)',
    borderStyle: 'solid',
  },
  active: {
    backgroundColor: '#263a43',
  },
  versionWrapper: {
    width: '100%',
    paddingHorizontal: 19,
  },
  version: {
    marginTop: 60,
    width: '100%',
    padding: 16,
    backgroundColor: '#263a43',
    borderRadius: 10,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
    shadowColor: 'rgba(38, 58, 67, 0.5)',
  },
});
