import { SpView } from 'src/components/SpView';
import { testProperties } from '@utils/testProperties';
import i18n from '@utils/i18n';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, ListRenderItem } from 'react-native';
import GradientButton from 'src/components/GradientButton';
import { CatFlapQuickActions } from '@constants/QuickActions';
import { Carousel } from 'react-native-snap-carousel';
import IndoorOnlySVG from 'src/components/SvgIcons/IndoorOnlySVG';
import colors from '@styles/colors';
import LockSVG from 'src/components/SvgIcons/LockSVG';
import UnlockSVG from 'src/components/SvgIcons/UnlockSVG';
import CurfewEnabledSVG from 'src/components/SvgIcons/CurfewEnabledSVG';
import { QuickActionButton, QuickActionLoaderButton } from '@constants/Navigation';
import { getNestedProperty } from '@utils/getNestedProperty';
import useBoundStore from '../../../../../../store/store';

interface FooterActionsProps {
  onAction: (data: CatFlapQuickActions) => void;
}
const ICON_SIZE = Math.min(Dimensions.get('screen').height * 0.05, 45);
const BUTTON_SIZE = Math.max(Math.min(Dimensions.get('screen').height * 0.18, 160), 125);

export const CatFlapFooterActions = ({ onAction }: FooterActionsProps) => {
  const SLIDER_WIDTH = Dimensions.get('window').width;
  // const ITEM_WIDTH = Math.round(SLIDER_WIDTH * 0.4);
  const loadingControl = useBoundStore(state => state.deviceStore.loadingControl);
  const { updateLockUnlockLoading, updateCurfewLoading, updateTagProfileLoading } = useBoundStore(
    state => state.deviceStore,
  );

  const { t } = useTranslation();
  const isCarousel = React.useRef(null);

  const buttons: QuickActionLoaderButton[] = [
    {
      icon: [
        <LockSVG
          color={colors.greyText.color}
          width={ICON_SIZE}
          height={ICON_SIZE}
          viewBox="0 0 28 28"
        />,
        <UnlockSVG
          color={colors.greyText.color}
          width={ICON_SIZE}
          height={ICON_SIZE}
          viewBox="0 0 28 28"
        />,
      ],
      text: `${i18n.t('locks')}`,
      action: () => {
        onAction(CatFlapQuickActions.lock);
      },
      loading: !!loadingControl.locking || updateLockUnlockLoading,
    },
    {
      icon: (
        <CurfewEnabledSVG
          color={colors.greyText.color}
          width={ICON_SIZE}
          height={ICON_SIZE}
          viewBox="0 0 30 30"
        />
      ),
      text: i18n.t('curfews'),
      action: () => onAction(CatFlapQuickActions.curfew),
      loading: !!loadingControl.curfew || updateCurfewLoading,
    },
    {
      icon: (
        <IndoorOnlySVG
          color={colors.greyText.color}
          width={ICON_SIZE}
          height={ICON_SIZE}
          viewBox="0 0 28 28"
        />
      ),
      text: i18n.t('indoor_only'),
      action: () => onAction(CatFlapQuickActions.indoor_mode),
      loading: !!loadingControl.tag_profiles?.length || updateTagProfileLoading,
    },
  ];

  const renderButtonItem: ListRenderItem<QuickActionLoaderButton> = useCallback(props => {
    return (
      <GradientButton
        colorsGradient={['rgba(0, 189, 136, 0.4)', 'rgba(45, 181, 142, 0.4)']}
        {...testProperties(props.item.text, t('button'), 'FooterActions')}
        borderRadius={18}
        text={props.item.text}
        icon={props.item.icon}
        action={props.item.action}
        key={`${props.item.text}${props.index}`}
        isLoading={props.item.loading}
        disabled={props.item.loading}
        styleContainer={
          props.index === 1 || props.index === 2 ? { marginLeft: props.index * 15 } : {}
        }
      />
    );
  }, []);

  return (
    <SpView style={styles.row}>
      <Carousel
        inactiveSlideOpacity={1}
        inactiveSlideScale={1}
        activeSlideAlignment="start"
        layout="default"
        vertical={false}
        ref={isCarousel}
        firstItem={0}
        keyExtractor={(item, index) => `${index}`}
        data={buttons}
        renderItem={renderButtonItem as any}
        sliderWidth={SLIDER_WIDTH}
        itemWidth={BUTTON_SIZE}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginHorizontal: -10,
  },
  column: {
    paddingHorizontal: 10,
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '20%',
  },
  button: {
    padding: 20,
    backgroundColor: 'transparent',
  },
});
