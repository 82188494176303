import AuthApi from '@api/AuthApi';
import { FormikProps } from 'formik';
import { FormValues } from 'src/pages/OnboardingLetsStart/Register/components/PasswordForm';

interface checkPasswordValidityObj {
  formik: FormikProps<FormValues>;
  setError: (arg: string) => void;
}

export const checkPasswordValidity = async (
  deviceId: string,
  emailAddress: string,
  { formik, setError }: checkPasswordValidityObj,
) => {
  try {
    const { token } = await AuthApi.login(
      {
        email_address: emailAddress,
        password: formik.values.currentPassword,
        device_id: deviceId,
      },
      true,
    );

    if (token) {
      return token;
    }
  } catch (e) {
    if (e && e.data.error.login?.includes('invalid_email_or_password')) {
      setError('password_is_invalid');
      return false;
    }
  }
  return null;
};
