import { StyleSheet, View } from 'react-native';
import React, { useCallback, useMemo, useState } from 'react';
import { SelectButtonsWrapperWithArray } from 'src/components/SelectButtonsWrapper';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import useBoundStore from 'src/store/store';
import SpTitle from 'src/components/SpTitle';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import { SpView } from 'src/components/SpView';
import { DeviceStackParamList } from '../..';

type Props = NativeStackScreenProps<DeviceStackParamList, 'DeviceBowlFoodSelect'>;

const DeviceBowlFoodSelect = ({ route }: Props) => {
  const { changeFoodTypeAction, $event, direction } = route.params;
  const { loadingDevice } = useBoundStore(s => s.deviceStore);

  const [bowlFoodType, setBowlFoodType] = useState($event?.food_type);

  const { t } = useTranslation();
  const navigation = useNavigation();

  const foodArray = [
    { name: t('dry'), id: 2 },
    { name: t('wet'), id: 1 },
  ];

  const onChange = useCallback(() => {
    if (bowlFoodType === $event?.food_type) {
      return navigation.goBack();
    }
    return changeFoodTypeAction(bowlFoodType, direction);
  }, [bowlFoodType, direction]);

  const title = useMemo(() => {
    switch (direction) {
      case 'left':
        return t('left_bowl');
      case 'right':
        return t('right_bowl');
      default:
        return t('food_type');
    }
  }, [direction, t]);

  return (
    <View style={styles.container}>
      <SpTitle text={title} />
      <SpView style={styles.innerContainer}>
        <SelectButtonsWrapperWithArray
          data={foodArray}
          handleChange={setBowlFoodType}
          selectedOption={bowlFoodType}
        />

        <SpRoundedHeaderButton
          backgroundColor={colors.primary.color}
          h={56}
          title={t('save')}
          onPress={onChange}
          isLoading={loadingDevice}
          stylesForContainer={styles.button}
        />
      </SpView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
    paddingHorizontal: 19,
    height: '100%',
  },
  button: {
    position: 'absolute',
    marginBottom: 68,
    width: '100%',
    bottom: 0,
  },
  innerContainer: {
    flex: 1,
    height: '100%',
  },
});

export default DeviceBowlFoodSelect;
