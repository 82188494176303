import { Image, StyleSheet, View } from 'react-native';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SpTitle from 'src/components/SpTitle';
import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import InstructionsSteps from '../components/InstructionsSteps';

interface IFitBowlProps {
  isBowlSingle: boolean;
}

const FitBowl = ({ isBowlSingle }: IFitBowlProps) => {
  const { t } = useTranslation();

  const fitBowlText = useMemo(() => {
    return [
      t('setup_bowl_instruction_1'),
      t('setup_bowl_instruction_2 {{bowl_type}}', {
        bowl_type: isBowlSingle ? t('single_bowl') : t('half_bowl'),
      }),
      t('setup_bowl_instruction_3 {{bowl_type}}', {
        bowl_type: isBowlSingle ? t('single_bowl') : t('half_bowls'),
      }),
    ];
  }, [isBowlSingle]);

  return (
    <>
      <View style={styles.container}>
        <SpTitle
          text={t('fit_bowl_title {{bowl_type1}} {{bowl_type2}}', {
            bowl_type1: isBowlSingle ? t('single_bowl') : t('half_bowl'),
            bowl_type2: isBowlSingle ? t('single_bowl') : t('half_bowls'),
          })}
          align="center"
        />
        <Image
          source={isBowlSingle ? AppImages.imgSingleBowlSetup : AppImages.imgHalfBowlSetup}
          {...testProperties(isBowlSingle ? 'imgSingleBowlSetup' : 'imgHalfBowlSetup', 'image')}
          style={{
            height: 200,
          }}
          resizeMode="contain"
        />
      </View>
      <InstructionsSteps
        arr={fitBowlText}
        withStepNums
        space={16}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginBottom: 40,
  },
});

export default FitBowl;
