import React, { useMemo, useState } from 'react';
import { SpView } from 'src/components/SpView';
import { Dimensions, Image, Linking, Platform, View } from 'react-native';
import { SpText } from '../../components/SpText/SpText';
import { SpButton } from 'src/components/SpButton';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { AppImages } from '@constants/AppImages';
import { testProperties } from '@utils/testProperties';
import styles from './OnboardingLetsStart.styled';
import { RootStackParamList } from '..';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { SpInput } from 'src/components/SpInput';

const OnboardingLetsStart = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const windowWidth = Dimensions.get('window').width;
  const [value, setValue] = useState('');
  const [adminCounter, setAdminCounter] = useState(0);

  const concaveBoxStyles = useMemo(() => {
    const roundingsValue = 6;
    return {
      width: windowWidth * roundingsValue,
      height: windowWidth * roundingsValue,
      borderRadius: (windowWidth * roundingsValue) / 2,
      left: -((windowWidth * roundingsValue) / 2 - windowWidth / 2),
    };
  }, [windowWidth]);

  const handlePressLetsStart = () => {
    setAdminCounter(0);
    navigation.navigate('RegisterNavigation');
  };
  const handlePressLogin = () => {
    setAdminCounter(0);
    navigation.navigate('LoginNavigation');
  };

  const handleLinkPress = () => {
    // create hidden input, cut with regexp and paste the token - IOS
    // adb shell in Saucelabs settings - Android
    const cutURL = value.match(/(?<=auth-token-login\/)\S*/);
    const finalUrl = 'surehub://auth-token-login/' + cutURL[0];
    Linking.openURL(finalUrl).catch(err => console.error('An error occurred', err));
  };

  const handleInput = e => {
    setValue(e);
  };
  const handlePress = () => {
    if (Platform.OS === 'ios') setAdminCounter(old => ++old);
  };

  return (
    <SpView style={styles.container}>
      <SpView style={styles.imageWrapper}>
        <SpView style={styles.topSection}>
          <View style={styles.backgroundImageWrapper}>
            <Image
              {...testProperties('background onboarding', 'photo')}
              source={AppImages.onboardingLetsStartBackground}
              resizeMode="cover"
              style={styles.backgroundImage}
            />
          </View>
          <SpView style={[styles.concaveBox, { ...concaveBoxStyles }]} />
          <SpView style={styles.logoCicleWrapper}>
            <TouchableWithoutFeedback
              onPress={handlePress}
              disabled={process.env.EXPO_PUBLIC_SAUCELABS !== 'true'}
            >
              <Image
                {...testProperties('logo onboarding', 'photo')}
                source={AppImages.logoGrey}
                style={styles.logoImage}
                resizeMode="contain"
              />
            </TouchableWithoutFeedback>
          </SpView>
        </SpView>
        <SpView style={styles.titleWrapper}>
          <SpText
            fontFamily="Rubik_SemiBold"
            style={styles.titleText}
          >
            {t('connect_with_your_pet')}
          </SpText>
          <SpText
            fontFamily="Rubik_SemiBold"
            color={colors.primary.color}
            style={styles.descriptionText}
          >
            {t('award_winning_pet')}
          </SpText>
        </SpView>
      </SpView>
      {adminCounter >= 15 && process.env.EXPO_PUBLIC_SAUCELABS === 'true' && (
        <SpView>
          <SpView style={{ padding: 10 }}>
            <SpInput
              value={value}
              onChangeText={handleInput}
            />
          </SpView>
          <SpView style={{ paddingHorizontal: 5, paddingVertical: 5, alignItems: 'center' }}>
            <SpText
              onPress={handleLinkPress}
              style={{ color: 'white', padding: 5, backgroundColor: 'black', borderRadius: 30 }}
            >
              CLICK!
            </SpText>
          </SpView>
        </SpView>
      )}
      <SpView style={styles.bottomSectionWrapper}>
        <SpButton
          onPress={handlePressLetsStart}
          borderRadius={14}
          textStyle={styles.buttonTextLetsStart}
          style={{ marginBottom: 36 }}
          color={colors.primary.color}
          title={t('lets_start_button')}
        />
        <SpView style={styles.loginWrapper}>
          <SpText
            size="md"
            color="#263a4380"
          >
            {t('already_have_an_account')}
          </SpText>
          <SpButton
            onPress={handlePressLogin}
            color={colors.greyText.color}
            borderRadius={10}
            paddingVertical={6}
            textStyle={styles.buttonLoginTextStyle}
            style={{ minWidth: 72 }}
            title={t('login')}
            fontFamily="Rubik_SemiBold"
          />
        </SpView>
      </SpView>
    </SpView>
  );
};

export default OnboardingLetsStart;
