import colors from '@styles/colors';
import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import GradientButton from 'src/components/GradientButton';
import { SpView } from 'src/components/SpView';
import ZeroSVG from 'src/components/SvgIcons/ZeroSVG';

import useBoundStore from '../../../../../../store/store';
import { FeederCardModal } from '../../constants/FeederCard';
import LoadingSpinner from 'src/components/Loader/Loader';

interface FooterActionsProps {
  onAction: (data: FeederCardModal) => void;
  deviceId?: number;
}

export const FeederFooterActions = ({ onAction, deviceId }: FooterActionsProps) => {
  const { t } = useTranslation();
  const { updateZeroScalesLoading, refreshUpdateZeroScalesStatus, asyncRequestState, loadingControl } =
    useBoundStore((state) => ({
      updateZeroScalesLoading: state.deviceStore.updateZeroScalesLoading,
      refreshUpdateZeroScalesStatus: state.deviceStore.refreshUpdateZeroScalesStatus,
      asyncRequestState: state.deviceStore.asyncRequestState,
      loadingControl: state.deviceStore.loadingControl,

    }));
  useLayoutEffect(() => {
    refreshUpdateZeroScalesStatus(deviceId);
  }, [asyncRequestState[deviceId]]);

  return (
    <SpView style={styles.column}>
      <GradientButton
        disabled={!!loadingControl.tare || updateZeroScalesLoading}
        isLoading={!!loadingControl.tare || updateZeroScalesLoading}
        colorsGradient={['rgba(0, 189, 136, 0.4)', 'rgba(45, 181, 142, 0.4)']}
        borderRadius={18}
        text={t('zero')}
        icon={
          <ZeroSVG
            color={colors.greyText.color}
            width={45}
            height={45}
            viewBox="0 0 28 28"
          />
        }
        action={() => onAction(FeederCardModal.BowlType)}
      />
      {(!!loadingControl.tare || updateZeroScalesLoading) && (
        <SpView style={styles.loadingSpinner}>
          <LoadingSpinner color="#ffffff" size="large" />
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  column: {
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  loadingSpinner: {
    position: 'absolute',
    height: '100%',
    justifyContent: 'center',
  },
});
