import React from 'react';
import { SpSwitch } from 'src/components/SpSwitch';
import { SpText } from '../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';

import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import colors from '@styles/colors';
import CurfewEnabledSVG from 'src/components/SvgIcons/CurfewEnabledSVG';

type CurfewTitleProps = {
  enabled: boolean;
  toggleCurfewAction: () => void;
  disabled: boolean;
};

const CurfewTitle = ({ enabled, toggleCurfewAction, disabled }: CurfewTitleProps) => {
  const { t } = useTranslation();
  return (
    <SpView
      width="100%"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      style={styles.container}>
      <SpView style={[disabled && styles.disabled]}>
        <CurfewEnabledSVG
          color={colors.greyText.color}
          width={34}
          height={34}
          viewBox="0 0 30 30"
        />
      </SpView>

      <SpText style={[styles.title, disabled && styles.disabled]}>{t('curfew')}</SpText>
      <SpView style={styles.bigger}>
        <SpSwitch
          disabled={disabled}
          checked={enabled}
          onChange={toggleCurfewAction}
        />
      </SpView>
    </SpView>
  );
};

export default CurfewTitle;

const styles = StyleSheet.create({
  container: {
    borderBottomColor: 'rgba(38, 58, 67, 0.15)',
    borderBottomWidth: 1,
    paddingBottom: 32,
  },
  title: {
    fontFamily: 'Rubik_Medium',
    fontSize: 20,
    color: colors.greyText.color,
    marginRight: 61,
    marginLeft: 16,
  },
  disabled: {
    opacity: 0.5,
  },
  bigger: {
    transform: [{ scale: Platform.OS === 'android' ? 1.8 : 1.2 }],
  },
});
