import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { SpVStack } from 'src/components/SpVStack';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

export interface SetupInstructionItem {
  icon: JSX.Element;
  title?: string;
  description?: string;
  link?: string;
  linkAction?: () => void;
}

export interface SetupInstructionProps {
  header?: JSX.Element;
  items: SetupInstructionItem[];
}

export const SetupInstruction = ({ header, items }: SetupInstructionProps) => {
  return (
    <SpView>
      {header}
      <SpVStack space={10}>
        {items.map((item, index) => {
          return (
            <SpView
              key={index}
              style={styles.listItem}>
              <SpView style={styles.listItemRight}>{item.icon}</SpView>
              <SpView style={styles.listItemLeft}>
                <SpText
                  size="md"
                  color="#263a43">
                  {item.title}
                </SpText>

                {item.description && (
                  <SpText
                    style={styles.description}
                    size="sm">
                    {item.description}
                  </SpText>
                )}

                {item.link && (
                  <TouchableOpacity onPress={item.linkAction}>
                    <SpText
                      style={styles.link}
                      size="sm"
                      fontFamily="Rubik_SemiBold">
                      {item.link}
                    </SpText>
                  </TouchableOpacity>
                )}
              </SpView>
            </SpView>
          );
        })}
      </SpVStack>
    </SpView>
  );
};

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  listItemRight: {
    width: 40,
    marginTop: 4,
    alignItems: 'center',
  },
  listItemLeft: {
    marginLeft: 16,
    width: 0,
    flexGrow: 1,
  },
  description: {
    marginTop: 4,
    color: '#263a43',
  },
  link: {
    marginTop: 4,
    color: '#03A2B1',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: '#03A2B1',
  },
});
