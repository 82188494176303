import React from 'react';
import colors from '@styles/colors';
import IndoorsSVG from '../SvgIcons/IndoorsSVG';
import { SpRoundIcon } from './SpRoundIcon';

interface SpHouseIconProps {
  action?: any;
  size?: number;
  iconSize?: number;
}

export const SpHouseIcon = ({ action, size = 12, iconSize = 23 }: SpHouseIconProps) => (
  <SpRoundIcon
    icon={
      <IndoorsSVG
        color={colors.greyText.color}
        width={iconSize}
        height={iconSize}
        viewBox="0 0 17 17"
      />
    }
    action={action}
    size={size}
    color={colors.pastelGreen.color}
    componentName="SpHouseIcon"
  />
);
