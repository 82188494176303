import { SpText } from '../SpText/SpText';
import { AppImages } from '@constants/AppImages';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-native';

interface SpDeviceOfflineProps {
  useImage?: boolean;
  deviceName?: string;
  center?: boolean;
}

export const SpDeviceOffline = ({
  useImage = true,
  deviceName,
  center = false,
}: SpDeviceOfflineProps) => {
  const { t } = useTranslation();

  if (useImage) {
    return (
      <Image
        source={AppImages.connectionNone}
        style={{
          width: 28,
          height: 28,
        }}
        resizeMode="contain"
        {...testProperties('noWifi', 'image', deviceName)}
      />
    );
  }

  return (
    <SpText
      {...testProperties('noWifi', 'text', deviceName)}
      color={colors.errorRed.color}
      size="sm"
      fontFamily="Rubik_SemiBold"
      align={center ? 'center' : 'left'}
    >
      {t('offline')}
    </SpText>
  );
};
