import { faDroplet, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { PetStatisticsModel } from '@models/Device';
import PetInfoSection from './PetInfoSection';
import { AdaptiveSize } from '../PetCard';
import { t } from 'i18next';
import React, { useMemo } from 'react';

type PetLastActionTime = {
  data: PetStatisticsModel['drinking'] | PetStatisticsModel['feeding'];
  isDrinking: boolean;
  disablePadding?: boolean;
  adaptiveSize: AdaptiveSize;
};

const PetDrinkingFeeding = ({
  data,
  isDrinking,
  disablePadding,
  adaptiveSize,
}: PetLastActionTime) => {
  const consumption = useMemo(() => {
    const events = data.activity.slice(-7);
    const daysWithEvents = events.filter(day => !!day.total_consumption).length;
    const sum = data.activity.reduce((curr, next) => {
      return curr + next.total_consumption;
    }, 0);
    const avg = daysWithEvents ? sum / daysWithEvents : 0;
    return avg ? Math.round(avg) : 0;
  }, [data, isDrinking]);

  return (
    <PetInfoSection
      icon={isDrinking ? faDroplet : faUtensils}
      avarageMl={isDrinking ? consumption : undefined}
      fontSizeForNumbers={adaptiveSize?.fontSizeForNumbers || 28}
      fontSizeForText={adaptiveSize?.fontSizeForText || 20}
      title={isDrinking ? t('drunk_today') : t('eaten_today')}
      data={`${data.total_consumption}`}
      disablePadding={disablePadding}
      type={isDrinking ? 'volume' : 'weight'}
    />
  );
};

export default PetDrinkingFeeding;
