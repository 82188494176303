import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from 'react-native-svg';

interface GridViewSVGProps extends SvgProps {
  active?: boolean;
}
const GridViewSVG = (props: GridViewSVGProps) => {
  return (
    <Svg width="34" height="34" viewBox="-1 -1 35 35" fill="none" {...props}>
      <Rect
        width="32"
        height="32"
        rx="8"
        fill={props.active ? 'white' : 'transparent'}
        opacity={props.active ? 0.2 : 1}
        strokeWidth={2.5}
        strokeOpacity={0.2}
        stroke="white"
      />
      <G clip-path="url(#clip0_3397_98637)">
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.88 8C8.86217 8 8 8.86217 8 9.88V13.48C8 14.4978 8.86217 15.36 9.88 15.36H13.48C14.4979 15.36 15.36 14.4978 15.36 13.48V9.88C15.36 8.86217 14.4979 8 13.48 8H9.88ZM9.6 9.88C9.6 9.74583 9.74582 9.6 9.88 9.6H13.48C13.6142 9.6 13.76 9.74583 13.76 9.88V13.48C13.76 13.6142 13.6142 13.76 13.48 13.76H9.88C9.74582 13.76 9.6 13.6142 9.6 13.48V9.88Z"
          fill="white"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.5201 8.00008C17.5023 8.00008 16.6401 8.86225 16.6401 9.88007V13.4801C16.6401 14.4979 17.5023 15.3601 18.5201 15.3601H22.1201C23.138 15.3601 24.0001 14.4979 24.0001 13.4801V9.88007C24.0001 8.86225 23.138 8.00008 22.1201 8.00008H18.5201ZM18.2401 9.88007C18.2401 9.74591 18.386 9.60007 18.5201 9.60007H22.1201C22.2543 9.60007 22.4001 9.74591 22.4001 9.88007V13.4801C22.4001 13.6142 22.2543 13.7601 22.1201 13.7601H18.5201C18.386 13.7601 18.2401 13.6142 18.2401 13.4801V9.88007Z"
          fill="white"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 18.5198C8 17.502 8.86217 16.6398 9.88 16.6398H13.48C14.4979 16.6398 15.36 17.502 15.36 18.5198V22.1198C15.36 23.1377 14.4979 23.9998 13.48 23.9998H9.88C8.86217 23.9998 8 23.1377 8 22.1198V18.5198ZM9.88 18.2398C9.74582 18.2398 9.6 18.3857 9.6 18.5198V22.1198C9.6 22.254 9.74582 22.3998 9.88 22.3998H13.48C13.6142 22.3998 13.76 22.254 13.76 22.1198V18.5198C13.76 18.3857 13.6142 18.2398 13.48 18.2398H9.88Z"
          fill="white"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.5201 16.6398C17.5023 16.6398 16.6401 17.502 16.6401 18.5198V22.1198C16.6401 23.1377 17.5023 23.9998 18.5201 23.9998H22.1201C23.138 23.9998 24.0001 23.1377 24.0001 22.1198V18.5198C24.0001 17.502 23.138 16.6398 22.1201 16.6398H18.5201ZM18.2401 18.5198C18.2401 18.3857 18.386 18.2398 18.5201 18.2398H22.1201C22.2543 18.2398 22.4001 18.3857 22.4001 18.5198V22.1198C22.4001 22.254 22.2543 22.3998 22.1201 22.3998H18.5201C18.386 22.3998 18.2401 22.254 18.2401 22.1198V18.5198Z"
          fill="white"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_3397_98637">
          <Rect width="16" height="16" fill="white" transform="translate(8 8)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default GridViewSVG;
