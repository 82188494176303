import { AppImages } from '@constants/AppImages';
import { DeviceType } from '@constants/Device';
import { testProperties } from '@utils/testProperties';
import React, { useMemo } from 'react';
import { Image } from 'react-native';

interface SpDeviceBatteryProps {
  type: DeviceType;
  battery: number;
}

const SpDeviceBattery = ({ type, battery }: SpDeviceBatteryProps) => {
  const image = useMemo(() => {
    if (type === DeviceType.Cerberus) {
      if (battery <= 4.5) {
        return AppImages.battery0Bar;
      }
      if (battery > 4.5 && battery <= 5.1) {
        return AppImages.battery1Bar;
      }
      if (battery > 5.1 && battery <= 5.4) {
        return AppImages.battery2Bar;
      }
      if (battery > 5.4 && battery <= 5.7) {
        return AppImages.battery3Bar;
      }
      if (battery > 5.7) {
        return AppImages.battery4Bar;
      }

      return null;
    }
    if (type === DeviceType.CatFlapConnect) {
      if (battery <= 5.0) {
        return AppImages.battery0Bar;
      }
      if (battery > 5.0 && battery <= 5.4) {
        return AppImages.battery1Bar;
      }
      if (battery > 5.4 && battery <= 5.6) {
        return AppImages.battery2Bar;
      }
      if (battery > 5.6 && battery <= 5.8) {
        return AppImages.battery3Bar;
      }
      if (battery > 5.8) {
        return AppImages.battery4Bar;
      }

      return null;
    }

    if (battery <= 5.1) {
      return AppImages.battery0Bar;
    }
    if (battery > 5.1 && battery <= 5.4) {
      return AppImages.battery1Bar;
    }
    if (battery > 5.3 && battery <= 5.6) {
      return AppImages.battery2Bar;
    }
    if (battery > 5.5 && battery <= 5.8) {
      return AppImages.battery3Bar;
    }
    if (battery > 5.7) {
      return AppImages.battery4Bar;
    }

    return null;
  }, [type, battery]);

  if (!image) {
    return null;
  }

  return (
    <Image
      style={{ height: 16, width: 28 }}
      source={image}
      resizeMode="contain"
      {...testProperties(`${DeviceType[type]}_${battery}`, 'image')}
    />
  );
};

export default SpDeviceBattery;
