import { useUserHasAdminAccess } from '@hooks/useUserHasAdminAccess';
import { SpEditMenuStack } from 'src/components/SpEditMenuStack';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { HouseholdStackParamList } from '..';
import useBoundStore from '../../../store/store';

export const HouseholdSettings = () => {
  const { activeHousehold } = useBoundStore(state => state.householdStore);
  const userHasAdminAccess = useUserHasAdminAccess();
  const { t } = useTranslation();

  const navigation = useNavigation<NavigationProp<HouseholdStackParamList>>();

  const changeHouseholdTimezoneFormat = () => {
    let timezoneName: string = activeHousehold?.timezone?.timezone
      .split('/')
      ?.reverse()
      ?.join(', ');
    const timezoneTime = activeHousehold?.timezone.name?.split(' ')[0];

    if (timezoneName.includes('_')) {
      timezoneName = timezoneName.split('_').join(' ');
    }

    return `${timezoneName} ${timezoneTime}` || activeHousehold.timezone.name;
  };

  const settingButtons = [
    {
      label: t('household_name'),
      value: activeHousehold?.name,
      viewOnly: !userHasAdminAccess,
      action: () =>
        navigation.navigate('HouseholdEditSetting', {
          label: 'Name',
          inputName: 'name',
          inputValue: activeHousehold?.name,
        }),
    },
    {
      label: t('timezone'),
      value: changeHouseholdTimezoneFormat(),
      viewOnly: !userHasAdminAccess,
      action: () =>
        navigation.navigate('HouseholdEditSetting', {
          label: 'Timezone',
          inputName: 'timezone_id',
          inputValue: activeHousehold?.timezone_id,
        }),
    },
  ];
  return (
    <View style={styles.container}>
      <SpEditMenuStack buttons={settingButtons} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 19,
    backgroundColor: colors.white.color,
    paddingTop: 23,
  },
});
