import { SpText } from '../../../../../../components/SpText/SpText';
import SpTransformStringToMultipleTexts from 'src/components/SpTransformStringToMultipleTexts';
import { SpView } from 'src/components/SpView';
import { GroupReportPointModel, MappedConsumptionReportDataModel } from '@models/ReportModel';
import colors from '@styles/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet } from 'react-native';
import DropShadow from 'react-native-drop-shadow';
import useAvarageGrouping from '../../../../hooks/useAvarageGrouping';

export interface PetAverageStatsFeedsProps {
  data: GroupReportPointModel<MappedConsumptionReportDataModel>[];
}

export const PetAverageStatsFeeds = ({ data }: PetAverageStatsFeedsProps) => {
  const { t } = useTranslation();
  const screenWidth = Dimensions.get('screen').width;
  const [averageActivity, duration] = useAvarageGrouping(data);

  return (
    <SpView style={styles.averageContainer}>
      <DropShadow style={styles.averageCardShadow}>
        <SpView style={styles.averageCard}>
          <SpText
            style={styles.averageCardLabel}
            numberOfLines={1}
            adjustsFontSizeToFit>
            {t('avg_feeds')}
          </SpText>
          <SpText
            style={styles.averageCardValue}
            numberOfLines={1}
            adjustsFontSizeToFit>
            {averageActivity}
          </SpText>
        </SpView>
      </DropShadow>
      <DropShadow style={styles.averageCardShadow}>
        <SpView style={styles.averageCard}>
          <SpText
            style={styles.averageCardLabel}
            numberOfLines={1}
            adjustsFontSizeToFit>
            {t('avg_duration')}
          </SpText>
          <SpTransformStringToMultipleTexts
            mainStyle={{
              fontSize: screenWidth < 400 ? 16 : 28,
              color: colors.greyText.color,
              fontFamily: 'Rubik_SemiBold',
            }}
            data={[
              duration.timeFirst,
              duration.unitFirst,
              duration.timeSecond,
              duration.unitSecond,
            ]}
          />
        </SpView>
      </DropShadow>
    </SpView>
  );
};

const styles = StyleSheet.create({
  averageContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  averageCardShadow: {
    width: '47%',
    minHeight: 92,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
  },
  averageCard: {
    minHeight: 92,
    alignItems: 'center',
    padding: 16,
    borderRadius: 16,
    backgroundColor: colors.white.color,
  },
  averageCardLabel: {
    color: colors.greySmallTitle.color,
    fontSize: 16,
    lineHeight: 18,
    marginBottom: 10,
  },
  averageCardValue: {
    color: colors.greyText.color,
    fontSize: 28,
    lineHeight: 28,
    fontFamily: 'Rubik_SemiBold',
  },
});
