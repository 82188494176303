import { DeviceFeederTareType } from '@constants/Device';
import { TimelineEventModel } from '@models/Timeline';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import TimelineDevicePhoto from './TimelineDevicePhoto';

export const Tare = ({ event, active }: { event: TimelineEventModel; active: boolean }) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);

  const parsedData = useMemo(() => {
    return JSON.parse(event.data);
  }, [event.data]);

  const text = useMemo(() => {
    let translationKey = '';
    switch (parsedData.tare_type) {
      case DeviceFeederTareType.Left:
        translationKey = 'notification_tare_left';
        break;
      case DeviceFeederTareType.Right:
        translationKey = 'notification_tare_right';
        break;
      default:
        translationKey = 'notification_tare';
    }

    return t(translationKey, {
      devicename: deviceName,
    });
  }, [parsedData, deviceName, t]);

  return (
    <TimelineCard
      active={active}
      image={
        <TimelineDevicePhoto
          active={active}
          event={event}
        />
      }
      text={text}
      date={event.created_at}
    />
  );
};
