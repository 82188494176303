import { TimelineEventModel } from '@models/Timeline';
import { get } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelinePetName } from '../../hooks/useTimelinePetName';
import { TimelineCard } from './TimelineCard';
import { TimelineUserPhoto } from './TimelineUserPhoto';

export const PetPermissionsChanged = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const userName = useMemo(() => get(event, 'users[0].name', ''), [event]);
  const petName = useTimelinePetName(event);
  const parsedData = useMemo(() => {
    return JSON.parse(event.data);
  }, [event.data]);

  const text = useMemo(() => {
    const { profile } = parsedData;

    if (userName) {
      return t(
        profile === 3
          ? 'timeline_access_pemissions_inside_only_enabled_in_app'
          : 'timeline_access_pemissions_inside_only_disabled',
        {
          user: userName,
          pet: petName,
        },
      );
    }

    return t(
      profile === 3
        ? 'timeline_access_pemissions_inside_only_enabled'
        : 'timeline_access_pemissions_inside_only_disabled',
      {
        pet: petName,
      },
    );
  }, [parsedData, petName, userName, t]);
  return (
    <TimelineCard
      active={active}
      image={
        <TimelineUserPhoto
          showPhoto
          active={active}
          event={event}
        />
      }
      text={text}
      date={event.created_at}
    />
  );
};
