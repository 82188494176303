import { DeviceType } from '@constants/Device';
import { PetModel } from '@models/Pet';
import { DeviceModel } from '@models/Device';
import { navigateToProducts } from '@utils/navigationShortcuts';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../../../index';
import { useCallback, useMemo } from 'react';

type CheckToLeavePetFlowProps = {
  deviceType: DeviceType;
  returnToStep: number;
  comingFromProfile: boolean;
  deviceId: DeviceModel['id'];
  existingDevice: boolean;
  pets: PetModel[];
};

const useCheckToLeavePetFlow = ({
  deviceType,
  returnToStep,
  comingFromProfile,
  deviceId,
  existingDevice,
  pets,
}: CheckToLeavePetFlowProps) => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const navigateToDogBowl = useCallback(() => {
    navigation.navigate('FlowNavigation', {
      screen: 'AddDogBowl',
      params: {
        step: returnToStep,
      },
    });
    return false;
  }, [navigation, returnToStep]);
  const navigateToFeeder = useCallback(() => {
    navigation.navigate('FlowNavigation', {
      screen: 'AddFeeder',
      params: {
        step: !existingDevice ? 5 : returnToStep,
        deviceId: deviceId,
      },
    });
    return false;
  }, [navigation, returnToStep, deviceId]);
  const navigateToFelaqua = useCallback(() => {
    navigation.navigate('FlowNavigation', {
      screen: 'AddFelaqua',
      params: {
        step: !existingDevice ? 7 : returnToStep,
        deviceId: deviceId,
      },
    });
    return false;
  }, [navigation, returnToStep, deviceId]);
  const leavePetFlow = useMemo(() => {
    if (deviceType === DeviceType.Cerberus) {
      return navigateToDogBowl;
    }

    if (!(comingFromProfile && existingDevice && pets.length > 1)) {
      if (deviceType === DeviceType.FelaquaConnect) {
        return navigateToFelaqua;
      }
      if (deviceType === DeviceType.FeederConnect) {
        return navigateToFeeder;
      }
    }
    return () => {
      navigateToProducts();
      return false;
    };
  }, [
    comingFromProfile,
    deviceType,
    existingDevice,
    navigateToDogBowl,
    navigateToFeeder,
    navigateToFelaqua,
    pets.length,
  ]);
  return { leavePetFlow };
};

export default useCheckToLeavePetFlow;
