import { PetModel } from '@models/Pet';
import React from 'react';
import { StyleSheet } from 'react-native';

import { Pet2UpCard } from './components/Pet2UpCard';
import { SpView } from '../../../../components/SpView';

interface Pet2CardsProps {
  pets: PetModel[];
}

export const Pet2Cards = ({ pets }: Pet2CardsProps) => {
  return (
    // 2 elements <SpView> that are 100% wide, and 50% tall in a flex container
    <SpView style={styles.contentContainer}>
      {pets && pets[0] && <Pet2UpCard pet={pets[0]} />}
      {pets && pets[1] && <Pet2UpCard pet={pets[1]} />}
    </SpView>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    width: '100%',
    flexGrow: 1, // Ensures that the FlatList fills the available space
    paddingHorizontal: 10,
    paddingVertical: 10,
  },

  item: {
    flex: 1, // Takes up 1/2 of the space for 2 columns
    margin: 10,
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  text: {
    fontSize: 16,
  },
});
