import { applyInterceptors } from '@api/Http';
import { SpLoading } from 'src/components/SpLoading';
import { useDeviceId } from '@hooks/useDeviceId';
import { CommonActions, NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import persistStorage from '@utils/persistStorage';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';

import { navigationRef } from './RootNavigation';
import { RootStackParamList } from './index';
import { Env } from '../../Env';
import useBoundStore from '../store/store';

type Props = NativeStackScreenProps<RootStackParamList, 'AuthTokenLogin'>;

const AuthTokenLogin = ({ route }: Props) => {
  const { setUser, quietLogout, quietLogin, user } = useBoundStore(state => state.accountStore);
  const newToken = useBoundStore(state => state.accountStore.token);

  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const token = route?.params?.token;
  const newEnv = route?.params?.env;
  const deviceId = useDeviceId();
  const [running, setRunning] = useState(false);
  const [hasNavigated, setHasNavigated] = useState(false);

  const loginRedirect = () => {
    console.log('Redirecting to login');
    rootNavigation.navigate('OnboardingLetsStart');
    setUser({ user: null, token: null });
  };
  const goToDashboard = useCallback(() => {
    Platform.OS === 'web' && (window.location.href = '/');
    navigationRef.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'DashboardNavigation', params: { screen: 'Pets' } }],
      }),
    );
  }, [navigationRef]);

  useEffect(() => {
    if (token) {
      console.log('Token:', token);
    }
    //check when the stores have finished doing their thing instead of waiting Xms
    if (user && newToken === token && !hasNavigated) {
      goToDashboard();
      setHasNavigated(true); // Prevent future invocations once the navigation has occurred
    }
  }, [newToken, user, token, hasNavigated]);

  useEffect(() => {
    if (running) {
      return;
    }

    const doLogoutAndReset = async () => {
      try {
        if (token) {
          await persistStorage.clearAll();
          await quietLogout(deviceId, token);
          if (newEnv) {
            console.log('Switching to new environment', newEnv);
            // alert(`Switched to ${newEnv} environment`);

            Env.APP_ENV = newEnv;
            applyInterceptors({
              getStoreOptions: () => {
                return {
                  token,
                  // locale if user is logged in, otherwise default
                  colour: null,
                  locale: 'en',
                };
              },
              envOveride: newEnv,
            });
          }
          setTimeout(async () => {
            console.log('attempting new login');
            await quietLogin(token);
          }, 500);
        } else {
          loginRedirect();
        }
      } catch (err) {
        console.log(' auth token error', err);
        loginRedirect();
      }
    };
    if (navigationRef.isReady() && !running && token && deviceId) {
      setRunning(true);
      doLogoutAndReset();
    }
  }, [
    token,
    navigationRef,
    running,
    setRunning,
    deviceId,
    quietLogout,
    loginRedirect,
    goToDashboard,
    newEnv,
  ]);

  return (
    <SafeAreaView style={styles.container}>
      <SpLoading />
    </SafeAreaView>
  );
};

export default AuthTokenLogin;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 19,
  },
  message: { lineHeight: 24, marginTop: 36 },
  title: {
    fontSize: 20,
    marginTop: 12,
    lineHeight: 28,
    color: colors.greyText.color,
    fontFamily: 'Rubik_Medium',
  },
  buttonWrap: {
    position: 'absolute',
    bottom: 34,
    right: 19,
    left: 19,
  },
});
