import { TimelineEventModel } from '@models/Timeline';
import { get } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import { TimelineUserPhoto } from './TimelineUserPhoto';

export const TargetWeightSet = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);
  const userName = get(event, 'users[0].name', '');

  const text = useMemo(() => {
    if (event.users) {
      return event.devices && event.devices.length
        ? t('notification_target_weight_set', {
            userName,
            deviceName,
          })
        : t('notification_target_weight_set_generic', {
            userName,
          });
    }

    return event.devices && event.devices.length
      ? t('notification_target_weight_set_anonymous', {
          deviceName,
        })
      : t('notification_target_weight_set_anonymous_generic');
  }, [event, deviceName, t]);

  return (
    <TimelineCard
      active={active}
      image={
        <TimelineUserPhoto
          showPhoto
          active={active}
          event={event}
        />
      }
      text={text}
      date={event.created_at}
    />
  );
};
