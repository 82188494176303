import { useState } from 'react';

export const useBreedsAndSpeciesHandling = () => {
  const [breedSelected, setBreedSelected] = useState({
    breed_id: true,
    breed_id2: true,
  });

  const handleSpecie = (field: string, value: boolean) => {
    setBreedSelected(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  return { breedSelected, handleSpecie };
};
