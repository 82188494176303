/* eslint-disable react/destructuring-assignment */
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';

import { faCheckCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import useAdaptiveFontSize from '@hooks/useAdaptiveFontSize';
import atomStyles from '@styles/atoms';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { ReportRangeType } from 'src/pages/Dashboard/constants/ReportRangeType';

type SingleProps = {
  type: 'single';
};

type DoubleProps = {
  type: 'double';
  isNormal: boolean;
};

type Props = {
  data: string;
  dataType: string;
  rangeType: ReportRangeType;
} & (SingleProps | DoubleProps);

const DrinkingAndFeedingStatistics: React.FC<Props> = props => {
  const { t } = useTranslation();
  const allowInsights = false;

  return (
    <SpView style={styles.mainContainer}>
      {props.type === 'double' && allowInsights ? (
        <SpView style={atomStyles.rowContainer}>
          <AvgStatusComponent
            {...props}
            data={props.data}
          />
          <SpView style={atomStyles.statusCardContainer}>
            <SpText style={[textStyles.longTitle, styles.title]}>
              {t(props.isNormal ? 'drinking_normal_card_title' : 'change_in_drinking_card_title')}
            </SpText>
            <SpView style={[styles.iconWrapper, styles.rightWrapper]}>
              <FontAwesomeIcon
                color={props.isNormal ? colors.green.color : colors.orangeWarning.color}
                size={32}
                icon={props.isNormal ? faCheckCircle : faCircleExclamation}
              />
            </SpView>
          </SpView>
        </SpView>
      ) : (
        <AvgStatusComponent
          {...props}
          data={props.data}
        />
      )}
    </SpView>
  );
};

const AvgStatusComponent = ({ data, dataType, rangeType }) => {
  const { t } = useTranslation();
  const [size, sizeLayout, opacityStyle] = useAdaptiveFontSize(
    {
      value: 28,
      type: 20,
    },
    [data, dataType],
  );

  const values = useMemo(() => {
    // split by first space
    return {
      value: Math.round(data.substring(0, data.indexOf(' '))),
      dataType: data.substring(data.indexOf(' ') + 1),
    };
  }, [data]);

  return (
    <SpView style={styles.ReportAvgContainer}>
      <SpView style={styles.AvgStatistics}>
        <SpText style={textStyles.defaultText}>{t('days_avg', {days: rangeType})}</SpText>
        <SpText
          style={[textStyles.title, styles.mainDataText, { fontSize: 24 }, opacityStyle]}
          onTextLayout={sizeLayout}
        >
          {values.value}
          <SpText
            style={[
              textStyles.title,
              styles.mainDataText,
              styles.smallSize,
              { fontSize: size.type },
            ]}
          >
            {values.dataType}
          </SpText>
        </SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  iconWrapper: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: colors.white.color,
    position: 'absolute',
  },
  title: {
    marginBottom: 0,
    padding: 3,
  },
  rightWrapper: {
    right: -16,
  },
  leftWrapper: {
    left: -16,
  },
  mainDataText: {
    marginLeft: 0,
    lineHeight: undefined,
  },
  smallSize: {
    marginLeft: 4,
  },
  mainContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8
  },
  ReportAvgContainer: {
    paddingVertical: 24,
  },
  AvgStatistics: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
});

export default memo(DrinkingAndFeedingStatistics);
