import { PetBowlFoodType } from '@constants/Device';
import { TimelineEventType } from '@constants/Timeline';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { TimelineEventModel } from '@models/Timeline';
import { WeightFrameModel, WeightModel } from '@models/Weight';
import { filter, get, map } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFoodTypeTranslation } from '../../hooks/useFoodTypeTranslation';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import TimelineDevicePhoto from './TimelineDevicePhoto';

const maximumDifference = 1;

export const WeightChanged = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);
  const isWater = event.type === TimelineEventType.PoseidonWeightChanged;
  const foodTypeTranslation = useFoodTypeTranslation();
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const checkIsTopup = (frames: WeightFrameModel[]) => {
    const totalInitial = map(frames, f => (f.change > 0 ? f.current_weight - f.change : 0)).reduce(
      (c, f) => c + f,
    );

    return totalInitial > maximumDifference;
  };

  const checkTargetWeight = (targetWeight: number, weight: number) => {
    return !targetWeight ? false : Math.abs(weight - targetWeight) <= maximumDifference;
  };

  const getSingleBowlText = (
    total: number,
    isIncrease: boolean,
    isTopup: boolean,
    isPortion: boolean,
    foodType: PetBowlFoodType,
  ) => {
    const key = `notification_${isWater ? 'litre' : 'weight'}_changed_single_${
      isIncrease ? 'increase' : 'decrease'
    }_${isTopup ? 'topup' : 'fill'}_${isPortion ? 'portion' : 'change'}`;

    return t(key, {
      devicename: deviceName,
      foodtype: foodTypeTranslation(foodType),
      gram: convertWithUnits((isIncrease ? total : -total), !isWater),
    }).replace(/\s+/g, ' ');
  };

  const getTotalWeight = (frames: WeightFrameModel[]) => {
    if (!frames) {
      return {
        total: 0,
        isTopup: false,
      };
    }

    return {
      total: Math.round(
        map(frames, f => f.current_weight).reduce((c, f) => c + f) -
          map(frames, f => f.current_weight - f.change).reduce((c, f) => c + f),
      ),
      isTopup: checkIsTopup(frames),
    };
  };

  const handleDualBowl = (data: any, weight: WeightModel) => {
    const total = getTotalWeight(weight?.frames);
    const isIncrease = total.total > 0;

    const leftFoodType = get(data, 'weight.food_type.0');
    const leftFrame = filter(weight.frames, f => f.index === 0)[0];
    const leftTargetWeight = get(data, 'weight.target.0');
    let leftWeight = leftFrame.change;
    leftWeight = leftWeight <= 0 ? 0 : leftWeight;
    leftWeight = Math.round(leftWeight);
    const leftIsTopup = leftFrame.current_weight - leftFrame.change > maximumDifference;
    const leftPortion = checkTargetWeight(leftTargetWeight, leftWeight);

    const rightFoodType = get(data, 'weight.food_type.1');
    const rightFrame = filter(weight.frames, f => f.index === 1)[0];
    const rightTargetWeight = get(data, 'weight.target.1');
    let rightWeight = rightFrame.change;
    rightWeight = rightWeight <= 0 ? 0 : rightWeight;
    rightWeight = Math.round(rightWeight);
    const rightIsTopup = rightFrame.current_weight - rightFrame.change > maximumDifference;
    const rightPortion = checkTargetWeight(rightTargetWeight, rightWeight);

    if (leftWeight === 0 || rightWeight === 0) {
      return getSingleBowlText(
        leftWeight || rightWeight,
        isIncrease,
        total.isTopup,
        leftWeight ? leftPortion : rightPortion,
        leftWeight ? leftFoodType : rightFoodType,
      );
    }

    // eslint-disable-next-line max-len
    const key = `notification_weight_changed_dual_${
      isIncrease ? 'increase' : 'decrease'
    }_${leftIsTopup ? 'topup' : 'fill'}_${leftPortion ? 'portion' : 'change'}_${
      rightIsTopup ? 'topup' : 'fill'
    }_${rightPortion ? 'portion' : 'change'}`;
    return t(key, {
      devicename: deviceName,
      gram1: convertWithUnits(leftWeight, !isWater),
      foodtype1: foodTypeTranslation(leftFoodType),
      gram2: convertWithUnits(rightWeight, !isWater),
      foodtype2: foodTypeTranslation(rightFoodType),
    });
  };

  const handleSingleBowl = (data: any, weight: WeightModel): string => {
    const foodType = get(data, 'weight.food_type.0');
    const total = getTotalWeight(weight?.frames);
    const isPortion = checkTargetWeight(get(data, 'weight.target.0'), total.total);

    return getSingleBowlText(total.total, total.total > 0, total.isTopup, isPortion, foodType);
  };

  const text = useMemo(() => {
    const data = JSON.parse(event.data);
    const weight = event.weights ? event.weights[0] : null;
    const counter = get(data, 'weight.count') || 1;

    if (counter === 1) {
      return handleSingleBowl(data, weight);
    }

    return handleDualBowl(data, weight);
  }, [event, deviceName, isWater, t]);

  return (
    <TimelineCard
      active={active}
      text={text}
      date={event.created_at}
      image={
        <TimelineDevicePhoto
          active={active}
          event={event}
        />
      }
    />
  );
};
