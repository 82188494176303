import { SpText } from '../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { StepsObjWrap } from '@models/FormikTypes';
import { SpTermsCheckboxState } from 'src/components/SpTermsCheckboxes';
import SpTitle from 'src/components/SpTitle';
import StepFormCreator from 'src/components/StepFormCreator';
import { CommonActions, NavigationProp, useNavigation } from '@react-navigation/native';
import textStyles from '@styles/text';
import checkPassword, { PasswordTypes } from '@utils/checkPassword';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import PassowrdForm from 'src/pages/OnboardingLetsStart/Register/forms/PasswordForm';
import useBoundStore from 'src/store/store';
import { useDeviceId } from '@hooks/useDeviceId';
import { RootStackParamList } from '../index';
import useValidation from '../validation';
import Announcement from './components/Announcement';
import ConfirmPasswordNew from './components/ConfirmPassword';
import EndUserLicence from './components/EndUserLicence';
import ResetPassword from './components/ResetPassword';
import WhatIsChanging from './components/WhatIsChanging';

type Props = NativeStackScreenProps<RootStackParamList, 'ReconfirmTermsAndConditions'>;

const ReconfirmTermsAndConditions: React.FC<Props> = ({ route }) => {
  const { daysToExpire } = route.params;
  const [step, setStep] = useState(1);
  const [terms, setTerms] = useState<SpTermsCheckboxState>({
    endUserLicenceAgreement: false,
    privacyPolicy: false,
  });
  const { passwordStepSchema } = useValidation();
  const deviceId = useDeviceId();
  const [loading, setLoading] = useState(false);
  const { user, updateUser, acceptTerms, logout } = useBoundStore(state => state.accountStore);
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const hideStepformProps = {
    hideButton: true,
    hideProgressHeader: true,
    hideCustomHeader: true,
  };

  const passwordCheckedAction = async (password: string): Promise<void> => {
    const match = checkPassword(password);

    if (match === PasswordTypes.invalid) {
      setLoading(false);
      setStep(4);
    } else {
      await updateTerms();
      setLoading(false);
    }
  };

  const PasswordFormWrap = useCallback(
    (children: JSX.Element) => (
      <SpView>
        <SpTitle text={t('new_password_required')} />
        <SpText style={[textStyles.defaultText, styles.customText]}>
          {t('new_password_description')}
        </SpText>
        {children}
      </SpView>
    ),
    [],
  );

  const updateTerms = async () => {
    const accepted = await acceptTerms();
    if (!accepted) {
      return;
    }
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [{ name: 'DashboardNavigation', params: { screen: 'Pets' } }],
      }),
    );
  };

  const steps: StepsObjWrap = {
    0: {
      ui: EndUserLicence,
      ...hideStepformProps,
      props: {
        setStep,
      },
      handleAndroidBackPress: () => {
        setStep(2);
        return true;
      },
    },
    1: {
      ui: Announcement,
      ...hideStepformProps,
      props: {
        setStep,
        daysToExpire,
      },
      // handleAndroidBackPress => alert => sure you want to leave the app?
    },
    2: {
      ui: WhatIsChanging,
      hideProgressHeader: true,
      hideButton: true,
      props: {
        setStep,
        terms,
        setTerms,
      },
    },
    3: {
      hideProgressHeader: true,
      ui: ConfirmPasswordNew,
      props: {
        loading,
        onPasswordChecked: passwordCheckedAction,
        onSetLoading: (data: boolean) => setLoading(data),
        navigateToLogin: () => {
          logout(deviceId);
          navigation.reset({
            index: 0,
            routes: [{ name: 'LoginNavigation', params: { screen: 'Login' } }],
          });
        },
        navigateToForgotPassword: () => setStep(5),
      },
      hideButton: true,
    },
    4: {
      ui: PassowrdForm,
      props: { label: 'password', placeholder: 'type_password' },
      hideProgressHeader: true,
      formik: {
        schema: passwordStepSchema,
        names: ['password', 'retypePassword'],
      },
      wrap: PasswordFormWrap,
      formikValidInterceptor: async ({
        formik: {
          values: { password },
        },
      }) => {
        try {
          await updateUser({ ...user, password });
          await updateTerms();
        } catch (e) {}
        return false;
      },
      extraScrollHeightAndroid: 200,
    },
    5: {
      ui: ResetPassword,
      ...hideStepformProps,
      props: {
        setStep,
      },
      handleAndroidBackPress: () => {
        setStep(prev => prev - 2);
        return true;
      },
    },
  };

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      steps={steps}
      skipSumbitValues
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
    />
  );
};

const styles = StyleSheet.create({
  customText: {
    marginBottom: 24,
  },
});

export default ReconfirmTermsAndConditions;
