import React, { useCallback, useState } from 'react';
import { TimeUnits } from '@constants/DateFormat';
import { StyleSheet, SafeAreaView, View } from 'react-native';

import SelectButton from 'src/components/SpSelectButton';
import SpTitle from 'src/components/SpTitle';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import { useTranslation } from 'react-i18next';

export interface AccountTimeProps {
  selectedTime: keyof typeof TimeUnits;
  handleChange: (unit: number) => void;
}

const AccountTimeWrapper = ({ selectedTime, handleChange }: AccountTimeProps) => {
  const [timeUnit, setTimeUnit] = useState(TimeUnits[selectedTime]);
  const units = enumToDescriptedArray(TimeUnits);
  const { t } = useTranslation();
  const onUnitChange = useCallback(
    (unit: number) => {
      handleChange(unit);
      setTimeUnit(unit);
    },
    [timeUnit, setTimeUnit],
  );
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.inner}>
        <SpTitle text={t('time_format')} />
        {units.map(unit => {
          const isActive = unit.id === timeUnit;
          return (
            <SelectButton
              key={unit.id}
              isActive={isActive}
              option={unit}
              setActive={onUnitChange}
              withIcon
            />
          );
        })}
      </View>
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inner: {
    flex: 1,
    paddingHorizontal: 20,
  },
});

export default AccountTimeWrapper;
