import { useEffect, useState } from 'react';
import NetInfo from '@react-native-community/netinfo';
import useBoundStore from 'src/store/store';
import { DdLogs } from '../services/SPDataDogService';

const useNetworkStatus = () => {
  const [networkState, setNetworkState] = useState(null);
  const { settingUpWiFiDevice } = useBoundStore(s => s.WiFiDeviceStore);
  const runManualCheck = async () => {
    const state = await NetInfo.fetch();
    console.log('runManualCheck', state);
    DdLogs.info('useNetworkStatus - runManualCheck');

    setNetworkState(state);
  };

  useEffect(() => {
    DdLogs.info('useNetworkStatus - useEffect');

    const unsubscribe = NetInfo.addEventListener(state => {
      setNetworkState(state);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return { networkState, disableNetworkCheck: settingUpWiFiDevice, runManualCheck };
};

export default useNetworkStatus;
