import i18n from '@utils/i18n';
import { isWithinInterval, parse } from 'date-fns';

export const curfew = (lock: string, unlock: string): boolean => {
  const lockTime = parse(lock, 'HH:mm', new Date());
  const unlockTime = parse(unlock, 'HH:mm', new Date());

  // if unlock time (06.00) is before the lock time (20.00) then we need to add a day to the unlock time
  if (lockTime.getHours() > unlockTime.getHours()) {
    unlockTime.setDate(unlockTime.getDate() + 1);
  }
  const currentTime = new Date();

  return !!(
    currentTime.getTime() === lockTime.getTime() ||
    isWithinInterval(currentTime, { start: lockTime, end: unlockTime })
  );
};

export const getCurfewStatusText = (enabled: boolean): string => {
  return enabled ? i18n.t('curfew_enabled') : i18n.t('curfew_disabled');
};
