import { StyleSheet, View } from 'react-native';
import React from 'react';
import { FormikProps } from 'formik';
import { FieldsObj } from '@models/FormikTypes';

import useBoundStore from 'src/store/store';
import SpTitle from 'src/components/SpTitle';

import SpEditMenuItem from 'src/components/SpEditMenuItem';
import { useTranslation } from 'react-i18next';
import { closeModal, openModal } from 'src/services/ModalBox';
import SpModal from 'src/components/SpModal';
import CustomHeader from 'src/components/CustomHeader';
import HouseholdTimezonesWrapper from '../../components/HouseholdTimezonesWrapper';

interface FormValues {
  timezone_id: string;
}

interface ITimezoneFormProps {
  formik: FormikProps<FormValues>;
  submitErrors: FieldsObj;
}

const TimezoneForm = ({ formik, submitErrors }: ITimezoneFormProps) => {
  const { getTimezoneByID } = useBoundStore(state => state.timezoneStore);
  const { t } = useTranslation();

  const changeHouseholdTimezoneFormat = () => {
    if (!formik.values?.timezone_id) return t('not_selected');

    const activeTimezone = getTimezoneByID(Number(formik.values?.timezone_id));
    let timezoneName: string = activeTimezone.timezone.split('/')?.reverse()?.join(', ');
    const timezoneTime = activeTimezone?.name?.split(' ').at(-1);

    if (timezoneName.includes('_')) {
      timezoneName = timezoneName.split('_').join(' ');
    }

    return `${timezoneName} ${timezoneTime}` || activeTimezone.name;
  };

  return (
    <View>
      <SpTitle text={t('choose_timezone')} />
      <SpEditMenuItem
        label={t('timezone')}
        value={changeHouseholdTimezoneFormat()}
        action={() => {
          openModal('CreateHouseholdTimezoneSelect');
        }}
      />
      <SpModal
        backdropColor="white"
        bottomAreaView={false}
        modalName="CreateHouseholdTimezoneSelect"
        onRequestClose={() => closeModal('CreateHouseholdTimezoneSelect')}>
        <View style={styles.timezoneModalWrapper}>
          <CustomHeader
            withTitle={false}
            goBackFunc={() => closeModal('CreateHouseholdTimezoneSelect')}
          />
          <HouseholdTimezonesWrapper
            withButton
            handleChange={id => {
              if (id) {
                formik.setTouched({ timezone_id: true });
                return formik.handleChange('timezone_id')(String(id));
              }
              return null;
            }}
            handleClose={() => closeModal('CreateHouseholdTimezoneSelect')}
            currentTimezoneId={Number(formik.values?.timezone_id)}
          />
        </View>
      </SpModal>
    </View>
  );
};

export default TimezoneForm;

const styles = StyleSheet.create({
  timezoneModalWrapper: {
    flex: 1,
    width: '100%',
  },
});
