import { SpView } from 'src/components/SpView';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../../components/SpText/SpText';
import React from 'react';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import ZeroSVG from 'src/components/SvgIcons/ZeroSVG';

const ZeroScalesSlide = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <SpView style={styles.iconWrapper}>
        <ZeroSVG
          color={colors.greyText.color}
          width={51}
          height={48}
          viewBox="0 0 28 28"
        />
      </SpView>
      <SpTitle
        style={styles.title}
        align="center"
        text={t('zero_scales')}
      />
      <SpText style={styles.contentText}>{t('zero_scales_tour_text')}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  title: {
    paddingTop: 16,
  },
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 32,
  },
});

export default ZeroScalesSlide;
