import { WeightUnits } from '@constants/WeightUnits';
import { faClock, faDroplet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { DeviceModel, PetStatisticsModel, WithFillPercent } from '@models/Device';
import colors from '@styles/colors';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { SpDivider } from 'src/components/SpDivider';
import { SpProgressBar } from 'src/components/SpProgressBar';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import PetLastActionTime from 'src/pages/Dashboard/Pets/pages/PetCarousel/components/PetLastActionTime';

import { MenuActions } from './MenuActions';
import useLastDate from '../../hooks/useLastDate';
import { getFillPercentage } from '../../utils/getFillingPercentage';
import { DeviceSettingMenuItem } from '../DeviceSettingMenuItem';
import { DeviceStatusHeader } from '../DeviceStatusHeader';
import FeedingIconSVG from '../../../../../../components/SvgIcons/FeedingIconSVG';

interface CerberusCardProps {
  data: DeviceModel & WithFillPercent;
  userHasWriteAccess: boolean;
}
enum SubstanceTypes {
  Water = 1,
  Food,
}
enum FoodTypes {
  Wet = 1,
  Dry,
  Wet_and_dry,
}
export const CerberusCard = ({ data, userHasWriteAccess }: CerberusCardProps) => {
  const online = data?.status.online;
  const substanceType = data.control.substance_type ?? data.control.data?.data?.substance_type ?? 0;
  const foodType = data.control.food_type ?? data.control.data?.data?.food_type ?? 0;
  const { t } = useTranslation();
  const percent = getFillPercentage(data, false);
  const lastFilled = useLastDate(data.status as any, 'last_filled_at');
  const lastActionData = useMemo(() => {
    if (typeof lastFilled === 'string') return undefined;
    return {
      last_consumption: lastFilled,
    } as any;
  }, [lastFilled]);
  const titleKey = useMemo(() => {
    if (substanceType === SubstanceTypes.Water) {
      return `${SubstanceTypes[substanceType].toLowerCase()}_remaining`;
    } else if (substanceType === SubstanceTypes.Food) {
      const foodTypePartKey = FoodTypes[foodType] ? `${FoodTypes[foodType].toLowerCase()}_` : '';
      return `${foodTypePartKey}${SubstanceTypes[substanceType].toLowerCase()}_remaining`;
    }
    return 'substance_type_not_selected';
  }, [substanceType, foodType]);
  return (
    <SpView flex={1}>
      <DeviceStatusHeader data={data} />
      {userHasWriteAccess && (
        <SpView marginTop={2}>
          <DeviceSettingMenuItem data={data?.id} />
        </SpView>
      )}
      {online && (
        <SpView
          marginTop={18}
          backgroundColor={colors.white.color}
          alignItems="center"
          style={styles.container}
          paddingVertical={10}>
          <SpView
            minHeight={32}
            minWidth={172}
            style={styles.progressBarContainer}>
            <SpProgressBar
              indicatorColors={
                substanceType === SubstanceTypes.Water
                  ? ['#45acd9', '#83daf0']
                  : ['#a3d76c', '#4bb96a']
              }
              value={percent[0]}
              width={200}
              borderRadius={16}
            />
          </SpView>
          <SpView
            flexDirection="row"
            alignItems="center">
            {substanceType === SubstanceTypes.Food && (
              <FeedingIconSVG
                color="#263a43"
                width={16}
                height={16}
              />
            )}

            {substanceType === SubstanceTypes.Water && (
              <FontAwesomeIcon
                icon={faDroplet}
                size={16}
                color={colors.greyText.color}
              />
            )}
            <SpText style={styles.title}>{t(titleKey)}</SpText>
          </SpView>
          <SpText style={styles.percent}>{percent}%</SpText>

          <SpView
            marginTop={32}
            width="100%">
            <SpDivider
              style={styles.divider}
              color={colors.greyBorder.color}
            />
            <PetLastActionTime
              icon={faClock}
              data={lastActionData}
              title={t('last_filled')}
              withTimeMarker
              rightSideFlexEnd
            />
            <SpDivider
              style={styles.divider}
              color={colors.greyBorder.color}
            />
          </SpView>
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  title: {
    marginLeft: 4,
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
    paddingBottom: 1,
  },
  container: {
    position: 'absolute',
    flex: 1,
    height: '55%',
    paddingHorizontal: 19,
    bottom: 0,
    left: -18,
    right: -18,
    paddingTop: 28,
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: -9,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    border: 'none',

    elevation: 8,
  },
  progressBarContainer: { position: 'absolute', top: -16 },
  percent: {
    fontFamily: 'Rubik_Medium',
    fontSize: 32,
    lineHeight: 32,
    color: colors.greyText.color,
    marginTop: 8,
  },
  divider: {
    width: '100%',
  },
});
