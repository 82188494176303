import React, { memo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { DeviceModel } from '@models/Device';
import { DeviceCard } from '../../../../../Dashboard/components/DeviceHierarchy/DeviceCard';
import colors from '@styles/colors';

type SelectDeviceItemProps = {
  item: DeviceModel;
  // onItemPress: (item: DeviceModel) => void;
  onItemPress: (item: DeviceModel) => () => void;
};

const SelectDeviceItem = ({ item, onItemPress }: SelectDeviceItemProps) => {
  return (
    <TouchableOpacity
      // onPress={() => onItemPress?.(item)}
      onPress={onItemPress?.(item)}
      style={[styles.itemContainer]}
      testID={`select-device-item_${item?.id}`}
    >
      <DeviceCard
        data={item}
        isPetFlow
      />
    </TouchableOpacity>
  );
};

export default memo(SelectDeviceItem);

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 14,
    marginBottom: 25,
  },
});
