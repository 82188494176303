import { AsyncStorageKeys } from '@constants/AsyncStorageKeys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { differenceInDays, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { DdLogs } from '../services/SPDataDogService';
import { SendAnalyticsDataService } from '../services/SendAnalyticsDataService';
import { MergedInterfaces } from '../store/models';
import useBoundStore from '../store/store';

export const useRefreshAnalyticsData = () => {
  const [allow, setAllow] = useState(false);
  const { activeHousehold, households } = useBoundStore((state: MergedInterfaces) => state.householdStore);
  const { petData} = useBoundStore((state: MergedInterfaces) => state.petStore);
  const {user} = useBoundStore((state: MergedInterfaces) => state.accountStore);
  const {deviceData} = useBoundStore((state: MergedInterfaces) => state.deviceStore);
  const run = async () => {
    const [UPLog] = await Promise.all([
      AsyncStorage.getItem(AsyncStorageKeys.UPLog),
    ]);
    await SendAnalyticsDataService.setDebugMode(user.last_name);

    const timeSinceLastOpen = UPLog
      ? differenceInDays(Date.now(), parseISO(UPLog as string).getTime())
      : null;

    // if user is NOT debug and last open was less than 7 days ago, send only events
    if (user.last_name !== '*debug*' &&timeSinceLastOpen !== null && timeSinceLastOpen < 7) {
      await SendAnalyticsDataService.trackEvents({ pets: petData });
      return;
    }

    await AsyncStorage.setItem(AsyncStorageKeys.UPLog, new Date().toISOString());
    await SendAnalyticsDataService.trackProperties({
      activeHousehold,
      devices: deviceData,
      households: households,
      user: user,
    });
    await SendAnalyticsDataService.trackEvents({ pets: petData });
  };

  const allowAnalyticsRefresh = useCallback(() => {
    setAllow(true);
  }, []);

  useEffect(() => {
    if (!allow) {
      return;
    }
    if (!activeHousehold) {
      return;
    }
    setAllow(false);
    DdLogs.info('useRefreshAnalyticsData - useEffect');
    run().then();
  }, [activeHousehold, allow, deviceData, households, petData, user]);

  return allowAnalyticsRefresh;
};
