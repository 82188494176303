import { StyleSheet, View } from 'react-native';
import React from 'react';
import colors from '@styles/colors';

interface ProgressLineProps {
  width?: number | string;
  step: number;
  steps: number;
}

const ProgressLine = ({ step, steps, width = '100%', ...props }: ProgressLineProps) => {
  const count = (step / steps) * 100;
  const offset = count < 100 ? `${count}%` : '100%';
  // @ts-ignore
  // @ts-ignore
  return (
    <View
      {...props}
      // @ts-ignore
      style={[styles.container, { width }]}
    >
      <View
        style={[
          styles.inner,
          // @ts-ignore
          {
            width: offset,
          },
        ]}
      />
    </View>
  );
};

export default ProgressLine;

const styles = StyleSheet.create({
  container: {
    height: 8,
    borderRadius: 6,
    backgroundColor: colors.secondary3.color,
  },
  inner: {
    height: 8,
    borderRadius: 6,
    backgroundColor: colors.secondary.color,
  },
});
