import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const TourSwipeSVG = (props: any) => (
  <Svg
    width="40px"
    height="52px"
    viewBox="0 0 40 52">
    <G
      id="tour-swipe"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd">
      <G
        id="swipe"
        fill="#03A2B1"
        fill-rule="nonzero">
        <Path
          d="M36.5730732,36 L28.236193,36 C27.7322883,36 27.4735391,35.6679375 27.3856019,35.5253438 C27.2967272,35.38275 27.1160715,35.002875 27.3416333,34.5526875 L39.5681888,10.0995938 C39.8503755,9.53615625 40,8.90334375 40,8.27053125 C40,5.835 38.1648495,4.00003125 35.9099815,4.00003125 C34.3504552,4.00003125 32.9480533,4.86628125 32.2508367,6.2608125 L25.9979188,18.767625 C24.9324533,16.8194063 22.721554,15.7265625 20.5223734,16.0615313 L20.5214359,16.0615313 L9.89162534,17.6972813 C8.29197409,17.943375 6.90429092,18.955125 6.18073069,20.403375 L0.846653604,31.0693125 C0.292967834,32.1786563 0,33.4199063 0,34.6581563 C0,37.7176875 1.69921344,40.4686875 4.43557989,41.8359375 L13.8368943,46.5360938 C15.7508883,47.494125 17.8954128,48 20.0370311,48 C21.441308,48 22.8329286,47.7870938 24.1727995,47.368125 L38.2977866,42.9540938 C38.557567,42.873 38.7724413,42.690375 38.8945035,42.4472813 L39.6386886,40.9599375 C39.8750316,40.4852813 40,39.9550313 40,39.42675 C40,37.537125 38.4627861,36 36.5730732,36 Z"
          id="Path"
        />
        <Path
          d="M0.999934375,12.0439687 C1.08196537,12.0439687 1.16493386,12.0342187 1.24696485,12.01275 L9.2469086,9.9688125 C9.57503258,9.8848125 9.83771926,9.641625 9.94712517,9.321375 C10.0554998,9.00103125 9.99493752,8.6475 9.78596942,8.38190625 L8.96565948,7.33921875 C12.2264618,5.1811875 16.069356,4.00003125 19.9999063,4.00003125 C22.8895535,4.00003125 25.6599511,4.59646875 28.2503492,5.77453125 C28.7393477,5.99690625 29.3119709,5.81653125 29.5572201,5.33859375 L30.4695923,3.560625 C30.7287165,3.0556875 30.5225609,2.416875 30.007125,2.1795 C26.8661348,0.73265625 23.5052078,0 19.9999063,0 C15.1645776,0 10.4338736,1.47853125 6.46066731,4.19325 C6.45110484,4.19934375 5.25729607,5.0536875 4.71973525,5.4980625 L4.72142275,5.500125 C2.98264693,6.93675 1.4564017,8.60353125 0.174843204,10.4794688 C-0.060468561,10.82325 -0.0575623201,11.2763438 0.180749435,11.617125 C0.37106134,11.8887187 0.678654129,12.0439687 0.999934375,12.0439687 Z"
          id="Path"
        />
      </G>
    </G>
  </Svg>
);
export default TourSwipeSVG;
