import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ChangePasswordLockSvg = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 16 16"
    {...(props as any)}>
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="M14.139 6h-1.083V4.667C13.056 2.093 10.788 0 8 0 5.212 0 2.944 2.093 2.944 4.667V6H1.861c-.2 0-.361.15-.361.333v8.334c0 .735.648 1.333 1.444 1.333h10.112c.796 0 1.444-.598 1.444-1.333V6.333c0-.184-.162-.333-.361-.333ZM9.08 12.963a.318.318 0 0 1-.09.26.375.375 0 0 1-.269.11H7.278c-.103 0-.2-.04-.27-.11a.318.318 0 0 1-.09-.26l.229-1.89A1.29 1.29 0 0 1 6.556 10c0-.735.647-1.333 1.444-1.333.797 0 1.444.598 1.444 1.333 0 .431-.22.824-.59 1.072l.227 1.891ZM10.89 6H5.11V4.667C5.111 3.197 6.407 2 8 2c1.593 0 2.889 1.196 2.889 2.667V6Z"
    />
  </Svg>
);

export default ChangePasswordLockSvg;
