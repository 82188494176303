import { SpText } from '../SpText/SpText';
import { SpView } from '../SpView';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

interface SpBadge {
  text: string;
  color?: string;
  width?: number | string;
  icon?: JSX.Element;
  textAlign?: 'center' | 'left' | 'right';
  paddingVertical?: number;
  flexDirection?: 'column' | 'row';
  badgeWrapperStyle?: ViewStyle;
}

const SpBadge = ({
  text,
  color = colors.secondary.color,
  width = 98,
  icon = null,
  textAlign = 'left',
  paddingVertical = 4,
  flexDirection = 'column',
  badgeWrapperStyle = {},
}: SpBadge) => {
  return (
    <SpView
      style={[styles.badgeWrapper, badgeWrapperStyle]}
      backgroundColor={color}
      paddingVertical={paddingVertical}
      paddingHorizontal={8}
      width={width}
      flexDirection={flexDirection}
      alignItems="center"
      borderRadius={12}
      {...testProperties(text, 'badge', 'SpBadge')}>
      {icon || null}
      <SpText
        {...testProperties(text, 'text', 'SpBadge')}
        align={textAlign}
        color={colors.white.color}
        fontFamily="Rubik">
        {text}
      </SpText>
    </SpView>
  );
};

export default SpBadge;

const styles = StyleSheet.create({
  badgeWrapper: {
    borderRadius: 12,
  },
});
