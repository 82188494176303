export const AppImages = {
  feeder: require('../../assets/products/feeder.png'),
  cerberus: require('../../assets/products/cerberus.png'),
  catFlap: require('../../assets/products/cat-flap.png'),
  petDoor: require('../../assets/products/petdoor.png'),
  poseidon: require('../../assets/products/poseidon.png'),
  hub: require('../../assets/products/hub/hub.png'),
  hubSetup: require('../../assets/products/hub/hub-setup.png'),
  wifi: require('../../assets/connection/wifi.png'),
  noWifi: require('../../assets/connection/wifi-no.png'),
  lockOptionBoth: require('../../assets/lock-options/both-ways-icon.png'),
  lockOptionInside: require('../../assets/lock-options/indoors.svg'),
  lockOptionOutside: require('../../assets/lock-options/outside-icon.png'),
  leftBowl: require('../../assets/bowl/left-bowl.png'),
  rightBowl: require('../../assets/bowl/right-bowl.png'),
  bowl: require('../../assets/bowl/bowl.png'),
  halfBowl: require('../../assets/bowl/half-bowl.png'),
  hubBright: require('../../assets/hub-lights/hub-bright.png'),
  hubDim: require('../../assets/hub-lights/hub-dim.png'),
  hubNone: require('../../assets/hub-lights/hub-none.png'),
  hubRouterGraphic: require('../../assets/setup/img-hub-router-graphic.png'),
  addCatFlapSetup: require('../../assets/setup/img-add-pet-cat-flap.png'),
  addPetDoorSetup: require('../../assets/setup/img-add-pet-to-door.png'),
  addFeederSetup: require('../../assets/setup/img-add-pet-to-feeder.png'),
  addPoseidonSetup: require('../../assets/setup/img-add-pet-felaqua.png'),
  pairPetDoor: require('../../assets/setup/img-pair-pet-door.png'),
  pairCatFlap: require('../../assets/setup/img-pair-cat-flap.png'),
  pairFeeder: require('../../assets/setup/img-pair-pet-feeder.png'),
  pairFelaqua: require('../../assets/setup/img-pair-felaqua.png'),
  batteryX4: require('../../assets/setup/img-attery-X4.png'),
  addPetIcon: require('../../assets/setup/img-add-pet-icon.png'),
  setupProductPetDoor: require('../../assets/setup/img-product-pet-door.png'),
  setupProductPetFeeder: require('../../assets/setup/img-product-pet-feeder.png'),
  setupProductFelaqua: require('../../assets/setup/img-product-felaqua.png'),
  setupProductCatFlap: require('../../assets/setup/img-product-cat-flap.png'),
  hubWifi: require('../../assets/products/hub/img-wifi-icon.png'),
  hubPencil: require('../../assets/products/hub/img-pencil-icon.png'),
  hubLights: require('../../assets/products/hub/img-hub-lights.png'),
  addCerberusSetup1a: require('../../assets/products/add-cerberus-step1-a.png'),
  addCerberusSetup1b: require('../../assets/products/add-cerberus-step1-b.png'),
  addCerberusSetup2a: require('../../assets/products/cdb/setup/add-cerberus-step2-imga.png'),
  addCerberusSetup2b: require('../../assets/products/cdb/setup/add-cerberus-step2-imgb.png'),
  addCerberusSetup3: require('../../assets/products/cdb/setup/add-cerberus-step3.png'),
  imageAddCerberusStep1Icon1: require('../../assets/products/cdb/setup/add-cerberus-step1-icona.png'),
  imageAddCerberusStep1Icon2: require('../../assets/products/cdb/setup/add-cerberus-step1-iconb.png'),
  cdbSetupCarousel1: require('../../assets/products/cdb/carousel/cdb-carousel-1.png'),
  cdbSetupCarousel2: require('../../assets/products/cdb/carousel/cdb-carousel-2.png'),
  cdbSetupCarousel3: require('../../assets/products/cdb/carousel/cdb-carousel-3.png'),
  cdbSetupCarousel4: require('../../assets/products/cdb/carousel/cdb-carousel-4.png'),
  cdbSetupCarousel5: require('../../assets/products/cdb/carousel/cdb-carousel-5.png'),
  cdbConfigureInitital: require('../../assets/products/cdb/setup/cdb-configure-initial.png'),
  cdbConfigureWaterCarousel1: require('../../assets/products/cdb/carousel/cdb-carousel-water-1.png'),
  cdbConfigureWaterCarousel2: require('../../assets/products/cdb/carousel/cdb-carousel-water-2.png'),
  cdbConfigureWaterCarousel3: require('../../assets/products/cdb/carousel/cdb-carousel-water-3.png'),
  cdbConfigureWaterCarousel4: require('../../assets/products/cdb/carousel/cdb-carousel-water-4.png'),
  cdbConfigureFoodCarousel1: require('../../assets/products/cdb/carousel/cdb-carousel-food-1.png'),
  cdbConfigureFoodCarousel2: require('../../assets/products/cdb/carousel/cdb-carousel-food-2.png'),
  cdbConfigureFoodCarousel3: require('../../assets/products/cdb/carousel/cdb-carousel-food-3.png'),
  cdbConfigureFoodCarousel4: require('../../assets/products/cdb/carousel/cdb-carousel-food-4.png'),
  cdbSubstanceWater: require('../../assets/products/cdb/substance/cdb-substance-water.png'),
  cdbSubstanceFood: require('../../assets/products/cdb/substance/cdb-substance-food.png'),
  felaquaPlacementGood1: require('../../assets/setup/img-felaqua-placement-good-1.png'),
  felaquaPlacementGood2: require('../../assets/setup/img-felaqua-placement-good-2.png'),
  felaquaPlacementGood3: require('../../assets/setup/img-felaqua-placement-good-3.png'),
  felaquaPlacementBad1: require('../../assets/setup/img-felaqua-placement-bad-1.png'),
  felaquaPlacementBad2: require('../../assets/setup/img-felaqua-placement-bad-2.png'),
  felaquaPlacementBad3: require('../../assets/setup/img-felaqua-placement-bad-3.png'),
  felaquaFirstFill1: require('../../assets/setup/img-first-fill-1.png'),
  felaquaFirstFill2: require('../../assets/setup/img-first-fill-2.png'),
  felaquaFirstFill3: require('../../assets/setup/img-first-fill-3.png'),
  felaquaFirstFill4: require('../../assets/setup/img-first-fill-4.png'),
  felaquaFirstFill5: require('../../assets/setup/img-first-fill-5.png'),
  pairHub: require('../../assets/products/hub/img-pair-hub.png'),
  petBowlSetupFood: require('../../assets/setup/img-pet-food.png'),
  petBowlSetupWater: require('../../assets/setup/img-water-drop.png'),
  cdbPairingHousehold: require('../../assets/setup/dbc-connecting.png'),
  cdbPaired: require('../../assets/setup/dbc-paired.png'),
  cdbFailedtoPair: require('../../assets/setup/dbc-failed-pair.png'),
  cdbFail: require('../../assets/setup/dbc-fail.png'),
  cdbWeHaventManageToConnect: require('../../assets/setup/weHaventManageToConnect.png'),
  resetImgCDBBottom: require('../../assets/setup/resetImgCDBBottom.png'),
  resetImgCDBTop: require('../../assets/setup/resetImgCDBTop.png'),
  zeroRequiredCDB: require('../../assets/zero-required.png'),
  imgSingleBowlSetup: require('../../assets/setup/img-single-bowl-placement.png'),
  imgHalfBowlSetup: require('../../assets/setup/img-half-bowl-placement.png'),
  imgZeroFeeder: require('../../assets/setup/img-zero-feeder.png'),
  battery0Bar: require('../../assets/battery/0-bars.png'),
  battery1Bar: require('../../assets/battery/1-bars.png'),
  battery2Bar: require('../../assets/battery/2-bars.png'),
  battery3Bar: require('../../assets/battery/3-bars.png'),
  battery4Bar: require('../../assets/battery/4-bars.png'),
  connectionFull: require('../../assets/connection/full.png'),
  connectionMedium: require('../../assets/connection/medium.png'),
  connectionLow: require('../../assets/connection/low.png'),
  connectionNone: require('../../assets/connection/no-connection.png'),
  curfewEnabled: require('../../assets/lock-options/curfew-enabled.png'),
  doorLocked: require('../../assets/lock-options/door-locked.png'),
  onboardingLetsStartBackground: require('../../assets/background/catOnboarding.png'),
  logoGrey: require('../../assets/logoGrey.png'),
  feederTimelineIcon: require('../../assets/timeline/timeline-feeder-icon.png'),
  cerberusTimelineIcon: require('../../assets/timeline/timeline-hub-icon.png'),
  catFlapTimelineIcon: require('../../assets/timeline/timeline-catflap-icon.png'),
  petDoorTimelineIcon: require('../../assets/timeline/timeline-petdoor-icon.png'),
  poseidonTimelineIcon: require('../../assets/timeline/timeline-felaqua-icon.png'),
  hubTimelineIcon: require('../../assets/timeline/timeline-hub-icon.png'),
  householdTimelineIcon: require('../../assets/timeline/timeline-household-icon.png'),
  petHomeCard: require('../../assets/tours/pet-home-card.png'),
  petHomePaw: require('../../assets/tours/petHome.png'),
  catAvatar: require('../../assets/tours/catAvatar.png'),
  doorExit: require('../../assets/tours/doorExit.png'),
  drinkingIcon: require('../../assets/tours/drinkingGraphIcon.png'),
  eatenIcon: require('../../assets/tours/eatenGraphsIcon.png'),
  tourUserAvatar: require('../../assets/tours/userAvatar.png'),
  feederZeroLightsAnimation: require('../../assets/setup/feeder-zero-lights-animation.gif'),
  focusIcon: require('../../assets/setup/focusIcon.png'),
  portionIndicatorLights: require('../../assets/setup/portion-indicator-lights.png'),
  alertIcon: require('../../assets/alert-icon.png'),
  appPermissionGraphic: require('../../assets/products/cdb/setup/app-permissions-graphic.png'),
  qrIcon: require('../../assets/setup/img-qr-code-icon.png'),
};
