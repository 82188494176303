import * as Notifications from 'expo-notifications';
import { useEffect, useState } from 'react';
import * as Device from 'expo-device';
import { useIsFocused } from '@react-navigation/native';

export const useDetectPushNotification = () => {
  const [action, setAction] = useState<(() => void) | null>(null);
  const saveAction = (actionOnNotification: () => void, actionName?: string) => {
    setAction(() => {
      return actionOnNotification;
    });
  };
  const isFocused = useIsFocused();
  useEffect(() => {
    // subscribe if current view is focused
    if (!isFocused) {
      return;
    }
    const subscription = Notifications.addNotificationReceivedListener((notification: any) => {
      if (
        Device.brand === 'google' &&
        (!notification || !notification.request?.trigger.remoteMessage?.notification)
      ) {
        return;
      }

      if (action && typeof action === 'function' && isFocused) {
        action(); // Pass the notification data to the action
      }
    });

    // Cleanup subscription
    return () => {
      Notifications.removeNotificationSubscription(subscription);
    };
  }, [action, isFocused]); // Dependency array

  return { saveAction };
};
