import { SpHStack } from 'src/components/SpHStack';
import { SpProfileImage } from 'src/components/SpProfileImage';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { faDroplet, faLocationDot, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { usePetQuickActions } from '@hooks/usePetQuickActions';
import { PetModel } from '@models/Pet';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';

import useBoundStore from '../../../../../../store/store';
import { RootStackParamList } from '../../../../../index';
import { SpVStack } from 'src/components/SpVStack';
import IconButton from 'src/components/IconButton';
import PawSVG from 'src/components/SvgIcons/PawSVG';

interface PetCardHeaderProps {
  pet: PetModel;
  petActions: any;
  pending: boolean;
  showQuickActions?: boolean;
  compact?: boolean;
}

const IMAGE_DIMENSIONS = Math.min(Dimensions.get('screen').height * 0.1, 80);

export const PetCardHeader = ({
  pet,
  petActions,
  pending,
  showQuickActions = false,
  compact = false,
}: PetCardHeaderProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { setActivePet } = useBoundStore(s => s.petStore);

  const drinking = petActions?.drinking;
  const feeding = petActions?.feeding;
  const movement = petActions?.movement;
  const { QuickActionsModal, isModalOpen, setIsModalOpen, allowQuickActions } =
    usePetQuickActions(pet);
  const handleModal = (modalOpen: boolean) => () => {
    setIsModalOpen(modalOpen);
  };
  const icons: JSX.Element = useMemo(() => {
    return (
      <>
        {movement && (
          <SpView
            {...testProperties('movement', 'icon')}
            marginLeft={6}>
            <FontAwesomeIcon
              icon={faLocationDot}
              color={colors.greyText.color}
              size={12}
            />
          </SpView>
        )}
        {drinking && (
          <SpView
            {...testProperties('drinking', 'icon')}
            marginLeft={6}>
            <FontAwesomeIcon
              icon={faDroplet}
              color={colors.greyText.color}
              size={12}
            />
          </SpView>
        )}
        {feeding && (
          <SpView
            {...testProperties('feeding', 'icon')}
            marginLeft={6}>
            <FontAwesomeIcon
              icon={faUtensils}
              color={colors.greyText.color}
              size={12}
            />
          </SpView>
        )}
      </>
    );
  }, [petActions, pet]);
  const handlePetProfileNavigation = useCallback(() => {
    setActivePet(pet?.id);
    navigation.navigate('PetsNavigation', { screen: 'PetProfile', params: { id: pet?.id } });
  }, [pet]);
  return compact ? (
    <>
      <SpVStack space={4} alignItems="center">
        <TouchableOpacity onPress={handlePetProfileNavigation}>
          <SpProfileImage
            width={IMAGE_DIMENSIONS * 0.9}
            height={IMAGE_DIMENSIONS * 0.9}
            imageUrl={pet?.photo?.location}
          />
        </TouchableOpacity>

        <SpView>
          <SpView style={styles.petNameContainer}>
            <SpText
              componentName="PetCardHeaderPetName"
              numberOfLines={1}
              fontFamily="Rubik_SemiBold"
              size="lg"
              style={styles.petName}>
              {pet?.name ?? ''}
            </SpText>
          </SpView>
        </SpView>
      </SpVStack>
      {/*{isModalOpen && QuickActionsModal}*/}
    </>
  ) : (
    <>
      <SpHStack space={8} alignItems="center" accessible={false}>
        <TouchableOpacity onPress={handlePetProfileNavigation} accessible={false}>
          <SpProfileImage
          accessible={false}
            width={IMAGE_DIMENSIONS}
            height={IMAGE_DIMENSIONS}
            imageUrl={pet?.photo?.location}
          />
        </TouchableOpacity>

        <SpView accessible={false}>
          <SpView style={styles.petNameContainer} accessible={false}>
            <SpText
            accessible={false}
              componentName="PetCardHeaderPetName"
              numberOfLines={1}
              fontFamily="Rubik_SemiBold"
              size="xxl"
              style={styles.petName}>
              {pet?.name ?? ''}
            </SpText>
          </SpView>
          {!showQuickActions && !!(feeding || drinking || movement) && !pending ? (
            <SpView flexDirection="row" alignItems="center" accessible={false}>
              <SpText style={styles.monitoringText}>{t('monitoring')}</SpText>
              {icons}
            </SpView>
          ) : null}
          {showQuickActions && !pending && (
            <SpView style={{ marginTop: 10 }} accessible={false}>
              <IconButton
                svgIcon={
                  <PawSVG color={colors.primary.color} width={38} height={20} viewBox="0 0 38 32" />
                }
                action={handleModal(true)}
                iconColor={colors.white.color}
                backgroundColor={colors.primary.color}
                componentName={`QuickAction${pet.id}`}
                width={88}
                height={36}
              />
            </SpView>
          )}
        </SpView>
      </SpHStack>
      {isModalOpen && QuickActionsModal}
    </>
  );
};

const styles = StyleSheet.create({
  petNameContainer: {
    maxWidth: 160,
  },
  petName: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  monitoringText: {
    color: colors.greyOpacity.color,
    fontSize: 14,
    fontFamily: 'Rubik',
  },
});
