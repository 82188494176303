/* eslint-disable no-sparse-arrays */
import { Dimensions, Image, ScrollView, StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import SpTitle from 'src/components/SpTitle';
import { DeviceType } from '@constants/Device';
import { SpCenter } from 'src/components/SpCenter';
import colors from '@styles/colors';
import { AppImages } from '@constants/AppImages';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { SpView } from 'src/components/SpView';
import CustomHeader from 'src/components/CustomHeader';
import { MAX_PET_SEARCH_RETRIES } from '@constants/FindPet';
import InstructionsSteps from '../../components/InstructionsSteps';

interface IAddPetsContent {
  productId: DeviceType;
  withStepNums?: boolean;
  handler: () => void;
  hideCustomHeader?: boolean;
  loadingElement?: JSX.Element;
  addAllpetBtn?: boolean;
  maxRetriesError?: number;
}

const windowWidth = Dimensions.get('window').width;

const AddPetsContent = ({
  productId,
  withStepNums,
  handler,
  hideCustomHeader = false,
  loadingElement = null,
  addAllpetBtn = false,
  maxRetriesError = MAX_PET_SEARCH_RETRIES,
}: IAddPetsContent) => {
  const { t } = useTranslation();

  const imageUrl = useMemo(() => {
    switch (productId) {
      case DeviceType.PetDoorConnect:
        return AppImages.addPetDoorSetup;
      case DeviceType.CatFlapConnect:
        return AppImages.addCatFlapSetup;
      case DeviceType.FeederConnect:
        return AppImages.addFeederSetup;
      case DeviceType.FelaquaConnect:
        return AppImages.addPoseidonSetup;
      default:
        return null;
    }
  }, [productId]);

  const text = useMemo(() => {
    switch (productId) {
      case DeviceType.CatFlapConnect:
        return [
          t('setup_cat_flap_instruction_1'),
          t(withStepNums ? 'setup_cat_flap_instruction_2' : 'setup_cat_flap_instruction_2_add_pet'),
          t(withStepNums ? 'setup_cat_flap_instruction_3' : 'setup_cat_flap_instruction_3_add_pet'),
          ,
        ];
      case DeviceType.PetDoorConnect:
        return [
          t('setup_pet_door_instruction_1'),
          t('setup_pet_door_instruction_2'),
          t('setup_pet_door_instruction_3'),
        ];
      case DeviceType.FeederConnect:
        return [t('setup_feeder_instruction_1'), t('setup_feeder_instruction_2')];
      case DeviceType.FelaquaConnect:
        return [t('setup_felaqua_instruction')];
      default:
        return null;
    }
  }, [productId, t]);

  return (
    <SpView flex={1}>
      {!hideCustomHeader && (
        <CustomHeader
          withCross
          withTitle={false}
          goBackFunc={handler}
        />
      )}
      <SpCenter>
        <SpTitle
          text={t('add_your_pet')}
          style={{ paddingVertical: 0 }}
        />
      </SpCenter>
      <ScrollView style={styles.addPetsContent}>
        <Image
          source={imageUrl}
          resizeMode="stretch"
          {...testProperties(`${productId}product`, 'image')}
          style={[
            styles.image,
            productId === DeviceType.FelaquaConnect && styles.poseidon,
            productId === DeviceType.PetDoorConnect && styles.petDoor,
            productId === DeviceType.FeederConnect && styles.feeder,
            productId === DeviceType.CatFlapConnect && styles.catFlap,
          ]}
        />
        <InstructionsSteps
          arr={text}
          withStepNums={withStepNums}
        />
      </ScrollView>
      {loadingElement}
      {addAllpetBtn && (
        <SpRoundedHeaderButton
          title={t('all_pets_added')}
          onPress={handler}
          backgroundColor={colors.primary.color}
          stylesForContainer={styles.button}
          h={56}
        />
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  instructionText: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.black.color,
    textAlign: 'center',
    lineHeight: 24,
    paddingHorizontal: 20,
  },
  image: {
    width: windowWidth,
    height: 212,
    marginLeft: -30,
    marginRight: -19,
  },
  poseidon: {
    height: 198,
    marginTop: 8,
    marginBottom: 32,
  },
  petDoor: {
    marginBottom: 16,
  },
  feeder: {
    marginTop: 12,
    marginBottom: 28,
  },
  catFlap: {
    marginBottom: 12,
  },
  addPetsContent: {
    flex: 1,
    paddingHorizontal: 20,
  },
  button: {
    padding: 19,
  },
  block: {
    height: 30,
    width: '100%',
  },
});

export default AddPetsContent;
