import { SpVStack } from 'src/components/SpVStack';
import { SubstanceType } from '@constants/Device';
import React from 'react';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import { capitalize } from '@utils/capitalize';
import { AppImages } from '@constants/AppImages';
import { SpView } from 'src/components/SpView';
import { Image, Platform, StyleSheet } from 'react-native';
import TypeButton from './TypeButton';
import { SpText } from '../../../../../../components/SpText/SpText';
import text from '@styles/text';
import i18n from '@utils/i18n';

interface SubstanceTypesProps {
  active: SubstanceType;
  onChange: (data: SubstanceType) => void;
  isSetup?: boolean;
}

export const SubstanceTypes = ({ active, onChange, isSetup = false }: SubstanceTypesProps) => {
  const substanceOptions = enumToDescriptedArray(SubstanceType, /-/g);
  const bowlTypeImages = [AppImages.petBowlSetupWater, AppImages.petBowlSetupFood];
  const bowlFlashModeImages = [
    AppImages.cdbConfigureInitital,
    AppImages.cdbSubstanceWater,
    AppImages.cdbSubstanceFood,
  ];
  return (
    <SpVStack
      style={[styles.wrapper, Platform.OS === 'android' ? styles.androidPaddingBottom : {}]}
      space={2}
      flex={1}>
      <SpView style={styles.titleContainer}>
        {isSetup && <SpText style={text.title}>{i18n.t('bowl_used_for')}</SpText>}
        <Image
          source={bowlFlashModeImages[active] ?? bowlFlashModeImages[0]}
          style={{ width: '100%', height: 160, resizeMode: 'contain' }}
        />
      </SpView>
      {substanceOptions.map((option, index) => {
        return (
          <SpView
            key={option.id + index}
            style={[styles.buttonWrap, index === 0]}>
            {index === 0 ? (
              <TypeButton
                text={capitalize(option.name)}
                isActive={active === option.id}
                onPress={() => {
                  onChange(option.id);
                }}
                isBowl
                {...bowlTypeImages[index]}
                image={bowlTypeImages[index]}
              />
            ) : (
              <TypeButton
                text={capitalize(option.name)}
                isActive={active === option.id}
                onPress={() => {
                  onChange(option.id);
                }}
                isBowl
                {...bowlTypeImages[index]}
                image={bowlTypeImages[index]}
                overidePrimaryColor={'#bc68ff'}
                overideBackgroundColor={'#bc68ff1a'}
              />
            )}
          </SpView>
        );
      })}
      {isSetup && (
        <SpView style={styles.titleContainer}>
          <SpText style={styles.note}>{i18n.t('configure_product')}</SpText>
        </SpView>
      )}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 100,
  },
  buttonWrap: {
    marginTop: 34,
  },
  androidPaddingBottom: {
    paddingBottom: 100,
  },
  title: {
    fontSize: 24,
    fontWeight: 800,
    marginTop: 10,
    paddingTop: 5,
    height: 30,
    textAlign: 'center',
  },
  titleContainer: {
    width: '100%',
  },
  note: {
    marginTop: 20,
    alignContent: 'center',
    textAlign: 'center',
    color: '#8E8E8E',
    fontSize: 14,
  },
});
