import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import React, { useMemo, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { PetBioMenu } from '@constants/Menus';
import AccountTextWrapper from 'src/pages/Account/components/AccountTextWrapper';
import { PetGender, PetSpayedOptions, PetSpecie } from '@constants/Pet';
import WeightInput from 'src/components/WeightInput';
import SpTitle from 'src/components/SpTitle';
import SelectButtonsWrapper from 'src/components/SelectButtonsWrapper';
import { useUpdatePet } from '@hooks/useUpdatePet';
import CustomKeyboardAvoidingView from 'src/components/CustomKeyboardAvoidingView';
import { SpView } from 'src/components/SpView';
import { PetStackParamList } from '../..';
import PetBioBreedWrapper from './PetBioBreedWrapper';
import PetBioCommentWrapper from './PetBioCommentWrapper';

type Props = NativeStackScreenProps<PetStackParamList, 'PetEditBio'>;

export const PetEditBio = ({ navigation, route }: Props) => {
  const [value, setValue] = useState(route.params.inputValue);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const { t } = useTranslation();
  const onUpdateAccount = useUpdatePet(
    setButtonIsLoading,
    setButtonDisabled,
    route.params.inputName,
    navigation,
  );
  const input = useMemo(() => {
    return (
      <View style={{ flex: 1 }}>
        {route.params.inputName === PetBioMenu.Name && (
          <AccountTextWrapper
            label={route.params.label}
            inputName={route.params.inputName}
            currentString={route.params.inputValue}
            handleChange={setValue}
            setButtonDisabled={setButtonDisabled}
          />
        )}
        {route.params.inputName === PetBioMenu.Species && (
          <View style={styles.container}>
            <SpTitle text={route.params.label} />
            <SelectButtonsWrapper
              optionsEnum={PetSpecie}
              handleChange={setValue}
              selectedOption={PetSpecie[route.params.inputValue]}
              setButtonDisabled={setButtonDisabled}
            />
          </View>
        )}
        {route.params.inputName === PetBioMenu.Gender && (
          <View style={styles.container}>
            <SpTitle text={route.params.label} />
            <SelectButtonsWrapper
              optionsEnum={PetGender}
              handleChange={setValue}
              selectedOption={PetGender[route.params.inputValue]}
              setButtonDisabled={setButtonDisabled}
            />
          </View>
        )}
        {route.params.inputName === PetBioMenu.Spayed && (
          <View style={styles.container}>
            <SpTitle text={route.params.label} />
            <SelectButtonsWrapper
              optionsEnum={PetSpayedOptions}
              handleChange={setValue}
              selectedOption={PetSpayedOptions[route.params.inputValue]}
              setButtonDisabled={setButtonDisabled}
            />
          </View>
        )}
        {route.params.inputName === PetBioMenu.Weight && (
          <WeightInput
            value={value}
            setValue={setValue}
            setButtonDisabled={setButtonDisabled}
            inputType="pet"
          />
        )}
        {route.params.inputName === PetBioMenu.Comments && (
          <PetBioCommentWrapper
            value={value}
            label={route.params.label}
            handleChange={setValue}
            setButtonDisabled={setButtonDisabled}
          />
        )}
        {route.params.inputName === PetBioMenu.BreedID && (
          <PetBioBreedWrapper
            handleChange={setValue}
            selectedBreed={route.params.inputValue}
            type={PetBioMenu.BreedID}
            setButtonDisabled={setButtonDisabled}
          />
        )}
        {route.params.inputName === PetBioMenu.BreedID2 && (
          <PetBioBreedWrapper
            handleChange={setValue}
            selectedBreed={route.params.inputValue}
            type={PetBioMenu.BreedID2}
            setButtonDisabled={setButtonDisabled}
          />
        )}
      </View>
    );
  }, [route, setValue, value]);

  const isBreedList = [PetBioMenu.BreedID2, PetBioMenu.BreedID].includes(
    route.params.inputName as PetBioMenu,
  );
  const isIos = Platform.OS === 'ios';
  const isCommentsMode = route.params.inputName === PetBioMenu.Comments;

  return (
    <CustomKeyboardAvoidingView
      disabled={isIos && isBreedList}
      androidAdjustType={isCommentsMode ? 'custom' : undefined}
      scrollView={false}
      modal
    >
      <SpView style={styles.content}>
        <View style={styles.inner}>
          {input}
          <View style={[styles.buttonWrapper, { marginTop: !isIos && isCommentsMode ? 20 : 0 }]}>
            <SpRoundedHeaderButton
              disabled={buttonDisabled}
              backgroundColor={colors.primary.color}
              h={56}
              title={t('save')}
              isLoading={buttonIsLoading}
              onPress={() => {
                onUpdateAccount(value);
              }}
              loadingIconType="spinner"
            />
          </View>
        </View>
      </SpView>
    </CustomKeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
  },
  buttonWrapper: {
    bottom: 0,
    position: 'absolute',
    paddingHorizontal: 19,
    width: '100%',
    marginBottom: 44,
  },
  input: {
    backgroundColor: '#efefef',
    height: 50,
    width: '100%',
    borderColor: '#71717a',
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingVertical: '2%',
    borderRadius: 10,
    color: '#71717a',
    fontSize: 12,
    fontWeight: 'bold',
  },
  inputComment: {
    height: 100,
  },
  container: {
    paddingHorizontal: 19,
  },
  shadowWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8,
  },
});
