import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import CountryApi from '@api/CountryApi';
import { CountrySlice, MergedInterfaces } from '../models';

const createCountrySlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  CountrySlice
> = (set, get) => {
  return {
    countryStore: lens((subSet, subGet, api) => ({
      countries: [],
      error: null,
      loadingCountries: false,
      getCountryByID: id => {
        const { countries } = subGet();
        return countries.filter(item => item.id === id)[0];
      },
      loadCountries: async (force = false) => {
        if (!force && subGet().countries.length) {
          return;
        }

        subSet({ loadingCountries: true, error: null });

        try {
          const countries = await CountryApi.getCountries();
          const sortedCountries = countries.sort((a, b) => (a.name > b.name ? 1 : -1));
          subSet({ countries: sortedCountries });
        } catch (response: any) {
          subSet({ error: response?.status || 500 });
        }

        subSet({ loadingCountries: false });
      },
      reset: () => {
        subSet({
          error: null,
          loadingCountries: false,
          countries: [],
        });
      },
    })),
  };
};
export default createCountrySlice;
