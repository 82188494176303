import { DeviceTimelineImages } from '@constants/DeviceImages';
import { TimelineEventModel } from '@models/Timeline';
import { get } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { TimelineCardImage } from './TimelineCardImage';

type Props = {
  active: boolean;
  event: TimelineEventModel;
};

const TimelineDevicePhoto: React.FC<Props> = ({ active, event }) => {
  const image = useMemo(() => {
    return DeviceTimelineImages[get(event, 'devices[0].product_id')] || null;
  }, [event?.devices]);

  if (!image) {
    return null;
  }
  return (
    <TimelineCardImage
      image={image}
      active={active}
    />
  );
};

export default TimelineDevicePhoto;
