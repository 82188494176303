import { useMemo } from 'react';
import CustomBottomSheetModal from 'src/components/CustomBottomSheetModal';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import React, { StyleSheet } from 'react-native';
import { SpText } from '../../../../../../components/SpText/SpText';
import { ConsumptionInsight, ConsumptionInsightOutcome } from '@models/ConsumptionInsight';
import { SpView } from '../../../../../../components/SpView';
import { SpButton } from '../../../../../../components/SpButton';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import colors from '@styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  ConsumptionInsightAlertBottomSheetText,
  ConsumptionInsightHabitBottomSheetText,
} from '../../constants/ConsumptionInsightText';
import { TimeService } from '../../../../../../services/TimeService';
import { DayMonthFormat } from '@constants/DateFormat';
import noop from '@utils/noop';
import { useTranslation } from 'react-i18next';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { useConsumptionPopupHandle } from '../../hooks/useConsumptionPopupHandle';
import CircleChevronBottomSVG from '../../../../../../components/SvgIcons/CircleChevronBottomSVG';
import CircleChevronTopSVG from '../../../../../../components/SvgIcons/CircleChevronTopSVG';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../../../../index';
import { PetModel } from '@models/Pet';
import usePetValidInsightData from '@hooks/usePetValidInsightData';

interface ConsumptionModalBottomSheetProps {
  pet: PetModel;
  opened: boolean;
  insight: ConsumptionInsight;
  withoutButton?: boolean;
  isAlert?: boolean;
  withoutFullDescription?: boolean;
  withDataHeader?: boolean;
  onDismiss: () => void;
  onViewGraph?: (date: string) => void;
  fromReport?: boolean;
}

const ConsumptionModalBottomSheet = ({
  pet,
  insight,
  opened,
  onDismiss,
  withoutButton = false,
  withoutFullDescription = false,
  isAlert = false,
  withDataHeader = false,
  onViewGraph = noop,
  fromReport = false,
}: ConsumptionModalBottomSheetProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const petValidProfile = usePetValidInsightData({ specie: pet?.species_id, weight: pet?.weight });
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const snapPoints = useMemo(() => ['40%'], []);

  const isTodayInsight = useMemo(() => {
    return TimeService.toLocal()
      .startOf('day')
      .equals(TimeService.toLocal(insight?.last_drinking_event_utc).startOf('day'));
  }, [insight?.last_drinking_event_utc]);

  const texts = useMemo(() => {
    return isAlert
      ? ConsumptionInsightAlertBottomSheetText[insight.outcome]
      : ConsumptionInsightHabitBottomSheetText[insight.outcome];
  }, [isAlert, insight.outcome]);

  const isAvailable = useMemo(() => {
    return ![
      ConsumptionInsightOutcome.WillBeAvailable,
      ConsumptionInsightOutcome.Unavailable,
    ].includes(insight.outcome);
  }, [insight.outcome]);

  const isUnavailable = useMemo(() => {
    return insight.outcome === ConsumptionInsightOutcome.Unavailable;
  }, [insight?.outcome]);

  const isChevronVisible = useMemo(() => {
    return insight.outcome !== ConsumptionInsightOutcome.WasFluctuating;
  }, [insight.outcome]);

  const isChevronDecreased = useMemo(() => {
    return [ConsumptionInsightOutcome.WasDecreased, ConsumptionInsightOutcome.Decreased].includes(
      insight.outcome,
    );
  }, [insight.outcome]);

  const handleProps = useConsumptionPopupHandle({ isAvailable, isAlert, outcome: insight.outcome });

  const isInsightNormal = useMemo(() => {
    return !isAlert && insight.outcome === ConsumptionInsightOutcome.Ok;
  }, [insight.outcome, isAlert]);

  const isMinimizedDescription = useMemo(() => {
    return withoutFullDescription && isAvailable && !isInsightNormal;
  }, [isAvailable, isInsightNormal, withoutFullDescription]);

  const title = useMemo(() => {
    if (isUnavailable) {
      return !petValidProfile
        ? texts.title
        : isTodayInsight
          ? texts.titleNotValid
          : texts.titlePastNotValid;
    }

    return !isTodayInsight && texts.titlePast ? texts.titlePast : texts.title;
  }, [
    isUnavailable,
    isTodayInsight,
    texts.titlePast,
    texts.title,
    texts.titleNotValid,
    texts.titlePastNotValid,
    petValidProfile,
  ]);

  return (
    <CustomBottomSheetModal
      opened={opened}
      backdropComponent={BottomSheetBackdrop}
      index={0}
      snapPoints={snapPoints}
      inScrollView
      onDismiss={onDismiss}
      {...handleProps}
    >
      {withDataHeader && isAvailable && !isInsightNormal && (
        <SpView style={styles.dataHeader}>
          {!isTodayInsight && (
            <SpText
              size="xl"
              style={styles.date}
            >
              {TimeService.toLocal(insight.last_drinking_event_utc).toFormat(DayMonthFormat)}
            </SpText>
          )}
          <SpView style={styles.amountText}>
            <SpText
              size="xl"
              color="#263A43"
              fontFamily="Rubik_SemiBold"
            >
              {convertWithUnits(insight.amount, false)}
            </SpText>
            {isTodayInsight && (
              <SpText
                size="lg"
                color="#263A43"
                fontFamily="Rubik_400Regular"
              >
                {t('ci_in_the_last_x_hours')}
              </SpText>
            )}
          </SpView>
        </SpView>
      )}
      {texts && (
        <SpView style={styles.wrapper}>
          {isAvailable && !isInsightNormal ? (
            <SpView
              flexDirection="row"
              alignItems="center"
            >
              {isChevronVisible && (
                <>{isChevronDecreased ? <CircleChevronBottomSVG /> : <CircleChevronTopSVG />}</>
              )}
              <SpText
                style={styles.indicatorText}
                size="md"
                align={isChevronVisible ? 'left' : 'center'}
              >
                {!isTodayInsight && texts.titlePast ? texts.titlePast : texts.title}
              </SpText>
            </SpView>
          ) : (
            <SpText
              size="md"
              align="center"
            >
              {title}
            </SpText>
          )}
          {isAvailable && !isInsightNormal && !isMinimizedDescription && (
            <>
              <SpView style={styles.firstNoticeWrapper}>
                <SpView style={styles.firstNotice}>
                  <SpView
                    flexDirection="row"
                    alignItems="center"
                  >
                    <FontAwesomeIcon
                      icon={faClock}
                      size={16}
                      color={colors.primary.color}
                      style={{ marginRight: 7 }}
                    />
                    <SpText
                      size="sm"
                      color="#676C79"
                    >
                      {t('ci_first_notice')}
                    </SpText>
                  </SpView>
                  <SpText
                    color="#263A43"
                    fontFamily="Rubik_Medium"
                    style={{ marginLeft: 50 }}
                  >
                    {TimeService.toAMPM(
                      insight.first_trigger_event_utc || insight.last_drinking_event_utc,
                    )}
                  </SpText>
                </SpView>
              </SpView>
              {texts.warning && (
                <SpText
                  size="sm"
                  fontFamily="Rubik_Medium"
                >
                  {texts.warning}
                </SpText>
              )}
            </>
          )}
          {!isAvailable ? (
            <SpView style={isAvailable && styles.notAvailableWrapper}>
              {texts.description && !petValidProfile && (
                <SpText
                  size={isUnavailable ? 'md' : 'sm'}
                  align="center"
                  style={styles.notAvailableDescription}
                >
                  {texts.description}
                </SpText>
              )}
            </SpView>
          ) : (
            <>
              {texts.description && !isMinimizedDescription && (
                <SpText
                  style={[styles.mt8, isInsightNormal && styles.centeredDescription]}
                  size="sm"
                >
                  {texts.description}
                </SpText>
              )}
              <SpText
                style={{
                  marginTop: isMinimizedDescription ? 30 : isInsightNormal ? 16 : 8,
                }}
                size="sm"
                align={isInsightNormal ? 'center' : 'left'}
              >
                {t('ci_consult_veterinarian')}
              </SpText>
            </>
          )}
          {isAvailable && !isInsightNormal && !withoutButton && (
            <SpButton
              title={fromReport ? t('ci_view_day') : t('ci_view_graph')}
              color="#02A2B1"
              onPress={() => {
                onDismiss();
                onViewGraph(insight.last_drinking_event_utc);
              }}
              style={styles.button}
            />
          )}
          {isUnavailable && !petValidProfile && (
            <SpButton
              color="#02A2B1"
              title={t('ci_update_now')}
              style={styles.updateButton}
              onPress={() => {
                onDismiss();
                navigation.navigate('PetsNavigation', {
                  screen: 'PetProfileSettings',
                  params: {
                    id: pet.id,
                  },
                });
              }}
            />
          )}
        </SpView>
      )}
    </CustomBottomSheetModal>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 23,
    paddingTop: 14,
    paddingBottom: 56,
  },
  icon: {
    position: 'absolute',
  },
  mt8: {
    marginTop: 8,
  },
  amountText: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  firstNoticeWrapper: {
    marginTop: 24,
    marginBottom: 34,
    flexDirection: 'row',
  },
  firstNotice: {
    backgroundColor: '#F6F4F3',
    borderRadius: 16,
    height: 48,
    paddingHorizontal: 25,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 1,
  },
  button: {
    marginTop: 36,
  },
  notAvailableWrapper: {
    marginTop: 36,
  },
  notAvailableDescription: {
    marginTop: 18,
  },
  centeredDescription: {
    textAlign: 'center',
    marginTop: 24,
  },
  indicatorText: {
    flexWrap: 'wrap',
    marginLeft: 20,
    flexShrink: 1,
    flexGrow: 1,
  },
  dataHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 20,
    marginHorizontal: 20,
    marginBottom: 24,
    borderBottomColor: '#BEC3C6',
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  date: {
    marginRight: 24,
  },
  test: {
    // width: 100,
    // height: 100,
    // backgroundColor: 'red',
    position: 'absolute',
    top: -50,
  },
  updateButton: {
    marginTop: 40,
  },
});

export default ConsumptionModalBottomSheet;
