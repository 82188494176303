import { Pressable, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { MarkedDates } from 'react-native-calendars/src/types';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';
import colors from '@styles/colors';
import SpModal from './SpModal';
import { testProperties } from '@utils/testProperties';
import CalendarDayComponent from './CalendarDayComponent';
import { SpText } from './SpText/SpText';
import { SpView } from './SpView';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { closeModal } from 'src/services/ModalBox';

interface CalendarModalProps {
  selectedDate: string;
  setSelectedDate: (date: number) => void;
  modal?: string;
  warningDates?: string[];
  allowInsights?: boolean;
}

const CalendarModal = ({
  selectedDate,
  setSelectedDate,
  modal,
  warningDates,
  allowInsights,
}: CalendarModalProps) => {
  const customHeaderProps: any = React.useRef();
  const markWarningDates = useMemo(() => {
    const commonStyles = {
      marked: true,
      dotColor: colors.orangeWarning.color,
      selectedColor: colors.orangeWarning.color,
    };
    const obj: MarkedDates = {};
    warningDates.forEach((item: string) => {
      obj[item] = {
        ...commonStyles,
        selected: selectedDate === item,
      };
    });
    return allowInsights ? obj : {};
  }, [warningDates, allowInsights, selectedDate]);

  const code = useCurrentLanguageCode();
  const { t } = useTranslation();

  const daysOfTheWeek = [
    'sunday_short',
    'monday_short',
    'tuesday_short',
    'wednesday_short',
    'thursday_short',
    'friday_short',
    'saturday_short',
  ];

  useLayoutEffect(() => {
    if (code) {
      LocaleConfig.locales.en = LocaleConfig.locales[''];
      LocaleConfig.locales[code] = {
        monthNames: [
          t('january'),
          t('february'),
          t('march'),
          t('april'),
          t('may'),
          t('june'),
          t('july'),
          t('august'),
          t('september'),
          t('october'),
          t('november'),
          t('december'),
        ],
        dayNames: [
          t('sunday'),
          t('monday'),
          t('tuesday'),
          t('wednesday'),
          t('thursday'),
          t('friday'),
          t('saturday'),
        ],
        dayNamesShort: [
          t('sunday_short'),
          t('monday_short'),
          t('tuesday_short'),
          t('wednesday_short'),
          t('thursday_short'),
          t('friday_short'),
          t('saturday_short'),
        ],
      };
      LocaleConfig.defaultLocale = code;
    }
  }, [code, t]);

  const arrowIcons = useCallback((direction: string) => {
    if (direction === 'left') {
      return (
        <FontAwesomeIcon
          {...testProperties('LeftCalendarArrow', 'Icon')}
          icon={faCaretLeft}
          color={colors.white.color}
          size={18}
        />
      );
    }
    return (
      <FontAwesomeIcon
        {...testProperties('RightCalendarArrow', 'Icon')}
        icon={faCaretRight}
        color={colors.white.color}
        size={18}
      />
    );
  }, []);

  const setCustomHeaderNewMonth = (next = false) => {
    const add = next ? 1 : -1;
    const month = new Date(customHeaderProps?.current?.month);
    const newMonth = new Date(month.setMonth(month.getMonth() + add));
    customHeaderProps?.current?.addMonth(add);
  };
  const moveNext = () => {
    setCustomHeaderNewMonth(true);
  };
  const movePrevious = () => {
    setCustomHeaderNewMonth(false);
  };

  const CustomHeader = React.forwardRef((props, ref) => {
    customHeaderProps.current = props;

    const date = new Date(customHeaderProps?.current?.month);
    const month = t(format(date, 'MMMM', { locale: enUS }).toLowerCase());
    const year = format(date, 'yyyy');
    const monthUpperCase = month.charAt(0).toUpperCase() + month.slice(1);

    return (
      // @ts-expect-error
      <View
        accessible={false}
        ref={ref}
        {...props}
        style={styles.customHeader}
      >
        <View
          accessible={false}
          style={styles.customUpperHeader}
        >
          <TouchableOpacity
            accessible={false}
            onPress={movePrevious}
          >
            <SpView {...testProperties('LeftCalendarArrow', 'Icon')}>{arrowIcons('left')}</SpView>
          </TouchableOpacity>
          <SpText
            accessible={false}
            style={styles.headerText}
          >{`${monthUpperCase} ${year}`}</SpText>
          <TouchableOpacity
            accessible={false}
            onPress={moveNext}
          >
            <SpView {...testProperties('RightCalendarArrow', 'Icon')}>{arrowIcons('right')}</SpView>
          </TouchableOpacity>
        </View>
        <View style={styles.customBottomHeader}>
          {daysOfTheWeek.map((item, index) => {
            return (
              <SpText
                style={styles.customBottomText}
                key={`${item}${index}`}
              >
                {t(item)}
              </SpText>
            );
          })}
        </View>
      </View>
    );
  });

  return (
    <SpModal
      accessible={false}
      backdropColor="transparent"
      modalName={modal}
    >
      <View
        accessible={false}
        style={styles.wrapper}
      >
        <Pressable
          accessible={false}
          style={[styles.content]}
        >
          <Calendar
            dayComponent={({ date, state, marking }) => {
              return (
                <CalendarDayComponent
                  date={date}
                  state={state}
                  marking={marking}
                  onPress={(date: number) => {
                    setSelectedDate(date);
                    closeModal(modal);
                  }}
                />
              );
            }}
            customHeader={CustomHeader}
            markedDates={{
              [selectedDate]: {
                selected: true,
                selectedColor: colors.secondary.color,
              },
              ...markWarningDates,
            }}
            maxDate={new Date().toDateString()}
            initialDate={selectedDate}
            theme={{
              calendarBackground: colors.greyText.color,
            }}
          />
        </Pressable>
      </View>
    </SpModal>
  );
};

const styles = StyleSheet.create({
  content: {
    width: '90%',
    borderRadius: 10,
    overflow: 'hidden',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    marginTop: 56,
  },
  customUpperHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 15,
    alignItems: 'center',
  },
  customBottomHeader: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingBottom: 12,
  },
  customHeader: {},
  headerText: {
    color: colors.white.color,
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
  },
  customBottomText: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
    color: colors.secondary.color,
  },
});

export default CalendarModal;
