import { dismissKeyboard } from '@utils/keyboardDismiss';
import React, { SetStateAction, useCallback, useState } from 'react';
import { Platform, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

import useBoundStore from 'src/store/store';
import { useTranslation } from 'react-i18next';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { AnalyticsService } from 'src/services/AnalyticsService';
import SpSearchList from '../../../components/SpSearchList';

interface HouseholdTimezonesProps {
  currentTimezoneId?: number;
  closeOnSelect?: boolean;
  handleChange: (timezone_id: SetStateAction<any>) => void;
  handleClose?: () => void;
  withButton?: boolean;
}

const HouseholdTimezonesWrapper = ({
  currentTimezoneId,
  closeOnSelect = false,
  handleChange,
  handleClose,
  withButton = false,
}: HouseholdTimezonesProps) => {
  const { timezones } = useBoundStore(state => state.timezoneStore);
  const { resetInsights } = useBoundStore(state => state.petStore);
  const [selectedItemId, setSelectedItemId] = useState<number[]>(
    currentTimezoneId ? [currentTimezoneId] : [],
  );

  const { t } = useTranslation();

  const onChangeTimezone = useCallback((ids: number[]) => {
    AnalyticsService.logEvent(`household_update_timezone`);

    if (!withButton) handleChange(ids[0]);
    setSelectedItemId(ids);
    resetInsights();
    if (closeOnSelect) handleClose();
  }, []);
  return (
    <TouchableWithoutFeedback
      onPress={() => dismissKeyboard()}
      style={styles.container}
    >
      <View style={styles.container}>
        <SpSearchList
          arr={timezones}
          selectedItems={selectedItemId}
          setItemId={onChangeTimezone}
          hasInput
          inputProps={{ testID: 'CountryOrCity' }}
        />
        {withButton && (
          <View style={styles.buttonWrap}>
            <SpRoundedHeaderButton
              disabled={selectedItemId.length === 0}
              backgroundColor={colors.primary.color}
              h={56}
              title={t('save')}
              onPress={() => {
                handleChange(selectedItemId);
                handleClose();
              }}
              stylesForContainer={Platform.OS === 'android' ? { marginBottom: 30 } : undefined}
            />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  buttonWrap: {
    position: 'absolute',
    bottom: 68,
    right: 19,
    left: 19,
    shadowColor: colors.greyText.color,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8.95,
    elevation: 18,
  },
});

export default HouseholdTimezonesWrapper;
