// src/locale/translationLoader.ts

// Sections of the app
// [
//   'GENERIC',
//   'AB',
//   'AA',
//   'AC',
//   'BA',
//   'BC',
//   'BD',
//   'CA',
//   'EA',
//   'EB',
//   'FA',
//   'FB',
//   'FC',
//   'FD',
//   'GB',
//   'GA',
//   'GCA',
//   'GCB',
//   'GCC',
//   'GCD',
//   'GCE',
//   'GDA',
//   'GDB',
//   'GE',
//   'GFB',
//   'GFC',
//   'GFD',
//   'GG',
//   'DB',
//   'HA',
//   'HC',
//   'HF',
//   'HAA',
//   'HCA',
//   'HCC',
//   'HCD',
//   'HEA',
//   'JA',
//   'KA',
//   'CB',
//   'LA',
//   'MA',
//   'NA',
//   'OA'
// ]

// import from en, cs, de, es, fi, fr, hu, it, ja, nl, pl, pt, ro, sv, zh

// en
// import enGENERIC from './en/GENERIC.json';
// import enAA from './en/AA.json';
// import enAB from './en/AB.json';
// import enAC from './en/AC.json';
// import enBA from './en/BA.json';
// import enBC from './en/BC.json';
// import enBD from './en/BD.json';
// import enCA from './en/CA.json';
// import enEA from './en/EA.json';
// import enEB from './en/EB.json';
// import enFA from './en/FA.json';
// import enFB from './en/FB.json';
// import enFC from './en/FC.json';
// import enFD from './en/FD.json';
// import enGB from './en/GB.json';
// import enGA from './en/GA.json';
// import enGCA from './en/GCA.json';
// import enGCB from './en/GCB.json';
// import enGCC from './en/GCC.json';
// import enGCD from './en/GCD.json';
// import enGCE from './en/GCE.json';
// import enGDA from './en/GDA.json';
// import enGDB from './en/GDB.json';
// import enGE from './en/GE.json';
// import enGFB from './en/GFB.json';
// import enGFC from './en/GFC.json';
// import enGFD from './en/GFD.json';
// import enGG from './en/GG.json';
// import enDB from './en/DB.json';
// import enHA from './en/HA.json';
// import enHC from './en/HC.json';
// import enHF from './en/HF.json';
// import enHAA from './en/HAA.json';
// import enHCA from './en/HCA.json';
// import enHCC from './en/HCC.json';
// import enHCD from './en/HCD.json';
// import enHEA from './en/HEA.json';
// import enJA from './en/JA.json';
// import enKA from './en/KA.json';
// import enCB from './en/CB.json';
// import enLA from './en/LA.json';
// import enMA from './en/MA.json';
// import enNA from './en/NA.json';
// import enOA from './en/OA.json';
// import enOther from './en/other.json';

import enAB from './en/AB.json';
import enAA from './en/AA.json';
import enAC from './en/AC.json';
import enAD from './en/AD.json';
import enBC from './en/BC.json';
import enBD from './en/BD.json';
import enCA from './en/CA.json';
import enEA from './en/EA.json';
import enEB from './en/EB.json';
import enEC from './en/EC.json';
import enFA from './en/FA.json';
import enFB from './en/FB.json';
import enFC from './en/FC.json';
import enFD from './en/FD.json';
import enGB from './en/GB.json';
import enGA from './en/GA.json';
import enGCA from './en/GCA.json';
import enGCB from './en/GCB.json';
import enGCC from './en/GCC.json';
import enGCD from './en/GCD.json';
import enGCE from './en/GCE.json';
import enGDA from './en/GDA.json';
import enGDB from './en/GDB.json';
import enGFB from './en/GFB.json';
import enGFC from './en/GFC.json';
import enGFD from './en/GFD.json';
import enGG from './en/GG.json';
import enDB from './en/DB.json';
import enHA from './en/HA.json';
import enHC from './en/HC.json';
import enHF from './en/HF.json';
import enHAA from './en/HAA.json';
import enHCA from './en/HCA.json';
import enHCB from './en/HCB.json';
import enHCC from './en/HCC.json';
import enHCD from './en/HCD.json';
import enHEA from './en/HEA.json';
import enJA from './en/JA.json';
import enBA from './en/BA.json';
import enCB from './en/CB.json';
import enLA from './en/LA.json';
import enMA from './en/MA.json';
import enGE from './en/GE.json';
import enGDC from './en/GDC.json';
import enNA from './en/NA.json';
import enOA from './en/OA.json';
import enDBA from './en/DBA.json';
import enDAA from './en/DAA.json';
import enQA from './en/QA.json';
import enRA from './en/RA.json';
import enKA from './en/KA.json';
import enDC from './en/DC.json';
import enMB from './en/MB.json';
import enPA from './en/PA.json';
import enCI from './en/CI.json';
import enToast from './en/toast.json';
import enNotification from './en/notification.json';
import enTimeline from './en/timeline.json';
import enCommon from './en/common.json';
import enOther from './en/other.json';

// //cs
import csAB from './cs/AB.json';
import csAA from './cs/AA.json';
import csAC from './cs/AC.json';
import csAD from './cs/AD.json';
import csBC from './cs/BC.json';
import csBD from './cs/BD.json';
import csCA from './cs/CA.json';
import csEA from './cs/EA.json';
import csEB from './cs/EB.json';
import csEC from './cs/EC.json';
import csFA from './cs/FA.json';
import csFB from './cs/FB.json';
import csFC from './cs/FC.json';
import csFD from './cs/FD.json';
import csGB from './cs/GB.json';
import csGA from './cs/GA.json';
import csGCA from './cs/GCA.json';
import csGCB from './cs/GCB.json';
import csGCC from './cs/GCC.json';
import csGCD from './cs/GCD.json';
import csGCE from './cs/GCE.json';
import csGDA from './cs/GDA.json';
import csGDB from './cs/GDB.json';
import csGFB from './cs/GFB.json';
import csGFC from './cs/GFC.json';
import csGFD from './cs/GFD.json';
import csGG from './cs/GG.json';
import csDB from './cs/DB.json';
import csHA from './cs/HA.json';
import csHC from './cs/HC.json';
import csHF from './cs/HF.json';
import csHAA from './cs/HAA.json';
import csHCA from './cs/HCA.json';
import csHCB from './cs/HCB.json';
import csHCC from './cs/HCC.json';
import csHCD from './cs/HCD.json';
import csHEA from './cs/HEA.json';
import csJA from './cs/JA.json';
import csBA from './cs/BA.json';
import csCB from './cs/CB.json';
import csLA from './cs/LA.json';
import csMA from './cs/MA.json';
import csGE from './cs/GE.json';
import csGDC from './cs/GDC.json';
import csNA from './cs/NA.json';
import csOA from './cs/OA.json';
import csDBA from './cs/DBA.json';
import csDAA from './cs/DAA.json';
import csQA from './cs/QA.json';
import csKA from './cs/KA.json';
import csDC from './cs/DC.json';
import csMB from './cs/MB.json';
import csPA from './cs/PA.json';
import csCI from './cs/CI.json';
import csToast from './cs/toast.json';
import csNotification from './cs/notification.json';
import csTimeline from './cs/timeline.json';
import csCommon from './cs/common.json';
import csOther from './cs/other.json';

//
// // de
import deAB from './de/AB.json';
import deAA from './de/AA.json';
import deAC from './de/AC.json';
import deAD from './de/AD.json';
import deBC from './de/BC.json';
import deBD from './de/BD.json';
import deCA from './de/CA.json';
import deEA from './de/EA.json';
import deEB from './de/EB.json';
import deEC from './de/EC.json';
import deFA from './de/FA.json';
import deFB from './de/FB.json';
import deFC from './de/FC.json';
import deFD from './de/FD.json';
import deGB from './de/GB.json';
import deGA from './de/GA.json';
import deGCA from './de/GCA.json';
import deGCB from './de/GCB.json';
import deGCC from './de/GCC.json';
import deGCD from './de/GCD.json';
import deGCE from './de/GCE.json';
import deGDA from './de/GDA.json';
import deGDB from './de/GDB.json';
import deGFB from './de/GFB.json';
import deGFC from './de/GFC.json';
import deGFD from './de/GFD.json';
import deGG from './de/GG.json';
import deDB from './de/DB.json';
import deHA from './de/HA.json';
import deHC from './de/HC.json';
import deHF from './de/HF.json';
import deHAA from './de/HAA.json';
import deHCA from './de/HCA.json';
import deHCB from './de/HCB.json';
import deHCC from './de/HCC.json';
import deHCD from './de/HCD.json';
import deHEA from './de/HEA.json';
import deJA from './de/JA.json';
import deBA from './de/BA.json';
import deCB from './de/CB.json';
import deLA from './de/LA.json';
import deMA from './de/MA.json';
import deGE from './de/GE.json';
import deGDC from './de/GDC.json';
import deNA from './de/NA.json';
import deOA from './de/OA.json';
import deDBA from './de/DBA.json';
import deDAA from './de/DAA.json';
import deQA from './de/QA.json';
import deKA from './de/KA.json';
import deDC from './de/DC.json';
import deMB from './de/MB.json';
import dePA from './de/PA.json';
import deCI from './de/CI.json';
import deToast from './de/toast.json';
import deNotification from './de/notification.json';
import deTimeline from './de/timeline.json';
import deCommon from './de/common.json';
import deOther from './de/other.json';
//
// // es
import esAB from './es/AB.json';
import esAA from './es/AA.json';
import esAC from './es/AC.json';
import esAD from './es/AD.json';
import esBC from './es/BC.json';
import esBD from './es/BD.json';
import esCA from './es/CA.json';
import esEA from './es/EA.json';
import esEB from './es/EB.json';
import esEC from './es/EC.json';
import esFA from './es/FA.json';
import esFB from './es/FB.json';
import esFC from './es/FC.json';
import esFD from './es/FD.json';
import esGB from './es/GB.json';
import esGA from './es/GA.json';
import esGCA from './es/GCA.json';
import esGCB from './es/GCB.json';
import esGCC from './es/GCC.json';
import esGCD from './es/GCD.json';
import esGCE from './es/GCE.json';
import esGDA from './es/GDA.json';
import esGDB from './es/GDB.json';
import esGFB from './es/GFB.json';
import esGFC from './es/GFC.json';
import esGFD from './es/GFD.json';
import esGG from './es/GG.json';
import esDB from './es/DB.json';
import esHA from './es/HA.json';
import esHC from './es/HC.json';
import esHF from './es/HF.json';
import esHAA from './es/HAA.json';
import esHCA from './es/HCA.json';
import esHCB from './es/HCB.json';
import esHCC from './es/HCC.json';
import esHCD from './es/HCD.json';
import esHEA from './es/HEA.json';
import esJA from './es/JA.json';
import esBA from './es/BA.json';
import esCB from './es/CB.json';
import esLA from './es/LA.json';
import esMA from './es/MA.json';
import esGE from './es/GE.json';
import esGDC from './es/GDC.json';
import esNA from './es/NA.json';
import esOA from './es/OA.json';
import esDBA from './es/DBA.json';
import esDAA from './es/DAA.json';
import esQA from './es/QA.json';
import esKA from './es/KA.json';
import esDC from './es/DC.json';
import esMB from './es/MB.json';
import esPA from './es/PA.json';
import esCI from './es/CI.json';
import esToast from './es/toast.json';
import esNotification from './es/notification.json';
import esTimeline from './es/timeline.json';
import esCommon from './es/common.json';
import esOther from './es/other.json';
//
// // fi
import fiAB from './fi/AB.json';
import fiAA from './fi/AA.json';
import fiAC from './fi/AC.json';
import fiAD from './fi/AD.json';
import fiBC from './fi/BC.json';
import fiBD from './fi/BD.json';
import fiCA from './fi/CA.json';
import fiEA from './fi/EA.json';
import fiEB from './fi/EB.json';
import fiEC from './fi/EC.json';
import fiFA from './fi/FA.json';
import fiFB from './fi/FB.json';
import fiFC from './fi/FC.json';
import fiFD from './fi/FD.json';
import fiGB from './fi/GB.json';
import fiGA from './fi/GA.json';
import fiGCA from './fi/GCA.json';
import fiGCB from './fi/GCB.json';
import fiGCC from './fi/GCC.json';
import fiGCD from './fi/GCD.json';
import fiGCE from './fi/GCE.json';
import fiGDA from './fi/GDA.json';
import fiGDB from './fi/GDB.json';
import fiGFB from './fi/GFB.json';
import fiGFC from './fi/GFC.json';
import fiGFD from './fi/GFD.json';
import fiGG from './fi/GG.json';
import fiDB from './fi/DB.json';
import fiHA from './fi/HA.json';
import fiHC from './fi/HC.json';
import fiHF from './fi/HF.json';
import fiHAA from './fi/HAA.json';
import fiHCA from './fi/HCA.json';
import fiHCB from './fi/HCB.json';
import fiHCC from './fi/HCC.json';
import fiHCD from './fi/HCD.json';
import fiHEA from './fi/HEA.json';
import fiJA from './fi/JA.json';
import fiBA from './fi/BA.json';
import fiCB from './fi/CB.json';
import fiLA from './fi/LA.json';
import fiMA from './fi/MA.json';
import fiGE from './fi/GE.json';
import fiGDC from './fi/GDC.json';
import fiNA from './fi/NA.json';
import fiOA from './fi/OA.json';
import fiDBA from './fi/DBA.json';
import fiDAA from './fi/DAA.json';
import fiQA from './fi/QA.json';
import fiKA from './fi/KA.json';
import fiDC from './fi/DC.json';
import fiMB from './fi/MB.json';
import fiPA from './fi/PA.json';
import fiCI from './fi/CI.json';
import fiToast from './fi/toast.json';
import fiNotification from './fi/notification.json';
import fiTimeline from './fi/timeline.json';
import fiCommon from './fi/common.json';
import fiOther from './fi/other.json';
//
// // fr
import frAB from './fr/AB.json';
import frAA from './fr/AA.json';
import frAC from './fr/AC.json';
import frAD from './fr/AD.json';
import frBC from './fr/BC.json';
import frBD from './fr/BD.json';
import frCA from './fr/CA.json';
import frEA from './fr/EA.json';
import frEB from './fr/EB.json';
import frEC from './fr/EC.json';
import frFA from './fr/FA.json';
import frFB from './fr/FB.json';
import frFC from './fr/FC.json';
import frFD from './fr/FD.json';
import frGB from './fr/GB.json';
import frGA from './fr/GA.json';
import frGCA from './fr/GCA.json';
import frGCB from './fr/GCB.json';
import frGCC from './fr/GCC.json';
import frGCD from './fr/GCD.json';
import frGCE from './fr/GCE.json';
import frGDA from './fr/GDA.json';
import frGDB from './fr/GDB.json';
import frGFB from './fr/GFB.json';
import frGFC from './fr/GFC.json';
import frGFD from './fr/GFD.json';
import frGG from './fr/GG.json';
import frDB from './fr/DB.json';
import frHA from './fr/HA.json';
import frHC from './fr/HC.json';
import frHF from './fr/HF.json';
import frHAA from './fr/HAA.json';
import frHCA from './fr/HCA.json';
import frHCB from './fr/HCB.json';
import frHCC from './fr/HCC.json';
import frHCD from './fr/HCD.json';
import frHEA from './fr/HEA.json';
import frJA from './fr/JA.json';
import frBA from './fr/BA.json';
import frCB from './fr/CB.json';
import frLA from './fr/LA.json';
import frMA from './fr/MA.json';
import frGE from './fr/GE.json';
import frGDC from './fr/GDC.json';
import frNA from './fr/NA.json';
import frOA from './fr/OA.json';
import frDBA from './fr/DBA.json';
import frDAA from './fr/DAA.json';
import frQA from './fr/QA.json';
import frKA from './fr/KA.json';
import frDC from './fr/DC.json';
import frMB from './fr/MB.json';
import frPA from './fr/PA.json';
import frCI from './fr/CI.json';
import frToast from './fr/toast.json';
import frNotification from './fr/notification.json';
import frTimeline from './fr/timeline.json';
import frCommon from './fr/common.json';
import frOther from './fr/other.json';
//
// // hu
import huAB from './hu/AB.json';
import huAA from './hu/AA.json';
import huAC from './hu/AC.json';
import huAD from './hu/AD.json';
import huBC from './hu/BC.json';
import huBD from './hu/BD.json';
import huCA from './hu/CA.json';
import huEA from './hu/EA.json';
import huEB from './hu/EB.json';
import huEC from './hu/EC.json';
import huFA from './hu/FA.json';
import huFB from './hu/FB.json';
import huFC from './hu/FC.json';
import huFD from './hu/FD.json';
import huGB from './hu/GB.json';
import huGA from './hu/GA.json';
import huGCA from './hu/GCA.json';
import huGCB from './hu/GCB.json';
import huGCC from './hu/GCC.json';
import huGCD from './hu/GCD.json';
import huGCE from './hu/GCE.json';
import huGDA from './hu/GDA.json';
import huGDB from './hu/GDB.json';
import huGFB from './hu/GFB.json';
import huGFC from './hu/GFC.json';
import huGFD from './hu/GFD.json';
import huGG from './hu/GG.json';
import huDB from './hu/DB.json';
import huHA from './hu/HA.json';
import huHC from './hu/HC.json';
import huHF from './hu/HF.json';
import huHAA from './hu/HAA.json';
import huHCA from './hu/HCA.json';
import huHCB from './hu/HCB.json';
import huHCC from './hu/HCC.json';
import huHCD from './hu/HCD.json';
import huHEA from './hu/HEA.json';
import huJA from './hu/JA.json';
import huBA from './hu/BA.json';
import huCB from './hu/CB.json';
import huLA from './hu/LA.json';
import huMA from './hu/MA.json';
import huGE from './hu/GE.json';
import huGDC from './hu/GDC.json';
import huNA from './hu/NA.json';
import huOA from './hu/OA.json';
import huDBA from './hu/DBA.json';
import huDAA from './hu/DAA.json';
import huQA from './hu/QA.json';
import huKA from './hu/KA.json';
import huDC from './hu/DC.json';
import huMB from './hu/MB.json';
import huPA from './hu/PA.json';
import huCI from './hu/CI.json';
import huToast from './hu/toast.json';
import huNotification from './hu/notification.json';
import huTimeline from './hu/timeline.json';
import huCommon from './hu/common.json';
import huOther from './hu/other.json';
//
// // it
import itAB from './it/AB.json';
import itAA from './it/AA.json';
import itAC from './it/AC.json';
import itAD from './it/AD.json';
import itBC from './it/BC.json';
import itBD from './it/BD.json';
import itCA from './it/CA.json';
import itEA from './it/EA.json';
import itEB from './it/EB.json';
import itEC from './it/EC.json';
import itFA from './it/FA.json';
import itFB from './it/FB.json';
import itFC from './it/FC.json';
import itFD from './it/FD.json';
import itGB from './it/GB.json';
import itGA from './it/GA.json';
import itGCA from './it/GCA.json';
import itGCB from './it/GCB.json';
import itGCC from './it/GCC.json';
import itGCD from './it/GCD.json';
import itGCE from './it/GCE.json';
import itGDA from './it/GDA.json';
import itGDB from './it/GDB.json';
import itGFB from './it/GFB.json';
import itGFC from './it/GFC.json';
import itGFD from './it/GFD.json';
import itGG from './it/GG.json';
import itDB from './it/DB.json';
import itHA from './it/HA.json';
import itHC from './it/HC.json';
import itHF from './it/HF.json';
import itHAA from './it/HAA.json';
import itHCA from './it/HCA.json';
import itHCB from './it/HCB.json';
import itHCC from './it/HCC.json';
import itHCD from './it/HCD.json';
import itHEA from './it/HEA.json';
import itJA from './it/JA.json';
import itBA from './it/BA.json';
import itCB from './it/CB.json';
import itLA from './it/LA.json';
import itMA from './it/MA.json';
import itGE from './it/GE.json';
import itGDC from './it/GDC.json';
import itNA from './it/NA.json';
import itOA from './it/OA.json';
import itDBA from './it/DBA.json';
import itDAA from './it/DAA.json';
import itQA from './it/QA.json';
import itKA from './it/KA.json';
import itDC from './it/DC.json';
import itMB from './it/MB.json';
import itPA from './it/PA.json';
import itCI from './it/CI.json';
import itToast from './it/toast.json';
import itNotification from './it/notification.json';
import itTimeline from './it/timeline.json';
import itCommon from './it/common.json';
import itOther from './it/other.json';
//
// // ja
import jaAB from './ja/AB.json';
import jaAA from './ja/AA.json';
import jaAC from './ja/AC.json';
import jaAD from './ja/AD.json';
import jaBC from './ja/BC.json';
import jaBD from './ja/BD.json';
import jaCA from './ja/CA.json';
import jaEA from './ja/EA.json';
import jaEB from './ja/EB.json';
import jaEC from './ja/EC.json';
import jaFA from './ja/FA.json';
import jaFB from './ja/FB.json';
import jaFC from './ja/FC.json';
import jaFD from './ja/FD.json';
import jaGB from './ja/GB.json';
import jaGA from './ja/GA.json';
import jaGCA from './ja/GCA.json';
import jaGCB from './ja/GCB.json';
import jaGCC from './ja/GCC.json';
import jaGCD from './ja/GCD.json';
import jaGCE from './ja/GCE.json';
import jaGDA from './ja/GDA.json';
import jaGDB from './ja/GDB.json';
import jaGFB from './ja/GFB.json';
import jaGFC from './ja/GFC.json';
import jaGFD from './ja/GFD.json';
import jaGG from './ja/GG.json';
import jaDB from './ja/DB.json';
import jaHA from './ja/HA.json';
import jaHC from './ja/HC.json';
import jaHF from './ja/HF.json';
import jaHAA from './ja/HAA.json';
import jaHCA from './ja/HCA.json';
import jaHCB from './ja/HCB.json';
import jaHCC from './ja/HCC.json';
import jaHCD from './ja/HCD.json';
import jaHEA from './ja/HEA.json';
import jaJA from './ja/JA.json';
import jaBA from './ja/BA.json';
import jaCB from './ja/CB.json';
import jaLA from './ja/LA.json';
import jaMA from './ja/MA.json';
import jaGE from './ja/GE.json';
import jaGDC from './ja/GDC.json';
import jaNA from './ja/NA.json';
import jaOA from './ja/OA.json';
import jaDBA from './ja/DBA.json';
import jaDAA from './ja/DAA.json';
import jaQA from './ja/QA.json';
import jaKA from './ja/KA.json';
import jaDC from './ja/DC.json';
import jaMB from './ja/MB.json';
import jaPA from './ja/PA.json';
import jaCI from './ja/CI.json';
import jaToast from './ja/toast.json';
import jaNotification from './ja/notification.json';
import jaTimeline from './ja/timeline.json';
import jaCommon from './ja/common.json';
import jaOther from './ja/other.json';
//
// // nl
import nlAB from './nl/AB.json';
import nlAA from './nl/AA.json';
import nlAC from './nl/AC.json';
import nlAD from './nl/AD.json';
import nlBC from './nl/BC.json';
import nlBD from './nl/BD.json';
import nlCA from './nl/CA.json';
import nlEA from './nl/EA.json';
import nlEB from './nl/EB.json';
import nlEC from './nl/EC.json';
import nlFA from './nl/FA.json';
import nlFB from './nl/FB.json';
import nlFC from './nl/FC.json';
import nlFD from './nl/FD.json';
import nlGB from './nl/GB.json';
import nlGA from './nl/GA.json';
import nlGCA from './nl/GCA.json';
import nlGCB from './nl/GCB.json';
import nlGCC from './nl/GCC.json';
import nlGCD from './nl/GCD.json';
import nlGCE from './nl/GCE.json';
import nlGDA from './nl/GDA.json';
import nlGDB from './nl/GDB.json';
import nlGFB from './nl/GFB.json';
import nlGFC from './nl/GFC.json';
import nlGFD from './nl/GFD.json';
import nlGG from './nl/GG.json';
import nlDB from './nl/DB.json';
import nlHA from './nl/HA.json';
import nlHC from './nl/HC.json';
import nlHF from './nl/HF.json';
import nlHAA from './nl/HAA.json';
import nlHCA from './nl/HCA.json';
import nlHCB from './nl/HCB.json';
import nlHCC from './nl/HCC.json';
import nlHCD from './nl/HCD.json';
import nlHEA from './nl/HEA.json';
import nlJA from './nl/JA.json';
import nlBA from './nl/BA.json';
import nlCB from './nl/CB.json';
import nlLA from './nl/LA.json';
import nlMA from './nl/MA.json';
import nlGE from './nl/GE.json';
import nlGDC from './nl/GDC.json';
import nlNA from './nl/NA.json';
import nlOA from './nl/OA.json';
import nlDBA from './nl/DBA.json';
import nlDAA from './nl/DAA.json';
import nlQA from './nl/QA.json';
import nlKA from './nl/KA.json';
import nlDC from './nl/DC.json';
import nlMB from './nl/MB.json';
import nlPA from './nl/PA.json';
import nlCI from './nl/CI.json';
import nlToast from './nl/toast.json';
import nlNotification from './nl/notification.json';
import nlTimeline from './nl/timeline.json';
import nlCommon from './nl/common.json';
import nlOther from './nl/other.json';
//
// // pl
import plAB from './pl/AB.json';
import plAA from './pl/AA.json';
import plAC from './pl/AC.json';
import plAD from './pl/AD.json';
import plBC from './pl/BC.json';
import plBD from './pl/BD.json';
import plCA from './pl/CA.json';
import plEA from './pl/EA.json';
import plEB from './pl/EB.json';
import plEC from './pl/EC.json';
import plFA from './pl/FA.json';
import plFB from './pl/FB.json';
import plFC from './pl/FC.json';
import plFD from './pl/FD.json';
import plGB from './pl/GB.json';
import plGA from './pl/GA.json';
import plGCA from './pl/GCA.json';
import plGCB from './pl/GCB.json';
import plGCC from './pl/GCC.json';
import plGCD from './pl/GCD.json';
import plGCE from './pl/GCE.json';
import plGDA from './pl/GDA.json';
import plGDB from './pl/GDB.json';
import plGFB from './pl/GFB.json';
import plGFC from './pl/GFC.json';
import plGFD from './pl/GFD.json';
import plGG from './pl/GG.json';
import plDB from './pl/DB.json';
import plHA from './pl/HA.json';
import plHC from './pl/HC.json';
import plHF from './pl/HF.json';
import plHAA from './pl/HAA.json';
import plHCA from './pl/HCA.json';
import plHCB from './pl/HCB.json';
import plHCC from './pl/HCC.json';
import plHCD from './pl/HCD.json';
import plHEA from './pl/HEA.json';
import plJA from './pl/JA.json';
import plBA from './pl/BA.json';
import plCB from './pl/CB.json';
import plLA from './pl/LA.json';
import plMA from './pl/MA.json';
import plGE from './pl/GE.json';
import plGDC from './pl/GDC.json';
import plNA from './pl/NA.json';
import plOA from './pl/OA.json';
import plDBA from './pl/DBA.json';
import plDAA from './pl/DAA.json';
import plQA from './pl/QA.json';
import plKA from './pl/KA.json';
import plDC from './pl/DC.json';
import plMB from './pl/MB.json';
import plPA from './pl/PA.json';
import plCI from './pl/CI.json';
import plToast from './pl/toast.json';
import plNotification from './pl/notification.json';
import plTimeline from './pl/timeline.json';
import plCommon from './pl/common.json';
import plOther from './pl/other.json';
//
// // pt
import ptAB from './pt/AB.json';
import ptAA from './pt/AA.json';
import ptAC from './pt/AC.json';
import ptAD from './pt/AD.json';
import ptBC from './pt/BC.json';
import ptBD from './pt/BD.json';
import ptCA from './pt/CA.json';
import ptEA from './pt/EA.json';
import ptEB from './pt/EB.json';
import ptEC from './pt/EC.json';
import ptFA from './pt/FA.json';
import ptFB from './pt/FB.json';
import ptFC from './pt/FC.json';
import ptFD from './pt/FD.json';
import ptGB from './pt/GB.json';
import ptGA from './pt/GA.json';
import ptGCA from './pt/GCA.json';
import ptGCB from './pt/GCB.json';
import ptGCC from './pt/GCC.json';
import ptGCD from './pt/GCD.json';
import ptGCE from './pt/GCE.json';
import ptGDA from './pt/GDA.json';
import ptGDB from './pt/GDB.json';
import ptGFB from './pt/GFB.json';
import ptGFC from './pt/GFC.json';
import ptGFD from './pt/GFD.json';
import ptGG from './pt/GG.json';
import ptDB from './pt/DB.json';
import ptHA from './pt/HA.json';
import ptHC from './pt/HC.json';
import ptHF from './pt/HF.json';
import ptHAA from './pt/HAA.json';
import ptHCA from './pt/HCA.json';
import ptHCB from './pt/HCB.json';
import ptHCC from './pt/HCC.json';
import ptHCD from './pt/HCD.json';
import ptHEA from './pt/HEA.json';
import ptJA from './pt/JA.json';
import ptBA from './pt/BA.json';
import ptCB from './pt/CB.json';
import ptLA from './pt/LA.json';
import ptMA from './pt/MA.json';
import ptGE from './pt/GE.json';
import ptGDC from './pt/GDC.json';
import ptNA from './pt/NA.json';
import ptOA from './pt/OA.json';
import ptDBA from './pt/DBA.json';
import ptDAA from './pt/DAA.json';
import ptQA from './pt/QA.json';
import ptKA from './pt/KA.json';
import ptDC from './pt/DC.json';
import ptMB from './pt/MB.json';
import ptPA from './pt/PA.json';
import ptCI from './pt/CI.json';
import ptToast from './pt/toast.json';
import ptNotification from './pt/notification.json';
import ptTimeline from './pt/timeline.json';
import ptCommon from './pt/common.json';
import ptOther from './pt/other.json';

import ptbrAB from './pt-BR/AB.json';
import ptbrAA from './pt-BR/AA.json';
import ptbrAC from './pt-BR/AC.json';
import ptbrAD from './pt-BR/AD.json';
import ptbrBC from './pt-BR/BC.json';
import ptbrBD from './pt-BR/BD.json';
import ptbrCA from './pt-BR/CA.json';
import ptbrEA from './pt-BR/EA.json';
import ptbrEB from './pt-BR/EB.json';
import ptbrEC from './pt-BR/EC.json';
import ptbrFA from './pt-BR/FA.json';
import ptbrFB from './pt-BR/FB.json';
import ptbrFC from './pt-BR/FC.json';
import ptbrFD from './pt-BR/FD.json';
import ptbrGB from './pt-BR/GB.json';
import ptbrGA from './pt-BR/GA.json';
import ptbrGCA from './pt-BR/GCA.json';
import ptbrGCB from './pt-BR/GCB.json';
import ptbrGCC from './pt-BR/GCC.json';
import ptbrGCD from './pt-BR/GCD.json';
import ptbrGCE from './pt-BR/GCE.json';
import ptbrGDA from './pt-BR/GDA.json';
import ptbrGDB from './pt-BR/GDB.json';
import ptbrGFB from './pt-BR/GFB.json';
import ptbrGFC from './pt-BR/GFC.json';
import ptbrGFD from './pt-BR/GFD.json';
import ptbrGG from './pt-BR/GG.json';
import ptbrDB from './pt-BR/DB.json';
import ptbrHA from './pt-BR/HA.json';
import ptbrHC from './pt-BR/HC.json';
import ptbrHF from './pt-BR/HF.json';
import ptbrHAA from './pt-BR/HAA.json';
import ptbrHCA from './pt-BR/HCA.json';
import ptbrHCB from './pt-BR/HCB.json';
import ptbrHCC from './pt-BR/HCC.json';
import ptbrHCD from './pt-BR/HCD.json';
import ptbrHEA from './pt-BR/HEA.json';
import ptbrJA from './pt-BR/JA.json';
import ptbrBA from './pt-BR/BA.json';
import ptbrCB from './pt-BR/CB.json';
import ptbrLA from './pt-BR/LA.json';
import ptbrMA from './pt-BR/MA.json';
import ptbrGE from './pt-BR/GE.json';
import ptbrGDC from './pt-BR/GDC.json';
import ptbrNA from './pt-BR/NA.json';
import ptbrOA from './pt-BR/OA.json';
import ptbrDBA from './pt-BR/DBA.json';
import ptbrDAA from './pt-BR/DAA.json';
import ptbrQA from './pt-BR/QA.json';
import ptbrKA from './pt-BR/KA.json';
import ptbrDC from './pt-BR/DC.json';
import ptbrMB from './pt-BR/MB.json';
import ptbrPA from './pt-BR/PA.json';
import ptbrCI from './pt-BR/CI.json';
import ptbrToast from './pt-BR/toast.json';
import ptbrNotification from './pt-BR/notification.json';
import ptbrTimeline from './pt-BR/timeline.json';
import ptbrCommon from './pt-BR/common.json';
import ptbrOther from './pt-BR/other.json';
//
// // ro
import roAB from './ro/AB.json';
import roAA from './ro/AA.json';
import roAC from './ro/AC.json';
import roAD from './ro/AD.json';
import roBC from './ro/BC.json';
import roBD from './ro/BD.json';
import roCA from './ro/CA.json';
import roEA from './ro/EA.json';
import roEB from './ro/EB.json';
import roEC from './ro/EC.json';
import roFA from './ro/FA.json';
import roFB from './ro/FB.json';
import roFC from './ro/FC.json';
import roFD from './ro/FD.json';
import roGB from './ro/GB.json';
import roGA from './ro/GA.json';
import roGCA from './ro/GCA.json';
import roGCB from './ro/GCB.json';
import roGCC from './ro/GCC.json';
import roGCD from './ro/GCD.json';
import roGCE from './ro/GCE.json';
import roGDA from './ro/GDA.json';
import roGDB from './ro/GDB.json';
import roGFB from './ro/GFB.json';
import roGFC from './ro/GFC.json';
import roGFD from './ro/GFD.json';
import roGG from './ro/GG.json';
import roDB from './ro/DB.json';
import roHA from './ro/HA.json';
import roHC from './ro/HC.json';
import roHF from './ro/HF.json';
import roHAA from './ro/HAA.json';
import roHCA from './ro/HCA.json';
import roHCB from './ro/HCB.json';
import roHCC from './ro/HCC.json';
import roHCD from './ro/HCD.json';
import roHEA from './ro/HEA.json';
import roJA from './ro/JA.json';
import roBA from './ro/BA.json';
import roCB from './ro/CB.json';
import roLA from './ro/LA.json';
import roMA from './ro/MA.json';
import roGE from './ro/GE.json';
import roGDC from './ro/GDC.json';
import roNA from './ro/NA.json';
import roOA from './ro/OA.json';
import roDBA from './ro/DBA.json';
import roDAA from './ro/DAA.json';
import roQA from './ro/QA.json';
import roKA from './ro/KA.json';
import roDC from './ro/DC.json';
import roMB from './ro/MB.json';
import roPA from './ro/PA.json';
import roCI from './ro/CI.json';
import roToast from './ro/toast.json';
import roNotification from './ro/notification.json';
import roTimeline from './ro/timeline.json';
import roCommon from './ro/common.json';
import roOther from './ro/other.json';
//
// // sv
import svAB from './sv/AB.json';
import svAA from './sv/AA.json';
import svAC from './sv/AC.json';
import svAD from './sv/AD.json';
import svBC from './sv/BC.json';
import svBD from './sv/BD.json';
import svCA from './sv/CA.json';
import svEA from './sv/EA.json';
import svEB from './sv/EB.json';
import svEC from './sv/EC.json';
import svFA from './sv/FA.json';
import svFB from './sv/FB.json';
import svFC from './sv/FC.json';
import svFD from './sv/FD.json';
import svGB from './sv/GB.json';
import svGA from './sv/GA.json';
import svGCA from './sv/GCA.json';
import svGCB from './sv/GCB.json';
import svGCC from './sv/GCC.json';
import svGCD from './sv/GCD.json';
import svGCE from './sv/GCE.json';
import svGDA from './sv/GDA.json';
import svGDB from './sv/GDB.json';
import svGFB from './sv/GFB.json';
import svGFC from './sv/GFC.json';
import svGFD from './sv/GFD.json';
import svGG from './sv/GG.json';
import svDB from './sv/DB.json';
import svHA from './sv/HA.json';
import svHC from './sv/HC.json';
import svHF from './sv/HF.json';
import svHAA from './sv/HAA.json';
import svHCA from './sv/HCA.json';
import svHCB from './sv/HCB.json';
import svHCC from './sv/HCC.json';
import svHCD from './sv/HCD.json';
import svHEA from './sv/HEA.json';
import svJA from './sv/JA.json';
import svBA from './sv/BA.json';
import svCB from './sv/CB.json';
import svLA from './sv/LA.json';
import svMA from './sv/MA.json';
import svGE from './sv/GE.json';
import svGDC from './sv/GDC.json';
import svNA from './sv/NA.json';
import svOA from './sv/OA.json';
import svDBA from './sv/DBA.json';
import svDAA from './sv/DAA.json';
import svQA from './sv/QA.json';
import svKA from './sv/KA.json';
import svDC from './sv/DC.json';
import svMB from './sv/MB.json';
import svPA from './sv/PA.json';
import svCI from './sv/CI.json';
import svToast from './sv/toast.json';
import svNotification from './sv/notification.json';
import svTimeline from './sv/timeline.json';
import svCommon from './sv/common.json';
import svOther from './sv/other.json';
//
// // zh
import zhAB from './zh/AB.json';
import zhAA from './zh/AA.json';
import zhAC from './zh/AC.json';
import zhAD from './zh/AD.json';
import zhBC from './zh/BC.json';
import zhBD from './zh/BD.json';
import zhCA from './zh/CA.json';
import zhEA from './zh/EA.json';
import zhEB from './zh/EB.json';
import zhEC from './zh/EC.json';
import zhFA from './zh/FA.json';
import zhFB from './zh/FB.json';
import zhFC from './zh/FC.json';
import zhFD from './zh/FD.json';
import zhGB from './zh/GB.json';
import zhGA from './zh/GA.json';
import zhGCA from './zh/GCA.json';
import zhGCB from './zh/GCB.json';
import zhGCC from './zh/GCC.json';
import zhGCD from './zh/GCD.json';
import zhGCE from './zh/GCE.json';
import zhGDA from './zh/GDA.json';
import zhGDB from './zh/GDB.json';
import zhGFB from './zh/GFB.json';
import zhGFC from './zh/GFC.json';
import zhGFD from './zh/GFD.json';
import zhGG from './zh/GG.json';
import zhDB from './zh/DB.json';
import zhHA from './zh/HA.json';
import zhHC from './zh/HC.json';
import zhHF from './zh/HF.json';
import zhHAA from './zh/HAA.json';
import zhHCA from './zh/HCA.json';
import zhHCB from './zh/HCB.json';
import zhHCC from './zh/HCC.json';
import zhHCD from './zh/HCD.json';
import zhHEA from './zh/HEA.json';
import zhJA from './zh/JA.json';
import zhBA from './zh/BA.json';
import zhCB from './zh/CB.json';
import zhLA from './zh/LA.json';
import zhMA from './zh/MA.json';
import zhGE from './zh/GE.json';
import zhGDC from './zh/GDC.json';
import zhNA from './zh/NA.json';
import zhOA from './zh/OA.json';
import zhDBA from './zh/DBA.json';
import zhDAA from './zh/DAA.json';
import zhQA from './zh/QA.json';
import zhKA from './zh/KA.json';
import zhDC from './zh/DC.json';
import zhMB from './zh/MB.json';
import zhPA from './zh/PA.json';
import zhCI from './zh/CI.json';
import zhToast from './zh/toast.json';
import zhNotification from './zh/notification.json';
import zhTimeline from './zh/timeline.json';
import zhCommon from './zh/common.json';
import zhOther from './zh/other.json';

import enNoTranslate from './en/no-translate.json';
import csNoTranslate from './cs/no-translate.json';
import deNoTranslate from './de/no-translate.json';
import esNoTranslate from './es/no-translate.json';
import frNoTranslate from './fr/no-translate.json';
import fiNoTranslate from './fi/no-translate.json';
import huNoTranslate from './hu/no-translate.json';
import itNoTranslate from './it/no-translate.json';
import jaNoTranslate from './ja/no-translate.json';
import nlNoTranslate from './nl/no-translate.json';
import plNoTranslate from './pl/no-translate.json';
import ptNoTranslate from './pt/no-translate.json';
import ptbrNoTranslate from './pt-BR/no-translate.json';
import roNoTranslate from './ro/no-translate.json';
import svNoTranslate from './sv/no-translate.json';
import zhNoTranslate from './zh/no-translate.json';

export interface ITranslations {
  [lang: string]: {
    [key: string]: any;
  };
}

// Export an object containing all translations from all languages
export const translations: ITranslations = {
  en: {
    ...enAB,
    ...enAA,
    ...enAC,
    ...enAD,
    ...enBC,
    ...enBD,
    ...enCA,
    ...enEA,
    ...enEB,
    ...enEC,
    ...enFA,
    ...enFB,
    ...enFC,
    ...enFD,
    ...enGB,
    ...enGA,
    ...enGCA,
    ...enGCB,
    ...enGCC,
    ...enGCD,
    ...enGCE,
    ...enGDA,
    ...enGDB,
    ...enGFB,
    ...enGFC,
    ...enGFD,
    ...enGG,
    ...enDB,
    ...enHA,
    ...enHC,
    ...enHF,
    ...enHAA,
    ...enHCA,
    ...enHCB,
    ...enHCC,
    ...enHCD,
    ...enHEA,
    ...enJA,
    ...enBA,
    ...enCB,
    ...enLA,
    ...enMA,
    ...enGE,
    ...enGDC,
    ...enNA,
    ...enOA,
    ...enDBA,
    ...enDAA,
    ...enQA,
    ...enRA,
    ...enKA,
    ...enDC,
    ...enMB,
    ...enPA,
    ...enCI,
    ...enToast,
    ...enNotification,
    ...enTimeline,
    ...enCommon,
    ...enOther,
    ...enNoTranslate,
  },
  cs: {
    ...csAB,
    ...csAA,
    ...csAC,
    ...csAD,
    ...csBC,
    ...csBD,
    ...csCA,
    ...csEA,
    ...csEB,
    ...csEC,
    ...csFA,
    ...csFB,
    ...csFC,
    ...csFD,
    ...csGB,
    ...csGA,
    ...csGCA,
    ...csGCB,
    ...csGCC,
    ...csGCD,
    ...csGCE,
    ...csGDA,
    ...csGDB,
    ...csGFB,
    ...csGFC,
    ...csGFD,
    ...csGG,
    ...csDB,
    ...csHA,
    ...csHC,
    ...csHF,
    ...csHAA,
    ...csHCA,
    ...csHCB,
    ...csHCC,
    ...csHCD,
    ...csHEA,
    ...csJA,
    ...csBA,
    ...csCB,
    ...csLA,
    ...csMA,
    ...csGE,
    ...csGDC,
    ...csNA,
    ...csOA,
    ...csDBA,
    ...csDAA,
    ...csQA,
    ...csKA,
    ...csDC,
    ...csMB,
    ...csPA,
    ...csCI,
    ...csToast,
    ...csNotification,
    ...csTimeline,
    ...csCommon,
    ...csOther,
    ...csNoTranslate,
  },
  de: {
    ...deAB,
    ...deAA,
    ...deAC,
    ...deAD,
    ...deBC,
    ...deBD,
    ...deCA,
    ...deEA,
    ...deEB,
    ...deEC,
    ...deFA,
    ...deFB,
    ...deFC,
    ...deFD,
    ...deGB,
    ...deGA,
    ...deGCA,
    ...deGCB,
    ...deGCC,
    ...deGCD,
    ...deGCE,
    ...deGDA,
    ...deGDB,
    ...deGFB,
    ...deGFC,
    ...deGFD,
    ...deGG,
    ...deDB,
    ...deHA,
    ...deHC,
    ...deHF,
    ...deHAA,
    ...deHCA,
    ...deHCB,
    ...deHCC,
    ...deHCD,
    ...deHEA,
    ...deJA,
    ...deBA,
    ...deCB,
    ...deLA,
    ...deMA,
    ...deGE,
    ...deGDC,
    ...deNA,
    ...deOA,
    ...deDBA,
    ...deDAA,
    ...deQA,
    ...deKA,
    ...deDC,
    ...deMB,
    ...dePA,
    ...deCI,
    ...deToast,
    ...deNotification,
    ...deTimeline,
    ...deCommon,
    ...deOther,
    ...deNoTranslate,
  },
  es: {
    ...esAB,
    ...esAA,
    ...esAC,
    ...esAD,
    ...esBC,
    ...esBD,
    ...esCA,
    ...esEA,
    ...esEB,
    ...esEC,
    ...esFA,
    ...esFB,
    ...esFC,
    ...esFD,
    ...esGB,
    ...esGA,
    ...esGCA,
    ...esGCB,
    ...esGCC,
    ...esGCD,
    ...esGCE,
    ...esGDA,
    ...esGDB,
    ...esGFB,
    ...esGFC,
    ...esGFD,
    ...esGG,
    ...esDB,
    ...esHA,
    ...esHC,
    ...esHF,
    ...esHAA,
    ...esHCA,
    ...esHCB,
    ...esHCC,
    ...esHCD,
    ...esHEA,
    ...esJA,
    ...esBA,
    ...esCB,
    ...esLA,
    ...esMA,
    ...esGE,
    ...esGDC,
    ...esNA,
    ...esOA,
    ...esDBA,
    ...esDAA,
    ...esQA,
    ...esKA,
    ...esDC,
    ...esMB,
    ...esPA,
    ...esCI,
    ...esToast,
    ...esNotification,
    ...esTimeline,
    ...esCommon,
    ...esOther,
    ...esNoTranslate,
  },

  fi: {
    ...fiAB,
    ...fiAA,
    ...fiAC,
    ...fiAD,
    ...fiBC,
    ...fiBD,
    ...fiCA,
    ...fiEA,
    ...fiEB,
    ...fiEC,
    ...fiFA,
    ...fiFB,
    ...fiFC,
    ...fiFD,
    ...fiGB,
    ...fiGA,
    ...fiGCA,
    ...fiGCB,
    ...fiGCC,
    ...fiGCD,
    ...fiGCE,
    ...fiGDA,
    ...fiGDB,
    ...fiGFB,
    ...fiGFC,
    ...fiGFD,
    ...fiGG,
    ...fiDB,
    ...fiHA,
    ...fiHC,
    ...fiHF,
    ...fiHAA,
    ...fiHCA,
    ...fiHCB,
    ...fiHCC,
    ...fiHCD,
    ...fiHEA,
    ...fiJA,
    ...fiBA,
    ...fiCB,
    ...fiLA,
    ...fiMA,
    ...fiGE,
    ...fiGDC,
    ...fiNA,
    ...fiOA,
    ...fiDBA,
    ...fiDAA,
    ...fiQA,
    ...fiKA,
    ...fiDC,
    ...fiMB,
    ...fiPA,
    ...fiCI,
    ...fiToast,
    ...fiNotification,
    ...fiTimeline,
    ...fiCommon,
    ...fiOther,
    ...fiNoTranslate,
  },
  fr: {
    ...frAB,
    ...frAA,
    ...frAC,
    ...frAD,
    ...frBC,
    ...frBD,
    ...frCA,
    ...frEA,
    ...frEB,
    ...frEC,
    ...frFA,
    ...frFB,
    ...frFC,
    ...frFD,
    ...frGB,
    ...frGA,
    ...frGCA,
    ...frGCB,
    ...frGCC,
    ...frGCD,
    ...frGCE,
    ...frGDA,
    ...frGDB,
    ...frGFB,
    ...frGFC,
    ...frGFD,
    ...frGG,
    ...frDB,
    ...frHA,
    ...frHC,
    ...frHF,
    ...frHAA,
    ...frHCA,
    ...frHCB,
    ...frHCC,
    ...frHCD,
    ...frHEA,
    ...frJA,
    ...frBA,
    ...frCB,
    ...frLA,
    ...frMA,
    ...frGE,
    ...frGDC,
    ...frNA,
    ...frOA,
    ...frDBA,
    ...frDAA,
    ...frQA,
    ...frKA,
    ...frDC,
    ...frMB,
    ...frPA,
    ...frCI,
    ...frToast,
    ...frNotification,
    ...frTimeline,
    ...frCommon,
    ...frOther,
    ...frNoTranslate,
  },
  hu: {
    ...huAB,
    ...huAA,
    ...huAC,
    ...huAD,
    ...huBC,
    ...huBD,
    ...huCA,
    ...huEA,
    ...huEB,
    ...huEC,
    ...huFA,
    ...huFB,
    ...huFC,
    ...huFD,
    ...huGB,
    ...huGA,
    ...huGCA,
    ...huGCB,
    ...huGCC,
    ...huGCD,
    ...huGCE,
    ...huGDA,
    ...huGDB,
    ...huGFB,
    ...huGFC,
    ...huGFD,
    ...huGG,
    ...huDB,
    ...huHA,
    ...huHC,
    ...huHF,
    ...huHAA,
    ...huHCA,
    ...huHCB,
    ...huHCC,
    ...huHCD,
    ...huHEA,
    ...huJA,
    ...huBA,
    ...huCB,
    ...huLA,
    ...huMA,
    ...huGE,
    ...huGDC,
    ...huNA,
    ...huOA,
    ...huDBA,
    ...huDAA,
    ...huQA,
    ...huKA,
    ...huDC,
    ...huMB,
    ...huPA,
    ...huCI,
    ...huToast,
    ...huNotification,
    ...huTimeline,
    ...huCommon,
    ...huOther,
    ...huNoTranslate,
  },
  it: {
    ...itAB,
    ...itAA,
    ...itAC,
    ...itAD,
    ...itBC,
    ...itBD,
    ...itCA,
    ...itEA,
    ...itEB,
    ...itEC,
    ...itFA,
    ...itFB,
    ...itFC,
    ...itFD,
    ...itGB,
    ...itGA,
    ...itGCA,
    ...itGCB,
    ...itGCC,
    ...itGCD,
    ...itGCE,
    ...itGDA,
    ...itGDB,
    ...itGFB,
    ...itGFC,
    ...itGFD,
    ...itGG,
    ...itDB,
    ...itHA,
    ...itHC,
    ...itHF,
    ...itHAA,
    ...itHCA,
    ...itHCB,
    ...itHCC,
    ...itHCD,
    ...itHEA,
    ...itJA,
    ...itBA,
    ...itCB,
    ...itLA,
    ...itMA,
    ...itGE,
    ...itGDC,
    ...itNA,
    ...itOA,
    ...itDBA,
    ...itDAA,
    ...itQA,
    ...itKA,
    ...itDC,
    ...itMB,
    ...itPA,
    ...itCI,
    ...itToast,
    ...itNotification,
    ...itTimeline,
    ...itCommon,
    ...itOther,
    ...itNoTranslate,
  },
  ja: {
    ...jaAB,
    ...jaAA,
    ...jaAC,
    ...jaAD,
    ...jaBC,
    ...jaBD,
    ...jaCA,
    ...jaEA,
    ...jaEB,
    ...jaEC,
    ...jaFA,
    ...jaFB,
    ...jaFC,
    ...jaFD,
    ...jaGB,
    ...jaGA,
    ...jaGCA,
    ...jaGCB,
    ...jaGCC,
    ...jaGCD,
    ...jaGCE,
    ...jaGDA,
    ...jaGDB,
    ...jaGFB,
    ...jaGFC,
    ...jaGFD,
    ...jaGG,
    ...jaDB,
    ...jaHA,
    ...jaHC,
    ...jaHF,
    ...jaHAA,
    ...jaHCA,
    ...jaHCB,
    ...jaHCC,
    ...jaHCD,
    ...jaHEA,
    ...jaJA,
    ...jaBA,
    ...jaCB,
    ...jaLA,
    ...jaMA,
    ...jaGE,
    ...jaGDC,
    ...jaNA,
    ...jaOA,
    ...jaDBA,
    ...jaDAA,
    ...jaQA,
    ...jaKA,
    ...jaDC,
    ...jaMB,
    ...jaPA,
    ...jaCI,
    ...jaToast,
    ...jaNotification,
    ...jaTimeline,
    ...jaCommon,
    ...jaOther,
    ...jaNoTranslate,
  },
  nl: {
    ...nlAB,
    ...nlAA,
    ...nlAC,
    ...nlAD,
    ...nlBC,
    ...nlBD,
    ...nlCA,
    ...nlEA,
    ...nlEB,
    ...nlEC,
    ...nlFA,
    ...nlFB,
    ...nlFC,
    ...nlFD,
    ...nlGB,
    ...nlGA,
    ...nlGCA,
    ...nlGCB,
    ...nlGCC,
    ...nlGCD,
    ...nlGCE,
    ...nlGDA,
    ...nlGDB,
    ...nlGFB,
    ...nlGFC,
    ...nlGFD,
    ...nlGG,
    ...nlDB,
    ...nlHA,
    ...nlHC,
    ...nlHF,
    ...nlHAA,
    ...nlHCA,
    ...nlHCB,
    ...nlHCC,
    ...nlHCD,
    ...nlHEA,
    ...nlJA,
    ...nlBA,
    ...nlCB,
    ...nlLA,
    ...nlMA,
    ...nlGE,
    ...nlGDC,
    ...nlNA,
    ...nlOA,
    ...nlDBA,
    ...nlDAA,
    ...nlQA,
    ...nlKA,
    ...nlDC,
    ...nlMB,
    ...nlPA,
    ...nlCI,
    ...nlToast,
    ...nlNotification,
    ...nlTimeline,
    ...nlCommon,
    ...nlOther,
    ...nlNoTranslate,
  },
  pl: {
    ...plAB,
    ...plAA,
    ...plAC,
    ...plAD,
    ...plBC,
    ...plBD,
    ...plCA,
    ...plEA,
    ...plEB,
    ...plEC,
    ...plFA,
    ...plFB,
    ...plFC,
    ...plFD,
    ...plGB,
    ...plGA,
    ...plGCA,
    ...plGCB,
    ...plGCC,
    ...plGCD,
    ...plGCE,
    ...plGDA,
    ...plGDB,
    ...plGFB,
    ...plGFC,
    ...plGFD,
    ...plGG,
    ...plDB,
    ...plHA,
    ...plHC,
    ...plHF,
    ...plHAA,
    ...plHCA,
    ...plHCB,
    ...plHCC,
    ...plHCD,
    ...plHEA,
    ...plJA,
    ...plBA,
    ...plCB,
    ...plLA,
    ...plMA,
    ...plGE,
    ...plGDC,
    ...plNA,
    ...plOA,
    ...plDBA,
    ...plDAA,
    ...plQA,
    ...plKA,
    ...plDC,
    ...plMB,
    ...plPA,
    ...plCI,
    ...plToast,
    ...plNotification,
    ...plTimeline,
    ...plCommon,
    ...plOther,
    ...plNoTranslate,
  },
  pt: {
    ...ptAB,
    ...ptAA,
    ...ptAC,
    ...ptAD,
    ...ptBC,
    ...ptBD,
    ...ptCA,
    ...ptEA,
    ...ptEB,
    ...ptEC,
    ...ptFA,
    ...ptFB,
    ...ptFC,
    ...ptFD,
    ...ptGB,
    ...ptGA,
    ...ptGCA,
    ...ptGCB,
    ...ptGCC,
    ...ptGCD,
    ...ptGCE,
    ...ptGDA,
    ...ptGDB,
    ...ptGFB,
    ...ptGFC,
    ...ptGFD,
    ...ptGG,
    ...ptDB,
    ...ptHA,
    ...ptHC,
    ...ptHF,
    ...ptHAA,
    ...ptHCA,
    ...ptHCB,
    ...ptHCC,
    ...ptHCD,
    ...ptHEA,
    ...ptJA,
    ...ptBA,
    ...ptCB,
    ...ptLA,
    ...ptMA,
    ...ptGE,
    ...ptGDC,
    ...ptNA,
    ...ptOA,
    ...ptDBA,
    ...ptDAA,
    ...ptQA,
    ...ptKA,
    ...ptDC,
    ...ptMB,
    ...ptPA,
    ...ptCI,
    ...ptToast,
    ...ptNotification,
    ...ptTimeline,
    ...ptCommon,
    ...ptOther,
    ...ptNoTranslate,
  },
  'pt-BR': {
    ...ptbrAB,
    ...ptbrAA,
    ...ptbrAC,
    ...ptbrAD,
    ...ptbrBC,
    ...ptbrBD,
    ...ptbrCA,
    ...ptbrEA,
    ...ptbrEB,
    ...ptbrEC,
    ...ptbrFA,
    ...ptbrFB,
    ...ptbrFC,
    ...ptbrFD,
    ...ptbrGB,
    ...ptbrGA,
    ...ptbrGCA,
    ...ptbrGCB,
    ...ptbrGCC,
    ...ptbrGCD,
    ...ptbrGCE,
    ...ptbrGDA,
    ...ptbrGDB,
    ...ptbrGFB,
    ...ptbrGFC,
    ...ptbrGFD,
    ...ptbrGG,
    ...ptbrDB,
    ...ptbrHA,
    ...ptbrHC,
    ...ptbrHF,
    ...ptbrHAA,
    ...ptbrHCA,
    ...ptbrHCB,
    ...ptbrHCC,
    ...ptbrHCD,
    ...ptbrHEA,
    ...ptbrJA,
    ...ptbrBA,
    ...ptbrCB,
    ...ptbrLA,
    ...ptbrMA,
    ...ptbrGE,
    ...ptbrGDC,
    ...ptbrNA,
    ...ptbrOA,
    ...ptbrDBA,
    ...ptbrDAA,
    ...ptbrQA,
    ...ptbrKA,
    ...ptbrDC,
    ...ptbrMB,
    ...ptbrPA,
    ...ptbrCI,
    ...ptbrToast,
    ...ptbrNotification,
    ...ptbrTimeline,
    ...ptbrCommon,
    ...ptbrOther,
    ...ptbrNoTranslate,
  },
  ro: {
    ...roAB,
    ...roAA,
    ...roAC,
    ...roAD,
    ...roBC,
    ...roBD,
    ...roCA,
    ...roEA,
    ...roEB,
    ...roEC,
    ...roFA,
    ...roFB,
    ...roFC,
    ...roFD,
    ...roGB,
    ...roGA,
    ...roGCA,
    ...roGCB,
    ...roGCC,
    ...roGCD,
    ...roGCE,
    ...roGDA,
    ...roGDB,
    ...roGFB,
    ...roGFC,
    ...roGFD,
    ...roGG,
    ...roDB,
    ...roHA,
    ...roHC,
    ...roHF,
    ...roHAA,
    ...roHCA,
    ...roHCB,
    ...roHCC,
    ...roHCD,
    ...roHEA,
    ...roJA,
    ...roBA,
    ...roCB,
    ...roLA,
    ...roMA,
    ...roGE,
    ...roGDC,
    ...roNA,
    ...roOA,
    ...roDBA,
    ...roDAA,
    ...roQA,
    ...roKA,
    ...roDC,
    ...roMB,
    ...roPA,
    ...roCI,
    ...roToast,
    ...roNotification,
    ...roTimeline,
    ...roCommon,
    ...roOther,
    ...roNoTranslate,
  },
  sv: {
    ...svAB,
    ...svAA,
    ...svAC,
    ...svAD,
    ...svBC,
    ...svBD,
    ...svCA,
    ...svEA,
    ...svEB,
    ...svEC,
    ...svFA,
    ...svFB,
    ...svFC,
    ...svFD,
    ...svGB,
    ...svGA,
    ...svGCA,
    ...svGCB,
    ...svGCC,
    ...svGCD,
    ...svGCE,
    ...svGDA,
    ...svGDB,
    ...svGFB,
    ...svGFC,
    ...svGFD,
    ...svGG,
    ...svDB,
    ...svHA,
    ...svHC,
    ...svHF,
    ...svHAA,
    ...svHCA,
    ...svHCB,
    ...svHCC,
    ...svHCD,
    ...svHEA,
    ...svJA,
    ...svBA,
    ...svCB,
    ...svLA,
    ...svMA,
    ...svGE,
    ...svGDC,
    ...svNA,
    ...svOA,
    ...svDBA,
    ...svDAA,
    ...svQA,
    ...svKA,
    ...svDC,
    ...svMB,
    ...svPA,
    ...svCI,
    ...svToast,
    ...svNotification,
    ...svTimeline,
    ...svCommon,
    ...svOther,
    ...svNoTranslate,
  },
  zh: {
    ...zhAB,
    ...zhAA,
    ...zhAC,
    ...zhAD,
    ...zhBC,
    ...zhBD,
    ...zhCA,
    ...zhEA,
    ...zhEB,
    ...zhEC,
    ...zhFA,
    ...zhFB,
    ...zhFC,
    ...zhFD,
    ...zhGB,
    ...zhGA,
    ...zhGCA,
    ...zhGCB,
    ...zhGCC,
    ...zhGCD,
    ...zhGCE,
    ...zhGDA,
    ...zhGDB,
    ...zhGFB,
    ...zhGFC,
    ...zhGFD,
    ...zhGG,
    ...zhDB,
    ...zhHA,
    ...zhHC,
    ...zhHF,
    ...zhHAA,
    ...zhHCA,
    ...zhHCB,
    ...zhHCC,
    ...zhHCD,
    ...zhHEA,
    ...zhJA,
    ...zhBA,
    ...zhCB,
    ...zhLA,
    ...zhMA,
    ...zhGE,
    ...zhGDC,
    ...zhNA,
    ...zhOA,
    ...zhDBA,
    ...zhDAA,
    ...zhQA,
    ...zhKA,
    ...zhDC,
    ...zhMB,
    ...zhPA,
    ...zhCI,
    ...zhToast,
    ...zhNotification,
    ...zhTimeline,
    ...zhCommon,
    ...zhOther,
    ...zhNoTranslate,
  },
};
