// original SVG

// <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="white" stroke-opacity="0.2"/>
//   <rect x="12" y="9" width="8" height="14" rx="1" stroke="white" stroke-width="2"/>
// </svg>

// converted to JSX
import * as React from 'react';
import Svg, { Rect, SvgProps } from 'react-native-svg';

interface CarouselViewSVGProps extends SvgProps {
  active?: boolean;
}
const CarouselViewSVG = (props: CarouselViewSVGProps) => {
  return (
    <Svg width="34" height="34" viewBox="-1 -1 35 35" fill="none" {...props}>
      <Rect
        width="32"
        height="32"
        rx={8}
        stroke="white"
        fill={props.active ? 'white' : 'transparent'}
        opacity={props.active ? 0.2 : 1}
        strokeWidth={2.5}
        strokeOpacity={0.2}
      />
      <Rect x="12" y="9" width="8" height="14" rx={1} stroke="white" strokeWidth="2.5" />
    </Svg>
  );
};

export default CarouselViewSVG;
