import { StyleSheet } from 'react-native';
import React from 'react';
import colors from '@styles/colors';
import { SpCenter } from 'src/components/SpCenter';
import { SpText } from '../../../components/SpText/SpText';
import { testProperties } from '@utils/testProperties';

interface IStepsNumCircleProps {
  num: number;
  style?: object;
}

const StepsNumCircle = ({ num, style }: IStepsNumCircleProps) => {
  return (
    <SpCenter
      {...testProperties(`${num}num`, 'label')}
      style={[styles.center, style || null]}>
      <SpText
        size="xxl"
        bold
        align="center"
        color={colors.white.color}>
        {num}
      </SpText>
    </SpCenter>
  );
};

export default StepsNumCircle;

const styles = StyleSheet.create({
  center: {
    height: 44,
    width: 44,
    backgroundColor: colors.greyText.color,
    opacity: 0.6,
    borderRadius: 50,
  },
});
