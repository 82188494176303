// for 6 month and 1 year we need to calculate specific number of days
// we need to jump from current date to make exact days of the year e.g.:
// Jan 13 - Jun 13 difference days are not equal to Jun 20 - Nov 20

import { useMemo } from 'react';
import { ReportRangeType } from '../constants/ReportRangeType';
import { addMonths, differenceInDays } from 'date-fns';

interface DateNavigationNextDaysProps {
  range: ReportRangeType;
  viewedDays: number;
  currentDate: Date;
}

export const useDateNavigationNextDays = ({
  range,
  viewedDays,
  currentDate,
}: DateNavigationNextDaysProps) => {
  return useMemo(() => {
    if (![ReportRangeType.SixMonths, ReportRangeType.OneYear].includes(range)) {
      return viewedDays || 1;
    }

    const numberOfMonthsToAdd = range === ReportRangeType.SixMonths ? 6 : 12;
    const monthsLater = addMonths(currentDate, numberOfMonthsToAdd);
    return differenceInDays(monthsLater.getTime(), currentDate);
  }, [range, viewedDays, currentDate]);
};
