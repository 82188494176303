import { SpInput } from './SpInput';
import { View, StyleSheet, TouchableWithoutFeedback, SafeAreaView } from 'react-native';
import React, { useState, useCallback, useEffect } from 'react';
import { SpRoundedHeaderButton } from './SpRoundedHeaderButton';
import { useTranslation } from 'react-i18next';
import useBoundStore from 'src/store/store';
import { useFormik } from 'formik';
import SpTitle from 'src/components/SpTitle';
import { SpText } from './SpText/SpText';
import colors from '@styles/colors';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import { testProperties } from '@utils/testProperties';
import CustomKeyboardAvoidingView from './CustomKeyboardAvoidingView';
import useValidation from 'src/pages/validation';

interface ResetPasswordFormProps {
  onSubmitSuccess: () => void;
  onSubmitError: () => void;
}

const ResetPasswordForm = ({ onSubmitSuccess, onSubmitError }: ResetPasswordFormProps) => {
  const { emailSchema } = useValidation();
  const { t } = useTranslation();
  const { userLoading, requestPasswordReset } = useBoundStore(state => state.accountStore);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const formik = useFormik({
    validationSchema: emailSchema,
    initialValues: {
      email: '',
    },
    onSubmit: value => onUpdateEmail(value),
    validateOnChange: validateAfterSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const onPress = async () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (formik.submitCount > 0) {
      setValidateAfterSubmit(true);
    }
  }, [formik.submitCount]);

  const onUpdateEmail = useCallback(
    async (value: any) => {
      try {
        await requestPasswordReset(value.email);
        onSubmitSuccess();
      } catch (err) {
        onSubmitError();
      }
    },
    [onSubmitSuccess, onSubmitError],
  );

  return (
    <SafeAreaView
      accessible={false}
      style={styles.container}>
      <CustomKeyboardAvoidingView>
        <TouchableWithoutFeedback
          accessible={false}
          onPress={() => dismissKeyboard()}>
          <View style={styles.inner}>
            <SpTitle
              style={styles.title}
              text={t('forgotten_password')}
            />
            <SpText
              {...testProperties('forgotten password msg', 'text')}
              fontFamily="Rubik"
              style={styles.text}>
              {t('forgotten_password_msg')}
            </SpText>
            <View style={styles.inputWrapper}>
              <SpInput
                value={formik.values.email}
                onChangeText={formik.handleChange('email')}
                label={t('email')}
                placeholder={t('type_here')}
                error={t(formik.errors.email)}
                isLoading={userLoading}
                keyboardType="email-address"
                multilineError
              />
            </View>
            <View style={styles.buttonWrap}>
              <SpRoundedHeaderButton
                backgroundColor={colors.primary.color}
                h={56}
                title={t('reset_password')}
                onPress={onPress}
                isLoading={userLoading}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </CustomKeyboardAvoidingView>
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
  },
  title: {
    color: colors.greyText.color,
    lineHeight: 32,
    fontSize: 24,
  },
  text: {
    lineHeight: 24,
    fontSize: 16,
  },
  inputWrapper: {
    marginTop: 48,
  },
  buttonWrap: {
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 40,
    marginBottom: 34,
  },
  scrollView: {
    flexGrow: 1,
    backgroundColor: colors.white.color,
  },
});
export default ResetPasswordForm;
