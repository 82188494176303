import qs from 'qs';
import Http from './Http';

class InviteApi {
  static readonly httpParams = {};

  static acceptInvite(inviteCode: string) {
    return Http.post(`/api/invite/${inviteCode}/accept`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

}

export default InviteApi;
