import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const AboutSVG = (props: SvgProps) => (
  <Svg
    width={16}
    height={16}
    {...props}>
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 3.556a.889.889 0 1 1 0 1.777.889.889 0 0 1 0-1.777Zm.889 8.888H8a.889.889 0 0 1-.889-.888V8a.889.889 0 1 1 0-1.778H8c.49 0 .889.398.889.89v3.555a.889.889 0 1 1 0 1.777Z"
    />
  </Svg>
);
export default AboutSVG;
