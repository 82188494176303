import { EditMenuItem } from '@models/Menu';
import { getNestedProperty } from '@utils/getNestedProperty';
import React, { useEffect, useState } from 'react';
import { View, FlatList } from 'react-native';
import { ListRenderItemInfo } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { SpDivider } from './SpDivider';
import SpEditMenuItem from './SpEditMenuItem';
import { SpFlatList } from './SpFlatList';
import useBoundStore from '../store/store';

export interface EditMenuStackProps {
  buttons: EditMenuItem[];
  stackType?: string;
  showTitleDivider?: boolean;
}

export const SpEditMenuStack = ({
  buttons,
  stackType = '',
  showTitleDivider = true,
}: EditMenuStackProps) => {
  const { bottom } = useSafeAreaInsets();
  const [pending, setPending] = useState<number>(0);
  const loadingControl = useBoundStore(state => state.deviceStore.loadingControl);
  const loadDevice = useBoundStore(state => state.deviceStore.loadDevice);

  useEffect(() => {
    setPending(old => {
      if (old > 0 && Object.keys(loadingControl).length === 0) {
        loadDevice(true);
      }
      return Object.keys(loadingControl).length;
    });
  }, [loadingControl]);

  return (
    <FlatList
      data={buttons}
      keyExtractor={(item, index) => `${item.label}-${index}`}
      scrollEnabled={buttons.length > 10}
      renderItem={({ item, index }) => {
        return (
          <View>
            {showTitleDivider && <SpDivider />}
            <SpEditMenuItem
              stackType={stackType}
              label={item.label}
              value={item.value}
              action={item.action}
              icon={item?.icon ?? null}
              iconSVG={item?.iconSVG ?? null}
              actionIcon={item?.actionIcon}
              viewOnly={item?.viewOnly}
              disabled={item?.disabled}
              isLoading={
                item?.loadingKey ? getNestedProperty(loadingControl, item.loadingKey) : false
              }
            />
          </View>
        );
      }}
    />
    // <SpFlatList
    //   data={buttons}
    //   showsVerticalScrollIndicator={false}
    //   ListHeaderComponent={showTitleDivider ? <SpDivider /> : null}
    //   contentContainerStyle={{ paddingBottom: bottom }}
    //   keyExtractor={(item, index) => `${item.label}-${index}`}
    //   renderItem={({ item }: ListRenderItemInfo<EditMenuItem>) => (
    //     <SpEditMenuItem
    //       stackType={stackType}
    //       label={item.label}
    //       value={item.value}
    //       action={item.action}
    //       icon={item?.icon ?? null}
    //       iconSVG={item?.iconSVG ?? null}
    //       actionIcon={item?.actionIcon}
    //       viewOnly={item?.viewOnly}
    //       disabled={item?.disabled}
    //       disableOnTagProfilesLoad={item.disableOnTagProfilesLoad}
    //       isLoading={item?.loadingKey ? getNestedProperty(loadingControl, item.loadingKey) : false}
    //     />
    //   )}
    // />
  );
};
