import useToastEffect from '@hooks/useToastEffect';
import useTour from '@hooks/useTour';
import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { DeviceModel } from '@models/Device';
import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SpLoading } from 'src/components/SpLoading';

import { FeederCard } from './FeederDevice/FeederCard';
import { MergedInterfaces } from '../../../../../store/models';
import useBoundStore from '../../../../../store/store';
import ProductsControlSlide from '../../../../Tour/components/ProductsControlSlide/ProductsControlSlide';
import ZeroScalesSlide from '../../../../Tour/components/ZeroScalesSlide/ZeroScalesSlide';

interface FeederDeviceWrapperProps {
  data: DeviceModel;
  deviceId: number;
}

export const FeederDeviceWrapper = ({ data, deviceId }: FeederDeviceWrapperProps) => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const stateIsSet = useRef(false);
  const {
    loadingDevice,
    updateZeroScalesError,
    updateZeroScalesSuccess,
    updateProperty,
    updateZeroScalesLoading,
  } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
      updateZeroScalesError: deviceStore.updateZeroScalesError,
      updateZeroScalesSuccess: deviceStore.updateZeroScalesSuccess,
      updateProperty: deviceStore.updateProperty,
      updateZeroScalesAsync: deviceStore.updateZeroScalesAsync,
      updateZeroScalesLoading: deviceStore.updateZeroScalesLoading,
    };
  });

  const userHasWriteAccess = useUserHasWriteAccess();
  const isFocused = useIsFocused();

  const initTour = useTour({
    uniqueId: 'v0-products-control-feeder',
    components: [<ProductsControlSlide />, <ZeroScalesSlide />],
    devices: ['all'],
    showSharedSlideOneTimeIndex: {
      tourGroup: 'feeder-flap-door',
      slideIndexToHide: 0,
    },
  });
  const resetter = () =>
    updateProperty({
      updateZeroScalesError: null,
      updateZeroScalesSuccess: null,
      loadingDevice: false,
      updateZeroScalesLoading: false,
    });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  useEffect(() => {
    return resetter;
  }, []);

  useEffect(() => {
    if (!updateZeroScalesLoading && !stateIsSet.current) {
      setIsSuccessful(!!updateZeroScalesSuccess);
      setIsError(!!updateZeroScalesError?.error);
      stateIsSet.current = true;
    }
  }, [updateZeroScalesError?.error, updateZeroScalesLoading, updateZeroScalesSuccess]);
  useEffect(() => {
    if (updateZeroScalesLoading) stateIsSet.current = false;
  }, [updateZeroScalesLoading]);

  useToastEffect({
    resetter: () => {
      setIsSuccessful(false);
      setIsError(false);
      stateIsSet.current = false;
      updateProperty({
        loadingDevice: false,
        updateZeroScalesLoading: false,
      });
    },
    successCondition: isSuccessful,
    errorCondition: isError,
    successToastMessage: 'success_zero',
    errorToastMessage: '',
    translate: true,
  });

  if (loadingDevice) {
    return <SpLoading />;
  }

  return (
    <FeederCard
      data={data}
      userHasWriteAccess={userHasWriteAccess}
    />
  );
};
