import React from 'react';
import { SpView } from 'src/components/SpView';
import { SpVStack } from 'src/components/SpVStack';
import { SpMenuStack } from 'src/components/SpMenuStack';
import { EditMenuItem } from '@models/Menu';

interface HouseholdMenuProps {
  menuStack: EditMenuItem[];
}

export const HouseholdMenu = ({ menuStack }: HouseholdMenuProps) => {
  return (
    <SpView>
      <SpVStack space={0}>
        <SpMenuStack buttons={menuStack} />
      </SpVStack>
    </SpView>
  );
};
