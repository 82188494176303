import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ShareSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    {...props}>
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.993 5.376 18.624.007 12 0Zm3.273 19.636H8.727a1.09 1.09 0 1 1 0-2.181h6.546a1.09 1.09 0 1 1 0 2.181Zm1.862-9.047a1.09 1.09 0 0 1-1.543 0l-2.501-2.5v7.184a1.09 1.09 0 0 1-2.182 0V8.088L8.408 10.59a1.09 1.09 0 0 1-1.543-1.542l4.363-4.363a1.095 1.095 0 0 1 1.544 0l4.363 4.363a1.09 1.09 0 0 1 0 1.542Z"
    />
  </Svg>
);
export default ShareSVG;
