import { Image, Platform, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import { useTranslation } from 'react-i18next';
import images from '@styles/images';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import text from '@styles/text';

export const InstructionStep2 = () => {
  const { t } = useTranslation();

  return (
    <SpView
      style={[
        styles.center,
        styles.wrapper,
        Platform.OS === 'android' ? styles.androidPaddingBottom : {},
      ]}>
      <SpText style={[text.title]}>{t('setup_cdb_instruction2_title')}</SpText>
      <SpView style={styles.center}>
        <Image
          style={images.imageAddCerberusStep2}
          source={AppImages.addCerberusSetup2a}
        />
        <SpText style={[text.body]}>{t('setup_cdb_instruction2_description1')}</SpText>
      </SpView>
      <SpView style={styles.center}>
        <Image
          style={images.imageAddCerberusStep2b}
          source={AppImages.addCerberusSetup2b}
        />
        <SpText style={[text.body]}>{t('setup_cdb_instruction2_description2')}</SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  androidPaddingBottom: {
    paddingBottom: 100,
  },
});

export default InstructionStep2;
