import { StyleSheet, View } from 'react-native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { DeviceStackParamList } from '../../Dashboard/Products/Device';

interface NoPortionSetupProps {
  id: number;
  setStep: (step: number) => void;
}

const NoPortionSetup = ({ id, setStep }: NoPortionSetupProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();

  return (
    <>
      <View style={styles.container}>
        <SpTitle
          text={t('no_portions_setup')}
          align="center"
        />
      </View>
      <SpText style={styles.center}>
        {t('if_you_continue_without_having_set_a_portion_weigh')}
      </SpText>
      <SpText style={styles.center}>
        {t('the_feeder_will_still_monitor_the_food_that_your_p')}
      </SpText>
      <SpView style={styles.btnCenter}>
        <SpRoundedHeaderButton
          stylesForContainer={{
            maxWidth: 180,
            marginTop: 40,
          }}
          backgroundColor={colors.greyText.color}
          textStyles={{
            fontSize: 16,
            fontFamily: 'Rubik_Medium',
            color: colors.white.color,
          }}
          onPress={() => {
            setStep(7); // go to portions setup
          }}
          title={t('setup_portions')}
        />
      </SpView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: 10,
  },
  btnCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default NoPortionSetup;
