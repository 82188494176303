import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { SpText } from '../../../../../../components/SpText/SpText';
import text from '@styles/text';
import { SpView } from 'src/components/SpView';
import { testProperties } from '@utils/testProperties';

interface IndoorModeInfoProps {
  continueButtonAction: any;
}
const IndoorModeInfo = ({ continueButtonAction }: IndoorModeInfoProps) => {
  const { t } = useTranslation();
  return (
    <ScrollView
      contentContainerStyle={styles.scrollView}
      keyboardShouldPersistTaps="always"
      keyboardDismissMode="on-drag"
      {...testProperties('indor info', 'scroll view', '', true)}>
      <SpView
        style={styles.modalContainer}
        accessible={false}>
        <SpText
          style={[text.title, styles.titleMargin]}
          {...testProperties('more indoor info title', 'text')}>
          {t('indoor_mode_title')}
        </SpText>
        <SpText
          style={[text.subtitle, styles.subtitleMargin]}
          {...testProperties('more indoor info text1', 'text')}>
          {t('indoor_modal_text1')}
        </SpText>
        <SpText
          style={[text.subtitle, styles.subtitleMargin]}
          {...testProperties('more indoor info text2', 'text')}>
          {t('indoor_modal_text2')}
        </SpText>
        <SpText
          align="center"
          style={styles.reminder}
          {...testProperties('indoor mode reminder', 'text')}>
          {t('indoor_modal_reminder')}
        </SpText>
        <SpRoundedHeaderButton
          title={t('continue')}
          onPress={continueButtonAction}
          backgroundColor={colors.primary.color}
          h={56}
          stylesForContainer={styles.buttonContinueContainer}
        />
      </SpView>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.white.color,
  },
  titleMargin: {
    marginTop: 24,
    marginBottom: 24,
  },
  subtitleMargin: {
    marginBottom: 24,
  },
  buttonText: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    color: colors.white.color,
  },
  buttonStyles: {
    maxHeight: 180,
    marginBottom: 48,
  },
  buttonWrapper: {
    flex: 1,
  },
  modalContainer: {
    paddingHorizontal: 19,
    flex: 1,
  },
  reminder: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
    color: colors.greyText.color,
    paddingHorizontal: 10,
  },
  buttonContinueContainer: { marginTop: 'auto', marginBottom: 68 },
});

export default IndoorModeInfo;
