import colors from '@styles/colors';
import React from 'react';

import LoadingSpinner from '../Loader/Loader';
import { SpCenter } from '../SpCenter';

export const SpLoading = () => (
  <SpCenter style={{ height: '100%', backgroundColor: colors.white.color }}>
    <LoadingSpinner />
  </SpCenter>
);
