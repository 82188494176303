import { SpProfileImage } from 'src/components/SpProfileImage';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import {
  faClose,
  faCaretDown,
  faArrowUp,
  faCircleArrowUp,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { PetModel } from '@models/Pet';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import React, { useMemo } from 'react';

import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import { WINDOW_HEIGHT } from '@gorhom/bottom-sheet';
import { openModal } from 'src/services/ModalBox';

import { DefaultHitSlop } from '@constants/DefaultHitSlop';
import { RootStackParamList } from '../../../../../index';
import { ReportName, ReportType, ReportTypeIcon } from '../../../../constants/ReportType';
import SelectActivityTypeModal from './SelectActivityTypeModal';
import { testProperties } from '@utils/testProperties';
import { AppImages } from '@constants/AppImages';
import GenerateReportSVG from 'src/components/SvgIcons/GenerateReportSVG';
import { useTranslation } from 'react-i18next';

interface PetDashboardReportHeaderProps {
  activePet: PetModel;
  type: ReportType;
  types: ReportType[];
  onChangeType: (data: ReportType) => void;
  onGenerateReport: () => void;
}

export const ReportHeader = ({
  activePet,
  type = null,
  types = [],
  onChangeType,
  onGenerateReport,
}: PetDashboardReportHeaderProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const isMultipleTypes = types.length > 1;
  const reportName = useMemo(() => {
    return (t(ReportName[type]) || '').toUpperCase();
  }, [type]);

  return (
    <SpView style={styles.container}>
      <SelectActivityTypeModal
        types={types}
        type={type}
        onChange={onChangeType}
      />
      <TouchableOpacity
        hitSlop={DefaultHitSlop}
        onPress={() => navigation.goBack()}
        style={styles.backButton}>
        <FontAwesomeIcon
          color={colors.greyText.color}
          size={24}
          icon={faClose}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onGenerateReport}
        style={styles.downloadReportButton}>
        <GenerateReportSVG />
      </TouchableOpacity>
      <SpView>
        <SpProfileImage
          width={48}
          height={48}
          imageUrl={activePet && activePet.photo?.location}
        />
      </SpView>
      <TouchableOpacity
        style={[styles.typeWrap, isMultipleTypes ? styles.typeWrapMultiple : null]}
        activeOpacity={1}
        onPress={() => (isMultipleTypes ? openModal('SelectActivityTypeModal') : null)}>
        <SpView style={styles.icon}>{ReportTypeIcon[type]}</SpView>
        <SpText style={styles.type}>{reportName}</SpText>
        {isMultipleTypes && (
          <FontAwesomeIcon
            style={styles.caretDownIcon}
            size={20}
            icon={faCaretDown}
          />
        )}
      </TouchableOpacity>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: WINDOW_HEIGHT > 700 ? 16 : 0,
    paddingTop: 4,
    backgroundColor: colors.white.color,
  },
  backButton: {
    position: 'absolute',
    left: 20,
    top: 18,
  },
  downloadReportButton: {
    position: 'absolute',
    right: 20,
    top: 16,
  },
  icon: {
    marginRight: 10,
  },
  typeWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: WINDOW_HEIGHT > 700 ? 20 : 0,
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderColor: 'transparent',
    borderWidth: 1,
    borderRadius: 18,
  },
  typeWrapMultiple: {
    borderColor: colors.primary.color,
    backgroundColor: colors.primaryBackgroundOpacity.color,
  },
  typeIcon: {
    marginRight: 10,
  },
  caretDownIcon: {
    marginLeft: 10,
  },
  type: {
    fontWeight: '500',
    letterSpacing: 2,
    color: '#263a43',
    fontFamily: 'Rubik_Medium',
  },
});
