import { StyleSheet, View } from 'react-native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../components/SpText/SpText';

const BeforeStart = () => {
  const { t } = useTranslation();

  return (
    <>
      <View style={styles.container}>
        <SpTitle
          text={t('before_you_start')}
          align="center"
        />
      </View>
      <SpText style={styles.center}>
        {t('changing_these_feeder_settings_will_mean_the_data')}
      </SpText>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginBottom: 40,
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
  },
});

export default BeforeStart;
