import { TagModel } from '@models/Tag';
import { useCallback, useEffect, useRef, useState } from 'react';

type UseControlIncompatibleTagsProps = {
  newTagAdded: TagModel | null;
  setNewTagAdded: (tag: TagModel | null) => void;
  incompatiblePets: any[];
  setIncompatiblePets: (callback: (prevIncompatiblePets: any) => any) => void;
};
const useControlIncompatibleTags = ({
  newTagAdded,
  setNewTagAdded,
  incompatiblePets,
  setIncompatiblePets,
}: UseControlIncompatibleTagsProps) => {
  const [allIncompatiblePetsSorted, setAllIncompatiblePetsSorted] = useState(false);
  const incompatibleIsReconciledRef = useRef<TagModel>(null);
  const updateIncompatiblePets = useCallback(() => {
    if (incompatibleIsReconciledRef.current) {
      setIncompatiblePets(prevIncompatiblePets => {
        const updatedIncompatiblePets = prevIncompatiblePets.filter(
          pet => pet.id !== incompatibleIsReconciledRef.current.id,
        );
        if (updatedIncompatiblePets.length === 0) {
          setAllIncompatiblePetsSorted(true);
        }
        return updatedIncompatiblePets;
      });
    }
  }, [setIncompatiblePets]);
  useEffect(() => {
    if (newTagAdded) {
      incompatibleIsReconciledRef.current = incompatiblePets.find(
        (incompPet: any) => incompPet?.tag_id === newTagAdded?.id,
      );
    }
  }, [incompatiblePets, newTagAdded]);

  useEffect(() => {
    if (newTagAdded) {
      updateIncompatiblePets();
      setNewTagAdded(null);
    }
  }, [newTagAdded, setNewTagAdded, updateIncompatiblePets]);
  return allIncompatiblePetsSorted;
};

export default useControlIncompatibleTags;
