/* eslint-disable react/jsx-no-useless-fragment */
import { SpView } from 'src/components/SpView';
import { useCurrentHub } from '@hooks/useCurrentHub';
import { useDevicesWithoutHubByHousehold } from '@hooks/useDevicesWithoutHubByHousehold';
import { DeviceModel } from '@models/Device';
import {
  NavigationProp,
  StackActions,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import SpDevicesSkeletonLoader from 'src/components/SpDevicesSkeletonLoader';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl, ScrollView, StyleSheet, Dimensions } from 'react-native';
import { SpCenter } from 'src/components/SpCenter';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import atoms from '@styles/atoms';
import colors from '@styles/colors';
import { DeviceType } from '@constants/Device';
import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';
import { RootStackParamList } from '../../index';
import { DeviceHierarchy } from '../components/DeviceHierarchy/DeviceHierarchy';
import EmptyPlaceholder from '../components/EmptyPlaceholder';
import { WebWrapper } from '../../../components/WebWrapper';
import { SpButton } from '../../../components/SpButton';
import { SpText } from '../../../components/SpText/SpText';

export const Products = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const products = useDevicesWithoutHubByHousehold();
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const hubs = useCurrentHub();
  const isFocused = useIsFocused();
  const [initialLoad, setInitialLoad] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const deviceStoreData = useBoundStore(state => ({
    loading: state.deviceStore.loadingDevice,
    load: state.deviceStore.loadDevice,
    getHousehold: state.householdStore.getHouseholds,
  }));
  const timeLineStoreData = useBoundStore((state: MergedInterfaces) => ({
    loading: state.timelineStore.loading,
    load: state.timelineStore.getByHousehold,
    events: state.timelineStore.events,
    reset: state.timelineStore.reset,
  }));
  useEffect(() => {
    if (isFocused) {
      deviceStoreData.load(true);
    }

    return () => {
      timeLineStoreData.reset();
    };
  }, [isFocused]);

  useEffect(() => {
    if (activeHousehold?.id) {
      timeLineStoreData.load(activeHousehold.id);
    }
  }, [activeHousehold]);

  const goToDevice = useCallback(
    (item: DeviceModel) => {
      navigation.dispatch(
        StackActions.push('DeviceNavigation', {
          screen: 'DeviceDetails',
          params: {
            id: item.id,
          },
        }),
      );
    },
    [navigation],
  );

  const cerberusDevices = useMemo(() => {
    return (hubs || []).filter(hub => hub.product_id === DeviceType.Cerberus);
  }, [hubs]);

  const generateProducts = useCallback(
    (hubId: number) => {
      return products.filter(product => product.parent_device_id === hubId);
    },
    [products],
  );

  useEffect(() => {
    const handleScreenSize = () => {
      setIsScrollable(
        Dimensions.get('window').height <
          (products.filter(product => hubs.filter(hub => product.parent_device_id === hub.id))
            .length +
            cerberusDevices.length) *
            225,
      ); // 50 is an arbitrary item height
    };
    handleScreenSize();
    Dimensions.addEventListener('change', handleScreenSize);
  }, [products]);

  if ((deviceStoreData.loading || timeLineStoreData.loading) && initialLoad === false) {
    setInitialLoad(true);
    return <SpDevicesSkeletonLoader />;
  }
  const onRefresh = () => {
    deviceStoreData.load(true);
    deviceStoreData.getHousehold();
  };
  const nav = () => navigation.navigate('FlowNavigation', { screen: 'SelectProduct' });
  return (
    <WebWrapper>
      <>
        {hubs.length === 0 && (
          <SpCenter flex={1}>
            <EmptyPlaceholder isPets={false} />
          </SpCenter>
        )}
        {hubs.length >= 1 && products.length + cerberusDevices.length === 0 && (
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={false}
                onRefresh={onRefresh}
              />
            }
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <SpView
              paddingVertical={16}
              paddingHorizontal={20}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <DeviceHierarchy
                products={generateProducts(hubs[0].id)}
                hub={hubs[0]}
                goToDevice={goToDevice}
              />
              <SpRoundedHeaderButton
                backgroundColor={colors.primary.color}
                title={t('add_product')}
                h={56}
                stylesForContainer={[atoms.maxWidth, { marginVertical: 32 }]}
                onPress={nav}
              />
            </SpView>
          </ScrollView>
        )}
        {hubs.length >= 1 && products.length + cerberusDevices.length > 0 && (
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={false}
                onRefresh={onRefresh}
              />
            }
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <SpView
              paddingVertical={16}
              paddingHorizontal={20}
              paddingBottom={75}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              {hubs.map(hub => (
                <SpView key={hub.id}>
                  <DeviceHierarchy
                    products={generateProducts(hub.id)}
                    hub={hub}
                    goToDevice={goToDevice}
                  />
                </SpView>
              ))}
              {!isScrollable && (
                <SpView style={styles.btnAddProduct}>
                  <SpButton
                    color="lightgrey"
                    textStyle={styles.btnTextAddProduct}
                    title={t('add_product')}
                    onPress={() => navigation.navigate('FlowNavigation')}
                  />
                </SpView>
              )}
            </SpView>
          </ScrollView>
        )}
        <SpView>
          {isScrollable && (
            <SpView style={styles.btnAddStaticProduct}>
              <SpButton
                color="lightgrey"
                textStyle={styles.btnTextAddProduct}
                title={t('add_product')}
                onPress={() => navigation.navigate('FlowNavigation')}
              />
            </SpView>
          )}
        </SpView>
      </>
    </WebWrapper>
  );
};

const styles = StyleSheet.create({
  btnAddProduct: {
    paddingVertical: 30,
    width: '94%',
    marginLeft: '3%',
  },
  btnTextAddProduct: {
    color: colors.greyText.color,
    fontWeight: 'bold',
  },
  btnAddStaticProduct: {
    position: 'absolute',
    bottom: 20,
    width: '94%',
    marginLeft: '3%',
  },
});
