import { Image, StyleSheet } from 'react-native';
import React from 'react';
import { SpView } from 'src/components/SpView';
import { SpText } from '../../../../components/SpText/SpText';
import colors from '@styles/colors';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';

type PromptPage = {
  headerMessage?: {
    txt: string;
    img: any;
  };
  title: string;
  subtitle: string;
  subtitle2?: string;
  buttonTitle?: string;
  buttonAction?: () => void;
  additionalPadding?: number;
  showButton?: boolean;
};

const PromptPage = ({
  headerMessage = null,
  title,
  subtitle,
  subtitle2,
  buttonTitle,
  buttonAction,
  additionalPadding = null,
  showButton = true,
}: PromptPage) => {
  return (
    <SpView style={[styles.container, additionalPadding && { paddingTop: additionalPadding }]}>
      {headerMessage && (
        <SpView style={styles.headerMessage}>
          {headerMessage.img && (
            <Image
              source={headerMessage.img}
              style={[styles.itemHeader, styles.imgHeader]}
            />
          )}
          {headerMessage.txt && (
            <SpText style={[styles.itemHeader, styles.txtHeader]}>{headerMessage.txt}</SpText>
          )}
        </SpView>
      )}
      <SpText style={styles.title}>{title}</SpText>
      <SpText style={styles.subtitle}>{subtitle}</SpText>
      {subtitle2 && <SpText style={styles.subtitle}>{subtitle2}</SpText>}
      {showButton && (
        <SpView style={{ alignItems: 'center' }}>
          <SpRoundedHeaderButton
            stylesForContainer={{
              maxWidth: 180,
            }}
            backgroundColor={colors.greyText.color}
            textStyles={{
              fontSize: 16,
              fontFamily: 'Rubik_Medium',
              color: colors.white.color,
            }}
            title={buttonTitle}
            onPress={buttonAction}
          />
        </SpView>
      )}
    </SpView>
  );
};

export default PromptPage;

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 24,
    color: colors.greyText.color,
    marginBottom: 24,
    textAlign: 'center',
    lineHeight: 32,
  },
  subtitle: {
    fontFamily: 'Rubik',
    color: colors.black.color,
    marginBottom: 24,
    textAlign: 'center',
    paddingHorizontal: 26,
  },
  container: {
    paddingTop: 24,
  },
  headerMessage: {
    borderStyle: 'solid',
    borderColor: colors.greyBorder.color,
    borderRadius: 10,
    borderWidth: 2,
    flexDirection: 'row',
    padding: 10,
    paddingLeft: 20,
    marginBottom: 30,
  },
  itemHeader: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  txtHeader: {
    fontWeight: 'bold',
    paddingTop: 18,
    paddingLeft: 20,
    fontFamily: 'Rubik_SemiBold',
    fontSize: 16,
    color: colors.greyText.color,
    textAlign: 'center',
    lineHeight: 20,
  },
  imgHeader: {
    width: 50,
    height: 50,
  },
});
