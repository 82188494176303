import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { SetupInstruction, SetupInstructionItem } from '../components/SetupInstruction';

export const PlaceInLocation = () => {
  const { t } = useTranslation();

  const checkedIcon = useMemo(() => {
    return (
      <SpView
        accessible
        {...testProperties('faCheck', 'icon')}
        style={styles.circle}>
        <FontAwesomeIcon
          color="white"
          size={18}
          icon={faCheck}
        />
      </SpView>
    );
  }, []);

  const items: SetupInstructionItem[] = useMemo(() => {
    return [
      {
        icon: checkedIcon,
        title: t('setup_felaqua_connect_instruction_3'),
      },
      {
        icon: checkedIcon,
        title: t('setup_felaqua_connect_instruction_4'),
      },
    ];
  }, [checkedIcon]);

  return (
    <SpView style={styles.container}>
      <SpText
        style={styles.title}
        size="xxl">
        {t('setup_felaqua_connect_instruction_1')}
      </SpText>
      <SpText
        style={styles.description}
        size="md">
        {t('setup_felaqua_connect_instruction_2')}
      </SpText>
      <SetupInstruction items={items} />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
  },
  title: {
    color: colors.black.color,
    textAlign: 'center',
    fontFamily: 'Rubik_SemiBold',
    marginTop: 16,
  },
  description: {
    color: colors.black.color,
    textAlign: 'center',
    marginBottom: 32,
    marginTop: 24,
  },
  circle: {
    height: 32,
    width: 32,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary.color,
    borderRadius: 50,
  },
});
