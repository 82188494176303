import { useTranslation } from 'react-i18next';
import GraphLinesMock from '../GraphLinesMock/GraphLinesMock';
import mockLine from '../GraphLinesMock/mockLineData';
import { AppImages } from '@constants/AppImages';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../../components/SpText/SpText';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import colors from '@styles/colors';
import TourSwipeSVG from 'src/components/SvgIcons/TourSwipeSVG';
import { ScrollView } from 'react-native-gesture-handler';

const AmountEatenGraphsSlide = () => {
  const { t } = useTranslation();
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <GraphLinesMock
        mockLine={mockLine}
        iconSource={AppImages.eatenIcon}
      />
      <SpTitle
        align="center"
        text={t('food_consumption_report')}
      />
      <SpText style={styles.contentText}>{t('food_consumption_report_tour_text')}</SpText>
      <View style={styles.iconWrapper}>
        <TourSwipeSVG />
      </View>
      <SpText style={[styles.contentText, styles.blueText]}>
        {t('food_consumption_report_tour_text2')}
      </SpText>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  blueText: {
    color: '#03A2B1',
    fontSize: 14,
    lineHeight: 20,
    marginTop: 12,
    fontFamily: 'Rubik_SemiBold',
  },
});

export default AmountEatenGraphsSlide;
