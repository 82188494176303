import { SpView } from '../../../../../../../components/SpView';
import { SpText } from '../../../../../../../components/SpText/SpText';
import React, { StyleSheet, TouchableOpacity } from 'react-native';
import { TimeService } from '../../../../../../../services/TimeService';
import { DayMonthFormat } from '@constants/DateFormat';
import { ConsumptionInsight } from '@models/ConsumptionInsight';
import { useConsumptionInsightConfig } from '../../../../../components/ConsumptionHabit/hooks/useConsumptionHabitConfig.hook';
import ChevronRightSVG from '../../../../../../../components/SvgIcons/ChevronRightSVG';

interface InsightAlertShortProps {
  insight: ConsumptionInsight;
  isAlert?: boolean;
  onSelect: (date: string) => void;
  fromDetailsAlert?: boolean;
}

const InsightAlertShort = ({ insight, isAlert = false, onSelect, fromDetailsAlert = false }: InsightAlertShortProps) => {
  const config = useConsumptionInsightConfig(
    insight.outcome,
    isAlert,
    insight.n_days,
    insight.last_drinking_event_utc,
    fromDetailsAlert,
  );

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => onSelect(insight.last_drinking_event_utc)}
    >
      <SpView
        style={styles.alert}
        flexDirection="row"
        alignItems="center"
      >
        <SpText style={styles.date}>
          {TimeService.toLocal(insight.last_drinking_event_utc).toFormat(DayMonthFormat)}
        </SpText>
        <SpView style={styles.icon}>{config.icon}</SpView>
        <SpView style={styles.text}>
          <SpText fontFamily="Rubik_Medium">{config.title}</SpText>
        </SpView>
        <SpView>
          <ChevronRightSVG
            width={20}
            height={20}
            viewBox="0 0 20 20"
          />
        </SpView>
      </SpView>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  alert: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 14,
    borderColor: 'rgba(38, 58, 67, 0.4)',
    paddingHorizontal: 20,
    paddingVertical: 20,
    marginTop: 24,
  },
  date: {
    minWidth: 50,
    flexShrink: 0,
    marginRight: 20,
  },
  icon: {
    marginRight: 12,
  },
  text: {
    flex: 1,
  },
});

export default InsightAlertShort;
