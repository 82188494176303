import { useTranslation } from 'react-i18next';
import { SpView } from 'src/components/SpView';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../../components/SpText/SpText';
import { StyleSheet, View } from 'react-native';
import colors from '@styles/colors';
import React from 'react';
import MockLinechart from '../MockLinechart/MockLinechart';
import mockLinechartsData from './mockLinechartsData';
import TourSwipeSVG from 'src/components/SvgIcons/TourSwipeSVG';

const FeedingVisitsSlide = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <SpView style={styles.linechartsWrapper}>
        <MockLinechart
          type="circle"
          items={mockLinechartsData}
        />
      </SpView>
      <SpTitle
        align="center"
        text={t('feeding_visits')}
      />
      <SpText style={styles.contentText}>{t('feeding_visits_tour_text')}</SpText>
      <View style={styles.iconWrapper}>
        <TourSwipeSVG />
      </View>
      <SpText style={[styles.contentText, styles.blueText]}>
        {t('feeding_visits_tour_text2')}
      </SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  linechartsWrapper: {
    paddingHorizontal: 19,
    marginTop: 32,
    marginBottom: 12,
  },
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  blueText: {
    color: '#03A2B1',
    fontSize: 14,
    lineHeight: 20,
    marginTop: 12,
    fontFamily: 'Rubik_SemiBold',
  },
});

export default FeedingVisitsSlide;
