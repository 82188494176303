import { StackScreenProps } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpView } from 'src/components/SpView';
import { StyleSheet } from 'react-native';
import { SpText } from '../../../../../../components/SpText/SpText';
import colors from '@styles/colors';
import { DeviceStackParamList } from '../../index';

export const MultiplePet = ({ route }: StackScreenProps<DeviceStackParamList, 'MultiplePet'>) => {
  const { t } = useTranslation();

  return (
    <SpView style={[styles.container]}>
      <SpText style={styles.title}>{t('more_pet_title')}</SpText>
      <SpText style={styles.subtitle}>{t('more_pet_subtitle')}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 24,
    color: colors.greyText.color,
    marginBottom: 24,
    textAlign: 'center',
    lineHeight: 32,
  },
  subtitle: {
    fontFamily: 'Rubik',
    color: colors.black.color,
    marginBottom: 24,
    textAlign: 'center',
    paddingHorizontal: 26,
  },
  container: {
    paddingTop: 24,
    backgroundColor: colors.white.color,
    height: '100%',
  },
  headerMessage: {
    borderStyle: 'solid',
    borderColor: colors.greyBorder.color,
    borderRadius: 10,
    borderWidth: 2,
    flexDirection: 'row',
    padding: 10,
    paddingLeft: 20,
    marginBottom: 30,
  },
  itemHeader: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  txtHeader: {
    fontWeight: 'bold',
    paddingTop: 18,
    paddingLeft: 20,
    fontFamily: 'Rubik_SemiBold',
    fontSize: 16,
    color: colors.greyText.color,
    textAlign: 'center',
    lineHeight: 20,
  },
  imgHeader: {
    width: 50,
    height: 50,
  },
});
