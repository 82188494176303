import { HouseholdInviteModel } from '@models/HouseholdInvite';
import qs from 'qs';
import Http from './Http';

class HouseholdInviteApi {
  static readonly httpParams = {};



  static sendHouseholdInvite(
    householdId: number,
    invite: HouseholdInviteModel,
  ): Promise<HouseholdInviteModel[]> {
    return Http.post(`/api/household/${householdId}/invite`, invite, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static resendHouseholdInvite(householdId: number, inviteId: number) {
    return Http.post(`/api/household/${householdId}/invite/${inviteId}/resend`, undefined, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }



  static updateHouseholdInvite(
    householdId: number,
    inviteId: number,
    invite: HouseholdInviteModel,
  ): Promise<HouseholdInviteModel[]> {
    return Http.put(`/api/household/${householdId}/invite/${inviteId}`, invite, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static deleteHouseholdInvite(
    householdId: number,
    userId: number,
  ): Promise<HouseholdInviteModel[]> {
    return Http.delete(`/api/household/${householdId}/invite/${userId}`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default HouseholdInviteApi;
