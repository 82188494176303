import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export const useTimeAMPMTransform = (): {
  getAMPMLabelFromDate: (data: Date | number) => string;
  timeToAMPM: (data: string) => string;
} => {
  const { t } = useTranslation();
  const getAMPMLabelFromDate = (date: Date | number): string => {
    const dateTime =
      typeof date === 'number' ? DateTime.fromMillis(date) : DateTime.fromJSDate(date);
    return dateTime.hour < 12 ? t('TIME_AM_UNIT') : t('TIME_PM_UNIT');
  };

  const zeroPad = (num: number): string => {
    if (num >= 0) {
      return (num < 10 ? '0' : '') + num;
    }

    return num.toString();
  };

  const timeToAMPM = (time: string): string => {
    const translation = `(${t('TIME_AM_UNIT')}|${t('TIME_PM_UNIT')})`;
    const ampmRegex = new RegExp(translation, 'i');
    let result = '';

    if (time) {
      if (time.match(ampmRegex)) {
        result = time;
      } else {
        const timeParts = time.trim().split(':').map(parseFloat);
        switch (timeParts[0]) {
          case 0:
            result = `12:${zeroPad(timeParts[1])} ${t('TIME_AM_UNIT')}`;
            break;
          case 12:
            result = `${time} ${t('TIME_PM_UNIT')}`;
            break;
          default:
            // eslint-disable-next-line max-len
            result =
              timeParts[0] < 12
                ? `${time} ${t('TIME_AM_UNIT')}`
                : `${zeroPad(timeParts[0] - 12)}:${zeroPad(timeParts[1])} ${t('TIME_PM_UNIT')}`;
            break;
        }
      }
    }

    return result;
  };

  return {
    getAMPMLabelFromDate,
    timeToAMPM,
  };
};
