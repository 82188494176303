import { differenceInDays, isValid } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { TimeService } from 'src/services/TimeService';
import { MergedInterfaces } from '../store/models';
import useBoundStore from '../store/store';
import { RemoteConfigKeys } from '@constants/RemoteConfig';
import { SpRemoteConfig } from '../services/RemoteConfigService';

const MAX_DAYS_TO_EXPIRE = 30;

export const useReconfirmTermsAndConditions = (appReady: boolean = false) => {
  const user = useBoundStore(s => s.accountStore.user);
  const { allowTemporaryViewAppBasedOnTerms } = useBoundStore(
    (state: MergedInterfaces) => state.UIStore,
  );
  const [isTermsChanged, setIsTermsChanged] = useState(null);
  const [daysToExpire, setDaysToExpire] = useState(null);

  const redirectOnLoginForNewTerms = useCallback(()=>{
    const userTermsAccepted = user?.terms_accepted;
    const termsAndConditionsUpdated = SpRemoteConfig().getString(RemoteConfigKeys.newTermsDate);

    if (userTermsAccepted && termsAndConditionsUpdated && isValid(new Date(termsAndConditionsUpdated))) {
      const acceptedDate = TimeService.convertOrUnconvertDateByUTC<number>(
        +new Date(userTermsAccepted),
        true,
      );
      const termsChangeDate = TimeService.convertOrUnconvertDateByUTC<number>(+new Date(termsAndConditionsUpdated), true);
      const leftDays =
        MAX_DAYS_TO_EXPIRE -
        Math.abs(
          differenceInDays(
            TimeService.convertOrUnconvertDateByUTC<number>(Date.now(), true),
            termsChangeDate,
          ),
        );
      console.log('leftDays', leftDays);
      const daysFullyExpired = leftDays <= 0;

      // User approved
      if (acceptedDate > termsChangeDate) {
        console.log('TERMS - User approved terms after last update', userTermsAccepted, termsChangeDate);

        setIsTermsChanged(false);
        setDaysToExpire(null);
        return false;
      }

      // User can short-term use app
      if (allowTemporaryViewAppBasedOnTerms ===  leftDays && !daysFullyExpired) {
        console.log('TERMS - User deferred terms update');

        setIsTermsChanged(false);
        setDaysToExpire(null);
        return false;
        // return { isTermsChanged: false, daysToExpire: null, setDate };
      }

      // Fully expired
      if (daysFullyExpired) {
        console.log('TERMS - No days left to use app');

        setIsTermsChanged(true);
        setDaysToExpire(leftDays);
        return true;
        // return { isTermsChanged: true, daysToExpire: leftDays, setDate };
      }
      console.log('TERMS - User hasnt deferred terms update, and it hasnt expired');

      setIsTermsChanged(true);
      setDaysToExpire(leftDays);
      return true;

    }else{
      console.log('TERMS - Error - user', userTermsAccepted);
      console.log('TERMS - Error - termsAndConditionsUpdated', termsAndConditionsUpdated);
      return true;

    }

  }, [user, allowTemporaryViewAppBasedOnTerms, setIsTermsChanged, setDaysToExpire,])

  useEffect(() => {
  redirectOnLoginForNewTerms()
}, [user, allowTemporaryViewAppBasedOnTerms, appReady, redirectOnLoginForNewTerms]);

  return { isTermsChanged, daysToExpire, redirectOnLoginForNewTerms };

};
