import { TimelineEventModel } from '@models/Timeline';
import { TimelinePetPhoto } from './TimelinePetPhoto';
import { TimelineCard } from './TimelineCard';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumptionAlertOutcome } from '@models/ConsumptionInsight';

interface Props {
  event: TimelineEventModel;
  active: boolean;
}

export const WCITimelineAlert = ({ event, active }: Props) => {
  const { t } = useTranslation();

  const TRANSLATIONS = {
    [ConsumptionAlertOutcome.Ok]: t('timeline_wci_alert_normal'),
    [ConsumptionAlertOutcome.Problematic]: t('timeline_wci_alert_problematic'),
    [ConsumptionAlertOutcome.StillProblematic]: t('timeline_wci_alert_still_problematic'),
  };

  const text = useMemo(() => {
    const [alert] = event?.consumption_alert || [];
    return TRANSLATIONS[alert?.outcome] || '';
  }, [event?.consumption_alert]);

  return (
    <TimelineCard
      active={active}
      image={
        <TimelinePetPhoto
          active={active}
          event={event}
          alert
        />
      }
      text={text}
      date={event.created_at}
    />
  );
};
