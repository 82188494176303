import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { useDeviceId } from '@hooks/useDeviceId';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import useBoundStore from 'src/store/store';
import colors from 'src/styles/colors';
import { useTranslation } from 'react-i18next';
import PasswordForm from 'src/pages/OnboardingLetsStart/Register/forms/PasswordForm';
import { testProperties } from '@utils/testProperties';
import useValidation from 'src/pages/validation';
import CustomKeyboardAvoidingView from 'src/components/CustomKeyboardAvoidingView';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import { SpInput } from 'src/components/SpInput';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useReturnHandler } from '@hooks/useReturnHandler';
import { CallTypes } from '@constants/Calls';
import { RootStackParamList } from '../index';

type Props = NativeStackScreenProps<RootStackParamList, 'ResetPassword'>;

const ResetPassword = ({ route }: Props) => {
  const { user, loginError, userLoading, resetPassword } = useBoundStore(
    state => state.accountStore,
  );
  const deviceId = useDeviceId();
  const token = route?.params?.token;
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const handler = useReturnHandler('password_reset');
  const { emailSchema, passwordStepSchema } = useValidation();
  const { t } = useTranslation();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const formik = useFormik({
    validationSchema: passwordStepSchema,
    initialValues: {
      currentPassword: '',
      password: '',
      retypePassword: '',
    },
    onSubmit: values => {
      resetPassword(email, values.password, token, deviceId);
    },
    validateOnChange: validateAfterSubmit,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const emailFormik = useFormik({
    validationSchema: emailSchema,
    initialValues: {
      email: '',
      retypeEmail: '',
    },
    onSubmit: () => {},
    validateOnBlur: false,
    enableReinitialize: true,
  });
  const emailFieldError = useMemo(() => {
    return emailFormik.errors.email;
  }, [emailFormik]);
  const email = emailFormik.values?.email || '';

  const onSubmit = useCallback(async () => {
    formik.handleSubmit();
    emailFormik.handleSubmit();
  }, []);

  useEffect(() => {
    if (user) {
      rootNavigation.reset({
        index: 0,
        routes: [{ name: 'DashboardNavigation' }],
      });
    }
  }, [user]);

  useEffect(() => {
    if (loginError) {
      handler(CallTypes.updating);
    }
  }, [handler, userLoading, loginError]);

  const insets = useSafeAreaInsets();
  return (
    <CustomKeyboardAvoidingView
      androidAdjustType="custom"
      keyboardShouldPersistTaps="always"
      extraScrollHeight={Platform.OS === 'ios' ? 75 : 200}>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        keyboardShouldPersistTaps="always"
        keyboardDismissMode="on-drag">
        <TouchableWithoutFeedback
          accessible={false}
          onPress={() => dismissKeyboard()}>
          <View style={[styles.inner, { paddingBottom: insets.bottom }]}>
            <Text
              {...testProperties(t('reset_password'), 'title text')}
              style={styles.title}>
              {t('reset_password')}
            </Text>
            <SpInput
              label={t('email')}
              value={emailFormik.values?.email || ''}
              onChangeText={text => {
                emailFormik.handleChange('email')(text);
                if (text)
                  emailFormik.setTouched({
                    ...emailFormik.touched,
                    email: true,
                  });
              }}
              keyboardType="email-address"
              error={t(emailFieldError)}
              returnKeyType="done"
              multilineError
            />
            <View style={styles.passwordFormWrapper}>
              <PasswordForm
                formik={formik}
                label={t('new_password')}
              />
            </View>
            <View style={styles.buttonWrap}>
              <SpRoundedHeaderButton
                {...testProperties(t('save'), t('button'), 'AccountPasswordWrapper')}
                backgroundColor={colors.primary.color}
                h={56}
                title={t('save')}
                onPress={onSubmit}
                isLoading={userLoading}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    </CustomKeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: colors.white.color },
  scrollView: {
    flexGrow: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    paddingHorizontal: 19,
    flex: 1,
  },
  title: {
    color: colors.greyText.color,
    fontFamily: 'Rubik_SemiBold',
    fontSize: 24,
    paddingVertical: 24,
  },
  buttonWrap: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 34,
    shadowColor: colors.greyText.color,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8.95,
    elevation: 18,
  },
  passwordFormWrapper: {
    marginTop: 40,
  },
});

export default ResetPassword;
