import AuthApi from '@api/AuthApi';
import { FormikProps } from 'formik';

interface checkPasswordValidityObj {
  formik: FormikProps<{ email: string }>;
  setError: (arg: string) => void;
}

export const checkEmailValidity = async (
  deviceId: string,
  { formik, setError }: checkPasswordValidityObj,
) => {
  try {
    await AuthApi.register({
      email_address: formik?.values.email,
      device_id: deviceId,
    });
  } catch (e) {
    if (e && e.data.error.email_address?.includes('The email address has already been taken.')) {
      setError('email_address_been_taken');
      return false;
    }

    return true;
  }
  return null;
};
