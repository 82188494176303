import React from 'react';
import { SpHouseIcon } from '../components/RoundIcons/SpHouseIcon';
import { SpTreesIcon } from '../components/RoundIcons/SpTreesIcon';

export const PetLocationIcons = {
  0: ({ size, iconSize, action }: { size?: number; iconSize?: number; action?: any }) => <></>,
  1: ({ size, iconSize, action }: { size?: number; iconSize?: number; action?: any }) => (
    <SpHouseIcon
      size={size}
      iconSize={iconSize}
      action={action}
    />
  ),
  2: ({ size, iconSize, action }: { size?: number; iconSize?: number; action?: any }) => (
    <SpTreesIcon
      size={size}
      iconSize={iconSize}
      action={action}
    />
  ),
};
