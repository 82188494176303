import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { PetStatisticsModel } from '@models/Device';
import PetInfoSection from './PetInfoSection';
import { AdaptiveSize } from '../PetCard';
import useTotalConsumption from '../../../../hooks/useTotalConsumption';

type PetWalkingProps = {
  data: PetStatisticsModel['movement'];
  disablePadding?: boolean;
  adaptiveSize: AdaptiveSize;
};

const PetWalking = ({ data, disablePadding, adaptiveSize }: PetWalkingProps) => {
  const { t } = useTranslation();

  const onlySeconds = useMemo(() => {
    if (typeof data?.time_outside === 'string') {
      const chunks = data.time_outside.split(':').map(item => +item);
      return chunks.length === 3 ? !chunks[0] && !chunks[1] && chunks[2] : undefined;
    }
    return undefined;
  }, [data.time_outside]);

  const time = useTotalConsumption(onlySeconds || data.time_outside);

  return (
    <PetInfoSection
      icon={faClock}
      fontSizeForNumbers={adaptiveSize?.fontSizeForNumbers || 24}
      fontSizeForText={adaptiveSize?.fontSizeForText || 16}
      title={t('time_outside')}
      data={time}
      disablePadding={disablePadding}
      type="time"
    />
  );
};

export default PetWalking;
