import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import SpModal from 'src/components/SpModal';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';

export const PetsPreviewModal = () => {
  const { t } = useTranslation();
  return (
    <SpModal
      backdropColor="transparent"
      modalName="PetsPreview"
      autoClose>
      <SpView style={styles.innerBox}>
        <SpText style={styles.text}>{t('in_progress_warning')}</SpText>
      </SpView>
    </SpModal>
  );
};

const styles = StyleSheet.create({
  innerBox: {
    marginTop: '75%',
    borderRadius: 10,
    backgroundColor: colors.greyText.color,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.9,
    shadowRadius: 15.84,
    elevation: 5,
    width: '90%',
  },
  text: {
    borderRadius: 30,
    color: 'white',
    textAlign: 'center',
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
});
