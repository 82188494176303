import CustomBottomSheetModal from 'src/components/CustomBottomSheetModal';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { useUserHasAdminAccess } from '@hooks/useUserHasAdminAccess';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';
import { SpView } from 'src/components/SpView';
import { SpVStack } from 'src/components/SpVStack';
import { useTranslation } from 'react-i18next';
import PreferencesSVG from 'src/components/SvgIcons/PreferencesSVG';
import colors from '@styles/colors';
import { DeviceEventEmitter, StyleSheet } from 'react-native';
import { SpText } from '../../components/SpText/SpText';
import text from '@styles/text';
import { ScrollView } from 'react-native-gesture-handler';
import { SpAddButton } from '../../components/SpAddButton/SpAddButton';
import { format } from 'date-fns';
import { TimeService } from 'src/services/TimeService';
import { useDevicesWithoutHubByHousehold } from '@hooks/useDevicesWithoutHubByHousehold';
import useTour from '@hooks/useTour';
import { EditMenuItem } from '@models/Menu';
import CustomHeader from 'src/components/CustomHeader';
import PersonalSVG from 'src/components/SvgIcons/PersonalSVG';
import useAreProductsPresent from '@hooks/useAreProductsPresent';
import useBoundStore from '../../store/store';
import { HouseholdList } from './components/HouseholdList';
import { HouseholdMenu } from './components/HouseholdMenu';
import { HouseholdSelector } from './components/HouseholdSelector';
import { HouseholdStackParamList } from './index';
import ChooseSetup from './ChooseSetup';
import { RootStackParamList } from '../index';
import { HouseholdHeader } from './components/HouseholdHeader';
import HouseholdSlide from '../Tour/components/HouseholdSlide/HouseholdSlide';
import MyHouseholdSlide from '../Tour/components/MyHouseholdSlide/MyHouseholdSlide';
import { useRefreshAnalyticsData } from '@hooks/useRefreshAnalyticsData';
import { WebWrapper } from '../../components/WebWrapper';

export const Household = () => {
  const { t } = useTranslation();
  const [open, setOpenModal] = useState<boolean>(false);
  const snapPoints = useMemo(() => ['40%'], []);
  const navigation = useNavigation<NavigationProp<HouseholdStackParamList>>();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const userHasAdminAccess = useUserHasAdminAccess();
  const {
    isHouseholdActive,
    setActiveHousehold,
    activeHousehold,
    isHouseholdOwned,
    loadDevice,
    ownedHouseholds,
    joinedHouseholds,
  } = useBoundStore(({ householdStore, deviceStore }) => {
    return {
      isHouseholdActive: householdStore.isHouseholdActive,
      setActiveHousehold: householdStore.setActiveHousehold,
      activeHousehold: householdStore.activeHousehold,
      isHouseholdOwned: householdStore.isHouseholdOwned,
      ownedHouseholds: householdStore.ownedHouseholdsData,
      joinedHouseholds: householdStore.joinedHouseholdsData,
      userHasWriteAccess: householdStore.userHasWriteAccess(),
      loadDevice: deviceStore.loadDevice,
    };
  });

  const allowAnalyticsRefresh = useRefreshAnalyticsData();

  const isFocused = useIsFocused();
  const devices = useDevicesWithoutHubByHousehold();

  const uniqueDevice = devices.length === 1 ? devices[0] : null;

  const initTour = useTour({
    uniqueId: 'v0-household',
    components: [<HouseholdSlide />, <MyHouseholdSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
    loadDevice(true);
  }, [isFocused]);

  const inviteDate = useMemo(() => {
    const myInviteDate = activeHousehold?.created_at;
    if (!myInviteDate) {
      return null;
    }
    const timezonedDate = TimeService.convertOrUnconvertDateByUTC(new Date(myInviteDate), true);
    return format(timezonedDate, 'dd MMM yyyy') || null;
  }, [activeHousehold]);

  const onDismiss = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const onOpenSideBar = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const navigateTo = (target: keyof HouseholdStackParamList) => {
    navigation.navigate(target);
  };

  const menuStack = useMemo(() => {
    const result: EditMenuItem[] = [
      {
        label: t('settings'),
        iconSVG: (
          <PreferencesSVG
            color={colors.greyText.color}
            width={20}
            height={20}
            viewBox="0 0 16 16"
          />
        ),
        action: () => navigateTo('HouseholdSettings'),
      },
    ];

    if (userHasAdminAccess) {
      result.push({
        label: t('users'),
        iconSVG: (
          <PersonalSVG color={colors.greyText.color} width={20} height={20} viewBox="0 0 16 16" />
        ),
        action: () => navigateTo('HouseholdUsers'),
      });
      // No integrations yet - Sam 11/07/23
      // result.push({
      //   label: t('integrations'),
      //   iconSVG: <NotificationFilledSVG color={colors.greyText.color} />,
      //   action: () => alert('Integrations TBD'),
      // });
    }

    return result;
  }, [userHasAdminAccess]);
  const areProductsPresent = useAreProductsPresent();
  const isPlaceholder = !userHasAdminAccess && inviteDate;
  const stepForPets = useMemo(() => {
    if (!areProductsPresent) return 0;
    if (uniqueDevice) return 2;
    return 1;
  }, [uniqueDevice, areProductsPresent]);
  const handleAddPet = useCallback(() => {
    rootNavigation.navigate('FlowNavigation', {
      screen: 'AddPets',
      params: {
        deviceType: uniqueDevice ? uniqueDevice.product_id : null,
        step: stepForPets,
        deviceId: uniqueDevice ? uniqueDevice.id : null,
        existingDevice: true,
      },
    });
  }, [rootNavigation, stepForPets, uniqueDevice]);
  return activeHousehold ? (
    <ScrollView style={styles.background}>
      <SpView style={{ backgroundColor: '#fff' }}>
        <HouseholdHeader name="HouseholdHeader">
          <HouseholdSelector householdName={activeHousehold?.name} action={onOpenSideBar} />
        </HouseholdHeader>
        <WebWrapper>
          <SpVStack
            space={20}
            paddingTop={70}
            paddingHorizontal={20}
            style={{ zIndex: -1 }}
          >
            {!isPlaceholder && <HouseholdMenu menuStack={menuStack} />}
            {userHasAdminAccess && (
              <>
                <SpAddButton
                  title={t('add_pet')}
                  onPress={handleAddPet}
                  style={{ marginBottom: 30 }}
                />
                <SpAddButton
                  title={t('add_product')}
                  onPress={() => rootNavigation.navigate('FlowNavigation')}
                  backgroundColor={{ backgroundColor: 'rgba(3,162,177, 0.25)' }}
                  plusColor={{ backgroundColor: colors.primary.color }}
                />
              </>
            )}

            {isPlaceholder && (
              <SpText style={styles.textWrapper}>
                <SpText
                  style={[
                    text.label,
                    { color: colors.greySmallTitle.color, fontSize: 16, textAlign: 'center' },
                  ]}
                >
                  {t('invited_to_this_household_on{{date}}', {
                    date: inviteDate,
                  })}
                </SpText>
              </SpText>
            )}
          </SpVStack>

          {open && (
            <CustomBottomSheetModal
              opened={open}
              backdropComponent={BottomSheetBackdrop}
              index={0}
              snapPoints={snapPoints}
              onDismiss={onDismiss}
            >
              <HouseholdList
                navigationRef={navigateTo}
                isHouseholdActive={isHouseholdActive}
                setActiveHousehold={newActiveHousehold => {
                  setActiveHousehold(newActiveHousehold);
                  allowAnalyticsRefresh();
                  DeviceEventEmitter.emit('refreshAnalyticsData');
                  onDismiss();
                  rootNavigation.navigate('DashboardNavigation', {
                    screen: 'Pets',
                  });
                }}
                joinedHouseholds={joinedHouseholds}
                ownedHouseholds={ownedHouseholds}
                isHouseholdOwned={isHouseholdOwned}
                closeBottomSheetModal={onDismiss}
              />
            </CustomBottomSheetModal>
          )}
        </WebWrapper>
      </SpView>
    </ScrollView>
  ) : (
    <>
      <CustomHeader withTitle={false} />
      <ChooseSetup />
    </>
  );
};
const styles = StyleSheet.create({
  background: {
    backgroundColor: colors.white.color,
  },
  container: {
    width: '100%',
    paddingTop: 20,
  },
  textWrapper: {
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  block: {
    height: 40,
    backgroundColor: colors.white.color,
  },
});
