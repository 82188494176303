import { TimelineEventModel } from '@models/Timeline';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import { TimelinePetPhoto } from './TimelinePetPhoto';

export const NewTag = ({ event, active }: { event: TimelineEventModel; active: boolean }) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);

  const text = useMemo(() => {
    const [pet] = event?.pets || [];

    if (pet?.name) {
      const translation = 'timeline_named_pet_assigned_product';
      return t(translation, {
        petname: event.pets[0].name,
        devicename: deviceName,
      });
    }

    const translation = (event?.users || []).length
      ? 'timeline_new_tag_pet'
      : 'timeline_new_tag_product';
    return t(translation, {
      collartag: event.tags && event.tags[0].tag,
      devicename: deviceName,
    });
  }, [event, deviceName, t]);

  return (
    <TimelineCard
      active={active}
      image={
        <TimelinePetPhoto
          active={active}
          event={event}
        />
      }
      text={text}
      date={event.created_at}
    />
  );
};
