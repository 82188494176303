import { SpLoading } from 'src/components/SpLoading';
import { SpView } from 'src/components/SpView';
import { DeviceType } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { RefreshControl, SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { DeviceModel } from '@models/Device';
import useBoundStore from '../../../../store/store';
import DeviceApi from '@api/DeviceApi';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import { CerberusWrapper } from './components/CerberusWrapper';
import { DrinkingDeviceWrapper } from './components/DrinkingDeviceWrapper';
import { FeederDeviceWrapper } from './components/FeederDeviceWrapper';
import { HubDeviceWrapper } from './components/HubDeviceWrapper';
import { PetDoorCatFlapDeviceWrapper } from './components/PetDoorCatFlapDeviceWrapper';
import { DeviceStackParamList } from './index';
import { isEqual } from 'lodash-es';
import { WebWrapper } from '../../../../components/WebWrapper';

export const DeviceDetails = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceDetails'>) => {
  const { id } = route.params;
  const [refreshControlLoading, setRefreshControlLoading] = useState(false);
  const device: DeviceModel = useDeviceById(id);
  const deviceStoreData = useBoundStore(state => ({
    loading: state.deviceStore.loadingDevice,
    load: state.deviceStore.loadDevice,
    updateDevice: state.deviceStore.updateDevice,
    getHousehold: state.householdStore.getHouseholds,

    loadingControl: state.deviceStore.loadingControl,
  }));
  const { activeHousehold, updateDevice, loadingControl } = useBoundStore(s => ({
    activeHousehold: s.householdStore.activeHousehold,
    loadingControl: s.deviceStore.loadingControl,
    updateDevice: s.deviceStore.updateDevice,
  }));
  const loadingControlPendingItems = useRef(0);
  const productId = device?.product_id;
  useDeviceStatusHook(id, true);

  useLayoutEffect(() => {
    const currentPending = Object.keys(loadingControl ?? {}).length;
    const fetchControl = async () => {
      return DeviceApi.getDevices(activeHousehold?.id).then(devices => {
        const currentDevice = devices.filter(e => e.id === id)[0];
        if (!isEqual(currentDevice, device)) {
          updateDevice(currentDevice);
        }
      });
    };
    if (currentPending < loadingControlPendingItems.current) {
      fetchControl();
    }
    loadingControlPendingItems.current = currentPending;
  }, [activeHousehold?.id, device, id, loadingControl, updateDevice]);

  useEffect(() => {
    if (productId === DeviceType.Hub) {
      return;
    }
    DeviceApi.enableFastPolling(id);
    console.log('enable fast polling', id);
  }, [id, productId]);
  const onRefresh = () => {
    setRefreshControlLoading(true);
    deviceStoreData.getHousehold();
    deviceStoreData.load(true);
  };

  const deviceSection = useMemo(() => {
    if (refreshControlLoading) {
      return null;
    }

    if (productId === DeviceType.PetDoorConnect || productId === DeviceType.CatFlapConnect) {
      return (
        <PetDoorCatFlapDeviceWrapper
          deviceId={id}
          data={device}
        />
      );
    }

    if (productId === DeviceType.FeederConnect) {
      return (
        <FeederDeviceWrapper
          deviceId={id}
          data={device}
        />
      );
    }

    if (productId === DeviceType.Cerberus) {
      return (
        <CerberusWrapper
          deviceId={id}
          data={device}
        />
      );
    }

    if (productId === DeviceType.FelaquaConnect) {
      return (
        <DrinkingDeviceWrapper
          deviceId={id}
          data={device}
        />
      );
    }

    if (productId === DeviceType.Hub) {
      return (
        <HubDeviceWrapper
          deviceId={id}
          data={device}
        />
      );
    }

    return <SpLoading />;
  }, [refreshControlLoading, productId, id, device]);

  useEffect(() => {
    if (!deviceStoreData.loading) {
      setRefreshControlLoading(false);
    }
  }, [deviceStoreData.loading]);

  return (
    <SafeAreaView style={styles.fullHeight}>
      <ScrollView
        contentContainerStyle={styles.fullHeight}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={onRefresh}
          />
        }
      >
        <WebWrapper>
          <SpView
            paddingHorizontal={18}
            flex={1}
          >
            {deviceSection}
          </SpView>
        </WebWrapper>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  fullHeight: {
    flexGrow: 1,
    backgroundColor: colors.white.color,
  },
});
