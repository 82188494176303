import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { DeviceModel } from '@models/Device';
import React, { useCallback, useEffect } from 'react';

import { CerberusCard } from './CerberusDevice/CerberusCard';
import LoadingSpinner from '../../../../../components/Loader/Loader';
import { MergedInterfaces } from '../../../../../store/models';
import useBoundStore from '../../../../../store/store';

interface CerberusWrapperProps {
  data: DeviceModel;
  deviceId: number;
}

export const CerberusWrapper = ({ data, deviceId }: CerberusWrapperProps) => {
  const { loadingDevice, updateProperty } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
      updateProperty: deviceStore.updateProperty,
    };
  });

  const userHasWriteAccess = useUserHasWriteAccess();

  const clearStoreProperties = useCallback(() => {
    updateProperty({
      loadingDevice: false,
    });
  }, [updateProperty]);

  useEffect(() => {
    clearStoreProperties();
  }, []);

  useEffect(() => {
    return () => clearStoreProperties();
  }, []);

  if (loadingDevice) {
    return <LoadingSpinner />;
  }

  return (
    <CerberusCard
      data={data}
      userHasWriteAccess={userHasWriteAccess}
    />
  );
};
