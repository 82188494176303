import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { MappedReportModel, ReportChangeViewDayPayload } from '@models/ReportModel';
import colors from '@styles/colors';
import { differenceInDays } from 'date-fns';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, ScrollView, StyleSheet } from 'react-native';
import { SpText } from '../../../../../../components/SpText/SpText';
import SpToggleButton from 'src/components/SpToggleButton';
import { SpView } from 'src/components/SpView';
import DateNavigation from 'src/pages/Dashboard/components/DateNavigation';
import { TimeService } from 'src/services/TimeService';

import AvgSatatisticsContainer from './AvgSatatisticsContainer';
import DrinkingAndFeedingStatistics from './DrinkingAndFeedingStatistics';
import { DrinkingGraph } from './DrinkingGraph';
import { FeedingGraph } from './FeedingGraph';
import { MovementGraph } from './MovementGraph';
import StatsViewedDaysModal from './StatsViewedDaysModal';
import LoadingSpinner from '../../../../../../components/Loader/Loader';
import { openModal } from '../../../../../../services/ModalBox';
import useBoundStore from '../../../../../../store/store';
import {
  ReportRangeLastName,
  ReportRangeType,
  ReportRangeTypeName,
} from '../../../../constants/ReportRangeType';
import { ReportType } from '../../../../constants/ReportType';
import { useGroupedRangeReportStats } from '../../../../hooks/useGroupedRangeReportStats';
import {
  ConsumptionInsight,
  ConsumptionInsightByDay,
  ConsumptionInsightWithType,
} from '@models/ConsumptionInsight';
import ConsumptionModalBottomSheet from '../../../../components/ConsumptionHabit/components/ConsumptionModalBottomSheet';
import InsightsAlertStatus from './InsightAlertStatus';
import InsightNotification from '../../../../components/ConsumptionHabit';
import { SafeAreaView } from 'react-native-safe-area-context';
import { getAverageValue } from '@utils/getAverageValue';
import { useStatusVisible } from 'src/pages/Dashboard/hooks/useStatusVisible';

interface SummaryTabProps {
  stats: MappedReportModel;
  pet: PetModel;
  type: ReportType;
  devices: DeviceModel[];
  dataType: string;
  rangeType: ReportRangeType;
  currentDate: number;
  selectedDayInsight: ConsumptionInsightWithType;
  onChangeViewedDays: (value: ReportChangeViewDayPayload) => void;
}

export const SummaryTab = ({
  stats,
  pet,
  type,
  devices,
  dataType,
  selectedDayInsight,
  rangeType,
  currentDate,
  onChangeViewedDays,
}: SummaryTabProps) => {
  const screenHeight = Dimensions.get('window').height;
  const { t } = useTranslation();
  const ref = useRef(null);
  const { loading } = useBoundStore(s => s.reportStore);
  const { petInsights } = useBoundStore(s => s.petStore);
  const [insight, setInsight] = useState(null);
  const [insightReport, setInsightReport] = useState<Record<number, ConsumptionInsightByDay>>(null);
  const [rangeButtonActive, setRangeButtonActive] = useState(false);
  const [compareMode, setCompareMode] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [average, setAverage] = useState(null);
  const rangeDates = useGroupedRangeReportStats(currentDate, rangeType);
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const hasInsightsAlerts = useStatusVisible(insightReport);
  const isDrinking = type === ReportType.Drinking;
  const dateNavigationData = {
    name: t(ReportRangeLastName[rangeType], {
      value: rangeType === 180 ? 6 : rangeType,
    }),
    viewedDays: differenceInDays(rangeDates.finish, rangeDates.start),
  };

  const onOpenInsight = (insight: ConsumptionInsight) => {
    setInsight(insight);
  };

  const chartsRender = useMemo(() => {
    if (compareMode) {
      return <SpText>TODO</SpText>;
    }

    if (loading) {
      return (
        <SpView style={styles.spinner}>
          <LoadingSpinner />
        </SpView>
      );
    }

    if (isDrinking) {
      return (
        <DrinkingGraph
          rangeType={rangeType}
          stats={stats}
          pet={pet}
          currentDate={currentDate}
          petInsights={petInsights}
          devices={devices}
          onSetCurrentInsights={setInsightReport}
          onSetAverage={val => setAverage(val)}
          onOpenInsight={onOpenInsight}
        />
      );
    }

    if (type === ReportType.Feeding) {
      return (
        <FeedingGraph
          rangeType={rangeType}
          stats={stats}
          pet={pet}
          currentDate={currentDate}
          devices={devices}
          onSetAverage={val => setAverage(val)}
        />
      );
    }

    if (type === ReportType.Movement) {
      return (
        <MovementGraph
          rangeType={rangeType}
          stats={stats}
          currentDate={currentDate}
          onSetAverage={val => setAverage(val)}
        />
      );
    }

    return null;
  }, [rangeType, stats, currentDate, devices, compareMode, type, loading]);

  const averageValue = useMemo((): string => {
    return getAverageValue(average, type);
  }, [type, average]);

  return (
    <SafeAreaView
      edges={[]}
      style={{ flex: 1 }}
    >
      <ScrollView contentContainerStyle={[styles.container, loading && { flexGrow: 1 }]}>
        <StatsViewedDaysModal
          topOffset={topOffset}
          type={rangeType}
          onChange={value => {
            onChangeViewedDays({ days: value });
            setRangeButtonActive(false);
          }}
          onClose={() => setRangeButtonActive(false)}
        />
        <SpView
          paddingHorizontal={19}
          marginTop={screenHeight < 810 ? 6 : 16}
          marginBottom={!isDrinking || (isDrinking && !hasInsightsAlerts) ? 8 : 0}
        >
          <DateNavigation
            title={dateNavigationData.name}
            currentDate={TimeService.toLocal(currentDate).toJSDate()}
            setDate={date => onChangeViewedDays({ date })}
            viewedDays={dateNavigationData.viewedDays}
            range={rangeType}
          />
        </SpView>
        {isDrinking && hasInsightsAlerts && !loading && (
          <SpView style={styles.alertWrap}>
            <InsightsAlertStatus
              data={insightReport}
              onSelectAlert={date => {
                onChangeViewedDays({
                  days: 1,
                  date: TimeService.toLocal(date).endOf('day').toMillis(),
                });
              }}
            />
          </SpView>
        )}

        <SpView style={styles.chartWrap}>{chartsRender}</SpView>

        <SpView style={styles.footerButtonWrap}>
          <SpView
            width={135}
            marginBottom={screenHeight < 700 ? -16 : 0}
          >
            <SpToggleButton
              ref={ref}
              onPress={() => {
                // @ts-ignore
                ref.current.measure((x, y, width, height, pageX, pageY) => {
                  setTopOffset(Platform.OS === 'android' ? y + pageY + 36 : y + pageY - 12);
                  openModal('StatsViewedDaysModal');
                  setRangeButtonActive(true);
                });
              }}
              text={t(ReportRangeTypeName[rangeType], {
                value: rangeType === 180 ? 6 : rangeType === 365 ? 1 : rangeType,
              })}
              withIcon
              width="100%"
              isActive={rangeButtonActive}
            />
          </SpView>
          {/* // Disabled until available */}
          {/*<SpToggleButton*/}
          {/*  onPress={() => setCompareMode(!compareMode)}*/}
          {/*  text={t('compare')}*/}
          {/*  width="45%"*/}
          {/*  isActive={compareMode}*/}
          {/*/>*/}
        </SpView>

        {type === ReportType.Movement && (
          <AvgSatatisticsContainer
            type="single"
            data={averageValue}
            fromReport
            rangeType={rangeType}
          />
        )}
        {((isDrinking && !loading && rangeType !== ReportRangeType.OneDay) ||
          (isDrinking && !selectedDayInsight?.insight)) && (
          <DrinkingAndFeedingStatistics
            type="single"
            data={convertWithUnits(Number(averageValue || 0))}
            dataType={dataType}
            rangeType={rangeType}
          />
        )}
        {type === ReportType.Feeding && (
          <DrinkingAndFeedingStatistics
            type="single"
            data={convertWithUnits(Number(averageValue || 0), true)}
            dataType={dataType}
            rangeType={rangeType}
          />
        )}

        {!!selectedDayInsight?.insight && isDrinking && (
          <SpView style={styles.insight}>
            <InsightNotification
              pet={pet}
              petId={pet?.id}
              withoutButton
              insight={selectedDayInsight.insight}
              isAlert={selectedDayInsight.isAlert}
              fromSummaryTab
            />
          </SpView>
        )}

        {/* // Disabled until available */}

        {/* <AmountHorizontalGraphs */}
        {/*   intervals={intervals} */}
        {/*   amounts={amounts} */}
        {/*   dataType={dataType} */}
        {/* /> */}
        {!!insight && (
          <ConsumptionModalBottomSheet
            pet={pet}
            opened={!!insight}
            insight={insight}
            isAlert={insight.isAlert}
            fromReport
            withDataHeader
            withoutFullDescription
            onViewGraph={(date: string) => {
              onChangeViewedDays({
                days: 1,
                date: TimeService.toLocal(date).endOf('day').toMillis(),
              });
            }}
            onDismiss={() => setInsight(null)}
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white.color,
  },
  content: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  chartWrap: {
    flex: 1,
  },
  footerButtonWrap: {
    flexDirection: 'row',
    marginHorizontal: 33,
    marginTop: 24,
    justifyContent: 'center',
  },
  alertWrap: {
    zIndex: 1000,
    marginBottom: -64,
    marginTop: 4,
  },
  spinner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
  },
  insight: {
    marginHorizontal: 24,
    marginVertical: 32,
  },
});
