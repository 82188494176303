import SpBadge from 'src/components/RoundIcons/SpBadge';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { ConditionModel } from '@models/Condition';
import { PetModel } from '@models/Pet';
import { SpEditMenuStack } from 'src/components/SpEditMenuStack';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet } from 'react-native';
import { useUserHasAdminAccess } from '@hooks/useUserHasAdminAccess';
import { PetStackParamList } from '../..';

interface PetProfileMedicalProps {
  conditions: ConditionModel[];
  pet: PetModel;
}

export const PetProfileMedical = ({ conditions, pet }: PetProfileMedicalProps) => {
  const navigation = useNavigation<NavigationProp<PetStackParamList>>();
  const { t } = useTranslation();
  const isAdmin = useUserHasAdminAccess();

  const settings = useMemo(() => {
    const sortedConditions: ConditionModel[] = [];
    pet.conditions.forEach(({ id }) => {
      const condition = conditions.find(item => item.id === id);
      sortedConditions.push(condition);
    });
    return [
      {
        viewOnly: !isAdmin,
        label: t('conditions'),
        value: pet.conditions.length ? (
          <FlatList
            data={sortedConditions.sort((a, b) => a.name.localeCompare(b.name))}
            renderItem={({ item }) => (
              <SpView
                style={styles.selectedItemsBadge}
                key={item.id}>
                <SpBadge
                  key={item.id}
                  text={item.name}
                  width="auto"
                />
              </SpView>
            )}
            contentContainerStyle={styles.selectedItemsBadges}
          />
        ) : (
          <SpText style={styles.noConditions}>{t('no_conditions')}</SpText>
        ),
        action: () => {
          navigation.navigate('PetProfileMedicalSelectConditions', {
            id: pet.id,
          });
        },
      },
    ];
  }, [pet, conditions, isAdmin]);

  return (
    <SpView style={styles.container}>
      <SpEditMenuStack buttons={settings} />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    marginTop: 20,
  },
  selectedItemsBadges: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 6,
  },
  selectedItemsBadge: {
    marginTop: 4,
    marginRight: 4,
  },
  noConditions: {
    color: colors.greyText.color,
    fontSize: 18,
    marginTop: 6,
    lineHeight: 24,
    fontFamily: 'Rubik',
  },
});
