import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';

const CalendarDayComponent = ({ date, state, marking, onPress }) => {
  const selected = marking?.selected;
  const today = state === 'today';

  const warningStyles = useMemo(() => {
    return !!marking && !marking.selected && !today ? styles.warningDate : {};
  }, [marking, today]);

  return (
    <TouchableOpacity
      accessible={false}
      onPress={() => onPress(date.timestamp)}
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      <View
        {...testProperties(
          `d:${date.day}-m:${date.month}${today ? 'Today' : 'notToday'}`,
          `${selected ? 'Selected' : 'NotSelected'}`,
          'CalendarModalDay',
        )}
        style={[
          styles.dayElem,
          warningStyles,
          {
            backgroundColor: selected
              ? marking?.selectedColor
              : today
                ? 'rgba(255,255,255, 0.3);'
                : null,
          },
        ]}
      >
        <Text
          style={[
            styles.dayText,
            { color: state === 'disabled' ? 'rgba(255,255,255, 0.2);' : colors.white.color },
          ]}
        >
          {date.day}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  dayElem: {
    borderRadius: 100,
    width: 36,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayText: {
    fontSize: 14,
    fontFamily: 'Rubik',
  },
  warningDate: {
    borderColor: colors.orangeWarning.color,
    borderWidth: 1,
  },
});

export default CalendarDayComponent;
