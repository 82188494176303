/* eslint-disable react/destructuring-assignment */
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import React, { memo, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import textStyles from '@styles/text';
import { useTranslation } from 'react-i18next';
import atomStyles from '@styles/atoms';
import colors from '@styles/colors';
import { ReportRangeType } from 'src/pages/Dashboard/constants/ReportRangeType';

type SingleAvgProps = {
  type: 'single';
  data: string;
  fromReport?: boolean;
  rangeType?: ReportRangeType;
};

type DoubleAvgProps = {
  type: 'double';
  visits: number;
  length: string;
  fromReport?: boolean;
  rangeType?: ReportRangeType;
};

type CustomStatisticsProps = {
  type: 'multi';
  leftLabel: string;
  leftValue: React.ReactElement | string;
  rightValue: React.ReactElement | string;
  rightLabel: string;
  fromReport?: boolean;
  rangeType?: ReportRangeType;
};

type Props = SingleAvgProps | DoubleAvgProps | CustomStatisticsProps;

const AvgSatatisticsContainer: React.FC<Props> = props => {
  const { t } = useTranslation();
  const leftLabelElement = useMemo(() => {
    const value = (() => {
      switch (props.type) {
        case 'double':
          return props.visits;
        case 'multi':
          return props.leftValue;
        case 'single':
          return props.data;
      }
    })();
    if (typeof value === 'string') {
      return (
        <SpText
          style={[textStyles.title, styles.mainDataText, { fontSize: 28 }]}
          numberOfLines={1}
        >
          {value}
        </SpText>
      );
    }
    return value;
  }, [props]);

  const rightLabelElement = useMemo(() => {
    const value = (() => {
      switch (props.type) {
        case 'double':
          return props.length;
        case 'multi':
          return props.rightValue;
        case 'single':
          return props.data;
      }
    })();

    if (typeof value === 'string') {
      return (
        <SpText
          style={[textStyles.title, styles.mainDataText, { fontSize: 28 }]}
          numberOfLines={1}
        >
          {value}
        </SpText>
      );
    }
    return value;
  }, [props]);

  return (
    <SpView style={props.fromReport ? styles.ReportAvgContainer : styles.mainContainer}>
      {props.type !== 'single' ? (
        <SpView style={[atomStyles.rowContainer, styles.innerContainer]}>
          <SpView style={[atomStyles.statusCardContainer, styles.blockContainer]}>
            <SpText
              style={styles.title}
              numberOfLines={1}
            >
              {props.type === 'double' ? `${t('average_short')} ${t('visits')}` : props.leftLabel}
            </SpText>
            {leftLabelElement}
          </SpView>
          <SpView
            style={[atomStyles.statusCardContainer, styles.blockContainer, styles.spaceBetween]}
          >
            <SpText
              style={[styles.title]}
              numberOfLines={1}
            >
              {props.type === 'double' ? `${t('average_short')} ${t('length')}` : props.rightLabel}
            </SpText>
            {rightLabelElement}
          </SpView>
        </SpView>
      ) : (
        <SpView
          style={
            props.fromReport
              ? styles.AvgStatistics
              : [atomStyles.statusCardContainer, styles.smallContainer]
          }
        >
          <SpText style={props.fromReport ? textStyles.defaultText : styles.title}>
            {props.fromReport ? t('days_avg', { days: props.rangeType }) : t('average_short')}
          </SpText>
          <SpText style={[textStyles.title, styles.mainDataText]}>{props.data}</SpText>
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  mainDataText: {
    marginLeft: 0,
    lineHeight: undefined,
    marginTop: 8,
  },
  mainContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallContainer: {
    minWidth: '50%',
    flex: undefined,
    paddingHorizontal: 5,
  },
  ReportAvgContainer: {
    marginTop: 8,
    paddingVertical: 24,
  },
  AvgStatistics: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontSize: 16,
    lineHeight: 20,
    textAlign: 'center',
    color: colors.greySmallTitle.color,
    marginBottom: 4,
  },
  blockContainer: {
    paddingHorizontal: 15,
  },
  innerContainer: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default memo(AvgSatatisticsContainer);
