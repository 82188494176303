import { TimelineEventModel } from '@models/Timeline';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import TimelineDevicePhoto from './TimelineDevicePhoto';

export const PoseidonLowWater = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);

  const text = useMemo(() => {
    return t('poseidon_low_water', {
      devicename: deviceName,
    });
  }, [deviceName, t]);

  return (
    <TimelineCard
      active={active}
      text={text}
      date={event.created_at}
      image={
        <TimelineDevicePhoto
          active={active}
          event={event}
        />
      }
    />
  );
};
