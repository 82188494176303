import { usePetDevices } from '@hooks/usePetDevices';
import { useMemo } from 'react';

import useBoundStore from '../../../store/store';

export const useCheckPending = (pet: any) => {
  const { devicesIdsRequestsTrack, loadingControl } = useBoundStore((s) => s.deviceStore);
  const devices = usePetDevices(pet);
  const petDevicesIds = useMemo(() => {
    return devices.map((item) => item.id);
  }, [devices, pet]);

  const pendingChecking = useMemo(() => {
    if (devicesIdsRequestsTrack && !Object?.values(devicesIdsRequestsTrack)?.length) return false;
    if (petDevicesIds.length > 1) return false;
    for (const id in devicesIdsRequestsTrack) {
      if (devicesIdsRequestsTrack[id]?.length) {
        const res = devicesIdsRequestsTrack[id].find((elem) => elem === pet.tag_id);
        if (res) return true;
      }
    }
    return false;
  }, [devicesIdsRequestsTrack, loadingControl]);

  return { pendingChecking }; // { pendingChecking: boolean }
};
