import { SpView } from 'src/components/SpView';
import { DeviceType } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { DeviceCard } from './DeviceCard';
import { DeviceIndicators } from './DeviceIndicators';
import { HubCard } from './HubCard';

interface DeviceHierarchyProps {
  products: DeviceModel[];
  hub: DeviceModel;
  goToDevice: (data: DeviceModel) => void;
}

export const DeviceHierarchy = ({ products, hub, goToDevice }: DeviceHierarchyProps) => {
  const isConnectionLineShown = products.length > 0 && hub.product_id === DeviceType.Hub;
  return (
    <SpView>
      <TouchableOpacity
        style={
          hub.product_id === DeviceType.Cerberus ? styles.deviceWrapper : null
        }
        accessible={false}
        onPress={() => goToDevice(hub)}
      >
        {hub.product_id === DeviceType.Hub ? (
          <SpView>
            <HubCard data={hub} />
            {isConnectionLineShown && (
              <SpView style={[styles.verticalLine, styles.hubVerticalLine]} />
            )}
          </SpView>
        ) : (
          <>
            <SpView style={styles.device}>
              <DeviceCard data={hub} />
            </SpView>
            <SpView style={styles.indicators}>
              <DeviceIndicators data={hub} />
            </SpView>
          </>
        )}
      </TouchableOpacity>

      {hub.product_id === DeviceType.Hub
        ? products.map((item, index) => {
            return (
              <SpView
                style={styles.deviceSection}
                key={item.id}>
                {index ? <SpView style={styles.verticalLine} /> : null}
                <TouchableOpacity
                  style={styles.deviceWrapper}
                  accessible={false}
                  onPress={() => goToDevice(item)}>
                  <SpView style={styles.device}>
                    <DeviceCard data={item} />
                  </SpView>
                  <SpView style={styles.indicators}>
                    <DeviceIndicators data={item} />
                  </SpView>
                </TouchableOpacity>
              </SpView>
            );
          })
        : null}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  hubWrapper: {
    flex: 1,
    flexDirection: 'row',
  },
  deviceSection: {
    flex: 1,
    flexDirection: 'column',
  },
  deviceWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  verticalLine: {
    height: 16,
    width: 4,
    marginLeft: 40,
    backgroundColor: '#263a43',
  },
  hubVerticalLine: {
    height: 32,
  },
  device: {
    width: '86%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  indicators: {
    width: '14%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingVertical: 4,
  },
});
