import { MappedConsumptionStats, PetGroupedStatistic } from '@models/ReportModel';
import SpModal from 'src/components/SpModal';
import React from 'react';
import { useLineGraphTrendPoints } from '../../../../hooks/useLineGraphTrendPoints';
import { useLineGraphTrendLabels } from '../../../../hooks/useLineGraphTrendLabels';
import { LineGraphModal } from 'src/pages/Dashboard/components/LineGraphModal';

interface LineGraphModalWrapperProps {
  modal: string;
  data: PetGroupedStatistic<{ totalActivity: number }>;
}

export const LineGraphModalWrapper = ({ modal, data }: LineGraphModalWrapperProps) => {
  const weekPoints = useLineGraphTrendPoints(data?.currentWeek || [], data?.previousWeek || []);
  const monthPoints = useLineGraphTrendPoints(data?.currentMonth || [], data?.previousMonth || []);
  const { monthThisLabels, weekThisLabels, weekLastLabels, monthLastLabels } =
    useLineGraphTrendLabels(data);

  return (
    <SpModal modalName={modal}>
      <LineGraphModal
        weekThisLabels={weekThisLabels}
        weekThisData={weekPoints.current}
        weekLastLabels={weekLastLabels}
        weekLastData={weekPoints.last}
        monthThisLabels={monthThisLabels}
        monthThisData={monthPoints.current}
        monthLastLabels={monthLastLabels}
        monthLastData={monthPoints.last}
      />
    </SpModal>
  );
};
