import DeviceApi from '@api/DeviceApi';
import { DeviceType } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import * as Network from 'expo-network';
import Toast from 'react-native-toast-message';
import i18n from '@utils/i18n';
import { AxiosError } from 'axios';

type ISearchDeviceServiceProps = {
  productId: DeviceType;
  onError?: (props: any) => void;
  onFound?: (props: any) => void;
  maxRetriesHandler?: (state: boolean) => void;
  onSuccess?: (props: any) => void;
  devices: DeviceModel[];
  loadingEnd?: () => void;
  loadingStart?: () => void;
  activeHouseholdId: number;
  maxRetries?: number;
};

export default class SearchDeviceService {
  timer: any = undefined;

  productId: DeviceType;

  parentDevice: DeviceModel;

  retries = 0;

  maxRetries = 40;

  retryEvery = 3000;

  errorHandler?: (props: any) => void;

  foundHandler?: (props: any) => void;

  successHandler?: (props: any) => void;

  devices: DeviceModel[];

  errors: Error[] = [];

  loadingStartHandler: () => void;

  loadingEndHandler: () => void;

  maxRetriesHandler: (state: boolean) => void;

  activeHouseholdId: number;

  constructor({
    productId,
    devices,
    onError,
    onSuccess,
    onFound,
    loadingEnd,
    loadingStart,
    activeHouseholdId,
    maxRetriesHandler,
    maxRetries = 40,
  }: ISearchDeviceServiceProps) {
    this.productId = productId;
    this.parentDevice = devices.filter(device => device.product_id === 1)[0] || null;
    this.maxRetries = maxRetries;
    this.devices = devices;
    this.errorHandler = onError;
    this.successHandler = onSuccess;
    this.foundHandler = onFound;
    this.loadingStartHandler = loadingStart;
    this.loadingEndHandler = loadingEnd;
    this.maxRetriesHandler = maxRetriesHandler;
    this.activeHouseholdId = activeHouseholdId;
  }

  stop() {
    this.loadingEndHandler();
    clearInterval(this.timer);
    // Sam - 16/08/23
    // This broke the start of the search for the hub because a parent device doesn't always exist.
    // DeviceApi.updateHubPairingMode(this.parentDevice.id, 0);
    this.timer = undefined;
    this.retries = 0;
  }

  async scan() {
    this.loadingStartHandler();
    this.retries += 1;

    // Put hub into search mode
    try {
      if (this.productId !== DeviceType.Hub && this.productId !== DeviceType.Cerberus) {
        await DeviceApi.updateHubPairingMode(this.parentDevice.id);
      }
    } catch (err) {
      if (err?.status === 504) {
        Toast.show({
          type: 'generalToast',
          props: {
            description: i18n.t('hub_pairing_mode_unreachable'),
            isError: true,
          },
          visibilityTime: 3000,
        });
      }
      // Need to handle a .NET 504 from updateHubPairingMode here.
      console.log(err);
    }
    return setInterval(() => {
      this.retries += 1;
      this.doSearch();
    }, this.retryEvery);
  }

  start() {
    this.maxRetriesHandler(false);
    this.stop();
    this.scan().then(timer => {
      this.timer = timer;
    });
    return this.doClearTimeout();
  }

  doClearTimeout() {
    clearInterval(this.timer);
  }

  doSearch() {
    if (this.retries > this.maxRetries) {
      this.maxRetriesHandler(true);
      this.stop();
    }

    if (
      !this.parentDevice ||
      this.productId === DeviceType.Hub ||
      this.productId === DeviceType.Cerberus
    ) {
      Network.getNetworkStateAsync().then(state => {
        if (state.isConnected && state.isInternetReachable) {
          this.searchHub();
        } else {
          console.log('waiting, no internet');
        }
      });
    } else {
      this.searchChild();
    }
  }

  finish(devices: DeviceModel[]) {
    if (this.productId === DeviceType.Cerberus) {
      this.successHandler?.(devices);
      return;
    }
    this.stop();
    this.retries = 0;
    this.successHandler?.(devices);
  }

  searchHub() {
    return DeviceApi.allPairingAsync()
      .then((devices: DeviceModel[]) => {
        if (!devices?.length) {
          throw new Error('No pairs found!');
        }
        this.finish([...devices]);
      })
      .catch(err => {
        console.log(err);
      });
  }

  searchChild() {
    return DeviceApi.getDevices(this.activeHouseholdId).then(newDevices => {
      const hub = newDevices.filter((e) => e.product_id === DeviceType.Hub);
      if (hub?.length && !hub[0].status.online) {
        console.log('HUB STATUS OFFLINE');
        this.maxRetriesHandler(true);
        this.stop()
      }
      const newDevice = newDevices.filter(
        d => this.devices.findIndex(f => f.id === d.id) === -1,
      );
      if (newDevice.length) {
        if (newDevice[newDevice.length - 1].product_id === this.productId) {
          this.finish(newDevice);
        } else {
          this.notify(newDevice);
        }
      }
    }).catch(error => {
      console.error('ERROR FETCHING DEVICES:', error);
      this.maxRetriesHandler(true);
      this.stop()
    });
  }

  notify(devices: DeviceModel[]) {
    this.stop();
    this.retries = 0;
    this.foundHandler?.(devices);
  }

  get exceptions() {
    return this.errors;
  }
}
