import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import React, { useMemo, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import useBoundStore from 'src/store/store';
import { WeightUnits } from '@constants/WeightUnits';
import { TimeUnits } from '@constants/DateFormat';
import colors from 'src/styles/colors';
import { useTranslation } from 'react-i18next';
import { AccountMenu } from '@constants/Menus';
import { useUpdateAccount } from '@hooks/useUpdateAccount';
import CustomKeyboardAvoidingView from 'src/components/CustomKeyboardAvoidingView';
import { SpView } from 'src/components/SpView';
import { AccountStackParamList } from '.';
import { AccountLanguageWrapper } from './components/AccountLanguageWrapper';
import AccountTextWrapper from './components/AccountTextWrapper';
import AccountWeightWrapper from './components/AccountWeightWrapper';
import AccountTimeWrapper from './components/AccountTimeWrapper';
import AccountMarketingWrapper from './components/AccountMarketingWrapper';
import AccountCountriesWrapper from './components/AccountCountriesWrapper';

type Props = NativeStackScreenProps<AccountStackParamList, 'AccountEditSetting'>;

export const AccountEditSetting = ({ navigation, route }: Props) => {
  const [value, setValue] = useState(route.params.inputValue);
  const [inputName] = useState(route.params.inputName);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { user } = useBoundStore(state => state.accountStore);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const { t } = useTranslation();

  const [onUpdateAccount, error] = useUpdateAccount(
    setButtonIsLoading,
    setButtonDisabled,
    inputName,
    navigation,
    value,
  );

  const input = useMemo(() => {
    return (
      <View style={{ flex: 1 }}>
        {route.params.inputName === AccountMenu.FirstName && (
          <AccountTextWrapper
            label={route.params.label}
            inputName={route.params.inputName}
            currentString={route.params.inputValue}
            handleChange={setValue}
            setButtonDisabled={setButtonDisabled}
          />
        )}
        {route.params.inputName === AccountMenu.LastName && (
          <AccountTextWrapper
            label={route.params.label}
            inputName={route.params.inputName}
            currentString={route.params.inputValue}
            handleChange={setValue}
            setButtonDisabled={setButtonDisabled}
          />
        )}
        {route.params.inputName === AccountMenu.EmailAddress && (
          // TODO: This flow needs email change verification.
          <AccountTextWrapper
            label={route.params.label}
            inputName={route.params.inputName}
            currentString={route.params.inputValue}
            handleChange={setValue}
            setButtonDisabled={setButtonDisabled}
            err={error}
          />
        )}
        {route.params.inputName === AccountMenu.Language && (
          <AccountLanguageWrapper
            selectedLanguageId={user.language_id}
            handleChange={setValue}
          />
        )}
        {route.params.inputName === AccountMenu.WeightUnits && (
          <AccountWeightWrapper
            selectedWeight={WeightUnits[route.params.inputValue]}
            handleChange={setValue}
          />
        )}
        {route.params.inputName === AccountMenu.TimeFormat && (
          <AccountTimeWrapper
            selectedTime={TimeUnits[route.params.inputValue] as keyof typeof TimeUnits}
            handleChange={setValue}
          />
        )}
        {route.params.inputName === AccountMenu.Country && (
          <AccountCountriesWrapper
            handleChange={setValue}
            selectedItemId={route.params.inputValue}
            setButtonDisabled={setButtonDisabled}
          />
        )}
        {route.params.inputName === AccountMenu.MarketingOptIn && (
          <AccountMarketingWrapper
            currentPreference={route.params.inputValue}
            handleChange={setValue}
          />
        )}
      </View>
    );
  }, [route, setValue, error]);
  return (
    <CustomKeyboardAvoidingView
      scrollView={false}
      modal
    >
      <SpView style={styles.content}>
        <View style={styles.inner}>{input}</View>
        <View style={styles.buttonWrapper}>
          <SpRoundedHeaderButton
            disabled={buttonDisabled}
            backgroundColor={colors.primary.color}
            h={56}
            title={t('save')}
            isLoading={buttonIsLoading}
            onPress={() => onUpdateAccount(value)}
            stylesForContainer={Platform.OS === 'android' ? { marginBottom: 30 } : undefined}
          />
        </View>
      </SpView>
    </CustomKeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: Platform.select({ web: null, default: 1 }),
    backgroundColor: colors.white.color,
    height: Platform.select({ web: '100%', default: null }),
  },
  inner: {
    flex: Platform.select({ web: null, default: 1 }),
    height: Platform.select({ web: '80%', default: null }),
  },
  buttonWrapper: {
    paddingHorizontal: 19,
    marginBottom: 34,
    bottom: Platform.select({ web: 60, default: null }),
    width: Platform.select({ web: '100%', default: null }),
  },
});
