import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const WCIAlertUp = (props: any) => (
  <Svg
    width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <G
      id="All/Light/Icon/Warning/Alert Up">
      <Path id="Path" d="M16 0.253906C7.168 0.253906 0 7.42191 0 16.2539C0 25.0859 7.168 32.2539 16 32.2539C24.832 32.2539 32 25.0859 32 16.2539C32 7.42191 24.832 0.253906 16 0.253906Z" fill="#FF9E63"/>
      <Path id="Fill 1" fill-rule="evenodd" clip-rule="evenodd" d="M13.9719 18.1841V21.396C13.9719 22.5161 14.8801 23.4243 16.0002 23.4243C17.1204 23.4243 18.0275 22.5161 18.0275 21.396V18.1841H22.0238C23.7838 18.1841 24.6623 16.0533 23.4142 14.8124L17.3907 8.82775C16.6217 8.0629 15.3787 8.0629 14.6098 8.82775L8.58624 14.8124C7.3371 16.0533 8.21662 18.1841 9.97669 18.1841H13.9719Z" fill="#263A43"/>
    </G>
  </Svg>
);
export default WCIAlertUp;
