import React, { useCallback, useLayoutEffect, useRef } from 'react';
import {
  GestureEvent,
  HandlerStateChangeEvent,
  PanGestureHandler,
  PanGestureHandlerEventPayload,
  State,
} from 'react-native-gesture-handler';
import { Dimensions, Platform } from 'react-native';

type BackSwipeHandlerProps = {
  /**
   * Whether the back swipe handler is enabled. When false the handler will not respond to gestures.
   */
  enabled: boolean;
  /**
   * The children components that the swipe handler wraps.
   */
  children: React.ReactNode;
  /**
   * The threshold percentage of the screen width to trigger the back swipe action.
   * Default is 30.
   */
  thresholdX?: number;
  /**
   * The activation threshold percentage of the screen width to start recognizing the swipe.
   * Default is 10.
   */
  activationThresholdX?: number;
  /**
   * The action to be executed on a successful back swipe. If not provided, the handler will not do anything.
   */
  onBackSwipeAction?: () => void;
  /**
   * The id for using it in jest.
   */
  testID?: string;
};

/**
 * A component that handles back swipe gestures, typically used for navigation.
 * @param {BackSwipeHandlerProps} props - The props for the component.
 * @returns {React.ReactElement} The React component.
 */
const BackSwipeHandler = ({
  enabled,
  children,
  thresholdX = 30,
  activationThresholdX = 10,
  onBackSwipeAction,
  testID,
}: BackSwipeHandlerProps): React.ReactElement => {
  const startSwipePosition = useRef(0);
  const threshold = useRef(0);
  const activationThreshold = useRef(0);
  const swipeStarted = useRef(false);

  useLayoutEffect(() => {
    threshold.current = (Dimensions.get('window').width * thresholdX) / 100;
    activationThreshold.current = (Dimensions.get('window').width * activationThresholdX) / 100;
  }, [activationThresholdX, thresholdX]);

  const handleGestureEvent = useCallback(
    (
      event:
        | GestureEvent<PanGestureHandlerEventPayload>
        | HandlerStateChangeEvent<PanGestureHandlerEventPayload>,
    ) => {
      const { absoluteX, translationX, state } = event.nativeEvent;

      switch (state) {
        case State.UNDETERMINED:
        case State.FAILED:
        case State.CANCELLED:
          swipeStarted.current = false;
          startSwipePosition.current = 0;
          break;

        case State.BEGAN:
          if (absoluteX < activationThreshold.current) {
            swipeStarted.current = true;
            startSwipePosition.current = absoluteX;
          }
          break;

        case State.ACTIVE:
          if (swipeStarted.current && translationX >= threshold.current) {
            swipeStarted.current = false;
            startSwipePosition.current = 0;
            onBackSwipeAction?.();
          }
          break;

        case State.END:
          if (swipeStarted.current && translationX >= threshold.current) {
            onBackSwipeAction?.();
          }
          swipeStarted.current = false;
          startSwipePosition.current = 0;
          break;
      }
    },
    [onBackSwipeAction],
  );

  return (
    <PanGestureHandler
      onGestureEvent={handleGestureEvent}
      onHandlerStateChange={handleGestureEvent}
      enabled={Platform.OS === 'ios' && enabled} // Only enable the handler on iOS if enabled prop is true.
      testID={testID}
    >
      {children}
    </PanGestureHandler>
  );
};

export default BackSwipeHandler;
