import { SpView } from 'src/components/SpView';
import { SpVStack } from 'src/components/SpVStack';
import SpModal from 'src/components/SpModal';
import colors from '@styles/colors';
import React, { useMemo } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { closeModal } from 'src/services/ModalBox';
import { ReportRangeType, ReportRangeTypeName } from '../../../../constants/ReportRangeType';
import SelectOptionWhite from './SelectOptionWhite';
import { useTranslation } from 'react-i18next';

interface SelectDateRangeModalProps {
  topOffset: number;
  type: ReportRangeType;
  onChange: (data: ReportRangeType) => void;
  onClose: () => void;
}

const StatsViewedDaysModal = ({
  type,
  topOffset,
  onChange,
  onClose,
}: SelectDateRangeModalProps) => {
  const { t } = useTranslation();

  const height = 250;
  const ranges = useMemo(() => {
    return Object.keys(ReportRangeTypeName).map(item => {
      return {
        id: Number(item),
        // @ts-ignore
        name: ReportRangeTypeName[item],
      };
    });
  }, []);

  return (
    <SpModal
      onModalCloseHandler={onClose}
      modalName="StatsViewedDaysModal"
      backdropColor="transparent">
      <Pressable
        accessible={false}
        style={[styles.modalWrap, { margin: topOffset - height - 60 }]}>
        <SpView style={[styles.modalContent, { height }]}>
          <SpVStack space={4}>
            {ranges.map(item => {
              return (
                <SelectOptionWhite
                  key={item.id}
                  item={{
                    id: item.id,
                    name: t(item.name, {
                      value: item.id === 180 ? 6 : item.id === 365 ? 1 : item.id,
                    }),
                  }}
                  active={item.id === type}
                  onChange={(data: ReportRangeType) => {
                    closeModal('StatsViewedDaysModal');
                    onChange(data);
                  }}
                />
              );
            })}
          </SpVStack>
        </SpView>
      </Pressable>
    </SpModal>
  );
};

export default StatsViewedDaysModal;

const styles = StyleSheet.create({
  modalWrap: {
    paddingHorizontal: 20,
    width: '100%',
    backgroundColor: colors.white.color,
  },
  modalContent: {
    backgroundColor: colors.greyText.color,
    borderRadius: 10,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  modalTitle: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    lineHeight: 24,
    color: colors.white.color,
  },
});
