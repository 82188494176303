import { SpView } from 'src/components/SpView';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import { SpDivider } from 'src/components/SpDivider';

import colors from '@styles/colors';
import SpBadge from 'src/components/RoundIcons/SpBadge';
import { permissionLevelOptions } from '@utils/permissionLevelDefiner';
import { SpProfileImage } from 'src/components/SpProfileImage';
import { testProperties } from '@utils/testProperties';
import { HouseholdStackParamList } from '..';
import { SpText } from '../../../components/SpText/SpText';
import { HouseholdModel } from '@models/Household';

export interface HouseHoldUsersProps {
  email?: string;
  img?: string;
  name: string;
  permissionLevelId?: number;
  id?: number;
  role: string;
  household?: HouseholdModel;
}

const HouseholdUserThumbnail = (user: HouseHoldUsersProps) => {
  const { img, email, name, role, permissionLevelId } = user;
  const navigation = useNavigation<NavigationProp<HouseholdStackParamList>>();
  return (
    <>
      <SpDivider />
      <TouchableOpacity
        {...testProperties(name || email, 'user thumbnail view')}
        onPress={() =>
          navigation.navigate('HouseholdManageUser', {
            user,
          })
        }>
        <View
          {...testProperties(`${name}Wrapper`, 'NameText')}
          style={styles.content}>
          <SpProfileImage
            isUser
            width={48}
            height={48}
            imageUrl={img}
          />
          <View
            {...testProperties(`${name}WrapperInner`, 'NameText')}
            style={styles.infoContainer}>
            {name ? (
              <SpView
                {...testProperties(`${name}TextWrapper`, 'NameText')}
                accessible
                style={styles.info}>
                <Text
                  {...testProperties(name, 'NameText')}
                  style={styles.topText}>
                  {name}
                </Text>
                <SpView
                  {...testProperties(`${name}BadgeWrapper`, 'Badge')}
                  style={styles.permissionLabelWrap}>
                  <SpBadge
                    width="auto"
                    text={permissionLevelOptions?.[permissionLevelId - 1]?.title}
                  />
                </SpView>
              </SpView>
            ) : (
              <SpText style={styles.bottomText}>{role === 'invite' && email}</SpText>
            )}

            <View {...testProperties('faChevronRight', 'icon')}>
              <FontAwesomeIcon
                style={styles.icon}
                size={24}
                icon={faChevronRight}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 17,
    marginBottom: 17,
    marginLeft: 8,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 12,
  },
  info: {
    flexDirection: 'column',
    width: '80%',
  },
  topText: {
    fontSize: 18,
    color: colors.greyText.color,
    fontFamily: 'Rubik',
    marginBottom: 6,
  },
  bottomText: {
    fontSize: 14,
    color: colors.greyText.color,
    fontFamily: 'Rubik',
  },
  icon: {
    color: colors.greyText.color,
  },
  permissionLabelWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default HouseholdUserThumbnail;
