import { ConsumptionInsight } from '@models/ConsumptionInsight';
import { useMemo } from 'react';
import { TimeService } from '../../../../../../services/TimeService';
import { WithoutTimeDateFormat } from '@constants/DateFormat';
import { ReportName, ReportType } from '../../../../constants/ReportType';
import { ConsumptionInsightWarningOutcomes } from '@constants/ConsumptionInsightTypes';

export const usePetInsightWarningDates = (
  habits: ConsumptionInsight[],
  type: ReportType,
): string[] => {
  const convertHabitDateToFormat = (habit: ConsumptionInsight) => {
    // when use for other than drinking filter by substance
    return TimeService.toLocal(habit.last_drinking_event_utc).toFormat(WithoutTimeDateFormat);
  };

  const warningDatesByTypes = useMemo(() => {
    return {
      feeding: [], // add for feeding CI in future
      movement: [], // add for movement CI in future
      drinking: habits
        .filter(h => ConsumptionInsightWarningOutcomes.includes(h.outcome))
        .map(convertHabitDateToFormat),
    };
  }, [habits]);

  return useMemo((): string[] => {
    return warningDatesByTypes[ReportName[type]] || [];
  }, [type, warningDatesByTypes]);
};
