import { DeviceType } from '@constants/Device';
import { useDevicesSearchService } from '@hooks/useDeviceService';
import StepFormCreator from 'src/components/StepFormCreator';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, Image, Linking, StyleSheet } from 'react-native';
import useValidation from 'src/pages/validation';
import useBoundStore from 'src/store/store';
import { SpText } from '../../../../../components/SpText/SpText';
import { SpCenter } from 'src/components/SpCenter';
import colors from '@styles/colors';
import { AppImages } from '@constants/AppImages';
import LoadingComponent from 'src/pages/Flows/components/LoadingComponent';
import SetupSteps from 'src/pages/Flows/Hub/SetupSteps';
import { ConnectHub } from 'src/pages/Flows/Hub/ConnectHub';
import { BeforeYouContinue } from 'src/pages/Flows/Generic/BeforeYouContinue';
import NameForm from 'src/pages/Flows/Generic/NameForm';
import { CompleteSetupProductWithLoading } from 'src/pages/Flows/Generic/CompleteSetupProductWithLoading';
import { testProperties } from '@utils/testProperties';
import { StepFormStepsObj } from '@models/FormikTypes';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { MAX_HUB_SEARCH_RETRIES } from '@constants/FindDevice';
import DeviceFoundState from '../../DeviceFoundState';
import { RootStackParamList } from '../../../../index';
import { navigateToProducts } from '@utils/navigationShortcuts';

const AddHub = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [tryTimes, setTryTimes] = useState(1);
  const { loadDevice, updateNameRequest } = useBoundStore(s => s.deviceStore);
  const [submitValues, setSubmitValues] = useState<any>();
  const [name, setName] = useState<string>('');
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { commonNameSchema } = useValidation();
  const { deviceSearchService, connected, isLoading, deviceId, maxRetriesError } =
    useDevicesSearchService(DeviceType.Hub, MAX_HUB_SEARCH_RETRIES);

  const appStateRef = useRef(false);

  useEffect(() => {
    if (AppState.currentState !== 'active') {
      deviceSearchService.stop();
      appStateRef.current = true;
    }

    if (appStateRef.current && step === 4 && !maxRetriesError) {
      deviceSearchService.start();
      appStateRef.current = false;
    }
  }, [AppState.currentState]);

  useEffect(() => {
    return () => {
      deviceSearchService?.stop();
    };
  }, []);

  useEffect(() => {
    if (step === 4 && !maxRetriesError) {
      deviceSearchService.start();
    }

    return () => {
      return deviceSearchService ? deviceSearchService.stop() : null;
    };
  }, [step, deviceSearchService]);

  const foundDeviceStep = useMemo(() => {
    return (
      <SpCenter
        style={{
          marginTop: 32,
        }}
      >
        <Image
          source={AppImages.pairHub}
          style={styles.image}
          resizeMode="contain"
          {...testProperties('pairHub', 'image')}
        />

        <SpText
          align="center"
          size="md"
          color={colors.greyText.color}
          style={styles.foundDeviseStep}
        >
          {t('setup_hub_found_step')}
        </SpText>
        <LoadingComponent
          searchMsg={t('looking_for_hub')}
          isConnected={connected}
          connectedMsg={t('hub_found')}
        />
      </SpCenter>
    );
  }, [connected, isLoading]);

  const tryAgain = () => {
    if (tryTimes > 2) {
      setStep(1);
      Linking.openURL(t('get_help_url'));
      return;
    }
    deviceSearchService.maxRetriesHandler(false);
    setTryTimes(old => ++old);
    setStep(4);
    deviceSearchService.start();
  };

  const searchHubStep = useMemo((): StepFormStepsObj => {
    return maxRetriesError
      ? {
          ui: (
            <DeviceFoundState
              connected
              maxRetriesError
            />
          ),
          backBottomButton: true,
          hideButton: false,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          buttonText: tryTimes > 2 ? t('get_help') : t('try_again'),
          forcePressHandler: tryAgain,
        }
      : {
          ui: (
            <DeviceFoundState
              connected={connected}
              maxRetriesError={false}
            />
          ),
          hideButton: !connected,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          interceptor: () => {
            if (connected) {
              return true;
            }
            deviceSearchService.start();
            return false;
          },
        };
  }, [maxRetriesError, connected, deviceSearchService, foundDeviceStep, tryTimes]);

  const onSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      try {
        await updateNameRequest(deviceId, { name: submitValues.name });
        await loadDevice(true);

        setCreated(true);
      } catch (err) {
        console.log(err);
      }
    },
    [submitValues, deviceId],
  );

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('add_hub')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        1: {
          ui: <SetupSteps />,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
          },
          hideProgressHeader: true,
        },
        2: {
          ui: <ConnectHub />,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          customStyleButton: styles.customButton,
          customStyleBackButton: styles.customBackButton,
          showHeaderShadow: true,
        },
        3: {
          ui: <BeforeYouContinue productId={DeviceType.Hub} />,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          customStyleButton: styles.customButton,
          customStyleBackButton: styles.customBackButton,
          showHeaderShadow: true,
        },
        4: searchHubStep,
        5: {
          ui: NameForm,
          props: {
            setName,
            label: t('hub_name'),
            title: t('name_your_hub'),
            hideTitleOnFocus: false,
          },
          formik: { schema: commonNameSchema('Hub name'), names: ['name'] },
          buttonDisabled: name === '',
        },
        6: {
          ui: (
            <CompleteSetupProductWithLoading
              deviceId={deviceId}
              productId={DeviceType.Hub}
              onLoadedCallback={onSubmit}
            />
          ),
          hideCustomHeader: true,
          hideButton: true,
          handleAndroidBackPress: () => {
            navigateToProducts();
            return true;
          },
        },
      }}
    />
  );
};

const styles = StyleSheet.create({
  foundDeviseStep: { marginTop: 32, marginBottom: 48, paddingHorizontal: 0 },
  image: { height: 264 },
  customButton: {
    marginTop: 0,
    flex: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,
    elevation: 7,
  },
  customBackButton: {
    backgroundColor: '#ffffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,
    elevation: 7,
  },
});

export default AddHub;
