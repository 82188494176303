import { AppImages } from '@constants/AppImages';
import { DeviceType } from '@constants/Device';
import { StepFormStepsObj } from '@models/FormikTypes';
import { navigateToPets, navigateToProducts } from '@utils/navigationShortcuts';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import { SpSuccessTick } from 'src/components/SpSuccessTick';
import StepFormCreator from 'src/components/StepFormCreator';
import { closeModal } from 'src/services/ModalBox';
import AddPetsContent from './AddPetsContent';
import { FlowStackParamList } from '../..';
import LoadingComponent from '../../components/LoadingComponent';
import AssignPet from '../AssignPet';
import FoundPets from '../FoundPets';
import IncompatibleChip from '../IncompatibleChip';
import IndoorModePage from '../IndoorModePage';
import PromptPage from '../PromptPage/PromptPage';
import { StepsEnum } from './misc/enums';
import NoProducts from './components/NoProducts';
import SelectDevice from './components/SelectDevice';
import AddPetsLoading from './components/AddPetsLoading';
import useAddPetsLogic from './hooks/useAddPetsLogic';

type Props = NativeStackScreenProps<FlowStackParamList, 'AddPets'>;

const AddPets = ({ route }: Props) => {
  const { existingDevice, comingFromSettings, comingFromIncompatibleAssignPage, deviceType } =
    route.params ?? {};
  const [submitValues, setSubmitValues] = useState<any>();
  const { t } = useTranslation();
  const {
    maxRetriesError,
    step,
    handleSetStep,
    unnamedPets,
    updatesPetsShared,
    incompatiblePetAssociatedRef,
    loading,
    leavePetFlow,
    handleSelectedDevice,
    applyInterceptor,
    setAssignUpdateData,
    indoorModeEnabledPets,
    setIndoorModeEnabledPets,
    redirectToAddPet,
    selectedDeviceRef,
    assignedPets,
    setAssignedPets,
    incompatiblePets,
    setIncompatiblePets,
    foundedPetsTagIds,
    indoorModePetList,
    handleAddAnotherPetAction,
    setUnnamedPets,
    handleFoundPetsContinueButtonAction,
    handleFoundPetsAndroidBackPress,
    handleAssignPetSaveButtonAction,
    handlePromptPageUnassignPetsAction,
    successTickMessage,
    addPetsStepConfig,
    handleIndoorModeLeaveAction,
  } = useAddPetsLogic({
    ...route.params,
    routeStep: route.params.step,
  });

  const LoaderComponent = useMemo(() => {
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <LoadingComponent />
      </View>
    );
  }, []);

  const step3 = useMemo((): StepFormStepsObj => {
    if (loading) {
      return {
        ui: LoaderComponent,
        hideButton: true,
        hideProgressHeader: true,
      };
    }
    const ui = (
      <AddPetsContent
        productId={deviceType || selectedDeviceRef.current?.product_id}
        hideCustomHeader
        handler={null}
        loadingElement={
          <AddPetsLoading
            comingFromSettings={comingFromSettings}
            maxRetriesError={maxRetriesError}
            comingFromIncompatibleAssignPage={
              comingFromIncompatibleAssignPage || incompatiblePetAssociatedRef.current
            }
            existingDevice={existingDevice}
            checkToLeavePetFlow={leavePetFlow}
            foundedPetsTagIds={foundedPetsTagIds}
            incompatiblePets={incompatiblePets}
          />
        }
        withStepNums={false}
      />
    );
    return {
      ui: ui,
      ...addPetsStepConfig,
    };
  }, [
    loading,
    deviceType,
    selectedDeviceRef,
    comingFromSettings,
    maxRetriesError,
    comingFromIncompatibleAssignPage,
    incompatiblePetAssociatedRef,
    existingDevice,
    leavePetFlow,
    foundedPetsTagIds,
    incompatiblePets,
    addPetsStepConfig,
    LoaderComponent,
  ]);

  return (
    <StepFormCreator
      step={step}
      setStep={handleSetStep}
      headerTitle={t('add_pet')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      disableKeyboardAwoiding={step === 1}
      deviceType={deviceType || selectedDeviceRef.current?.product_id}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        [StepsEnum.NoProducts /**step 0*/]: {
          ui: loading ? LoaderComponent : NoProducts,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToPets,
          },
          buttonText: t('add_a_product'),
          hideProgressHeader: true,
          interceptor: applyInterceptor(StepsEnum.NoProducts),
        },
        [StepsEnum.SelectDevice /**step 1*/]: {
          ui: loading ? LoaderComponent : <SelectDevice onItemSelected={handleSelectedDevice} />,
          hideButton: true,
          hideProgressHeader: true,
          skipStep: Boolean(selectedDeviceRef.current),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          interceptor: applyInterceptor(StepsEnum.SelectDevice),
        },
        [StepsEnum.MorePetFeeder /**step 2*/]: {
          ui: loading ? (
            LoaderComponent
          ) : (
            <PromptPage
              headerMessage={{
                txt: selectedDeviceRef.current?.name || selectedDeviceRef.current?.serial_number,
                img: AppImages.feeder,
              }}
              title={t('more_pet_title_exiting_device')}
              subtitle={t('more_pet_subtitle')}
              showButton={false}
            />
          ),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToPets,
            withTitle: false,
            withArrowBack: false,
            withRightButton: false,
          },
          goBackAction: () => handleSetStep(StepsEnum.SelectDevice),
          skipStep: !(
            selectedDeviceRef.current?.product_id === DeviceType.FeederConnect &&
            selectedDeviceRef.current?.tags?.length > 0 &&
            existingDevice
          ),
          hideProgressHeader: true,
          interceptor: applyInterceptor(StepsEnum.MorePetFeeder),
        },
        [StepsEnum.AddPets /**step 3*/]: {
          ...step3,
          interceptor: applyInterceptor(StepsEnum.AddPets),
        },
        [StepsEnum.FoundPets /**step 4*/]: {
          ui: loading ? (
            LoaderComponent
          ) : (
            <FoundPets
              isRemovable
              addAnotherPetAction={handleAddAnotherPetAction}
              assignedPets={assignedPets}
              continueButtonAction={handleFoundPetsContinueButtonAction}
              setAssignedPets={setAssignedPets}
              setUnnamedPets={setUnnamedPets}
            />
          ),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToPets,
          },
          hideProgressHeader: false,
          skipStep: existingDevice,
          handleAndroidBackPress: handleFoundPetsAndroidBackPress,
          interceptor: applyInterceptor(StepsEnum.FoundPets),
        },
        [StepsEnum.PetsNotNamed /**step 5*/]: {
          ui: loading ? (
            LoaderComponent
          ) : (
            <PromptPage
              title={t('you_havent_named_all_your_pets')}
              subtitle={t('you_can_continue_without_naming')}
              showButton={false}
            />
          ),
          skipStep: (() => unnamedPets === false || existingDevice)(),
          additionalButton: true,
          additionalButtonName: t('go_back'),
          additionalButtonAction: () => handleSetStep(StepsEnum.FoundPets /** step 4*/),
          customHeaderProps: {
            withLeaveButton: false,
            withTitle: false,
            withArrowBack: false,
            withRightButton: false,
          },
          goBackAction: () => handleSetStep(StepsEnum.FoundPets /** step 4*/),
          hideProgressHeader: true,
          interceptor: applyInterceptor(StepsEnum.PetsNotNamed),
        },
        [StepsEnum.AssignPets /**step 6*/]: {
          ui: loading ? (
            LoaderComponent
          ) : (
            <AssignPet
              isAsync={false}
              device={selectedDeviceRef.current}
              data={updatesPetsShared}
              buttonText={t('add_new_pet')}
              shouldPassData
              setAssignUpdateData={setAssignUpdateData}
              title={t('assign_pet_title')}
              subtitle={
                selectedDeviceRef.current?.product_id === DeviceType.Cerberus
                  ? t('assign_pet_subtitle_cdb')
                  : t('assign_pet_subtitle')
              }
              isSetupFlow
              incompatiblePets={incompatiblePets}
              setIncompatiblePets={setIncompatiblePets}
              onClickOnIncompatiblePet={redirectToAddPet}
              saveButtonAction={handleAssignPetSaveButtonAction}
            />
          ),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToPets,
          },
          hideProgressHeader: true,
          buttonDisabled: loading,
          interceptor: applyInterceptor(StepsEnum.AssignPets),
        },
        [StepsEnum.IncompatibleChip /**step 7*/]: {
          ui: loading ? (
            LoaderComponent
          ) : (
            <IncompatibleChip
              productType={selectedDeviceRef.current?.product_id}
              incompatiblePetAssociated={incompatiblePetAssociatedRef.current}
              buttonAction={handleAddAnotherPetAction}
            />
          ),
          customHeaderProps: {
            withArrowBack: true,
            withLeaveButton: false,
            withTitle: false,
            withRightButton: false,
          },
          hideButton: true,
          interceptor: applyInterceptor(StepsEnum.IncompatibleChip),
        },
        [StepsEnum.RemovePets /**step 8*/]: {
          ui: loading ? (
            LoaderComponent
          ) : (
            <PromptPage
              title={t('more_pet_title')}
              subtitle={t('more_pet_subtitle')}
              buttonTitle={t('unassign_pets')}
              buttonAction={handlePromptPageUnassignPetsAction}
            />
          ),
          hideProgressHeader: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          handleAndroidBackPress: () => {
            handlePromptPageUnassignPetsAction();
            return true;
          },
          interceptor: applyInterceptor(StepsEnum.RemovePets),
        },
        [StepsEnum.IndoorMode /**step 9*/]: {
          ui: loading ? (
            LoaderComponent
          ) : (
            <IndoorModePage
              petList={indoorModePetList}
              indoorModeEnabledPets={indoorModeEnabledPets}
              setIndoorModeEnabledPets={setIndoorModeEnabledPets}
              continueButtonAction={() => closeModal('IndoorModeModal')}
            />
          ),
          customHeaderProps: existingDevice
            ? null
            : {
                withArrowBack: false,
                withLeaveButton: true,
                withTitle: false,
                withRightButton: false,
                leaveButtonAction: handleIndoorModeLeaveAction,
              },
          skipStep: selectedDeviceRef.current?.product_id !== DeviceType.CatFlapConnect,
          handleAndroidBackPress: () => {
            handleIndoorModeLeaveAction();
            return true;
          },
          interceptor: applyInterceptor(StepsEnum.IndoorMode),
        },
        [StepsEnum.Success /**step 10*/]: {
          ui: loading ? (
            LoaderComponent
          ) : (
            <View style={{ marginTop: 112 }}>
              <SpSuccessTick text={successTickMessage} />
            </View>
          ),
          hideProgressHeader: true,
          hideCustomHeader: true,
          handleAndroidBackPress: () => {
            navigateToPets();
            return true;
          },
          interceptor: applyInterceptor(StepsEnum.Success),
        },
        [StepsEnum.Loader /**step 11*/]: {
          ui: LoaderComponent,
          hideProgressHeader: true,
          hideCustomHeader: true,
          hideButton: true,
        },
      }}
    />
  );
};

export default AddPets;
