import { TimelineEventModel } from '@models/Timeline';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimelinePetName } from '../../hooks/useTimelinePetName';
import { TimelineCard } from './TimelineCard';
import { TimelinePetPhoto } from './TimelinePetPhoto';

export const PetName = ({ event, active }: { event: TimelineEventModel; active: boolean }) => {
  const { t } = useTranslation();
  const petName = useTimelinePetName(event);

  const text = useMemo(() => {
    return t('timeline_pet_joined_household', {
      petname: petName,
    });
  }, [event, petName, t]);

  return (
    <TimelineCard
      active={active}
      image={
        <TimelinePetPhoto
          active={active}
          event={event}
        />
      }
      text={text}
      date={event.created_at}
    />
  );
};
