import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { LineGraph, LineGraphPointModel } from './LineGraph';

interface LineGraphModalProps {
  weekLastLabels: string[];
  weekThisLabels: string[];
  weekLastData: LineGraphPointModel[];
  weekThisData: LineGraphPointModel[];
  monthLastLabels: string[];
  monthThisLabels: string[];
  monthLastData: LineGraphPointModel[];
  monthThisData: LineGraphPointModel[];
}

export const LineGraphModal = ({
  weekLastLabels,
  weekThisLabels,
  weekLastData,
  weekThisData,
  monthLastLabels,
  monthThisLabels,
  monthLastData,
  monthThisData,
}: LineGraphModalProps) => {
  const { t } = useTranslation();

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{
        width: '95%',
        borderRadius: 15,
        marginTop: 60,
        margin: 15,
        backgroundColor: 'white',
      }}>
      <Pressable>
        <SpText style={styles.title}>{t('trends')}</SpText>
        <SpText style={[styles.subtitle, { marginTop: 15 }]}>{t('week')}</SpText>
        <SpView style={styles.graph}>
          <LineGraph
            padding={60}
            lastData={weekLastData}
            thisData={weekThisData}
            lastLabels={weekLastLabels}
            thisLabels={weekThisLabels}
            yAxisData={['M', 'T', 'W', 'T', 'F', 'S', 'S']}
            labels={[t('last_week'), t('this_week')]}
          />
        </SpView>

        <SpText style={[styles.subtitle]}>{t('month')}</SpText>
        <SpView style={styles.graph}>
          <LineGraph
            padding={60}
            lastData={monthLastData}
            thisData={monthThisData}
            lastLabels={monthLastLabels}
            thisLabels={monthThisLabels}
            yAxisData={[1, 5, 10, 15, 20, 25, 30]}
            labels={[t('last_month'), t('this_month')]}
          />
        </SpView>
      </Pressable>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  title: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 24,
    lineHeight: 26,
    fontWeight: 'bold',
    color: colors.placeholderGrey.color,
  },
  graph: {
    marginTop: 10,
    paddingHorizontal: 24,
  },
  subtitle: {
    paddingHorizontal: 24,
    marginTop: 20,
    fontSize: 18,
    lineHeight: 20,
    fontWeight: 'bold',
    color: colors.placeholderGrey.color,
  },
});
