import { StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { SpView } from 'src/components/SpView';
import { SpText } from '../../../components/SpText/SpText';
import text from '@styles/text';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { DeviceType } from '@constants/Device';
import { useDeviceProductNameById } from '@hooks/useDeviceProductNameById';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import useBoundStore from '../../../store/store';

type IncompatibleChipProp = {
  productType: DeviceType;
  incompatiblePetAssociated: any;
  buttonAction?: () => void;
};

const IncompatibleChip = ({
  productType,
  incompatiblePetAssociated,
  buttonAction,
}: IncompatibleChipProp) => {
  const { getPetById } = useBoundStore(state => state.petStore);
  const { t } = useTranslation();
  const getProductName = useDeviceProductNameById(productType);
  const [pet, setPet] = useState(null);

  useEffect(() => {
    setPet(getPetById(incompatiblePetAssociated));
  }, []);

  return (
    <>
      {!pet && (
        <SpView>
          <SpText>No pets</SpText>
        </SpView>
      )}
      {pet && (
        <>
        <SpView>
          <SpText style={[text.title, styles.titleMargin]}>
            {t('incompatible_chip_title {{pet_name}} {{product_type}}', {
              pet_name: pet.name,
              product_type: getProductName,
            })}
          </SpText>
          <SpText style={[text.subtitle, styles.subtitleMargin]}>
            {t('incompatible_chip_description {{product_type}}', {
              product_type: getProductName,
            })}
          </SpText>
        </SpView>
        <SpView style={styles.btnBottom}>
          <SpRoundedHeaderButton
            backgroundColor={colors.primary.color}
            textStyles={{
              fontSize: 16,
              fontFamily: 'Rubik_Medium',
              color: colors.white.color,
            }}
            title={t('save')}
            onPress={buttonAction}
          />
        </SpView>
        </>
      )}
    </>
  );
};

export default IncompatibleChip;

const styles = StyleSheet.create({
  titleMargin: {
    marginTop: 24,
    marginBottom: 24,
  },
  subtitleMargin: {
    marginBottom: 24,
  },
  buttonText: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    color: colors.white.color,
  },
  buttonStyles: {
    maxHeight: 180,
    marginBottom: 48,
  },
  buttonWrapper: {
    flex: 1,
  },
  modalContainer: {
    paddingHorizontal: 19,
  },
  reminder: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
    color: colors.greyText.color,
    paddingHorizontal: 10,
  },
  buttonContinueContainer: { marginTop: 'auto', marginBottom: 68 },
  btnBottom: {position: 'absolute',width: '100%',
    bottom: 20, left: '5%' }
});
