// react native hook that records an array of dates that the user has opened the app
// counts the number of days that a user has opened the app and
// on the 10th day shows a popup that asks for feedback after the components using the hook has been focused for more than 15 seconds

import { RemoteConfigKeys } from '@constants/RemoteConfig';
import { useRemoteConfigBooleanValue } from '@hooks/useRemoteConfigBooleanValue';
import { useRemoteConfigNumberValue } from '@hooks/useRemoteConfigNumberValue';
import * as StoreReview from 'expo-store-review';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { AppState } from 'react-native';

// import { DdLogs, initDdSdkReactNative } from '/src/services/SPDataDogService';
import { AnalyticsService } from '../../services/AnalyticsService';
import useBoundStore from '../../store/store';

export default function useTriggerFeedbackPrompt() {
  const {
    isAuthenticated,
    setDaysAppOpened,
    daysAppOpened,
    setHasGivenFeedback,
    hasGivenFeedback,
    clearHasGivenFeedback,
  } = useBoundStore((store) => store.accountStore);

  const isEnabled = useRemoteConfigBooleanValue(RemoteConfigKeys.feedback_enabled);
  const feedbackDays = useRemoteConfigNumberValue(RemoteConfigKeys.feedbackDays);
  const feedbackDelay = useRemoteConfigNumberValue(RemoteConfigKeys.feedbackDelay) * 1000;

  const feedbackTimeoutRef = useRef(null);
  const reviewTimeoutRef = useRef(null);

  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        console.log('App has come to the foreground!');
      }

      appState.current = nextAppState;
      setAppStateVisible(appState.current);
      console.log('AppState', appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    // clearHasGivenFeedback();
    // return;
    const promptUser = async () => {
      if (await StoreReview.hasAction()) {
        StoreReview.requestReview()
          .then((done) => {
            console.log('StoreReview.requestReview() done', done);
            AnalyticsService.logEvent(`StoreReview - User Prompted`);
            setHasGivenFeedback(startOfDay);
          })
          .catch((err) => {
            console.log('StoreReview.requestReview() error', err);
          });
      } else {
        console.log('StoreReview.requestReview() not available');
      }
    };

    // Clear timeouts if the component unmounts or loses focus
    const clearTimeouts = () => {
      console.log('FEEDBACK - clearing timeouts');
      if (feedbackTimeoutRef.current) {
        clearTimeout(feedbackTimeoutRef.current);
        feedbackTimeoutRef.current = null;
      }
      if (reviewTimeoutRef.current) {
        clearTimeout(reviewTimeoutRef.current);
        reviewTimeoutRef.current = null;
      }
    };

    // Stop if the view is not focused, not enabled, or not authenticated
    if (appStateVisible !== 'active' || !isEnabled || !isAuthenticated) {
      clearTimeouts();
      if (!isEnabled) {
        console.log('FEEDBACK - feedback not enabled');
        // clearHasGivenFeedback();
      }
      if (appStateVisible !== 'active') {
        console.log('FEEDBACK - not focused');
      }
      return;
    }
    // Stop if they have already given feedback
    if (hasGivenFeedback) {
      // clearHasGivenFeedback();
      console.log('FEEDBACK - has given feedback ');
      AnalyticsService.logEvent(`StoreReview - Has Given Feedback `);

      return;
    }
    //get start of day with luxon
    const startOfDay = DateTime.now().startOf('day').toMillis();

    //check if the current day is already in the array
    if (!daysAppOpened.includes(startOfDay)) {
      console.log(
        'FEEDBACK - scheduling adding day to array',
        feedbackDelay,
        feedbackDays,
        Date.now(),
      );
      feedbackTimeoutRef.current = setTimeout(() => {
        // Add the current day to the array if they have had the app open for 10 seconds
        console.log('FEEDBACK - adding day to array', Date.now());
        setDaysAppOpened([...daysAppOpened, startOfDay]);
        AnalyticsService.logEvent(`StoreReview - DayAdded ${daysAppOpened.length + 1}`);
      }, feedbackDelay);
    } else {
      console.log('FEEDBACK - day already in array');
    }

    //check if the user has either opened the app for exactly 10 days, or has opened the app for more than 10 days and had requested to give feedback later
    if (daysAppOpened.length > feedbackDays && appStateVisible === 'active') {
      // show feedback if the user is focused on this component for more than 15 seconds
      reviewTimeoutRef.current = setTimeout(() => {
        // show feedback
        console.log('FEEDBACK -show feedback');

        promptUser();
      }, feedbackDelay);

      // on return clear the timeout and console log the timeout
      return () => {
        clearTimeouts();

        console.log('FEEDBACK - component unmounted - cleared timeout');
      };
    }
  }, [
    isEnabled,
    isAuthenticated,
    daysAppOpened,
    hasGivenFeedback,
    setDaysAppOpened,
    setHasGivenFeedback,
    appStateVisible,
  ]);
}
