import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { useCurrentLanguageCode } from '@hooks/useCurrentLanguage';
import { SpTermsCheckboxes, SpTermsCheckboxState } from 'src/components/SpTermsCheckboxes';
import atomStyles from '@styles/atoms';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

export type ReactSetState<T> = React.Dispatch<React.SetStateAction<T>>;

type Props = {
  setStep: ReactSetState<number>;
  terms: SpTermsCheckboxState;
  setTerms: ReactSetState<SpTermsCheckboxState>;
};

const WhatIsChanging: React.FC<Props> = ({ setStep, terms, setTerms }) => {
  const { t } = useTranslation();
  const userLanguageCode = useCurrentLanguageCode();

  return (
    <SpView
      alignItems="center"
      paddingVertical={19}>
      <Text style={[textStyles.loadingText, styles.smallTitle]}>{t('changes_question')}</Text>
      <ScrollView style={styles.chnagesScrollView}>
        <SpText style={[textStyles.defaultText, styles.smallerText]}>{t('large_terms')}</SpText>
      </ScrollView>
      <SpRoundedHeaderButton
        title={t('view_full_licence')}
        backgroundColor={colors.primary.color}
        stylesForContainer={styles.smallBtn}
        onPress={() => setStep(0)}
        textStyles={styles.buttonText}
      />
      <SpText style={textStyles.defaultText}>{t('full_licence_abilities')}</SpText>
      <SpView style={styles.container}>
        <SpTermsCheckboxes
          languageCode={userLanguageCode}
          active={terms}
          onChange={setTerms}
        />
      </SpView>
      <SpRoundedHeaderButton
        h={56}
        title={t('continue')}
        backgroundColor={colors.primary.color}
        stylesForContainer={[atomStyles.maxWidth, styles.bigBtn]}
        onPress={() => setStep(curr => curr + 1)}
        disabled={!terms.endUserLicenceAgreement || !terms.privacyPolicy}
      />
      <TouchableOpacity onPress={() => setStep(1)}>
        <SpText style={[textStyles.loadingText, styles.link]}>{t('decline')}</SpText>
      </TouchableOpacity>
    </SpView>
  );
};

const styles = StyleSheet.create({
  buttonText: {
    fontSize: 16,
    color: colors.white.color,
    fontFamily: 'Rubik_Medium',
  },
  smallBtn: {
    marginVertical: 24,
    width: '100%',
  },
  container: {
    marginTop: 24,
    gap: 16,
    width: '100%',
  },
  flex: {
    flex: 1,
  },
  smallerText: {
    marginTop: 4,
    fontSize: 14,
    lineHeight: 20,
  },
  smallTitle: {
    fontWeight: 'bold',
    textAlign: 'left',
    width: '100%',
    marginTop: undefined,
  },
  chnagesScrollView: {
    maxHeight: 200,
  },
  link: {
    fontSize: 16,
    marginTop: 26,
  },
  bigBtn: {
    marginTop: 44,
  },
});

export default WhatIsChanging;
