import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

/* SVGR has dropped some elements not supported by react-native-svg: title */
const CIDecreasedSVG = (props: any) => (
  <Svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
  >
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0858 5.58128C16.8486 5.58128 17.7546 7.69151 16.5405 8.96953L10.4548 15.3755C9.66375 16.2082 8.33654 16.2082 7.54553 15.3755L1.45985 8.96953C0.245728 7.69151 1.15168 5.58128 2.91447 5.58128H7.01046V2.05917C7.01046 0.921924 7.90127 3.8147e-06 9.00015 3.8147e-06C10.099 3.8147e-06 10.9898 0.921924 10.9898 2.05917L10.9898 5.58128H15.0858Z"
      fill="#263A43"
    />
  </Svg>
);
export default CIDecreasedSVG;
