const mockLine = [
  {
    height: '90%',
  },
  {
    height: '80%',
  },
  {
    height: '92%',
  },
  {
    height: '87%',
  },
  {
    height: '78%',
  },
  {
    height: '100%',
  },
  {
    height: '78%',
  },
  {
    height: '75%',
  },
  {
    height: '72%',
  },
  {
    height: '89%',
  },
  {
    height: '85%',
  },
];

export default mockLine;
