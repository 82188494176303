import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const TourTapSVG = (props: any) => (
  <Svg
    width="44px"
    height="68px"
    viewBox="0 0 44 68">
    <G
      id="tour-tap"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd">
      <G
        id="Group-8"
        transform="translate(0, 0)"
        fill="#03A2B1">
        <Path
          d="M11.4612441,31.503 C12.6692441,30.851 14.0112441,30.495 15.4112441,30.495 C15.7182441,30.495 16.0222441,30.511 16.3242441,30.544 L16.3212441,30.104 C12.1452441,27.678 9.32324415,23.167 9.32324415,18 C9.32324415,10.28 15.6042441,4 23.3232441,4 C31.0432441,4 37.3232441,10.28 37.3232441,18 C37.3232441,23.086 34.5882441,27.534 30.5192441,29.986 L30.5492441,34.474 C36.8842441,31.684 41.3232441,25.355 41.3232441,18 C41.3232441,8.074 33.2492441,0 23.3232441,0 C13.3982441,0 5.32324415,8.074 5.32324415,18 C5.32324415,23.379 7.70824415,28.202 11.4612441,31.503"
          id="Fill-1"
        />
        <Path
          d="M16.2927441,25.7822 L16.2437441,18.3502 C16.2267441,15.6322 17.7117441,13.1982 20.1197441,11.9972 C21.0867441,11.5152 22.1217441,11.2702 23.1967441,11.2702 C25.9257441,11.2702 28.4127441,12.8592 29.6887441,15.4162 C30.1777441,16.3972 30.4387441,17.4922 30.4447441,18.5832 L30.4917441,25.6582 C32.5377441,23.7422 33.8237441,21.0242 33.8237441,18.0002 C33.8237441,12.2012 29.1227441,7.5002 23.3237441,7.5002 C17.5247441,7.5002 12.8237441,12.2012 12.8237441,18.0002 C12.8237441,21.0902 14.1677441,23.8612 16.2927441,25.7822"
          id="Fill-3"
        />
        <Path
          d="M38.0411441,46.2926 L29.7651441,50.4186 C29.2651441,50.6686 28.8401441,50.4606 28.6811441,50.3596 C28.5211441,50.2596 28.1501441,49.9646 28.1461441,49.3966 L27.9451441,18.5976 C27.9411441,17.8876 27.7701441,17.1726 27.4511441,16.5326 C26.2221441,14.0676 23.4741441,13.1186 21.2361441,14.2346 C19.6871441,15.0066 18.7321441,16.5776 18.7441441,18.3336 L18.8471441,34.0866 C16.8071441,32.6416 14.0601441,32.6306 12.0461441,34.0576 L2.31814415,40.9746 C0.854144148,42.0156 -0.0128558517,43.7266 0,45.5506 L0.0871441483,58.9856 C0.0971441483,60.3816 0.432144148,61.7836 1.05714415,63.0366 C2.60114415,66.1326 5.67614415,68.0766 9.08214415,68.1056 L20.7871441,68.2096 C23.1701441,68.2316 25.5541441,67.6816 27.6811441,66.6216 C29.0751441,65.9266 30.3491441,65.0226 31.4671441,63.9356 L43.2621441,52.4766 C43.4791441,52.2666 43.6001441,51.9756 43.5991503,51.6686 L43.5871441,49.7946 C43.5821441,49.1976 43.4391441,48.5996 43.1721441,48.0646 C42.2191441,46.1526 39.9171441,45.3576 38.0411441,46.2926"
          id="Fill-5"
        />
      </G>
    </G>
  </Svg>
);
export default TourTapSVG;
