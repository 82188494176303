import { lens } from '@dhmk/zustand-lens';
import { StateCreator } from 'zustand';
import { MergedInterfaces } from '../models';

export interface UISliceModel {
  UIStore: {
    userHasZeroedAFeeder: boolean;
    allowTemporaryViewAppBasedOnTerms: number;
    updateProperty: (data: Partial<UISliceModel['UIStore']>) => void;
    reset: () => void;
  };
}

const createUISlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  UISliceModel
> = () => {
  return {
    UIStore: lens(set => ({
      userHasZeroedAFeeder: false,
      allowTemporaryViewAppBasedOnTerms: 0,
      updateProperty: data => set(data),
      reset: () => {
        set({
          userHasZeroedAFeeder: false,
        });
      },
    })),
  };
};
export default createUISlice;
