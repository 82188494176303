import React, { useCallback, useMemo, useState } from 'react';
import { AssignPetDevices, StepsEnum } from '../misc/enums';
import { DeviceModel } from '@models/Device';
import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import { DeviceType } from '@constants/Device';
import { RootStackParamList } from '../../../../index';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import useBoundStore from '../../../../../store/store';
import { DeviceTagModel } from '@models/DeviceTag';
import { PetModel, PetWithTagModel } from '@models/Pet';

type UseHandleStepsProps = {
  selectedDeviceRef: React.MutableRefObject<DeviceModel>;
  existingDevice: boolean;
  setSelectedDevice: (device: DeviceModel) => void;
  stopSkipRef: React.MutableRefObject<boolean>;
  petService: any;
  unnamedPets: boolean;
  comeFromRemovePets: React.MutableRefObject<boolean>;
  setUpdatesPetsShared: (pets: (PetWithTagModel & { isAssigned: boolean })[]) => void;
  updatesPetsShared: PetWithTagModel[];
  incompatiblePetAssociatedRef: React.MutableRefObject<number>;
  assignedPets?: PetModel[];
  showPetsAddedRef: React.MutableRefObject<boolean>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updateAssignedPets: () => Promise<void>;
  deviceId?: number;
  unchanged?: React.MutableRefObject<number>;
  noProducts?: boolean;
  routeStep?: StepsEnum;
  foundedPetsTagIds?: number[];
};

const useHandleSteps = ({
  selectedDeviceRef,
  existingDevice,
  setSelectedDevice,
  stopSkipRef,
  petService,
  unnamedPets,
  comeFromRemovePets,
  setUpdatesPetsShared,
  unchanged,
  incompatiblePetAssociatedRef,
  foundedPetsTagIds,
  showPetsAddedRef,
  setLoading,
  updateAssignedPets,
  deviceId,
}: UseHandleStepsProps) => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [step, setStep] = useState<StepsEnum>(11);
  const pets = usePetsByHousehold();

  const { refreshDeviceTags, getDeviceById, fetchAndGetDeviceById, loadForceDevice } =
    useBoundStore(({ deviceStore }) => ({
      refreshDeviceTags: deviceStore.refreshDeviceTags,
      getDeviceById: deviceStore.getDeviceById,
      fetchAndGetDeviceById: deviceStore.fetchAndGetDeviceById,
      loadForceDevice: deviceStore.loadForceDevice,
    }));

  const refreshSelectedDevice = useCallback(async () => {
    if (selectedDeviceRef.current?.id) {
      selectedDeviceRef.current = await fetchAndGetDeviceById(selectedDeviceRef.current?.id);
    } else if (deviceId) {
      selectedDeviceRef.current = await fetchAndGetDeviceById(deviceId);
    }
  }, [deviceId, fetchAndGetDeviceById, selectedDeviceRef]);

  const updatePetsShared = useCallback(async () => {
    await refreshDeviceTags(selectedDeviceRef.current?.id);
    const deviceTags = getDeviceById(selectedDeviceRef.current?.id)?.tags || [];
    const updatedPets = pets.map(pet => {
      const tag: DeviceTagModel = deviceTags.find(tag => tag.id === pet.tag_id);
      return {
        pet,
        isAssigned: !!tag,
        tag,
      };
    });
    if (!comeFromRemovePets.current) {
      setUpdatesPetsShared(updatedPets);
    }
    return updatedPets;
  }, [
    comeFromRemovePets,
    getDeviceById,
    pets,
    refreshDeviceTags,
    selectedDeviceRef,
    setUpdatesPetsShared,
  ]);

  const setStepActions = useMemo(
    () => ({
      [StepsEnum.NoProducts]: () => {
        setStep(StepsEnum.NoProducts);
        setLoading(false);
      },
      [StepsEnum.SelectDevice]: () => {
        if (!!selectedDeviceRef.current) {
          return setStepActions[StepsEnum.MorePetFeeder /** step 2*/]();
        }
        loadForceDevice().then(devices => {
          const assignPetDevices = devices.filter(device => AssignPetDevices[device.product_id]);
          if (assignPetDevices.length === 1) {
            const [device] = assignPetDevices;
            if (device.product_id === DeviceType.Cerberus && device.tags.length === 0) {
              const params = existingDevice
                ? {
                    device_id: device?.id,
                    nextPage: {
                      stack: 'HouseholdNavigation',
                      screen: 'Household',
                    },
                  }
                : { device_id: device?.id };

              navigation.dispatch(StackActions.replace('CreatePetProfile', params));
              return;
            } else if (device.product_id !== DeviceType.Cerberus) {
              setSelectedDevice(device);
              return setStepActions[StepsEnum.MorePetFeeder /** step 2*/]();
            }
          }
          setStep(StepsEnum.SelectDevice);
          setLoading(false);
        });
      },
      [StepsEnum.MorePetFeeder]: () => {
        refreshSelectedDevice().then(() => {
          if (
            !(
              selectedDeviceRef.current?.product_id === DeviceType.FeederConnect &&
              selectedDeviceRef.current?.tags?.length > 0 &&
              existingDevice
            )
          ) {
            return setStepActions[StepsEnum.AddPets /**step 3*/]();
          }
          setStep(StepsEnum.MorePetFeeder);
          setLoading(false);
        });
      },
      [StepsEnum.AddPets]: () => {
        refreshSelectedDevice().then(() => {
          //shouldWeJumpFoundPage
          if (
            !stopSkipRef.current &&
            selectedDeviceRef.current?.tags?.length > 0 &&
            !existingDevice
          ) {
            return setStepActions[StepsEnum.FoundPets /**step 4*/]();
          }
          //shouldWeJumpAssignPage
          if (
            !stopSkipRef.current &&
            pets?.length > 0 &&
            !existingDevice &&
            step !== StepsEnum.AddPets
          ) {
            return setStepActions[StepsEnum.AssignPets /**step 6*/]();
          }
          petService.start(() => {
            setStep(StepsEnum.AddPets);
            setLoading(false);
          });
        });
      },
      [StepsEnum.FoundPets]: async () => {
        if (existingDevice) {
          return setStepActions[StepsEnum.AssignPets /**step 6*/]();
        }
        refreshSelectedDevice()
          .then(updateAssignedPets)
          .then(updatePetsShared)
          .then(updatedPets => {
            /** If user on step AddPets(3) added to device existing pet with profile created, and this is only pet that was added, then skipping step FoundPets and UnnamedPets*/
            // According to actual design, step by step instruction: if there were added multiple pets - it is FoundPets screen, this will be the case regardless of whether profiles have already been created for animals
            if (step === StepsEnum.AddPets && foundedPetsTagIds.length === 1) {
              const [id] = foundedPetsTagIds;
              const currPet = updatedPets.find(e => e?.pet?.tag_id === id);
              const { pet, isAssigned } = currPet;
              if (isAssigned && +pet.version > 0) {
                return setStepActions[StepsEnum.AssignPets /**step 6*/]();
              }
            }
            setStep(StepsEnum.FoundPets);
            setLoading(false);
          });
      },
      [StepsEnum.PetsNotNamed]: () => {
        if (unnamedPets === false) {
          return setStepActions[StepsEnum.AssignPets /**step 6*/]();
        }
        setStep(StepsEnum.PetsNotNamed);
        setLoading(false);
      },
      [StepsEnum.AssignPets]: async () => {
        await refreshSelectedDevice();

        const updatedPets = await updatePetsShared();

        // If all the pets are already assigned to the device we don't need to go to the assign screen
        const skipStep = !updatedPets.some(pet => !pet.isAssigned);
        if (skipStep && !comeFromRemovePets.current) {
          comeFromRemovePets.current = false;
          return setStepActions[StepsEnum.IncompatibleChip /**step 7*/]();
        }
        comeFromRemovePets.current = false;

        setStep(StepsEnum.AssignPets);
        setLoading(false);
      },
      [StepsEnum.IncompatibleChip]: () => {
        if (!incompatiblePetAssociatedRef.current) {
          return setStepActions[StepsEnum.RemovePets /**step 8*/]();
        }
        setStep(StepsEnum.IncompatibleChip);
        setLoading(false);
      },
      [StepsEnum.RemovePets]: () => {
        if (
          existingDevice ||
          !(
            selectedDeviceRef.current?.product_id === DeviceType.FeederConnect &&
            unchanged.current > 1
          )
        ) {
          if (selectedDeviceRef.current?.product_id === DeviceType.CatFlapConnect) {
            showPetsAddedRef.current = true;
          }
          return setStepActions[StepsEnum.Success /*step 10*/]();
        }
        setStep(StepsEnum.RemovePets);
        setLoading(false);
      },
      [StepsEnum.IndoorMode]: () => {
        if (selectedDeviceRef.current?.product_id !== DeviceType.CatFlapConnect) {
          return setStepActions[StepsEnum.Success /*step 10*/]();
        }
        setStep(StepsEnum.IndoorMode);
        setLoading(false);
      },
      [StepsEnum.Success]: () => {
        setStep(StepsEnum.Success);
        setLoading(false);
      },
    }),
    [
      setLoading,
      selectedDeviceRef,
      loadForceDevice,
      existingDevice,
      navigation,
      setSelectedDevice,
      refreshSelectedDevice,
      stopSkipRef,
      pets?.length,
      step,
      petService,
      updateAssignedPets,
      updatePetsShared,
      foundedPetsTagIds.length,
      unnamedPets,
      comeFromRemovePets,
      incompatiblePetAssociatedRef,
      unchanged,
      showPetsAddedRef,
    ],
  );
  const handleSetStep = useCallback(
    (step: StepsEnum) => {
      // Set loading to true to show the loading component before each step change.
      setLoading(true);
      setStepActions[step]();
    },
    [setLoading, setStepActions],
  );

  return {
    step,
    handleSetStep,
  };
};

export default useHandleSteps;
