import React, { memo } from 'react';
import { SpView } from '../SpView';
import { Dimensions, StyleSheet } from 'react-native';
import SpGraphicSkeleton from '../SpGraphicSkeleton';

const SpDashbooardSkeleton = () => {
  return (
    <SpView style={styles.container}>
      <SpView style={styles.notificationBlock} />
      <SpView style={styles.titlesContainer}>
        <SpView style={styles.bigTitle} />
        <SpView style={styles.smallTitle} />
      </SpView>
      <SpView style={styles.tabsContainer}>
        <SpView style={styles.tab} />
        <SpView style={styles.tab} />
      </SpView>
      <SpView>
        <SpGraphicSkeleton />
      </SpView>
    </SpView>
  );
};
const CALENDAR_HEIGHT = 252;
const maxHeight = (Dimensions.get('window').height - CALENDAR_HEIGHT) / 7.25;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  notificationBlock: {
    height: maxHeight,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    width: '90%',
    borderRadius: 24,
  },
  titlesContainer: {
    gap: 8,
    alignItems: 'center',
  },
  bigTitle: {
    width: 156,
    height: 28,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    borderRadius: 15,
  },
  smallTitle: {
    height: 20,
    width: 100,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    borderRadius: 15,
  },
  tabsContainer: {
    width: '90%',
    gap: 38,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  tab: {
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    height: maxHeight,
    borderRadius: 24,
    flexGrow: 1,
  },
  smallTab: {
    width: 64,
    height: 28,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    borderRadius: 15,
  },
  smallTabContainer: {
    width: '90%',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

export default memo(SpDashbooardSkeleton);
