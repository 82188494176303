import React, { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { StyleSheet, SafeAreaView, View } from 'react-native';
import SelectButton from './SpSelectButton';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';

type ISelectButtonsWrapperProps = {
  selectedOption: string;
  handleChange: (unit: number) => void;
  optionsEnum: any;
  setButtonDisabled?: Dispatch<SetStateAction<boolean>>;
};

const SelectButtonsWrapper = ({
  optionsEnum,
  selectedOption,
  handleChange,
  setButtonDisabled,
}: ISelectButtonsWrapperProps) => {
  const [currentOption, SetCurrentOption] = useState(selectedOption);
  const options = enumToDescriptedArray(optionsEnum, /-/g);
  const onChange = useCallback(
    (unit: number) => {
      handleChange(unit);
      SetCurrentOption(optionsEnum[unit]);
    },
    [handleChange],
  );
  useEffect(() => {
    if (setButtonDisabled) {
      if (!currentOption || currentOption === selectedOption) {
        return setButtonDisabled(true);
      }
      setButtonDisabled(false);
    }
  }, [setButtonDisabled, currentOption]);

  return (
    <SafeAreaView>
      {options.map(option => {
        const isActive = currentOption === option.name;

        return (
          <SelectButton
            key={option.id}
            option={option}
            setActive={onChange}
            withIcon
            isActive={isActive}
          />
        );
      })}
    </SafeAreaView>
  );
};

type SelectButtonsWrapperWithArrayProps = {
  data: { name: string; id: number }[];
  handleChange: (unit: number) => void;
  selectedOption: number;
};

export const SelectButtonsWrapperWithArray = ({
  data,
  selectedOption,
  handleChange,
}: SelectButtonsWrapperWithArrayProps) => {
  const [currentOption, setCurrentOption] = useState(selectedOption);
  const onChange = useCallback(
    (unit: number) => {
      handleChange(unit);
      setCurrentOption(unit);
    },
    [handleChange],
  );
  return (
    <SafeAreaView>
      {data.map(option => {
        const isActive = currentOption === option.id;

        return (
          <SelectButton
            key={option.id}
            option={option}
            setActive={onChange}
            withIcon
            isActive={isActive}
          />
        );
      })}
    </SafeAreaView>
  );
};

export default SelectButtonsWrapper;
