/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { NotificationEventModel } from '@models/Notification';
import React, { useCallback, useMemo, useState } from 'react';
import { Linking, StyleSheet, TouchableOpacity } from 'react-native';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import CustomHeader from 'src/components/CustomHeader';
import textStyles from '@styles/text';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getDuration } from '@utils/date';
import useBoundStore from 'src/store/store';
import { SafeAreaView } from 'react-native-safe-area-context';
import { testProperties } from '@utils/testProperties';
import { SpFlatList } from 'src/components/SpFlatList';
import { Tabs } from '../Tabs';
import { useFocusEffect } from '@react-navigation/native';
import { WebWrapper } from '../../../../components/WebWrapper';

type DashboardNotificationsProps = {};

const DashboardNotifications: React.FC<DashboardNotificationsProps> = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const tabs = [t('alerts'), t('inbox')];
  const data = useBoundStore(store => store.notificationStore.data);
  const messages = useBoundStore(store => store.notificationStore.messages);
  const loadAll = useBoundStore(store => store.notificationStore.loadAll);
  // const messages = [{title: 'test', link: 'test'}]

  useFocusEffect(
    useCallback(() => {
      loadAll(true);
    }, []),
  );

  const tabsData = useMemo(() => {
    // filter logic
    return [data, messages];
  }, [data]);
  const isInbox = activeTab === 1;
  const renderTab = useCallback(
    (item: number) => {
      return (
        <SpText
          style={styles.tabText}
          color={activeTab === item ? colors.primary.color : colors.greyText.color}
        >
          {tabs[item]}
        </SpText>
      );
    },
    [activeTab],
  );

  const pressHandler = (link: string) => {
    Linking.openURL(link);
  };

  const renderItem = ({ item }: { item: NotificationEventModel }) => {
    if (!item || !item.id || item.id === 0) return null;

    return (
      <TouchableOpacity
        style={[styles.notificationCard, isInbox && styles.notificationCardWithBorder]}
        onPress={() => {
          return null;
        }}
        {...testProperties('opacityButton', `notification${item.id}`)}
      >
        <SpView>
          <SpText
            style={[textStyles.smallTitle, styles.notificationDescription]}
            numberOfLines={isInbox ? 2 : 3}
            {...testProperties('text', `notificationMessage${item.id}`)}
          >
            {item.text}
          </SpText>
          <SpText
            style={[textStyles.smallTitle, styles.notificationTime]}
            {...testProperties('text', `notificationTime${item.id}`)}
          >
            {getDuration(item.created_at.toString(), false)}
          </SpText>
        </SpView>
        {isInbox && (
          <FontAwesomeIcon
            color={colors.greyText.color}
            size={24}
            icon={faChevronRight}
          />
        )}
        {!isInbox && (
          <SpView
            style={styles.devider}
            {...testProperties('view', `notificationDevider${item.id}`)}
          />
        )}
      </TouchableOpacity>
    );
  };
  const messageRenderItem = ({ item }) => {
    if (!item || !item.title || !item.link) return null;

    return (
      <TouchableOpacity
        style={[styles.notificationCard, isInbox && styles.notificationCardWithBorder]}
        onPress={() => pressHandler(item.link)}
        {...testProperties('opacityButton', `notification${item.title}`)}
      >
        <SpView>
          <SpText
            style={[textStyles.smallTitle, styles.notificationDescription]}
            numberOfLines={isInbox ? 2 : 3}
            {...testProperties('text', `notificationMessage${item.title}`)}
          >
            {item.title}
          </SpText>
          <SpText
            style={[textStyles.smallTitle, styles.notificationTime]}
            {...testProperties('text', `notificationTime${item.link}`)}
          >
            {item.link}
          </SpText>
        </SpView>

        <SpView style={styles.linkCard}>
          <FontAwesomeIcon
            color={colors.greyText.color}
            size={24}
            icon={faChevronRight}
          />
        </SpView>
      </TouchableOpacity>
    );
  };
  const header = useMemo(() => {
    return (
      <>
        <CustomHeader
          title={t('notifications')}
          withCross
          withTitle
        />
        <SpView style={styles.spacer} />
        <Tabs
          active={activeTab}
          onChange={setActiveTab}
          data={[0, 1]}
          renderItem={renderTab}
          containerStyle={styles.tabsContainer}
        />
      </>
    );
  }, [activeTab, renderTab]);

  const emptyComponent = useMemo(() => {
    return (
      <SpText
        style={[textStyles.smallTitle, styles.emptyText]}
        {...testProperties('text', 'empty')}
      >
        {t(isInbox ? 'noMessages' : 'noAlerts')}
      </SpText>
    );
  }, [isInbox]);

  return (
    <SafeAreaView style={styles.container}>
      <WebWrapper>
        <SpFlatList
          data={tabsData[activeTab]}
          renderItem={isInbox ? messageRenderItem : renderItem}
          ListEmptyComponent={emptyComponent}
          ListHeaderComponent={header}
          style={styles.flatlist}
          stickyHeaderIndices={[0]}
          keyExtractor={item =>
            isInbox ? `Notifications${item.title.toString()}` : `Notifications${item.id.toString()}`
          }
          {...testProperties('flatlist', 'notifications')}
        />
      </WebWrapper>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  tabsContainer: {
    width: '100%',
  },
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  emptyText: {
    marginTop: 52,
    fontFamily: 'Rubik',
  },
  notificationCard: {
    marginHorizontal: 19,
    marginTop: 16,
  },
  notificationCardWithBorder: {
    borderWidth: 1,
    borderColor: colors.greySmallTitle.color,
    borderRadius: 14,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 19,
    padding: 16,
  },
  flatlist: {
    flex: 1,
    marginBottom: 10,
  },
  notificationDescription: {
    color: colors.greyText.color,
    textAlign: undefined,
    fontSize: 14,
    fontFamily: 'Rubik',
  },
  notificationTime: {
    fontSize: 14,
    marginTop: 4,
    textAlign: undefined,
    fontFamily: 'Rubik',
  },
  spacer: {
    width: '100%',
    height: 20,
    backgroundColor: colors.white.color,
  },
  devider: {
    backgroundColor: 'rgba(38, 58, 67, 0.15)',
    width: '100%',
    height: 1,
    marginTop: 16,
  },
  tabText: {
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
  },
  linkCard: {
    flex: 1,
    alignItems: 'flex-end',
  },
});

export default DashboardNotifications;
