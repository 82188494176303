import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const TimelineActiveSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    {...props}>
    <G
      fill="#03A2B1"
      fillRule="evenodd"
      stroke="#03A2B1"
      strokeWidth={0.5}>
      <Path d="M15.147 25.5a11.31 11.31 0 0 1-9.342-4.944.954.954 0 0 1-.134-.72.95.95 0 0 1 1.727-.323 9.417 9.417 0 0 0 7.718 4.075c4.351-.005 8.117-2.975 9.158-7.222 1.245-5.082-1.854-10.236-6.91-11.487A9.434 9.434 0 0 0 15.1 4.6 9.456 9.456 0 0 0 9.325 6.59a9.397 9.397 0 0 0-3.386 5.235l-.061.249 1.785-1.21a.944.944 0 0 1 1.365.267.954.954 0 0 1 .12.72.95.95 0 0 1-.423.596l-3.463 2.345a.969.969 0 0 1-.72.138 1.036 1.036 0 0 1-.603-.408l-2.305-3.513a.953.953 0 0 1-.155-.797.949.949 0 0 1 1.738-.257l.892 1.357.055-.216C5.444 6.06 9.947 2.526 15.117 2.5a11.295 11.295 0 0 1 8.03 3.394 11.418 11.418 0 0 1 3.302 8.105 11.38 11.38 0 0 1-4.864 9.472 11.204 11.204 0 0 1-6.437 2.029h-.001Z" />
      <Path d="M18.161 18.144a.956.956 0 0 1-.676-.278l-3.145-3.19a.996.996 0 0 1-.28-.678V8.675c0-.527.427-.956.951-.956s.95.429.95.956v4.932l2.875 2.915a.96.96 0 0 1-.006 1.352.956.956 0 0 1-.669.27" />
    </G>
  </Svg>
);
export default TimelineActiveSVG;
