import { DeviceFeederTypeUpdated } from '@models/Device';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useLastDate = (
  events: DeviceFeederTypeUpdated,
  key: keyof DeviceFeederTypeUpdated['bowl_status'][0],
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const lastFilledData = events?.bowl_status?.map(elem => elem[key]).filter(elem => elem);
    if (!lastFilledData || lastFilledData?.length === 0) {
      return t('unknown');
    }
    let maxDate = 0;
    for (let i = 0; i < lastFilledData.length; i++) {
      const date = +new Date(lastFilledData[i]);
      if (maxDate < date) {
        maxDate = date;
      }
    }
    return maxDate;
  }, [events]);
};

export default useLastDate;
