import React from 'react'
import { useTranslation } from 'react-i18next';
import { getTimelineDeviceName } from '../../../hooks/useTimelineDeviceName';
import { TimelineCard } from '../TimelineCard';
import TimelineDevicePhoto from '../TimelineDevicePhoto';
import { TimelineEventModel } from '@models/Timeline';

type ITarringOccurredProps = {
  active: boolean;
  event: TimelineEventModel;
};

const TarringOccurred = ({active, event}: ITarringOccurredProps) => {
  const { t } = useTranslation();
  const deviceName = getTimelineDeviceName(event?.devices);
  return (
    <TimelineCard
      active={active}
      date={event.created_at}
      image={
        <TimelineDevicePhoto
          active={active}
          event={event}
        />
      }
      text={t('tarring_occurred', {
        devicename: deviceName,
      })}
    />
  );
}

export default TarringOccurred;
