import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const FeedingIconSVG = ({ color, width = 24, height = 24, ...props }: any) => (
  <Svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 24 24`}>
    <G
      id="feeding-icon-active"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd">
      <G
        id="Amount-Drunk-Icon-Copy-2"
        transform="translate(2.25, 0)"
        fill={color || '#03A2B1'}
        fill-rule="nonzero">
        <G
          id="cutlery"
          transform="translate(0, 0)">
          <Path
            d="M9,8.00009766 C9,3.58201172 6.98337891,0 4.5,0 C2.01568359,0 0,3.58201172 0,8.00009766 C0,9.92279297 1.21201172,11.5518164 2.90818359,12.195293 L2.49990234,21.9999023 C2.49990234,23.1045117 3.39539062,24 4.5,24 C5.60460937,24 6.50009766,23.1045117 6.50009766,21.9999023 L6.09181641,12.1961719 C7.78921875,11.5518164 9,9.92279297 9,8.00009766 Z"
            id="Path"
          />
          <Path
            d="M19.5,7.25009766 L19.2500977,0 L18,0 L17.7500977,7.25009766 L16.7499023,7.25009766 L16.5,0 L15,0 L14.7500977,7.25009766 L13.7499023,7.25009766 L13.5,0 L12.2499023,0 L12,7.25009766 C12,8.77529297 12.9141211,10.0818164 14.2226953,10.6679883 L13.7499023,21.9999023 C13.7499023,23.1045117 14.6453906,24 15.75,24 C16.8546094,24 17.7500977,23.1045117 17.7500977,21.9999023 L17.2773047,10.6679883 C18.5858789,10.0818164 19.5,8.77529297 19.5,7.25009766 Z"
            id="Path"
          />
        </G>
      </G>
    </G>
  </Svg>
);
export default FeedingIconSVG;
