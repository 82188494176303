import { useNavigation } from '@react-navigation/native';
import persistStorage from '@utils/persistStorage';
import useBoundStore from '../store/store';
import { AnalyticsService } from '../services/AnalyticsService';

interface IUseTour {
  uniqueId: string;
  components: JSX.Element[];
  devices?: string[];
  countId?: number;
  showSharedSlideOneTimeIndex?: {
    tourGroup: string;
    slideIndexToHide: number;
  };
}

const useTour = (props: IUseTour) => {
  const setCompletedTours = useBoundStore(s => s.tourStore.setCompletedTours);
  const addCompletedTours = useBoundStore(s => s.tourStore.addCompletedTours);
  const addDashboardCount = useBoundStore(s => s.tourStore.addDashboardCount);

  const navigation = useNavigation();

  const checkIsCompletedTour = async () => {
    await AnalyticsService.logEvent('useTour - checkIsCompletedTour');
    const countId: string = props?.countId ? `-${props.countId}` : '';
    let componentsArray = props.components;
    const completedToursFromStorage = await persistStorage.getItem('COMPLETED_TOURS');
    setCompletedTours(completedToursFromStorage);
    if (completedToursFromStorage?.[`${props.uniqueId}${countId}`]) return;

    if (props?.showSharedSlideOneTimeIndex?.tourGroup) {
      const tourGroupFromStorage = await persistStorage.getItem('TOUR_GROUP');

      if (tourGroupFromStorage?.[props.showSharedSlideOneTimeIndex.tourGroup]) {
        console.log(1);
        componentsArray = componentsArray.filter(
          (item, index) =>
            index !==
            tourGroupFromStorage[props.showSharedSlideOneTimeIndex.tourGroup].slideIndexToHide,
        );
      } else if (!tourGroupFromStorage) {
        console.log(2);
        await persistStorage.setItem('TOUR_GROUP', {
          [props.showSharedSlideOneTimeIndex.tourGroup]: {
            slideIndexToHide: props.showSharedSlideOneTimeIndex.slideIndexToHide,
          },
        });
      } else {
        console.log(3);
        await persistStorage.setItem('TOUR_GROUP', {
          ...tourGroupFromStorage,
          ...{
            [props.showSharedSlideOneTimeIndex.tourGroup]: {
              slideIndexToHide: props.showSharedSlideOneTimeIndex.slideIndexToHide,
            },
          },
        });
      }
    }
    addCompletedTours(`${props.uniqueId}${countId}`);
    navigation.navigate('Tour', { components: componentsArray });
    if (props?.countId) {
      addDashboardCount();
    }
  };

  if (!props) return () => console.log('No Tours data');

  if (!props?.uniqueId) return () => console.log('Tours Error unique ID');

  return checkIsCompletedTour;
};

export default useTour;
