import { SpProfileImage } from 'src/components/SpProfileImage';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { TimelineEventModel } from '@models/Timeline';
import colors from '@styles/colors';
import { get } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import WCIAlertInfo from '../../../../components/SvgIcons/WCIAlertInfo';

interface Props {
  active: boolean;
  event: TimelineEventModel;
  isIntruder?: boolean;
  alert?: boolean;
}

export const TimelinePetPhoto = ({ active, event, isIntruder = false, alert = false }: Props) => {
  const pets = usePetsByHousehold();

  const petId = useMemo(() => {
    return get(event, 'pets[0].id', null);
  }, [event]);

  const currentPet = useMemo(() => {
    return pets.find(item => item.id === petId);
  }, [pets, petId]);

  return (
    <View style={styles.wrapper}>
      <SpProfileImage
        backgroundColor="#dde0e2"
        imageUrl={isIntruder ? '' : currentPet?.photo?.location}
        width={active ? 56 : 48}
        height={active ? 56 : 48}
        withBorder={active || alert}
        borderWidth={2}
        borderColor={alert ? colors.errorRed.color : colors.green.color}
      />
      {alert && (
        <View style={styles.icon}>
          <WCIAlertInfo />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    right: -5,
    top: -5,
  },
});
