import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { MovementReportDataModel } from '@models/ReportModel';
import { useMemo } from 'react';

import { TimeService } from '../../../services/TimeService';

export function useTransformMovementEventToReport(data: MovementReportDataModel, timezone: string) {
  const userTimeFormat = useUserTimeUnits();
  const { concatTwoDigitFormatToOne, convertSecondToTwoDigitFormat } = useTimeTransform();
  return useMemo(() => {
    const duration = concatTwoDigitFormatToOne(convertSecondToTwoDigitFormat(data.duration));
    const start = TimeService.toLocal(data.from).toFormat('HH:mm');
    const end = TimeService.toLocal(data.to).toFormat('HH:mm');

    return {
      start,
      end,
      duration,
    };
  }, [data, userTimeFormat]);
}
