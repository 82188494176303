import { PetStatisticsModel } from '@models/Device';

import Http from './Http';
import { PetModel } from '../models/Pet';
import { ConsumptionInsightApiResponse } from '@models/ConsumptionInsight';
import { DateTime } from 'luxon';

class PetApi {


  static getPets(): Promise<PetModel[]> {
    return Http.get(`/api/pet`,{}).then(response => response.data.data);
  }

  static getPetStatistics(
    petIds: number[],
    from: string,
    daysInHistory?: number,
  ): Promise<PetStatisticsModel[]> {
    return Http.get(
      `/api/dashboard/pet?Pet_Id=${petIds.join('&Pet_Id=')}&From=${from}${
        daysInHistory ? `&dayshistory=${daysInHistory}` : ''
      }`,
      {

      },
    ).then(response => {
      return response.data.data;
    });
  }

  static createPet(pet: PetModel): Promise<PetModel[]> {
    return Http.post(`/api/pet`, pet, {
    }).then(response => response.data.data);
  }

  static updatePet(petId: number, pet: PetModel): Promise<PetModel[]> {
    return Http.put(`/api/pet/${petId}`, pet, {
    }).then(response => response.data.data);
  }

  static deletePet(petId: number): Promise<PetModel[]> {
    return Http.delete(`/api/pet/${petId}`).then(response => response.data.data);
  }

  static updatePetPosition(
    petId: number,
    position: { where: number; since: string },
  ): Promise<PetModel[]> {
    return Http.post(`/api/pet/${petId}/position`, position, {
    }).then(response => response.data.data);
  }

  static getPetInsights(
    petId: number,
    to: DateTime,
    days?: number,
  ): Promise<ConsumptionInsightApiResponse> {
    const from = to.minus({ days }).startOf('day');

    return Http.get(`/api/pet/${petId}/insight?&From=${from.toISO()}&To=${to.toISO()}`).then(
      res => res.data.data,
    );
  }
}

export default PetApi;
