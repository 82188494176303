import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { SpRoundIcon } from './SpRoundIcon';

interface SpMenuIconProps {
  icon: IconProp;
  action: () => void;
}
export const SpMenuIcon = ({ action, icon = faUser }: SpMenuIconProps) => {
  return (
    <SpRoundIcon
      icon={
        <FontAwesomeIcon
          color="white"
          size={12}
          icon={icon}
        />
      }
      action={action}
      size={7}
      componentName="SpMenuIcon"
    />
  );
};
