/* eslint-disable react/jsx-no-useless-fragment */

import { DeviceFeederBowlType, DeviceFeederTareType, DeviceStatusIDs } from '@constants/Device';
import { getZeroScalesActions } from '@constants/ZeroScalesActions';
import { DeviceFeederTypeUpdated, DeviceModel } from '@models/Device';
import colors from '@styles/colors';
import { getSingleDuration } from '@utils/date';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { SpVStack } from 'src/components/SpVStack';
import { SpView } from 'src/components/SpView';
import { SpZeroScalesActions, ZeroScalesActionModel } from 'src/components/SpZeroScalesActions';
import { SpZeroScalesUpdateStatusMessage } from 'src/components/SpZeroScalesUpdateStatusMessage';

import LoadingSpinner from '../../../../../../components/Loader/Loader';
import { MergedInterfaces } from '../../../../../../store/models';
import useBoundStore from '../../../../../../store/store';
import useLastDate from '../../hooks/useLastDate';
import DeviceApi from '@api/DeviceApi';

interface ZeroScalesProps {
  deviceId: number;
  type: DeviceFeederBowlType;
  events: DeviceFeederTypeUpdated;
  device: DeviceModel;
}

export const ZeroScales = ({ deviceId, type, events, device }: ZeroScalesProps) => {
  const {
    asyncRequestState,
    updateZeroScalesLoading,
    updateZeroScalesError,
    updateZeroScalesSuccess,
    updateZeroScalesAsync,
    refreshUpdateZeroScalesStatus,
    updateDevice,
    lastZeroBowlTime,
    setLastZeroBowlTime,
    loadingControl,
  } = useBoundStore(({ deviceStore }: MergedInterfaces) => {
    return {
      asyncRequestState: deviceStore.asyncRequestState,
      updateZeroScalesError: deviceStore.updateZeroScalesError,
      updateZeroScalesLoading: deviceStore.updateZeroScalesLoading,
      updateZeroScalesSuccess: deviceStore.updateZeroScalesSuccess,
      updateZeroScalesAsync: deviceStore.updateZeroScalesAsync,
      refreshUpdateZeroScalesStatus: deviceStore.refreshUpdateZeroScalesStatus,
      updateDevice: deviceStore.updateDevice,
      lastZeroBowlTime: deviceStore.lastZeroBowlTime,
      setLastZeroBowlTime: deviceStore.setLastZeroBowlTime,
      loadingControl: deviceStore.loadingControl,
    };
  });
  const [updatedDevice, setUpdatedDevice] = useState(null);
  const [localLoader, setLocalLoader] = useState(false);

  const { activeHousehold } = useBoundStore(s => s.householdStore);

  useEffect(() => {
    refreshUpdateZeroScalesStatus(deviceId);
  }, [asyncRequestState[deviceId]]);

  const lastZeroed = useLastDate(updatedDevice?.status || events, 'last_zeroed_at');

  const actions: ZeroScalesActionModel[] = useMemo(() => {
    return getZeroScalesActions(type);
  }, [type]);

  const onSelect = (bowlType: DeviceFeederTareType) => {
    const fetch = async () => {
      setLocalLoader(true);
      await updateZeroScalesAsync(deviceId, bowlType);
      setLastZeroBowlTime(+new Date());
    };

    if (!updateZeroScalesLoading) {
      fetch();
    }
  };

  useEffect(() => {
    const upd = async () => {
      const devices = await DeviceApi.getDevices(activeHousehold?.id);
      const currentFeeder = devices.filter(elem => elem.id === deviceId)[0];
      setUpdatedDevice(currentFeeder);
      await updateDevice(currentFeeder);
      setLocalLoader(false);
    }
    if (!loadingControl.tare) {
      upd()
    }
  }, [loadingControl.tare])

  // console.log(localLoader)

  return (
    <SpVStack
      space={8}
      style={styles.wrapper}>
      {(updateZeroScalesLoading || localLoader || loadingControl.tare) && (
        <SpView alignItems="center">
          <LoadingSpinner />
        </SpView>
      )}

      <SpZeroScalesUpdateStatusMessage
        loading={updateZeroScalesLoading}
        localLoader={localLoader}
        success={!!updateZeroScalesSuccess}
        error={updateZeroScalesError?.error}
        lastZeroed={
          typeof lastZeroed === 'string'
            ? lastZeroed
            : getSingleDuration(new Date(lastZeroed).toISOString())
        }
        convertedLastZeroed={lastZeroBowlTime}
      />

      <SpZeroScalesActions
        buttons={actions}
        onSelect={onSelect}
      />
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    backgroundColor: colors.secondary4.color,
    borderRadius: 16,
  },
  bowlImage: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  images: {
    flexDirection: 'row',
    marginLeft: 14,
  },
  description: {
    marginTop: 16,
    marginBottom: 30,
  },
  wrapper: {
    position: 'relative',
  },
  rowSuccessContainer: {
    flexDirection: 'row',
    width: '100%',
    gap: 20,
  },
  successMsg: {
    fontSize: 20,
    color: colors.greyText.color,
    fontFamily: 'Rubik_Medium',
  },
  successDesc: {
    marginTop: 4,
  },
});
