import React, { useRef, useState } from 'react';
import { PetService } from '../../../../../services/PetService';
import noop from '@utils/noop';
import { TagModel } from '@models/Tag';
import useBoundStore from '../../../../../store/store';
import { PetModel } from '@models/Pet';
import { useTranslation } from 'react-i18next';
import { useCustomToast } from '@hooks/useToast';
import { DeviceModel } from '@models/Device';
import { DeviceType } from '@constants/Device';

type usePetServiceProps = {
  existingDevice: boolean;
  onNewTagFound?: (newTag: TagModel) => void;
  handleNewPet?: (pet: PetModel) => void;
  maxRetriesHandler: (value: boolean) => void;
  selectedDeviceRef: React.MutableRefObject<DeviceModel>;
  onRetry?: () => void;
  tryTimesMax?: number;
  onTryTimesExceeded?: () => void;
};

const usePetService = ({
  existingDevice,
  onNewTagFound,
  handleNewPet,
  maxRetriesHandler,
  selectedDeviceRef,
  onRetry,
  tryTimesMax = 2,
  onTryTimesExceeded,
}: usePetServiceProps) => {
  const { t } = useTranslation();
  const toast = useCustomToast();
  const { fetchAndGetPetByTagId } = useBoundStore(({ petStore }) => ({
    fetchAndGetPetByTagId: petStore.fetchAndGetPetByTagId,
  }));
  const [tryTimes, setTryTimes] = useState(0);

  const petServiceRef = useRef<PetService>(
    new PetService({
      onError: noop,
      timeoutHandler: () => null,
      onFound: async (newTag: TagModel) => {
        onNewTagFound?.(newTag);
        fetchAndGetPetByTagId(newTag.id).then((pet) => {
          if (pet?.id) {
            handleNewPet?.(pet);
          }
          if (selectedDeviceRef?.current?.product_id === DeviceType.FelaquaConnect) {
            petServiceRef.current.start(selectedDeviceRef?.current);
          }
        })
        toast.show({ description: t('pet_found') });
      },
      multipleSearch: !existingDevice,
      maxRetriesHandler: maxRetriesHandler,
    }),
  );
  return {
    maxRetriesHandler: petServiceRef.current?.maxRetriesHandler,
    start: (fn?: () => void) => {
      petServiceRef.current?.start(selectedDeviceRef.current).then(() => fn?.());
    },
    stop: (fn?: () => void) => {
      petServiceRef.current?.stop(selectedDeviceRef.current).then(() => fn?.());
    },
    retry: () => {
      if (tryTimes > tryTimesMax) {
        petServiceRef.current.stop(selectedDeviceRef.current).then(() => {
          onTryTimesExceeded?.();
        });
        return;
      }
      petServiceRef.current?.maxRetriesHandler(false);
      setTryTimes(prev => prev + 1);
      onRetry?.();
      petServiceRef.current?.start(selectedDeviceRef.current);
    },
    tryTimes,
    resetTryTimes: () => setTryTimes(0),
  };
};

export default usePetService;
