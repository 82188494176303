import { FeederFoodType } from '@constants/Device';
import { MappedConsumptionReportDataModel } from '@models/ReportModel';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransformFeedingEventToReport } from '../hooks/useTransformFeedingEventToReport';
import { EventDetailsCard } from './EventDetailsCard';
import useBoundStore from '../../../store/store';

interface FeedingPointReportProps {
  data: MappedConsumptionReportDataModel;
}

export const FeedingEventReport = ({ data }: FeedingPointReportProps) => {
  const { t } = useTranslation();
  const item = useTransformFeedingEventToReport(data);
  const { getDeviceById } = useBoundStore(s => s.deviceStore);

  item.duration = data.duration < 60 ? `00:${item.duration}` : item.duration;

  const weightColumn = useMemo(() => {
    if (item.leftBowl.bowlCount === 2) {
      return {
        amountLabel: `${t('amount')}`,
        label: `${t(FeederFoodType[item.leftBowl.type])} / ${t(FeederFoodType[item.rightBowl.type])}`,
        value: `${item.leftBowl.value || 0} / ${item.rightBowl.value || 0}`,
      };
    } else if (item.leftBowl.bowlCount === 1) {
      return {
        label: `${t('amount')}`,
        value: item.leftBowl.value,
      };
    } else {
      return {
        label: `${t(FeederFoodType[1])} / ${t(FeederFoodType[2])}`,
        value: '0 / 0',
      };
    }
  }, [item]);

  return (
    <EventDetailsCard
      leftLabel={t('time')}
      leftValue={item.time}
      centerTopLabel={weightColumn?.amountLabel}
      centerLabel={weightColumn.label}
      centerValue={weightColumn.value}
      rightLabel={t('duration')}
      rightValue={item.duration}
      deviceName={getDeviceById(data.device_id)?.name || ''}
    />
  );
};
