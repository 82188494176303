import React, { memo, useCallback, useMemo } from 'react';
import { FlatList, ListRenderItem, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import { testProperties } from '@utils/testProperties';
import { DeviceModel } from '@models/Device';
import { DeviceType } from '@constants/Device';
import text from '@styles/text';
import { SpView } from '../../../../../../components/SpView';
import { SpText } from '../../../../../../components/SpText/SpText';
import SelectDeviceItemDisabled from './SelectDeviceItemDisabled';
import SelectDeviceItem from './SelectDeviceItem';

type SelectDeviceProps = {
  onItemSelected: (item: DeviceModel) => () => void;
};

type DeviceForRender = {
  device: DeviceModel;
  disabled: boolean;
};

const SelectDevice = ({ onItemSelected }: SelectDeviceProps) => {
  const { t } = useTranslation();
  const devices = useDevicesByHousehold();

  const shouldDisableDevice = useCallback((device: DeviceModel) => {
    return !!(device?.product_id === DeviceType.Cerberus && device?.tags?.length);
  }, []);

  const devicesForRender: DeviceForRender[] = useMemo(() => {
    const devicesWithoutHub = devices.filter(device => device?.product_id !== DeviceType.Hub);
    return devicesWithoutHub.map(device => ({ device, disabled: shouldDisableDevice(device) }));
  }, [devices, shouldDisableDevice]);

  const renderItem: ListRenderItem<DeviceForRender> = useCallback(
    ({ item }) => {
      return item.disabled ? (
        <SelectDeviceItemDisabled item={item.device} />
      ) : (
        <SelectDeviceItem
          item={item.device}
          onItemPress={onItemSelected}
        />
      );
    },
    [onItemSelected],
  );

  return (
    <SpView>
      <SpView>
        <SpText
          {...testProperties('ChooseProduct', 'text')}
          accessible={false}
          style={[styles.title]}
        >
          {t('choose_a_product')}
        </SpText>
      </SpView>
      <SpView style={styles.listContainer}>
        <FlatList
          data={devicesForRender}
          renderItem={renderItem}
          keyExtractor={item => `${item.device.id}`}
          {...testProperties('flatList', 'items')}
          accessible={false}
        />
      </SpView>
    </SpView>
  );
};

export default memo(SelectDevice);

const styles = StyleSheet.create({
  title: {
    ...text.title,
    paddingTop: 20,
    paddingBottom: 30,
  },
  listContainer: {
    paddingBottom: 100,
    flex: 1,
  },
});
