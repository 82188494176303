import { WeightUnits } from '@constants/WeightUnits';
import SpTitle from 'src/components/SpTitle';
import React, { useCallback, useState } from 'react';
import { StyleSheet, SafeAreaView, View } from 'react-native';
import SelectButton from 'src/components/SpSelectButton';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import { useTranslation } from 'react-i18next';

export interface AccountUnitProps {
  selectedWeight: string;
  handleChange: (unit: number) => void;
}

const AccountWeightWrapper = ({ selectedWeight, handleChange }: AccountUnitProps) => {
  const [weightUnit, setWeightUnit] = useState(selectedWeight);
  const units = enumToDescriptedArray(WeightUnits);
  const onUnitChange = useCallback((unit: number) => {
    handleChange(unit);
    setWeightUnit(WeightUnits[unit]);
  }, []);
  const { t } = useTranslation();
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.inner}>
        <SpTitle text={t('units')} />
        {units.map(unit => {
          const isActive = weightUnit === unit.name;
          return (
            <SelectButton
              key={unit.id}
              option={unit}
              setActive={onUnitChange}
              withIcon
              isActive={isActive}
            />
          );
        })}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inner: {
    flex: 1,
    paddingHorizontal: 20,
  },
});

export default AccountWeightWrapper;
