import { DeviceTimelineImages } from '@constants/DeviceImages';
import { useDeviceProductNameById } from '@hooks/useDeviceProductNameById';
import { TimelineEventModel } from '@models/Timeline';
import { get } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TimelineCard } from './TimelineCard';
import { TimelineCardImage } from './TimelineCardImage';
import TimelineDevicePhoto from './TimelineDevicePhoto';

export const NewDevice = ({ event, active }: { event: TimelineEventModel; active: boolean }) => {
  const { t } = useTranslation();
  const productName = useDeviceProductNameById(get(event, 'devices[0].product_id', null));

  return (
    <TimelineCard
      active={active}
      image={
        <TimelineDevicePhoto
          active={active}
          event={event}
        />
      }
      text={t('timeline_product_added', {
        productname: productName,
      })}
      date={event.created_at}
    />
  );
};
