import { withLenses } from '@dhmk/zustand-lens';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { create as _create, StateCreator } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { MergedInterfaces } from './models';
import createAccountSlice from './modules/AccountStore';
import createBreedSlice from './modules/BreedStore';
import createConditionSlice from './modules/ConditionStore';
import createCountrySlice from './modules/CountryStore';
import createDeviceSlice from './modules/DeviceStore';
import createHouseholdInviteSlice from './modules/HouseholdInviteStore';
import createHouseholdSlice from './modules/HouseholdStore';
import createHouseholdUserSlice from './modules/HouseholdUserStore';
import createInviteSlice from './modules/InviteStore';
import createLanguageSlice from './modules/LanguageStore';
import createNotificationSlice from './modules/NotificationStore';
import createPetSlice from './modules/PetStore';
import createTagSlice from './modules/TagStore';
import createProductSlice from './modules/ProductStore';
import createReportSlice from './modules/ReportStore';
import createTimelineSlice from './modules/TimelineStore';
import createTimezoneSlice from './modules/TimezoneStore';
import createUISlice from './modules/UIStore';
import createWiFiDeviceSlice from './modules/WiFiDeviceStore';
import createRetrySlice from './modules/RetryStore';
import createTourSlice from './modules/TourStore';

const resetters: (() => void)[] = [];

// @ts-ignore
export const storeSlices = withLenses((...a) => ({
  // @ts-expect-error: Types should be updated
  ...createPetSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createTagSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createHouseholdSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createDeviceSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createAccountSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createCountrySlice(...a),
  // @ts-expect-error: Types should be updated
  ...createTimelineSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createTimezoneSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createHouseholdInviteSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createHouseholdUserSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createUISlice(...a),
  // @ts-expect-error: Types should be updated
  ...createInviteSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createNotificationSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createLanguageSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createReportSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createConditionSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createBreedSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createWiFiDeviceSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createRetrySlice(...a),
  // @ts-expect-error: Types should be updated
  ...createProductSlice(...a),
  // @ts-expect-error: Types should be updated
  ...createTourSlice(...a),
}));

const create = (<T>(f: StateCreator<T> | undefined) => {
  if (f === undefined) return create;
  const store = _create(f);
  const initialState = store.getState();
  resetters.push(() => {
    store.setState(initialState, true);
  });
  return store;
}) as typeof _create;

const useBoundStore = create<MergedInterfaces>()(
  // @ts-ignore
  persist(storeSlices, {
    name: 'store',
    storage: createJSONStorage(() => AsyncStorage),
    // @ts-ignore
    merge: (persistedState, currentState) => {
      return Object.fromEntries(
        Object.entries(currentState).map(([key, value]) => [
          key,
          {
            // @ts-ignore
            ...currentState[key],
            // @ts-ignore
            ...persistedState[key],
          },
        ]),
      );
    },
  }),
);

export const resetAllStores = () => {
  resetters.forEach(resetter => {
    resetter();
  });
};

export default useBoundStore;
