import { DeviceEventEmitter, Pressable, StyleSheet, Text } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import SpModal from 'src/components/SpModal';
import colors from '@styles/colors';
import { closeModal } from 'src/services/ModalBox';
import * as Clipboard from 'expo-clipboard';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';

interface IMicrochipNumberModalProps {
  value: string;
}

const MicrochipNumberModal = ({ value }: IMicrochipNumberModalProps) => {
  const [textCopied, setTextCopied] = useState(false);

  const { t } = useTranslation();

  const deactivateButton = useCallback(async () => {
    setTextCopied(false);
  }, []);

  const copyToClipboard = useCallback(async () => {
    await Clipboard.setStringAsync(value);
    setTextCopied(true);
  }, [value]);

  return (
    <SpModal
      modalName="MicrochipNumber"
      backdropColor="transparent"
      onModalCloseHandler={deactivateButton}>
      <Pressable
        {...testProperties('MicrochipNumberModal', 'modal')}
        accessible={false}
        onPress={() => {
          closeModal('MicrochipNumber');
          deactivateButton();
        }}
        style={styles.modalWrap}>
        <Pressable
          accessible={false}
          style={styles.modalContent}>
          <Text
            {...testProperties(t('microchip_number'), 'text')}
            style={styles.modalTitle}>
            {t('microchip_number')}
          </Text>
          <Text
            {...testProperties(value, 'text')}
            style={styles.modalNumber}>
            {value}
          </Text>
          <Pressable
            {...testProperties(textCopied ? t('copied') : t('copy'), 'button')}
            onPress={copyToClipboard}
            style={styles.modalButton}>
            <Text
              {...testProperties(textCopied ? t('copied') : t('copy'), 'text')}
              style={styles.modalButtonText}>
              {textCopied ? t('copied') : t('copy')}
            </Text>
          </Pressable>
        </Pressable>
      </Pressable>
    </SpModal>
  );
};

export default MicrochipNumberModal;

const styles = StyleSheet.create({
  modalWrap: {
    paddingHorizontal: 19,
    width: '100%',
    marginTop: 64,
    backgroundColor: colors.white.color,
  },
  modalContent: {
    backgroundColor: colors.greyText.color,
    borderRadius: 10,
    height: 144,
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 24,
  },
  modalTitle: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    lineHeight: 24,
    color: colors.white.color,
  },
  modalNumber: {
    fontFamily: 'Rubik',
    fontSize: 20,
    lineHeight: 28,
    marginTop: 8,
    color: colors.white.color,
  },
  modalButton: {
    borderRadius: 14,
    backgroundColor: colors.white.color,
    marginTop: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 7,
  },
  modalButtonText: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
  },
});
