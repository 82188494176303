import { SpView } from 'src/components/SpView';
import { DeviceFeederBowlModel } from '@models/Device';
import React from 'react';
import { StyleSheet } from 'react-native';
import { RemainingFood } from './RemainingFood';
import { SingleBowlType } from './SingleBowlType';
import { SpText } from '../../../../../../components/SpText/SpText';
import { FeederFoodType } from '@constants/Device';
import { t } from 'i18next';
import { BowlTypeSide } from './BowlTypeSide';
import { useTranslation } from 'react-i18next';

interface BowlSingleProps {
  data: DeviceFeederBowlModel;
  percentage: number;
}

export const BowlSingle = ({ data, percentage }: BowlSingleProps) => {
  const { t } = useTranslation();

  return (
    <SpView alignItems="center">
      <SpView width="100%">
        <SingleBowlType
          data={data?.settings[0]}
          percentage={percentage}
        />
        <SpView style={[styles.label, { transform: [{ translateX: -70 }] }]}>
          <RemainingFood />
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  card: {
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  label: {
    position: 'absolute',
    top: 45,
    left: '50%',
  },
});
