import EssenceCreated from 'src/components/EssenceCreated';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { DeviceType } from '@constants/Device';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import i18 from '@utils/i18n';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { RootStackParamList } from '../../index';
import useBoundStore from '../../../store/store';

interface CompleteSetupProductProps {
  productId: DeviceType;
  loaded?: boolean;
  showFooter?: boolean;
  onLoadedCallback?: (arg: (arg: boolean) => void) => void;
  deviceId?: number;
  hideButton?: boolean;
  onPress?: () => void;
  disableAddBtn?: boolean;
  hideLink?: boolean;
}

export const CompleteSetupProduct = ({
  productId,
  loaded = true,
  showFooter = true,
  onLoadedCallback = null,
  deviceId,
  hideButton = false,
  onPress,
  disableAddBtn = false,
  hideLink = false,
}: CompleteSetupProductProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<any>>();
  const navigationRoot = useNavigation<NavigationProp<RootStackParamList>>();
  const deviceStoreData = useBoundStore(state => ({
    productSetupSelection: state.deviceStore.productSetupSelection,
  }));

  const link = useMemo(() => {
    if (productId === DeviceType.Hub) {
      return {
        disabled: true,
        text: t('lets_add_product_now'),
      };
    }
    /* if (productId === DeviceType.FelaquaConnect) {
      return {
        disabled: true,
        text: '',
      };
    } */
    if (productId === DeviceType.Cerberus) {
      return {
        disabled: true,
        text: t('lets_add_pet_now'),
      };
    }
    return {
      navigate: () =>
        navigation.navigate('AddPets', {
          deviceId,
          deviceType: productId,
          step: 3,
        }),
      text: t('lets_add_pet_now'),
    };
  }, [productId]);

  const text = useMemo(() => {
    switch (productId) {
      case DeviceType.PetDoorConnect:
        return i18.t('setup_pet_door_added');
      case DeviceType.CatFlapConnect:
        return i18.t('setup_cat_flap_added');
      case DeviceType.FeederConnect:
        return i18.t('setup_feeder_added');
      case DeviceType.FelaquaConnect:
        return i18.t('setup_felaqua_added');
      case DeviceType.Hub:
        return i18.t('setup_hub_added');
      case DeviceType.Cerberus:
        return i18.t('setup_bowl_added');
      default:
        return null;
    }
  }, [productId]);

  const pressHandler = () => {
    let nav: string;
    if (productId === DeviceType.Hub) {
      switch (deviceStoreData.productSetupSelection) {
        case DeviceType.FelaquaConnect:
          nav = 'AddFelaqua';
          break;
        case DeviceType.FeederConnect:
          nav = 'AddFeeder';
          break;
        case DeviceType.CatFlapConnect:
          nav = 'AddCatFlap';
          break;
        case DeviceType.PetDoorConnect:
          nav = 'AddPetDoor';
          break;
        default:
          nav = 'SelectProduct';
          break;
      }

      navigation.reset({
        index: 1,
        routes: [
          { name: 'DashboardNavigation', params: { screen: 'Pets' } },
          {
            name: 'FlowNavigation',
            params: { screen: nav },
          },
        ],
      });
    } else if (link.navigate) {
      link.navigate();
    } else {
      navigationRoot.navigate('DashboardNavigation', {
        screen: 'Products',
      });
    }
  };

  return (
    <SpView style={productId === DeviceType.Cerberus ? styles.cdbSetupContainer : styles.container}>
      <EssenceCreated
        msg={text}
        initialValue={loaded}
        onSubmit={onLoadedCallback}
      />

      {loaded && showFooter ? (
        <SpView>
          {hideLink === false && (
            <SpText
              style={productId === DeviceType.Cerberus ? styles.cdbSetupLink : styles.link}
              size="xl">
              {link.text}
            </SpText>
          )}

          {!hideButton && (
            <SpRoundedHeaderButton
              title={t('continue')}
              onPress={onPress || pressHandler}
              backgroundColor={colors.primary.color}
              h={56}
              w="90%"
              stylesForContainer={{
                alignItems: 'center',
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
          )}
        </SpView>
      ) : null}
    </SpView>
  );
};

const styles = StyleSheet.create({
  cdbSetupContainer: {
    flex: 1,
    paddingTop: 80,
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
    paddingTop: 80,
    paddingBottom: 28,
    justifyContent: 'space-between',
    marginBottom: 100,
  },
  cdbSetupLink: {
    color: colors.greyText.color,
    textAlign: 'center',
    marginTop: 0,
  },
  link: {
    color: colors.greyText.color,
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 20,
  },
});
