import { SpText } from './SpText/SpText';
import { SpView } from './SpView';
import { AppImages } from '@constants/AppImages';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';
const OfflineWarning = () => {
  const { t } = useTranslation();
  return (
    <SpView style={styles.container}>
      <Image
        style={styles.alert}
        source={AppImages.alertIcon}
      />

      <SpText style={styles.title}>{t('you_appear_to_have_lost')}</SpText>
      <SpText style={styles.subtitle}>{t('you_will_be_unable_to_use')}</SpText>
    </SpView>
  );
};
const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    //   flex: 1,
    alignContent: 'center',
    justifyContent: 'center',

    textAlign: 'center',
    backgroundColor: '#2a3942',
  },
  title: {
    width: '80%',
    marginHorizontal: '10%',
    marginTop: 30,
    color: 'white',
    textAlign: 'center',
    fontSize: 24,
    lineHeight: 32,
  },
  subtitle: {
    width: '70%',
    marginHorizontal: '15%',
    marginTop: 20,
    color: 'white',
    textAlign: 'center',
  },
  alert: {
    width: 80,
    height: 70,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default OfflineWarning;
