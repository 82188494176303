import React, { memo, useMemo } from 'react';
import { SpView } from './SpView';
import { Dimensions, StyleSheet } from 'react-native';
import { SpText } from './SpText/SpText';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import { SpCenter } from './SpCenter';
import { format, startOfDay } from 'date-fns';
import text from '@styles/text';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

type EmptyGraphicProps = {
  showText?: boolean;
  selectedDate?: number;
};

const SpGraphicSkeleton: React.FC<EmptyGraphicProps> = ({ showText = false, selectedDate = 0 }) => {
  const { t } = useTranslation();

  const days = useMemo(() => {
    const today = +startOfDay(selectedDate);
    const milisecdsInDay = 86400000;
    const weekDaysArray: string[] = [];

    for (let i = 0; i < 7; i++) {
      weekDaysArray.unshift(format(new Date(today - milisecdsInDay * i), 'EEEEEE'));
    }

    return weekDaysArray.map((item, i) => (
      <SpCenter
        style={[styles.textCircle, { opacity: showText ? 1 : 0 }]}
        backgroundColor={i === 6 ? colors.primary.color : undefined}
        key={`${item}${i}`}>
        <SpText
          style={styles.dayText}
          color={i === 6 ? colors.white.color : colors.greySmallTitle.color}>
          {item}
        </SpText>
      </SpCenter>
    ));
  }, [showText, selectedDate]);

  return (
    <SpView>
      <SpView style={styles.triangleContainer}>
        <SpView style={styles.triangle} />
        <SpView style={styles.triangle} />
        <SpView style={styles.triangle} />
        {showText && (
          <SpView style={styles.textWrapper}>
            <FontAwesomeIcon
              size={16}
              icon={faCircleXmark}
              color={colors.greyOpacity.color}
            />
            <SpText style={[text.defaultText, styles.unavailiableText]}>{t('unavailiable')}</SpText>
          </SpView>
        )}
      </SpView>
      <SpView style={styles.block}>
        <SpView style={styles.dayAxis}>{days}</SpView>
      </SpView>
      {!showText && <SpView style={styles.extraBlock} />}
    </SpView>
  );
};

const triangleHalf = Dimensions.get('window').width / 6;

const styles = StyleSheet.create({
  triangleContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: triangleHalf,
    borderRightWidth: triangleHalf,
    borderBottomWidth: 55,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'rgba(38, 58, 67, 0.06)',
  },
  block: {
    width: '100%',
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    justifyContent: 'flex-end',
    paddingBottom: 10,
    paddingTop: 20,
  },
  textWrapper: {
    position: 'absolute',
    gap: 5,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  circle: {
    width: 12,
    height: 12,
    backgroundColor: colors.greySmallTitle.color,
    borderRadius: 6,
  },
  textCircle: {
    width: 33,
    height: 33,
    borderRadius: 17,
  },
  dayAxis: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 25,
    width: '100%',
  },
  dayText: {
    fontSize: 14,
    fontFamily: 'Rubik',
  },
  unavailiableText: {
    color: colors.greyOpacity.color,
  },
  crossText: {
    color: colors.white.color,
    fontWeight: 'bold',
    fontSize: 7,
  },
  extraBlock: {
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    width: '100%',
    height: 30,
  },
});

export default memo(SpGraphicSkeleton);
