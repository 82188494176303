import * as React from 'react';
import Svg, { Defs, Path, G, Mask, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const AccessSVG = ({ color, ...props }: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    {...props}>
    <Defs>
      <Path
        id="a"
        d="M0 0h28.5v28.5H0z"
      />
    </Defs>
    <G
      fill="none"
      fillRule="evenodd"
      transform="matrix(-1 0 0 1 24 -2.25)">
      <Mask
        id="b"
        fill="#fff">
        <Use xlinkHref="#a" />
      </Mask>
      <Path
        fill={color || '#03A2B1'}
        d="M9.5 8.669c0-1.995 0-2.993.388-3.755a3.562 3.562 0 0 1 1.557-1.557c.762-.388 1.76-.388 3.755-.388h2.85c1.995 0 2.993 0 3.755.388.67.342 1.215.887 1.557 1.557.388.762.388 1.76.388 3.755V19.83c0 1.995 0 2.993-.388 3.755a3.562 3.562 0 0 1-1.557 1.557c-.762.388-1.76.388-3.755.388H15.2c-1.995 0-2.993 0-3.755-.388a3.562 3.562 0 0 1-1.557-1.557c-.388-.762-.388-1.76-.388-3.755V18.11h4.75a3.86 3.86 0 0 0 0-7.718H9.5V8.669Zm-3.107 3.068a1.484 1.484 0 1 0-2.099-2.1L.732 13.2c-.58.58-.58 1.52 0 2.1l3.562 3.562a1.484 1.484 0 0 0 2.1-2.1l-1.03-1.028h8.886a1.484 1.484 0 1 0 0-2.968H5.365l1.028-1.029Z"
        mask="url(#b)"
      />
    </G>
  </Svg>
);
export default AccessSVG;
