import { SpText } from '../../../../../components/SpText/SpText';
import SpTransformStringToMultipleTexts from 'src/components/SpTransformStringToMultipleTexts';
import { SpView } from 'src/components/SpView';
import { PetStatisticsModel } from '@models/Device';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import DropShadow from 'react-native-drop-shadow';

import { PetStackParamList } from '..';
import { ReportType } from '../../../constants/ReportType';
import { ReportTabs } from '../../../hooks/useReportTabs';
import { useSummaryDrinkingCard } from '../../../hooks/useSummaryDrinkingCard';
import { useSummaryFeedingCard } from '../../../hooks/useSummaryFeedingCard';
import { useSummaryMovementCard } from '../../../hooks/useSummaryMovementCard';
import useTotalConsumption from '../../../hooks/useTotalConsumption';
import { PetMovementNotification } from 'src/pages/Dashboard/components/PetMovementNotification';
import InsightNotification from '../../../components/ConsumptionHabit';
import { ConsumptionInsightWithType } from '@models/ConsumptionInsight';
import { ReportRangeType } from '../../../constants/ReportRangeType';
import { SpDivider } from 'src/components/SpDivider';
import ReportIcon from './components/SvgIcons/ReportIcon';
import ReportPathIcon from './components/SvgIcons/ReportPathIcon';

interface PetDashboardCardCarouselProps {
  activeTab: ReportType;
  petId: number;
  isNotAssigned: boolean;
  isAtThisDay: boolean;
  petStatistic: PetStatisticsModel;
  insight: ConsumptionInsightWithType;
}

const AlternativeCard = ({
  activeTab,
  isNotAssigned,
  petId,
  isAtThisDay,
  petStatistic,
  insight,
}: PetDashboardCardCarouselProps) => {
  const { t } = useTranslation();

  const drinking = useSummaryDrinkingCard(petStatistic?.drinking);
  const feeding = useSummaryFeedingCard(petStatistic?.feeding);
  const movement = useSummaryMovementCard(petStatistic?.movement);
  const navigation = useNavigation<NavigationProp<PetStackParamList>>();

  const onReportsPress = useCallback(
    (tab: ReportTabs) => {
      navigation.navigate('PetDashboardReport', { type: activeTab, tab });
    },
    [activeTab],
  );

  const onViewGraph = (
    date: string,
    tab: ReportTabs,
    type: ReportType,
    viewedDays: ReportRangeType,
  ) => {
    navigation.navigate('PetDashboardReport', { type, tab, currentDate: date, viewedDays });
  };

  const cartData = useMemo(() => {
    if (activeTab === ReportType.Drinking) {
      return drinking;
    }

    if (activeTab === ReportType.Feeding) {
      return feeding;
    }

    if (activeTab === ReportType.Movement) {
      return movement;
    }

    return {
      title: '',
      timeAgo: '',
      time: '',
      leftLabel: '0',
      leftValue: t('no_activity_yet'),
      rightLabel: '0',
      rightValue: t('no_activity_yet'),
    };
  }, [activeTab, t, drinking, feeding, movement]);

  const notification = useMemo(() => {
    if (activeTab === ReportType.Drinking) {
      return (
        <>
          <SpView style={styles.habitWrapper}>
            {insight.insight && (
              <InsightNotification
                rolling={isAtThisDay}
                onViewGraph={(date: string) =>
                  onViewGraph(date, ReportTabs.Summary, ReportType.Drinking, ReportRangeType.OneDay)
                }
                insight={insight.insight}
                isAlert={insight.isAlert}
              />
            )}
          </SpView>
          {/*<DrinkingFeedingInfo time={petStatistic?.drinking?.consumption_time || 0} />*/}
        </>
      );
    }

    if (activeTab === ReportType.Feeding) {
      // All meal related design items postponed.
      // if (activeTab === ReportType.Feeding) {
      //   return <PetMealsGivenNotification mealsGiven={1} />;
      // }
      return <DrinkingFeedingInfo time={petStatistic?.feeding?.consumption_time || 0} />;
    }

    if (activeTab === ReportType.Movement && isAtThisDay) {
      return (
        <PetMovementNotification
          locationSince={petStatistic?.movement?.since}
          location={petStatistic?.movement?.where}
        />
      );
    }

    return null;
  }, [activeTab, petStatistic, isAtThisDay]);

  const formattedTimeAgo = useMemo(() => {
    if (!cartData || !cartData.timeAgo) {
      return null;
    }
    const shortedTimeAgo = cartData.timeAgo
      .replace('hours', t('hours_unit_1'))
      .replace('minutes', t('minutes_unit_2'));
    return shortedTimeAgo.split(' ').map((item, i) => {
      const style = i === 0 ? styles.formattedTimeAgoFirst : styles.formattedTimeAgoOther;
      return (
        <SpText
          key={item + i}
          style={style}
        >
          {item}
        </SpText>
      );
    });
  }, [cartData]);

  const formattedLeftLabel = useMemo(() => {
    if (cartData) {
      const parts = cartData.leftLabel.split(' ').filter(Boolean);

      return (
        <SpTransformStringToMultipleTexts
          data={parts}
          offsetBetween={5}
          ignoreOpacity
          mainStyle={styles.cartLabel}
          prefixStyle={styles.leftLabelOther}
        />
      );
    }

    return null;
  }, [cartData]);

  const isCardsInRow = !(!isAtThisDay && activeTab === ReportType.Movement);

  return (
    <SpView style={styles.container}>
      {notification && <SpView style={styles.notificationWrap}>{notification}</SpView>}
      {isAtThisDay && (
        <SpView style={styles.textData}>
          <SpText
            color={colors.greyText.color}
            size="md"
          >
            {cartData?.title || ''}
          </SpText>
          <SpView flexDirection="row">{formattedTimeAgo}</SpView>
          <SpView>
            {formattedTimeAgo ? (
              <>
                <SpText
                  style={styles.timeAgoTime}
                  size="md"
                  color={colors.greyOpacity.color}
                >
                  {cartData.time}
                </SpText>
              </>
            ) : (
              <SpView paddingVertical={20}>
                <SpText
                  size="xxl"
                  fontFamily="Rubik_Medium"
                >
                  {t('no_activity_yet')}
                </SpText>
              </SpView>
            )}
          </SpView>
        </SpView>
      )}
      <SpDivider style={{ width: '100%' }} />
      <SpView style={[styles.summary, isCardsInRow && styles.summaryRow]}>
        <SpView style={isCardsInRow ? styles.cardRowContent : styles.cardColumnContent}>
          <SpView style={styles.cart}>
            {formattedLeftLabel}
            <SpText
              style={[styles.greyColor, { fontSize: 16 }]}
              numberOfLines={1}
              adjustsFontSizeToFit
            >
              {cartData.leftValue}
            </SpText>

            <TouchableOpacity onPress={() => onReportsPress(ReportTabs.Summary)}>
              <SpView style={styles.reportIcon}>
                <ReportIcon />
                <ReportPathIcon />
              </SpView>
            </TouchableOpacity>
          </SpView>
        </SpView>
        <SpView style={isCardsInRow ? styles.cardRowContent : styles.cardColumnContent}>
          <SpView style={styles.cart}>
            <SpText style={[styles.cartLabel, { fontSize: 28 }]}>{cartData.rightLabel}</SpText>
            <SpText
              style={[styles.greyColor, { fontSize: 16 }]}
              numberOfLines={1}
              adjustsFontSizeToFit
            >
              {cartData.rightValue}
            </SpText>

            <TouchableOpacity onPress={() => onReportsPress(ReportTabs.Detail)}>
              <SpView style={styles.reportIcon}>
                <ReportIcon />
                <ReportPathIcon />
              </SpView>
            </TouchableOpacity>
          </SpView>
        </SpView>
      </SpView>
    </SpView>
  );
};
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: windowHeight < 810 ? 7 : 15,
  },
  notificationWrap: {
    width: '100%',
    paddingVertical: 20,
  },
  formattedTimeAgoFirst: {
    fontSize: 24,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
  },
  formattedTimeAgoOther: {
    marginLeft: 4,
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
  },
  timeAgoTime: {
    marginLeft: 8,
  },
  summaryRow: {
    flexDirection: 'row',
  },
  summaryColumn: {
    flexDirection: 'column',
  },
  summary: {
    width: '100%',
    gap: windowHeight < 810 ? 10 : 32,
    justifyContent: 'space-between',
  },
  cart: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: windowHeight < 810 ? '3%' : 18,
    paddingBottom: windowHeight < 810 ? '3%' : 18,
    paddingHorizontal: 0,
    borderRadius: 16,
    width: '100%',
    backgroundColor: colors.white.color,
    flexGrow: 1,
  },
  cardRowContent: {
    flexBasis: '44%',
    flexShrink: 0,
    flexDirection: 'column',
  },
  cardColumnContent: {
    flexBasis: '100%',
  },
  cardShadow: {
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    flexGrow: 1,
    flexDirection: 'column',
  },
  cartLabel: {
    fontSize: 28,
    lineHeight: 28,
    fontFamily: 'Rubik_SemiBold',
  },
  leftLabelOther: {
    fontSize: 20,
    lineHeight: 28,
    fontFamily: 'Rubik_SemiBold',
  },
  drinkingNormal: {
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(45, 181, 142, 0.2)',
    borderRadius: 16,
    paddingVertical: 20,
    paddingLeft: 16,
  },
  drinkingContainer: {
    padding: 13,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(38, 58, 67, 0.1)',
    width: '90%',
    height: 80,
    borderRadius: 16,
    alignSelf: 'center',
  },
  smallDrinkingContainer: {
    height: '100%',
    justifyContent: 'space-between',
    gap: 2,
    alignItems: 'center',
  },
  textContainer: {
    flexDirection: 'row',
  },
  bigText: {
    fontSize: 28,
    textAlign: undefined,
    marginLeft: 3,
  },
  smallText: {
    fontSize: 20,
    textAlign: undefined,
    marginLeft: 3,
  },
  textData: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 12,
  },
  greyColor: {
    color: colors.greyOpacity.color,
    lineHeight: 20,
  },
  habitWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },
  reportIcon: {
    backgroundColor: '#5C71C1',
    borderRadius: 100,
    marginTop: 12,
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 14,
    alignSelf: 'flex-end'
  },
});

export default AlternativeCard;

const DrinkingFeedingInfo: React.FC<{ time: number }> = ({ time }) => {
  const { t } = useTranslation();
  const data = useTotalConsumption(time);

  const separated = useMemo(() => {
    return data.split(' ').map((text, ind) => {
      if (ind % 2 === 0) {
        return (
          <SpText
            style={[textStyles.title, styles.bigText]}
            key={text}
          >
            {text}
          </SpText>
        );
      }
      return (
        <SpText
          style={[textStyles.title, styles.smallText]}
          key={text}
        >
          {text}
        </SpText>
      );
    });
  }, [data]);

  return (
    <SpView style={styles.drinkingContainer}>
      <SpView style={styles.smallDrinkingContainer}>
        <SpView style={styles.textContainer}>{separated}</SpView>
        <SpText style={textStyles.defaultText}>{t('time_at_bowl')}</SpText>
      </SpView>
    </SpView>
  );
};
