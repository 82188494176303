import { BackHandler, DeviceEventEmitter, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';

import EssenceCreated from 'src/components/EssenceCreated';
import StepFormCreator from 'src/components/StepFormCreator';
import useBoundStore from 'src/store/store';
import SpTitle from 'src/components/SpTitle';
import { t } from 'i18next';
import { SpView } from 'src/components/SpView';

import useValidation from 'src/pages/validation';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from 'src/pages';
import NameForm from '../../Flows/Generic/NameForm';
import TimezoneForm from './forms/TimezoneForm';
import { useRefreshAnalyticsData } from '@hooks/useRefreshAnalyticsData';

const CreateHousehold = () => {
  const [step, setStep] = useState(1);
  const { commonNameSchema, commonNumSchema } = useValidation();
  const [submitValues, setSubmitValues] = useState<any>();
  const [timezoneButtonDisabled, setTimezoneButtonDisabled] = useState(true);
  const [name, setName] = useState('');
  const allowAnalyticsRefresh = useRefreshAnalyticsData();

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { createHousehold, setActiveHousehold } = useBoundStore(state => state.householdStore);

  const onSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      const submitObj = {
        name: submitValues.name.trim(),
        timezone_id: Number(submitValues.timezone_id),
      };

      try {
        const household = await createHousehold(submitObj);
        setActiveHousehold(household);
        setCreated(true);
        allowAnalyticsRefresh();
      } catch (err) {
        console.log(err);
      }
    },
    [submitValues],
  );

  const NameFormWrap = useCallback(
    (children: JSX.Element) => (
      <SpView marginTop={32}>
        <SpTitle text={t('name_household')} />
        {children}
      </SpView>
    ),
    [],
  );
  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('create_household')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        1: {
          ui: NameForm,
          props: {
            label: t('household_name'),
            disableKeyboardAwoiding: true,
            setName: setName,
          },
          wrap: NameFormWrap,
          buttonDisabled: name.length < 1,
          formik: {
            schema: commonNameSchema('Household name'),
            names: ['name'],
          },
        },
        2: {
          ui: TimezoneForm,
          formik: {
            schema: commonNumSchema('timezone_id'),
            names: ['timezone_id'],
          },
          buttonDisabled: timezoneButtonDisabled,
          effect: ({ formik }) => {
            setTimezoneButtonDisabled(Boolean(!formik?.values?.timezone_id));
          },
        },
        3: {
          ui: (
            <View style={styles.stepWrapper}>
              <EssenceCreated
                msg={t('household_created')}
                onSubmit={onSubmit}
              />
            </View>
          ),
          hideCustomHeader: true,
          interceptor: () => {
            navigation.navigate('FlowNavigation', { screen: 'SelectProduct' });
            return false;
          },
          handleAndroidBackPress: () => {
            navigation.navigate('FlowNavigation', { screen: 'SelectProduct' });
            return true;
          },
        },
      }}
    />
  );
};

export default CreateHousehold;

const styles = StyleSheet.create({
  nameWrapper: {
    marginTop: 56,
  },
  stepWrapper: {
    marginTop: '40%',
  },
});
