import { SpView, SpViewProps } from 'src/components/SpView';
import React, { PropsWithChildren } from 'react';

export const DeviceBottomSheetContainer = ({
  children,
  ...props
}: PropsWithChildren<SpViewProps>) => {
  return (
    <SpView
    accessible={false}
      {...props}
      paddingVertical={18}
      paddingHorizontal={22}>
      {children}
    </SpView>
  );
};
