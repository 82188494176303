import { StyleSheet, View } from 'react-native';
import React, { Key } from 'react';
import { SpVStack } from 'src/components/SpVStack';
import colors from '@styles/colors';
import { SpText } from '../../../components/SpText/SpText';
import StepsNumCircle from './StepsNumCircle';

type InstructionsStepsProps = {
  arr: string[];
  withStepNums?: boolean;
  space?: number;
  centerNumCircle?: boolean;
};

const InstructionsSteps = ({
  arr,
  withStepNums,
  space = 12,
  centerNumCircle,
}: InstructionsStepsProps) => {
  return (
    <SpVStack
      space={space}
      style={[styles.container]}>
      {arr?.map((item, index) => {
        return (
          <View
            key={item as Key}
            style={[
              withStepNums && styles.containerRow,
              centerNumCircle && { alignItems: 'center' },
            ]}>
            {withStepNums && (
              <StepsNumCircle
                style={styles.stepsIcon}
                num={index + 1}
              />
            )}
            <SpText style={[styles.instructionText, withStepNums && styles.withStepNumsText]}>
              {item}
            </SpText>
          </View>
        );
      })}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
  },
  containerRow: {
    flexDirection: 'row',
    width: '90%',
  },
  withStepNumsText: {
    textAlign: 'left',
    paddingHorizontal: 20,
  },
  instructionText: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.black.color,
    textAlign: 'center',
    lineHeight: 22,
  },
  stepsIcon: {
    height: 38,
    width: 38,
    fontSize: 12,
  },
});

export default InstructionsSteps;
