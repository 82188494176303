import { HouseholdUserPermissionLevel } from '@constants/HouseholdUserPermissionLevel';
import { lens } from '@dhmk/zustand-lens';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { StateCreator } from 'zustand';

import HouseholdApi from '../../api/HouseholdApi';
import { HouseholdModel } from '../../models/Household';
import { HouseholdRemovedService } from '../../services/HouseholdRemovedService';
import { TimeService } from '../../services/TimeService';
import { HouseholdSlice, MergedInterfaces } from '../models';

const createHouseholdSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  HouseholdSlice
> = (set, get) => {
  return {
    householdStore: lens((subSet, subGet) => ({
      activeHousehold: null,
      households: [],
      loadingHouseholds: false,
      joinedHouseholdsData: [],
      ownedHouseholdsData: [],
      activeHouseholdIsEmpty: false,
      setActiveHousehold: household => {
        set(state => ({
          householdStore: { ...state.householdStore, activeHouseholdIsEmpty: false },
        }));
        subSet({ activeHousehold: household });
        console.log('set active household', household);
        TimeService.changeDefaultTimezone(household.timezone.timezone);
      },
      getHouseholds: async () => {
        try {
          const { activeHousehold, ownedHouseholds, joinedHouseholds } = subGet();
          subSet({ loadingHouseholds: true });
          const households = await HouseholdApi.getHouseholds();
          const { user } = get().accountStore;

          await HouseholdRemovedService.setOrUpdateInitialData(user, households);

          subSet({ households });
          if (!activeHousehold) {
            subSet({
              activeHousehold: ownedHouseholds()[0] || households[0],
            });
          } else {
            subSet({
              households,
              activeHousehold:
                households.map(h => h.id).indexOf(activeHousehold.id) !== -1
                  ? households.filter(h => h.id === activeHousehold.id)[0]
                  : households[0] || null,
            });
          }

          subSet({
            ownedHouseholdsData: ownedHouseholds(),
            joinedHouseholdsData: joinedHouseholds(),
            loadingHouseholds: false,
          });
        } catch ({ response }) {
          subSet({ loadingHouseholds: false });
        }
      },
      // @ts-expect-error: Types should be updated
      createHousehold: async household => {
        try {
          AnalyticsService.logEvent(`household_createHousehold`);

          subSet({ loadingHouseholds: true });
          const createdHousehold = await HouseholdApi.createHousehold(household);
          await subGet().getHouseholds();
          return createdHousehold;
        } catch (err) {
          subSet({ loadingHouseholds: false });
          throw err;
        }
      },
      updateHousehold: async household => {
        subSet({ loadingHouseholds: true });
        try {
          await HouseholdApi.updateHousehold(household);
          await subGet().getHouseholds();
        } catch (err) {
          subSet({ loadingHouseholds: false });
          const error = { code: 3 };
          throw error;
        }
      },
      isHouseholdActive: household => {
        const { activeHousehold } = subGet();
        return household.id === activeHousehold?.id;
      },
      isHouseholdOwned: (household: HouseholdModel) => {
        const { user } = get().accountStore;
        return household.created_user_id === user.id;
      },
      ownedHouseholds: () => {
        const { user } = get().accountStore;
        const { households } = subGet();
        return households.filter(h => h.created_user_id === user.id);
      },
      joinedHouseholds: () => {
        const { user } = get().accountStore;
        const { households } = subGet();
        return households.filter(h => h.created_user_id !== user.id);
      },
      updateHouseholdsOrderLocaly: (
        data: HouseholdModel[],
        householdType: 'ownedHouseholdsData' | 'joinedHouseholdsData',
      ) => {
        subSet({ [householdType]: data });
      },

      resetHousehold: () => {
        subSet({
          activeHousehold: null,
          households: null,
          loadingHouseholds: false,
          joinedHouseholdsData: [],
          ownedHouseholdsData: [],
        });
      },
      userPermission: () => {
        const { activeHousehold } = subGet();
        const { user } = get().accountStore;
        const currentUser = activeHousehold?.users?.find(userObj => userObj.id === user.id);

        if (currentUser?.owner) {
          return HouseholdUserPermissionLevel.Admin;
        }

        if (currentUser?.write) {
          return HouseholdUserPermissionLevel.Controller;
        }

        return HouseholdUserPermissionLevel.Viewer;
      },
      userHasWriteAccess: () => {
        const userPermission = subGet().userPermission();
        return (
          userPermission === HouseholdUserPermissionLevel.Admin ||
          userPermission === HouseholdUserPermissionLevel.Controller
        );
      },
      userHasAdminAccess: () => {
        const userPermission = subGet().userPermission();
        return userPermission === HouseholdUserPermissionLevel.Admin;
      },
    })),
  };
};

export default createHouseholdSlice;
