import DeviceApi from '@api/DeviceApi';

export const asyncControl = async (id: number, control: object ) => {
  return new Promise (async (resolve, reject) => {
    try {
      console.log("send async request")
      const response = await DeviceApi.parseDeviceControlAsync(id, control);
      console.log('response', response);

      if (response.results[0].status === 4) {
        console.log('response', response.results[0].status);
        resolve(response);
        return
      }
      if (!response.results[0]?.request_id) {
        reject('No request ID');
        return
      }
      const responseID = response.results[0].request_id;

      let requests = 0;
      let timer = setInterval(async () => {
        if (requests > 10) {
          clearInterval(timer);
          reject('Timeout');
        }
        const response = await DeviceApi.getDeviceStatusByID(id, responseID);
        if (response.status === 0) {
          resolve(response);
          clearInterval(timer);
        }
        requests++;
      }, 1000);
    }
    catch (error) {
      reject(error);
    }
  });

}
