import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const IndoorsSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    height={50}
    {...props}>
    <G
      fill="#263A43"
      fillRule="nonzero">
      <Path d="M15.731 7.192 8.553.955a.846.846 0 0 0-1.106 0L.27 7.192a.753.753 0 0 0-.213.86c.124.304.424.5.765.5h1.147v6.25c0 .247.21.448.47.448h3.935c.26 0 .47-.2.47-.449v-3.794h2.313v3.794c0 .248.211.449.47.449h3.935c.26 0 .471-.2.471-.449V8.553h1.147a.815.815 0 0 0 .765-.5.753.753 0 0 0-.213-.86ZM14.16 1.958h-3.327l3.823 3.021V2.39c0-.238-.222-.43-.495-.43Z" />
    </G>
  </Svg>
);
export default IndoorsSVG;
