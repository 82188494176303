import { SpView } from 'src/components/SpView';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../../components/SpText/SpText';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import TourTapSVG from 'src/components/SvgIcons/TourTapSVG';
import noop from '@utils/noop';
import { HouseholdSelector } from '../../../Household/components/HouseholdSelector';

const MyHouseholdSlide = () => {
  const { t } = useTranslation();

  return (
    <SpView>
      <SpView style={styles.myHouseholdWrapper}>
        <HouseholdSelector
          action={noop}
          householdName={t('my_household')}
        />
        <View style={styles.tapIconWrapper}>
          <TourTapSVG />
        </View>
      </SpView>
      <SpTitle
        style={styles.title}
        align="center"
        text={t('tap_to_switch_or_join')}
      />
      <SpText style={styles.contentText}>{t('my_household_tour_text')}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  title: {
    paddingTop: 16,
  },
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 32,
  },
  myHouseholdWrapper: {
    marginTop: 32,
    marginBottom: 44,
  },
  tapIconWrapper: {
    width: '100%',
    position: 'absolute',
    alignItems: 'center',
    top: 50,
  },
});

export default MyHouseholdSlide;
