import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { MappedReportModel, ReportChangeViewDayPayload } from '@models/ReportModel';
import colors from '@styles/colors';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import DropShadow from 'react-native-drop-shadow';
import { SpView } from 'src/components/SpView';
import { TimeService } from 'src/services/TimeService';

import { PetDashboardDrinking } from './PetDashboardDrinking';
import { PetDashboardFeeding } from './PetDashboardFeeding';
import { PetDashboardMovement } from './PetDashboardMovement';
import { ReportHeader } from './components/ReportHeader';
import { SummaryTab } from './components/SummaryTab';
import { TabsWrap } from './components/TabsWrap';
import { ReportWrapperFilter } from '../../../components/PetDashboardReportWrapper';
import { ReportRangeType } from '../../../constants/ReportRangeType';
import { ReportType } from '../../../constants/ReportType';
import { ReportTabs } from '../../../hooks/useReportTabs';
import { ConsumptionInsightsGroups, ConsumptionInsightWithType } from '@models/ConsumptionInsight';
import { WithoutTimeDateFormat } from '@constants/DateFormat';

interface PetDashboardSummaryProps {
  stats: MappedReportModel;
  devices: DeviceModel[];
  pet: PetModel;
  type: ReportType;
  types: ReportType[];
  onChangeType: (data: ReportType) => void;
  currentDate: Date;
  viewedDays: ReportRangeType;
  initialActiveTab: ReportTabs;
  petInsights: ConsumptionInsightsGroups;
  setFilter: (payload: ReportWrapperFilter) => void;
  onGenerateReport: () => void;
}

export const ReportDashboard = ({
  stats,
  devices,
  pet,
  type,
  types,
  onChangeType,
  currentDate,
  setFilter,
  viewedDays,
  initialActiveTab,
  onGenerateReport,
  petInsights,
}: PetDashboardSummaryProps) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { getShortUnit } = useWeightConversion(userWeightUnits, userCountryCode);

  const isMovement = type === ReportType.Movement;

  const dataType = useMemo(() => {
    if (type === ReportType.Drinking) {
      return getShortUnit(false);
    }
    if (type === ReportType.Feeding) {
      return getShortUnit(true);
    }
    return '';
  }, [type, getShortUnit]);

  const onChangeViewedDays = useCallback(
    ({ date, days }: ReportChangeViewDayPayload) => {
      setFilter({
        currentDate: date ? +date : TimeService.toLocal().toMillis(),
        viewedDays: days ? days : viewedDays,
      });
    },
    [setFilter, viewedDays],
  );
  const onSetActiveTab = useCallback(
    (tab: ReportTabs) => {
      const holdSelected: boolean = tab === ReportTabs.Summary || viewedDays === ReportRangeType.SevenDays || viewedDays === ReportRangeType.TwentyEightDays;
      onChangeViewedDays({
        date: (currentDate && holdSelected ? TimeService.toLocal(currentDate) : TimeService.toLocal()).endOf('day').toMillis(),
        days: holdSelected ? viewedDays : viewedDays >= 28 ? 28 : 7,
      });
      setActiveTab(tab);
    },
    [currentDate, onChangeViewedDays, viewedDays],
  );

  const selectedDayInsight = useMemo((): ConsumptionInsightWithType => {
    if (viewedDays !== ReportRangeType.OneDay) {
      return null;
    }
    const date = TimeService.toLocal(currentDate).toFormat(WithoutTimeDateFormat);
    const habit = petInsights.habits[date];
    const alert = petInsights.alerts[date];

    return {
      insight: alert || habit,
      isAlert: !!alert,
    };
  }, [petInsights, currentDate, viewedDays]);

  return (
    <SpView
      testID="PetDashboardSummary"
      style={styles.container}
    >
      <ReportHeader
        types={types}
        activePet={pet}
        type={type}
        onChangeType={onChangeType}
        onGenerateReport={onGenerateReport}
      />
      <DropShadow>
        <TabsWrap
          activeTab={activeTab}
          onChange={onSetActiveTab}
          type={type}
        />
      </DropShadow>

      {activeTab === ReportTabs.Summary && (
        // Bar Chart
        <SummaryTab
          devices={devices}
          stats={stats}
          type={type}
          pet={pet}
          dataType={dataType}
          rangeType={viewedDays}
          //@ts-ignore
          currentDate={currentDate}
          selectedDayInsight={selectedDayInsight}
          onChangeViewedDays={onChangeViewedDays}
        />
      )}
      {activeTab === ReportTabs.Detail && type === ReportType.Drinking && (
        <PetDashboardDrinking
          currentDate={+currentDate}
          pet={pet}
          devices={devices}
          data={stats?.drinking || []}
          onGeneratePDFReport={null}
          onChangeViewedDays={onChangeViewedDays}
          rangeType={viewedDays}
        />
      )}
      {activeTab === ReportTabs.Detail && type === ReportType.Feeding && (
        <PetDashboardFeeding
          currentDate={+currentDate}
          pet={pet}
          devices={devices}
          data={stats?.feeding || []}
          onChangeViewedDays={onChangeViewedDays}
          rangeType={viewedDays}
        />
      )}

      {activeTab === ReportTabs.Detail && isMovement && (
        <PetDashboardMovement
          currentDate={+currentDate}
          data={stats?.movement || []}
          onGeneratePDFReport={null}
          onChangeViewedDays={onChangeViewedDays}
          rangeType={viewedDays}
        />
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    backgroundColor: colors.white.color,
  },
  content: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  cardShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -20,
    },
    shadowOpacity: 0.05,
    shadowRadius: 8,
  },
});
