import { StyleSheet, Text, View } from 'react-native';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StepFormCreator from 'src/components/StepFormCreator';
import { AppImages } from '@constants/AppImages';
import SpTitle from 'src/components/SpTitle';
import { FeederFoodType, SubstanceType } from '@constants/Device';
import SelectButtonsWrapper from 'src/components/SelectButtonsWrapper';
import { capitalize } from '@utils/capitalize';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from 'src/pages';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import LoadingComponent from '../components/LoadingComponent';
import TypeButton from '../components/TypeButton';

const PetBowlSetup = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const substanceTypeOptions = enumToDescriptedArray(SubstanceType);
  const [submitValues, setSubmitValues] = useState<any>();
  const [bowlType, setBowlType] = useState(null);
  const [foodType, setFoodType] = useState(null);

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const bowlTypeImages = [AppImages.petBowlSetupWater, AppImages.petBowlSetupFood];

  const bowlTypeStep = useMemo(() => {
    return (
      <View style={styles.stepWrapper}>
        <SpTitle text={t('bowlType_title')} />
        {substanceTypeOptions.map((item, index) => {
          const option = substanceTypeOptions[index];
          return (
            <View
              key={item.id + index}
              style={[styles.buttonWrap, index === 0 && { marginTop: 52 }]}>
              <TypeButton
                text={capitalize(option.name)}
                isActive={bowlType === option.id}
                onPress={() => {
                  setBowlType(option.id);
                }}
                {...bowlTypeImages[index]}
                image={bowlTypeImages[index]}
              />
            </View>
          );
        }, [])}
      </View>
    );
  }, [bowlType]);

  const foodTypeStep = useMemo(() => {
    return (
      <View style={styles.stepWrapper}>
        <View style={styles.foodTypeTitle}>
          <SpTitle text={t('select_food_type')} />
        </View>
        <SelectButtonsWrapper
          optionsEnum={FeederFoodType}
          handleChange={setFoodType}
          selectedOption={FeederFoodType[foodType]}
        />
      </View>
    );
  }, [foodType]);

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('configure_product')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        1: {
          ui: bowlTypeStep,
          buttonDisabled: !bowlType,
          customHeaderProps: {
            withLeaveButton: true,
          },
        },
        2: {
          ui: foodTypeStep,
          buttonDisabled: !foodType,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
          },
          skipStep: bowlType === 1,
        },
        3: {
          ui: <Text>Water FIll Information</Text>,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
          },
        },
        4: {
          ui: <Text>Food FIll Information</Text>,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
          },
        },
        5: {
          ui: (
            <View style={{ marginTop: 112 }}>
              <LoadingComponent
                isConnected
                connectedMsg={t('setup_complete')}
              />
            </View>
          ),
          hideCustomHeader: true,
          interceptor: () => {
            navigation.navigate('DashboardNavigation');
            return false;
          },
          handleAndroidBackPress: () => {
            navigation.navigate('DashboardNavigation');
            return true;
          },
        },
      }}
    />
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: 32,
    flex: 1,
  },

  buttonWrap: {
    marginTop: 44,
  },
  foodTypeTitle: {
    alignItems: 'center',
    marginBottom: 48,
  },
});

export default PetBowlSetup;
