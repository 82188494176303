import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { useMemo } from 'react';
import { useDevicesByHousehold } from './useDevicesByHousehold';
import useBoundStore from 'src/store/store';

export const usePetDevices = (pet: PetModel): DeviceModel[] => {
  const devices = useDevicesByHousehold();
  const { devicesIdsRequestsTrack } = useBoundStore(s => s.deviceStore);
  return useMemo(() => {
    return devices.filter(device => {
      return (device?.tags || []).find(item => item?.id === pet?.tag_id);
    });
  }, [pet, devices, devicesIdsRequestsTrack]);
};
