import { DeviceType, PetDoorLockingMode } from '@constants/Device';
import {
  DeviceCatFlapControlModel,
  DeviceCatFlapCurfew,
  DeviceModel,
  DevicePetDoorControlModel,
  DevicePetDoorCurfew,
} from '@models/Device';
import React, { useMemo } from 'react';
import { MultipleCurfew } from './MultipleCurfew';
import { SingleCurfew } from './SingleCurfew';

interface CurfewCardsProps {
  data: DeviceModel;
  error: string;
  onChangeCurfew: (
    data: DevicePetDoorCurfew | DeviceCatFlapCurfew[],
    locking: PetDoorLockingMode,
  ) => void;
}

export const CurfewCards = ({ data, error, onChangeCurfew }: CurfewCardsProps) => {
  const control = useMemo(() => {
    return data.control as DeviceCatFlapControlModel | DevicePetDoorControlModel;
  }, [data]);

  if (data.product_id === DeviceType.PetDoorConnect) {
    return (
      <SingleCurfew
        data={control?.curfew as DevicePetDoorCurfew}
        error={error}
        onChangeCurfew={onChangeCurfew}
        locking={control?.locking}
      />
    );
  }

  return (
    <MultipleCurfew
      data={control?.curfew as DeviceCatFlapCurfew[]}
      error={error}
      onChangeCurfew={onChangeCurfew}
    />
  );
};
