import type { Ref } from 'react';
import React from 'react';
import type { GestureResponderEvent, View } from 'react-native';
import { useFocusEffect } from './useFocusEffect';

export type ComponentRef = Ref<any>;

export const isInRange = (value: number, target: number, threshold: number) =>
  value < target + threshold && value > target - threshold;

let collection: { ref: ComponentRef; cb: () => void }[] = [];

export let isTouch: boolean | undefined;
export const setIsTouch = (value: boolean) => (isTouch = value);

export const onTouch = (event: GestureResponderEvent) => {
  if (!isTouch) {
    return;
  }
  const { pageX, pageY } = event.nativeEvent;
  collection.forEach(item => {
    // @ts-ignore
    item.ref?.current?.measure((_x, _y, width: number, height: number, x: number, y: number) => {
      if (pageX < x || pageX > x + width || pageY < y || pageY > y + height) {
        item.cb();
      }
    });
  });
  isTouch = undefined;
};

export const register: (ref: ComponentRef, cb: () => void) => void = (ref, cb) => {
  if (collection.find(c => c.ref === ref)) {
    return;
  }
  collection.push({ ref, cb });
};

export const unregister: (ref: ComponentRef) => void = refToRemove =>
  (collection = collection.filter(({ ref }) => ref !== refToRemove));

type HookConfig = {
  triggerOnUnmount?: boolean;
  triggerOnBlur?: boolean;
};

export const useClickOutside = <T = View>(
  callback: () => void,
  config?: HookConfig,
): React.RefObject<T> => {
  const callbackRef = React.useRef(callback);
  const ref = React.useRef<T>(null);

  useFocusEffect(
    () => {
      if (config?.triggerOnBlur === false) {
        return;
      }
      register(ref, callbackRef.current);
    },
    () => {
      if (config?.triggerOnBlur === false) {
        return;
      }
      callbackRef.current();
      unregister(ref);
    },
  );
  React.useEffect(() => {
    register(ref, callbackRef.current);
    return () => {
      unregister(ref);
      if (config?.triggerOnUnmount === false) {
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      callbackRef.current();
    };
  }, [config?.triggerOnUnmount]);

  return ref;
};
