import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { NativeStackNavigationProp } from 'react-native-screens/lib/typescript/native-stack';
import useBoundStore from 'src/store/store';
import { storeLang } from '@utils/i18n';
import { AccountMenu } from '@constants/Menus';
import { AccountStackParamList } from 'src/pages/Account';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { useTranslation } from 'react-i18next';
import { useReturnHandler } from './useReturnHandler';
import { useRefreshAllData } from './useRefreshAllData';

export const useUpdateAccount = (
  updateButtonLoading: Dispatch<SetStateAction<boolean>>,
  updateButtonDisabled: Dispatch<React.SetStateAction<boolean>>,
  inputName: string,
  navigation: NativeStackNavigationProp<AccountStackParamList, 'AccountEditSetting'>,
  value: string,
): [(val: any) => Promise<void>, string] => {
  const { user, updateUser } = useBoundStore(state => state.accountStore);
  const { getLanguageByID } = useBoundStore(state => state.languageStore);
  const { refreshStaticData } = useRefreshAllData();
  const handler = useReturnHandler(inputName);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const performUpdateUser = async val => {
    try {
      setError('');
      updateButtonLoading(true);
      updateButtonDisabled(true);
      AnalyticsService.logEvent(`account_update_${inputName}`);
      await updateUser({
        ...user,
        [inputName]: val,
      });
      if (inputName === AccountMenu.Language) {
        await refreshStaticData();
        const i18Code = getLanguageByID(val).code;
        storeLang(i18Code);
      }
      navigation.pop();
      handler();
    } catch (err: any) {
      if (err?.data?.error?.error?.includes('Email Address must be unique')) {
        setError(t('unique_email_error'));
      } else {
        navigation.pop();
      }
      handler(err.code, err?.data?.error?.email_address[0]);
    } finally {
      updateButtonLoading(false);
      updateButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (error) setError('');
  }, [value]);

  return [performUpdateUser, error];
};
