import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import React from 'react';
import { Image, ImageSourcePropType, Pressable, StyleSheet } from 'react-native';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';

interface ITypeButtonProps {
  text: string;
  image: ImageSourcePropType;
  onPress: () => void;
  isActive: boolean;
  overidePrimaryColor?: string;
  overideBackgroundColor?: string;
}

const TypeButton = ({
  text,
  image,
  onPress,
  isActive,
  overidePrimaryColor,
  overideBackgroundColor,
}: ITypeButtonProps) => {
  return (
    <Pressable
      style={[
        styles.container,
        isActive && {
          borderColor: overidePrimaryColor ?? colors.btnBlue.color,
          backgroundColor: overideBackgroundColor ?? colors.blueBackgroundOpacity.color,
        },
      ]}
      onPress={onPress}>
      <Image
        source={image}
        style={[styles.image, { marginTop: 0, height: 52 }]}
        resizeMode="contain"
      />
      <SpText
        color={colors.greyText.color}
        fontFamily="Rubik_Medium"
        size="xl"
        bold
        style={{
          marginTop: 4,
        }}>
        {text}
      </SpText>
      {isActive && (
        <SpView style={styles.activeIconContainer}>
          <FontAwesomeIcon
            size={36}
            icon={faCircle}
            color={overidePrimaryColor ?? colors.primary.color}
          />
        </SpView>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 14,
    height: 100,
    alignItems: 'center',
    paddingTop: 12,
  },
  activeIconContainer: {
    position: 'absolute',
    right: -10,
    top: -10,
    zIndex: 2,
    backgroundColor: colors.white.color,
    borderRadius: 18,
  },
  image: {
    height: 44,
  },
  activeContainer: {
    borderColor: colors.primary.color,
    backgroundColor: colors.primaryBackgroundOpacity.color,
  },
  // activeContainer: {
  //   borderColor: colors.btnBlue.color,
  //   backgroundColor: colors.blueBackgroundOpacity.color,
  // },
});

export default TypeButton;
