import * as React from 'react';
import { Defs, Ellipse, G, Mask, Path, Svg, Use } from 'react-native-svg';

const HouseholdSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={167}
    height={140}
    {...props}>
    <Defs>
      <Path
        id="a"
        d="M.055.004 0 0l.055.004Z"
      />
    </Defs>
    <G
      fill="none"
      fillRule="evenodd">
      <Ellipse
        cx={83.5}
        cy={127.719}
        fill="#263A43"
        fillOpacity={0.101}
        rx={83.5}
        ry={12.281}
      />
      <Path
        fill="#92D0D4"
        d="M27.257 129.258H134.58c2.713 0 4.913-2.2 4.913-4.913V44.643H27.257v84.615ZM27.27 44.643h112.027L82.117 6.42 27.269 44.643"
      />
      <Path
        fill="#BEE1DF"
        d="M130.993 102.742V63.13h-34.75v36.284a54.674 54.674 0 0 1-8.218-3.541 40.909 40.909 0 0 1-6.506-4.301V60.306H70.315c.959-4.312 2.691-8.545 4.914-12.535.59-1.059 1.234-2.1 1.921-3.128h43.19l18.957 12.673v42.141a7.024 7.024 0 0 0-1.729 1.814 40.117 40.117 0 0 1-6.575 1.47M120.34 44.643H77.15c2.866-4.282 6.502-8.302 10.29-12.162a72.358 72.358 0 0 1 6.234-5.663l26.666 17.825"
      />
      <Path
        fill="#88BEC2"
        d="M96.242 120.966H51.94c.219-.175.478-.42.75-.676.228-.216.463-.438.72-.664 2.001-2.365 2.043-5.358.126-8.902-.002 0-1.369-2.34-3.592-3.433h29.342c1.178.5 2.379.974 3.602 1.422 4.237 1.55 8.728 2.727 13.354 3.58v8.673m-51.98-13.446c-.357 0-.713-.077-1.043-.229h2.086l-.022.01c-.326.147-.674.22-1.021.22m-17.005-7.528V52.41l.292-.203v6.42l20.064-13.984h5.897c-1.683 3.46-3.022 7.023-3.958 10.703a47.964 47.964 0 0 0-.988 4.96H34.629V97.85h-.01l-.016-.002h-.001l-.035-.002h-.002l-.017-.002h-.001l-.055-.004h-.019a.96.96 0 0 0-.037-.003h-.018l-.001-.001-.018-.001-.037-.002h-.019l-.019-.002-.037-.001h-.001l-.018-.001-.038-.002h-.02l-.018-.001h-.038l-.018-.001h-.003l-.017-.001h-.04l-.017-.001h-.039l-.018-.001H33.883c-.052 0-.114-.003-.167-.007-.011 0-.085-.003-.2-.003-1.529 0-4.242.302-6.259 2.18"
      />
      <Path
        fill="#88BEC2"
        d="M53.51 44.643h-5.897l9.689-6.752a68.015 68.015 0 0 0-3.792 6.752"
      />
      <Path
        fill="#02A6B6"
        d="M27.257 129.258H134.58c2.713 0 4.913-2.2 4.913-4.913v-3.38H27.257v8.293Z"
      />
      <Path
        fill="#6D9A9E"
        d="m139.492 57.446-.195-.13v-6.42l.195.13v6.42M57.302 37.891c2.77-4.353 6.068-8.542 9.767-12.597a92.663 92.663 0 0 1 1.703-1.817l14.446-10.07 14.825 9.91c-.798.65-1.513 1.313-2.122 1.764a68.987 68.987 0 0 0-2.247 1.737l-10.456-6.99-25.916 18.063"
      />
      <Path
        fill="#8CA5A5"
        d="M139.297 57.316 120.34 44.643h9.604l9.353 6.252v6.421M129.944 44.643h-9.604L93.674 26.818c.732-.59 1.48-1.17 2.247-1.737.609-.451 1.324-1.113 2.122-1.763l31.901 21.325"
      />
      <Path
        fill="#648D90"
        d="M27.55 58.627v-6.42L38.4 44.643h9.212L27.549 58.627M47.613 44.643H38.4l30.371-21.166a92.663 92.663 0 0 0-1.703 1.817c-3.7 4.055-6.997 8.244-9.767 12.597l-9.69 6.752"
      />
      <Path
        fill="#76453A"
        d="m149.85 56.806 7.484-2.497a2.456 2.456 0 0 0 .588-4.372L97.854 9.783a25.79 25.79 0 0 0-29.078.283L11.519 49.97a2.456 2.456 0 0 0 .597 4.335l7.103 2.47a2.456 2.456 0 0 0 2.212-.304l61.787-43.063 64.49 43.11a2.456 2.456 0 0 0 2.142.288Z"
      />
      <Path
        fill="#9E9F9E"
        d="M7.733 52.609 83.218 0 7.733 52.609m154.077-.072L83.218 0l78.593 52.537"
      />
      <Path
        fill="#4E2E26"
        d="M83.063 6.837 14.175 54.849l-1.894-.659a2.456 2.456 0 0 1-.597-4.335L81.843.96a2.456 2.456 0 0 1 2.769-.027l73.032 48.82a2.456 2.456 0 0 1-.588 4.372l-2.173.725-71.82-48.012Z"
      />
      <Path
        fill="#76453A"
        d="M99.193 124.84h28.85V67.547h-28.85v57.293Zm-2.95 4.418h34.75V63.13h-34.75v66.128Z"
      />
      <Path
        fill="#291D1A"
        d="M116.302 129.258h-20.06V63.13h34.751v45.805a9.418 9.418 0 0 0-1.74-.009V65.901H97.416v60.584h20.017c-.168.157-.32.325-.453.505-.45.609-.675 1.36-.678 2.268"
      />
      <Path
        fill="#452821"
        d="M99.193 124.84h28.85V67.547h-28.85z"
      />
      <Path
        fill="#3A211B"
        d="M108.169 124.84h-8.976V67.547h28.85v4.08c-1.554.086-3.116.37-4.646.715-3.619.815-7.144 2.673-10.078 5.599-5.352 5.34-7.823 14.875-8.695 23.352-.822 7.996-.033 16.815 3.545 23.547"
      />
      <Path
        fill="#F1A948"
        d="M126.02 96.33c0 1.554-1.175 2.816-2.623 2.816-1.452 0-2.625-1.262-2.625-2.817 0-1.556 1.173-2.815 2.625-2.815 1.448 0 2.624 1.26 2.624 2.815"
      />
      <Path
        fill="#3F241E"
        d="M34.629 107.291h46.89V60.306h-46.89z"
      />
      <Path
        fill="#21170F"
        d="M38.155 103.757h39.837V63.84H38.155z"
      />
      <Path
        fill="#65B7B1"
        d="M40.367 82.69H56.62V66.404H40.367zM59.542 82.69h16.254V66.404H59.542zM40.36 101.192h16.253V84.907H40.359zM59.535 101.192h16.253V84.907H59.535z"
      />
      <Path
        fill="#A0C9C2"
        d="m40.367 67.583 16.246 6.964v.658l-16.246-6.86v-.762M56.62 75.812v.693L40.36 69.87v-.798l16.26 6.74M40.36 70.458l16.26 6.721v.66L40.367 71.22l-.008-.762M40.367 88.58l16.246 6.963v.66l-16.246-6.861v-.762M56.62 96.81v.692l-16.26-6.636v-.796l16.26 6.74M40.36 91.456l16.26 6.721v.659l-16.253-6.618-.008-.762M59.546 87.92l16.245 6.966v.659l-16.245-6.861v-.763M75.8 96.152v.692l-16.26-6.635v-.798l16.26 6.74M59.54 90.798l16.26 6.72v.66l-16.254-6.619-.007-.76M59.547 70.077l16.244 6.966v.657l-16.244-6.86v-.763M75.8 78.306V79l-16.26-6.636v-.797l16.26 6.74M59.54 72.953l16.26 6.721v.66l-16.253-6.619-.008-.762"
      />
      <Path
        fill="#68893D"
        d="M5.328 131.725c.157.46 1.358 3.363 6.526 3.363h.003c.688 0 1.43-.052 2.202-.156.11-.015.221-.024.331-.024h42.86c1.56 0 3.725-.801 5.268-1.951 2.043-1.523 2.164-4.273 1.38-6.322-.907-2.361-2.966-4.076-4.9-4.076-.532 0-1.069.128-1.592.38a2.582 2.582 0 0 0-.498.326 13.05 13.05 0 0 1-2.37 2.04 6.567 6.567 0 0 1-.663.402l-.05.026a2.497 2.497 0 0 1-3.348-1.102 2.537 2.537 0 0 1 1.055-3.388c.02-.01.112-.065.246-.154.245-.17.569-.476.912-.799.228-.216.463-.438.72-.664 2.001-2.365 2.043-5.358.126-8.902-.002 0-2.329-3.983-5.848-3.983-.767 0-1.577.189-2.405.56a2.486 2.486 0 0 1-2.248-.104 2.522 2.522 0 0 1-1.258-1.878c-.104-.756-1.231-7.497-7.894-7.497-.052 0-.114-.002-.167-.006-.011 0-.085-.003-.2-.003-2.624 0-8.737.889-8.829 9.111a2.532 2.532 0 0 1-1.076 2.047 2.484 2.484 0 0 1-2.277.301s-1.13-.383-2.652-.383c-2.85 0-5.095 1.3-6.675 3.864-.333.638-1.718 3.912 2.59 7.177a2.535 2.535 0 0 1 .857 2.857 2.496 2.496 0 0 1-2.444 1.685l-.128-.002c-2.018 0-5.825.53-7.293 2.52-.801 1.087-.891 2.625-.272 4.702l.006.016v.006l.005.011"
      />
      <G transform="translate(34.492 97.841)">
        <Mask
          id="b"
          fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <Path
          fill="#648D90"
          d="M-.61.01h.746v-.03H-.61z"
          mask="url(#b)"
        />
      </G>
    </G>
  </Svg>
);
export default HouseholdSVG;
