import CustomKeyboardAvoidingView from 'src/components/CustomKeyboardAvoidingView';
import { SpText } from '../../../components/SpText/SpText';
import { useDeviceId } from '@hooks/useDeviceId';
import { LoginFormModel } from '@models/Auth';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, ScrollView, StyleSheet, TouchableWithoutFeedback } from 'react-native';

import LoginForm from './components/LoginForm';
import { RootStackParamList } from '../..';
import useBoundStore from '../../../store/store';
import { useReconfirmTermsAndConditions } from '@hooks/useReconfirmTermsAndConditions';
import { WebWrapper } from '../../../components/WebWrapper';

export const Login = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { loginError, login, user } = useBoundStore(state => state.accountStore);
  const languages = useBoundStore(s => s.languageStore.languages);
  const deviceId = useDeviceId();
  const errorMessage = useMemo(() => {
    if (loginError) {
      if (loginError === 401 || loginError === 422) {
        return t('SIGN_ERROR');
      }
      return t('LOST_INTERNET_FIRST');
    }

    return null;
  }, [t, loginError]);

  const { isTermsChanged } = useReconfirmTermsAndConditions(true);
  const isTermsChangedRef = useRef(isTermsChanged);

  useEffect(() => {
    isTermsChangedRef.current = isTermsChanged;
  }, [isTermsChanged]);

  const onSubmit = async (data: LoginFormModel) => {
    const succeeded = await login({ ...data, device_id: deviceId }, languages);

    if (succeeded && !isTermsChangedRef.current) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'DashboardNavigation' }],
      });
    }
  };

  return (
    <TouchableWithoutFeedback
      accessible={false}
      onPress={() => dismissKeyboard()}
    >
      <SafeAreaView style={styles.view}>
        <WebWrapper>
          <CustomKeyboardAvoidingView keyboardShouldPersistTaps="always">
            <ScrollView
              contentContainerStyle={styles.scrollView}
              showsVerticalScrollIndicator={false}
              style={styles.inner}
              keyboardShouldPersistTaps="handled"
            >
              <SpText style={styles.title}>{t('login')}</SpText>

              <LoginForm
                error={errorMessage}
                onSubmit={onSubmit}
              />
            </ScrollView>
          </CustomKeyboardAvoidingView>
        </WebWrapper>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  view: {
    backgroundColor: '#fff',
    flex: 1,
  },
  inner: {
    flex: 1,
    paddingHorizontal: 19,
  },
  scrollView: { flexGrow: 1 },
  title: {
    fontSize: 32,
    marginTop: 20,
    lineHeight: 40,
    fontFamily: 'Rubik_Medium',
    color: colors.greyText.color,
    marginBottom: 10,
  },
});
