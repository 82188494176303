import React from 'react';
import { SpView } from 'src/components/SpView';
import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { AppImages } from '@constants/AppImages';
import SpTitle from 'src/components/SpTitle';
import { useTranslation } from 'react-i18next';
import { SpText } from '../../../../components/SpText/SpText';
import colors from '@styles/colors';
import TourSwipeSVG from 'src/components/SvgIcons/TourSwipeSVG';
import { ScrollView } from 'react-native-gesture-handler';

const { height } = Dimensions.get('window');

const PetHomeSlide = () => {
  const { t } = useTranslation();
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <SpView style={styles.imageWrapper}>
        <View style={[styles.sideImageWrapper, styles.sideImageMarginLeft]}>
          <Image
            style={[styles.leftImage]}
            source={AppImages.petHomeCard}
          />
        </View>

        <Image
          style={[styles.middleImage]}
          source={AppImages.petHomeCard}
        />
        <View style={[styles.sideImageWrapper, styles.sideImageMarginRight]}>
          <Image
            style={[styles.rightImage]}
            source={AppImages.petHomeCard}
          />
        </View>
      </SpView>
      <SpView style={styles.shadowContainer}>
        <View style={[styles.leftShadowWrapper, styles.shadowCommon]} />
        <View style={[styles.middleShadowWrapper, styles.shadowCommon]} />
        <View style={[styles.rightShadowWrapper, styles.shadowCommon]} />
      </SpView>
      <SpTitle
        style={styles.titleText}
        text={t('pet_home')}
        align="center"
      />
      <SpText style={styles.contentText}>{t('pet_home_tour_text')}</SpText>
      <View style={styles.iconWrapper}>
        <TourSwipeSVG />
      </View>
      <SpText style={[styles.contentText2, styles.blueText]}>{t('pet_home_tour_text2')}</SpText>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  imageWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 32,
    paddingBottom: height < 810 ? 6 : 32,
    justifyContent: 'center',
    zIndex: 20,
  },
  sideImageWrapper: {
    zIndex: 10,
    borderRadius: 20,
    opacity: 0.5,
    width: '25%',
    height: 218,
    overflow: 'hidden',
  },
  sideImageMarginLeft: {
    left: -25,
  },
  sideImageMarginRight: {
    right: -25,
  },
  leftImage: {
    resizeMode: 'contain',
    borderRadius: 20,
    width: '150%',
    left: '-45%',
    height: '100%',
  },
  middleImage: {
    height: 256,
    borderRadius: 20,
    width: 185,
  },
  rightImage: {
    resizeMode: 'contain',
    borderRadius: 20,
    width: '150%',
    right: '4%',
    height: '100%',
  },
  titleText: {
    paddingTop: 0,
    paddingBottom: height < 810 ? 3 : null,
    lineHeight: height < 810 ? 25 : null,
  },
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    lineHeight: height < 810 ? 20 : 24,
    textAlign: 'center',
    paddingBottom: height < 810 ? 5 : 24,
    color: colors.greyText.color,
  },
  contentText2: {
    fontSize: 16,
    paddingHorizontal: 19,
    lineHeight: 24,
    textAlign: 'center',
    color: colors.greyText.color,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  blueText: {
    color: '#03A2B1',
    fontSize: 14,
    lineHeight: height < 810 ? 15 : 20,
    marginTop: height < 810 ? 3 : 12,
    fontFamily: 'Rubik_SemiBold',
  },
  shadowContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 32,
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 0,
  },
  middleShadowWrapper: {
    backgroundColor: '#FFFFFF',
    height: 256,
    width: 185,
    elevation: 10,
  },
  leftShadowWrapper: {
    opacity: 0.3,
    width: '25%',
    height: 218,
    left: -25,
    elevation: 20,
  },
  rightShadowWrapper: {
    opacity: 0.3,
    width: '25%',
    height: 218,
    right: -25,
    elevation: 20,
  },
  shadowCommon: {
    backgroundColor: '#FFFFFF',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    borderRadius: 20,
  },
});

export default PetHomeSlide;
