import DeviceApi from '@api/DeviceApi';
import {
  DeviceFeederBowlType,
  DeviceFeederTareType,
  DeviceStatusIDs,
  DeviceType,
  PetDoorLockingMode,
} from '@constants/Device';
import { PetPosition } from '@constants/Pet';
import { TagProfile } from '@constants/Tag';
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { BottomSheetBackdrop, TouchableOpacity } from '@gorhom/bottom-sheet';
import { BackHandler, ScrollView, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import { usePetDevices } from '@hooks/usePetDevices';

import { MergedInterfaces } from '../../../../../../store/models';
import useBoundStore from '../../../../../../store/store';

import { useDeviceById } from '@hooks/useDeviceById';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { usePetsWithTag } from '@hooks/usePetsWithTag';
import { DeviceFeederControlModel, DeviceModel, RequestResult } from '@models/Device';
import { PetModel } from '@models/Pet';
import colors from '@styles/colors';
import i18n from '@utils/i18n';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomBottomSheetModal from 'src/components/CustomBottomSheetModal';
import { SpLockUnlockActions } from 'src/components/SpLockUnlockActions';
import { SpProfileImage } from 'src/components/SpProfileImage';
import { SpSwitch } from 'src/components/SpSwitch';
import { SpVStack } from 'src/components/SpVStack';
import { SpView } from 'src/components/SpView';
import { SpZeroScalesActions, ZeroScalesActionModel } from 'src/components/SpZeroScalesActions';
import { SpZeroScalesUpdateStatusMessage } from 'src/components/SpZeroScalesUpdateStatusMessage';
import IndoorsSVG from 'src/components/SvgIcons/IndoorsSVG';
import OutdoorsSVG from 'src/components/SvgIcons/OutdoorsSVG';

import LoadingSpinner from '../../../../../../components/Loader/Loader';
import { SpText } from '../../../../../../components/SpText/SpText';
import useLastDate from 'src/pages/Dashboard/Products/Device/hooks/useLastDate';
import { getSingleDuration } from '@utils/date';

interface PetCardQuickActionsProps {
  pet: PetModel;
  indoorOnlyModeDevices: DeviceModel[];
  changeLocationDevices: DeviceModel[];
  zeroBowlDevices: DeviceModel[];
  isOpen: boolean;
  onUpdateLocation: (data: PetPosition) => void;
  onDismiss: () => void;
  isLocationUpdating: boolean;
  handleAndroidBackPress?: () => boolean;
}

export const PetCardQuickActions = ({
  isOpen,
  pet,
  onDismiss,
  onUpdateLocation,
  indoorOnlyModeDevices,
  changeLocationDevices,
  zeroBowlDevices,
  isLocationUpdating,
  handleAndroidBackPress,
}: PetCardQuickActionsProps) => {
  const { t } = useTranslation();
  const snapPoints = useMemo(() => ['40%'], []);
  const {
    updateZeroScalesAsync,
    updateProperty,
    setUpdateZeroScalesLoading,
    setUpdateZeroScalesError,
    setUpdateZeroScalesSuccess,
    updateZeroScalesLoading,
    updateZeroScalesError,
    updateZeroScalesSuccess,
    updateTagProfileLoading,
    asyncRequestState,
    updateDevice,
    lastZeroBowlTime,
    setLastZeroBowlTime,
    activeHousehold,
  } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore, householdStore } = state;

    return {
      updateZeroScalesAsync: deviceStore.updateZeroScalesAsync,
      updateZeroScalesError: deviceStore.updateZeroScalesError,
      updateZeroScalesLoading: deviceStore.updateZeroScalesLoading,
      updateZeroScalesSuccess: deviceStore.updateZeroScalesSuccess,
      setUpdateZeroScalesLoading: deviceStore.setUpdateZeroScalesLoading,
      setUpdateZeroScalesError: deviceStore.setUpdateZeroScalesError,
      setUpdateZeroScalesSuccess: deviceStore.setUpdateZeroScalesSuccess,
      loadingDevice: deviceStore.loadingDevice,
      updateProperty: deviceStore.updateProperty,
      updateTagProfileLoading: deviceStore.updateTagProfileLoading,
      asyncRequestState: deviceStore.asyncRequestState,
      tagAssignmentStatus: deviceStore.tagAssignmentStatus,
      updateDevice: deviceStore.updateDevice,
      lastZeroBowlTime: deviceStore.lastZeroBowlTime,
      setLastZeroBowlTime: deviceStore.setLastZeroBowlTime,
      activeHousehold: householdStore.activeHousehold,
    };
  });
  const { loadingControl, davidsUpdateTagProfile } = useBoundStore(s => s.deviceStore);
  const [petPositionLoader, setPetPositionLoader] = useState(false);
  const [updatedDevice, setUpdatedDevice] = useState(null);
  const [localLoader, setLocalLoader] = useState(false);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      return !!handleAndroidBackPress && handleAndroidBackPress();
    });
    return () => {
      backHandler.remove();
    };
  }, [handleAndroidBackPress]);

  useEffect(() => {
    if (!asyncRequestState[zeroBowlDevices[0]?.id]) return;
    const lastStatus = asyncRequestState[zeroBowlDevices[0]?.id].filter(item => {
      if (item.state) {
        return Object?.keys(item.state)?.includes('tare');
      } else return false;
    })[
      asyncRequestState[zeroBowlDevices[0]?.id]?.filter(item => {
        if (item.state) {
          return Object?.keys(item.state)?.includes('tare');
        } else return false;
      })?.length - 1
    ];
    if (!lastStatus) return;

    if (lastStatus.status === DeviceStatusIDs.Pending) {
      setUpdateZeroScalesLoading(true);
    } else {
      setUpdateZeroScalesLoading(false);
    }
    if (lastStatus.status === DeviceStatusIDs.Success) {
      setUpdateZeroScalesSuccess(true);
    } else {
      setUpdateZeroScalesSuccess(false);
    }
    // if status is an error and requested_at is within the last hour
    const fiveMinsInMS = 300000;
    if (
      (lastStatus.status === DeviceStatusIDs.DeviceError ||
        lastStatus.status === DeviceStatusIDs.ServerError ||
        lastStatus.status === DeviceStatusIDs.NoChange) &&
      new Date(lastStatus.requested_at).getTime() > new Date().getTime() - fiveMinsInMS
    ) {
      setUpdateZeroScalesError(lastStatus.status);
    } else {
      setUpdateZeroScalesError(null);
    }
  }, [asyncRequestState[zeroBowlDevices[0]?.id]]);

  const petDevices = usePetDevices(pet);

  const flap = petDevices.find(elem => elem.product_id === DeviceType.CatFlapConnect);
  const feeder = petDevices.find(elem => elem.id === zeroBowlDevices[0]?.id);
  const device = useDeviceById(flap?.id);
  const pets = usePetsByHousehold();
  const petsWithTag = usePetsWithTag(device, pets);

  useDeviceStatusHook(flap?.id, true);
  useDeviceStatusHook(zeroBowlDevices[0]?.id, true);

  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [refreshingTags, setRefreshingTags] = useState(false);

  const lastZeroed = useLastDate(updatedDevice?.status || feeder?.status, 'last_zeroed_at');

  const onSelect = (bowlType: DeviceFeederTareType) => {
    const fetch = async () => {
      setLocalLoader(true);
      await updateZeroScalesAsync(feeder?.id, bowlType);
      setLastZeroBowlTime(+new Date());
    };

    if (!updateZeroScalesLoading) {
      fetch();
    }
  };

  useEffect(() => {
    const upd = async () => {
      const devices = await DeviceApi.getDevices(activeHousehold?.id);
      const currentFeeder = devices.filter(elem => elem.id === feeder?.id)[0];
      setUpdatedDevice(currentFeeder);
      await updateDevice(currentFeeder);
      setLocalLoader(false);
    }
    if (!loadingControl.tare) {
      upd()
    }
  }, [loadingControl.tare])

  const [checked, setChecked] = useState<RequestResult>('none');

  useEffect(() => {
    if (isLoadingTags && !loadingControl.tag_profiles?.some(item => item.tag_id === pet.tag_id)) {
      setRefreshingTags(true);
      DeviceApi.getDeviceTags(device.id).then(res => {
        const newDevice = { ...device, tags: res };
        updateDevice(newDevice);
        setRefreshingTags(false);
      });
    }
  }, [isLoadingTags, loadingControl]);
  useEffect(() => {
    const fastPollingDevices = petDevices.map(item => {
      DeviceApi.enableFastPolling(item.id);
      console.log('enable fast polling', item.id);
    });
    Promise.all(fastPollingDevices);
  }, [petDevices]);
  useEffect(() => {
    return () => {
      if (!loadingControl.tag_profiles?.some(item => item.tag_id === pet.tag_id)) {
        DeviceApi.getDeviceTags(device.id).then(res => {
          const newDevice = { ...device, tags: res };
          updateDevice(newDevice);
        });
      }
    };
  }, []);

  useEffect(() => {
    setIsLoadingTags(
      loadingControl.tag_profiles?.length > 0 &&
        loadingControl.tag_profiles.some(item => item.tag_id === pet.tag_id),
    );
  }, [loadingControl?.tag_profiles]);

  const currPet = petsWithTag.find(elem => elem.pet.tag_id === pet.tag_id);
  const locationOption = useMemo(() => {
    if (changeLocationDevices.length) {
      const petWhere = pet?.status?.activity?.where;
      const outdoor = (
        <OutdoorsSVG
          color={colors.greyText.color}
          width={21}
          height={21}
          viewBox="0 0 17 17"
        />
      );
      const indoor = (
        <IndoorsSVG
          color={colors.greyText.color}
          width={23}
          height={23}
          viewBox="0 0 17 17"
        />
      );
      const getPosition = () => {
        switch (petWhere) {
          case PetPosition.inside:
            return t('outside');
          case PetPosition.outside:
            return t('inside');
          default:
            return '';
        }
      };

      if (petWhere === PetPosition.none || !petWhere) {
        return [
          {
            icon: indoor,
            text: t('change_pet_location {{position}}', {
              position: t('inside'),
            }),
            id: PetDoorLockingMode.inside_only,
          },
          {
            icon: outdoor,
            text: t('change_pet_location {{position}}', {
              position: t('outside'),
            }),
            id: PetDoorLockingMode.outside_only,
          },
        ];
      }
      return [
        {
          icon: petWhere === PetPosition.inside ? outdoor : indoor,
          text: t('change_pet_location {{position}}', {
            position: getPosition(),
          }),
          id:
            pet.status.activity?.where === PetPosition.inside
              ? PetPosition.outside
              : PetPosition.inside,
        },
      ];
    }

    return [];
  }, [changeLocationDevices, pet, isLocationUpdating]);

  const zeroBowlOption: ZeroScalesActionModel[] = useMemo(() => {
    if (zeroBowlDevices.length) {
      const type = (zeroBowlDevices[0].control as DeviceFeederControlModel)?.bowls?.type;

      return [
        {
          id:
            type === DeviceFeederBowlType.Single
              ? DeviceFeederTareType.Left
              : DeviceFeederTareType.Both,
          text:
            type === DeviceFeederBowlType.Single ? i18n.t('zero_bowl') : i18n.t('zero_both_bowls'),
          image: [],
        },
      ];
    }

    return [];
  }, [zeroBowlDevices]);

  const indoorOnlyModeAction = async () => {
    if (flap) {
      if (checked === 'error') {
        setChecked('success');
        await davidsUpdateTagProfile(flap?.id, pet, TagProfile.Safe);
      } else {
        setChecked('error');
        await davidsUpdateTagProfile(flap?.id, pet, TagProfile.Permitted);
      }
    }
  };

  useLayoutEffect(() => {
    if (flap && checked === 'none') {
      const index = flap.tags.findIndex(item => item.id === pet.tag_id);
      if (index === -1) return;
      if (loadingControl.tag_profiles || updateTagProfileLoading) return;
      setChecked(flap?.tags[index]?.profile === TagProfile.Safe ? 'success' : 'error');
    }
  }, [checked, flap, loadingControl]);

  useEffect(() => {
    updateProperty({
      updateZeroScalesLoading: false,
    });
    return () => updateProperty({ updateTagProfileResult: 'none' });
  }, []);

  const component = useMemo(() => {
    return () => (
      <Animated.View style={styles.avatarContainer}>
        <SpView style={styles.avatar}>
          <SpProfileImage
            width={46}
            height={46}
            imageUrl={pet?.photo?.location}
          />
        </SpView>
      </Animated.View>
    );
  }, [pet]);

  const indoorModeChecking = useMemo(() => {
    if (
      updateTagProfileLoading ||
      loadingControl.tag_profiles?.filter(
        item => item === pet.tag_id || item?.tag_id === pet.tag_id,
      )?.length > 0
    ) {
      return true;
    }
    return false;
  }, [updateTagProfileLoading, loadingControl, isLocationUpdating]);

  return (
    <SpView>
      <CustomBottomSheetModal
        opened={isOpen}
        backdropComponent={BottomSheetBackdrop}
        index={0}
        handleComponent={component}
        snapPoints={snapPoints}
        onDismiss={() => {
          updateProperty({
            updateZeroScalesError: null,
            updateZeroScalesSuccess: null,
            updateZeroScalesLoading: false,
          });
          onDismiss();
        }}
        inScrollView
      >
        <ScrollView>
          <SpView style={styles.header}>
            <SpView
              flexDirection="row"
              alignItems="center"
            >
              <SpView marginRight={4}>
                <FontAwesomeIcon
                  color={colors.greyText.color}
                  size={22}
                  icon={faBoltLightning}
                />
              </SpView>

              <SpText
                size="xxl"
                color={colors.greyText.color}
                fontFamily="Rubik_Medium"
                align="center"
              >
                {t('quick_actions')}
              </SpText>
            </SpView>
          </SpView>

          <SpView
            paddingHorizontal={19}
            paddingBottom={36}
          >
            {updateZeroScalesLoading ||
            !!loadingControl.tare ||
            updateTagProfileLoading ||
            !!loadingControl.tag_profiles?.length ||
            petPositionLoader ||
            localLoader ||
            isLocationUpdating ? (
              <SpView
                paddingBottom={19}
                alignItems="center"
              >
                <LoadingSpinner />
              </SpView>
            ) : null}

            <SpVStack space={10}>
              {!!zeroBowlDevices.length && (
                <SpZeroScalesUpdateStatusMessage
                  isQuickAction
                  localLoader={localLoader}
                  loading={!!loadingControl.tare || updateZeroScalesLoading}
                  success={!!updateZeroScalesSuccess}
                  error={updateZeroScalesError?.error}
                  lastZeroed={
                    typeof lastZeroed === 'string'
                      ? lastZeroed
                      : getSingleDuration(new Date(lastZeroed).toISOString())
                  }
                  convertedLastZeroed={lastZeroBowlTime}
                />
              )}

              {!!changeLocationDevices.length && (
                <SpLockUnlockActions
                  textStyle={{ fontSize: 16 }}
                  buttons={locationOption}
                  onSelect={onUpdateLocation}
                  disabled={isLocationUpdating}
                  position={pet?.status?.activity?.where}
                  loading={isLocationUpdating}
                  setPetPositionLoader={setPetPositionLoader}
                />
              )}

              {!!indoorOnlyModeDevices.length && (
                <SpView style={styles.indoorOnlyContainer}>
                  <SpView>
                    <SpText fontFamily="Rubik_Medium">{t('indoor_mode_title')}</SpText>
                    <SpView marginTop={4}>
                      {!indoorModeChecking && !refreshingTags && (
                        <SpText
                          size="sm"
                          color={
                            currPet.pet.profile === TagProfile.Safe
                              ? colors.toxicGreen.color
                              : colors.errorRed.color
                          }
                          fontFamily="Rubik_Medium"
                        >
                          {currPet.pet.profile === TagProfile.Safe ? t('enabled') : t('disabled')}
                        </SpText>
                      )}
                    </SpView>
                  </SpView>
                  <SpView>
                    <TouchableOpacity
                      onPress={indoorOnlyModeAction}
                      activeOpacity={1}
                    >
                      {indoorModeChecking || refreshingTags ? (
                        <LoadingSpinner />
                      ) : (
                        <SpSwitch
                          disabled
                          checked={currPet.pet.profile === TagProfile.Safe}
                          onChange={null}
                          customStyle={{ opacity: 1 }}
                        />
                      )}
                    </TouchableOpacity>
                  </SpView>
                </SpView>
              )}

              {!!zeroBowlDevices.length && (
                <SpZeroScalesActions
                  textStyle={{ fontSize: 16 }}
                  buttons={zeroBowlOption}
                  onSelect={onSelect}
                  isLoading={localLoader}
                />
              )}
            </SpVStack>
          </SpView>
        </ScrollView>
      </CustomBottomSheetModal>
    </SpView>
  );
};

const styles = StyleSheet.create({
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 26,
    marginTop: 10,
    position: 'relative',
  },
  avatarContainer: {
    alignItems: 'center',
    marginTop: -20,
  },
  avatar: {
    backgroundColor: colors.white.color,
    borderRadius: 50,
  },
  indoorOnlyContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    borderRadius: 16,
  },
});
