import React, { PropsWithChildren, useMemo } from 'react';
import { FlexAlignType, StyleProp, StyleSheet, View } from 'react-native';

export interface SpViewProps {
  flex?: number;
  flexDirection?: 'row' | 'column';
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch'
    | 'space-between'
    | 'space-around'
    | undefined;
  alignItems?: FlexAlignType;
  alignSelf?: 'auto' | FlexAlignType;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | undefined;
  paddingVertical?: number | string;
  paddingHorizontal?: number | string;
  paddingLeft?: number | string;
  paddingRight?: number | string;
  paddingBottom?: number;
  paddingTop?: number | string;
  padding?: number | string;
  marginTop?: number | string;
  marginRight?: number | string;
  marginLeft?: number | string;
  marginBottom?: number | string;
  marginHorizontal?: number | string;
  marginVertical?: number | string;
  borderRadius?: number;
  gap?: number;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  height?: number | string;
  minHeight?: number | string;
  backgroundColor?: string;
  testID?: string;
  accessibilityLabel?: string;
  accessible?: boolean;
  style?: StyleProp<unknown>;
}

export const SpView = ({
  children,
  style = {},
  testID = null,
  accessibilityLabel = null,
  accessible = null,
  ...props
}: PropsWithChildren<SpViewProps>) => {
  const viewStyle = useMemo(() => {
    return StyleSheet.flatten([props, style]);
  }, [props, style]);
  return (
    <View
      accessible={accessible}
      accessibilityLabel={accessibilityLabel}
      testID={testID}
      style={viewStyle}>
      {children}
    </View>
  );
};
