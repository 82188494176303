import { SpLoading } from 'src/components/SpLoading';
import { DeviceType, PetBowlFoodType, SubstanceType } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import { useCustomToast } from '@hooks/useToast';
import { DeviceCerberusControlModel, DeviceFeederControlModel } from '@models/Device';
import { StackScreenProps } from '@react-navigation/stack';
import SpAlert from '@utils/alert';
import i18n from '@utils/i18n';
import { cloneDeep } from 'lodash-es';
import React, { useCallback, useEffect } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { MergedInterfaces } from '../../../../../../store/models';
import useBoundStore from '../../../../../../store/store';
import { DeviceStackParamList } from '../../index';
import { FeederBowlSetup } from '../../components/DeviceBowlSetup/FeederBowlSetup';
import { DeviceSettingContainer } from '../../components/DeviceSettingContainer';
import { CerberusBowlSetup } from '../../components/DeviceBowlSetup/CerberusBowlSetup';

export const DeviceBowlSetup = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceBowlSetup'>) => {
  const { id } = route.params;
  const device = useDeviceById(id);
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const { loadingDevice, resetStatusFields, updateCerberusControl } = useBoundStore(
    (state: MergedInterfaces) => {
      const { deviceStore } = state;
      return {
        loadingDevice: deviceStore.loadingDevice,
        resetStatusFields: deviceStore.resetStatusFields,
        updateCerberusControl: deviceStore.updateCerberusControl,
      };
    },
  );

  const confirmChangeModal = useCallback((callback: () => void) => {
    SpAlert(
      i18n.t('confirm_food_type_change_title'),
      i18n.t('confirm_food_type_change_description'),
      [
        {
          text: i18n.t('confirm'),
          onPress: async () => {
            callback();
            navigation.goBack();
            return true;
          },
        },
        { text: i18n.t('cancel'), onPress: () => true },
      ],
    );
  }, []);

  const changeCerberusFoodTypeAction = useCallback(
    ($event: PetBowlFoodType) => {
      const control = { food_type: $event } as DeviceCerberusControlModel;
      updateCerberusControl(device.id, control);
    },
    [device.id, updateCerberusControl],
  );

  const changeSubstanceTypeAction = useCallback(
    ($event: SubstanceType) => {
      const control: DeviceCerberusControlModel = cloneDeep(
        device.control as DeviceCerberusControlModel,
      );

      control.substance_type = $event;
      updateCerberusControl(device.id, control);
    },
    [device, updateCerberusControl],
  );

  useEffect(() => resetStatusFields, []);

  if (loadingDevice || !device) {
    return <SpLoading />;
  }
  const bowlSetupForProduct = (productType: DeviceType) => {
    switch (productType) {
      case DeviceType.FeederConnect:
        return (
          <FeederBowlSetup
            data={(device?.control as DeviceFeederControlModel)?.bowls}
            confirmChangeModal={confirmChangeModal}
            id={route.params.id}
          />
        );
      case DeviceType.Cerberus:
        return (
          <CerberusBowlSetup
            data={device?.control as DeviceCerberusControlModel}
            onChangeFoodType={changeCerberusFoodTypeAction}
            onChangeSubstanceType={changeSubstanceTypeAction}
          />
        );
      default:
        return null;
    }
  };
  return <DeviceSettingContainer>{bowlSetupForProduct(device.product_id)}</DeviceSettingContainer>;
};
