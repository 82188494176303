import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { AppImages } from '@constants/AppImages';
import { SpCarouselWithSteps } from 'src/components/CarouselWithSteps';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, ImageSourcePropType, StyleSheet } from 'react-native';

import RedTimer5sSVG from '../../../components/SvgIcons/RedTimer5sSVG';

export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = 280;

interface ConfigureCDBCarouselWaterProps {
  enableConfigureCDBCarouselWaterContinue: (data: number) => void;
  setNumberItems: (data: number) => void;
}

export const ConfigureCDBCarouselWater = ({
  enableConfigureCDBCarouselWaterContinue,
  setNumberItems,
}: ConfigureCDBCarouselWaterProps) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return [
      {
        text: t('setup_configure_water_cdb_carousel_1'),
        image: AppImages.cdbConfigureWaterCarousel1,
      },
      {
        text: t('setup_configure_water_cdb_carousel_2'),
        image: AppImages.cdbConfigureWaterCarousel2,
      },
      {
        text: t('setup_configure_water_cdb_carousel_3'),
        image: AppImages.cdbConfigureWaterCarousel3,
      },
      {
        text: t('setup_configure_water_cdb_carousel_4'),
        image: AppImages.cdbConfigureWaterCarousel4,
      },
      {
        text: t('setup_configure_water_cdb_carousel_5'),
        image: AppImages.cdbConfigureInitital,
        customHeader: <RedTimer5sSVG />,
        customItemStyle: { borderColor: colors.errorRed.color },
      },
    ];
  }, []);
  useEffect(() => {
    setNumberItems(items.length);
  }, []);

  const renderCartItem = useCallback(
    (props: {
      item: {
        text: string;
        image: ImageSourcePropType;
        customHeader?: ReactNode;
        customItemStyle?: Record<string, string | number>;
      };
    }) => {
      return (
        <SpView
          accessible
          style={[styles.item, props.item.customItemStyle ?? {}]}>
          {!!props.item.customHeader && (
            <SpView style={styles.header}>{props.item.customHeader}</SpView>
          )}
          <Image
            accessible
            style={styles.image}
            source={props.item.image}
            resizeMode="contain"
            {...testProperties(`${props.item.text}`, 'image')}
          />
          <SpText
            style={[styles.text, { marginBottom: 24 }]}
            size="sm"
            color={colors.black.color}>
            {props.item.text}
          </SpText>
        </SpView>
      );
    },
    [],
  );

  return (
    <SpView style={styles.container}>
      <SpView>
        <SpText style={styles.title}>{t('using_the_bowl')}</SpText>
      </SpView>
      <SpView>
        <SpText style={styles.description}>{t('follow_new_dog_bowl_connect')}</SpText>
      </SpView>
      <SpCarouselWithSteps
        height="77%"
        renderItem={renderCartItem}
        data={items}
        sliderWidth={SLIDER_WIDTH}
        slideWidth={ITEM_WIDTH}
        onLastTemplate={enableConfigureCDBCarouselWaterContinue}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: '3%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    marginTop: 8,
    textAlign: 'center',
    fontFamily: 'Rubik_SemiBold',
  },
  image: {
    width: 240,
    height: 176,
  },
  item: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: 'space-around',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
    marginBottom: '6%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingVertical: 10,
  },
  description: {
    fontSize: 14,
    paddingTop: 20,
    paddingBottom: 10,
    textAlign: 'center',
  },
  header: {
    alignItems: 'center',
  },
});
