import { PetBioMenu } from '@constants/Menus';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { NativeStackNavigationProp } from 'react-native-screens/lib/typescript/native-stack';
import useBoundStore from 'src/store/store';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { convertToGramsOrOz } from '@utils/convertToGramsOrOz';
import { useReturnHandler } from './useReturnHandler';
import { useUserWeightUnits } from './useUserWeightUnits';
import { useBreedsAndSpeciesHandling } from './useBreedsAndSpeciesHandling';
import { Alert } from 'react-native';
import { useTranslation } from 'react-i18next';

const CI_WARNING_FIELDS = ['species_id', 'food_type_id', 'gender', 'spayed', 'weight'];

export const useUpdatePet = (
  updateButtonLoading: Dispatch<SetStateAction<boolean>>,
  updateButtonDisabled: Dispatch<React.SetStateAction<boolean>>,
  inputName: string,
  navigation: NativeStackNavigationProp<any>,
): ((value: any) => void) => {
  const { t } = useTranslation();
  const COMMA_REGEX = /,/g;
  const { activePet, updatePet, petStatistics } = useBoundStore(state => state.petStore);

  const isPetWithCI = useMemo(() => {
    const stats = petStatistics.find(stat => stat.pet_id === activePet.id);
    return stats?.consumption_habit?.length > 0;
  }, [petStatistics, activePet]);

  const userWeightUnits = useUserWeightUnits();
  const handler = useReturnHandler(inputName === PetBioMenu.Name ? 'pet_name' : inputName);
  const { handleSpecie } = useBreedsAndSpeciesHandling();

  const updatePetHandler = async (val: any) => {
    try {
      updateButtonLoading(true);
      updateButtonDisabled(true);
      AnalyticsService.logEvent(`pet_update_${inputName}`);
      await updatePet(activePet.id, {
        ...activePet,
        [inputName]:
          inputName === PetBioMenu.Weight
            ? convertToGramsOrOz(Number(val.replace(COMMA_REGEX, '.')), userWeightUnits).toFixed(0)
            : val,
      });
      if (inputName === 'species_id') {
        handleSpecie('breed_id', false);
        handleSpecie('breed_id2', false);
      }
      if (inputName === 'breed_id') {
        handleSpecie('breed_id', true);
      }
      if (inputName === 'breed_id2') {
        handleSpecie('breed_id2', true);
      }

      navigation.pop();
      handler();
    } catch (err: any) {
      navigation.pop();
      handler(err.code);
    }
  };

  return val => {
    if (isPetWithCI && CI_WARNING_FIELDS.includes(inputName)) {
      Alert.alert(
        t('pet_profile_update'),
        t(
          inputName === PetBioMenu.Weight
            ? 'pet_profile_update_description_weight'
            : 'pet_profile_update_description',
        ),
        [
          {
            text: t('cancel'),
            style: 'cancel',
          },
          {
            text: t('continue'),
            style: 'destructive',
            onPress: () => updatePetHandler(val),
          },
        ],
      );

      return;
    }

    updatePetHandler(val);
  };
};
