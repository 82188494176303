/* eslint-disable react/jsx-no-useless-fragment */
import { DeviceFeederTareType } from '@constants/Device';
import noop from '@utils/noop';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { Image, ImageSourcePropType, Pressable, StyleSheet } from 'react-native';
import { SpVStack } from '../SpVStack';
import { SpText } from '../SpText/SpText';
import { SpView } from '../SpView';
import { SpZeroIcon } from '../RoundIcons/SpZeroIcon';
import colors from '@styles/colors';
import LoadingSpinner from '../Loader/Loader';

export interface ZeroScalesActionModel {
  id: DeviceFeederTareType;
  text: string;
  image: ImageSourcePropType[];
}

interface ZeroScalesActionsProps {
  buttons: ZeroScalesActionModel[];
  textStyle?: React.CSSProperties;
  onSelect: (data: DeviceFeederTareType) => void;
  isLoading?: boolean;
}

export const SpZeroScalesActions = ({
  buttons,
  textStyle = null,
  onSelect,
  isLoading = false,
}: ZeroScalesActionsProps) => {
  return (
    <SpVStack space={10}>
      {buttons.map(item => {
        return (
          <Pressable
            onPress={() => onSelect(item.id)}
            key={item.id}
            style={styles.button}>
            <SpText
              size="xl"
              fontFamily="Rubik_Medium"
              style={[{ flex: 6 }, textStyle]}>
              {item.text}
            </SpText>
            <SpView
              style={styles.images}
              flex={item.image.length}>
              {item.image.map((image, ind) => (
                <Image
                  key={ind}
                  style={styles.bowlImage}
                  source={image}
                  {...testProperties('bowlImage', 'image')}
                />
              ))}
            </SpView>
            {isLoading ? <LoadingSpinner /> : <SpZeroIcon action={noop} />}
          </Pressable>
        );
      })}
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: colors.secondary3.color,
    borderRadius: 16,
  },
  bowlImage: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  images: {
    flexDirection: 'row',
    marginLeft: 14,
  },
});
