import { ZeroScalesActionModel } from 'src/components/SpZeroScalesActions';
import i18n from '@utils/i18n';
import { AppImages } from './AppImages';
import { DeviceFeederBowlType, DeviceFeederTareType } from './Device';

export const ZeroScalesActions: ZeroScalesActionModel[] = [
  {
    id: DeviceFeederTareType.Left,
    text: i18n.t('zero_left'),
    image: [AppImages.leftBowl],
  },
  {
    id: DeviceFeederTareType.Right,
    text: i18n.t('zero_right'),
    image: [AppImages.rightBowl],
  },
  {
    id: DeviceFeederTareType.Both,
    text: i18n.t('zero_both'),
    image: [AppImages.leftBowl, AppImages.rightBowl],
  },
];

export const getZeroScalesActions = (type: DeviceFeederBowlType): ZeroScalesActionModel[] => {
  if (type === DeviceFeederBowlType.Single) {
    const left = ZeroScalesActions.find(item => item.id === DeviceFeederTareType.Left);

    return [
      {
        ...left,
        text: i18n.t('zero'),
        image: [],
      },
    ];
  }

  return ZeroScalesActions;
};
