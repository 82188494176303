import { useMemo } from 'react';

import { ReportRangeType } from '../pages/Dashboard/constants/ReportRangeType';

export const useViewDays = (range: ReportRangeType) => {
  return useMemo(() => {
    return [ReportRangeType.SevenDays, ReportRangeType.TwentyEightDays].includes(range)
      ? range
      : ReportRangeType.SevenDays;
  }, [range]);
};
