import { Linking, StyleSheet } from 'react-native';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import text from '@styles/text';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { useTranslation } from 'react-i18next';
import useBoundStore from '../../../store/store';
import CDBConnectingSVG from '../../../components/SvgIcons/CDBConnectingSVG';
import LoadingComponent from '../components/LoadingComponent';
import CDBPairHouseholdSVG from '../../../components/SvgIcons/CDBPairHouseholdSVG';
import CDBPairHouseholdSuccessSVG from '../../../components/SvgIcons/CDBPairHouseholdSuccessSVG';
import CDBPairHouseholdFailedSVG from '../../../components/SvgIcons/CDBPairHouseholdFailedSVG';
import colors from '@styles/colors';
import { SpProgressBar } from '../../../components/SpProgressBar';
import SpBadge from '../../../components/RoundIcons/SpBadge';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { useIsFocused } from '@react-navigation/native';


interface PairingToHouseholdStepProps {
  isLoading: boolean;
  connected: boolean;
  stepActive: boolean;
}
enum StepsEnum {
  CDBConnecting,
  CDBFirmwareUpdate,
  CDBPairHousehold,
  CDBPairHouseholdSuccess,
  CDBPairHouseholdFailed,
}

export const PairingToHouseholdStep = ({ isLoading, stepActive }: PairingToHouseholdStepProps) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState<StepsEnum>(StepsEnum.CDBConnecting);
  const {
    error,
    loading,
    stopSearch,
    closeConnection,
    WiFiDeviceFirmwareUpdate,
    WiFiDeviceFirmwareUpdateState,
    WiFiDeviceConnectedToServer,
    pairedDeviceDeviceID,
    startPairDeviceToHousehold,
    WiFiDeviceConnectedToNetwork,
    WiFiDeviceConnectedToInternet,
    WiFiDeviceConnectedToMqtt,
    pairingToHousehold,
  } = useBoundStore(state => state.WiFiDeviceStore);
const isFocused = useIsFocused();
  const StepImage = useMemo(() => {
    switch (step) {
      case StepsEnum.CDBConnecting:
        return <CDBConnectingSVG />;
      case StepsEnum.CDBFirmwareUpdate:
        return <LoadingComponent />;
      case StepsEnum.CDBPairHousehold:
        return <CDBPairHouseholdSVG />;
      case StepsEnum.CDBPairHouseholdSuccess:
        return <CDBPairHouseholdSuccessSVG />;
      case StepsEnum.CDBPairHouseholdFailed:
        return <CDBPairHouseholdFailedSVG />;
      default:
        return <CDBConnectingSVG />;
    }
  }, [step]);

  const StepInfo = useMemo(() => {
    let title: string | null;
    let description: string | null = null;
    console.log(step, 'STEP!!!!');
    switch (step) {
      case StepsEnum.CDBConnecting:
        title = t('cdb_connecting_the_dog_bowl');
        break;
      case StepsEnum.CDBFirmwareUpdate:
        title = t('cdb_updating_firmware');
        description = t('cdb_updating_firmware_description');
        break;
      case StepsEnum.CDBPairHousehold:
        title = t('cdb_adding_to_the_household');
        break;
      case StepsEnum.CDBPairHouseholdSuccess:
        title = t('cdb_all_connected');
        break;
      case StepsEnum.CDBPairHouseholdFailed:
        title = t('cdb_failed_to_pair_to_household');
        break;
      default:
        title = t('cdb_connecting_the_dog_bowl');
        break;
    }
    return (
      <>
        {title && <SpText style={[text.title, styles.title]}>{title}</SpText>}
        {description && <SpText style={[text.body, styles.description]}>{description}</SpText>}
      </>
    );
  }, [step, t]);

  const StepProgress = useMemo(() => {
    if (step === StepsEnum.CDBConnecting) return <></>;
    const backGroundColor =
      step === StepsEnum.CDBPairHouseholdFailed ? colors.errorRed.color : colors.primary.color;
    let progress = 0;
    if (step === StepsEnum.CDBFirmwareUpdate)
      progress = Math.round(WiFiDeviceFirmwareUpdateState * 0.74);
    if (step === StepsEnum.CDBPairHousehold) progress = 75;
    if (step === StepsEnum.CDBPairHouseholdSuccess) progress = 100;
    if (step === StepsEnum.CDBPairHouseholdFailed) progress = 79;
    return (
      <>
        <SpView
          width={'70%'}
          height={12}
          style={styles.center}>
          <SpProgressBar
            value={progress}
            width={'100%'}
            borderRadius={16}
            indicatorColors={[backGroundColor, backGroundColor]}
          />
        </SpView>
        <SpBadge
          width={60}
          badgeWrapperStyle={{ borderRadius: 30 }}
          text={`${progress}%`}
          color={backGroundColor}
        />
      </>
    );
  }, [WiFiDeviceFirmwareUpdateState, step]);

  const StepHint = useMemo(() => {
    return (
      <>
        <SpText style={[text.body, styles.text]}>{t('setup_cdb_failed_to_pair_mode_help')}</SpText>
        <SpText
          style={[text.linkText, styles.text]}
          onPress={() => Linking.openURL(`https://${t('setup_cdb_failed_to_pair_link')}`)}>
          {t('setup_cdb_failed_to_pair_link')}
        </SpText>
      </>
    );
  }, [t]);


  useLayoutEffect(() => {
    if (
      !stepActive ||
      step === StepsEnum.CDBConnecting ||
      WiFiDeviceFirmwareUpdate ||
      WiFiDeviceFirmwareUpdateState === 100 ||
      !isFocused
    )
      return;
    setStep(StepsEnum.CDBConnecting);
  }, [WiFiDeviceFirmwareUpdate, WiFiDeviceFirmwareUpdateState, isFocused, step, stepActive]);

  useEffect(() => {
    if (!stepActive || step === StepsEnum.CDBFirmwareUpdate || !WiFiDeviceFirmwareUpdate) return;
    setStep(StepsEnum.CDBFirmwareUpdate);
  }, [WiFiDeviceFirmwareUpdate, stepActive, step]);

  useEffect(() => {
    if (!stepActive || step >= StepsEnum.CDBPairHousehold) return;
    if (
      WiFiDeviceFirmwareUpdateState === 100 &&
      !error &&
      !loading &&
      WiFiDeviceConnectedToServer &&
      !pairedDeviceDeviceID
    ) {
      setStep(StepsEnum.CDBPairHousehold);
      closeConnection().then(() => {
        setTimeout(() => {
          startPairDeviceToHousehold();
        }, 25000);
      });
    }
  }, [
    WiFiDeviceConnectedToInternet,
    WiFiDeviceConnectedToNetwork,
    WiFiDeviceConnectedToServer,
    WiFiDeviceFirmwareUpdateState,
    // WiFiDeviceConnectedToMqtt,
    closeConnection,
    error,
    loading,
    pairedDeviceDeviceID,
    startPairDeviceToHousehold,
    step,
    stepActive,
  ]);

  useEffect(() => {
    if (!stepActive || step === StepsEnum.CDBPairHouseholdSuccess || !pairedDeviceDeviceID) return;
    setStep(StepsEnum.CDBPairHouseholdSuccess);
    AnalyticsService.logEvent('AddDogBowl - useEffect - PairingToHouseholdStep - success');
  }, [pairedDeviceDeviceID, step, stepActive]);

  useEffect(() => {
    if (!stepActive || step === StepsEnum.CDBPairHouseholdFailed || !error || !pairingToHousehold) return;
    stopSearch(false);
    setStep(StepsEnum.CDBPairHouseholdFailed);
  }, [error, pairingToHousehold, step, stepActive, stopSearch]);

  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={[styles.imageWrapper, styles.center]}>{StepImage}</SpView>
      <SpView style={[styles.infoWrapper, styles.center]}>{StepInfo}</SpView>
      <SpView style={[styles.progressWrapper]}>{StepProgress}</SpView>
      <SpView style={[styles.hintWrapper, styles.center]}>
        {step === StepsEnum.CDBPairHouseholdFailed && StepHint}
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: '35%',
    marginBottom: 100,
    justifyContent: 'space-around',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginTop: '5%',
    textAlign: 'center',
  },
  imageWrapper: {
    width: '100%',
    height: 60,
    justifyContent: 'center',
  },
  infoWrapper: {
    width: '100%',
    height: 100,
  },
  progressWrapper: {
    width: '100%',
    height: 75,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  hintWrapper: {
    marginTop: '10%',
    width: '100%',
    height: 100,
  },
  buttonWrapper: {
    width: '100%',
    height: 100,
    justifyContent: 'center',
  },
  title: {},
  description: {},
});

export default PairingToHouseholdStep;
