import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const MedicalSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}>
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0Zm1.85 3.078h-3.7V6.15H3.077v3.702H6.15v3.07h3.702v-3.07h3.071V6.149H9.851V3.08Z"
    />
  </Svg>
);
export default MedicalSVG;
