import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StepFormCreator from 'src/components/StepFormCreator';
import { useTranslation } from 'react-i18next';
import SpTitle from 'src/components/SpTitle';
import { SpProfileImage } from 'src/components/SpProfileImage';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import useBoundStore from 'src/store/store';
import useValidation from 'src/pages/validation';
import { PetDietOptions, PetGender, PetSpayedOptions, PetSpecie } from '@constants/Pet';
import WeightInput from 'src/components/WeightInput';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { DeviceType } from '@constants/Device';
import { PetBioMenu } from '@constants/Menus';
import { usePickerAndCameraImage } from '@hooks/usePickerAndCameraImage';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { SpEditMenuStack } from 'src/components/SpEditMenuStack';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { BreedModel } from '@models/Breed';
import { SpView } from 'src/components/SpView';
import { SpText } from '../../../../components/SpText/SpText';
import { PetModel } from '@models/Pet';
import SelectButtonsWrapper from 'src/components/SelectButtonsWrapper';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import SpDatePicker from 'src/components/SpDatePicker';
import { useCustomToast } from '@hooks/useToast';
import { SpInput } from 'src/components/SpInput';
import { convertToGramsOrOz } from '@utils/convertToGramsOrOz';
import { navigateToPets } from '@utils/navigationShortcuts';
import { format, parseISO } from 'date-fns';
import { DeviceEventEmitter, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import SpBadge from '../../../../components/RoundIcons/SpBadge';
import { SpButtonStack } from 'src/components/SpButtonStack';
import CustomBottomSheetModal from '../../../../components/CustomBottomSheetModal';
import NameForm from '../../Generic/NameForm';
import { FlowStackParamList } from '../..';
import { navigationRef } from 'src/pages/RootNavigation';
import { stepsEnums } from '../Devices/RootDevices/AddDogBowl';
import { TimeService } from '../../../../services/TimeService';
import LoadingComponent from '../../components/LoadingComponent';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { StepsEnum } from '../../Generic/AddPets/misc/enums';

type Props = NativeStackScreenProps<FlowStackParamList, 'CreatePetProfile'>;

const CreatePetProfile = ({ route }: Props) => {
  const {
    nextPage,
    cdb: isCDB,
    existingDevice,
    deviceType,
    foundJustOnePet,
    returnToStep,
    id: routeID,
    device_id,
  } = route.params ?? {};
  const [submitValues, setSubmitValues] = useState<any>();
  const { commonNameSchema } = useValidation();
  const navigation = useNavigation<NavigationProp<any>>();
  const flowNavigation = useNavigation<NavigationProp<FlowStackParamList>>();

  const { t } = useTranslation();

  const [validateOnStart, setValidateOnStart] = useState<boolean>(false);

  const [step, setStep] = useState(1);

  const {
    breeds,
    loadBreeds,
    conditions,
    loadConditions,
    activeHousehold,
    updatePhoto,
    createPet,
    updatePet,
    unassignAssignMultiplePetsRequest
  } = useBoundStore(({ breedStore, conditionStore, householdStore, petStore, deviceStore }) => {
    return {
      breeds: breedStore.breeds,
      loadBreeds: breedStore.loadBreeds,
      conditions: conditionStore.data,
      loadConditions: conditionStore.loadAll,
      activeHousehold: householdStore.activeHousehold,
      updatePhoto: petStore.updatePhoto,
      createPet: petStore.createPet,
      updatePet: petStore.updatePet,
      unassignAssignMultiplePetsRequest: deviceStore.unassignAssignMultiplePetsRequest,
    };
  });
  const [open, setOpenModal] = useState<boolean>(false);
  const [image, setImage] = useState(null);
  const [specie, setSpecie] = useState(null);
  const [name, setName] = useState(null);
  const [gender, setGender] = useState(null);
  const [spayed, setSpayed] = useState(null);
  const [date_of_birth, setDate_of_birth] = useState(new Date());
  const [formattedDob, setFormattedDob] = useState(
    format(parseISO(new Date().toISOString()), 'dd/MM/yyyy'),
  );
  const [show, setShow] = useState(false);
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const petWeight = useMemo(
    () => convertWithUnits(0, true, true),
    [userWeightUnits, userCountryCode],
  );
  const [weight, setWeight] = useState('');
  const [weightButtonDisabled, setWeightButtonDisabled] = useState(true);
  const weightWithUnit = `${weight} ${petWeight.split(' ')[1]}`;
  const [foodType, setFoodType] = useState(null);
  const [primaryBreed, setPrimaryBreed] = useState(null);
  const [secondaryBreed, setSecondaryBreed] = useState(null);
  const [maxDate, setMaxDate] = useState(
    TimeService.convertOrUnconvertDateByUTCOld<Date>(new Date(), true),
  );
  const [comments, setComments] = useState(null);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useCustomToast();
  const { cameraImage, pickerImage } = usePickerAndCameraImage();
  const pets = usePetsByHousehold();

  const onOpen = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);
  const onDismiss = useCallback(async () => {
    await setOpenModal(false);
  }, [setOpenModal]);

  const showMode = () => {
    setShow(true);
  };

  useEffect(() => {
    loadBreeds(true);
    loadConditions(true);
  }, []);

  const snapPoints = useMemo(() => ['40%'], []);
  const onChange = (selectedDate: Date) => {
    setShow(false);
    setFormattedDob(format(parseISO(new Date(selectedDate).toISOString()), 'dd/MM/yyyy'));
    setDate_of_birth(selectedDate);
  };

  const step2 = useMemo(() => {
    return (
      <View style={styles.stepVerticalOffset}>
        <SpTitle text={t('create_pet_photo_title')} />
        <View style={styles.profileImageWrap}>
          <SpProfileImage
            width={120}
            height={120}
            imageUrl={image}
          />
          <SpRoundedHeaderButton
            backgroundColor={colors.primary.color}
            h={40}
            title={image ? t('change_photo') : t('add_photo')}
            onPress={onOpen}
            textStyles={styles.profileImageButtonText}
            borderRadius={10}
            paddingHorizontal={0}
            stylesForContent={styles.profileImageButtonWrap}
          />
        </View>
        <CustomBottomSheetModal
          opened={open}
          backdropComponent={BottomSheetBackdrop}
          index={0}
          snapPoints={snapPoints}
          onDismiss={onDismiss}
          inScrollView
          scrollViewStyle={styles.bottomSheetModalContent}
        >
          <SpButtonStack
            buttons={[
              {
                text: t('take_photo'),
                action: async () => {
                  onDismiss();
                  setImage(await cameraImage());
                },
              },
              {
                text: t('upload_photo'),
                action: async () => {
                  onDismiss();
                  setImage(await pickerImage());
                },
              },
            ]}
          />
        </CustomBottomSheetModal>
      </View>
    );
  }, [open, image]);

  const step3 = () => {
    return (
      <View style={styles.stepVerticalOffset}>
        <SpTitle text={t('create_pet_species_title')} />
        <SelectButtonsWrapper
          optionsEnum={PetSpecie}
          handleChange={(value: number) => {
            setSpecie(value);
            setPrimaryBreed(null);
            setSecondaryBreed(null);
          }}
          selectedOption={PetSpecie[specie]}
        />
      </View>
    );
  };

  const step4 = () => {
    return (
      <View style={styles.stepVerticalOffset}>
        <SpTitle text={t('create_pet_gender_title')} />
        <SelectButtonsWrapper
          optionsEnum={PetGender}
          handleChange={setGender}
          selectedOption={PetGender[gender]}
        />
      </View>
    );
  };

  const step5 = () => {
    return (
      <View style={styles.stepVerticalOffset}>
        <SpTitle text={t(gender === PetGender.female ? 'are_they_spayed' : 'are_they_neutered')} />
        <SelectButtonsWrapper
          optionsEnum={PetSpayedOptions}
          handleChange={setSpayed}
          selectedOption={PetSpayedOptions[spayed]}
        />
      </View>
    );
  };

  const step6 = useMemo(() => {
    return (
      <SpView>
        <SpTitle text={t('date_of_birth')} />
        <TouchableOpacity onPress={() => showMode()}>
          <View pointerEvents="none">
            <SpInput
              value={`${formattedDob}`}
              placeholder={t('dob')}
              editable={false}
            />
          </View>
        </TouchableOpacity>
        {show && (
          <SpDatePicker
            maximumDate={maxDate}
            modal
            mode="date"
            testID="datePicker"
            open={show}
            date={date_of_birth}
            onConfirm={onChange}
            onCancel={() => setShow(false)}
            androidVariant="iosClone"
          />
        )}
      </SpView>
    );
  }, [show, date_of_birth]);

  const step7 = useMemo(() => {
    return (
      <View style={styles.stepVerticalOffset}>
        <SpTitle text={t('create_pet_weight_title')} />
        <View style={[styles.stepHorizontalOffset, { marginTop: -48 }]}>
          <WeightInput
            value={weightWithUnit}
            setValue={setWeight}
            setButtonDisabled={setWeightButtonDisabled}
            placeholder="type_here"
            inputType="pet"
            specie={specie}
            validateOnStart={validateOnStart}
            setValidateOnStart={setValidateOnStart}
          />
        </View>
      </View>
    );
  }, [weight, weightWithUnit, specie]);

  const step8 = () => {
    return (
      <View style={styles.stepVerticalOffset}>
        <SpTitle text={t('food_type')} />
        <SelectButtonsWrapper
          optionsEnum={PetDietOptions}
          handleChange={setFoodType}
          selectedOption={PetDietOptions[foodType]}
        />
      </View>
    );
  };

  const primaryBreedValue = useMemo(
    () => breeds.find((item: BreedModel) => item.id === primaryBreed)?.name,
    [breeds, primaryBreed],
  );
  const secondaryBreedValue = useMemo(
    () => breeds.find((item: BreedModel) => item.id === secondaryBreed)?.name,
    [breeds, secondaryBreed],
  );

  const conditionsDisplay = useMemo(() => {
    if (!(selectedConditions[0] !== 0 && selectedConditions?.length)) {
      return <SpText style={styles.noConditions}>{t('not_selected')}</SpText>;
    }
    const data = selectedConditions.reduce((acc, next) => {
      const condition = conditions.find(item => item.id === next);
      if (condition) {
        acc.push(
          <SpBadge
            key={next}
            text={condition.name}
            width="auto"
            badgeWrapperStyle={styles.selectedItemsBadge}
          />,
        );
      }
      return acc;
    }, []);
    const res = data.sort((a, b) => {
      return a.props.text.localeCompare(b.props.text);
    });
    return <SpView style={styles.selectedItemsBadges}>{res}</SpView>;
  }, [selectedConditions]);

  const settingButtons = [
    {
      label: `${t('primary_breed')} *`,
      value: primaryBreedValue || t('not_selected'),
      action: () => {
        navigation.navigate('CreatePetProfileDetails', {
          label: t('primary_breed'),
          inputName: PetBioMenu.BreedID,
          inputValue: primaryBreed,
          handleChange: setPrimaryBreed,
          anotherTypeBreed: secondaryBreed,
          anotherSpecie: specie,
        });
      },
    },
    {
      label: `${t('secondary_breed')}`,
      value: secondaryBreedValue || t('none'),
      action: () => {
        navigation.navigate('CreatePetProfileDetails', {
          label: t('secondary_breed'),
          inputName: PetBioMenu.BreedID2,
          inputValue: secondaryBreed,
          handleChange: setSecondaryBreed,
          anotherTypeBreed: primaryBreed,
          anotherSpecie: specie,
        });
      },
    },
    {
      label: `${t('conditions')} *`,
      //! Warning! There is an appium error contained with element nesting
      // Do not create additional levels of nesting of elements in this array
      // https://github.com/facebookarchive/WebDriverAgent/issues/905#issuecomment-384971617
      value: conditionsDisplay,
      action: () => {
        navigation.navigate('CreatePetProfileDetails', {
          label: t('conditions'),
          inputName: 'Conditions',
          inputValue: selectedConditions.map(item => {
            return { id: item };
          }),
          handleChange: setSelectedConditions,
        });
      },
    },
    {
      label: t('comments'),
      value: comments || t('none'),
      action: () =>
        navigation.navigate('CreatePetProfileDetails', {
          label: t('comments'),
          inputName: PetBioMenu.Comments,
          inputValue: comments,
          handleChange: setComments,
        }),
    },
  ];

  const step9 = useMemo(() => {
    return (
      <>
        <SpTitle
          text={t('create_pet_details_title')}
          style={styles.stepVerticalOffset}
        />
        <SpText color={colors.errorRed.color}>{t('fields_marked_required')}</SpText>
        <SpEditMenuStack
          showTitleDivider={false}
          stackType="createPetDetails"
          buttons={settingButtons}
        />
        <SpView height={Platform.OS === 'android' ? 85 : 50} />
      </>
    );
  }, [settingButtons]);

  const onSubmit = async () => {
    setLoading(true);
    const submitObj: PetModel = {
      household_id: activeHousehold.id,
      name: submitValues.name,
      gender,
      weight: convertToGramsOrOz(Number(weight.replace(/,/g, '.')), userWeightUnits),
      comments: comments ?? '',
      breed_id: primaryBreed,
      breed_id2: secondaryBreed ?? 0,
      spayed,
      date_of_birth: date_of_birth.toISOString(),
      food_type_id: foodType + 1,
      species_id: specie,
      conditions: selectedConditions.map(item => {
        return { id: item };
      }),
    };
    let createdPet = null;
    try {
      // TODO assign to device_id if one exists
      if (routeID) {
        await updatePet(routeID, submitObj);
      } else {
        createdPet = await createPet(submitObj);
      }
      if (image) {
        await updatePhoto(image);
      }
      if (device_id && createdPet !== null) {
        await unassignAssignMultiplePetsRequest(
          device_id,
          createdPet.map(elem => ({
            assign: elem.isAssigned,
            tagId: elem.pet.tag_id,
            pet: elem.pet,
          })),
        );
        if (isCDB) {
          return true;
        }
      }
      if (existingDevice) {
        flowNavigation.navigate('AddPets', {
          step: 8,
          loading: true,
          existingDevice,
          deviceType,
          comingFromProfile: true,
        });
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  const handlePetProfileCreatedContinue = useCallback(() => {
    if (isCDB) {
      // await assignPetRequest(pairedDeviceDeviceID, pet.pet.tag_id);

      // user created a pet for isCDB, put them back in the step
      // after the one they came from to avoid loop.
      navigation.navigate('AddDogBowl', {
        step: stepsEnums.SubstanceTypes,
      });
      return;
    }

    if (nextPage) {
      navigation.navigate(nextPage.stack, {
        screen: nextPage.screen,
      });
      return;
    }

    // If we came from the setup process and we added only one pet but there are more in the household we go to the Assign Pet
    if (pets.length > 1 && foundJustOnePet) {
      flowNavigation.navigate('AddPets', {
        step: StepsEnum.AssignPets,
        loading: true,
        existingDevice,
        deviceType,
        returnToStep
      });
      return;
    }


    // If adding only one pet during the device setup process
    if (foundJustOnePet) {
      if (deviceType === DeviceType.CatFlapConnect) {
        navigation.navigate('AddCatFlap', {
          step: returnToStep,
          deviceId: device_id,
        });
        return;
      }
      if (deviceType === DeviceType.FelaquaConnect) {
        navigation.navigate('AddFelaqua', {
          step: returnToStep,
          deviceId: device_id,
        });
        return;
      }
      if (deviceType === DeviceType.FeederConnect) {
        navigation.navigate('AddFeeder', {
          step: returnToStep,
          deviceId: device_id,
        });
        return;
      }
    }

    navigation.goBack();
  }, [deviceType, foundJustOnePet, navigation, isCDB, device_id, nextPage, returnToStep]);

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('create_pet')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      disableKeyboardAwoiding={step === 1}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        1: {
          ui: NameForm,
          props: {
            label: t('name'),
            title: t('create_pet_name_title'),
            setName,
            disableKeyboardAwoiding: true,
            hideTitleOnFocus: false,
          },
          backBottomButton: true,
          buttonDisabled: !name,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
          formik: { schema: commonNameSchema('Pet name'), names: ['name'] },
        },
        2: {
          ui: step2,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
          handleAndroidBackPress: () => {
            if (open) {
              onDismiss();
              return true;
            } else {
              return 'goBack';
            }
          },
        },
        3: {
          ui: step3,
          buttonDisabled: specie === null,
          interceptor: () => {
            if (specie) {
              return true;
            }
            return false;
          },
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
        },
        4: {
          ui: step4,
          buttonDisabled: gender === null,
          interceptor: () => {
            if (gender !== null) {
              return true;
            }
            return false;
          },
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
        },
        5: {
          ui: step5,
          buttonDisabled: spayed === null,
          interceptor: () => {
            if (spayed) {
              return true;
            }
            return false;
          },
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
        },
        6: {
          ui: step6,
          buttonDisabled: !date_of_birth,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
          interceptor: () => {
            if (date_of_birth) {
              return true;
            }
            return false;
          },
        },
        7: {
          ui: step7,
          buttonDisabled: !(!weightButtonDisabled && weight), // foodType === null,
          backBottomButton: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
        },
        8: {
          ui: step8,
          backBottomButton: true,
          buttonDisabled: foodType === null,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
        },
        9: {
          ui: loading ? LoadingComponent : step9,
          backBottomButton: true,
          buttonDisabled: primaryBreed === null || selectedConditions.length === 0,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: () => {
              navigation.navigate('DashboardNavigation');
            },
          },
          interceptor: onSubmit,
          handleAndroidBackPress: () => {
            const routeName = navigationRef.current.getCurrentRoute()?.name;
            if (routeName === 'CreatePetProfileDetails') {
              return false;
            } else {
              return 'goBack';
            }
          },
        },
        10: {
          ui: (
            <View style={{ marginTop: 112 }}>
              <LoadingComponent
                isConnected
                connectedMsg={t('pet_profile_created')}
              />
            </View>
          ),
          hideCustomHeader: true,
          forcePressHandler: handlePetProfileCreatedContinue,
          handleAndroidBackPress: () => {
            navigateToPets();
            return true;
          },
        },
      }}
    />
  );
};

const styles = StyleSheet.create({
  profileImageWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileImageButtonText: {
    fontSize: 16,
    color: '#ffffff',
    fontFamily: 'Rubik_Medium',
  },
  profileImageButtonWrap: {
    marginLeft: 24,
    paddingHorizontal: 7,
  },
  bottomSheetModalContent: {
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  stepVerticalOffset: {
    marginTop: 32,
  },
  stepHorizontalOffset: {
    marginRight: -19,
    marginLeft: -19,
  },
  selectedItemsBadges: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 6,
  },
  selectedItemsBadge: {
    marginTop: 4,
    marginRight: 4,
  },
  noConditions: {
    color: colors.greyText.color,
    fontSize: 18,
    marginTop: 6,
    lineHeight: 24,
    fontFamily: 'Rubik',
  },
});

export default CreatePetProfile;
