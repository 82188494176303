import { SpView } from 'src/components/SpView';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../../components/SpText/SpText';
import React from 'react';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import PetInactiveSVG from 'src/components/SvgIcons/PetInactiveSVG';
import ProductInactiveSVG from 'src/components/SvgIcons/ProductInactiveSVG';
import House from '../../../../../assets/House';

const HouseholdSlide = () => {
  const { t } = useTranslation();

  return (
    <SpView>
      <SpView style={styles.iconWrapper}>
        <SpView style={styles.iconCircle}>
          <PetInactiveSVG color="#ffffff" />
        </SpView>
        <House />
        <SpView style={styles.iconCircle}>
          <ProductInactiveSVG color="#ffffff" />
        </SpView>
      </SpView>
      <SpTitle
        style={styles.title}
        align="center"
        text={t('household')}
      />
      <SpText style={styles.contentText}>{t('household_tour_text')}</SpText>
      <SpText style={styles.contentText}>{t('household_tour_text2')}</SpText>
    </SpView>
  );
};

const styles = StyleSheet.create({
  title: {
    paddingTop: 16,
  },
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 32,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 17,
  },
  iconCircle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#263a43',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default HouseholdSlide;
