import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const IndoorOnlySVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    {...props}>
    <G
      fill="#263A43"
      fillRule="evenodd">
      <G fillRule="nonzero">
        <Path d="M13.632 1c.808 0 1.57.195 2.239.543a7.5 7.5 0 0 0 6.362 12.421c.53.672.978 1.604.978 2.856 0 2.617-2.149 4.746-4.79 4.746H8.843C6.202 21.566 4 19.49 4 16.873c0-2.937 2.521-4.209 2.675-4.368-.218-.41-1.079-1.24-1.079-2.804 0-3.203 3.07-3.793 3.23-3.905l.052-.502A4.772 4.772 0 0 1 13.632 1ZM18.5 26.418h-3.272V22.73h-3.193v3.688H8.843V28H18.5z" />
      </G>
      <Path d="M21.5.5a6 6 0 1 1 0 12 6 6 0 0 1 0-12Zm2.47 2.47a.25.25 0 0 0-.354 0L21.5 5.085 19.384 2.97a.25.25 0 0 0-.354 0l-1.06 1.06a.25.25 0 0 0 0 .354l2.115 2.115-2.115 2.117a.25.25 0 0 0 0 .354l1.06 1.06a.25.25 0 0 0 .354 0l2.115-2.117 2.117 2.117a.25.25 0 0 0 .354 0l1.06-1.06a.25.25 0 0 0 0-.354L22.913 6.5l2.117-2.115a.25.25 0 0 0 0-.354Z" />
    </G>
  </Svg>
);
export default IndoorOnlySVG;
