import React, { memo } from 'react';
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import colors from '@styles/colors';
import atomStyles from '@styles/atoms';
import { testProperties } from '@utils/testProperties';
import { SpView } from './SpView';
import { SpText } from './SpText/SpText';

type Props = {
  active: boolean;
  handler: (bool: boolean) => void;
  text: string | React.ReactNode;
  disabled?: boolean;
  containerStyle?: ViewStyle;
  title?: string;
  componentNameIcon?: string;
};

const RadioCheckboxWithText: React.FC<Props> = ({
  active,
  handler,
  text,
  disabled = false,
  containerStyle,
  title,
  componentNameIcon,
}) => {
  return (
    <Pressable
      {...testProperties(`RadioCheckboxWithText`, 'Pressable')}
      onPress={() => handler(!active)}
      style={[
        atomStyles.rowContainer,
        atomStyles.maxWidth,
        containerStyle,
        disabled && styles.disabled,
      ]}
      disabled={disabled}>
      <View
        {...testProperties(
          'icon',
          `${title || text}`,
          active
            ? componentNameIcon || 'FaCheck'
            : componentNameIcon
              ? `Un${componentNameIcon}`
              : 'FaUnCheck',
        )}
        style={[atomStyles.alternativeCheckbox]}>
        {active && (
          <FontAwesomeIcon
            size={32}
            icon={faCheckCircle}
            color={colors.primary.color}
          />
        )}
      </View>
      {typeof text === 'string' ? <SpText style={styles.text}>{text}</SpText> : text}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontFamily: 'Rubik',
    color: colors.greyText.color,
    lineHeight: 20,
    flex: 1,
  },
  disabled: {
    opacity: 0.5,
  },
});

export default memo(RadioCheckboxWithText);
