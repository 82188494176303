import { useMemo } from 'react';
import useBoundStore from '../store/store';

export const useUserCountryCode = (): number => {
  const user = useBoundStore(s => s.accountStore.user);

  return useMemo(() => {
    return user?.country_id || null;
  }, [user]);
};
