import Svg, { SvgProps, G, Defs, ClipPath, Rect, Path, Circle } from 'react-native-svg';
import React from 'react';

const CDBPairHouseholdSuccessSVG = (props: SvgProps) => (
  <Svg
    width="298"
    height="55"
    viewBox="0 0 298 55"
    fill="none">
    <G clipPath="url(#clip0_0_23142)">
      <Path
        d="M21.5416 4.58301H16.0416C15.2817 4.58301 14.6666 5.19811 14.6666 5.95801C14.6666 6.71791 15.2817 7.33301 16.0416 7.33301H21.5416C22.3015 7.33301 22.9166 6.71791 22.9166 5.95801C22.9166 5.19811 22.3015 4.58301 21.5416 4.58301Z"
        fill="#263A43"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 0H27.5C30.5323 0 33 2.4677 33 5.5V49.5C33 52.5323 30.5323 55 27.5 55H5.5C2.4677 55 0 52.5323 0 49.5V5.5C0 2.4677 2.4677 0 5.5 0ZM27.5 52.25C29.0189 52.25 30.25 51.0189 30.25 49.5V5.5C30.25 3.98105 29.0189 2.75 27.5 2.75H5.5C3.98105 2.75 2.75 3.98105 2.75 5.5V49.5C2.75 51.0189 3.98105 52.25 5.5 52.25H27.5Z"
        fill="#263A43"
      />
      <Circle
        cx="16.5"
        cy="47.667"
        r="2.75"
        fill="#263A43"
      />
      <Circle
        cx="11.4584"
        cy="5.95801"
        r="1.375"
        fill="#263A43"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.558 44.9998H118.442C116.511 44.9998 114.951 43.3593 115.001 41.3564C115.049 39.4194 116.641 37.9014 118.519 37.9014H166.481C168.359 37.9014 169.951 39.4194 169.999 41.3564C170.049 43.3593 168.489 44.9998 166.558 44.9998Z"
        stroke="#263A43"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.481 37.9014H118.519L121.875 20.1719H163.125L166.481 37.9014Z"
        stroke="#263A43"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.254 13.0902H156.25C156.25 11.1322 154.711 9.54508 152.812 9.54508C151.83 9.54508 150.945 9.97049 150.319 10.6515C149.477 9.04659 147.831 7.95641 145.937 7.95641C145.052 7.95641 144.222 8.19565 143.502 8.6133C142.604 7.04934 140.953 6 139.062 6C136.619 6 134.574 7.7533 134.042 10.1062C133.507 9.75161 132.871 9.54508 132.187 9.54508C130.289 9.54508 128.75 11.1322 128.75 13.0902H128.746C126.85 13.0902 125.312 14.6754 125.312 16.631V16.631C125.312 18.5865 126.85 20.1718 128.746 20.1718H156.254C158.15 20.1718 159.688 18.5866 159.688 16.631V16.631C159.687 14.6754 158.15 13.0902 156.254 13.0902Z"
        stroke="#263A43"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 31C47.6569 31 49 29.6569 49 28C49 26.3431 47.6569 25 46 25C44.3431 25 43 26.3431 43 28C43 29.6569 44.3431 31 46 31ZM58 31C59.6569 31 61 29.6569 61 28C61 26.3431 59.6569 25 58 25C56.3431 25 55 26.3431 55 28C55 29.6569 56.3431 31 58 31ZM73 28C73 29.6569 71.6569 31 70 31C68.3431 31 67 29.6569 67 28C67 26.3431 68.3431 25 70 25C71.6569 25 73 26.3431 73 28ZM82 31C83.6569 31 85 29.6569 85 28C85 26.3431 83.6569 25 82 25C80.3431 25 79 26.3431 79 28C79 29.6569 80.3431 31 82 31ZM97 28C97 29.6569 95.6569 31 94 31C92.3431 31 91 29.6569 91 28C91 26.3431 92.3431 25 94 25C95.6569 25 97 26.3431 97 28ZM106 31C107.657 31 109 29.6569 109 28C109 26.3431 107.657 25 106 25C104.343 25 103 26.3431 103 28C103 29.6569 104.343 31 106 31Z"
        fill="#2DB58E"
        fillOpacity="0.5"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179 31C180.657 31 182 29.6569 182 28C182 26.3431 180.657 25 179 25C177.343 25 176 26.3431 176 28C176 29.6569 177.343 31 179 31ZM191 31C192.657 31 194 29.6569 194 28C194 26.3431 192.657 25 191 25C189.343 25 188 26.3431 188 28C188 29.6569 189.343 31 191 31ZM206 28C206 29.6569 204.657 31 203 31C201.343 31 200 29.6569 200 28C200 26.3431 201.343 25 203 25C204.657 25 206 26.3431 206 28ZM215 31C216.657 31 218 29.6569 218 28C218 26.3431 216.657 25 215 25C213.343 25 212 26.3431 212 28C212 29.6569 213.343 31 215 31ZM230 28C230 29.6569 228.657 31 227 31C225.343 31 224 29.6569 224 28C224 26.3431 225.343 25 227 25C228.657 25 230 26.3431 230 28ZM239 31C240.657 31 242 29.6569 242 28C242 26.3431 240.657 25 239 25C237.343 25 236 26.3431 236 28C236 29.6569 237.343 31 239 31Z"
        fill="#2DB58E"
        fillOpacity="0.5"
      />
      <Path
        d="M297.211 22.5484L276.123 3.62309C275.198 2.79227 273.802 2.79236 272.877 3.623L251.789 22.5484C251.048 23.2139 250.803 24.2392 251.165 25.1604C251.527 26.0816 252.41 26.6768 253.413 26.6768H256.781V45.6385C256.781 46.3903 257.4 46.9999 258.163 46.9999H269.721C270.485 46.9999 271.104 46.3904 271.104 45.6385V34.1256H277.897V45.6386C277.897 46.3904 278.516 47 279.279 47H290.837C291.6 47 292.219 46.3905 292.219 45.6386V26.6768H295.588C296.591 26.6768 297.473 26.0816 297.835 25.1604C298.197 24.2391 297.952 23.2139 297.211 22.5484Z"
        stroke="#263A43"
        strokeWidth="3"
      />
      <Circle
        cx="76"
        cy="28"
        r="12"
        fill="#2DB58E"
      />
      <Path
        d="M70 28L74.5 32.5L82 23.5"
        stroke="white"
        strokeWidth="2.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle
        cx="209"
        cy="28"
        r="12"
        fill="#2DB58E"
      />
      <Path
        d="M203 28L207.5 32.5L215 23.5"
        stroke="white"
        strokeWidth="2.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_0_23142">
        <Rect
          width="298"
          height="55"
          fill="white"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

export default CDBPairHouseholdSuccessSVG;
