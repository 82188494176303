import { SafeAreaView, StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import colors from '@styles/colors';
import { SpEditMenuStack } from 'src/components/SpEditMenuStack';
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { PetDietOptions } from '@constants/Pet';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import { useTranslation } from 'react-i18next';
import { SpView } from 'src/components/SpView';
import useBoundStore from 'src/store/store';
import { useUserHasAdminAccess } from '@hooks/useUserHasAdminAccess';
import { PetStackParamList } from '../..';

const PetDiet = () => {
  const ENUM_REGEX = /^[A-Z][a-z0-9_-]*$/;
  const navigation = useNavigation<NavigationProp<PetStackParamList>>();
  const route = useRoute<RouteProp<PetStackParamList, 'PetDiet'>>();
  const { getPetById } = useBoundStore(state => state.petStore);

  const activePet = getPetById(route.params.id);
  const { t } = useTranslation();
  const isAdmin = useUserHasAdminAccess();

  const diet = enumToDescriptedArray(PetDietOptions, ENUM_REGEX, true).find(
    item => item.id === activePet.food_type_id,
  );
  const dataToRender = useMemo(() => {
    return [
      {
        viewOnly: !isAdmin,
        label: t('food_type'),
        value: t(diet?.name) || t('not_selected'),
        action: () =>
          navigation.navigate('PetDietEdit', {
            id: activePet.food_type_id,
          }),
      },
    ];
  }, [activePet.food_type_id, isAdmin]);

  return (
    <SafeAreaView style={styles.container}>
      <SpView
        style={styles.inner}
        flex={1}
        paddingHorizontal={19}>
        <SpEditMenuStack buttons={dataToRender} />
      </SpView>
    </SafeAreaView>
  );
};

export default PetDiet;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
    paddingTop: 23,
  },
});
