import { StackScreenProps } from '@react-navigation/stack';
import colors from '@styles/colors';
import * as React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import useBoundStore from '../../../store/store';
import { PetProfileMedical } from '../Pets/pages/PetProfile/pages/PetProfileMedical';
import { PetStackParamList } from '../Pets/pages';

export const PetProfileMedicalWrapper = ({
  route,
}: StackScreenProps<PetStackParamList, 'PetProfileMedical'>) => {
  const getPetById = useBoundStore(state => state.petStore.getPetById);
  const conditions = useBoundStore(state => state.conditionStore.data);
  const pet = getPetById(route.params.id);

  return (
    <SafeAreaView style={styles.container}>
      <PetProfileMedical
        conditions={conditions}
        pet={pet}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
});
