import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import useBoundStore from 'src/store/store';
import {
  permissionLevelOptions,
  userPermissionLevelDefinerReverse,
} from '@utils/permissionLevelDefiner';
import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import colors from '@styles/colors';
import SpTitle from 'src/components/SpTitle';
import { SpInput } from 'src/components/SpInput';
import SpSelectBadge from 'src/components/SpSelectBadge';
import { useFormik } from 'formik';
import i18 from '@utils/i18n';
import { useTranslation } from 'react-i18next';
import { RootStackParamList } from 'src/pages';
import useValidation from 'src/pages/validation';
import { SpText } from '../../../components/SpText/SpText';
import { AnalyticsService } from 'src/services/AnalyticsService';

const HouseholdSendInvite = () => {
  const { sendInviteSchema } = useValidation();
  const [email, setEmail] = useState('');
  const [selectedPermissionLevel, setSelectedPermissionLevel] = useState(null);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const { sendInvite, loadingHouseholdInvite } = useBoundStore(state => state.householdInviteStore);
  const { activeHousehold } = useBoundStore(state => state.householdStore);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const formik = useFormik({
    validationSchema: sendInviteSchema,
    initialValues: {
      email: '',
      permissionLevel: '',
    },
    onSubmit: async () => {
      if (activeHousehold?.invites?.some(elem => elem.email_address === email)) {
        setError(t('pending_invite'));
        return;
      }
      setError('');
      try {
        AnalyticsService.logEvent(`household_send_invite`);

        await sendInvite({
          email_address: email,
          ...userPermissionLevelDefinerReverse(selectedPermissionLevel),
        });
        navigation.dispatch(StackActions.pop(1));
        navigation.navigate('SuccessScreenModal', {
          title: t('invite_sent'),
          withHeader: true,
          headerNavObject: {
            parentNavigatorName: 'HouseholdNavigation',
            screenName: 'HouseholdUsers',
          },
        });
      } catch (err: any) {
        setError(err.data.error.error[0]);
      }
    },
    validateOnChange: validateAfterSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const onSendInvite = useCallback(() => {
    formik.handleSubmit();
  }, []);

  useEffect(() => {
    if (formik.submitCount > 0) setValidateAfterSubmit(true);
  }, [formik.submitCount]);

  return (
    <ScrollView
      keyboardShouldPersistTaps="always"
      keyboardDismissMode="on-drag"
      contentContainerStyle={styles.scrollView}
      style={styles.content}>
      <TouchableWithoutFeedback
        accessible={false}
        onPress={() => dismissKeyboard()}>
        <View style={styles.contentWrap}>
          <SpTitle text={i18.t('send_invite')} />
          <SpInput
            value={email}
            keyboardType="email-address"
            onChangeText={text => {
              formik.handleChange('email')(text);
              setEmail(text);
            }}
            label={i18.t('email_address')}
            error={formik.errors.email}
            isLoading={loadingHouseholdInvite}
          />
          <SpText style={styles.subtitle}>{t('choose_permission_level')}</SpText>

          <View style={styles.permissionOptionsOffset}>
            {permissionLevelOptions.map((item, index) => {
              const isActive = selectedPermissionLevel === item.id;
              return (
                <View
                  key={item.title + item.id}
                  style={
                    permissionLevelOptions.length - 1 > index && styles.permissionOptionsInnerOffset
                  }>
                  <SpSelectBadge
                    onPress={() => {
                      setSelectedPermissionLevel(item.id);
                      formik.handleChange('permissionLevel')(String(item.id));
                      dismissKeyboard();
                    }}
                    title={t(item.title)}
                    subtitle={t(item.subtitle)}
                    isActive={isActive}
                    isLoading={loadingHouseholdInvite}
                  />
                </View>
              );
            })}
          </View>

          {formik.errors.permissionLevel && (
            <Text style={styles.error}>{formik.errors.permissionLevel}</Text>
          )}

          {error && <Text style={styles.error}>{error}</Text>}

          <View style={styles.buttonWrap}>
            <SpRoundedHeaderButton
              backgroundColor={colors.primary.color}
              h={56}
              title={i18.t('send')}
              onPress={onSendInvite}
              isLoading={loadingHouseholdInvite}
              disabled={!(email && selectedPermissionLevel)}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  safeArea: { flex: 1, backgroundColor: colors.white.color },
  content: {
    flex: 1,
    backgroundColor: colors.white.color,
    paddingHorizontal: 19,
  },
  scrollView: {
    flexGrow: 1,
    backgroundColor: colors.white.color,
  },
  contentWrap: {
    flex: 1,
  },
  subtitle: {
    fontSize: 20,
    fontFamily: 'Rubik_Medium',
    marginTop: 32,
  },
  buttonWrap: {
    justifyContent: 'flex-end',
    flex: 1,
    shadowColor: colors.greyText.color,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8.95,
    elevation: 18,
    marginBottom: 50,
  },
  permissionOptionsOffset: { marginTop: 16, flex: 1 },
  permissionOptionsInnerOffset: { marginBottom: 24 },
  error: {
    color: colors.errorRed.color,
    fontSize: 14,
    fontFamily: 'Rubik',
    lineHeight: 20,
    marginTop: 8,
    textAlign: 'center',
  },
});

export default HouseholdSendInvite;
