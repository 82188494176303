import { ConditionModel } from '@models/Condition';
import qs from 'qs';
import Http from './Http';

class ConditionApi {
  static readonly httpParams = {};

  static getConditions(): Promise<ConditionModel[]> {
    return Http.get(`/api/condition`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default ConditionApi;
