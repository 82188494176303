import { AppImages } from '@constants/AppImages';
import text from '@styles/text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';

export const WeHaveNotManageToConnectToCDB = () => {
  const { t } = useTranslation();

  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={{ flexDirection: 'column' }}>
        <SpView style={[{ flex: 1 }, styles.center]}>
          <Image
            style={{ maxWidth: 250, maxHeight: 200 }}
            source={AppImages.cdbWeHaventManageToConnect}
            resizeMode="contain"
          />
          <SpText style={[text.title]}>{t('we_havent_manage_setup_cdb')}</SpText>
          <SpText style={[text.body, styles.text]}>{t('factory_reset_start_again')}</SpText>
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    // marginTop: '10%',
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
  text: {
    marginTop: '5%',
    marginBottom: '5%',
  },
});

export default WeHaveNotManageToConnectToCDB;
