import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import persistStorage from '@utils/persistStorage';
import { TourSlice } from '../models/TourSlice';
import { MergedInterfaces } from '../models';

const createTourSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  TourSlice
> = (set, get) => {
  return {
    tourStore: lens((subSet, subGet) => ({
      toursHasBeenReset: 'none',
      completedTours: {},
      currentTour: null,
      petDashboardCount: 1,
      setCompletedTours: data => {
        subSet({ completedTours: data });
      },
      addCompletedTours: async data => {
        subSet({
          completedTours: { ...subGet().completedTours, ...{ [data]: true } },
        });
        await persistStorage.setItem('COMPLETED_TOURS', subGet().completedTours);
      },
      addDashboardCount: () => {
        subSet({ petDashboardCount: subGet().petDashboardCount + 1 });
      },
      resetTours: async () => {
        subSet({
          completedTours: {},
          currentTour: null,
          petDashboardCount: 1,
        });
        try {
          await Promise.all([
            persistStorage.removeItem('COMPLETED_TOURS'),
            persistStorage.removeItem('TOUR_GROUP'),
          ]);
          subSet({ toursHasBeenReset: 'success' });
        } catch {
          subSet({ toursHasBeenReset: 'error' });
        }
      },
      updateProperty: (name, value) => {
        subSet({ [name]: value });
      },
    })),
  };
};

export default createTourSlice;
