import CustomBottomSheetModal from 'src/components/CustomBottomSheetModal';
import { SpView } from 'src/components/SpView';
import { PetBowlFoodType, SubstanceType } from '@constants/Device';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { DeviceCerberusControlModel } from '@models/Device';
import { SpEditMenuStack } from 'src/components/SpEditMenuStack';
import React, { useCallback, useMemo, useState } from 'react';
import { getActions } from '../../constants/CerberusBowlSetup';
import { DeviceBottomSheetContainer } from '../DeviceBottomSheetContainer';
import { FoodTypes } from './FoodTypes';
import { SubstanceTypes } from './SubstanceTypes';

interface BowlSetupProps {
  data: DeviceCerberusControlModel;
  onChangeFoodType: (data: PetBowlFoodType, isLeft: boolean) => void;
  onChangeSubstanceType: (data: SubstanceType, isFood: boolean) => void;
}

export const CerberusBowlSetup = ({
  data,
  onChangeFoodType,
  onChangeSubstanceType,
}: BowlSetupProps) => {
  const [food, setFood] = useState<{
    data: DeviceCerberusControlModel;
    isLeft: boolean;
  }>(null);
  const [substance, setSubstance] = useState<{
    data: SubstanceType;
    isFood: boolean;
  }>(null);
  const snapPoints = useMemo(() => ['30%'], []);
  const dismissAction = useCallback(() => {
    setFood(null);
    setSubstance(null);
  }, [setFood]);

  const changeFoodTypeAction = useCallback(
    ($event: PetBowlFoodType) => {
      onChangeFoodType($event, food.isLeft);
      setFood(null);
    },
    [setFood, food],
  );

  const changeSubstanceTypeAction = useCallback(
    ($event: SubstanceType) => {
      onChangeSubstanceType($event, substance.isFood);
      setSubstance(null);
    },
    [setSubstance, substance],
  );

  const actions = useMemo(() => {
    return getActions(
      data,
      ($event, isLeft) => setFood({ data: $event, isLeft }),
      ($event, isFood) => setSubstance({ data: $event, isFood }),
    );
  }, [data, setFood, setSubstance]);

  return (
    <SpView>
      <SpView>
        <SpEditMenuStack buttons={actions} />
      </SpView>

      <CustomBottomSheetModal
        opened={Boolean(food || substance)}
        backdropComponent={BottomSheetBackdrop}
        index={0}
        snapPoints={snapPoints}
        onDismiss={dismissAction}>
        <DeviceBottomSheetContainer flex={1}>
          {food && (
            <FoodTypes
              active={food?.data?.food_type}
              onChange={changeFoodTypeAction}
            />
          )}
          {substance && (
            <SubstanceTypes
              active={substance?.data}
              onChange={changeSubstanceTypeAction}
            />
          )}
        </DeviceBottomSheetContainer>
      </CustomBottomSheetModal>
    </SpView>
  );
};
