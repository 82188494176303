import AsyncStorage from '@react-native-async-storage/async-storage';
import { Env } from 'Env';
import axios, { AxiosError } from 'axios';
import * as Localization from 'expo-localization';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { ITranslations, translations } from '../../locale/translationLoader';

const backendOptions = {
  lng: Localization.locale,
  preload: ['en'],
  loadPath: `https://${Env.APP_ENV}.surehub.io/locale/{{lng}}/translations.json`,
  crossDomain: true,
};

function loadLangs() {
  const newAbortSignal = (timeoutMs: number) => {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);
    return abortController.signal;
  };
  const langPromises = Object.keys(translations).map(lang => {
    const languageTranslations = (translations as ITranslations)[lang];
    console.log(`Loading ${lang}`);
    i18n.addResourceBundle(lang, 'en', languageTranslations);

    // Add the translations to the i18n instance
    return axios
      .get(`https://${Env.APP_ENV}.surehub.io/locale/${lang}/overide.json`, {
        signal: newAbortSignal(400),
      })
      .then(res => {
        const remoteTranslations = { ...languageTranslations, ...res.data };
        console.log('got remote translations', lang);

        i18n.addResourceBundle(lang, 'en', remoteTranslations);
      })
      .catch((err: AxiosError) => {
        console.log(err);
        i18n.addResourceBundle(lang, 'en', languageTranslations);
      });
  });
  Promise.all(langPromises).then(() => {
    console.log('done loading translations');
  });
}

//   let en_import = { ...en, ...en_AA };
//   if (Env.APP_NAME !== "Sure Petcare") {
//     en_import = { ...en, ...en_AA, ...LocalTranslations.en };
//   }
//   i18n.addResourceBundle("en", "en", en_import);
//   i18n.addResourceBundle("de", "en", de);
//   i18n.addResourceBundle("fr", "en", fr);
//
//   i18n.addResourceBundle("it", "en", it);
// }

//   // eslint-disable-next-line
//     const translations
//     translations.en = {...LocalTranslations.en, require("../../translations/en.json"), };
//
//   if (Env.APP_NAME !== 'Sure Petcare') {
//     en = { ...en, ...LocalTranslations.en };
//   }
//
//
// }

// some helper functions to save/get language from async store,
// might not be needed when we start loading the users preferences,
// we can set the language from the users account settings
export const init = async () => {
  return true;
};
export const storeLang = async (value: string) => {
  try {
    await i18n.changeLanguage(value);
    await AsyncStorage.setItem('@language', value);
  } catch (e) {
    // saving error
  }
};
export const getLang = async () => {
  try {
    const value = await AsyncStorage.getItem('@language');
    if (value !== null) {
      await i18n.changeLanguage(value);
      // await i18n.changeLanguage('cimode');

      // value previously stored
    }
  } catch (e) {
    // error reading value
  }
};

// i18n initialisation
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // UNCOMMENT THIS WHEN FEATURE WORKS AGAIN
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      lng: Localization.locale,
      compatibilityJSON: 'v3',
      react: {
        useSuspense: true,
      },
      // backend: backendOptions,
      partialBundledLanguages: true,
      resources: {},
      ns: ['en'],
      fallbackLng: 'en',
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    },
    // eslint-disable-next-line consistent-return
    err => {
      if (err) return err;
    },
  )
  .then(() => {
    loadLangs();
    return getLang();
  });

// i18n.addResourceBundle('en', 'localTranslations', LocalTranslations.en);

// i18n.addResourceBundle('en', 'en', en);
// load the last saved language from storage if its there
// getLang();
export default i18n;
