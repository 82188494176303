import { PetPosition } from '@constants/Pet';
import { useRefreshPetData } from '@hooks/useRefreshPetData';
import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { PetModel } from '@models/Pet';
import { formatISO } from 'date-fns';
import React, { useMemo, useState } from 'react';

import { useCheckPending } from '../pages/Dashboard/hooks/useCheckPending';
import { usePetCardQuickActions } from '../pages/Dashboard/hooks/usePetCardQuickActions';
import useBoundStore from '../store/store';
import { PetCardQuickActions } from '../pages/Dashboard/Pets/pages/PetCarousel/components/PetCardQuickActions';

export const usePetQuickActions = (pet: PetModel) => {
  const { pendingChecking } = useCheckPending(pet);
  const quickActions = usePetCardQuickActions(pet);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocationUpdating, setIsLocationUpdating] = useState(false);
  const { updatePetPosition } = useBoundStore(s => s.petStore);
  const { refreshStats } = useRefreshPetData();
  const handleUpdateLocation = async (data: PetPosition) => {
    setIsLocationUpdating(true);
    const formattedDate = formatISO(new Date(), { representation: 'complete' });

    try {
      await updatePetPosition(pet.id, {
        where: data,
        since: formattedDate,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLocationUpdating(false);
      refreshStats();
    }
  };
  const writeAccess = useUserHasWriteAccess();
  const { devicesIdsRequestsTrack, loadingControl } = useBoundStore(s => s.deviceStore);
  const allowQuickActions = useMemo(() => {
    return (
      writeAccess &&
      Boolean(
        quickActions.zeroBowl.length ||
          quickActions.indoorOnlyMode.length ||
          quickActions.changeLocation.length,
      )
    );
  }, [quickActions, writeAccess, devicesIdsRequestsTrack, pet, pendingChecking, loadingControl]);
  const QuickActionsModal = (
    <PetCardQuickActions
      pet={pet}
      zeroBowlDevices={quickActions.zeroBowl}
      changeLocationDevices={quickActions.changeLocation}
      indoorOnlyModeDevices={quickActions.indoorOnlyMode}
      isOpen={isModalOpen}
      onUpdateLocation={handleUpdateLocation}
      isLocationUpdating={isLocationUpdating}
      onDismiss={() => {
        setIsModalOpen(false);
      }}
      handleAndroidBackPress={() => {
        setIsModalOpen(false);
        return true;
      }}
    />
  );

  return { QuickActionsModal, allowQuickActions, isModalOpen, setIsModalOpen };
};
