import { BackHandler, SafeAreaView, StyleSheet, Text, View } from 'react-native';
import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import colors from '@styles/colors';
import SpSentIcon from 'src/components/RoundIcons/SpSentIcon';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { RootStackParamList } from 'src/pages';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import CustomHeader from 'src/components/CustomHeader';
import { SpText } from '../components/SpText/SpText';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { testProperties } from '@utils/testProperties';
import { useDeviceId } from '@hooks/useDeviceId';
import useBoundStore from '../store/store';
import { navigationRef } from './RootNavigation';

export type navObject = {
  parentNavigatorName?: string;
  screenName: string;
  params?: any;
  makeLogout?: boolean;
  reset?: boolean;
};

type Props = NativeStackScreenProps<RootStackParamList, 'SuccessScreen' | 'SuccessScreenModal'>;

const SuccessScreen = ({ route }: Props) => {
  const { withButton, title, msg, buttonText, buttonNavObject, withHeader, headerNavObject } =
    route.params;
  const { logout } = useBoundStore(state => state.accountStore);
  const deviceId = useDeviceId();
  const navigation = useNavigation();

  const navigationHandler = (navObject: navObject) => {
    const { parentNavigatorName, screenName, makeLogout = false } = navObject;
    if (makeLogout) {
      logout(deviceId);
    }

    if (navObject?.reset) {
      navigationRef.current.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: screenName }],
        }),
      );
      return;
    }

    // @ts-ignore
    return parentNavigatorName
      ? // @ts-ignore
        navigation.navigate(parentNavigatorName, {
          screen: screenName,
          params: navObject?.params || {},
        })
      : // @ts-ignore
        navigation.navigate(screenName, navObject?.params || {});
  };

  const handleAndroidBackPress = useCallback(() => {
    navigationRef.reset({
      index: 0,
      routes: [{ name: 'DashboardNavigation' }],
    });
    return true;
  }, [navigationRef]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', handleAndroidBackPress);

    return () => {
      backHandler.remove();
    };
  }, [handleAndroidBackPress]);

  return (
    <SafeAreaView style={styles.container}>
      {withHeader && (
        <CustomHeader
          goBackFunc={() => navigationHandler(headerNavObject)}
          withTitle={false}
        />
      )}
      <View style={[styles.inner, { paddingTop: withHeader ? 80 : 136 }]}>
        <SpSentIcon />
        <Text
          {...testProperties(title, 'TitleText')}
          style={styles.title}>
          {title}
        </Text>
        <SpText
          align="center"
          fontFamily="Rubik"
          style={styles.message}>
          {msg}
        </SpText>
        {withButton && (
          <View style={styles.buttonWrap}>
            <SpRoundedHeaderButton
              backgroundColor={colors.primary.color}
              h={56}
              title={buttonText}
              onPress={() => navigationHandler(buttonNavObject)}
            />
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

export default SuccessScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 19,
  },
  message: { lineHeight: 24, marginTop: 36 },
  title: {
    fontSize: 20,
    marginTop: 12,
    lineHeight: 28,
    color: colors.greyText.color,
    fontFamily: 'Rubik_Medium',
  },
  buttonWrap: {
    position: 'absolute',
    bottom: 34,
    right: 19,
    left: 19,
  },
});
