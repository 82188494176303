/* eslint-disable react/react-in-jsx-scope */
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import textStyles from '@styles/text';
import { useTranslation } from 'react-i18next';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { StyleSheet } from 'react-native';
import { PetStatisticsModel } from '@models/Device';
import React, { Fragment } from 'react';
import useAdaptiveFontSize from '@hooks/useAdaptiveFontSize';
import useTotalConsumption from '../../../../hooks/useTotalConsumption';

type Props = {
  isFeeds: boolean;
  data: PetStatisticsModel['feeding'] | PetStatisticsModel['drinking'];
};

const PetTimeAtBowl: React.FC<Props> = ({ isFeeds, data }) => {
  const { t } = useTranslation();
  const time = useTotalConsumption(data.consumption_time, 1);
  const [sizes, layoutHandler, opacityStyle] = useAdaptiveFontSize(
    {
      data: 24,
      label: 16,
    },
    [time],
  );
  return (
    <SpView style={styles.row}>
      <SpView>
        <SpView style={styles.msin}>
          <SpView {...testProperties(faClock.iconName, 'icon')}>
            <FontAwesomeIcon
              icon={faClock}
              size={16}
              color={colors.primary.color}
            />
          </SpView>
          <SpText style={[textStyles.defaultText, { marginLeft: 5 }]}>{t('time_at_bowl')}</SpText>
        </SpView>
        <SpText
          style={[styles.maxWidthtext, opacityStyle]}
          onTextLayout={layoutHandler}>
          {time.split(' ').map((part, index) => {
            const isNumber = !Number.isNaN(Number(part));
            const size = isNumber ? sizes.data : sizes.label;
            return (
              <Fragment key={`element${part}`}>
                <SpText
                  style={[textStyles.title, styles.timeData, { fontSize: size, lineHeight: 24 }]}>
                  {part}
                </SpText>
                <SpView width={5} />
              </Fragment>
            );
          })}
        </SpText>
      </SpView>
      <SpView style={styles.block}>
        <SpText style={[textStyles.title, { lineHeight: undefined }]}>
          {data.number_of_visits}
        </SpText>
        <SpText
          style={[textStyles.title, styles.smallText]}
          numberOfLines={2}
          adjustsFontSizeToFit>
          {t(isFeeds ? 'feeds' : 'drinks').toLocaleLowerCase()}
        </SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  msin: {
    marginBottom: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  block: {
    borderRadius: 12,
    alignItems: 'center',
    padding: 6,
    backgroundColor: colors.grayBottomSheetHeader.color,
    justifyContent: 'space-between',
    gap: 2,
    maxWidth: '30%',
  },
  row: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  smallText: {
    fontSize: 14,
    lineHeight: undefined,
  },
  timeData: {
    fontFamily: 'Rubik_Medium',
    textAlign: 'left',
  },
  maxWidthtext: {
    maxWidth: '70%',
  },
});

export default PetTimeAtBowl;
