import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import text from '@styles/text';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Keyboard,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import SpSearchList from 'src/components/SpSearchList';
import { SpView } from 'src/components/SpView';
import { RootStackParamList } from 'src/pages';
import useBoundStore from 'src/store/store';
import colors from 'src/styles/colors';

import { SpDivider } from '../../../components/SpDivider';
import { SpLoading } from '../../../components/SpLoading';
import { SpRoundedHeaderButton } from '../../../components/SpRoundedHeaderButton';
import { SpText } from '../../../components/SpText/SpText';
import { FlowStackParamList } from '../index';

type Props = NativeStackScreenProps<FlowStackParamList>;

export const SelectSSID = ({ navigation, route }: Props) => {
  const [value, setValue] = useState(route.params.inputValue);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { deviceSSIDS, setTargetNetworkID, targetNetworkID, startScanDevice, loading } =
    useBoundStore(s => s.WiFiDeviceStore);

  const { t } = useTranslation();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();

  const searchAgain = useCallback(() => {
    startScanDevice();
  }, []);

  async function onSSIDSelect() {
    setButtonDisabled(true);
    setTargetNetworkID(value);

    navigation.pop();
  }
  const handleNavigation = (uri: string) => {
    return () => {
      rootNavigation.navigate('WebviewScreen', {
        uri: `https://${uri}`,
        title: 'Support',
      });
    };
  };
  const onChangeSSID = useCallback(
    (ids: number[]) => {
      setValue(ids[0]);
    },
    [deviceSSIDS],
  );
  return (
    <SafeAreaView style={styles.content}>
      {deviceSSIDS.length > 0 ? (
        <SpView style={styles.inner}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <SpView style={styles.container}>
              <SpSearchList
                arr={deviceSSIDS.map(ssid => {
                  const newSsid = {
                    ...ssid,
                    itemImage: (
                      <FontAwesomeIcon
                        color={colors.greyText.color}
                        size={20}
                        // This is done only to get the idea in case later we want to put the level of the signal
                        icon={ssid < -79 ? faWifi : ssid < 0 ? faWifi : faWifi}
                      />
                    ),
                  };
                  return newSsid;
                })}
                selectedItems={[value || 0]}
                setItemId={onChangeSSID}
                withImage
                clearSelectionOnZeroIndex={false}
                clearInMainListOnNegativeIndex
                buttonText={t('refresh_networks')}
                buttonCallback={searchAgain}
              />
            </SpView>
          </TouchableWithoutFeedback>
          <SpView style={styles.buttonWrapper}>
            <SpRoundedHeaderButton
              disabled={buttonDisabled}
              backgroundColor={colors.primary.color}
              h={56}
              title={t('save')}
              isLoading={buttonDisabled}
              onPress={() => onSSIDSelect()}
            />
          </SpView>
        </SpView>
      ) : loading ? (
        <SpLoading />
      ) : (
        <>
          <SpView style={{ ...styles.noWiFiHeader }}>
            <SpText>{t('no_networks_found')}</SpText>
            <SpRoundedHeaderButton
              stylesForContainer={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20,
              }}
              w={200}
              backgroundColor={colors.greyText.color}
              textStyles={{
                fontSize: 16,
                fontFamily: 'Rubik_Medium',
                color: colors.white.color,
              }}
              onPress={() => searchAgain()}
              title={t('search_for_networks')}
            />
          </SpView>
          <SpDivider />

          <SpView style={{ flex: 1, paddingHorizontal: 15, paddingTop: 35 }}>
            <SpView style={{ flex: 1 }}>
              <SpText style={[text.defaultTextSmall, { textAlign: 'left' }]}>
                {t('before-searching-again-check-the-following')}
              </SpText>
              <SpText style={[text.leftSubtitle]}>{t('2-4-only')}</SpText>
              <SpText style={text.defaultTextSmall}>{t('make-sure-your-wi-fi-network-is')}</SpText>
              <SpText style={[text.leftSubtitle]}>{t('check-connection')}</SpText>
              <SpText style={text.defaultTextSmall}>
                {t('make-sure-your-wi-fi-router-is-turned-on-and-has-a-good-connection')}
              </SpText>
              <SpText style={[text.leftSubtitle]}>{t('router-distance')}</SpText>
              <SpText style={text.defaultTextSmall}>
                {t('try-getting-closer-to-your-wi-fi-router')}
              </SpText>
            </SpView>
            <SpView style={{ position: 'absolute', bottom: 10, left: 15, width: '100%' }}>
              <SpText style={text.body}>{t('setup_cdb_failed_to_pair_mode_help')}</SpText>
              <TouchableOpacity onPress={handleNavigation(t('setup_cdb_battery_link'))}>
                <SpText
                  color="#fff"
                  bold
                  style={text.linkText}>
                  {t('setup_cdb_battery_link')}
                </SpText>
              </TouchableOpacity>
            </SpView>
          </SpView>
        </>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
  },
  buttonWrapper: {
    paddingHorizontal: 19,
    marginBottom: 34,
  },
  noWiFiHeader: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
  },
});
