import { useLayoutEffect } from 'react';

import useBoundStore from '../store/store';

const useCleanLoadingControlCache = () => {
  const {
    setUpdateCurfewLoading,
    setUpdateLockUnlockLoading,
    resetLoadingControlJustSent,
    setUpdateTagProfileLoading,
    setUpdateZeroScalesLoading,
  } = useBoundStore(store => store.deviceStore);

  useLayoutEffect(() => {
    console.log('Reset Loading Control Cache');
    setUpdateCurfewLoading(false);
    setUpdateLockUnlockLoading(false);
    setUpdateTagProfileLoading(false);
    setUpdateZeroScalesLoading(false);
    resetLoadingControlJustSent();
  }, [
    resetLoadingControlJustSent,
    setUpdateCurfewLoading,
    setUpdateLockUnlockLoading,
    setUpdateTagProfileLoading,
    setUpdateZeroScalesLoading,
  ]);
};

export default useCleanLoadingControlCache;
