import { useTimeTransform } from '@hooks/useTimeTransform';
import { MappedMovementReportDataModel } from '@models/ReportModel';
import { differenceInSeconds, endOfDay, isBefore } from 'date-fns';
import { Interval } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useBoundStore from 'src/store/store';

import { EventDetailsCard } from './EventDetailsCard';
import { TimeService } from '../../../services/TimeService';
import { useTransformMovementEventToReport } from '../hooks/useTransformMovementEventToReport';

interface MovementPointReportProps {
  data: MappedMovementReportDataModel;
  index?: number;
}

export const MovementEventReport = ({ data, index }: MovementPointReportProps) => {
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const item = useTransformMovementEventToReport(data, activeHousehold.timezone.timezone);
  const { t } = useTranslation();
  const { getDeviceById } = useBoundStore((s) => s.deviceStore);

  const { concatTwoDigitFormatToOne, convertSecondToTwoDigitFormat } = useTimeTransform();

  const fullDay = useMemo(() => {
    const fullDayDiff = Interval.fromDateTimes(
      TimeService.toLocal(data.to),
      TimeService.toLocal(data.from),
    ).length('seconds');
    return fullDayDiff > 86400;
  }, []);

  const isSeveralDaysEnd = () => {
    return isBefore(
      TimeService.toLocal(data.from).startOf('day').toSeconds(),
      TimeService.toLocal(data.to).startOf('day').toSeconds(),
    );
  };

  const endDate = useMemo(() => {
    // TODO this needs to use the 'active' property not the entry device id
    if (isSeveralDaysEnd() || item.duration === '23h 59m' || data.active) return `- - -`;
    return item.end;
  }, []);

  const startDate = useMemo(() => {
    if (index === 0 && (item.start === '00:00' || item.duration === '23h 59m')) return '- - -';
    return item.start;
  }, []);

  const duration = useMemo(() => {
    console.log(
      concatTwoDigitFormatToOne(convertSecondToTwoDigitFormat(86400)),
      convertSecondToTwoDigitFormat(86400),
    );

    if (fullDay) {
      return concatTwoDigitFormatToOne(convertSecondToTwoDigitFormat(86400));
    }

    if (isSeveralDaysEnd()) {
      const convertedFrom = new Date(data.from);
      const convertedEndDay = endOfDay(convertedFrom);
      const duration = differenceInSeconds(convertedEndDay, convertedFrom);
      return concatTwoDigitFormatToOne(convertSecondToTwoDigitFormat(duration));
    }
    return item.duration;
  }, []);
  const getDeviceName = (data) => {
    if (data.entryDevice?.name && data.exitDevice?.name) {
      if (data.entryDevice.name === data.exitDevice.name) {
        return data.entryDevice.name;
      } else {
        return `${data.exitDevice.name}/${data.entryDevice.name}`;
      }
    }
    if (data.entryDevice?.name) {
      return data.entryDevice.name;
    }
    if (data.exitDevice?.name) {
      return data.exitDevice.name;
    }
    return '';
  };
  return (
    <EventDetailsCard
      leftLabel={t('exit/entry')}
      leftValue={`${startDate} - ${endDate}`}
      rightLabel={t('duration')}
      rightValue={duration}
      deviceName={getDeviceName(data)}
    />
  );
};
