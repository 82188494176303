import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { SpCarouselWithSteps } from 'src/components/CarouselWithSteps';
import colors from '@styles/colors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppState,
  Button,
  Dimensions,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faCaretRight,
  faCircleExclamation,
  faCopy,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { stepsEnums } from '../StepForms/Devices/RootDevices/AddDogBowl';
import * as Clipboard from 'expo-clipboard';

export const SLIDER_WIDTH = Dimensions.get('window').width + 90;
export const ITEM_WIDTH = 325;
export const WORD_TO_COPY = 'SurePetcare';

interface InstructionStep3Props {
  enableInstructionStep3Continue: (data: number) => void;
  setNumberItems?: (data: number) => void;
  setStep: (step: number) => void;
  nextStep?: number;
  screenActive: boolean;
}

export const InstructionStep3 = ({
  enableInstructionStep3Continue,
  setNumberItems,
  setStep,
  nextStep,
  screenActive,
}: InstructionStep3Props) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState<boolean>(false);
  const [appState, setAppState] = useState(AppState.currentState);

  useEffect(() => {
    const handleAppStateChange = nextAppState => {
      if (!screenActive) return;
      if (appState.match(/inactive|background/) && nextAppState === 'active') {
        // User has returned to the app
        setTimeout(() => {
          setStep(
            nextStep && typeof nextStep === 'number' ? nextStep : stepsEnums.WiFiConnectToDevice,
          );
        }, 500);
      }
      setAppState(nextAppState);
    };
    const subscription = AppState.addEventListener('change', handleAppStateChange);
    return () => {
      subscription.remove();
    };
  }, [appState]);

  async function goToWiFiSettings() {
    if (Platform.OS === 'ios') {
      return await Linking.canOpenURL('App-Prefs:WIFI')
        .then(supported => {
          if (supported) {
            return Linking.openURL('App-Prefs:WIFI');
          }
          return false;
        })
        .catch(err => console.error('An error occurred', err));
    }
    return await Linking.sendIntent(`android.settings.WIFI_SETTINGS`);
  }

  const items = useMemo(() => {
    const copyAction = async () => {
      if (!copied) {
        setCopied(!copied);
        await Clipboard.setStringAsync(WORD_TO_COPY);
      }
    };
    const takeUserToSettings = () => {
      goToWiFiSettings();
    };
    return [
      {
        title: t('setup_connect_cdb_title_1'),
        subtitle: t('setup_connect_cdb_subtitle_1'),
        textInGrey: t('setup_connect_cdb_text_grey_1'),
        button: null,
        textBottom: t('setup_connect_cdb_text_bottom_1'),
        step: 1,
      },
      {
        title: t('setup_connect_cdb_title_2'),
        subtitle: t('setup_connect_cdb_subtitle_2'),
        textInGrey: t('setup_connect_cdb_text_grey_2'),
        button: (
          <TouchableOpacity onPress={copyAction}>
            <SpView
              backgroundColor={copied ? colors.primary.color : '#233a43'}
              style={styles.btnCarousel}>
              <SpText
                color={'white'}
                style={styles.textBtnCarousel}>
                {copied
                  ? t('setup_connect_cdb_text_btn_copied_2')
                  : t('setup_connect_cdb_text_btn_copy_2')}
              </SpText>
              <FontAwesomeIcon
                icon={faCopy}
                color={'white'}
                style={styles.iconBtnCarousel}
              />
            </SpView>
          </TouchableOpacity>
        ),
        textBottom: t('setup_connect_cdb_text_bottom_2'),
        step: 2,
      },
      {
        textInGrey: Platform.OS === 'android'
                    ? [t('setup_connect_cdb_text_grey_android_3_1'), [t('setup_connect_cdb_text_grey_android_3_2_1'),t('setup_connect_cdb_text_grey_android_3_2_2'),t('setup_connect_cdb_text_grey_android_3_2_3')]]
                    : [t('setup_connect_cdb_text_grey_ios_3_1'), [t('setup_connect_cdb_text_grey_ios_3_2_1'),t('setup_connect_cdb_text_grey_ios_3_2_2'),t('setup_connect_cdb_text_grey_ios_3_2_3')]],
        button: <TouchableOpacity onPress={takeUserToSettings}>
          <SpView backgroundColor={colors.primary.color} style={styles.btnSettingCarousel}>
            <FontAwesomeIcon icon={faWifi} color={'white'} style={styles.iconWifiBtnCarousel} />
            <SpText color={'white'} style={styles.textSettingBtnCarousel}>{t('setup_connect_cdb_text_btn_3')}</SpText>
            <FontAwesomeIcon icon={faCaretRight} color={'white'} style={styles.iconBtnCarousel} />
          </SpView>
        </TouchableOpacity>,
      },
    ];
  }, [copied]);

  useEffect(() => {
    if (!setNumberItems) return;
    setNumberItems(items.length);
  }, []);

  const renderCartItem = useCallback(
    (props: {
      item: {
        title?: string;
        subtitle?: string;
        textInGrey: string | string[];
        textBottom?: string;
        button?: Button;
        step?: number;
      };
    }) => {
      return (
        <SpView accessible style={[styles.item, {...(!props.item.step?{justifyContent: 'space-between', paddingHorizontal: 8}:{})} ]}>
          {props.item.title && <SpView style={styles.titleWrapperStyle}>
            <SpView style={styles.blackCircle}>
              <SpText color={'white'} size={'sm'} style={styles.titleStepStyle}>{props.item.step}</SpText>
            </SpView>
            <SpText style={styles.titleStyle} color={'#233a43'}>{props.item.title}</SpText>
          </SpView>}
          {props.item.subtitle && <SpView style={styles.subtitleWrapperStyle}>
            <SpText size={'md'} color={'#929da1'} style={styles.textSubtitleStepStyle}>{props.item.subtitle}</SpText>
          </SpView>}
          <SpView style={[styles.greyTextWrapperStyle,{...(!props.item.step?{paddingBottom: 0}:{})}]}>
            <SpView>
              {!props.item.step &&
                <SpView style={styles.iconInGreyText} color={'orange'}><FontAwesomeIcon icon={faCircleExclamation}
                                                                                        size={40}
                                                                                        color={'orange'} /></SpView>}
              {Array.isArray(props.item.textInGrey) ?
                <SpView style={!props.item.step ? styles.lastCarouselItemWithoutStep : styles.lastCarouselItem}>
                  <SpText style={!props.item.step ? styles.lastGreyWithoutStepTextStyle : styles.lastGreyTextStyle}>
                    {props.item.textInGrey[0]}</SpText>
                  {Array.isArray(props.item.textInGrey[1]) ?
                  <SpText style={!props.item.step ? styles.lastGreyWithoutStepTextStyle : styles.lastGreyTextStyle}>
                    {props.item.textInGrey[1][0]}
                    <SpText style={[!props.item.step ? styles.lastGreyWithoutStepTextStyle : styles.lastGreyTextStyle, { fontFamily: 'Rubik_SemiBold' }]}>
                      {props.item.textInGrey[1][1]}
                    </SpText>
                    {props.item.textInGrey[1][2]}
                  </SpText>
                  :
                  <SpText style={!props.item.step ? styles.lastGreyWithoutStepTextStyle : styles.lastGreyTextStyle}>
                  {props.item.textInGrey[1]}</SpText>
                  }
                </SpView>
                :
                <SpText style={!props.item.step ? styles.greyWithoutStepTextStyle : styles.greyTextStyle}
                      color={'#233a43'}>
                        {props.item.textInGrey}</SpText>
              }
            </SpView>
          </SpView>
          {props.item.textBottom && (
            <SpView style={styles.textBottomWrapperStyle}>
              <SpText
                color={'#233a43'}
                style={styles.textBottomStyle}>
                {props.item.textBottom}
              </SpText>
            </SpView>
          )}
          <SpView>{props.item.button}</SpView>
        </SpView>
      );
    },
    [],
  );

  return (
    <SpView style={styles.container}>
      <SpView style={styles.mainSentenceWrapper}>
        <SpText
          color={'#233a43'}
          style={styles.mainSentenceText}>
          {t('setup_connect_cdb_main_sentence')}
        </SpText>
      </SpView>
      <SpCarouselWithSteps
        renderItem={renderCartItem}
        data={items}
        sliderWidth={SLIDER_WIDTH}
        slideWidth={ITEM_WIDTH}
        height={'80%'}
        onLastTemplate={enableInstructionStep3Continue}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: '3%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainSentenceWrapper: {
    marginTop: 20,
    marginBottom: 40,
    width: '70%',
  },
  mainSentenceText: {
    textAlign: 'center',
    fontFamily: 'Rubik',
    fontSize: 17,
  },
  item: {
    flex: 1,
    paddingHorizontal: 18,
    paddingVertical:15,
    justifyContent: 'space-around',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
    marginBottom: '6%',
  },
  titleWrapperStyle: {
    flexDirection: 'row',
    marginBottom: 30,
  },
  blackCircle: {
    backgroundColor: '#666c78',
    borderRadius: 50,
    width: 48,
    height: 48,
  },
  titleStepStyle: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 28,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    flex: 1,
    paddingTop: '40%',
  },
  titleStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    fontFamily: 'Rubik_SemiBold',
    paddingLeft: 18,
    fontSize: 20,
  },
  subtitleWrapperStyle: {
    alignItems: 'center',

    /*borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'red',*/
  },
  textSubtitleStepStyle: {
    fontFamily: 'Rubik_SemiBold',
  },
  greyTextWrapperStyle: {
    backgroundColor: '#edeff0',
    borderRadius: 20,
    width: '90%',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
    paddingVertical: 25,
  },
  greyTextStyle: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 20,
  },
  greyWithoutStepTextStyle: {
    padding: 20,
    fontSize: 14,
  },
  lastCarouselItem: {
    gap: 8,
  },
  lastCarouselItemWithoutStep: {
    gap: 8,
    padding: 20,
  },
  lastGreyTextStyle: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 20,
  },
  lastGreyWithoutStepTextStyle: {
    fontSize: 14,
    textAlign: 'center',
  },
  iconInGreyText: {
    alignItems: 'center',
    alignSelf: 'center',
  },
  textBottomWrapperStyle: {
    marginTop: 5,
    width: '94%',
    alignItems: 'center',
    alignSelf: 'center',
    fontSize: 16,
  },
  textBottomStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 15,
    flex: 1,
  },
  btnCarousel: {
    width: '55%',
    height: 35,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  textBtnCarousel: {
    fontFamily: 'Rubik_SemiBold',
    position: 'absolute',
    left: 0,
    marginLeft: 20,
  },
  textSettingBtnCarousel: {
    fontFamily: 'Rubik_SemiBold',
    position: 'absolute',
    fontSize: 18,
  },
  iconBtnCarousel: {
    position: 'absolute',
    right: 0,
    marginRight: 20,
  },
  btnSettingCarousel: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    flex: 1,
    width: '100%',
    height: 60,
    borderRadius: 20,
  },
  iconWifiBtnCarousel: {
    position: 'absolute',
    left: '25%',
  },
});
