import { ConsumptionInsightOutcome, ConsumptionInsightByDay } from "@models/ConsumptionInsight";

export const useStatusVisible = (data:  Record<number, ConsumptionInsightByDay>) => {
  return Object.values(data || {}).some(field => {
    return (
      !!field?.alert ||
      (!!field?.habit &&
        ![
          ConsumptionInsightOutcome.Unavailable,
          ConsumptionInsightOutcome.WillBeAvailable,
          ConsumptionInsightOutcome.Ok,
        ].includes(field?.habit.outcome))
    );
  });
};
