import { BreedModel } from '@models/Breed';
import qs from 'qs';
import Http from './Http';

class BreedApi {
  static readonly httpParams = {};

  static getBreeds(): Promise<BreedModel[]> {
    return Http.get(`/api/breed`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default BreedApi;
