import * as React from 'react';
import { Path, Svg } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const WCIAlertInfo = (props: any) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <Path
      d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0Z"
      fill="#ED0055"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.2C11.12 19.2 10.4 18.48 10.4 17.6C10.4 16.72 11.12 16 12 16C12.88 16 13.6 16.72 13.6 17.6C13.6 18.48 12.88 19.2 12 19.2Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.832 6.79205L13.296 13.208C13.24 13.88 12.68 14.4 12 14.4C11.32 14.4 10.76 13.88 10.704 13.208L10.168 6.79205C10.08 5.72005 10.92 4.80005 12 4.80005C13.024 4.80005 13.84 5.63205 13.84 6.64005C13.84 6.68805 13.84 6.74405 13.832 6.79205Z"
      fill="white"
    />
  </Svg>
);
export default WCIAlertInfo;
