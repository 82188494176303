import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { PetStatisticsModel } from '@models/Device';
import { getSingleDuration } from '@utils/date';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@utils/capitalize';

export function useSummaryFeedingCard(data: PetStatisticsModel['drinking']) {
  const { t } = useTranslation();
  const userTimeFormat = useUserTimeUnits();
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const { timeToFormat, convertDateToTime } = useTimeTransform();

  const totalWeight = useMemo(() => {
    if (!data?.total_consumption) return `0 ${t('gram')}`;
    return convertWithUnits(data.total_consumption, true);
  }, [data?.total_consumption]);

  const lastFeed = useMemo(() => {
    return data?.last_consumption
      ? timeToFormat(convertDateToTime(data.last_consumption), userTimeFormat)
      : '-';
  }, [data?.last_consumption]);

  const timeAgo = useMemo(() => {
    return data?.last_consumption ? getSingleDuration(data.last_consumption, true) : '-';
  }, [data?.last_consumption]);

  return {
    title: t('last_ate'),
    timeAgo,
    time: lastFeed,
    leftLabel: totalWeight,
    leftValue: t('amount_eaten'),
    rightLabel: data?.number_of_visits,
    rightValue: capitalize(t('feeds')),
  };
}
