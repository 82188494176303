import { DeviceFeederBowlType } from '@constants/Device';
import { DeviceFeederBowlModel } from '@models/Device';
import { EditMenuItem } from '@models/Menu';
import i18n from '@utils/i18n';
import { t } from 'i18next';
import { convertToGrams } from '@utils/convertToGrams';

export const getTargetSide = (isLeft: boolean): string => {
  return isLeft ? i18n.t('left_bowl') : i18n.t('right_bowl');
};

export const getTargetWithUnit = (
  value: number,
  unit: string,
  withUndefined = false,
): string | undefined => {
  if (!value) {
    if (withUndefined) return undefined;
    return t('not_selected');
  }
  return `${value || 0}${unit}`;
};

export const getActions = (
  data: DeviceFeederBowlModel,
  changeTarget: any,
  unit: string,
): EditMenuItem[] => {
  if (data?.type === DeviceFeederBowlType.Half) {
    return [
      {
        label: i18n.t('left_bowl_portion'),
        value: getTargetWithUnit(convertToGrams(data?.settings[0]?.target, unit, true), unit, true),
        action: () => changeTarget(data, true),
      },
      {
        label: i18n.t('right_bowl_portion'),
        value: getTargetWithUnit(convertToGrams(data?.settings[1]?.target, unit, true), unit, true),
        action: () => changeTarget(data, false),
      },
    ];
  }

  return [
    {
      label: i18n.t('bowl'),
      value: getTargetWithUnit(convertToGrams(data?.settings[0]?.target, unit, true), unit, true),
      action: () => changeTarget(data, true),
    },
  ];
};
