import React, { FC } from 'react';
import { SpView } from 'src/components/SpView';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';

interface IMockLinechart {
  type: 'circle' | 'square';
  items?: any[];
}

const MockLinechart: FC<IMockLinechart> = ({ type = 'circle', items }) => {
  return (
    <SpView style={[styles.lineWrapper, styles]}>
      <SpView style={styles.line}>
        {items?.map(item => (
          <SpView
            key={`${item.width}${Math}`}
            style={[
              styles.lineItem,
              { width: item?.width, left: item.left },
              styles[type],
              item?.isActive ? styles.activeItem : {},
            ]}
          />
        ))}
      </SpView>
      <SpView>
        <SpView style={styles.descriptionBlock}>
          <SpView style={styles.descriptionBlockSection}>
            <SpView style={[styles.descriptionItem, { width: '50%' }]} />
            <SpView style={[styles.descriptionItem, { width: '37%' }]} />
          </SpView>
          <SpView style={[styles.descriptionBlockSection, { alignItems: 'flex-end' }]}>
            <SpView style={[styles.descriptionItem, { width: 46 }]} />
            <SpView style={[styles.descriptionItem, { width: 64 }]} />
          </SpView>
          <SpView style={styles.hiddenDescriptionBlock} />
        </SpView>
      </SpView>
      <SpView />
    </SpView>
  );
};

const styles = StyleSheet.create({
  line: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 6,
    backgroundColor: '#263a43',
    borderRadius: 18,
    height: 36,
    alignItems: 'center',
    marginBottom: 12,
  },
  lineItem: {
    position: 'absolute',
    height: 24,
    backgroundColor: colors.pastelGreen.color,
  },
  circle: {
    width: 24,
    borderRadius: 12,
  },
  square: {
    borderRadius: 4,
  },
  lineWrapper: {},
  activeItem: {
    backgroundColor: colors.toxicGreen.color,
  },
  descriptionBlock: {
    width: '88%',
    height: 76,
    backgroundColor: colors.lightBlack.color,
    paddingHorizontal: 18,
    paddingVertical: 16,
    borderRadius: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  descriptionItem: {
    height: 16,
    borderRadius: 15,
    backgroundColor: colors.pastelGreen.color,
  },
  descriptionBlockSection: {
    width: '50%',
    rowGap: 8,
    flexDirection: 'column',
  },
  hiddenDescriptionBlock: {
    height: 76,
    width: '10%',
    backgroundColor: colors.lightBlack.color,
    opacity: 0.57,
    position: 'absolute',
    right: '-15%',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
});

export default MockLinechart;
