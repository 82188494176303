/* eslint-disable consistent-return */
import { SpLoading } from 'src/components/SpLoading';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SubstanceType } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import { useCustomToast } from '@hooks/useToast';
import { DeviceCerberusControlModel } from '@models/Device';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { SpView } from 'src/components/SpView';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { useTranslation } from 'react-i18next';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import useBoundStore from '../../../../../../store/store';
import { DeviceStackParamList } from '../../index';
import { DeviceSettingContainer } from '../../components/DeviceSettingContainer';
import { SubstanceTypes } from '../../components/DeviceBowlSetup/SubstanceTypes';

export const DeviceSubstanceSetup = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceSubstanceSetup'>) => {
  const { show } = useCustomToast();
  const { id } = route.params;
  const device = useDeviceById(id);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const deviceNavigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const { loadingDevice, updateCerberusControl, resetStatusFields } = useBoundStore(
    s => s.deviceStore,
  );
  const [substanceType, setSubstanceType] = useState(
    (device?.control as DeviceCerberusControlModel).substance_type || 1,
  );

  const changeSubstanceTypeAction = useCallback(($event: SubstanceType) => {
    setSubstanceType($event);
  }, []);

  const onChange = useCallback(async () => {
    const control = {
      substance_type: substanceType,
    } as DeviceCerberusControlModel;

    const result = await updateCerberusControl(device.id, control);

    if (!result) {
      show({ description: t('error_update_substance_type'), isError: true });
      return;
    }
    show({ isError: false, description: t('success_update_substance_type') });

    if (substanceType === SubstanceType.water) {
      return navigation.goBack();
    }

    return !loadingDevice ? deviceNavigation.navigate('DeviceFoodSetup', { id: device.id }) : null;
  }, [substanceType, loadingDevice]);

  useEffect(() => {
    return resetStatusFields;
  }, []);

  if (loadingDevice || !device) {
    return <SpLoading />;
  }

  return (
    <DeviceSettingContainer>
      <SpView style={styles.wrapperContainer}>
        <SpView style={styles.mainWrapper}>
          <SpView>
            <SpText align="center">{t('change_pet_bowl_title')}</SpText>
          </SpView>
          <SubstanceTypes
            active={substanceType}
            onChange={changeSubstanceTypeAction}
          />
        </SpView>
        <SpView style={styles.buttonWrapper}>
          <SpRoundedHeaderButton
            backgroundColor={colors.primary.color}
            h={56}
            title={t('update')}
            onPress={onChange}
            isLoading={loadingDevice}
            stylesForContainer={styles.button}
          />
        </SpView>
      </SpView>
    </DeviceSettingContainer>
  );
};

const styles = StyleSheet.create({
  wrapperContainer: {
    flexGrow: 1, // all the available vertical space will be occupied by it
    justifyContent: 'space-between',
  },
  mainWrapper: {
    height: '85%',
    display: 'flex',
  },
  button: {
    marginBottom: 67,
    marginTop: 'auto',
  },
  buttonWrapper: {
    textAlignVertical: 'bottom',
  },
});
