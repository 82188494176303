import { CloseDelays, DeviceType, PetBowlFoodType, SubstanceType } from '@constants/Device';
import {
  DeviceCerberusControlModel,
  DeviceFeederControlModel,
  DeviceLoadingControl,
  DeviceModel,
} from '@models/Device';
import { EditMenuItem } from '@models/Menu';
import { PetModel } from '@models/Pet';
import { NavigationProp, StackActions } from '@react-navigation/native';
import colors from '@styles/colors';
import i18n from '@utils/i18n';
import React from 'react';
import { SpView } from 'src/components/SpView';
import BowlSVG from 'src/components/SvgIcons/BowlSVG';
import FullWifiSVG from 'src/components/SvgIcons/FullWifiSVG';
import PortionSVG from 'src/components/SvgIcons/PortionSVG';

import { PetsPreview } from '../components/DeviceSetting/PetsPreview';
import { DeviceStackParamList } from '../index';

export const getActions = (
  device: DeviceModel,
  pets: PetModel[],
  navigation: NavigationProp<DeviceStackParamList>,
  userHasWriteAccess: boolean,
  loadingControl: DeviceLoadingControl,
): EditMenuItem[] => {
  if (!device) return [];
  const actions: EditMenuItem[] = [
    {
      disabled: !device.status.online,
      label: i18n.t('product_name'),
      value: device?.name,
      viewOnly: !userHasWriteAccess,
      action: () => {
        if (!device.status.online) return;
        navigation.dispatch(StackActions.push('DeviceName', { id: device.id }));
      },
    },
  ];

  if (
    device.product_id === DeviceType.PetDoorConnect ||
    device.product_id === DeviceType.CatFlapConnect ||
    device.product_id === DeviceType.FeederConnect ||
    device.product_id === DeviceType.FelaquaConnect ||
    device.product_id === DeviceType.Cerberus
  ) {
    actions.push({
      disabled: !device.status.online,
      label: i18n.t('assign_pets'),
      viewOnly: !userHasWriteAccess,
      loadingKey: 'tag_profiles',
      value: (
        <SpView marginTop={6}>
          <PetsPreview
            data={pets.map((item) => ({
              name: item?.name,
              id: item?.id,
              image: item?.photo?.location,
            }))}
          />
        </SpView>
      ),
      action: () => {
        if (!device.status.online) return;
        navigation.dispatch(
          StackActions.push('DeviceAssignPet', { id: device.id }),
        );
      },
    });
  }

  if (device.product_id === DeviceType.Cerberus) {
    const control = device?.control as DeviceCerberusControlModel;
    actions.push(
      ...[
        {
          disabled: !device.status.online,
          label: i18n.t('bowl_use'),
          value: SubstanceType[control.substance_type]
            ? i18n.t(SubstanceType[control.substance_type])
            : null,
          loadingKey: 'bowls_control',
          viewOnly: !userHasWriteAccess,
          action: () => {
            if (!device.status.online) return;
            navigation.dispatch(
              StackActions.push('DeviceSubstanceSetup', { id: device.id }),
            );
          },
        },
        {
          disabled: !device.status.online,
          label: i18n.t('wifi_setup'),
          viewOnly: !userHasWriteAccess,
          iconSVG: (
            <FullWifiSVG
              color={colors.greyText.color}
              width={20}
              height={20}
              viewBox="0 0 23 18"
            />
          ),
          action: () => {
            if (!device.status.online) return;
            navigation.dispatch(
              StackActions.push('DeviceWiFiSetup', { id: device.id }),
            );
          },
        },
      ],
    );
    if (control.substance_type === SubstanceType.food) {
      actions.push(
        ...[
          {
            disabled: !device.status.online,
            label: i18n.t('food_setup'),
            value: i18n.t(PetBowlFoodType[control.food_type]),
            loadingKey: 'bowls_control',
            viewOnly: !userHasWriteAccess,
            action: () => {
              if (!device.status.online) return;
              navigation.dispatch(
                StackActions.push('DeviceFoodSetup', { id: device.id }),
              );
            },
          },
        ],
      );
    }
  }

  if (device.product_id === DeviceType.FeederConnect) {
    const control = device?.control as DeviceFeederControlModel;
    const controlLidCloseDelay = (): string => {
      const { lid } = control;

      if (!lid?.close_delay && lid?.close_delay !== 0) {
        return i18n.t('not_selected');
      }
      if ((lid.close_delay as number) === 40) lid.close_delay = 20;
      return i18n.t(`${CloseDelays[lid.close_delay]?.toLowerCase()}`);
    };

    actions.push(
      ...[
        {
          disabled: !device.status.online,
          label: i18n.t('bowl_setup'),
          value: null,
          loadingKey: 'bowls.type',
          viewOnly: !userHasWriteAccess,
          iconSVG: (
            <BowlSVG
              color={colors.greyText.color}
              width={20}
              height={20}
              viewBox="0 0 16 16"
            />
          ),
          action: () => {
            if (!device.status.online) return;
            navigation.dispatch(
              StackActions.push('DeviceBowlSetup', { id: device.id }),
            );
          },
        },
        control.bowls?.settings?.length && {
          disabled: !device.status.online,
          label: i18n.t('portioning'),
          loadingKey: 'bowls.settings.0.target',
          value: null,
          viewOnly: !userHasWriteAccess,
          iconSVG: (
            <PortionSVG
              color={colors.greyText.color}
              width={20}
              height={20}
              viewBox="0 0 16 16"
            />
          ),
          action: () => {
            if (!device.status.online) return;
            navigation.dispatch(
              StackActions.push('DevicePortioning', { id: device.id }),
            );
          },
        },
        {
          disabled: !device.status.online,
          label: i18n.t('lid_delay'),
          value: controlLidCloseDelay(),
          loadingKey: 'lid.close_delay',
          viewOnly: !userHasWriteAccess,
          action: () => {
            if (!device.status.online) return;
            navigation.dispatch(
              StackActions.push('DeviceLidDelay', { id: device.id }),
            );
          },
        },
      ],
    );
  }

  return actions.filter(Boolean);
};
