import { DeviceType } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import { HouseholdModel } from '@models/Household';
import { PetModel } from '@models/Pet';
import { UserModel } from '@models/User';
import { differenceInDays, differenceInWeeks, getYear, parseISO } from 'date-fns';
import { AnalyticsService } from './AnalyticsService';
import { SpRemoteConfig } from './RemoteConfigService';

export class SendAnalyticsDataService {
  static async trackEvents({ pets }: { pets: PetModel[] }) {
    const currentYear = getYear(Date.now());
    const conditions: number[] = [];

    let catTotal = 0;
    let dogTotal = 0;

    pets.forEach((pet) => {
      if (pet.species_id === 1) {
        catTotal++;
      } else {
        dogTotal++;
      }

      const petPrefix = pet.species_id === 1 ? 'cat' : 'dog';
      // Check pet age for for triggering events.
      if (pet.date_of_birth) {
        const momentBirthday = parseISO(pet.date_of_birth);
        const birthYear = getYear(momentBirthday);

        if (birthYear === currentYear || birthYear === currentYear - 1) {
          // Birthday was this or last year, check ages in weeks.
          const weeksSinceBirth = differenceInWeeks(Date.now(), momentBirthday);

          if (weeksSinceBirth === 4) {
            AnalyticsService.logEvent(`${petPrefix}_four_weeks_old`);
          } else if (weeksSinceBirth === 8) {
            AnalyticsService.logEvent(`${petPrefix}_eight_weeks_old`);
          } else if (weeksSinceBirth === 16) {
            AnalyticsService.logEvent(`${petPrefix}_sixteen_weeks_old`);
          }
          // also check for first birthday - TODO: confirm logic.
          if (weeksSinceBirth === 53) {
            AnalyticsService.logEvent(`${petPrefix}_birthday_${currentYear - birthYear}`);
          }
        } else {
          const updatedBirthday = new Date(pet.date_of_birth).setFullYear(currentYear);
          const daysSinceBirthday = differenceInDays(new Date(updatedBirthday), Date.now());
          if (daysSinceBirthday >= 0 && daysSinceBirthday < 8) {
            // Pet's birthday was in the past week, log event for current animal.
            // eslint-disable-next-line prettier/prettier
            AnalyticsService.logEvent(`${petPrefix}_birthday_${currentYear - birthYear}`);
          }
        }
      }

      pet.conditions?.forEach((condition) => {
        if (!conditions.includes(condition.id)) {
          conditions.push(condition.id);
        }
      });
    });
    await AnalyticsService.setUserProperties({
      conditions: JSON.stringify(conditions),
      catTotal: catTotal.toString(),
      dogTotal: dogTotal.toString(),
    });
  }
  static async setDebugMode(userLastName: string) {
    if (userLastName && userLastName === '*debug*') {
      await AnalyticsService.setUserProperties({
        debug: 'true',
      });
      await SpRemoteConfig().fetch(0);
      await SpRemoteConfig().activate();
      const parameters = SpRemoteConfig().getAll();

      Object.entries(parameters).forEach(($) => {
        const [key, entry] = $;
        AnalyticsService.logEvent(
          `remote_config - ${key} - ${entry.asString()} - ${entry.getSource()}`,
          {
            key,
            value: entry.asString(),
          },
        );
        console.log('Key: ', key);
        console.log('Source: ', entry.getSource());
        console.log('Value: ', entry.asString());
      });
      // console.log('Firebase Debug mode enabled');
    }
  }
  static async trackProperties({
    households,
    activeHousehold,
    user,
    devices,
  }: {
    user: UserModel;
    activeHousehold: HouseholdModel;
    households: HouseholdModel[];
    devices: DeviceModel[];
  }) {
    await AnalyticsService.setUserProperties({
      userCreationDate: new Date(user.created_at).getFullYear().toString(),
    });

    // Collect userRole list
    const permissions: string[] = [];
    households.forEach((household) => {
      const meOwner = household.users.find(
        (item) => item.owner && item.write && item.id === user.id,
      );
      if (meOwner) {
        if (!permissions.includes('owner')) {
          permissions.push('owner');
        }
      } else {
        const meController = household.users.find(
          (item) => !item.owner && item.write && item.id === user.id,
        );
        if (meController) {
          if (!permissions.includes('controller')) {
            permissions.push('controller');
          }
        } else {
          // Is viewer by default
          if (!permissions.includes('viewer')) {
            permissions.push('viewer');
          }
        }
      }
    });

    if (activeHousehold.created_user_id === user.id) {
      // For current household, if owner, report each owned device
      devices
        .filter((device) => device.household_id === activeHousehold.id)
        .forEach((device) => {
          let dev;
          if (device.product_id === 8) {
            dev = 'FelaquaConnect';
          } else {
            dev = DeviceType[device.product_id].toString();
          }

          AnalyticsService.setUserProperties({ [dev]: 'true' });
        });
    }

    await AnalyticsService.setUserProperties({
      userRole: JSON.stringify(permissions),
    });
  }
}
