import { FC } from 'react';
import { SpView } from './SpView';
import { StyleProp, StyleSheet } from 'react-native';
import React from 'react';
import SlideIndicator from '../components/SlideIndicator';

interface ISlidesIndicator {
  items?: [];
  style?: StyleProp<unknown>;
  activeSlideIndex: number;
  isShow?: boolean;
}

const SlidesIndicator: FC<ISlidesIndicator> = ({
  items,
  style = {},
  activeSlideIndex,
  isShow = true,
}) => {
  if (!isShow) return null;
  return (
    <SpView style={[styles.wrapper, style]}>
      {items?.map((item, index: number) => (
        <SlideIndicator
          isActive={activeSlideIndex === index}
          key={index}
        />
      ))}
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'center',
    flexDirection: 'row',
    columnGap: 8,
  },
});

export default SlidesIndicator;
