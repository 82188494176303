import { intervalToDuration } from 'date-fns';

type Tranlsate = (data: string) => string;
const setWordToPlural = (num: number, word: string) => {
  if (num === 1) return word;
  if (num > 1) return `${word}s`;
  return '';
};

const translateDiffWord = (data: string, t: Tranlsate) => {
  switch (data) {
    case 'day':
      return t('day');
    case 'days':
      return t('days');
    case 'month':
      return t('month');
    case 'months':
      return t('months');
    case 'year':
      return t('year');
    case 'years':
      return t('years');
    default:
      return '';
  }
};

export const dateDiffInString = (date: string | number | Date, t: Tranlsate) => {
  const interval = intervalToDuration({
    start: new Date(),
    end: new Date(date),
  });

  let result = '';
  if (interval.years) {
    result += `${interval.years} ${translateDiffWord(setWordToPlural(interval.years, 'year'), t)}`;
  }
  if (interval.months) {
    result += ` ${interval.months} ${translateDiffWord(
      setWordToPlural(interval.months, 'month'),
      t,
    )}`;
  }
  if (interval.days && !interval.years) {
    result += ` ${interval.days} ${translateDiffWord(setWordToPlural(interval.days, 'day'), t)}`;
  }

  return result || t('today');
};
