import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { SvgProps } from 'react-native-svg/src/elements/Svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const InvalidSVG = (props: SvgProps) => (
  <Svg
    viewBox="0 0 16 16"
    height={16}
    width={16}
    {...props}>
    <G
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd">
      <G
        id="warning"
        fill="#ED0055"
        fill-rule="nonzero">
        <Path
          d="M8,0 C3.584,0 0,3.584 0,8 C0,12.416 3.584,16 8,16 C12.416,16 16,12.416 16,8 C16,3.584 12.416,0 8,0 Z M8,12.8 C7.41333333,12.8 6.93333333,12.32 6.93333333,11.7333333 C6.93333333,11.1466667 7.41333333,10.6666667 8,10.6666667 C8.58666667,10.6666667 9.06666667,11.1466667 9.06666667,11.7333333 C9.06666667,12.32 8.58666667,12.8 8,12.8 Z M9.22133333,4.528 L8.864,8.80533333 C8.82666667,9.25333333 8.45333333,9.6 8,9.6 C7.54666667,9.6 7.17333333,9.25333333 7.136,8.80533333 L6.77866667,4.528 C6.72,3.81333333 7.28,3.2 8,3.2 C8.68266667,3.2 9.22666667,3.75466667 9.22666667,4.42666667 C9.22666667,4.45866667 9.22666667,4.496 9.22133333,4.528 L9.22133333,4.528 Z"
          id="Shape"
        />
      </G>
    </G>
  </Svg>
);
export default InvalidSVG;
