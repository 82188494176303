import { getNestedProperty } from '@utils/getNestedProperty';
import { testProperties } from '@utils/testProperties';
import React, { useEffect } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import SpMenuItem from '../SpMenuItem';
import { MenuItem } from '../../models/Menu';
import useBoundStore from '../../store/store';

export interface SpMenuStackProps {
  buttons: MenuItem[];
}

export const SpMenuStack = ({ buttons }: SpMenuStackProps) => {
  const loadingControl = useBoundStore(state => state.deviceStore.loadingControl);

  return (
    <View style={styles.container}>
      {buttons.map((item, index) => {
        const isLoading = item?.loadingKey
          ? getNestedProperty(loadingControl, item.loadingKey)
          : false;
        return (
          <Pressable
            accessible={false}
            style={index !== 0 && styles.accountButtonWrap}
            key={index}
            disabled={isLoading}
            onPress={item.action}
            {...testProperties(item.label, 'button', 'SpMenuStack')}
          >
            <SpMenuItem
              label={item.label}
              action={item.action}
              icon={item.icon ?? null}
              iconSVG={item.iconSVG ?? null}
              isLoading={isLoading}
              disabled={item?.disabled}
            />
          </Pressable>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  accountButtonWrap: {
    marginTop: 16,
  },
});
