import { GroupReportPointModel } from '@models/ReportModel';
import { useMemo } from 'react';

import { TimeService } from '../../../services/TimeService';

export function useCurrentAndPreviousDays<T>(
  activeDate: number,
  data: GroupReportPointModel<T>[],
  viewedDays: number,
): {
  currentDay: GroupReportPointModel<T>;
  rangeData: GroupReportPointModel<T>[];
} {
  return useMemo(() => {
    const currentDay =
      data.find(
        item =>
          TimeService.toLocal(item.date).toISODate() ===
          TimeService.parseDate(activeDate).toISODate(),
      ) || null;
    const rangeData: GroupReportPointModel<T>[] = [];

    new Array(viewedDays).fill(true).forEach((_, i) => {
      const prevDate = TimeService.parseDate(activeDate)
        .minus({ days: i })
        .endOf('day')
        .toISODate();
      const prevDayFromList = data.find(
        item => TimeService.toLocal(item.date).toISODate() === prevDate,
      );

      if (prevDayFromList) {
        rangeData.push(prevDayFromList);
      }
    });

    rangeData.unshift(currentDay);

    return {
      currentDay,
      rangeData,
    };
  }, [activeDate, data, viewedDays]);
}
