import React, { useCallback, useMemo, useState } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import useBoundStore from 'src/store/store';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import CustomBottomSheetModal from 'src/components/CustomBottomSheetModal';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { PetGender, PetSpayedOptions, PetSpecie } from '@constants/Pet';
import { useTranslation } from 'react-i18next';
import { SpEditMenuStack } from 'src/components/SpEditMenuStack';
import { PetBioMenu } from '@constants/Menus';
import { SpProfileImage } from 'src/components/SpProfileImage';
import colors from '@styles/colors';
import { BreedModel } from '@models/Breed';
import { format, parseISO } from 'date-fns';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import noop from '@utils/noop';
import { usePickerAndCameraImage } from '@hooks/usePickerAndCameraImage';
import useGetSpayedOrNeutered from '@hooks/useGetSpayedOrNeutered';
import ActionPicker from 'src/components/ActionPicker';
import { PetStackParamList } from '../..';

type Props = NativeStackScreenProps<PetStackParamList, 'PetProfileSettings'>;

export const PetProfileSettings = ({ route }: Props) => {
  const { t } = useTranslation();
  const { loadingPet, updatePhoto, getPetById, deletePhoto } = useBoundStore(
    state => state.petStore,
  );
  const { breeds } = useBoundStore(s => s.breedStore);
  const activePet = getPetById(route.params.id);
  const navigation = useNavigation<NavigationProp<PetStackParamList>>();
  const [open, setOpenModal] = useState<boolean>(false);
  const emasculate = useGetSpayedOrNeutered();
  const [androidDOBLoading] = useState(false);
  const onOpen = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);
  const onDismiss = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);

  const petWeight = useMemo(
    () => convertWithUnits(Number(activePet.weight), true),
    [userWeightUnits, userCountryCode],
  );

  const snapPoints = useMemo(() => ['40%'], []);

  const { cameraImage, pickerImage } = usePickerAndCameraImage();

  const primaryBreedValue = useMemo(
    () => breeds.find((item: BreedModel) => item.id === activePet.breed_id)?.name,
    [breeds, activePet.breed_id],
  );
  const secondaryBreedValue = useMemo(
    () => breeds.find((item: BreedModel) => item.id === activePet.breed_id2)?.name,
    [breeds, activePet.breed_id2],
  );

  const settingButtons = [
    {
      label: t('pet_name'),
      value: activePet?.name,
      action: () =>
        navigation.navigate('PetEditBio', {
          label: t('pet_name'),
          inputName: PetBioMenu.Name,
          inputValue: activePet?.name,
        }),
    },
    {
      label: t('species'),
      value: t(PetSpecie[activePet?.species_id]),
      action: () =>
        navigation.navigate('PetEditBio', {
          label: t('species'),
          inputName: PetBioMenu.Species,
          inputValue: activePet?.species_id,
        }),
    },
    {
      label: t('gender'),
      value: t(PetGender[activePet?.gender]),
      action: () =>
        navigation.navigate('PetEditBio', {
          label: t('gender'),
          inputName: PetBioMenu.Gender,
          inputValue: activePet?.gender,
        }),
    },
    {
      label: emasculate,
      value: t(PetSpayedOptions[activePet?.spayed]),
      action: () =>
        navigation.navigate('PetEditBio', {
          label: emasculate,
          inputName: PetBioMenu.Spayed,
          inputValue: activePet?.spayed,
        }),
    },
    {
      label: t('date_of_birth'),
      value: activePet?.date_of_birth
        ? format(parseISO(activePet?.date_of_birth), 'dd/MM/yyyy')
        : null,
      action: () => {
        noop();
      },
    },
    {
      label: t('weight'),
      value: petWeight,
      action: () =>
        navigation.navigate('PetEditBio', {
          label: t('weight'),
          inputName: PetBioMenu.Weight,
          inputValue: petWeight,
        }),
    },
    {
      label: t('primary_breed'),
      value: primaryBreedValue,
      disabled: !activePet.species_id,
      action: () =>
        navigation.navigate('PetEditBio', {
          label: t('primary_breed'),
          inputName: PetBioMenu.BreedID,
          inputValue: activePet?.breed_id,
        }),
    },
    {
      label: t('secondary_breed'),
      value: secondaryBreedValue,
      disabled: !activePet.species_id,
      action: () =>
        navigation.navigate('PetEditBio', {
          label: t('secondary_breed'),
          inputName: PetBioMenu.BreedID2,
          inputValue: activePet?.breed_id2,
        }),
    },
    {
      label: t('comments'),
      value: activePet?.comments,
      action: () =>
        navigation.navigate('PetEditBio', {
          label: t('comments'),
          inputName: PetBioMenu.Comments,
          inputValue: activePet?.comments,
        }),
    },
  ];

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.content}>
        <View style={styles.header}>
          <SpProfileImage
            width={92}
            height={92}
            imageUrl={activePet?.photo?.location}
          />
          <View style={styles.buttonWrap}>
            <SpRoundedHeaderButton
              backgroundColor={colors.primary.color}
              h={40}
              title="Edit Photo"
              onPress={onOpen}
              textStyles={styles.buttonText}
              borderRadius={10}
              paddingHorizontal={0}
              isLoading={androidDOBLoading ? false : loadingPet}
              disabled={androidDOBLoading ? false : loadingPet}
            />
          </View>
        </View>

        <SpEditMenuStack buttons={settingButtons} />
      </View>

      <CustomBottomSheetModal
        opened={open}
        backdropComponent={BottomSheetBackdrop}
        index={0}
        snapPoints={snapPoints}
        onDismiss={onDismiss}
        inScrollView
        scrollViewStyle={styles.bottomSheetModalContent}>
        <ActionPicker
          buttons={[
            {
              label: t('take_photo'),
              action: async () => {
                const img = await cameraImage();
                onDismiss();
                if (img) await updatePhoto(img);
              },
            },
            {
              label: t('upload_photo'),
              action: async () => {
                const img = await pickerImage();
                onDismiss();
                if (img) await updatePhoto(img);
              },
            },
            {
              label: t('delete_photo'),
              action: async () => {
                onDismiss();
                await deletePhoto();
              },
            },
          ]}
        />
      </CustomBottomSheetModal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  content: {
    flex: 1,
    paddingHorizontal: 19,
    backgroundColor: colors.white.color,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 47,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#ffffff',
    letterSpacing: 0,
    fontFamily: 'Rubik_Medium',
  },
  buttonWrap: {
    marginLeft: 24,
    width: 117,
  },
  bottomSheetModalContent: {
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  androidDateLoader: {
    zIndex: 3,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
