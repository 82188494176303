import { useCustomToast } from '@hooks/useToast';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import colors from '@styles/colors';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import useBoundStore from '../../../store/store';
import { PetProfileMedicalSelectConditions } from '../Pets/pages/PetProfile/components/PetProfileMedicalSelectConditions';
import { PetStackParamList } from '../Pets/pages';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { isEqual } from 'lodash-es';

export const PetProfileMedicalSelectConditionsWrapper = ({
  route,
}: StackScreenProps<PetStackParamList, 'PetProfileMedicalSelectConditions'>) => {
  const { t } = useTranslation();
  const conditions = useBoundStore(state => state.conditionStore.data);
  const { updatePet, loadingPet, getPetById } = useBoundStore(state => state.petStore);
  const [isLoading, setIsLoading] = useState(false);
  const pet = getPetById(route.params.id);
  const toast = useCustomToast();
  const navigation = useNavigation<NavigationProp<PetStackParamList>>();
  const [selected, setSelected] = useState([]);

  const onUpdateConditions = useCallback(
    async (selectedConditions: number[]) => {
      setIsLoading(true);
      const newConditions = conditions.filter(item => selectedConditions.includes(item.id));

      try {
        await updatePet(pet.id, { ...pet, conditions: newConditions });
        toast.show({
          description: t('successfully_updated {{label}}', {
            label: t('conditions'),
          }),
        });
        navigation.navigate('PetProfileMedical', {
          id: pet.id,
        });
      } catch (err) {
        toast.show({
          isError: true,
          description: t('error_updating {{label}}', {
            label: t('conditions'),
          }),
        });
      }
      setIsLoading(false);
    },
    [pet, toast, conditions],
  );
  const noConditionsOption = conditions.shift();
  conditions.sort((a, b) => a.name.localeCompare(b.name));
  conditions.unshift(noConditionsOption);
  return (
    <SafeAreaView style={styles.container}>
      <PetProfileMedicalSelectConditions
        conditions={conditions}
        setSelected={setSelected}
        selected={selected}
        petConditions={pet.conditions}
        loading={loadingPet}
        onChange={onUpdateConditions}
        extraButtonMargin={20}
      />
      <View style={[styles.buttonWrapper, styles.shadowWrapper]}>
        <SpRoundedHeaderButton
          disabled={
            isLoading ||
            isEqual(
              pet.conditions.map(cond => cond?.id).sort((a, b) => a - b),
              [...selected].sort((a, b) => a - b),
            )
          }
          backgroundColor={colors.primary.color}
          isLoading={isLoading}
          h={56}
          title={t('save')}
          onPress={() => {
            onUpdateConditions(selected);
          }}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  button: {
    padding: 20,
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: 68,
    left: 19,
    right: 18,
  },
  shadowWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8,
  },
});
