import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const IncompatibleExclamationSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    {...props}>
    <Path
      fill="#ED0055"
      fillRule="evenodd"
      d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 19.2C11.12 19.2 10.4 18.48 10.4 17.6C10.4 16.72 11.12 16 12 16C12.88 16 13.6 16.72 13.6 17.6C13.6 18.48 12.88 19.2 12 19.2ZM13.296 13.208L13.832 6.792C13.84 6.744 13.84 6.688 13.84 6.64C13.84 5.632 13.024 4.8 12 4.8C10.92 4.8 10.08 5.72 10.168 6.792L10.704 13.208C10.76 13.88 11.32 14.4 12 14.4C12.68 14.4 13.24 13.88 13.296 13.208Z"
    />
  </Svg>
);
export default IncompatibleExclamationSVG;
