import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const OutdoorsSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}>
    <G
      fill="#263A43"
      fillRule="nonzero">
      <Path d="M9.71 6.818c.129-.243.634-.734.634-1.662 0-1.89-1.765-2.246-1.86-2.313l-.028-.3A2.8 2.8 0 0 0 5.656 0a2.812 2.812 0 0 0-2.792 2.545l-.03.297C2.74 2.908.937 3.258.937 5.156c0 .927.506 1.42.634 1.662C1.48 6.912 0 7.666 0 9.406c0 1.551 1.293 2.781 2.844 2.781h5.625a2.816 2.816 0 0 0 2.812-2.812c0-1.75-1.463-2.445-1.57-2.557Z" />
      <Path d="M14.43 6.818c.128-.243.633-.734.633-1.662 0-1.89-1.797-2.246-1.89-2.313a896.21 896.21 0 0 1-.03-.3A2.8 2.8 0 0 0 10.344 0C9.712 0 9.132.22 8.66.579c.344.464.594 1.005.695 1.607 1.666.792 1.926 2.173 1.926 2.97 0 .602-.156 1.078-.33 1.434.85.77 1.268 1.697 1.268 2.785a3.726 3.726 0 0 1-1.296 2.813h2.233c1.551 0 2.844-1.262 2.844-2.813 0-1.75-1.464-2.445-1.57-2.557ZM12.219 15.063v-1.938h-1.875v1.938h-3.75v-1.938H4.719v1.938H2.844V16h11.25v-.938z" />
    </G>
  </Svg>
);
export default OutdoorsSVG;
