import { DeviceModel } from '@models/Device';
import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../../../index';
import { useCallback } from 'react';
import { DeviceType } from '@constants/Device';

type HandleSelectedDeviceProps = {
  existingDevice: boolean;
  handleStep: (step: number) => void;
  setSelectedDevice: (device: DeviceModel) => void;
  currentStep: number;
};
export const useHandleSelectedDevice = ({
  existingDevice,
  setSelectedDevice,
  handleStep,
  currentStep,
}: HandleSelectedDeviceProps) => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const cerberusAction = useCallback(
    (device: DeviceModel) => () => {
      const params = existingDevice
        ? {
            device_id: device?.id,
            nextPage: {
              stack: 'HouseholdNavigation',
              screen: 'Household',
            },
          }
        : { device_id: device?.id };

      navigation.dispatch(StackActions.replace('CreatePetProfile', params));
    },
    [existingDevice, navigation],
  );

  const generalAction = useCallback(
    (device: DeviceModel) => () => {
      setSelectedDevice(device);
      handleStep(currentStep + 1);
      },
    [currentStep, handleStep, setSelectedDevice],
  );

  const handleSelectedDevice = useCallback(
    (device: DeviceModel) => {
      if (device?.product_id === DeviceType.Cerberus && device?.tags.length === 0) {
        return cerberusAction(device);
      } else if (device?.product_id !== DeviceType.Cerberus) {
        return generalAction(device);
      }
      return () => {
        console.log(
          `[DEBUG] [AddPets] [useHandleSelectedDevice] [handleSelectedDevice] - No action for device ${DeviceType[device?.product_id]}`,
        );
      };
    },
    [cerberusAction, generalAction],
  );
  return { handleSelectedDevice };
};

export default useHandleSelectedDevice;
