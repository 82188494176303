import { Image, Platform, StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SpInput } from 'src/components/SpInput';
import { SpView } from 'src/components/SpView';
import { SpText } from '../../../components/SpText/SpText';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../components/LoadingComponent';
import SpErrorBadge from '../../../components/SpErrorBadge';
import { SpRoundedHeaderButton } from '../../../components/SpRoundedHeaderButton';
import { CameraView } from 'expo-camera/next';
import { AppImages } from '@constants/AppImages';
import { Camera } from 'expo-camera';
import { Defs, Mask, Rect, Svg } from 'react-native-svg';

interface WiFiPasswordInputProps {
  SelectedWiFiSSID: string;
  setTargetNetworkPassword: (text: string) => void;
  targetNetworkPassword: string;
  loading: boolean;
  error: string;
}

export const WiFiPasswordInput = ({
  SelectedWiFiSSID,
  setTargetNetworkPassword,
  targetNetworkPassword,
  loading,
  error,
}: WiFiPasswordInputProps) => {
  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [showScan, setShowScan] = useState(false);

  const handleShowScanner = useCallback((scannerStatus: boolean) => {
    setShowScan(scannerStatus);
    setScanned(false);
  }, []);

  const handleBarCodeScanned = useCallback(
    ({ type, data }) => {
      setScanned(true);
      if (!data) return;
      console.log(`Bar code with type ${type} and data ${data} has been scanned!`);
      const qrCodePassword =
        data.match(/(?<=WIFI:\S*)(?<=P:)[^;]+/g)?.[0] ?? data.split(/\s+/g).pop();
      setTargetNetworkPassword(qrCodePassword);
      setShowScan(false);
      // alert(`Bar code with type ${type} and data ${data} has been scanned!`);
    },
    [setTargetNetworkPassword],
  );

  useEffect(() => {
    if (!showScan) return;
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, [showScan]);

  const PasswordUI = useMemo(() => {
    return (
      <>
        <SpText style={[styles.titlePassword, styles.center]}>
          {t('network_password_required', { ssid: SelectedWiFiSSID })}
        </SpText>
        {error && <SpErrorBadge text={t('password_is_incorrect')} />}
        <SpView>
          <SpInput
            label={t('password')}
            onChangeText={setTargetNetworkPassword}
            value={targetNetworkPassword}
            password
          />
        </SpView>
        <SpView style={[styles.qrCode]}>
          <Image
            style={[styles.qrImage]}
            source={AppImages.qrIcon}
            resizeMode="contain"
          />
          <SpRoundedHeaderButton
            title={t('scan_qr_code')}
            stylesForContent={styles.button}
            textStyles={styles.btnText}
            onPress={() => handleShowScanner(true)}
          />
        </SpView>
        <SpView style={styles.marginFive}>
          {loading && (
            <LoadingComponent
              searchMsg=""
              connectedMsg=""
              isConnected={false}
            />
          )}
        </SpView>
      </>
    );
  }, [
    SelectedWiFiSSID,
    error,
    handleShowScanner,
    loading,
    setTargetNetworkPassword,
    t,
    targetNetworkPassword,
  ]);
  const QRScanUI = useMemo(() => {
    return (
      <>
        <SpView style={styles.qrCodeScanner}>
          <SpText style={[styles.titlePassword, styles.center]}>
            {t('cdb_use_camera_to_scan_qr')}
          </SpText>
          <SpView style={styles.qrWrapper}>
            <CameraView
              onBarcodeScanned={scanned ? undefined : handleBarCodeScanned}
              barcodeScannerSettings={{
                barcodeTypes: ['qr', 'pdf417', 'code128', 'code39'],
              }}
              style={styles.camera}
            />
            <SpView style={{ width: 280, height: 280, borderRadius: 16 }}>
              <Svg
                width="280"
                height="280"
                viewBox="0 0 280 280"
              >
                <Defs>
                  <Mask
                    id="mask"
                    x="0"
                    y="0"
                    width="280"
                    height="280"
                  >
                    <Rect
                      x="0"
                      y="0"
                      width="280"
                      height="280"
                      fill="white"
                    />
                    <Rect
                      x="50"
                      y="50"
                      width="165"
                      height="165"
                      fill="black"
                    />
                  </Mask>
                </Defs>
                <Rect
                  x="0"
                  y="0"
                  width="280"
                  height="280"
                  fill={'#263A4338'}
                  opacity={0.5}
                  mask="url(#mask)"
                />
              </Svg>
            </SpView>
          </SpView>
          <SpView style={styles.marginFive}>
            <SpRoundedHeaderButton
              title={t('type_password_instead')}
              stylesForContent={styles.button}
              textStyles={styles.btnText}
              onPress={() => handleShowScanner(false)}
            />
          </SpView>
        </SpView>
      </>
    );
  }, [handleBarCodeScanned, handleShowScanner, scanned, t]);

  return (
    <SpView style={[styles.wrapper, Platform.OS === 'android' ? styles.androidPaddingBottom : {}]}>
      <SpView style={styles.wrapper}>{showScan && hasPermission ? QRScanUI : PasswordUI}</SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  qrWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: 280,
    height: 280,
    borderRadius: 16,
  },
  qrImage: {
    width: 36,
    height: 36,
  },
  wrapper: {
    flex: 1,
    marginBottom: 100,
  },
  titlePassword: {
    marginTop: '2%',
    marginBottom: '10%',
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.black.color,
    alignItems: 'center',
    textAlign: 'center',
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
  },
  qrCode: {
    marginTop: '20%',
    alignContent: 'center',
    alignItems: 'center',
    height: 92,
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: colors.primary.color,
    height: 40,
  },
  btnText: {
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
    color: colors.white.color,
  },
  marginFive: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  qrCodeScanner: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  androidPaddingBottom: {
    paddingBottom: 100,
  },
  camera: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 16,
  },
});

export default WiFiPasswordInput;
