import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

const CheckGreenSVG = ({ width = 32, height = 32, ...props }) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <G>
        <Circle
          cx="16"
          cy="16"
          r="16"
          fill="#2DB58E"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1203 8.6556C25.8628 9.27433 25.9631 10.3778 25.3444 11.1203L15.3444 23.1203C15.0296 23.498 14.5705 23.7259 14.0793 23.7482C13.5881 23.7705 13.1102 23.5851 12.7626 23.2374L6.76256 17.2374C6.07915 16.554 6.07915 15.446 6.76256 14.7625C7.44598 14.0791 8.55402 14.0791 9.23744 14.7625L13.8824 19.4075L22.6556 8.87966C23.2743 8.13718 24.3778 8.03686 25.1203 8.6556Z"
          fill="white"
        />
      </G>
    </Svg>
  );
};

export default CheckGreenSVG;
