import { DeviceType } from '@constants/Device';
import { DeviceImages } from '@constants/DeviceImages';
import { Image } from 'react-native';
import React, { useMemo } from 'react';
import { testProperties } from '@utils/testProperties';

interface SpProductCardImageProps {
  data: DeviceType;
  height?: number;
  width?: number;
}

export const SpProductCardImage = ({ data, height = 26, width = 26 }: SpProductCardImageProps) => {
  const source = useMemo(() => {
    return DeviceImages[data] || null;
  }, [data]);

  return (
    <Image
      style={{ height, width }}
      source={source}
      resizeMode="contain"
      {...testProperties(DeviceType[data], 'image')}
    />
  );
};
