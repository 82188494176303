import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const ChevronRightSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    {...props}>
    <Path
      d="M7.41494 19.6802L15.9447 10.7718C16.1418 10.566 16.25 10.2915 16.25 9.99248C16.25 9.69947 16.1418 9.42484 15.9447 9.21931L7.42304 0.319186C7.22577 0.113333 6.96281 0 6.68225 0C6.4017 0 6.13858 0.11317 5.94147 0.319186L5.31388 0.97463C5.11677 1.18048 5.00794 1.45528 5.00794 1.74829C5.00794 2.04129 5.11677 2.31593 5.31388 2.52194L12.47 9.99557L5.30562 17.4775C5.10867 17.6833 5 17.958 5 18.2511C5 18.5442 5.10867 18.8186 5.30562 19.0246L5.93353 19.6802C6.34191 20.1066 7.00656 20.1066 7.41494 19.6802Z"
      fill="#263A43"
    />
  </Svg>
);
export default ChevronRightSVG;
