import { SpLoading } from 'src/components/SpLoading';
import { useDeviceById } from '@hooks/useDeviceById';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { usePetsWithTag } from '@hooks/usePetsWithTag';
import { useCustomToast } from '@hooks/useToast';
import { StackScreenProps } from '@react-navigation/stack';
import i18n from '@utils/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { SpView } from 'src/components/SpView';
import colors from '@styles/colors';
import { t } from 'i18next';
import { PetModel, PetWithTagModel } from '@models/Pet';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { MergedInterfaces } from '../../../../../../store/models';
import useBoundStore from '../../../../../../store/store';
import { DeviceStackParamList } from '../../index';
import AssignPet from '../../../../../Flows/Generic/AssignPet';
import { FlowStackParamList } from '../../../../../Flows';
import { RootStackParamList } from '../../../../../index';
import { DeviceType } from '@constants/Device';

interface PetWithTagModelAssigned extends PetWithTagModel {
  isAssigned: boolean;
}

export const DeviceAssignPet = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceAssignPet'>) => {
  const [incompatiblePets, setIncompatiblePets] = useState([]);
  const toast = useCustomToast();
  const { id } = route.params;
  const device = useDeviceById(id);
  const pets = usePetsByHousehold();
  const petsWithTag = usePetsWithTag(device, pets);
  const deviceNavigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const flowNavigation = useNavigation<NavigationProp<FlowStackParamList>>();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();

  const {
    loadingDevice,
    updateProperty,
    unassignAssignMultiplePetsRequest,
  } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
      assignUnassignPetError: deviceStore.assignUnassignPetError,
      assignPetSuccess: deviceStore.assignPetSuccess,
      unassignPetSuccess: deviceStore.unassignPetSuccess,
      updateProperty: deviceStore.updateProperty,
      unassignAssignMultiplePetsRequest: deviceStore.unassignAssignMultiplePetsRequest,
    };
  });

  const redirectToAddPet = useCallback((pet: PetModel) => {
    rootNavigation.navigate('FlowNavigation', {
      screen: 'AddPets',
      params: {
        deviceType: device.product_id,
        step: 7,
        petIncompatibleAssociated: pet?.id,
        deviceId: device.id,
        existingDevice: true,
        comingFromSettings: true,
        comingFromIncompatibleAssignPage: false,
      },
    });
  }, []);

  // const confirmUnassignPetDialog = useCallback(
  //   (deviceId: number, tagId: number) => {
  //     SpAlert(
  //       i18n.t('confirm_unassign_pet_to_device_title'),
  //       i18n.t('confirm_unassign_pet_to_device_description'),
  //       [
  //         {
  //           text: i18n.t('confirm'),
  //           onPress: () => {
  //             unassignPetRequest(deviceId, tagId);
  //             return true;
  //           },
  //         },
  //         { text: i18n.t('cancel'), onPress: () => true },
  //       ],
  //     );
  //   },
  //   [device, unassignPetRequest],
  // );
  const changeAction = (updateData: PetWithTagModelAssigned[]) => {
    deviceNavigation.navigate('DeviceSetting', { id: device.id });
    const asyncAction = async () => {
      const differences = updateData.filter(({ pet, isAssigned }, ind) => {
        return pet.id === petsWithTag[ind].pet.id && isAssigned !== petsWithTag[ind].isAssigned;
      });
      const result = await unassignAssignMultiplePetsRequest(
        device.id,
        differences.map(elem => ({
          assign: elem.isAssigned,
          tagId: elem.pet.tag_id,
          pet: elem.pet,
        })),
      );
      if (result) {
        toast.show({
          description: t('pets_saved'),
        });
      } else {
        toast.show({
          description: i18n.t('sorry_try_again'),
          isError: true,
        });
      }
    };

    asyncAction();
  };

  const clearStoreProperties = useCallback(() => {
    updateProperty({
      assignPetSuccess: undefined,
      unassignPetSuccess: undefined,
      assignUnassignPetError: undefined,
      loadingDevice: false,
    });
  }, [updateProperty]);

  useEffect(() => {
    return () => clearStoreProperties();
  }, []);

  if (loadingDevice) {
    return <SpLoading />;
  }

  return (
    <SpView
      paddingHorizontal={20}
      flex={1}
      backgroundColor={colors.white.color}>
      <AssignPet
        data={petsWithTag}
        title={t('assign_title')}
        subtitle={(device?.product_id === DeviceType.Cerberus) ? t('assign_pet_subtitle_cdb') : t('assign_subtitle')}
        shouldPassData
        isAlternativeStyle
        setIncompatiblePets={setIncompatiblePets}
        onClickOnIncompatiblePet={redirectToAddPet}
        incompatiblePets={incompatiblePets}
        comingFromSettings
        saveButtonAction={changeAction}
        buttonText={t('save')}
        device={device}
      />
    </SpView>
  );
};
