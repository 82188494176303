import { SpView } from 'src/components/SpView';
import React, { useEffect, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useIsFocused } from '@react-navigation/native';
import useTour from '@hooks/useTour';
import useBoundStore from '../../../store/store';
import { navigationRef } from '../../RootNavigation';
import PetDataSlide from '../../Tour/components/PetDataSlide/PetDataSlide';
import PetHomeSlide from '../../Tour/components/PetHomeSlide/PetHomeSlide';
import QuickActionsSlide from '../../Tour/components/QuickActionsSlide/QuickActionsSlide';
import { DateHeader } from '../components/DateHeader';
import { PetList } from '../components/PetList';

export const Pets = () => {
  const userHasWriteAccess = useBoundStore((s) => s.householdStore.userHasWriteAccess());
  const isFocused = useIsFocused();
  const components = useMemo(() => {
    const data = [
      <PetHomeSlide />,
      userHasWriteAccess && <QuickActionsSlide />,
      <PetDataSlide />,
    ].filter((elem) => !!elem);
    return data;
  }, [userHasWriteAccess]);
  const initTour = useTour({
    uniqueId: 'v0-pets-dashboard',
    components,
    devices: ['all'],
  });
  useEffect(() => {
    if (
      (navigationRef.getCurrentRoute()?.name === 'DashboardNavigation' &&
        navigationRef.getCurrentRoute()?.params?.screen === 'Pets') ||
      navigationRef.getCurrentRoute()?.name === 'Pets' ||
      isFocused
    ) {
      initTour();
    }
  }, [isFocused, navigationRef]);


  // if (!activeHousehold && loadingHouseholds) return null;
  return (
    <SpView alignItems="center" width="100%" height="100%">
      <LinearGradient
        colors={['rgba(3, 166, 181, 0.3)', 'rgba(0, 189, 136, 0.3)', 'rgba(45, 181, 142, 0.3)']}
        style={styles.linearGradient}>
        <View style={styles.events}>
          <DateHeader />
        </View>
        <PetList />
      </LinearGradient>
    </SpView>
  );
};

const styles = StyleSheet.create({
  linearGradient: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  events: {
    width: '100%',
    height: 45,

    // maxHeight: '10%',
  },
});
