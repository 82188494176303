import React, { useMemo } from 'react';
import { ConsumptionAlertOutcome, ConsumptionInsightOutcome } from '@models/ConsumptionInsight';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import InfoRedSVG from '../../../../../components/SvgIcons/InfoRedSVG';
import InfoYellowSVG from '../../../../../components/SvgIcons/InfoYellowSVG';
import CheckGreenSVG from '../../../../../components/SvgIcons/CheckGreenSVG';
import { useTranslation } from 'react-i18next';
import { TimeService } from '../../../../../services/TimeService';
import WCIAlertUp from 'src/components/SvgIcons/WCIAlertUp';
import WCIAlertDown from 'src/components/SvgIcons/WCIAlertDown';
import WCIAlertHigh from 'src/components/SvgIcons/WCIAlertHigh';

interface ConsumptionHabitConfigResult {
  title: string;
  wrapper: StyleProp<ViewStyle>;
  iconBg: StyleProp<ViewStyle>;
  icon: JSX.Element;
}

export const useConsumptionInsightConfig = (
  outcome: ConsumptionInsightOutcome,
  isAlert = false,
  days = 0,
  date?: string,
  fromDetailsAlert = false,
): ConsumptionHabitConfigResult => {
  const { t } = useTranslation();
  const ciOption = days > 1 ? `${days} ${t('days')}` : `${days} ${t('day')}`;

  const isToday = useMemo(() => {
    return TimeService.toLocal().startOf('day').equals(TimeService.toLocal(date).startOf('day'));
  }, [date]);

  const config = useMemo(() => {
    const habitConfig: Record<ConsumptionInsightOutcome, ConsumptionHabitConfigResult> = {
      [ConsumptionInsightOutcome.Unavailable]: {
        title: t('ci_drinking_insights_status_not_available'),
        wrapper: styles.greyWrapper,
        iconBg: null,
        icon: (
          <InfoRedSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionInsightOutcome.WillBeAvailable]: {
        title: t('ci_drinking_insights_status_will_be_available_in', { days: ciOption }),
        wrapper: styles.greyWrapper,
        iconBg: null,
        icon: (
          <InfoRedSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionInsightOutcome.Ok]: {
        title: isToday
          ? t('ci_drinking_insights_status_is_normal')
          : t('ci_drinking_insights_status_is_normal_past'),
        wrapper: styles.greenWrapper,
        iconBg: styles.greenBg,
        icon: (
          <CheckGreenSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionInsightOutcome.Decreased]: {
        title: fromDetailsAlert
          ? t('ci_drinking_insights_status_decreased_alert')
          : isToday
            ? t('ci_drinking_insights_status_has_decreased')
            : t('ci_drinking_insights_status_has_decreased_past'),
        wrapper: styles.yellowWrapper,
        iconBg: styles.yellowBg,
        icon: fromDetailsAlert ? (
          <WCIAlertDown />
        ) : (
          <InfoYellowSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionInsightOutcome.WasDecreased]: {
        title: isToday
          ? t('ci_drinking_insights_status_has_decreased_normal')
          : t('ci_drinking_insights_status_has_decreased_normal_past'),
        wrapper: styles.greenWrapper,
        iconBg: styles.greenBg,
        icon: (
          <CheckGreenSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionInsightOutcome.Elevated]: {
        title: fromDetailsAlert
          ? t('ci_drinking_insights_status_increased_alert')
          : isToday
            ? t('ci_drinking_insights_status_increased')
            : t('ci_drinking_insights_status_increased_past'),
        wrapper: styles.yellowWrapper,
        iconBg: styles.yellowBg,
        icon: fromDetailsAlert ? (
          <WCIAlertUp />
        ) : (
          <InfoYellowSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionInsightOutcome.WasElevated]: {
        title: t('ci_drinking_insights_status_was_elevated'),
        wrapper: styles.greenWrapper,
        iconBg: styles.greenBg,
        icon: (
          <CheckGreenSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionInsightOutcome.WasFluctuating]: {
        title: fromDetailsAlert
          ? t('ci_habit_popup_was_fluctuating_alert')
          : isToday
            ? t('ci_drinking_insights_status_fluctuated')
            : t('ci_drinking_insights_status_fluctuated_past'),
        wrapper: styles.greenWrapper,
        iconBg: styles.greenBg,
        icon: (
          <CheckGreenSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
    };

    const alertConfig: Record<ConsumptionAlertOutcome, ConsumptionHabitConfigResult> = {
      [ConsumptionAlertOutcome.Unavailable]: {
        title: 'TBD: not used anywhere atm',
        wrapper: styles.redWrapper,
        iconBg: styles.redBg,
        icon: (
          <InfoRedSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionAlertOutcome.Problematic]: {
        title: fromDetailsAlert
          ? t('ci_drinking_insights_status_high_alert')
          : isToday
            ? t('ci_drinking_insights_status_very_elevated')
            : t('ci_drinking_insights_status_very_elevated_past'),
        wrapper: styles.redWrapper,
        iconBg: styles.redBg,
        icon: fromDetailsAlert ? (
          <WCIAlertHigh />
        ) : (
          <InfoRedSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionAlertOutcome.StillProblematic]: {
        title: fromDetailsAlert
          ? t('ci_drinking_insights_status_high_alert')
          : isToday
            ? t('ci_drinking_insights_status_continues_elevated')
            : t('ci_drinking_insights_status_continues_elevated_past'),
        wrapper: styles.redWrapper,
        iconBg: styles.redBg,
        icon: fromDetailsAlert ? (
          <WCIAlertHigh />
        ) : (
          <InfoRedSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
      [ConsumptionAlertOutcome.Ok]: {
        title: 'TBD: Ok not used anywhere atm',
        wrapper: styles.redWrapper,
        iconBg: styles.redBg,
        icon: (
          <InfoRedSVG
            width={32}
            height={32}
            viewBox="0 0 32 32"
            color="#dedede"
          />
        ),
      },
    };

    return isAlert ? alertConfig : habitConfig;
  }, [t, ciOption, isToday, isAlert]);

  return useMemo(() => {
    return config[outcome] || null;
  }, [config, outcome]);
};

const styles = StyleSheet.create({
  redWrapper: {
    backgroundColor: 'rgba(237, 0, 85, 0.2)',
    borderWidth: 1,
    borderColor: 'rgba(237, 0, 85, .5)',
  },
  yellowWrapper: {
    backgroundColor: '#f3e0d5',
    borderWidth: 1,
    borderColor: '#f3e0d5',
  },
  greenWrapper: {
    backgroundColor: 'rgba(45, 181, 142, 0.2)',
    borderWidth: 1,
    borderColor: 'rgba(45, 181, 142, 0.2)',
  },
  greyWrapper: {
    backgroundColor: '#DEE2E3',
    borderWidth: 1,
    borderColor: '#DEE2E3',
  },
  greenBg: {
    backgroundColor: 'rgba(45, 181, 142, 0.4)',
  },
  redBg: {
    backgroundColor: 'rgba(237, 0, 85, 0.4)',
  },
  yellowBg: {
    backgroundColor: '#fac097',
  },
});
