import React, { useMemo } from 'react';
import { G, Rect } from 'react-native-svg';
import CIExclamationSVG from '../../../../../../components/SvgIcons/CIExclamationSVG';
import CIDecreasedSVG from '../../../../../../components/SvgIcons/CIDecreasedSVG';
import { ConsumptionInsightByDay, ConsumptionInsightOutcome } from '@models/ConsumptionInsight';
import CIIncreasedSVG from '../../../../../../components/SvgIcons/CIIncreasedSVG';
import { ReportRangeType } from '../../../../constants/ReportRangeType';

interface Props {
  index: number;
  barWidth: number;
  barGap: number;
  chartYPos: number;
  insights: ConsumptionInsightByDay;
  rangeType: ReportRangeType;
  onBarPress: () => void;
}

const WCIIcons = ({
  index,
  barGap,
  barWidth,
  chartYPos,
  insights,
  rangeType,
  onBarPress,
}: Props) => {
  const exclamationOnly = useMemo(() => {
    return rangeType !== ReportRangeType.SevenDays;
  }, [rangeType]);

  const isExclamation = useMemo(() => {
    return [
      ConsumptionInsightOutcome.Decreased,
      ConsumptionInsightOutcome.WasDecreased,
      ConsumptionInsightOutcome.Elevated,
      ConsumptionInsightOutcome.WasElevated,
      ConsumptionInsightOutcome.WasFluctuating,
    ].includes(insights?.habit?.outcome);
  }, [insights]);

  const isDecreased = useMemo(() => {
    return (
      [ConsumptionInsightOutcome.Decreased].includes(insights?.habit?.outcome) && !exclamationOnly
    );
  }, [insights?.habit?.outcome, exclamationOnly]);

  const isIncreased = useMemo(() => {
    return (
      [ConsumptionInsightOutcome.Elevated].includes(insights?.habit?.outcome) && !exclamationOnly
    );
  }, [insights?.habit?.outcome, exclamationOnly]);

  const exclamationGap = useMemo(() => {
    return (isDecreased || isIncreased) && !exclamationOnly ? 12 : 3;
  }, [isDecreased, isIncreased, exclamationOnly]);

  const arrowGap = useMemo(() => {
    return exclamationGap ? 12 : 3;
  }, [exclamationGap]);

  if (!isExclamation && !isDecreased && !isIncreased) {
    return null;
  }

  return (
    <>
      <Rect
        x={index * (barWidth + barGap)}
        y={chartYPos - 40}
        width={barWidth + 5}
        height={40}
        fill="transparent"
        onPress={onBarPress}
      />
      {isExclamation && (
        <G
          transform={`translate(${index * (barWidth + barGap) + barWidth / 2 - exclamationGap}, ${chartYPos - 29})`}
        >
          <CIExclamationSVG />
        </G>
      )}
      {isDecreased && (
        <G
          transform={`translate(${index * (barWidth + barGap) + barWidth / 2 - 12 + arrowGap}, ${chartYPos - 27})`}
        >
          <CIDecreasedSVG />
        </G>
      )}
      {isIncreased && (
        <G
          transform={`translate(${index * (barWidth + barGap) + barWidth / 2 - 12 + arrowGap}, ${chartYPos - 27})`}
        >
          <CIIncreasedSVG />
        </G>
      )}
    </>
  );
};

export default WCIIcons;
