import React, { memo } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import LoadingComponent from '../../../../components/LoadingComponent';
import { SpView } from '../../../../../../components/SpView';
import { useTranslation } from 'react-i18next';
const windowHeight = Dimensions.get('window').height;

const SearchTimeout = () => {
  const { t } = useTranslation();
  return (
    <SpView style={styles.loaderContainer}>
      <LoadingComponent
        isConnected
        connectedMsg={t('timeout_error')}
        error
      />
    </SpView>
  );
};

export default memo(SearchTimeout);

const styles = StyleSheet.create({
  loaderContainer: {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: windowHeight < 810 ? 95 : 70,
  },
});
