import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

export const RemainingFood = () => {
  const { t } = useTranslation();

  return (
    <SpView style={styles.label}>
      <View {...testProperties('faUtensils', 'icon')}>
        <FontAwesomeIcon
          icon={faUtensils}
          color={colors.greyText.color}
        />
      </View>
      <SpView marginTop={2}>
        <SpText style={styles.remainingFood}>{t('food_remaining')}</SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  label: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  remainingFood: {
    marginLeft: 5,
  },
});
