import { EditMenuItem } from '@models/Menu';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useMemo } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { openModal } from '../../services/ModalBox';
import useBoundStore from '../../store/store';
import { SpView } from '../SpView';
import SpEditMenuItemValue from '../SpEditMenuItemValue';
import LoadingSpinner from '../Loader/Loader';
import { SpSelectArrow } from '../SpSelectArrow';
import { SpMenuIcon } from '../RoundIcons/SpMenuIcon';
import { SpDivider } from '../SpDivider';
import { useTranslation } from 'react-i18next';

const SpEditMenuItem = ({
  label,
  value,
  actionIcon,
  icon,
  iconSVG,
  action,
  disabled,
  viewOnly = false,
  stackType = '',
  isLoading,
}: EditMenuItem) => {
  const { t } = useTranslation();
  const loadingControl = useBoundStore(state => state.deviceStore.loadingControl);
  const onAction  = () => {
    if (isLoading) {
      if (label === t('assign_pets')) {
        openModal('PetsPreview');
      }
    } else if (!viewOnly) {
      action?.();
    }
  };
  const valueRenderItem = useMemo(() => {
    if (value) {
      return typeof value === 'string' ? (
        <SpView
          style={[
            styles.value,
            {
              opacity: isLoading ? 0.3 : 1,
            },
          ]}>
          <SpEditMenuItemValue
            ellipsizeMode={stackType === 'createPetDetails' ? 'tail' : undefined}
            numberOfLines={stackType === 'createPetDetails' ? 1 : undefined}
            value={value}
          />
        </SpView>
      ) : (
        <SpView
          style={{
            opacity: isLoading ? 0.3 : 1,
          }}>
          {value}
        </SpView>
      );
    }

    return null;
  }, [value, loadingControl]);

  const actionIconRender = useMemo(() => {
    if (viewOnly) {
      return null;
    }

    return isLoading ? (
      <LoadingSpinner size="large" />
    ) : (
      <SpSelectArrow
        componentName={label}
        color={colors.greyText.color}
      />
    );
  }, [actionIcon, viewOnly, loadingControl]);

  return (
    <Pressable
      accessible={false}
      {...testProperties(label, 'button', 'SpEditMenuItem')}
      onPress={onAction}>
      <View style={[styles.inner, disabled && { opacity: 0.5 }]}>
        {icon ? (
          <SpView marginRight={10}>
            <SpMenuIcon
              icon={icon}
              action={action}
            />
          </SpView>
        ) : null}
        {iconSVG ?? <SpView>{iconSVG}</SpView>}
        <SpView style={styles.contentWrap}>
          <>
            <SpView style={styles.content}>
              <Text
                {...testProperties(label, 'setting button text')}
                style={[
                  styles.label,
                  iconSVG ? styles.svgMargin : null,
                  {
                    opacity: isLoading ? 0.3 : 1,
                  },
                ]}>
                {label}
              </Text>
              {valueRenderItem}
            </SpView>
            <Pressable onPress={onAction}>{actionIconRender}</Pressable>
          </>
        </SpView>
      </View>
      <SpDivider />
    </Pressable>
  );
};

export default SpEditMenuItem;

const styles = StyleSheet.create({
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 17,
    paddingLeft: 8,
  },
  content: {
    flexShrink: 1,
  },
  contentWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  label: {
    color: colors.greyText.color,
    fontSize: 14,
    opacity: 0.6,
    lineHeight: 20,
    fontFamily: 'Rubik',
  },
  svgMargin: {
    marginLeft: 16,
  },
  value: {
    // marginTop: 6,
  },
});
