import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const WCIAlertDown = (props: any) => (
  <Svg
    width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <G
      id="All/Light/Icon/Warning/Alert Down">
      <Path id="Path" d="M16 0.253906C7.168 0.253906 0 7.42191 0 16.2539C0 25.0859 7.168 32.2539 16 32.2539C24.832 32.2539 32 25.0859 32 16.2539C32 7.42191 24.832 0.253906 16 0.253906Z" fill="#FF9E63" />
      <Path id="Fill 1" fill-rule="evenodd" clip-rule="evenodd" d="M18.0281 13.4942V10.2822C18.0281 9.1621 17.1199 8.25391 15.9998 8.25391C14.8796 8.25391 13.9725 9.1621 13.9725 10.2822V13.4942H9.97623C8.21617 13.4942 7.33767 15.6249 8.58579 16.8658L14.6093 22.8505C15.3783 23.6153 16.6213 23.6153 17.3902 22.8505L23.4138 16.8658C24.6629 15.6249 23.7834 13.4942 22.0233 13.4942H18.0281Z" fill="#263A43" />
    </G>
  </Svg>
);
export default WCIAlertDown;
