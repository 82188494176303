/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo } from 'react';
import { PetModel } from '@models/Pet';
import { PetCard } from './PetCard';
import EmptyPlaceholder from '../../../components/EmptyPlaceholder';
import { Dimensions, FlatList, StyleSheet } from 'react-native';
import { SpView } from 'src/components/SpView';

interface PetCarouselTemplateProps {
  pets: PetModel[];
  loading: boolean;
}

export const PetCarousel = memo(({ pets, loading }: PetCarouselTemplateProps) => {
  const screenWidth = Dimensions.get('window').width;
  let numColumns = 1;
  if (screenWidth >= 600 && screenWidth < 900) {
    numColumns = 2;
  } else if (screenWidth >= 900) {
    numColumns = 3;
  }

  if (!pets.length && !loading) {
    return <EmptyPlaceholder isPets />;
  }

  return (
    <>
      <FlatList
        data={pets}
        renderItem={({ item }) => (
          <SpView style={styles.cardContainer}>
            <PetCard
              item={item}
              loading={loading}
            />
          </SpView>
        )}
        keyExtractor={item => item.id.toString()}
        horizontal={false}
        numColumns={numColumns}
        showsVerticalScrollIndicator
      />
    </>
  );
});

const styles = StyleSheet.create({
  cardContainer: {
    margin: 20,
    maxWidth: 350,
  },
});
