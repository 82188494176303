import React, { StyleSheet, View } from 'react-native';
import { SpView } from 'src/components/SpView';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faClock, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import colors from '@styles/colors';
import { SpText } from '../../../../../../components/SpText/SpText';
import { useTranslation } from 'react-i18next';
import { formatDistance } from 'date-fns';
import { testProperties } from '@utils/testProperties';
import { TimeService } from 'src/services/TimeService';
import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { useMemo } from 'react';
import { DeviceFeederTypeUpdated } from '@models/Device';
import useLastDate from '../../hooks/useLastDate';

interface FeederInfoProps {
  trainingMode?: number;
  events: DeviceFeederTypeUpdated;
}

export const FeederInfo = ({ trainingMode = 0, events }: FeederInfoProps) => {
  const { t } = useTranslation();
  const { timeToFormat, convertDateToTime } = useTimeTransform();
  const userTimeFormat = useUserTimeUnits();
  const updatedTime = useLastDate(events, 'last_filled_at');

  const [timezonedLastEvents, convertedDate] = useMemo(() => {
    if (typeof updatedTime === 'string') {
      return [updatedTime, ''];
    }
    const newDate = TimeService.convertOrUnconvertDateByUTC<Date>(new Date(updatedTime), true);
    const dateString = timeToFormat(convertDateToTime(updatedTime), userTimeFormat);
    const timeDistance = formatDistance(newDate, TimeService.getTimezonnedCurrentDate(), {
      addSuffix: true,
    });
    return [timeDistance, dateString];
  }, [updatedTime, userTimeFormat]);

  const isYear2000 = useMemo(() => {
    return new Date(updatedTime).getFullYear() === 2000;
  }, [updatedTime]);

  return (
    <SpView style={styles.wrapper}>
      <SpView width="57%">
        <SpView flexDirection="row">
          <View {...testProperties('faClock', 'icon')}>
            <FontAwesomeIcon
              icon={faClock}
              size={20}
              color={colors.primary.color}
            />
          </View>
          <SpText
            size="md"
            style={styles.label}>
            {t('last_filled')}
          </SpText>
        </SpView>
        {updatedTime && (
          <>
            <SpText
              style={styles.value}
              size="xl"
              fontFamily="Rubik_Medium"
              bold>
              {isYear2000 ? '-' : timezonedLastEvents}
            </SpText>
            <SpText
              style={styles.description}
              size="md">
              {isYear2000 ? '-' : convertedDate}
            </SpText>
          </>
        )}
      </SpView>
      <SpView>
        <SpView flexDirection="row">
          <View {...testProperties('faGraduationCap', 'icon')}>
            <FontAwesomeIcon
              icon={faGraduationCap}
              size={20}
              color={colors.primary.color}
            />
          </View>
          <SpText
            align="right"
            size="md"
            style={styles.label}>
            {t('training')}
          </SpText>
        </SpView>
        {trainingMode !== 0 ? (
          <SpText
            style={styles.value}
            size="xl"
            fontFamily="Rubik_Medium"
            bold>
            {t('mode', { modeNum: `${trainingMode}` })}
          </SpText>
        ) : (
          <SpText
            style={styles.description}
            size="sm">
            {t('mode_disabled')}
          </SpText>
        )}
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 11,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    marginLeft: 8,
  },
  value: {
    marginTop: 4,
  },
  description: {
    marginTop: 4,
    color: 'rgba(38, 58, 67, 0.5);',
  },
});
