import { lastScreenOptions } from '@constants/Screen';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Selector } from '@constants/Navigation';
import { SpView } from 'src/components/SpView';
import styles from '@styles/atoms';
import CustomHeader from '../../../../components/CustomHeader';
import { PetDashboardReportWrapper } from '../../components/PetDashboardReportWrapper';
import { PetDashboardSummaryWrapper } from '../../components/PetDashboardSummaryWrapper';
import { PetProfileMedicalSelectConditionsWrapper } from '../../components/PetProfileMedicalSelectConditionsWrapper';
import { PetProfileMedicalWrapper } from '../../components/PetProfileMedicalWrapper';
import { VisitHistoryWrapper } from '../../components/VisitHistoryWrapper';
import { ReportType } from '../../constants/ReportType';
import { PetProfileSettings } from './PetProfile/pages/PetProfileSettings';
import PetProfile from './PetProfile/PetProfile';
import PetDiet from './PetProfile/pages/PetDiet';
import PetDietEdit from './PetProfile/components/PetDietEdit';
import { PetEditBio } from './PetProfile/components/PetEditBio';
import { ReportTabs } from '../../hooks/useReportTabs';
import { navigationRef } from '../../../RootNavigation';
import { DeviceEventEmitter } from 'react-native';
import { ReportRangeType } from '../../constants/ReportRangeType';
import { PetModel } from '@models/Pet';

interface PetEditBioProps {
  label: string;
  inputName: string;
  inputValue: any;
}

export type PetStackParamList = {
  PetDashboardReport: {
    type: ReportType;
    tab: ReportTabs;
    viewedDays?: ReportRangeType;
    currentDate?: string;
    pet?: PetModel;
  };
  PetDashboardSummary: Selector;
  PetProfileSettings: Selector;
  PetProfile: Selector;
  VisitHistory: {
    type: ReportType;
  };
  PetDiet: Selector;
  PetDietEdit: Selector;
  PetProfileMedical: Selector;
  PetProfileMedicalSelectConditions: Selector;
  PetEditBio: PetEditBioProps;
};

const Stack = createStackNavigator<PetStackParamList>();

export default function PetsNavigation() {
  const { t } = useTranslation();

  return (
    <SpView style={styles.screenWrapper}>
      <Stack.Navigator initialRouteName="PetDashboardSummary">
        <Stack.Screen
          name="PetDashboardSummary"
          options={{ headerShown: false }}
          component={PetDashboardSummaryWrapper}
        />
        <Stack.Screen
          name="PetProfileSettings"
          options={{
            headerShown: false,
          }}
          component={PetProfileSettings}
        />

        <Stack.Screen
          name="PetEditBio"
          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
            }),
          }}
          component={PetEditBio}
        />
        <Stack.Screen
          name="PetDashboardReport"
          options={{ headerShown: false }}
          component={PetDashboardReportWrapper}
        />
        <Stack.Screen
          name="VisitHistory"
          options={{
            gestureEnabled: true,
            headerShown: false,
            presentation: 'transparentModal',
          }}
          component={VisitHistoryWrapper}
        />

        <Stack.Screen
          name="PetProfile"
          options={{
            headerShown: false,
          }}
          component={PetProfile}
        />

        {/* <Stack.Screen
          name="PetEditBio"
          options={{ ...lastScreenOptions() }}
          component={PetEditBio}
        /> */}
        {/* <Stack.Screen
          name="PetDashboardReport"
          options={{
            headerShown: false,
          }}
          component={PetDashboardReportWrapper}
        /> */}
        {/* <Stack.Screen
          name="VisitHistory"
          options={{
            gestureEnabled: true,
            headerShown: false,
            presentation: 'transparentModal',
          }}
          component={VisitHistoryWrapper}
        /> */}

        {/* <Stack.Screen
          name="PetProfile"
          options={{
            headerShown: true,
            header: () =>
              CustomHeader({
                withTitle: false,
                withRightButton: true,
                rightButtonText: t('microchip'),
                rightButtonAction: () => {
                  openModal('MicrochipNumber');
                  setActiveModalButtons({ microchipNumber: true });
                },
                rightButtonActive: activeModalButtons.microchipNumber,
              }),
          }}
          component={PetProfile}
        /> */}

        <Stack.Screen
          name="PetDiet"
          component={PetDiet}
          options={{
            headerShown: true,
            header: () =>
              CustomHeader({
                title: t('diet'),
                withArrowBack: true,
                goBackFunc: () => navigationRef.goBack(),
              }),
          }}
        />

        <Stack.Screen
          name="PetDietEdit"
          component={PetDietEdit}
          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
            }),
          }}
        />
        <Stack.Screen
          name="PetProfileMedical"
          options={{
            header: () =>
              CustomHeader({
                title: t('medical'),
                goBackFunc: () => navigationRef.goBack(),
              }),
          }}
          component={PetProfileMedicalWrapper}
        />
        <Stack.Screen
          name="PetProfileMedicalSelectConditions"
          options={{
            ...lastScreenOptions({
              goBackFunc: () => navigationRef.goBack(),
            }),
          }}
          component={PetProfileMedicalSelectConditionsWrapper}
        />
      </Stack.Navigator>
    </SpView>
  );
}
