import { FlatList, Platform, SafeAreaView, StyleSheet } from 'react-native';
import React, { useMemo, useState } from 'react';
import SelectButton from 'src/components/SpSelectButton';
import SpTitle from 'src/components/SpTitle';
import { SpView } from 'src/components/SpView';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import { PetDietOptions } from '@constants/Pet';
import { RouteProp, useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { useUpdatePet } from '@hooks/useUpdatePet';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { PetStackParamList } from '../..';

type Props = NativeStackScreenProps<PetStackParamList, 'PetDietEdit'>;

const PetDietEdit = ({ navigation }: Props) => {
  const ENUM_REGEX = /^[A-Z][a-z0-9_-]*$/;
  const insets = useSafeAreaInsets();
  const units = useMemo(
    () => enumToDescriptedArray(PetDietOptions, ENUM_REGEX, true),
    [PetDietOptions],
  );
  const route = useRoute<RouteProp<PetStackParamList, 'PetDietEdit'>>();
  const id = route.params.id || 0;
  const [selectedDiet, setSelectedDiet] = useState(units.find(item => item.id === id)?.id);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const { t } = useTranslation();
  const handleSelectDiet = (newSelectedDiet: React.SetStateAction<PetDietOptions>) => {
    setSelectedDiet(newSelectedDiet);
  };

  const handleUpdatePet = useUpdatePet(
    setButtonIsLoading,
    setButtonDisabled,
    'food_type_id',
    navigation,
  );

  return (
    <SafeAreaView style={[styles.container]}>
      <SpView style={styles.inner}>
        <FlatList
          style={{ paddingHorizontal: 10 }}
          data={units}
          renderItem={({ item }) => {
            return (
              <SelectButton
                key={`${item.id}${item.name}`}
                isActive={selectedDiet === item.id}
                option={item}
                setActive={handleSelectDiet}
                withIcon
              />
            );
          }}
          keyExtractor={item => `${item.id}${item.name}`}
          ListHeaderComponent={<SpTitle text={t('food_type')} />}
        />
      </SpView>
      <SpView
        style={[
          styles.button,
          {
            marginBottom: insets.bottom,
            bottom: Platform.OS === 'ios' ? 0 : '10%',
          },
        ]}>
        <SpRoundedHeaderButton
          disabled={buttonDisabled}
          isLoading={buttonIsLoading}
          title={t('save')}
          backgroundColor={colors.primary.color}
          h={56}
          onPress={() => handleUpdatePet(selectedDiet)}
        />
      </SpView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
    paddingHorizontal: 20,
  },
  button: {
    paddingHorizontal: 20,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default PetDietEdit;
