import { PetModel } from '@models/Pet';
import { useMemo } from 'react';

import useBoundStore from '../store/store';

export const usePetsByHousehold = (): PetModel[] => {
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const petData = useBoundStore(s => s.petStore.petData);

  return useMemo(() => {
    // AnalyticsService.logEvent('usePetsByHousehold - useMemo');
    if (!activeHousehold || !activeHousehold?.id) return [];
    return petData.filter(item => item.household_id === activeHousehold?.id);
  }, [activeHousehold?.id, petData]);
};
