import { RemoteConfigDefault, RemoteConfigKeys } from '@constants/RemoteConfig';
import { useCallback, useEffect, useState } from 'react';

export const useRemoteConfigBooleanValue = (name: RemoteConfigKeys): boolean => {
  const [value, setValue] = useState<boolean>(false);

  const getData = useCallback(async () => {
    setValue(RemoteConfigDefault[name]);
  }, [setValue, name]);

  useEffect(() => {
    getData().then();
  }, [getData, name, setValue]);

  return value;
};
