import { HouseholdUserModel } from '@models/HouseholdUser';
import qs from 'qs';
import Http from './Http';

class HouseholdUserApi {
  static readonly httpParams = {};





  static updateHouseholdUser(
    householdId: number,
    userId: number,
    user: HouseholdUserModel,
  ): Promise<HouseholdUserModel[]> {
    return Http.put(`/api/household/${householdId}/user/${userId}`, user, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }

  static deleteHouseholdUser(householdId: number, userId: number): Promise<HouseholdUserModel[]> {
    return Http.delete(`/api/household/${householdId}/user/${userId}`, {
      params: this.httpParams,
      paramsSerializer: params => qs.stringify(params),
    }).then(response => response.data.data);
  }
}

export default HouseholdUserApi;
