import { RemoteConfigDefault, RemoteConfigKeys } from '@constants/RemoteConfig';
import { useCallback, useEffect, useState } from 'react';

export const useRemoteConfigNumberValue = (name: RemoteConfigKeys): number => {
  const [value, setValue] = useState<number>(null);

  const getData = useCallback(async () => {
    setValue(RemoteConfigDefault[name]);
  }, [setValue, name]);

  useEffect(() => {
    getData().then();
  }, [getData, name]);

  return value;
};
