import { AppImages } from '@constants/AppImages';
import colors from '@styles/colors';
import images from '@styles/images';
import text from '@styles/text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Platform, StyleSheet } from 'react-native';

import { SpText } from '../../../components/SpText/SpText';
import { SpView } from '../../../components/SpView';

const WiFiResetInstructions = () => {
  const { t } = useTranslation();
  return (
    <SpView
      style={[
        styles.center,
        styles.wrapper,
        styles.container,
        Platform.OS === 'android' ? styles.androidPaddingBottom : {},
      ]}>
      <SpView style={styles.center}>
        <Image
          accessible
          style={[styles.image]}
          source={AppImages.resetImgCDBTop}
          resizeMode="contain"
        />
      </SpView>
      <SpView style={[styles.center, styles.textBlock]}>
        <SpText style={[text.body, styles.text]}>{t('cdb_wifi_reset_paragraph_1')}</SpText>
      </SpView>
      <SpView style={[styles.center, styles.textBlock]}>
        <SpText style={[text.body, styles.text]}>{t('cdb_wifi_reset_paragraph_2')}</SpText>
      </SpView>
      <SpView style={styles.center}>
        <Image
          style={images.imageAddCerberusStep2b}
          source={AppImages.addCerberusSetup2b}
        />
      </SpView>
      <SpView style={[styles.center, styles.hint]}>
        <SpText style={[text.title, styles.text]}>{t('flashing_another_colour_title')}</SpText>
        <SpText style={[text.body, styles.text]}>{t('flashing_another_colour_description')}</SpText>
      </SpView>
    </SpView>
  );
};

export default WiFiResetInstructions;

const styles = StyleSheet.create({
  container: { flex: 1 },
  center: {
    alignItems: 'center',
  },
  androidPaddingBottom: {
    paddingBottom: 100,
  },
  hint: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: colors.greyAccent.color,
    borderRadius: 15,
    marginTop: 'auto',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  text: {
    fontSize: 16,
  },
  image: { width: 60, height: 60 },
  textBlock: {
    marginHorizontal: '5%',
    marginVertical: '3%',
  },
  wrapper: {
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});
