import { SpLoading } from 'src/components/SpLoading';
import { MappedReportModel } from '@models/ReportModel';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useState } from 'react';
import useBoundStore from '../../../store/store';
import { Dashboard } from './VisitHistory/Dashboard';
import { PetStackParamList } from '../Pets/pages';

export const VisitHistoryWrapper = ({
  route,
}: StackScreenProps<PetStackParamList, 'VisitHistory'>) => {
  const [data, setData] = useState<MappedReportModel>(null);
  const { loadDetailsReport, loading } = useBoundStore(s => s.reportStore);
  const pet = useBoundStore(state => state.petStore.activePet);
  const household = useBoundStore(s => s.householdStore.activeHousehold);
  const loadReport = useCallback(async () => {
    return loadDetailsReport(household?.id, pet.id);
  }, [household, pet]);

  useEffect(() => {
    loadReport().then(response => setData(response));
  }, [household, pet]);

  if (loading) {
    return <SpLoading />;
  }

  return (
    <Dashboard
      data={data}
      type={route.params.type}
    />
  );
};
