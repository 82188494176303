import React, { memo } from 'react';
import { SpView } from '../../../../../../components/SpView';
import SpSentIcon from '../../../../../../components/RoundIcons/SpSentIcon';
import { SpText } from '../../../../../../components/SpText/SpText';
import { testProperties } from '@utils/testProperties';
import { Dimensions, StyleSheet } from 'react-native';

const windowHeight = Dimensions.get('window').height;

type PetFoundProps = {
  message: string;
};

const PetFound = ({ message }: PetFoundProps) => {
  return (
    <SpView
      style={styles.justify}
      accessible={false}
    >
      <SpSentIcon />
      <SpText {...testProperties('found pets', 'text')}>{message}</SpText>
    </SpView>
  );
};

export default memo(PetFound);

const styles = StyleSheet.create({
  justify: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: windowHeight < 810 ? 95 : 60,
  },
});
