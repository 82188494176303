import * as React from 'react';
import { Path, Svg } from 'react-native-svg';

const DropdownSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={8}
    {...props}>
    <Path
      fill="#263A43"
      fillRule="evenodd"
      d="M11.922 0H3.078a1.333 1.333 0 0 0-.973 2.245l4.422 4.717a1.333 1.333 0 0 0 1.946 0l4.422-4.717A1.333 1.333 0 0 0 11.922 0Z"
    />
  </Svg>
);
export default DropdownSVG;
