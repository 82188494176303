import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedMovementReportDataModel,
  MappedPoseidonReportDataModel,
  MappedReportModel,
} from '@models/ReportModel';
import { isWithinInterval } from 'date-fns';
import { useMemo } from 'react';

import { TimeService } from '../../../services/TimeService';
import { ReportWrapperFilter } from '../components/PetDashboardReportWrapper';

export const usePetDashboardReportFilterData = (
  data: MappedReportModel,
  filter: ReportWrapperFilter,
) => {
  const filterData = (
    data: GroupReportPointModel<
      | MappedConsumptionReportDataModel
      | MappedMovementReportDataModel
      | MappedPoseidonReportDataModel
    >[],
    days: number,
  ) => {
    return data?.filter(item => {
      const itemDate = TimeService.toLocal(item.date).toJSDate();
      const currentDateMinusx = TimeService.toLocal(filter.currentDate)
        .minus({
          days: days * 2 < 14 ? 14 : days * 2,
        })
        .toJSDate();
      const currentDatePlusx = TimeService.toLocal(filter.currentDate).plus({ days: 1 }).toJSDate();
      return isWithinInterval(itemDate, {
        start: currentDateMinusx,
        end: currentDatePlusx,
      });
    });
  };

  return useMemo(() => {
    return {
      feeding: filterData(
        data?.feeding ? data?.feeding : [],
        filter.viewedDays,
      ) as GroupReportPointModel<MappedConsumptionReportDataModel>[],
      movement: filterData(
        data?.movement ? data?.movement : [],
        filter.viewedDays,
      ) as GroupReportPointModel<MappedMovementReportDataModel>[],
      drinking: filterData(
        data?.drinking ? data?.drinking : [],
        filter.viewedDays,
      ) as GroupReportPointModel<MappedPoseidonReportDataModel>[],
    };
  }, [data, filter.viewedDays, filter.currentDate]);
};
