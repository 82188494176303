import { PetBioMenu } from '@constants/Menus';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

import useBoundStore from 'src/store/store';
import SpSearchList from 'src/components/SpSearchList';
import { BreedModel } from '@models/Breed';
import { useTranslation } from 'react-i18next';

interface PetBioBreedWrapperProps {
  handleChange: (breedID: number) => void;
  closeOnSelect?: boolean;
  handleClose?: () => void;
  selectedBreed?: number;
  type?: string;
  anotherTypeBreedValue?: number;
  anotherSpecie?: number;
  setButtonDisabled?: Dispatch<SetStateAction<boolean>>;
}

const PetBioBreedWrapper = ({
  handleChange,
  closeOnSelect = false,
  handleClose,
  selectedBreed,
  type,
  anotherTypeBreedValue = null,
  anotherSpecie = null,
  setButtonDisabled,
}: PetBioBreedWrapperProps) => {
  const { breeds } = useBoundStore(state => state.breedStore);
  const { activePet } = useBoundStore(state => state.petStore);
  const [currentBreed, setCurrentBreed] = useState<number[]>(selectedBreed ? [selectedBreed] : []);

  const { t } = useTranslation();

  useEffect(() => {
    if (!setButtonDisabled) return;
    if (currentBreed.length === 0) {
      setButtonDisabled(true);
    }
    if (currentBreed[0] === selectedBreed) setButtonDisabled(true);
  }, []);

  const BreedsArr = useMemo(() => {
    let anotherTypeBreed: number;
    if (anotherTypeBreedValue) {
      anotherTypeBreed = anotherTypeBreedValue;
    } else {
      anotherTypeBreed =
        (type === PetBioMenu.BreedID && activePet?.breed_id2) ||
        (type === PetBioMenu.BreedID2 && activePet?.breed_id);
    }
    return breeds
      .filter((breed: BreedModel) => breed.species_id === (anotherSpecie || activePet?.species_id))
      .filter((breed: BreedModel) => breed.id !== anotherTypeBreed);
  }, [breeds, anotherSpecie]);

  const onChangeBreed = useCallback((ids: number[]) => {
    // cast 0 to null to unset breed
    handleChange(ids[0]);
    setCurrentBreed(ids);
    if (closeOnSelect) handleClose();
    if (setButtonDisabled) setButtonDisabled(false);
  }, []);

  return (
    <TouchableWithoutFeedback onPress={() => dismissKeyboard()}>
      <View style={styles.container}>
        <SpSearchList
          // Add None option to the list of breeds only if the type is BreedID2
          arr={
            type === PetBioMenu.BreedID2 ? [{ id: 0, name: 'None' }, ...BreedsArr] : [...BreedsArr]
          }
          selectedItems={currentBreed}
          setItemId={onChangeBreed}
          hasInput
          firstPlugText={t('none')}
          isCreateProfileScreen
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

export default PetBioBreedWrapper;
