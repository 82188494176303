import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const CircleChevronTopSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    {...props}
  >
    <Path
      d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0Z"
      fill="#263A43"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9719 17.9299V21.1418C13.9719 22.262 14.8801 23.1702 16.0002 23.1702C17.1204 23.1702 18.0275 22.262 18.0275 21.1418V17.9299H22.0238C23.7838 17.9299 24.6623 15.7992 23.4142 14.5582L17.3907 8.5736C16.6217 7.80875 15.3787 7.80875 14.6098 8.5736L8.58624 14.5582C7.3371 15.7992 8.21662 17.9299 9.97669 17.9299H13.9719Z"
      fill="white"
    />
  </Svg>
);
export default CircleChevronTopSVG;
