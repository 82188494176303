import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import persistStorage from '@utils/persistStorage';
import { DdLogs } from '../services/SPDataDogService';

export const useAcceptedPushNotifications = () => {
  const [accepted, setAccepted] = useState(null);
  const checkState = () => {
    // persistStorage.removeItem('PUSH_NOTIFICATION_PERMISSION');
    Platform.select({
      native: () => {
        return persistStorage.getItem('PUSH_NOTIFICATION_PERMISSION').then(persistedPreference => {
          const parsedPreference = persistedPreference;
          if (parsedPreference !== null && parsedPreference.granted !== null) {
            setAccepted(parsedPreference.granted);
            DdLogs.info(
              'useAcceptedPushNotifications - checkState - PUSH_NOTIFICATION_PERMISSION',
              { permission: parsedPreference.granted },
            );
          }
        });
      },
      default: () => {
        setAccepted(false);
      },
    })();
  };
  useEffect(() => {
    checkState();
  }, []);

  return { accepted, checkState };
};
