import colors from '@styles/colors';
import { StyleSheet } from 'react-native';

const text = StyleSheet.create({
  title: {
    fontSize: 20,
    fontFamily: 'Rubik_SemiBold',
    lineHeight: 32,
    textAlign: 'center',
    color: colors.greyText.color,
    marginLeft: 1,
  },
  longTitle: {
    fontFamily: 'Rubik',
    fontSize: 20,
    color: colors.black.color,
    paddingHorizontal: 20,
    marginBottom: 44,
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.black.color,
    paddingHorizontal: 20,
    marginBottom: 44,
    textAlign: 'center',
  },
  prompt: {
    fontFamily: 'Rubik',
    fontSize: 20,
    color: colors.black.color,
    lineHeight: 24,
    textAlign: 'center',
  },
  body: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.black.color,
    lineHeight: 24,
    textAlign: 'center',
  },
  loadingText: {
    fontFamily: 'Rubik_Medium',
    marginTop: 16,
    fontSize: 20,
    textAlign: 'center',
    color: colors.greyText.color,
  },
  subtitleLoading: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.black.color,
    paddingHorizontal: 20,
    paddingTop: 20,
    marginBottom: 44,
    textAlign: 'center',
  },
  error: {
    fontSize: 12,
    color: 'red',
  },
  linkText: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
    color: colors.primary.color,
    fontWeight: 'bold',
    textAlign: 'center',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: colors.primary.color,
  },
  smallTitle: {
    fontFamily: 'Rubik_Medium',
    fontSize: 20,
    textAlign: 'center',
    color: colors.greySmallTitle.color,
  },

  leftTitle: {
    fontFamily: 'Rubik_Medium',
    fontSize: 20,
    textAlign: 'left',
    color: colors.black.color,
    marginTop: 30,
    marginBottom: 10,
  },
  leftSubtitle: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    textAlign: 'left',
    color: colors.black.color,
    marginTop: 15,
    marginBottom: 3,
  },
  defaultText: {
    fontFamily: 'Rubik',
    fontSize: 16,
    textAlign: 'left',
    color: colors.greyText.color,
    lineHeight: 24,
  },
  defaultTextSmall: {
    fontFamily: 'Rubik',
    fontSize: 14,
    textAlign: 'left',
    color: colors.greyText.color,
    lineHeight: 24,
  },
  label: {
    marginBottom: 8,
    height: 20,
    fontSize: 14,
    color: colors.greyText.color,
    lineHeight: 20,
    fontFamily: 'Rubik',
  },
});

export default text;
