import { SpView } from 'src/components/SpView';
import { Image, ImageSourcePropType, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import React, { FC } from 'react';

interface IGraphLinesMock {
  mockLine: { height: string }[];
  iconSource: ImageSourcePropType;
  imageStyle?: StyleProp<ViewStyle>;
}

const GraphLinesMock: FC<IGraphLinesMock> = ({ mockLine, iconSource, imageStyle }) => {
  return (
    <SpView style={styles.graphWrapper}>
      {mockLine.map(item => {
        return (
          <SpView
            key={`${item.height}${Math.random()}`}
            style={[styles.graphLine, { height: item.height }]}
          />
        );
      })}
      <SpView style={[styles.iconWrapper, imageStyle || {}]}>
        <Image
          source={iconSource}
          style={[styles.doorIcon]}
        />
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  graphWrapper: {
    marginTop: 32,
    width: 208,
    height: 112,
    borderBottomWidth: 2.5,
    borderBottomColor: '#2db58e',
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    columnGap: 5,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  graphLine: {
    backgroundColor: '#2db58e',
    width: 10,
    borderTopLeftRadius: 126,
    borderTopRightRadius: 126,
  },
  iconWrapper: {
    position: 'absolute',
    bottom: -20,
  },
  doorIcon: {
    width: 40,
    height: 40,
  },
});

export default GraphLinesMock;
