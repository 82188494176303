import { SpText } from '../SpText/SpText';
import colors from '@styles/colors';
import React from 'react';
import { StyleSheet } from 'react-native';

export interface SpEditMenuItemValueProps {
  value: string;
  numberOfLines?: number | undefined;
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip' | undefined;
}

const SpEditMenuItemValue = ({
  value,
  numberOfLines = undefined,
  ellipsizeMode = undefined,
}: SpEditMenuItemValueProps) => {
  return (
    // eslint-disable-next-line prettier/prettier
    <SpText
      ellipsizeMode={ellipsizeMode}
      numberOfLines={numberOfLines}
      style={styles.container}>
      {value}
    </SpText>
  );
};
export default SpEditMenuItemValue;

const styles = StyleSheet.create({
  container: {
    color: colors.greyText.color,
    fontSize: 18,
    lineHeight: 24,
    fontFamily: 'Rubik',
  },
});
