import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const ReportIcon = (props: any) => (
  <Svg
    width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <G
      id="solid/reports">
        <G id="icon">
            <Path d="M12.5 1.04175C11.2343 1.04175 10.2083 2.06776 10.2083 3.33342V16.6667C10.2083 17.9324 11.2343 18.9584 12.5 18.9584H15.8333C17.099 18.9584 18.125 17.9324 18.125 16.6667V3.33341C18.125 2.06776 17.099 1.04175 15.8333 1.04175H12.5Z" fill="white" />
            <Path d="M4.16667 7.70842C2.90101 7.70842 1.875 8.73443 1.875 10.0001V16.6667C1.875 17.9324 2.90101 18.9584 4.16667 18.9584H6.66667C7.93232 18.9584 8.95833 17.9324 8.95833 16.6667V10.0001C8.95833 8.73443 7.93232 7.70842 6.66667 7.70842H4.16667Z" fill="white" />
        </G>
    </G>
  </Svg>
);
export default ReportIcon;