import DeviceApi from '@api/DeviceApi';
import { DeviceModel } from '@models/Device';
import { TagModel } from '@models/Tag';
import { DeviceType } from '@constants/Device';
import { MAX_PET_SEARCH_RETRIES } from '@constants/FindPet';
import { asyncControl } from '@utils/asyncControlAwait';

type IPetService = {
  onFound?: (tag: TagModel) => void;
  onError?: (e: any) => void;
  maxRetriesHandler?: (state: boolean) => void;
  timeoutHandler?: () => void;
  multipleSearch?: boolean;
};

export class PetService {
  timer: ReturnType<typeof setInterval> = undefined;

  retries = 0;

  maxRetries = MAX_PET_SEARCH_RETRIES;

  retryEvery = 3000;

  errorHandler?: (props: any) => void;

  maxRetriesHandler: (state: boolean) => void;

  foundHandler?: (props: any) => void;

  notifyTimeout?: () => void;

  startingTags: TagModel[];

  errors: Error[] = [];

  loadingStartHandler: () => void;

  loadingEndHandler: () => void;

  activeHouseholdId: number;

  multipleSearch: boolean;

  constructor({
    onFound,
    onError,
    timeoutHandler,
    multipleSearch = false,
    maxRetriesHandler,
  }: IPetService) {
    this.foundHandler = onFound;
    this.errorHandler = onError;
    this.notifyTimeout = timeoutHandler;
    this.timer = null;
    this.startingTags = null;
    this.errorHandler = onError;
    this.multipleSearch = multipleSearch;
    this.maxRetriesHandler = maxRetriesHandler;
  }

  async stop(device: DeviceModel = null) {
    if (!this.timer) {
      return;
    }
    clearInterval(this.timer);
    this.timer = null;
    this.startingTags = null;

    if (device?.product_id === DeviceType.FelaquaConnect) {
      console.log('stop learn mode');
      await asyncControl(device.id, { learn_mode: false });
    }
  }

  async start(
    device: DeviceModel,
    maxRetries: number = this.maxRetries,
    wait: number = this.retryEvery,
  ) {
    try {
    this.maxRetriesHandler(false);
    await this.stop(device);

    if (device.product_id === DeviceType.FelaquaConnect) {

        console.log('start learn mode');
        await asyncControl(device.id, { learn_mode: true });
        console.log('learn mode started');

    }
    this.doSearch(device);
    this.retries += 1;
    this.timer = setInterval(() => {

      this.doSearch(device);
      this.retries += 1;
    }, wait);
    }
  catch(e) {
      console.log('learn mode ERROR');
    this.errorHandler(e);

    throw e
    }
  }

  async doSearch(device: DeviceModel | number) {
    if (this.retries > this.maxRetries && !this.multipleSearch) {
      // if (this.retries > this.maxRetries) {
      this.maxRetriesHandler(true);
      if (typeof device !== 'number' && device?.id) {
        await this.finish(device as DeviceModel);
      }
      this.notifyTimeout();
    } else {
      const deviceId = typeof device === 'number' ? device : device?.id;
      DeviceApi.getDeviceTags(deviceId)
        .then(async tags => {
          if (this.startingTags == null) {
            this.startingTags = tags.slice();
            return;
          }
          if (tags === null) {
            return;
          }
          const newTags = tags.filter(d => {
            return (
              this.startingTags === null || this.startingTags.findIndex(f => f.id === d.id) === -1
            );
          });
          if (newTags.length) {
            this.startingTags = [...this.startingTags, ...newTags];
            this.foundHandler(newTags[0]);
            if (!this.multipleSearch) {
              if (typeof device !== 'number' && device?.id) {
                await this.finish(device);
              }
            }
          }
        })
        .catch(e => {
          this.errorHandler(e);
        });
    }
  }

  async finish(device: DeviceModel) {
    await this.stop(device);
    this.retries = 0;
  }
}
