import * as React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

const GenerateReportSVG = (props: SvgProps) => {
  return (
    <Svg
      width={26}
      height={26}
      viewBox="0 0 24 24"
      {...props}>
      <G
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1">
        <G
          fill="#263A43"
          fillRule="nonzero">
          <Path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.993 5.376 18.624.007 12 0zm3.273 19.636H8.727a1.09 1.09 0 110-2.181h6.546a1.09 1.09 0 110 2.181zm1.862-9.047a1.09 1.09 0 01-1.543 0l-2.501-2.5v7.184a1.09 1.09 0 01-2.182 0V8.088L8.408 10.59a1.09 1.09 0 01-1.543-1.542l4.363-4.363a1.095 1.095 0 011.544 0l4.363 4.363a1.09 1.09 0 010 1.542z" />
        </G>
      </G>
    </Svg>
  );
};

export default GenerateReportSVG;
