import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DeviceModel } from '@models/Device';
import { PetModel, PetWithTagModel } from '@models/Pet';
import useBoundStore from '../../../../../store/store';
import { TagModel } from '@models/Tag';
import useControlIncompatibleTags from './useControlIncompatibleTags';
import usePetService from './usePetService';
import { StepsEnum } from '../misc/enums';
import { Linking } from 'react-native';
import {
  NavigationProp,
  StackActions,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import { RootStackParamList } from '../../../../index';
import { useTranslation } from 'react-i18next';
import useHandleSteps from './useHandleSteps';
import useCheckToLeavePetFlow from './useCheckToLeavePetFlow';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { DeviceType } from '@constants/Device';
import useHandleSelectedDevice from './useHandleSelectedDevice';
import useStepInterceptor from './useStepInterceptor';
import { navigationRef } from '../../../../RootNavigation';
import { navigateToPets } from '@utils/navigationShortcuts';

type UseAddPetsLogicProps = {
  existingDevice?: boolean;
  deviceType?: DeviceType;
  returnToStep?: number;
  comingFromProfile?: boolean;
  deviceId?: number;
  comingFromSettings?: boolean;
  comingFromIncompatibleAssignPage?: boolean;
  petIncompatibleAssociated?: number;
  routeStep?: number;
  noProducts?: boolean;
};
const useAddPetsLogic = ({
  existingDevice,
  deviceType,
  returnToStep,
  comingFromProfile,
  deviceId,
  comingFromSettings,
  comingFromIncompatibleAssignPage,
  petIncompatibleAssociated,
  routeStep,
  noProducts,
}: UseAddPetsLogicProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const isFocused = useIsFocused();

  const [newTagAdded, setNewTagAdded] = useState<TagModel>(null);
  const [foundedPetsTagIds, setFoundedPetsTagIds] = useState<number[]>([]);
  const [incompatiblePets, setIncompatiblePets] = useState([]);
  const [maxRetriesError, setMaxRetriesError] = useState(false);
  const [updatesPetsShared, setUpdatesPetsShared] = useState<
    (PetWithTagModel & { isAssigned: boolean })[]
  >([]);
  const [assignUpdateData, setAssignUpdateData] = useState<
    (PetWithTagModel & { isAssigned: boolean })[]
  >([]);
  const [assignedPets, setAssignedPets] = useState<PetModel[]>([]);
  const [indoorModeEnabledPets, setIndoorModeEnabledPets] = useState<PetModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [unnamedPets, setUnnamedPets] = useState<boolean>(false);

  const prevFocusedState = useRef<boolean>(false);
  const selectedDeviceRef = useRef<DeviceModel>(null);
  const incompatiblePetAssociatedRef = useRef<number>(null);
  const showPetsAddedRef = useRef(false);
  const backFromCreatePetProfile = useRef(false);
  const comeFromRemovePets = useRef<boolean>(false);
  const stopSkipRef = useRef<boolean>(false);

  const { getPetByTagId, fetchAndGetPetByTagId, fetchAndGetDeviceById } = useBoundStore(
    ({ petStore, deviceStore }) => ({
      getPetByTagId: petStore.getPetByTagId,
      fetchAndGetPetByTagId: petStore.fetchAndGetPetByTagId,
      fetchAndGetDeviceById: deviceStore.fetchAndGetDeviceById,
    }),
  );

  const pets = usePetsByHousehold();
  const unchanged = useRef(0);

  useEffect(() => {
    unchanged.current = assignedPets.length;
  }, [assignedPets]);

  const setSelectedDevice = useCallback(
    async (device: DeviceModel) => {
      if (!device) return;
      selectedDeviceRef.current = device;
      if (device.tags?.length > 0) {
        stopSkipRef.current = false;
        const devicePetsPromises = device.tags.map(async tag => {
          return await fetchAndGetPetByTagId(tag.id);
        });
        const devicePets = (await Promise.all(devicePetsPromises)).filter(
          pet => !!pet && !assignedPets.find(assignedPet => assignedPet?.id === pet.id),
        );
        setAssignedPets(prev => [...prev, ...devicePets]);
      }
    },
    [assignedPets, fetchAndGetPetByTagId],
  );
  const updateAssignedPets = useCallback(async () => {
    try {
      const newPetDataPromises = assignedPets.map(async assignedPet => {
        return await fetchAndGetPetByTagId(assignedPet.tag_id);
      });
      const resolvedPetData = (await Promise.all(newPetDataPromises)).filter(pet => !!pet);
      setAssignedPets(resolvedPetData);
      return;
    } catch (error) {
      console.error('Error updating assigned pets:', error);
    }
  }, [assignedPets, fetchAndGetPetByTagId]);
  const addFoundPetTagId = useCallback((newTag: TagModel) => {
    setNewTagAdded(newTag);
    setFoundedPetsTagIds(old => [...old, newTag.id]);
  }, []);

  // Custom hook to control the incompatible tags
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const allIncompatiblePetsSorted = useControlIncompatibleTags({
    newTagAdded,
    setNewTagAdded,
    incompatiblePets,
    setIncompatiblePets,
  });

  const petService = usePetService({
    existingDevice,
    selectedDeviceRef: selectedDeviceRef,
    onNewTagFound: addFoundPetTagId,
    handleNewPet: pet => {
      setAssignedPets(pets => [...pets, pet]);
    },
    maxRetriesHandler: setMaxRetriesError,
    onRetry: () => {
      handleSetStep(StepsEnum.AddPets);
    },
    onTryTimesExceeded: () => {
      navigation.navigate('DashboardNavigation');
      Linking.openURL(t('get_help_url'));
    },
  });

  const { step, handleSetStep } = useHandleSteps({
    selectedDeviceRef: selectedDeviceRef,
    existingDevice,
    setSelectedDevice,
    stopSkipRef,
    petService,
    unnamedPets,
    comeFromRemovePets,
    setUpdatesPetsShared,
    updatesPetsShared,
    incompatiblePetAssociatedRef,
    foundedPetsTagIds,
    unchanged,
    showPetsAddedRef,
    setLoading,
    updateAssignedPets,
    deviceId,
    noProducts,
    routeStep,
  });

  const { leavePetFlow } = useCheckToLeavePetFlow({
    deviceType: deviceType || selectedDeviceRef?.current?.product_id,
    returnToStep,
    comingFromProfile,
    deviceId: deviceId || selectedDeviceRef?.current?.id,
    existingDevice,
    pets,
  });

  const { handleSelectedDevice } = useHandleSelectedDevice({
    existingDevice,
    setSelectedDevice,
    handleStep: handleSetStep,
    currentStep: step,
  });

  const { applyInterceptor } = useStepInterceptor({
    petService: petService,
    assignUpdateData,
    updatesPetsShared,
    setLoading,
    selectedDeviceRef: selectedDeviceRef,
    setAssignedPets,
    setUpdatesPetsShared,
    checkToLeavePetFlow: leavePetFlow,
    showPetsAddedRef,
    handleSetStep: handleSetStep,
    assignedPets,
    indoorModeEnabledPets,
    setIncompatiblePetAssociated: val => (incompatiblePetAssociatedRef.current = val),
    comingFromSettings,
    comingFromIncompatibleAssignPage,
    existingDevice,
    foundedPetsTagIds,
    backFromCreatePetProfile,
  });

  const redirectToAddPet = useCallback(
    (pet: PetModel) => {
      incompatiblePetAssociatedRef.current = pet?.id;
      handleSetStep(StepsEnum.IncompatibleChip /**step 7*/);
    },
    [handleSetStep, incompatiblePetAssociatedRef],
  );

  const indoorModePetList = useMemo(() => {
    return existingDevice ? [getPetByTagId(foundedPetsTagIds[0]) as PetModel] : assignedPets;
  }, [assignedPets, existingDevice, foundedPetsTagIds, getPetByTagId]);

  const handleAddAnotherPetAction = useCallback(() => {
    updateAssignedPets().then(() => {
      handleSetStep(StepsEnum.AddPets /** step 3*/);
      setFoundedPetsTagIds([]);
      stopSkipRef.current = true;
    });
  }, [handleSetStep, updateAssignedPets]);

  const handleFoundPetsContinueButtonAction = useCallback(() => {
    return handleSetStep(StepsEnum.PetsNotNamed /** step 5*/);
  }, [handleSetStep]);

  const handleFoundPetsAndroidBackPress = useCallback(() => {
    const routeName = navigationRef.current.getCurrentRoute()?.name;
    if (routeName === 'PetEditBio') {
      navigationRef.goBack();
      return true;
    }
    if (routeName === 'PetProfileSettings') {
      navigation.navigate('FlowNavigation', {
        screen: 'AddPets',
        params: {
          // @ts-ignore
          deviceType: selectedDeviceRef.current?.product_id,
          deviceId: selectedDeviceRef.current?.id,
          step: StepsEnum.FoundPets,
        },
      });
      return true;
    }
    if (routeName === 'AddPets') {
      navigateToPets();
      return true;
    }
    return false;
  }, [navigation]);

  const handleAssignPetSaveButtonAction = useCallback(() => {
    if (selectedDeviceRef.current?.product_id === DeviceType.Cerberus) {
      navigation.dispatch(
        StackActions.replace('CreatePetProfile', {
          cdb: true,
          device_id: deviceId,
        }),
      );
    } else {
      stopSkipRef.current = true;
      handleSetStep(StepsEnum.AddPets /**step 3*/);
    }
  }, [handleSetStep, navigation, deviceId]);

  const handlePromptPageUnassignPetsAction = useCallback(() => {
    comeFromRemovePets.current = true;
    handleSetStep(StepsEnum.AssignPets /**step 6*/);
  }, [handleSetStep]);

  const successTickMessage = useMemo(() => {
    return t(
      selectedDeviceRef.current?.product_id === DeviceType.FeederConnect ||
        selectedDeviceRef.current?.product_id === DeviceType.FelaquaConnect ||
        selectedDeviceRef.current?.product_id === DeviceType.PetDoorConnect ||
        selectedDeviceRef.current?.product_id === DeviceType.Cerberus ||
        (selectedDeviceRef.current?.product_id === DeviceType.CatFlapConnect && existingDevice) ||
        (selectedDeviceRef.current?.product_id === DeviceType.CatFlapConnect &&
          showPetsAddedRef.current)
        ? existingDevice ||
          incompatiblePets.length > 0 ||
          selectedDeviceRef.current?.product_id === DeviceType.Cerberus
          ? 'pet_added'
          : 'pets_added'
        : 'setup_complete',
    );
  }, [
    existingDevice,
    incompatiblePets,
    t,
    selectedDeviceRef.current?.product_id,
    showPetsAddedRef.current,
  ]);

  const addPetsLeaveButtonAction = useCallback(() => {
    petService.stop(() => {
      navigation.navigate('DashboardNavigation', {
        screen: 'Pets',
      });
    });
  }, [navigation, petService]);

  const addPetsStepConfig = useMemo(() => {
    return maxRetriesError
      ? {
          backBottomButton: true,
          hideButton: false,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
            leaveButtonAction: addPetsLeaveButtonAction,
          },
          buttonText: petService.tryTimes > 2 ? t('get_help') : t('sorry_try_again'),
          forcePressHandler: petService.retry,
        }
      : {
          customHeaderProps: {
            withLeaveButton: !loading,
            withArrowBack: !loading,
            leaveButtonAction: addPetsLeaveButtonAction,
          },
          hideButton: foundedPetsTagIds.length < 1 || loading,
          hideProgressHeader: true,
        };
  }, [
    addPetsLeaveButtonAction,
    foundedPetsTagIds.length,
    loading,
    maxRetriesError,
    petService.retry,
    petService.tryTimes,
    t,
  ]);

  const handlePromptPageForcePressHandler = useCallback(() => {
    if (selectedDeviceRef.current?.product_id === DeviceType.CatFlapConnect) {
      showPetsAddedRef.current = true;
    }
    if (selectedDeviceRef.current?.product_id === DeviceType.PetDoorConnect) {
      handleSetStep(StepsEnum.AssignPets /**step 6*/);
    } else if (
      selectedDeviceRef.current?.product_id === DeviceType.FeederConnect &&
      selectedDeviceRef.current?.tags?.length > 1
    ) {
      handleSetStep(StepsEnum.RemovePets /**step 8*/);
    } else {
      handleSetStep(StepsEnum.Success /** step 10*/);
    }
  }, [handleSetStep]);
  const handleIndoorModeLeaveAction = useCallback(() => {
    navigation.navigate('DashboardNavigation', {
      screen: 'Pets',
    });
  }, [navigation]);

  useLayoutEffect(() => {
    if (isFocused && prevFocusedState.current === false) {
      (async () => {
        const deviceID = selectedDeviceRef.current?.id ?? deviceId;
        if (deviceID) {
          setSelectedDevice(await fetchAndGetDeviceById(deviceID));
        }
      })();
    }
  }, [deviceId, fetchAndGetDeviceById, isFocused, setSelectedDevice]);

  useLayoutEffect(() => {
    incompatiblePetAssociatedRef.current = petIncompatibleAssociated;
    return () => {
      petService?.stop?.();
    };
  }, []);

  useLayoutEffect(() => {
    if (!isFocused) {
      prevFocusedState.current = isFocused;
      return;
    }
    if (isFocused && prevFocusedState.current === false) {
      prevFocusedState.current = isFocused;
      if (
        backFromCreatePetProfile.current &&
        step === StepsEnum.AddPets &&
        routeStep === StepsEnum.SelectDevice
      ) {
        backFromCreatePetProfile.current = false;
      } else if (step === StepsEnum.FoundPets) {
        handleSetStep(StepsEnum.FoundPets);
      } else {
        if (routeStep === StepsEnum.NoProducts || routeStep) {
          handleSetStep(routeStep);
        } else if (noProducts) {
          handleSetStep(StepsEnum.NoProducts);
        } else {
          handleSetStep(StepsEnum.SelectDevice);
        }
      }
    }
  }, [isFocused, routeStep, step, handleSetStep, noProducts]);

  return {
    setSelectedDevice,
    maxRetriesError,
    step,
    handleSetStep,
    unnamedPets,
    updatesPetsShared,
    incompatiblePetAssociatedRef,
    loading,
    leavePetFlow,
    handleSelectedDevice,
    applyInterceptor,
    setAssignUpdateData,
    indoorModeEnabledPets,
    setIndoorModeEnabledPets,
    backFromCreatePetProfile,
    redirectToAddPet,
    selectedDeviceRef,
    assignedPets,
    setAssignedPets,
    incompatiblePets,
    setIncompatiblePets,
    foundedPetsTagIds,
    indoorModePetList,
    handleAddAnotherPetAction,
    setUnnamedPets,
    handleFoundPetsContinueButtonAction,
    handleFoundPetsAndroidBackPress,
    handleAssignPetSaveButtonAction,
    handlePromptPageUnassignPetsAction,
    successTickMessage,
    addPetsStepConfig,
    handlePromptPageForcePressHandler,
    handleIndoorModeLeaveAction,
  };
};
export default useAddPetsLogic;
