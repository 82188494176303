import SelectButton from 'src/components/SpSelectButton';
import React, { useCallback } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { Carousel } from 'react-native-snap-carousel';
import { SLIDER_WIDTH } from '../../Pets/pages/PetCarousel/PetCard';

const windowWidth = Dimensions.get('window').width;

interface MonthCarouselProps {
  activeMonth: number;
  months: { name: string; date: number }[];
  onChange: (data: number) => void;
}

export const MonthCarousel = ({ activeMonth, months, onChange }: MonthCarouselProps) => {
  const isCarousel = React.useRef(null);

  const renderItem = useCallback(
    (data: { item: { date: number; name: string } }) => {
      const isActive = activeMonth === data.item.date;
      return (
        <View style={styles.item}>
          <SelectButton
            size={120}
            option={{ id: data.item.date, name: data.item.name }}
            isActive={isActive}
            setActive={() => {
              const convertedIndex = months.findIndex(month => month.date === data.item.date);
              handleMarker(convertedIndex);
              onChange(months[convertedIndex].date);
            }}
          />
        </View>
      );
    },
    [activeMonth, months, onChange],
  );

  const handleMarker = useCallback((index: number) => {
    isCarousel.current.snapToItem(index);
  }, []);

  return (
    <Carousel
      layout="default"
      vertical={false}
      onSnapToItem={index => {
        onChange(months[index].date);
      }}
      ref={isCarousel}
      data={months}
      renderItem={renderItem}
      sliderWidth={SLIDER_WIDTH + (windowWidth / 100) * 35}
      itemWidth={(windowWidth / 100) * 33}
    />
  );
};

const styles = StyleSheet.create({
  item: { marginLeft: 24 },
});
