import { SpVStack } from 'src/components/SpVStack';
import { DeviceHubLedMode } from '@constants/Device';
import React, { useMemo } from 'react';
import TypeButton from 'src/pages/Flows/components/TypeButton';

interface LockActionsProps {
  buttons: Record<DeviceHubLedMode, { icon: JSX.Element; text: string; action: () => void }>;
  active: DeviceHubLedMode;
}

export const LightOptions = ({ buttons, active }: LockActionsProps) => {
  const actions = useMemo(() => {
    return [DeviceHubLedMode.Normal, DeviceHubLedMode.Dim, DeviceHubLedMode.Off];
  }, []);

  return (
    <SpVStack
      space={8}
      width="100%">
      {actions.map((item, index) => {
        return (
          <TypeButton
            key={`${buttons[item].text}${index}`}
            text={buttons[item].text}
            isActive={active === actions[index]}
            onPress={buttons[item].action}
            svg={buttons[item].icon}
            leftAlign
          />
        );
      })}
    </SpVStack>
  );
};
