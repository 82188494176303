import { testProperties } from '@utils/testProperties';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Pressable, StyleSheet, SafeAreaView, View } from 'react-native';

import { ModalBoxEmitter } from 'src/services/ModalBox';

interface SpModalProps {
  modalName: string;
  children: any;
  backdropColor?: string;
  onModalCloseHandler?: () => void;
  bottomAreaView?: boolean;
  disabledPress?: boolean;
  onRequestClose?: () => void;
  autoClose?: boolean;
}

const SpModal = ({
  modalName,
  children,
  backdropColor = 'rgba(173,179,195,0.9)',
  onModalCloseHandler,
  bottomAreaView = true,
  disabledPress = false,
  onRequestClose,
  autoClose = false,
}: SpModalProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const onModalBoxEmit = ({ modalBoxType }: { modalBoxType: string }) => {
      if (modalBoxType === modalName) {
        setModalVisible(true);

        if (autoClose) {
          const id = setTimeout(() => {
            setModalVisible(false);
            if (onModalCloseHandler) {
              onModalCloseHandler();
            }
          }, 6000);
          setTimer(id);
        }
      }
    };

    const onModalClose = ({ modalBoxType }: { modalBoxType: string }) => {
      if (modalBoxType === modalName) {
        if (autoClose && timer !== undefined) {
          clearTimeout(timer);
          setTimer(undefined);
        }
        setModalVisible(false);
      }
    };

    ModalBoxEmitter.on('openModal', onModalBoxEmit);
    ModalBoxEmitter.on('closeModal', onModalClose);

    return () => {
      ModalBoxEmitter.off('openModal', onModalBoxEmit);
      ModalBoxEmitter.off('closeModal', onModalClose);

      if (autoClose && timer !== undefined) {
        clearTimeout(timer);
      }
    };
  }, [modalName, autoClose, timer]);

  const toggleModal = useCallback(() => {
    if (isModalVisible && onModalCloseHandler) {
      onModalCloseHandler();
    }
    setModalVisible(prevState => !prevState);
  }, [isModalVisible]);

  return (
    <Modal
      visible={isModalVisible}
      transparent
      onRequestClose={onRequestClose}>
      <SafeAreaView style={[styles.safeArea, { backgroundColor: backdropColor }]} />
      <View
        style={styles.content}
        {...testProperties('modal', 'container')}>
        <Pressable
          onPress={toggleModal}
          style={[styles.backdrop, { backgroundColor: backdropColor }]}
          disabled={disabledPress}
          {...testProperties('all modal button wrapper', '', '', true)}>
          {children}
        </Pressable>
      </View>
      {bottomAreaView && (
        <SafeAreaView style={[styles.safeArea, { backgroundColor: backdropColor }]} />
      )}
    </Modal>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    width: '100%',
  },
  backdrop: {
    flex: 1,
    alignItems: 'center',
    zIndex: 1,
  },
  safeArea: {
    flex: 0,
  },
});

export default SpModal;
