import { testProperties } from '@utils/testProperties';
import React, { PropsWithChildren } from 'react';
import { Pressable, StyleProp, StyleSheet } from 'react-native';
import { SpCenter } from '../SpCenter';
import { SpText } from '../SpText/SpText';

export interface SpMenuButtonProps {
  text?: string;
  action: () => void;
  style?: StyleProp<unknown>;
}

export const SpMenuButton = ({
  text,
  action,
  style = {},
  children = null,
}: PropsWithChildren<SpMenuButtonProps>) => {
  return (
    <Pressable
      accessible={false}
      {...testProperties(text, 'button', 'CustomBottomSheetModal')}
      style={styles.container}
      onPress={action}>
      <SpCenter
        flex={1}
        backgroundColor="#e4e4e7"
        borderRadius={10}
        style={style}>
        {children || <SpText componentName="CustomBottomSheetModal">{text}</SpText>}
      </SpCenter>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
  },
});
