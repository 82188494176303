import { SpLoading } from 'src/components/SpLoading';
import { CloseDelays } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import { DeviceFeederControlModel } from '@models/Device';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import CustomHeader from '../../../../../../components/CustomHeader';
import { openModal } from 'src/services/ModalBox';
import { useTranslation } from 'react-i18next';
import useBoundStore from '../../../../../../store/store';
import { DeviceStackParamList } from '../../index';
import { LidDelay } from '../../components/DeviceLidDelay/LidDelay';
import { DeviceSettingContainer } from '../../components/DeviceSettingContainer';
import InfoModal from '../../components/InfoModal';

export const DeviceLidDelay = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceLidDelay'>) => {
  const { id } = route.params;
  const device = useDeviceById(id);
  const { loadingDevice, updateCloseDelayAsync, loadingControl } = useBoundStore(state => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
      updateCloseDelayAsync: deviceStore.updateCloseDelayAsync,
      loadingControl: deviceStore.loadingControl,
    };
  });
  const { t } = useTranslation();

  const [infoModalActive, setInfoModalActive] = React.useState(false);

  const changeDelayAction = useCallback(
    ($event: CloseDelays) => {
      if ($event === device?.control?.lid?.close_delay) {
        return;
      }
      if (loadingControl.lid?.close_delay) {
        openModal('PetsPreview');
        return;
      }
      updateCloseDelayAsync(device.id, $event);
    },
    [
      device?.control?.lid?.close_delay,
      device.id,
      loadingControl.lid?.close_delay,
      updateCloseDelayAsync,
    ],
  );

  if (loadingDevice || !device) {
    return <SpLoading />;
  }

  return (
    <>
      <CustomHeader
        withTitle={false}
        withRightButton={true}
        rightButtonText={t('info')}
        rightButtonAction={() => {
          openModal('InfoModal');
          setInfoModalActive(true);
        }}
        rightButtonActive={infoModalActive}
      />
      <DeviceSettingContainer>
        <InfoModal deactivateButton={() => setInfoModalActive(false)} />
        <LidDelay
          data={(device?.control as DeviceFeederControlModel).lid}
          onChangeDelay={changeDelayAction}
        />
      </DeviceSettingContainer>
    </>
  );
};
