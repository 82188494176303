import React, { PropsWithChildren } from 'react';
import { SpView, SpViewProps } from './SpView';
import { testProperties } from '@utils/testProperties';

interface SpHStackProps extends SpViewProps {
  space: number;
}

export const SpHStack = ({ space, children, ...props }: PropsWithChildren<SpHStackProps>) => (
  <SpView
  accessible={false}
    {...props}
    flexDirection="row">
    {React.Children.toArray(children).map((item, index) => {
      const isFirst = index === 0;
      const isLast = React.Children.count(children) === index + 1;
      return (
        <SpView
        accessible={false}
          style={{
            marginLeft: isFirst ? 0 : space,
            marginRight: isLast ? 0 : space,
          }}
          key={index}
          // accessible
          >
          {item}
        </SpView>
      );
    })}
  </SpView>
);
