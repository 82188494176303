import { SpCenter } from 'src/components/SpCenter';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { PetDoorLockingMode } from '@constants/Device';
import { UnlockDoorActions } from '@constants/LockUnlockDoorActions';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import OutdoorsSVG from 'src/components/SvgIcons/OutdoorsSVG';
import BothWaysSVG from 'src/components/SvgIcons/BothWaysSVG';
import colors from '@styles/colors';
import IndoorsSVG from 'src/components/SvgIcons/IndoorsSVG';
import useBoundStore from 'src/store/store';
import { SpLockUnlockActions } from 'src/components/SpLockUnlockActions';

type Props = {
  lockMode: PetDoorLockingMode;
  isDoor: boolean;
  deviceId: number;
  disabled: boolean;
  dismissAction: () => void;
};

type LockTypeInfo = {
  icon: React.ReactNode;
  description: string;
};

const UnlockAction: React.FC<Props> = ({ lockMode, isDoor, deviceId, disabled, dismissAction }) => {
  const { t } = useTranslation();
  const { updateLockUnlockRequest } = useBoundStore(store => store.deviceStore);

  const lockTypeInfo = useMemo<LockTypeInfo>(() => {
    const options: LockTypeInfo[] = [
      {
        icon: (
          <IndoorsSVG
            color={colors.greyText.color}
            width={44}
            height={44}
            viewBox="0 0 16 16"
          />
        ),
        description: t('kept_inside'),
      },
      {
        icon: (
          <OutdoorsSVG
            color={colors.greyText.color}
            width={44}
            height={44}
            viewBox="0 0 16 16"
          />
        ),
        description: t('kept_outside'),
      },
      {
        icon: (
          <BothWaysSVG
            color={colors.greyText.color}
            width={44}
            height={44}
            viewBox="0 0 17 17"
          />
        ),
        description: t('both_ways', { type: t(isDoor ? 'door' : 'flap') }),
      },
    ];

    if (lockMode === PetDoorLockingMode.inside_only) {
      return options[0];
    }
    if (lockMode === PetDoorLockingMode.outside_only) {
      return options[1];
    }
    return options[2];
  }, [lockMode]);

  const unlockAction = () => {
    updateLockUnlockRequest(deviceId, { locking: PetDoorLockingMode.normal });
    dismissAction();
  };

  return (
    <SpView style={styles.container}>
      <SpCenter
        style={styles.iconContainer}
        {...testProperties('image', `lockResultSuccessLockType${lockMode}`)}>
        {lockTypeInfo.icon}
      </SpCenter>
      <SpText
        style={[styles.title]}
        {...testProperties('text', `locked`)}>
        {t('locked')}
      </SpText>
      <SpText
        style={[styles.description]}
        {...testProperties('text', `lockDescription`)}>
        {lockTypeInfo.description}
      </SpText>
      <SpView width="100%">
        <SpLockUnlockActions
          buttons={UnlockDoorActions}
          onSelect={unlockAction}
          disabled={disabled}
        />
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  iconContainer: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: 'rgba(38, 58, 67, 0.05)',
    marginTop: 52,
  },
  description: {
    marginBottom: 56,
    marginTop: 8,
  },
  title: {
    marginTop: 16,
  },
});

export default UnlockAction;
