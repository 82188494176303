import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const DietSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}>
    <G
      fill="#263A43"
      fillRule="nonzero">
      <Path d="M7.5 5.333C7.5 2.388 6.156 0 4.5 0s-3 2.388-3 5.333c0 1.282.808 2.368 1.939 2.797l-.272 6.537a1.333 1.333 0 0 0 2.666 0L5.561 8.13A2.993 2.993 0 0 0 7.5 5.333ZM14.5 4.833 14.333 0H13.5l-.167 4.833h-.666L12.5 0h-1l-.167 4.833h-.666L10.5 0h-.833L9.5 4.833c0 1.017.61 1.888 1.482 2.279l-.315 7.555a1.333 1.333 0 0 0 2.666 0l-.315-7.555A2.497 2.497 0 0 0 14.5 4.833Z" />
    </G>
  </Svg>
);
export default DietSVG;
