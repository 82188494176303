import IconButton from 'src/components/IconButton';
import { SpHStack } from 'src/components/SpHStack';
import { SpView } from 'src/components/SpView';
import DashSVG from 'src/components/SvgIcons/DashSVG';
import PawSVG from 'src/components/SvgIcons/PawSVG';
import { usePetDevices } from '@hooks/usePetDevices';
import { usePetQuickActions } from '@hooks/usePetQuickActions';
import { PetModel } from '@models/Pet';
import SpPetSkeletonLoader from 'src/components/SpPetSkeletonLoader';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet } from 'react-native';
import { RootStackParamList } from 'src/pages';
import useBoundStore from 'src/store/store';

import { PetCardHeader } from './components/PetCardHeader';
import { usePetActions } from 'src/pages/Dashboard/hooks/usePetActions';
import usePetDisplayContentLogic from '../../../hooks/usePetDisplayContentLogic';

export const SLIDER_WIDTH = Dimensions.get('window').width + 80;
export const ITEM_WIDTH = Math.min(Math.round(SLIDER_WIDTH * 0.65), 350);
const HEIGHT = Dimensions.get('screen').height;

interface PetCardProps {
  item: PetModel;
  loading: boolean;
  dataRefreshing: boolean;
}

export type AdaptiveSize =
  | {
      fontSizeForNumbers: number;
      fontSizeForText: number;
    }
  | undefined;

const { width } = Dimensions.get('window');
const adaptiveSize: AdaptiveSize = {
  fontSizeForNumbers: Math.min(width / 15, 24),
  fontSizeForText: Math.min(width / 18, 24),
};

export const PetCard = ({ item, loading, dataRefreshing }: PetCardProps) => {
  const pet = item;
  const devices = usePetDevices(pet);
  const petDevicesIds = useMemo(() => {
    return devices.map(item => item.id);
  }, [devices, pet]);
  const { t } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const { setActivePet } = useBoundStore(s => s.petStore);
  const { petActions } = usePetActions(item);
  const { devicesIdsRequestsTrack, loadingControl } = useBoundStore(s => s.deviceStore);

  const handleNavigationToStats = () => {
    setActivePet(pet.id);

    navigate('PetsNavigation', {
      screen: 'PetDashboardSummary',
      params: { id: pet.id },
    });
  };

  const handleModal = (modalOpen: boolean) => () => {
    setIsModalOpen(modalOpen);
  };

  const pendingChecking = useMemo(() => {
    if (!Object?.values(devicesIdsRequestsTrack ?? {})?.length && !dataRefreshing) return false;
    if (petDevicesIds.length > 1) return false;
    for (const id in devicesIdsRequestsTrack) {
      if (devicesIdsRequestsTrack[id]?.length) {
        const res = devicesIdsRequestsTrack[id].find(elem => elem === pet.tag_id);
        if (res) return true;
      }
    }
    return false;
  }, [devicesIdsRequestsTrack, loadingControl, dataRefreshing]);

  const { QuickActionsModal, isModalOpen, setIsModalOpen, allowQuickActions } =
    usePetQuickActions(pet);

  const { displayContentLogic } = usePetDisplayContentLogic(
    pet,
    petActions,
    pendingChecking,
    adaptiveSize,
  );
  const petHeader = useMemo(() => {
    return (
      <PetCardHeader
        petActions={petActions}
        pet={pet}
        pending={pendingChecking}
      />
    );
  }, [petActions, pet, devicesIdsRequestsTrack]);

  return (
    <>
      {(loading && dataRefreshing) || !pet.name || item?.id === 1 || petActions === null ? (
        <SpPetSkeletonLoader />
      ) : (
        <SpView
          borderRadius={24}
          maxWidth={ITEM_WIDTH}
          padding={HEIGHT < 810 ? 10 : 20}
          backgroundColor="#ffffff"
          height="100%"
          justifyContent="space-between"
        >
          <SpView>{petHeader}</SpView>
          <SpView style={styles.boxContainer}>{displayContentLogic}</SpView>
          {!pendingChecking && (
            <SpHStack
              justifyContent={allowQuickActions ? 'space-around' : 'center'}
              flexDirection="row"
              space={3}
              width="100%"
            >
              {allowQuickActions && (
                <IconButton
                  svgIcon={
                    <PawSVG
                      color={colors.primary.color}
                      width={38}
                      height={32}
                      viewBox="0 0 38 32"
                    />
                  }
                  action={handleModal(true)}
                  iconColor={colors.white.color}
                  backgroundColor={colors.primary.color}
                  componentName={`QuickAction${pet.id}`}
                />
              )}
              {petActions && (
                <IconButton
                  action={handleNavigationToStats}
                  iconColor={colors.white.color}
                  backgroundColor={colors.greyText.color}
                  componentName={`Dashboard${pet.id}`}
                  width={!allowQuickActions ? ITEM_WIDTH - 40 : undefined}
                  svgIcon={
                    <DashSVG
                      color={colors.greyText.color}
                      width={50}
                      height={50}
                      viewBox="0 0 50 30"
                    />
                  }
                />
              )}
            </SpHStack>
          )}
        </SpView>
      )}

      {isModalOpen && !loading && QuickActionsModal}
    </>
  );
};

const styles = StyleSheet.create({
  blurer: {
    opacity: 0,
  },
  boxContainer: {
    flex: 1,
    marginVertical: HEIGHT > 720 ? '7%' : '3%',
    justifyContent: 'space-evenly',
    marginTop: 0,
  },
  noDataText: {
    fontSize: 16,
    fontFamily: 'Rubik',
  },
});
