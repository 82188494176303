import { AppImages } from '@constants/AppImages';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import text from '@styles/text';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { SpCarouselWithSteps } from '../../../components/CarouselWithSteps';
import { SpRoundedHeaderButton } from '../../../components/SpRoundedHeaderButton';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from '../../../components/SpView';
import { RootStackParamList } from '../../index';

const FactoryResetCDB = () => {
  const { t } = useTranslation();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [disabled, setDisabled] = useState(true);
  const items = useMemo(() => {
    return [
      {
        text: [t('hold_button_CDB_paragraph_one'), t('cdb_factory_reset_let_go')],
        image: AppImages.resetImgCDBTop,
        imageStyle: { maxWidth: 60, maxHeight: 60 },
      },
      {
        text: [t('repress_button'), t('cdb_factory_reset_complete')],
        image: AppImages.resetImgCDBBottom,
      },
      {
        title: t('having_problems'),
        text: [t('cdb_factory_reset_another_flashing')],
        hint: {
          text: t('cdb_factory_reset_not_now'),
          url: 'www.surepetcare.com/support',
        },
      },
    ];
  }, []);

  const renderCartItem = useCallback(
    (props: {
      dataIndex: number;
      item: {
        title: string;
        text: string[];
        image: ImageSourcePropType;
        imageStyle: object;
        hint: {
          text: string;
          url: string;
        };
      };
    }) => {
      return (
        <SpView
          accessible
          style={styles.item}>
          <View style={styles.iconWrap}>
            <SpText style={styles.itemIndex}>{props.dataIndex + 1}</SpText>
          </View>
          <SpView style={[styles.instructions]}>
            {props.item.title && <SpText style={[text.title]}>{props.item.title}</SpText>}
            {props.item.image && (
              <Image
                accessible
                style={[styles.image, props.item.imageStyle]}
                source={props.item.image}
                resizeMode="contain"
                {...testProperties(`${props.item.text}`, 'image')}
              />
            )}
            {props.item.text &&
              props.item.text.map((paragraph, index) => (
                <SpText
                  key={`ph-${index}`}
                  style={[text.body, styles.text]}
                  size="sm"
                  color={colors.black.color}>
                  {paragraph}
                </SpText>
              ))}
          </SpView>
          {props.item.hint && (
            <SpView style={[styles.hint]}>
              <SpText style={[text.body]}>{props.item.hint.text}</SpText>
              <SpText style={[text.linkText, styles.linkTextBlack]}>{props.item.hint.url}</SpText>
            </SpView>
          )}
        </SpView>
      );
    },
    [],
  );
  return (
    <SafeAreaView
      accessible={false}
      style={styles.screen}
      edges={['top']}>
      <SpView style={[styles.container]}>
        <SpView style={[styles.header]}>
          <SpText style={[text.title]}>{t('title_factory_help')}</SpText>
          <SpText style={[text.body, styles.text]}>{t('cdb_factory_description')}</SpText>
        </SpView>
        <SpCarouselWithSteps
          height="70%"
          renderItem={renderCartItem}
          data={items}
          sliderWidth={Dimensions.get('window').width + 80}
          slideWidth={Dimensions.get('window').width - 80}
          onLastTemplate={i => setDisabled(!!(items.length - i - 1))}
        />
        <SpView style={styles.buttonWrapper}>
          <SpRoundedHeaderButton
            backgroundColor={colors.primary.color}
            h={56}
            title={t('done')}
            disabled={disabled}
            onPress={() => rootNavigation.navigate('DashboardNavigation')}
            stylesForContainer={styles.button}
          />
        </SpView>
      </SpView>
    </SafeAreaView>
  );
};

export default FactoryResetCDB;

const styles = StyleSheet.create({
  screen: {
    height: '100%',
    backgroundColor: colors.white.color,
    flex: 1,
  },
  container: {
    flex: 1,
    marginTop: 80,
    alignItems: 'center',
    maxHeight: '85%',
    paddingHorizontal: 20,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignSelf: 'center',
    width: '100%',
  },
  header: {
    width: '100%',
    justifyContent: 'space-evenly',
    marginBottom: '10%',
  },
  text: {
    marginTop: '5%',
    marginBottom: '5%',
    fontSize: 16,
  },
  image: {
    width: 240,
    height: 140,
  },
  item: {
    flex: 1,
    paddingHorizontal: 18,
    paddingVertical: 30,
    justifyContent: 'space-around',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
    marginVertical: 30,
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingVertical: 10,
  },
  description: {
    fontSize: 14,
    paddingBottom: 20,
    textAlign: 'center',
  },
  button: {
    width: '100%',
    marginBottom: 50,
    marginTop: 'auto',
  },
  buttonWrapper: {
    marginTop: '10%',
    textAlignVertical: 'bottom',
    width: '100%',
  },
  hint: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: colors.greyAccent.color,
    borderRadius: 15,
    marginTop: 'auto',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  instructions: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary.color,
    height: 40,
    width: 40,
    position: 'absolute',
    top: -15,
    left: -15,
    borderRadius: 50,
  },
  itemIndex: {
    top: 2,
    right: 1,
    fontSize: 22,
    color: colors.white.color,
    fontFamily: 'Rubik_Medium',
  },
  linkTextBlack: {
    color: colors.black.color,
    textDecorationLine: 'none',
  },
});
