import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { DeviceModel } from '@models/Device';
import { DeviceCard } from '../../../../../Dashboard/components/DeviceHierarchy/DeviceCard';
import { SpView } from '../../../../../../components/SpView';
import { testProperties } from '@utils/testProperties';
import colors from '@styles/colors';

type SelectDeviceItemDisabledProps = {
  item: DeviceModel;
};

const SelectDeviceItemDisabled = ({ item }: SelectDeviceItemDisabledProps) => {
  return (
    <SpView
      {...testProperties('Cards', 'view')}
      style={[styles.itemContainer]}
      testID={`select-device-item_${item?.id}`}
    >
      <DeviceCard
        data={item}
        isPetFlow
      />
    </SpView>
  );
};

export default memo(SelectDeviceItemDisabled);

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 14,
    marginBottom: 25,
    opacity: 0.5,
  },
});
