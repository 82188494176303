import React, { useMemo } from 'react';
import {
  Platform,
  Pressable,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  View,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { SpView } from 'src/components/SpView';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';
import { SpText } from '../../../components/SpText/SpText';

import { SpDivider } from 'src/components/SpDivider';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import { SpPlusIcon } from 'src/components/SpPlusIcon';
import { HouseholdModel } from '../../../models/Household';
import { HouseholdListItem } from './HouseholdListItem';
import { HouseholdStackParamList } from '..';

interface HouseholdListProps {
  navigationRef: (target: keyof HouseholdStackParamList) => void;
  isHouseholdActive: (household: HouseholdModel) => boolean;
  setActiveHousehold: (household: HouseholdModel) => void;
  joinedHouseholds: HouseholdModel[];
  ownedHouseholds: HouseholdModel[];
  isHouseholdOwned: (household: HouseholdModel) => boolean;
  closeBottomSheetModal?: () => void;
}

export const HouseholdList = ({
  navigationRef,
  isHouseholdActive,
  setActiveHousehold,
  joinedHouseholds,
  ownedHouseholds,
  isHouseholdOwned,
  closeBottomSheetModal,
}: HouseholdListProps) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const households = [
      {
        title: t('my_household'),
        data: [...ownedHouseholds],
      },
    ];
    if (joinedHouseholds.length !== 0) {
      households.push({
        title: t('other_households'),
        data: [...joinedHouseholds],
      });
    }

    return households;
  }, [ownedHouseholds, joinedHouseholds]);

  const renderItem: SectionListRenderItem<HouseholdModel> = ({ item }) => {
    return (
      <HouseholdListItem
        household={item}
        isHouseholdActive={isHouseholdActive(item)}
        isHouseholdOwned={isHouseholdOwned(item)}
        onSelectHousehold={() => setActiveHousehold(item)}
      />
    );
  };

  const renderSectionFooter = () => {
    return (
      <SpView style={styles.joinButton}>
        <SpRoundedHeaderButton
          title={t('join_household')}
          backgroundColor={colors.primary.color}
          textStyles={styles.joinButtonText}
          onPress={() => {
            closeBottomSheetModal();
            navigationRef('JoinHousehold');
          }}
        />
      </SpView>
    );
  };
  const handleCreateHousehold = () => {
    closeBottomSheetModal();
    navigationRef('CreateHousehold');
  };
  const renderSectionHeader = (info: { section: SectionListData<HouseholdModel> }) => {
    if (info.section.title === t('my_household')) {
      if (info.section.data.length === 0) {
        return (
          <Pressable
            onPress={handleCreateHousehold}
            style={styles.createHouseholdButton}>
            <SpView style={styles.createHouseholdButtonContainer}>
              <SpText
                size="xl"
                fontFamily="Rubik_Medium"
                color={colors.greyText.color}
                style={{ opacity: 10 }}>
                {t('create_household')}
              </SpText>
            </SpView>

            <SpView style={styles.plus}>
              <SpPlusIcon color={colors.white.color} />
            </SpView>
            <SpView style={styles.block} />
          </Pressable>
        );
      }
      return (
        <SpView
          flexDirection="row"
          alignItems="center"
          marginBottom={10}>
          <View {...testProperties('faHouseChimney', 'icon')}>
            <FontAwesomeIcon
              icon={faHouseChimney}
              size={16}
            />
          </View>
          <SpText style={styles.labelForOwned}>{info.section.title}</SpText>
        </SpView>
      );
    }
    return (
      <>
        <SpDivider />
        <SpView flexDirection="row">
          <SpText style={styles.labelForJoined}>{info.section.title}</SpText>
        </SpView>
      </>
    );
  };
  const component = (
    <SectionList
      sections={data}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      stickySectionHeadersEnabled={false}
      ListFooterComponent={renderSectionFooter()}
      keyExtractor={item => `${item.id}`}
      style={styles.list}
    />
  );
  return Platform.OS === 'ios' ? (
    <SpView style={styles.container}>{component}</SpView>
  ) : (
    <ScrollView style={styles.container}>{component}</ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: 20,
  },
  list: {
    paddingHorizontal: 20,
  },
  joinButton: {
    marginBottom: 60,
    marginTop: 10,
  },
  joinButtonText: {
    fontSize: 16,
    color: colors.white.color,
    fontFamily: 'Rubik_Medium',
  },
  createHouseholdButtonContainer: {
    borderRadius: 16,
    flex: 1,
    height: 72,
    backgroundColor: 'rgba(45, 181, 142, 0.2)',
    paddingLeft: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelForOwned: {
    marginLeft: 10,
    fontSize: 14,
    fontFamily: 'Rubik_Medium',
  },
  labelForJoined: {
    marginVertical: 15,
    fontFamily: 'Rubik_Medium',

    fontSize: 14,
  },
  createHouseholdSmallButton: {
    height: 50,
    borderRadius: 100,
    width: '90%',
    marginVertical: 10,
  },
  createHouseholdSmallButtonPlus: {
    position: 'relative',
    top: 15,
    left: -15,
  },
  createHouseholdButton: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: 32,
  },
  plus: {
    width: 44,
    height: 44,
    backgroundColor: colors.toxicGreen.color,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 22,
    position: 'absolute',
    right: 0,
  },
  block: {
    width: 22,
    height: '100%',
  },
});
