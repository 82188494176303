import colors from '@styles/colors';
import { addMinutes, startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import CalendarStrip, { IDayComponentProps } from 'react-native-calendar-strip';
import useBoundStore from 'src/store/store';
import { TimeService } from 'src/services/TimeService';
import { testProperties } from '@utils/testProperties';

interface PetDashboardSummaryDateCarouselProps {
  selectedDate: string;
  petInsightsLoaded: boolean;
  dateRange: number[];
  setSelectedDate: (data: number) => void;
  warningDates?: string[];
  allowInsights?: boolean;
}

const DateCarousel = ({
  selectedDate,
  petInsightsLoaded,
  dateRange,
  setSelectedDate,
  warningDates,
  allowInsights = false,
}: PetDashboardSummaryDateCarouselProps) => {
  const [dateValue, setDateValue] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const calendarStripRef = useRef(null);
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);

  const newStartingDate = useMemo(() => {
    return new Date(
      zonedTimeToUtc(
        addMinutes(
          startOfDay(utcToZonedTime(selectedDate, activeHousehold.timezone.timezone)),
          activeHousehold.timezone.utc_offset / 60,
        ),
        activeHousehold.timezone.timezone,
      ),
    );
  }, []);

  useEffect(() => {
    setStartingDate(newStartingDate);
  }, []);

  useEffect(() => {
    setStartingDate(newStartingDate);
    setDateValue(
      new Date(
        zonedTimeToUtc(
          addMinutes(
            startOfDay(utcToZonedTime(selectedDate, activeHousehold.timezone.timezone)),
            activeHousehold.timezone.utc_offset / 60,
          ),
          activeHousehold.timezone.timezone,
        ),
      ),
    );
  }, [selectedDate]);

  useEffect(() => {
    if (calendarStripRef !== null) {
      calendarStripRef.current.state.selectedDate = new Date(
        zonedTimeToUtc(
          addMinutes(
            startOfDay(utcToZonedTime(selectedDate, activeHousehold.timezone.timezone)),
            activeHousehold.timezone.utc_offset / 60,
          ),
          activeHousehold.timezone.timezone,
        ),
      );
    }
  }, [dateValue, selectedDate]);

  const handleOnDateSelected = dateObj => {
    setSelectedDate(dateObj.valueOf());
    setDateValue(new Date(dateObj.valueOf()));
  };

  const dayComponent = useCallback(
    (props: IDayComponentProps) => {
      const dayObj = TimeService.parseDate(props.date.toJSON());
      const dateToFormat = TimeService?.toLocal(dayObj);
      const dateNum = dateToFormat?.toFormat('d');
      const dayShortName = dayObj?.toFormat('ccc').slice(0, 2);
      const isActive = dateToFormat?.toFormat('yyyy-MM-dd') === selectedDate;
      const isWarning = warningDates.includes(dateToFormat?.toFormat('yyyy-MM-dd'));
      const isWarningActive = isActive && isWarning && allowInsights;

      return (
        <TouchableOpacity
          {...testProperties(
            `${dateNum}-${dayShortName}`,
            `${isActive ? 'Active' : 'nonActive'}`,
            'CalendarDay',
          )}
          onPress={() => {
            setSelectedDate(dayObj.valueOf());
            setDateValue(dayObj);
          }}
          hitSlop={{ left: 3, right: 3, top: 5, bottom: 5 }}
          style={[
            styles.dayComponentContainer,
            {
              backgroundColor:
                isActive && petInsightsLoaded ? colors.secondary.color : 'transparent',
            },
            isWarningActive &&
              petInsightsLoaded && {
                backgroundColor: colors.orangeWarning.color,
              },
          ]}
        >
          <View
            style={[
              styles.dayComponentNumWrap,
              {
                borderWidth: isWarning && petInsightsLoaded ? 2 : 0,
                width: isWarning ? 32 : 30,
                height: isWarning ? 32 : 30,
                marginTop: isWarning ? 0 : 1,
              },
              isWarningActive && { borderWidth: 0 },
            ]}
          >
            <Text style={[styles.fontSize16, { color: colors.greyText.color }]}>{dateNum}</Text>
          </View>
          <Text
            style={[
              styles.fontSize16,
              {
                color: isActive && petInsightsLoaded ? colors.white.color : colors.greyText.color,
              },
            ]}
          >
            {dayShortName}
          </Text>
        </TouchableOpacity>
      );
    },
    [warningDates, selectedDate],
  );

  return (
    <CalendarStrip
      accessible={false}
      ref={calendarStripRef}
      scrollable
      style={styles.container}
      calendarColor={colors.white.color}
      showMonth={false}
      maxDayComponentSize={72}
      dayComponentHeight={72}
      responsiveSizingOffset={-5.7}
      dayComponent={dayComponent}
      showDayName={false}
      leftSelector={[]}
      rightSelector={[]}
      minDate={new Date(dateRange[0])}
      maxDate={
        new Date(
          zonedTimeToUtc(
            addMinutes(
              startOfDay(utcToZonedTime(dateRange[1], activeHousehold.timezone.timezone)),
              activeHousehold.timezone.utc_offset / 60,
            ),
            activeHousehold.timezone.timezone,
          ),
        )
      }
      startingDate={startingDate}
      scrollToOnSetSelectedDate
      onDateSelected={handleOnDateSelected}
    />
  );
};

export default DateCarousel;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  },
  dayComponentContainer: {
    height: 72,
    width: 45,
    borderRadius: 35,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 4,
    paddingBottom: 8,
    marginLeft: 23,
  },
  dayComponentNumWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white.color,
    borderRadius: 50,
    borderColor: colors.orangeWarning.color,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 8,
  },
  fontSize16: {
    fontSize: 16,
  },
});
