import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import atoms from '@styles/atoms';
import colors from '@styles/colors';
import { capitalize } from '@utils/capitalize';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Pressable, ViewStyle } from 'react-native';
import SelectOptionWhite from 'src/pages/Dashboard/Pets/pages/PetDashboardReport/components/SelectOptionWhite';
import { ReportName, ReportType, ReportTypeIcon } from 'src/pages/Dashboard/constants/ReportType';

import { SpText } from './SpText/SpText';
import { SpVStack } from './SpVStack';
import { SpView } from './SpView';

type ActivityChangerProps = {
  selectedType: ReportType;
  availiableTypes: ReportType[];
  onChange: (type: ReportType) => void;
  style?: ViewStyle;
  disabled?: boolean;
};

const ActivityChanger: React.FC<ActivityChangerProps> = ({
  availiableTypes,
  selectedType,
  onChange,
  style,
  disabled = false,
}) => {
  const [modalShown, setModalShown] = useState(false);
  const isMultipleTypes = !disabled && availiableTypes.length > 1;

  const onListTrigger = useCallback(() => {
    if (availiableTypes.length > 1) {
      setModalShown(!modalShown);
    }
  }, [setModalShown, modalShown, availiableTypes]);

  return (
    <>
      <Pressable
        style={[
          styles.typeWrap,
          isMultipleTypes ? styles.typeWrapMultiple : null,
          disabled && atoms.disabledButton,
          style,
        ]}
        onPress={onListTrigger}
        disabled={disabled}>
        <SpView style={styles.typeIcon}>{ReportTypeIcon[selectedType]}</SpView>

        <SpText style={styles.type}>{ReportName[selectedType].toUpperCase()}</SpText>
        {isMultipleTypes && (
          <FontAwesomeIcon
            style={styles.caretDownIcon}
            size={20}
            icon={faCaretDown}
          />
        )}
      </Pressable>
      {modalShown && (
        <SpView style={styles.highZIndex}>
          <SpView style={styles.modalContent}>
            <SpVStack space={4}>
              {availiableTypes.map(item => {
                return (
                  <SelectOptionWhite
                    key={item}
                    item={{ id: item, name: capitalize(ReportName[item]) }}
                    active={item === selectedType}
                    onChange={() => {
                      onChange(item);
                      setModalShown(false);
                    }}
                  />
                );
              })}
            </SpVStack>
          </SpView>
        </SpView>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  typeWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderColor: 'transparent',
    borderWidth: 1,
    borderRadius: 18,
    alignSelf: 'center',
  },
  typeWrapMultiple: {
    borderColor: colors.primary.color,
    backgroundColor: colors.primaryBackgroundOpacity.color,
  },
  typeIcon: {
    marginRight: 10,
  },
  caretDownIcon: {
    marginLeft: 10,
  },
  type: {
    fontWeight: '500',
    letterSpacing: 2,
    color: '#263a43',
    fontFamily: 'Rubik_Medium',
  },
  modalContent: {
    backgroundColor: colors.greyText.color,
    borderRadius: 10,
    justifyContent: 'center',
    paddingHorizontal: 20,
    marginTop: 10,
    paddingVertical: 15,
    position: 'absolute',
  },
  highZIndex: {
    zIndex: 10000,
  },
});

export default ActivityChanger;
