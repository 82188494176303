import * as React from 'react';
import Svg, { SvgProps, Defs, Circle, G, Mask, Use, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const PetPlaceholderSVG = (props: SvgProps) => (
  <Svg
    width={100}
    height={100}
    {...props}>
    <Defs>
      <Circle
        id="a"
        cx={60}
        cy={60}
        r={60}
      />
    </Defs>
    <G
      fill="none"
      fillRule="evenodd">
      <Mask
        id="b"
        fill="#fff">
        <Use xlinkHref="#a" />
      </Mask>
      <Use
        xlinkHref="#a"
        fill="#E9EBEC"
      />
      <Path
        fill="#263A43"
        fillRule="nonzero"
        d="M99.444 61.776c-.795 2.862-2.385 5.342-4.474 6.98-1.669 1.312-3.563 1.998-5.426 1.998a7.409 7.409 0 0 1-2.066-.291c-2.463-.719-4.459-2.713-5.472-5.474-.927-2.516-.99-5.477-.195-8.34.803-2.86 2.393-5.34 4.475-6.98 2.292-1.8 5.02-2.423 7.49-1.706 2.464.719 4.468 2.713 5.481 5.474.92 2.516.99 5.477.187 8.34Zm-52.119-7.232c2.931 0 5.73-1.664 7.678-4.565 1.77-2.632 2.744-6.078 2.744-9.706 0-3.631-.974-7.078-2.744-9.709-1.949-2.9-4.747-4.564-7.678-4.564-2.939 0-5.737 1.663-7.686 4.564-1.762 2.631-2.736 6.078-2.736 9.709 0 3.628.974 7.074 2.736 9.706 1.949 2.901 4.747 4.565 7.686 4.565Zm25.35 0c2.94 0 5.737-1.664 7.686-4.565 1.762-2.632 2.736-6.078 2.736-9.706 0-3.631-.974-7.078-2.736-9.709-1.949-2.9-4.747-4.564-7.686-4.564-2.93 0-5.73 1.663-7.678 4.564-1.77 2.631-2.744 6.078-2.744 9.709 0 3.628.974 7.074 2.744 9.706 1.949 2.901 4.747 4.565 7.678 4.565ZM38.19 56.652c-.803-2.863-2.393-5.343-4.475-6.983-2.291-1.8-5.02-2.423-7.49-1.706-2.472.719-4.467 2.713-5.48 5.474-.92 2.516-.99 5.477-.188 8.34.795 2.86 2.385 5.34 4.474 6.98 1.669 1.311 3.563 1.997 5.426 1.997.694 0 1.387-.096 2.058-.291 2.47-.718 4.466-2.713 5.48-5.473.928-2.517.99-5.478.195-8.338Zm37.105 6.977c-4.272-4.577-9.697-7.096-15.294-7.096-5.597 0-11.03 2.52-15.286 7.096-4.07 4.365-6.907 10.422-7.998 17.058-.694 4.232.569 8.306 3.476 11.176 3.025 2.986 6.977 3.893 10.843 2.491A26.137 26.137 0 0 1 60 92.785c3.087 0 6.104.528 8.965 1.569a10.46 10.46 0 0 0 3.57.646c2.642 0 5.176-1.071 7.265-3.137 2.915-2.87 4.178-6.943 3.484-11.176-1.09-6.636-3.928-12.693-7.99-17.058Z"
        mask="url(#b)"
      />
    </G>
  </Svg>
);
export default PetPlaceholderSVG;
