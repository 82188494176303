import React, { memo } from 'react';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpCenter } from '../../../../../../components/SpCenter';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';

type DoItLaterProps = {
  onPress: () => void;
};

const DoItLater = ({ onPress }: DoItLaterProps) => {
  const { t } = useTranslation();
  return (
    <SpCenter>
      <SpText
        style={styles.link}
        onPress={() => onPress?.()}
        testID={'do-it-later'}
      >
        {t('do_it_later')}
      </SpText>
    </SpCenter>
  );
};

export default memo(DoItLater);

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
    marginLeft: 50,
  },
});
