import { testProperties } from '@utils/testProperties';
import React, { PropsWithChildren, useMemo } from 'react';
import {
  GestureResponderEvent,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextLayoutEventData,
} from 'react-native';

export interface SpTextProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  align?: 'center' | 'left' | 'right';
  bold?: boolean;
  color?: string;
  style?: StyleProp<unknown>;
  numberOfLines?: number;
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip' | undefined;
  fontFamily?: string;
  componentName?: string;
  onPress?: (data: GestureResponderEvent) => void;
  suppressHighlighting?: boolean;
  accessible?: boolean;
  accessibilityLabel?: string;
  testID?: string;
  onTextLayout?: (eve: NativeSyntheticEvent<TextLayoutEventData>) => void;
  adjustsFontSizeToFit?: boolean;
}

export const SpText = ({
  children,
  size = 'md',
  align = 'left',
  style,
  color = '#000000',
  numberOfLines,
  ellipsizeMode,
  bold = false,
  fontFamily = 'Rubik',
  componentName,
  suppressHighlighting = true,
  ...props
}: PropsWithChildren<SpTextProps>) => {
  const textStyle = useMemo(() => {
    let basic = StyleSheet.flatten([sizes[size], aligns[align] as any]);

    if (bold) {
      basic = StyleSheet.flatten([
        basic,
        {
          fontWeight: '600',
        },
      ]);
    }

    if (color) {
      basic = StyleSheet.flatten([
        basic,
        {
          color,
        },
      ]);
    }

    if (fontFamily) {
      basic = StyleSheet.flatten([
        basic,
        {
          fontFamily,
        },
      ]);
    }

    return style ? StyleSheet.flatten([basic, style]) : basic;
  }, [size, align, style, color, bold]);

  return (
    <Text
      {...testProperties(
        typeof children === 'string' ? (children as string) : '',
        'text',
        componentName,
      )}
      style={textStyle}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
      textBreakStrategy="simple"
      suppressHighlighting={suppressHighlighting}
      {...props}>
      {children}
    </Text>
  );
};

const sizes = StyleSheet.create({
  xs: {
    fontSize: 12,
    lineHeight: 18,
  },
  sm: {
    fontSize: 14,
    lineHeight: 20,
  },
  md: {
    fontSize: 16,
    lineHeight: 22,
  },
  lg: {
    fontSize: 18,
    lineHeight: 24,
  },
  xl: {
    fontSize: 20,
    lineHeight: 26,
  },
  xxl: {
    fontSize: 24,
    lineHeight: 30,
  },
});

const aligns = StyleSheet.create({
  left: { textAlign: 'left' },
  center: { textAlign: 'center' },
  right: { textAlign: 'right' },
});
