import { TouchableOpacity, StyleSheet } from 'react-native';
import React from 'react';
import colors from '@styles/colors';
import { SpText } from './SpText/SpText';
import { testProperties } from '@utils/testProperties';

type DestructiveButtonProps = {
  onPress: () => void;
  text: string;
};

const DestructiveButton = ({ text, onPress }: DestructiveButtonProps) => {
  return (
    <TouchableOpacity
      {...testProperties(text, 'destructive button')}
      onPress={onPress}
      style={styles.deleteButton}>
      <SpText
        style={styles.deleteText}
        color={colors.white.color}>
        {text}
      </SpText>
    </TouchableOpacity>
  );
};

export default DestructiveButton;

const styles = StyleSheet.create({
  deleteButton: {
    backgroundColor: colors.errorRed.color,
    paddingHorizontal: 32,
    paddingVertical: 20,
    borderRadius: 14,
    marginLeft: 16,
  },
  deleteText: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
  },
});
