type IKey = 'oz' | 'lb' | 'kg';

type IUnitsObject = {
  [key in IKey]: {
    operation: 'multiply' | 'divide';
    value: number;
  };
};

const unitsObject: IUnitsObject = {
  oz: {
    operation: 'multiply',
    value: 28.35,
  },
  lb: {
    operation: 'multiply',
    value: 453.6,
  },

  kg: {
    operation: 'multiply',
    value: 10000,
  },
};

export const convertToGrams = (weight: number, from: IKey, reverse = false, toFixed = 4) => {
  if (!weight) return 0;
  if (unitsObject?.[from] && reverse === false) {
    return unitsObject[from].operation === 'multiply'
      ? Number((weight * unitsObject[from].value).toFixed(toFixed))
      : Number((weight / unitsObject[from].value).toFixed(toFixed));
  }
  if (unitsObject?.[from] && reverse === true) {
    return unitsObject[from].operation === 'multiply'
      ? Number((weight / unitsObject[from].value).toFixed(toFixed))
      : Number((weight * unitsObject[from].value).toFixed(toFixed));
  }
  return Number(weight.toFixed(toFixed));
};
