import { StyleSheet, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../../../../components/SpText/SpText';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import useBoundStore from 'src/store/store';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import CustomKeyboardAvoidingView from 'src/components/CustomKeyboardAvoidingView';
import { BowlOptions } from '../../../../../Flows/StepForms/Devices/AddFeeder';
import PortionAmountForm from '../../../../../Flows/Feeder/PortionAmountForm';
import { DeviceStackParamList } from '../..';

type Props = NativeStackScreenProps<DeviceStackParamList, 'DeviceSetPortioning'>;

const DeviceSetPortioning = ({ route }: Props) => {
  const { type, settings } = route.params.bowlData;
  const { onChangeTarget, isLeft } = route.params;
  const isBowlSingle = type === 1;
  const { t } = useTranslation();

  const { loadingDevice } = useBoundStore(s => s.deviceStore);

  const obj = (str: string) => ({
    single: settings[0]?.[str] || '',
    left: settings[0]?.[str] || '',
    right: settings[1]?.[str] || '',
  });
  const [weight, setWeight] = useState<BowlOptions>(obj('target'));
  const foodType = obj('food_type');
  const [isWeightChanged, setIsWeightChanged] = useState(false);
  const [weightError, setWeightError] = useState({
    single: null,
    left: null,
    right: null,
  });
  const isSingleBowlError = isBowlSingle
    ? !!weightError.single
    : !!weightError.right || !!weightError.left;
  useEffect(() => {
    const isSingleChanged = weight.single !== obj('target').single;
    const isLeftChanged = weight.left !== obj('target').left;
    const isRightChanged = weight.right !== obj('target').right;
    setIsWeightChanged(isBowlSingle ? isSingleChanged : isLeftChanged || isRightChanged);
  }, [weight, obj, isBowlSingle]);

  const portionFormCreator = (left?: boolean) => {
    let appendName = '';
    if (isBowlSingle) {
      appendName = 'single';
    } else {
      appendName = isLeft ? 'Left' : 'Right';
    }
    return (
      <PortionAmountForm
        bowlFoodType={foodType}
        weight={weight}
        setWeight={setWeight}
        weightError={weightError}
        setWeightError={setWeightError}
        isBowlSingle={isBowlSingle}
        isLeft={left}
        componentName={`DeviceSetPortioning_${appendName}`}
      />
    );
  };

  return (
    <CustomKeyboardAvoidingView>
      <View style={styles.container}>
        <SpTitle
          align="center"
          text={t('portion_amount')}
        />
        <SpText
          style={{ marginBottom: 44 }}
          align="center">
          {t('portion_amount_subtitle')}
        </SpText>
        {isBowlSingle ? (
          portionFormCreator()
        ) : (
          <View>{isLeft ? portionFormCreator(true) : portionFormCreator()}</View>
        )}
        <SpRoundedHeaderButton
          backgroundColor={colors.primary.color}
          h={56}
          title={t('save')}
          disabled={isSingleBowlError || !isWeightChanged}
          onPress={() => {
            return isBowlSingle
              ? onChangeTarget([Number(weight.single)])
              : onChangeTarget([Number(weight.left), Number(weight.right)]);
          }}
          isLoading={loadingDevice}
          stylesForContainer={styles.button}
        />
      </View>
    </CustomKeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 19,
    backgroundColor: colors.white.color,
    flex: 1,
  },
  button: {
    marginBottom: 67,
    marginTop: 'auto',
  },
});

export default DeviceSetPortioning;
