/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Animated, Pressable, StyleSheet, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheckCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SpView } from 'src/components/SpView';
import colors from '@styles/colors';
import { GestureHandlerRootView, Swipeable } from 'react-native-gesture-handler';
import { useTranslation } from 'react-i18next';
import useBoundStore from 'src/store/store';
import SpAlert from '@utils/alert';
import DestructiveButton from 'src/components/DestructiveButton';
import { testProperties } from '@utils/testProperties';
import { SpText } from '../../../components/SpText/SpText';
import { useCustomToast } from '@hooks/useToast';
import { CommonActions } from '@react-navigation/routers';
import { navigationRef } from 'src/pages/RootNavigation';
import { HouseholdModel } from '../../../models/Household';

interface HouseholdListItemProps {
  household: HouseholdModel;
  isHouseholdActive: boolean;
  isHouseholdOwned: boolean;
  onSelectHousehold: () => void;
}

export const HouseholdListItem = ({
  household,
  onSelectHousehold,
  isHouseholdActive,
  isHouseholdOwned,
}: HouseholdListItemProps) => {
  const { t } = useTranslation();
  const { deleteUserFromHousehold } = useBoundStore(state => state.householdUserStore);
  const { show } = useCustomToast();
  const { user } = useBoundStore(state => state.accountStore);

  const showOptions = () => {
    SpAlert(t('leave_household'), t('leave_household_message'), [
      {
        text: t('cancel'),
        onPress: () => console.log('cancel'),
      },
      {
        text: t('leave'),
        onPress: async () => {
          await deleteUserFromHousehold(user.id, household.id);
          show({ description: t('left_household') });
          navigationRef.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: 'DashboardNavigation', params: { screen: 'Pets' } }],
            }),
          );
        },
        style: 'destructive',
      },
    ]);
  };

  const renderRightActions = (
    progress: Animated.AnimatedInterpolation<any>,
    dragAnimatedValue: Animated.AnimatedInterpolation<any>,
  ) => {
    const opacity = dragAnimatedValue.interpolate({
      inputRange: [-35, 0],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    });
    return (
      <Animated.View style={[{ opacity }]}>
        <DestructiveButton
          onPress={showOptions}
          text={t('leave')}
        />
      </Animated.View>
    );
  };

  if (isHouseholdOwned) {
    return (
      <SpView style={styles.container}>
        <Pressable
          onPress={onSelectHousehold}
          style={[styles.householdButton, styles.householdButtonOwned]}
          {...testProperties(household.name, 'button')}
          accessible={false}>
          <SpView
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <SpText
              style={[
                styles.label,
                isHouseholdOwned ? styles.labelHouseholdOwned : styles.labelHouseholdNotOwned,
              ]}>
              {household.name}
            </SpText>
            <View
              {...testProperties(
                isHouseholdActive ? 'faCheckCircle' : `faChevronRight`, //////
                `icon_${household.name}`,
              )}>
              <FontAwesomeIcon
                style={styles.icon}
                color={colors.white.color}
                size={25}
                icon={isHouseholdActive ? faCheckCircle : faChevronRight}
              />
            </View>
          </SpView>
        </Pressable>
      </SpView>
    );
  }

  return (
    <GestureHandlerRootView>
      <Swipeable renderRightActions={renderRightActions}>
        <SpView style={styles.container}>
          <Pressable
            onPress={onSelectHousehold}
            style={[
              styles.householdButton,
              styles.householdButtonNotOwned,
              isHouseholdActive ? styles.buttonActive : null,
            ]}
            {...testProperties(household.name, 'button')}
            accessible={false}>
            <SpView
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center">
              <SpText
                style={[
                  styles.label,
                  isHouseholdOwned ? styles.labelHouseholdOwned : styles.labelHouseholdNotOwned,
                ]}>
                {household.name}
              </SpText>
              <View
                {...testProperties(
                  isHouseholdActive ? 'faCheckCircle' : `faChevronRight`,
                  `icon_${household.name}`,
                )}>
                <FontAwesomeIcon
                  style={styles.icon}
                  color={isHouseholdActive ? colors.primary.color : colors.greyText.color}
                  size={25}
                  icon={isHouseholdActive ? faCheckCircle : faChevronRight}
                />
              </View>
            </SpView>
          </Pressable>
        </SpView>
      </Swipeable>
    </GestureHandlerRootView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  icon: {
    marginRight: 24,
    marginLeft: 'auto',
  },
  label: {
    marginLeft: 24,
  },
  labelHouseholdOwned: {
    fontFamily: 'Rubik_Medium',
    fontSize: 20,
    color: colors.white.color,
  },
  labelHouseholdNotOwned: {
    color: colors.greyText.color,
    fontFamily: 'Rubik',
    fontSize: 16,
  },
  householdButton: {
    flex: 1,

    borderRadius: 14,
  },
  buttonActive: {
    backgroundColor: colors.primaryBackgroundOpacity.color,
    borderColor: colors.primary.color,
  },
  householdButtonOwned: {
    backgroundColor: colors.greyText.color,
    paddingVertical: 22,
  },
  householdButtonNotOwned: {
    backgroundColor: colors.white.color,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.greyBorder.color,
    paddingVertical: 16,
  },
  leaveButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4,
  },
});
