import { SpView } from 'src/components/SpView';
import { DeviceFeederBowlModel } from '@models/Device';
import React from 'react';
import { StyleSheet } from 'react-native';
import { SpText } from '../../../../../../components/SpText/SpText';
import { FeederFoodType } from '@constants/Device';
import { useTranslation } from 'react-i18next';
import { BowlTypeSide } from './BowlTypeSide';
import { RemainingFood } from './RemainingFood';
import { BowlType } from './BowlType';

interface BowlDualProps {
  data: DeviceFeederBowlModel;
  percentages: number[];
}

export const BowlDual = ({ data, percentages }: BowlDualProps) => {
  const { t } = useTranslation();

  return (
    <SpView
      flexDirection="row"
      marginHorizontal={-15}>
      <>
        <SpView style={styles.card}>
          {data?.settings[0] && (
            <BowlType
              type="left"
              data={data?.settings[0]}
              percentage={percentages[0]}
            />
          )}
        </SpView>
        <SpView style={[styles.label, { transform: [{ translateX: -70 }] }]}>
          <RemainingFood />
        </SpView>
        <SpView style={styles.card}>
          {data?.settings[1] && (
            <BowlType
              type="right"
              data={data?.settings[1]}
              percentage={percentages[1]}
            />
          )}
        </SpView>
      </>
    </SpView>
  );
};

const styles = StyleSheet.create({
  card: {
    width: '50%',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  portionLabel: {
    flex: 1,
    alignItems: 'center',
    marginTop: 10,
  },
  label: {
    position: 'absolute',
    top: 45,
    left: '50%',
  },
});
