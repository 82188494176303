import { usePetQuickActions } from '@hooks/usePetQuickActions';
import { PetModel } from '@models/Pet';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';

import useBoundStore from '../../../../../store/store';
import { RootStackParamList } from '../../../../index';
import { useCheckPending } from '../../../hooks/useCheckPending';
import { usePetActions } from '../../../hooks/usePetActions';
import usePetDisplayContentLogic from '../../../hooks/usePetDisplayContentLogic';
import { PetCardHeader } from '../../../Pets/pages/PetCarousel/components/PetCardHeader';
import { SpView } from '../../../../../components/SpView';

export type AdaptiveSize =
  | {
      fontSizeForNumbers: number;
      fontSizeForText: number;
    }
  | undefined;

const { width } = Dimensions.get('window');
const adaptiveSize: AdaptiveSize = {
  fontSizeForNumbers: Math.min(width / 15, 24),
  fontSizeForText: Math.min(width / 18, 24),
};

interface Pet2UpCardProps {
  pet: PetModel;
}

export const Pet2UpCard = (props: Pet2UpCardProps) => {
  const { petActions } = usePetActions(props.pet);
  const { allowQuickActions } = usePetQuickActions(props.pet);

  const { pendingChecking } = useCheckPending(props.pet);
  const { displayContentLogic } = usePetDisplayContentLogic(
    props.pet,
    petActions,
    pendingChecking,
    adaptiveSize,
    'compact',
  );
  const { devicesIdsRequestsTrack } = useBoundStore(s => s.deviceStore);
  const petHeader = useMemo(() => {
    return (
      <PetCardHeader
        petActions={petActions}
        pet={props.pet}
        pending={pendingChecking}
        showQuickActions={allowQuickActions}
      />
    );
  }, [petActions, props.pet, devicesIdsRequestsTrack]);
  const { setActivePet } = useBoundStore(s => s.petStore);
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const handleNavigationToStats = () => {
    setActivePet(props.pet.id);

    navigate('PetsNavigation', {
      screen: 'PetDashboardSummary',
      params: { id: props.pet.id },
    });
  };
  return (
    <SpView style={styles.item}>
      <TouchableOpacity
        disabled={!petActions}
        onPress={handleNavigationToStats}
        style={{ width: '100%' }}
      >
        <SpView style={{ height: '100%', width: '100%', padding: 12 }}>
          <SpView style={{ alignItems: 'center', height: '50%', justifyContent: 'center' }}>
            {petHeader}
          </SpView>
          <SpView
            style={{
              alignItems: 'center',
              width: '100%',
              height: '50%',

              justifyContent: 'center',
              paddingTop: 5,
            }}
          >
            {displayContentLogic}
          </SpView>
        </SpView>
      </TouchableOpacity>
    </SpView>
  );
};

const styles = StyleSheet.create({
  item: {
    flex: 1, // Takes up 1/2 of the space for 2 columns
    margin: Dimensions.get('screen').height < 700 ? 10 : 12,
    padding: Dimensions.get('screen').height < 700 ? 5 : 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 24,
  },
});
