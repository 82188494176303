import { HouseholdUserPermissionLevel } from '@constants/HouseholdUserPermissionLevel';
import i18n from './i18n';

export const permissionLevelOptions = [
  {
    title: i18n.t('admin'),
    subtitle: 'permission_options_admin_subtitle',
    id: HouseholdUserPermissionLevel.Admin,
  },
  {
    title: i18n.t('controller'),
    subtitle: 'permission_options_controller_subtitle',
    id: HouseholdUserPermissionLevel.Controller,
  },
  {
    title: i18n.t('viewer'),
    subtitle: 'permission_options_viewer_subtitle',
    id: HouseholdUserPermissionLevel.Viewer,
  },
];
export const userPermissionLevelDefiner = (
  owner: boolean,
  write: boolean,
): HouseholdUserPermissionLevel => {
  if (owner && write) {
    return HouseholdUserPermissionLevel.Admin;
  }

  if (write && !owner) {
    return HouseholdUserPermissionLevel.Controller;
  }

  if (!owner && !write) {
    return HouseholdUserPermissionLevel.Viewer;
  }

  return null;
};

export const userPermissionLevelDefinerReverse = (id: HouseholdUserPermissionLevel) => {
  switch (Number(id)) {
    case HouseholdUserPermissionLevel.Admin:
      return { owner: true, write: true };
    case HouseholdUserPermissionLevel.Controller:
      return { owner: false, write: true };
    case HouseholdUserPermissionLevel.Viewer:
      return { owner: false, write: false };
  }
  return null;
};

export type PermissionSelectOption = {
  id: number;
  title: string;
  subtitle?: string;
};
