import { Image, Platform, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import { useTranslation } from 'react-i18next';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import text from '@styles/text';

export const LocalDevicePermission = () => {
  const { t } = useTranslation();
  return (
    <SpView style={[styles.center, Platform.OS === 'android' ? styles.androidPaddingBottom : {}]}>
      <SpText style={[text.title, styles.biffetText]}>
        {t('lets_make_sure_the_sure_petcare_app_can_find_devic')}
      </SpText>
      <Image
        style={styles.imageAddCerberusStep1}
        source={AppImages.appPermissionGraphic}
      />

      <SpView style={[styles.roundedGreyInfo]}>
        <SpText style={text.body}>{t('please_continue_and_if_asked_allow_the_app_to_find')}</SpText>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
  },
  imageAddCerberusStep1: {
    width: '90%',
    height: 130,
    marginTop: 32,
    marginBottom: 32,
    resizeMode: 'contain',
  },
  roundedGreyInfo: {
    backgroundColor: '#EEE',
    borderRadius: 10,
    padding: 20,
    // width: '90%',
    // height: 150,
    // flexDirection: 'row',
    // paddingLeft: 10,
    // marginTop: 10,
  },
});

export default LocalDevicePermission;
