import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import i18n from '@utils/i18n';
import React, { useMemo } from 'react';
import { SpMenuStack } from 'src/components/SpMenuStack';
import PreferencesSVG from 'src/components/SvgIcons/PreferencesSVG';
import colors from '@styles/colors';
import { MenuItem } from '@models/Menu';
import { DeviceStackParamList } from '../index';

interface DeviceSettingMenuItemProps {
  data: number;
}

export const DeviceSettingMenuItem = ({ data }: DeviceSettingMenuItemProps) => {
  const navigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const menuItems = useMemo((): MenuItem[] => {
    return [
      {
        label: i18n.t('settings'),
        iconSVG: (
          <PreferencesSVG
            color={colors.greyText.color}
            width={20}
            height={20}
            viewBox="0 0 16 16"
          />
        ),
        action: () => navigation.dispatch(StackActions.push('DeviceSetting', { id: data })),
      },
    ];
  }, [data]);

  return <SpMenuStack buttons={menuItems} />;
};
