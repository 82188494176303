import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import colors from '@styles/colors';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { SpButton } from 'src/components/SpButton';
import { RootStackParamList } from '../../../index';

type CDBHelpPairingModeProps = {};

const CDBHelpPairingMode: React.FC<CDBHelpPairingModeProps> = () => {
  const { t } = useTranslation();
  
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const handleNavigation = (uri: string) => {
    return () => {
      navigation.navigate('WebviewScreen', {
        uri: `https://${uri}`,
        title: 'Support',
      });
    };
  };

  return (
    <SpView style={styles.container}>
      <ScrollView>
      <SpText
        color="#fff"
        size="sm"
        style={styles.question}>
        {t('setup_cdb_pairing_help')}
      </SpText>
      <SpText
        color="#fff"
        size="sm"
        style={styles.paragraph}>
        {t('setup_cdb_pairing_paragraph_1')}
      </SpText>
      <SpView>
        <SpText
          color="#fff"
          bold
          style={styles.title}>
          {t('setup_cdb_pairing_title_1')}
        </SpText>
        <SpText
          color="#fff"
          size="sm"
          style={styles.paragraphDesc}>
          {t('setup_cdb_pairing_description_1')}
        </SpText>
      </SpView>
      <SpView>
        <SpText
          color="#fff"
          bold
          style={styles.title}>
          {t('setup_cdb_pairing_title_2')}
        </SpText>
        <SpText
          color="#fff"
          size="sm"
          style={styles.paragraphDesc}>
          {t('setup_cdb_pairing_description_2')}
        </SpText>
      </SpView>
      <SpView>
        <SpText
          color="#fff"
          bold
          style={styles.title}>
          {t('setup_cdb_pairing_title_3')}
        </SpText>
        <SpText
          color="#fff"
          size="sm"
          style={styles.paragraphDesc}>
          {t('setup_cdb_pairing_description_3')}
        </SpText>
      </SpView>
      <SpView style={styles.factoryWrapper}>
        <SpView style={[styles.centered, styles.titleFactoryHelp]}>
          <SpText
            color="white"
            size="lg">
            {t('title_factory_help')}
          </SpText>
        </SpView>
        <SpView style={[styles.centered, styles.sentenceFactoryHelp]}>
          <SpView style={[styles.centered, styles.widthSentenceFactoryHelp]}>
            <SpText
              color="white"
              size="md"
              align="center">
              {t('sentence_factory_help')}
            </SpText>
          </SpView>
        </SpView>
        <SpView style={styles.centered}>
          <SpButton
            style={styles.btnHelp}
            title={t('btn_factory_help')}
            borderRadius={12}
            onPress={() => navigation.navigate('FlowNavigation', { screen: 'CDBHowToFactoryReset' })}
          />
        </SpView>
      </SpView>
      <SpText
        color="#fff"
        size="sm"
        style={styles.paragraph2}>
        {t('setup_cdb_pairing_paragraph_2')}
      </SpText>
      <TouchableOpacity onPress={handleNavigation(t('setup_cdb_battery_link'))}>
        <SpText
          color="#fff"
          bold
          style={styles.link}>
          {t('setup_cdb_battery_link')}
        </SpText>
      </TouchableOpacity>
      </ScrollView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.helpScreenColor.color,
    color: 'white',
    height: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  paragraph: {
    color: 'white',
    fontSize: 16,
    marginTop: 15,
  },
  paragraph2: {
    color: 'white',
    fontSize: 16,
    marginTop: 20,
  },
  title: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 5,
  },
  paragraphDesc: {
    color: 'white',
    fontSize: 14,
  },
  link: {
    color: 'white',
    marginTop: 25,
    fontWeight: 'bold',
    fontSize: 16,
  },
  question: {
    fontSize: 20,
  },
  factoryWrapper: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: 20,
    padding: 5,
    marginTop: 20,
  },
  centered: {
    alignItems: 'center',
  },
  btnHelp: {
    width: 150,
    paddingVertical: 15,
    borderRadius: 40,
  },
  titleFactoryHelp: {
    paddingTop: 10,
  },
  sentenceFactoryHelp: {
    paddingTop: 10,
  },
  widthSentenceFactoryHelp: {
    width: '80%',
    textAlign: 'center',
  },
});

export default CDBHelpPairingMode;
