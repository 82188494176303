import { RemoteConfigDefault, RemoteConfigKeys } from '@constants/RemoteConfig';
import { FirebaseRemoteConfigTypes } from '@react-native-firebase/remote-config';

let remoteConfig: () => FirebaseRemoteConfigTypes.Module = null;
let loadingModulePromise: Promise<() => FirebaseRemoteConfigTypes.Module> = null;

const getBoolean = (data: string) => Boolean(data);
const getString = (data: string) => String(data);
const getNumber = (data: string) => Number(data);

class ConfigValue {
  private data = '';

  private source = 'remote';

  constructor(data: string) {
    this.data = data;
  }

  getBoolean(): boolean {
    return getBoolean(this.data);
  }

  getString(): string {
    return getString(this.data);
  }

  getNumber(): number {
    return getNumber(this.data);
  }

  getSource(): string {
    return this.source;
  }
}
const getAll = (): Record<RemoteConfigKeys, ConfigValue> =>{
  // @ts-ignore
  return Object.keys(RemoteConfigDefault).reduce((acc, property: string) => {
    // @ts-ignore
    acc[property] = new ConfigValue(RemoteConfigDefault[property]);
    return acc;
  }, {});
}
const getValue = (name: RemoteConfigKeys): ConfigValue => {
  return getAll()[name] || new ConfigValue('');
}
const mockRemoteConfig = () => {
  return {
    setDefaults(_: unknown): Promise<null> {
      return Promise.resolve(null);
    },
    fetchAndActivate(): Promise<boolean> {
      return Promise.resolve(true);
    },
    getAll,
    getValue,
    getBoolean(name: RemoteConfigKeys) {
      return getValue(name).getBoolean();
    },
    getNumber(name: RemoteConfigKeys) {
      return getValue(name).getNumber();
    },
    getString(name: RemoteConfigKeys) {
      return getValue(name).getString();
    },
  };
};

export const SpRemoteConfigService = async (): Promise<() => FirebaseRemoteConfigTypes.Module> => {
  // @ts-ignore
  return Promise.resolve(mockRemoteConfig);
};
export const SpRemoteConfig = mockRemoteConfig;
