import {
  faAngleRight,
  faArrowRight,
  faCheckCircle,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useRoute } from '@react-navigation/native';
import atomStyles from '@styles/atoms';
import colors from '@styles/colors';
import noop from '@utils/noop';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { SpProfileImage } from 'src/components/SpProfileImage';
import { SpView } from 'src/components/SpView';
import { SpText } from '../../../components/SpText/SpText';
import { Switch } from 'react-native-switch';

import LoadingSpinner from '../../../components/Loader/Loader';
import IncompatibleExclamationSVG from '../../../components/SvgIcons/IncompatibleExclamationSVG';

type PetItem = {
  imageUrl: string;
  assigned?: boolean;
  indoorModeEnabled?: boolean;
  name: string;
  created?: string;
  withAssignedIcon?: boolean;
  handlePress?: () => void;
  isIndoorMode?: boolean;
  isAlternativeStyle?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isIncompatible?: boolean;
  isCompacted?: boolean;
};

const PetItem = ({
  imageUrl,
  assigned,
  indoorModeEnabled,
  name,
  handlePress,
  created = null,
  withAssignedIcon = false,
  isIndoorMode = false,
  isAlternativeStyle = false,
  isDisabled = false,
  isLoading = false,
  isIncompatible = false,
  isCompacted = false,
}: PetItem) => {
  const { t } = useTranslation();
  const currRoute = useRoute();

  return isIncompatible ? (
    <Pressable
      accessible={false}
      style={[styles.container, styles.isLoading]}
      onPress={handlePress}
    >
      <SpView style={styles.petItem}>
        <SpView
          marginRight={16}
          {...testProperties(`${name}`, `PetImage`)}
        >
          <SpProfileImage
            width={48}
            height={48}
            imageUrl={imageUrl}
          />
        </SpView>
        <SpView style={styles.petDetails}>
          <SpText style={styles.name}>{name}</SpText>
          <SpView>
            <SpText
              style={styles.time}
              color={colors.errorRed.color}
            >
              {t('add_pet_again')}
            </SpText>
          </SpView>
        </SpView>
      </SpView>
      <View
        style={
          !assigned
            ? [
                atomStyles.alternativeCheckbox,
                {
                  position: 'absolute',
                  right: 18,
                },
              ]
            : null
        }
      >
        <IncompatibleExclamationSVG />
      </View>
    </Pressable>
  ) : (
    <Pressable
      accessible={false}
      style={[
        styles.container,
        isLoading ? styles.isLoading : {},
        isDisabled ? styles.isLoading : {},
        isIncompatible ? styles.isLoading : {},
        { paddingRight: isCompacted ? 25 : 72 },
      ]}
      onPress={isDisabled ? noop : handlePress}
    >
      <SpView style={styles.petItem}>
        <SpView
          marginRight={16}
          {...testProperties(`${name}`, `PetImage`)}
        >
          <SpProfileImage
            width={48}
            height={48}
            imageUrl={imageUrl}
          />
        </SpView>
        {indoorModeEnabled ? (
          <>
            <SpView style={styles.petDetails}>
              <SpText style={styles.name}>{name}</SpText>
              <SpText style={styles.enabled}>{indoorModeEnabled && t('enabled')}</SpText>
            </SpView>
            <SpView>
              <SpText style={styles.time}>{created}</SpText>
            </SpView>
          </>
        ) : (
          <SpView style={styles.petDetails}>
            <SpText style={styles.name}>{name}</SpText>
            {!isIndoorMode ||
              (currRoute !== 'AddPets' && (
                <SpView>
                  <SpText style={styles.time}>
                    {t('added')} {created}
                  </SpText>
                </SpView>
              ))}
          </SpView>
        )}
      </SpView>

      {!isIndoorMode &&
        !isAlternativeStyle &&
        (withAssignedIcon ? (
          <View
            {...testProperties(assigned ? 'faCheckCircle' : 'faCirclePlus', `icon`)}
            style={!assigned ? atomStyles.alternativeCheckbox : null}
          >
            <FontAwesomeIcon
              size={32}
              icon={assigned ? faCheckCircle : faCircle}
              color={assigned ? colors.primary.color : colors.white.color}
            />
          </View>
        ) : (
          <View {...testProperties('faAngleRight', 'icon')}>
            <FontAwesomeIcon
              size={20}
              icon={faAngleRight}
              color={colors.greyText.color}
            />
          </View>
        ))}

      {isAlternativeStyle && (
        <SpView
          {...testProperties(assigned ? 'faCheckCircle' : 'faCirclePlus', `icon_${name || ''}`)}
          style={[atomStyles.alternativeCheckbox, { position: 'absolute', right: 18 }]}
        >
          {isLoading && <LoadingSpinner size="small" />}
          {assigned && !isLoading && (
            <FontAwesomeIcon
              size={32}
              icon={faCheckCircle}
              color={colors.primary.color}
            />
          )}
        </SpView>
      )}
      {isIndoorMode && (
        <Switch
          value={indoorModeEnabled}
          onValueChange={handlePress}
          disabled={false}
          renderActiveText={false}
          renderInActiveText={false}
          backgroundActive={colors.green.color}
          backgroundInactive={colors.greyBorder.color}
          circleSize={25}
          circleBorderWidth={0}
          containerStyle={{
            minHeight: 32,
          }}
          innerCircleStyle={{
            width: 25,
            height: 25,
            backgroundColor: colors.white.color,
          }}
          switchBorderRadius={18}
          switchLeftPx={3}
          switchRightPx={3}
          {...testProperties(`${name} pet`, 'switch')}
        />
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 17,
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 14,
    marginBottom: 25,
    paddingRight: 72,
  },
  petItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  petDetails: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexShrink: 1,
    overflow: 'hidden',
  },
  name: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.greyText.color,
  },
  time: {
    // fontWeight: 'bold',
    fontSize: 12,
    display: 'flex',
  },
  enabled: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
    color: colors.green.color,
  },
  isLoading: {
    opacity: 0.6,
  },
});

export default PetItem;
