import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import colors from '@styles/colors';
import { SettingsOption } from '@constants/Settings';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';

interface SelectButtonProps {
  option: SettingsOption;
  isActive: boolean;
  setActive: (arg: number) => void;
  size?: 'small' | 'medium' | 'fullwidth' | number;
  withIcon?: boolean;
  isLoading?: boolean;
}

const SelectButton = ({
  option,
  isActive,
  setActive,
  size = 'fullwidth',
  withIcon = false,
  isLoading = false,
}: SelectButtonProps) => {
  const { t } = useTranslation();
  return (
    <Pressable
      accessible={false}
      {...testProperties(option.name, 'select button')}
      style={[
        styles.selectItem,
        isLoading && styles.loading,
        isActive && styles.activeItem,
        typeof size === 'number' ? { width: size } : styles[size],
      ]}
      onPress={() => !isLoading && setActive(option?.id)}>
      <Text
        {...testProperties(option?.name, 'text')}
        style={[styles.text, isActive && styles.activeText]}>
        {t(option?.name)}
      </Text>
      {withIcon && isActive && (
        <View
          style={styles.icon}
          {...testProperties(option.name, 'check icon')}>
          <FontAwesomeIcon
            color="white"
            size={24}
            icon={faCheckCircle}
          />
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  small: {
    width: '30%',
  },
  medium: {
    width: '40%',
  },
  fullwidth: {
    minWidth: '100%',
  },
  selectItem: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 28,
    backgroundColor: 'white',
    height: 56,
    justifyContent: 'flex-start',
    paddingHorizontal: 24,
    marginBottom: 24,
    borderColor: colors.greyBorder.color,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  activeItem: {
    backgroundColor: colors.green.color,
    borderColor: colors.green.color,
  },
  text: {
    color: colors.greyText.color,
    fontSize: 18,
    fontFamily: 'Rubik_Medium',
  },
  activeText: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Rubik_Medium',
  },
  icon: { position: 'absolute', right: 24 },
  loading: { opacity: 0.5 },
});

export default SelectButton;
