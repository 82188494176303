import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { DeviceType } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import { SpDeviceOffline } from 'src/components/SpDeviceOffline';
import { SpProductCardImage } from 'src/components/SpProductCardImage';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { StyleSheet } from 'react-native';

interface HubCardProps {
  data: DeviceModel;
}

export const HubCard = ({ data }: HubCardProps) => {
  const online = data?.status.online;

  return (
    <SpView
      {...testProperties(data.name, 'view', 'HubCard')}
      style={styles.container}>
      <SpView style={styles.left}>
        <SpProductCardImage
          data={DeviceType.Hub}
          height={52}
          width={52}
        />
        <SpView style={styles.content}>
          <SpText
            style={styles.name}
            size="xl">
            {data?.name}
          </SpText>
          {!online && (
            <SpDeviceOffline
              deviceName={data.name}
              useImage={false}
            />
          )}
        </SpView>
      </SpView>
      {!online && (
        <SpView style={styles.right}>
          <SpDeviceOffline
            deviceName={data.name}
            useImage
          />
        </SpView>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderRadius: 24,
    backgroundColor: colors.white.color,
    flexDirection: 'row',
  },
  left: {
    flex: 1,
    flexDirection: 'row',
  },
  right: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 12,
  },
  name: {
    color: colors.greyText.color,
    fontFamily: 'Rubik_SemiBold',
  },
});
