import { StyleSheet, View } from 'react-native';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import SpTitle from 'src/components/SpTitle';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import { capitalize } from '@utils/capitalize';
import { AppImages } from '@constants/AppImages';
import TypeButton from '../components/TypeButton';

enum BowlButtons {
  single_bowl = 1,
  half_bowls = 4,
}

interface IChooseBowlTypeProps {
  bowlType: number;
  setBowlType: Dispatch<React.SetStateAction<number>>;
  withTitle?: boolean;
}

const ChooseBowlType = ({ bowlType, setBowlType, withTitle = false }: IChooseBowlTypeProps) => {
  const { t } = useTranslation();
  const bowlTypeOptions = enumToDescriptedArray(BowlButtons, /\s/);
  const bowlTypeImages = [AppImages.bowl, AppImages.halfBowl];
  return (
    <View style={{ gap: 35 }}>
      {withTitle && (
        <SpTitle
          align="center"
          text={t('choose_bowl_type')}
        />
      )}
      {bowlTypeOptions.map((item, index) => {
        const option = bowlTypeOptions[index];
        return (
          <View
            key={item.id + index}
            style={[styles.buttonWrap]}>
            <TypeButton
              text={capitalize(t(option.name))}
              isActive={bowlType === option.id}
              onPress={() => {
                setBowlType(option.id);
              }}
              isBowl
              {...bowlTypeImages[index]}
              image={bowlTypeImages[index]}
            />
          </View>
        );
      }, [])}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonWrap: {},
});

export default ChooseBowlType;
