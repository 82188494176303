import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const PreferencesSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}>
    <Path
      fill="#263A43"
      fillRule="nonzero"
      d="m13.881 6.036-.076-.027-.297-.725.032-.074c.9-2.085.839-2.147.69-2.298l-1.202-1.196a.317.317 0 0 0-.21-.085c-.067 0-.272 0-2.046.82l-.07.033-.733-.301-.03-.074C9.107 0 9.02 0 8.814 0H7.116c-.207 0-.3 0-1.074 2.112l-.03.077-.728.303-.07-.03c-1.2-.516-1.901-.778-2.084-.778a.318.318 0 0 0-.21.083l-1.202 1.2c-.153.153-.216.218.732 2.259l.035.076-.298.723-.073.029C0 6.888 0 6.969 0 7.186v1.697c0 .218 0 .308 2.118 1.083l.076.028.298.72-.032.075c-.9 2.085-.844 2.142-.69 2.297l1.199 1.198c.05.05.137.085.211.085.068 0 .272 0 2.047-.82l.071-.034.733.302.028.075C6.894 16 6.979 16 7.187 16h1.697c.213 0 .301 0 1.076-2.114l.028-.077.73-.3.07.028c1.199.519 1.9.78 2.081.78.074 0 .161-.033.211-.084l1.204-1.202c.152-.154.215-.217-.734-2.256l-.036-.077.298-.72.07-.029C16 9.111 16 9.03 16 8.812V7.116c0-.218 0-.308-2.119-1.08ZM8 10.752A2.754 2.754 0 0 1 5.25 8 2.752 2.752 0 0 1 8 5.25 2.752 2.752 0 0 1 10.748 8 2.754 2.754 0 0 1 8 10.752Z"
    />
  </Svg>
);
export default PreferencesSVG;
