import { Image, Linking, StyleSheet } from 'react-native';
import React from 'react';
import { AppImages } from '@constants/AppImages';
import text from '@styles/text';
import { SpText } from '../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from '@styles/colors';

interface PairingWIFIFailedProps {
  getHelpAction: () => void;
}

export const PairingWIFIFailed = ({ getHelpAction }: PairingWIFIFailedProps) => {
  const { t } = useTranslation();

  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={{ flexDirection: 'column' }}>
        <SpView style={[{ flex: 1 }, styles.center]}>
          <Image
            style={{ width: 200 }}
            source={AppImages.cdbFailedtoPair}
            resizeMode="contain"
          />
          <SpText style={[text.title]}>{t('cdb_error_wifi')}</SpText>
          <SpText style={[text.body, styles.text]}>{t('setup_cdb_failed_to_pair_lights')}</SpText>
          <SpRoundedHeaderButton
            stylesForContainer={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
            w={170}
            backgroundColor={colors.greyText.color}
            textStyles={{
              fontSize: 16,
              fontFamily: 'Rubik_Medium',
              color: colors.white.color,
            }}
            onPress={() => getHelpAction()}
            title={'Connection tips'}
          />
        </SpView>
        <SpView style={{ flex: 1, paddingBottom: 20 }}>
          <SpText style={[text.body, styles.text]}>
            {t('setup_cdb_failed_to_pair_mode_help')}
          </SpText>
          <SpText
            style={[text.linkText, styles.text]}
            onPress={() => Linking.openURL(`https://${t('setup_cdb_failed_to_pair_link')}`)}>
            {t('setup_cdb_failed_to_pair_link')}
          </SpText>
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: '10%',
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
  text: {
    marginTop: '5%',
  },
});

export default PairingWIFIFailed;
