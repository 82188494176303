import { SpView } from 'src/components/SpView';
import { SpText } from '../../../../components/SpText/SpText';
import { ScrollView, StyleSheet } from 'react-native';
import React from 'react';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import UnlockSVG from 'src/components/SvgIcons/UnlockSVG';
import LockSVG from 'src/components/SvgIcons/LockSVG';
import CurfewEnabledSVG from 'src/components/SvgIcons/CurfewEnabledSVG';

const PetDoorSlide = () => {
  const { t } = useTranslation();
  return (
    <ScrollView>
      <SpView style={styles.wrapper}>
        <SpView style={styles.itemWrapper}>
          <SpView style={styles.lockUnlockIcons}>
            <LockSVG
              color={colors.greyText.color}
              width={45}
              height={45}
              viewBox="0 0 28 28"
            />
            <UnlockSVG
              color={colors.greyText.color}
              width={45}
              height={45}
              viewBox="0 0 28 28"
            />
          </SpView>
          <SpText style={styles.title}>{t('lock_and_unlock')}</SpText>
          <SpText style={styles.text}>{t('lock_and_unlock_tour_text')}</SpText>
        </SpView>
        <SpView style={styles.itemWrapper}>
          <CurfewEnabledSVG
            color={colors.greyText.color}
            width={45}
            height={45}
            viewBox="0 0 30 30"
          />
          <SpText style={styles.title}>{t('curfew')}</SpText>
          <SpText style={styles.text}>{t('choose_a_time_curfew')}</SpText>
        </SpView>
      </SpView>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 32,
    rowGap: 32,
    flexDirection: 'column',
  },
  itemWrapper: {
    paddingHorizontal: 19,
    columnGap: 7,
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 28,
    fontFamily: 'Rubik_Medium',
    color: colors.greyText.color,
  },
  text: {
    lineHeight: 20,
    color: colors.greyText.color,
    textAlign: 'center',
    fontSize: 14,
  },
  lockUnlockIcons: {
    flexDirection: 'row',
    marginBottom: 7,
  },
});

export default PetDoorSlide;
