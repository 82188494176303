import { SpView } from './SpView';

export const WebWrapper = ({ children }) => {
  return (
    <SpView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        width: '100%',
      }}
    >
      <SpView
        style={{
          height: '100%',
          width: '100%',
          maxWidth: 900,
        }}
      >
        {children}
      </SpView>
    </SpView>
  );
};
