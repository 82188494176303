import { PetPosition } from '@constants/Pet';
import { PetLocationIcons } from '@constants/PetLocationIcons';
import { usePetQuickActions } from '@hooks/usePetQuickActions';
import { PetModel } from '@models/Pet';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';

import useBoundStore from '../../../../../store/store';
import { RootStackParamList } from '../../../../index';
import { useCheckPending } from '../../../hooks/useCheckPending';
import { usePetActions } from '../../../hooks/usePetActions';
import usePetDisplayContentLogic from '../../../hooks/usePetDisplayContentLogic';
import { SpView } from '../../../../../components/SpView';
import { PetCardHeader } from '../../../Pets/pages/PetCarousel/components/PetCardHeader';

export type AdaptiveSize =
  | {
      fontSizeForNumbers: number;
      fontSizeForText: number;
    }
  | undefined;

const { width } = Dimensions.get('window');
const adaptiveSize: AdaptiveSize = {
  fontSizeForNumbers: Math.min(width / 15, 24),
  fontSizeForText: Math.min(width / 18, 24),
};

interface PetGridCardProps {
  pet: PetModel;
}

export const PetGridCard = (props: PetGridCardProps) => {
  const { petActions } = usePetActions(props.pet);
  const { pendingChecking } = useCheckPending(props.pet);
  const { displayContentLogic } = usePetDisplayContentLogic(
    props.pet,
    petActions,
    pendingChecking,
    adaptiveSize,
    'subCompact',
  );
  const { devicesIdsRequestsTrack } = useBoundStore(s => s.deviceStore);
  const petHeader = useMemo(() => {
    return (
      <PetCardHeader
        petActions={petActions}
        pet={props.pet}
        pending={pendingChecking}
        showQuickActions
        compact
      />
    );
  }, [petActions, props.pet, devicesIdsRequestsTrack]);
  const { setActivePet } = useBoundStore(s => s.petStore);
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { QuickActionsModal, isModalOpen, setIsModalOpen, allowQuickActions } = usePetQuickActions(
    props.pet,
  );
  const handleModal = () => {
    setIsModalOpen(true);
  };
  const handleNavigationToStats = () => {
    setActivePet(props.pet.id);

    navigate('PetsNavigation', {
      screen: 'PetDashboardSummary',
      params: { id: props.pet.id },
    });
  };

  return (
    <>
      <SpView style={styles.item}>
        {petActions?.movement && petActions.movement.where !== PetPosition.none && (
          <SpView
            style={{
              position: 'absolute',
              top: -10,
              right: -10,
              borderWidth: 2,
              borderColor: 'white',
              borderRadius: 40,
              zIndex: 100,
            }}
          >
            <TouchableOpacity style={{ width: '100%', height: '100%' }}>
              {PetLocationIcons[petActions.movement.where]({ size: 12, action: handleModal })}
            </TouchableOpacity>
          </SpView>
        )}
        <TouchableOpacity
          disabled={!petActions}
          onPress={handleNavigationToStats}
          style={{ width: '100%' }}
        >
          <SpView
            style={{
              height: '100%',
              width: '100%',
              padding: 15,
              flexDirection: 'column',
            }}
          >
            <SpView
              style={{
                flex: 1 / 2,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {petHeader}
            </SpView>
            <SpView
              style={{
                flex: 1 / 2,
                paddingTop: 15,
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {displayContentLogic}
            </SpView>
          </SpView>
        </TouchableOpacity>
      </SpView>
      {isModalOpen && QuickActionsModal}
    </>
  );
};

const styles = StyleSheet.create({
  item: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 24,
  },
});
