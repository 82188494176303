import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { SpVStack } from 'src/components/SpVStack';
import SpTitle from 'src/components/SpTitle';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from './Layout';

export const RemoveAccountInformationDialog = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <Layout
      nextButtonText={t('continue')}
      onNext={() => navigation.goBack()}>
      <SpView alignItems="center">
        <SpTitle
          align="center"
          text={t('ad_explanation_delete_account_title')}
        />
      </SpView>
      <SpVStack space={12}>
        <SpText>{t('ad_explanation_dialog_delete_account_body_1')}</SpText>

        <SpView>
          <SpView marginBottom={6}>
            <SpText fontFamily="Rubik_SemiBold">
              {t('ad_explanation_dialog_delete_account_body_2')}
            </SpText>
          </SpView>

          <SpVStack space={6}>
            <SpText>{`\u2022 ${t('ad_explanation_dialog_delete_account_body_3')}`}</SpText>
            <SpText>{`\u2022 ${t('ad_explanation_dialog_delete_account_body_4')}`}</SpText>
            <SpText>{`\u2022 ${t('ad_explanation_dialog_delete_account_body_5')}`}</SpText>
          </SpVStack>
        </SpView>

        <SpText>{t('ad_explanation_dialog_delete_account_body_6')}</SpText>
      </SpVStack>
    </Layout>
  );
};
