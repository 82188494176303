import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import Svg, { Circle } from 'react-native-svg';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from '../../../../../../components/SpView';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

const CircleProgress = ({ value, maxValue, size = 100, strokeWidth = 10 }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = ((maxValue - value) / maxValue) * 100;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const animatedValue = useRef(new Animated.Value(0)).current;
  const animatedStrokeDashoffset = animatedValue.interpolate({
    inputRange: [0, 100],
    outputRange: [circumference, strokeDashoffset],
  });

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: 50,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  }, [progress]);

  if (!value || !maxValue) {
    return null;
  }

  return (
    <View style={styles.container}>
      <SpView style={styles.daysContainer}>
        <SpText
          size="xxl"
          fontFamily="Rubik_Medium"
          style={styles.days}
        >
          {value}
        </SpText>
      </SpView>
      <Svg
        width={size}
        height={size}
      >
        <Circle
          stroke="#D3D3D3" // Grey color for the background circle
          fill="transparent"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <AnimatedCircle
          stroke="#2DB58E" // Green color for the progress circle
          fill="transparent"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={animatedStrokeDashoffset}
          strokeLinecap="round"
          rotation="-90"
          origin={`${size / 2}, ${size / 2}`}
        />
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  daysContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  days: {},
});

export default CircleProgress;
