import { StyleSheet, Text, View, Pressable, FlatList, TouchableOpacity } from 'react-native';
import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import SpModal from 'src/components/SpModal';
import { closeModal } from 'src/services/ModalBox';
import useBoundStore from 'src/store/store';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';

interface PetChangeModalProps {
  modal: string;
}

const PetChangeModal = ({ modal }: PetChangeModalProps) => {
  const pets = usePetsByHousehold();
  const { setActivePet } = useBoundStore(state => state.petStore);

  const renderItem = useCallback(({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setActivePet(item.id);
          closeModal(modal);
        }}>
        <View style={styles.itemContainer}>
          <FontAwesomeIcon
            color="white"
            size={30}
            icon={faImage}
          />
          <Text style={styles.itemText}>{item.name}</Text>
        </View>
      </TouchableOpacity>
    );
  }, []);

  return (
    <SpModal
      modalName={modal}
      backdropColor="transparent">
      <Pressable
        accessible={false}
        style={styles.modalContent}>
        <View style={styles.triangle} />
        <View style={styles.flatlistWrapper}>
          <FlatList
            data={pets}
            renderItem={renderItem}
            keyExtractor={(pet, index) => pet.name + index}
            contentContainerStyle={styles.contentContainer}
          />
        </View>
      </Pressable>
    </SpModal>
  );
};

export default PetChangeModal;

const styles = StyleSheet.create({
  modalContent: {
    width: '90%',
    marginTop: '28%',
    alignItems: 'center',
    backgroundColor: 'grey',
    borderRadius: 10,
  },
  container: {
    width: '100%',
    alignItems: 'center',
  },
  triangle: {
    borderTopWidth: 0,
    borderRightWidth: 9,
    borderBottomWidth: 10,
    borderLeftWidth: 9,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderLeftColor: 'transparent',
    borderBottomColor: 'grey',
    marginTop: -8,
    paddingTop: -8,
  },
  itemContainer: {
    height: 70,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  itemText: {
    marginLeft: 10,
    color: 'white',
    fontSize: 18,
  },
  flatlistWrapper: {
    width: '100%',
  },
  contentContainer: { paddingVertical: 10 },
});
