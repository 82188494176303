import { SpMenuStack } from 'src/components/SpMenuStack';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import CustomBottomSheetModal from 'src/components/CustomBottomSheetModal';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import colors from 'src/styles/colors';
import { AccountMenu } from '@constants/Menus';
import { useTranslation } from 'react-i18next';
import { SpView } from 'src/components/SpView';
import { usePickerAndCameraImage } from '@hooks/usePickerAndCameraImage';
import ChangePasswordLockSvg from 'src/components/SvgIcons/ChangePasswordLockSvg';
import ActionPicker from 'src/components/ActionPicker';
import { EditMenuItem, MenuItem } from '@models/Menu';
import useToastEffect from '@hooks/useToastEffect';
import { SpProfileImage } from '../../../components/SpProfileImage';
import { AccountStackParamList } from '../index';
import { SpEditMenuStack } from '../../../components/SpEditMenuStack';
import useBoundStore from '../../../store/store';
import { WebWrapper } from '../../../components/WebWrapper';

export const PersonalSettings = () => {
  const { t } = useTranslation();

  const navigation = useNavigation<NavigationProp<AccountStackParamList>>();
  const user = useBoundStore(state => state.accountStore.user);
  const { updatePhoto, deletePhoto, updateProperty, updatePhotoResult, deletePhotoResult } =
    useBoundStore(state => state.accountStore);
  const [open, setOpenModal] = useState<boolean>(false);
  const snapPoints = useMemo(() => ['40%'], []);
  const onOpen = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const onDismiss = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const { cameraImage, pickerImage } = usePickerAndCameraImage();

  const settings: EditMenuItem[] = useMemo(() => {
    return [
      {
        label: t('first_name'),
        value: user.first_name,
        action: () =>
          navigation.navigate('AccountEditSetting', {
            label: t('first_name'),
            inputName: AccountMenu.FirstName,
            inputValue: user.first_name,
          }),
      },
      {
        label: t('surname'),
        value: user.last_name,
        action: () =>
          navigation.navigate('AccountEditSetting', {
            label: t('surname'),
            inputName: AccountMenu.LastName,
            inputValue: user.last_name,
          }),
      },
      {
        label: t('email'),
        value: user.email_address,
        action: () =>
          navigation.navigate('AccountEditSetting', {
            label: t('email'),
            inputName: AccountMenu.EmailAddress,
            inputValue: user.email_address,
          }),
      },
    ];
  }, [user]);

  const buttons = useMemo<MenuItem[]>(
    () => [
      {
        label: t('take_photo'),
        action: async () => {
          const img = await cameraImage();
          onDismiss();
          if (img) await updatePhoto(img);
        },
      },
      {
        label: t('upload_photo'),
        action: async () => {
          const img = await pickerImage();
          onDismiss();
          if (img) await updatePhoto(img);
        },
      },
      {
        label: t('delete_photo'),
        action: () => {
          deletePhoto();
          onDismiss();
        },
      },
    ],
    [],
  );

  const menu: MenuItem[] = useMemo(() => {
    return [
      {
        label: t('change_password'),
        action: () => {
          navigation.navigate('Password');
        },
        iconSVG: (
          <ChangePasswordLockSvg
            width={16}
            height={16}
          />
        ),
      },
    ];
  }, []);

  useToastEffect({
    resetter: () => updateProperty({ updatePhotoResult: 'none' }),
    successCondition: updatePhotoResult === 'success',
    errorCondition: updatePhotoResult === 'error',
    errorToastMessage: t('error_profile_image'),
    successToastMessage: t('success_profile_image'),
  });

  useToastEffect({
    resetter: () => updateProperty({ deletePhotoResult: 'none' }),
    successCondition: deletePhotoResult === 'success',
    errorCondition: deletePhotoResult === 'error',
    errorToastMessage: t('error_delete_profile_photo'),
    successToastMessage: t('success_delete_profile_photo'),
  });

  return (
    <SpView style={styles.container}>
      <WebWrapper>
        <SpView style={styles.inner}>
          <>
            <SpView style={styles.header}>
              <SpProfileImage
                width={92}
                height={92}
                isUser
                imageUrl={user?.photo?.location}
              />
              <SpView style={styles.buttonWrap}>
                <SpRoundedHeaderButton
                  backgroundColor={colors.primary.color}
                  h={40}
                  title={t('edit_photo')}
                  onPress={onOpen}
                  textStyles={styles.buttonText}
                  borderRadius={10}
                  paddingHorizontal={0}
                />
              </SpView>
            </SpView>

            <SpView style={styles.menuStackWrap}>
              <SpEditMenuStack buttons={settings} />
              <SpView style={styles.menuStackWrap}>
                <SpMenuStack buttons={menu} />
              </SpView>
            </SpView>
          </>

          <CustomBottomSheetModal
            opened={open}
            backdropComponent={BottomSheetBackdrop}
            index={0}
            snapPoints={snapPoints}
            onDismiss={onDismiss}
            inScrollView
            scrollViewStyle={styles.bottomSheetModalContent}
          >
            <ActionPicker buttons={buttons} />
          </CustomBottomSheetModal>
        </SpView>
      </WebWrapper>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    color: '#f2f2f2',
    backgroundColor: '#ffffff',
  },
  inner: {
    flex: 1,
    width: '85%',
  },
  iconContainer: {
    marginLeft: 15,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 24,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#ffffff',
    letterSpacing: 0,
    fontFamily: 'Rubik_Medium',
  },
  buttonWrap: {
    marginLeft: 24,
    width: 117,
  },
  menuStackWrap: {
    marginTop: 43,
  },
  bottomSheetModalContent: {
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
});
