import { DayWeekMonthShortFormat } from '@constants/DateFormat';
import { useCustomToast } from '@hooks/useToast';
import useTour from '@hooks/useTour';
import { useViewDays } from '@hooks/useViewDays';
import {
  GroupReportPointModel,
  MappedMovementReportDataModel,
  ReportChangeViewDayPayload,
} from '@models/ReportModel';
import { useIsFocused } from '@react-navigation/native';
import { fillEmptyPoints } from '@utils/fillEmptyPoints';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, FlatList, StyleSheet, View } from 'react-native';
import { DurationLineChart } from 'src/components/DurationLineChart';
import { SpText } from '../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import DateNavigation from 'src/pages/Dashboard/components/DateNavigation';
import { MovementEventReport } from 'src/pages/Dashboard/components/MovementEventReport';
import { TimeService } from 'src/services/TimeService';
import { Header } from './components/Header';
import { LineGraphModalWrapper } from './components/LineGraphModalWrapper';
import { PetAverageStatsMovement } from './components/PetAverageStatsMovement';
import LoadingSpinner from '../../../../../components/Loader/Loader';
import useBoundStore from '../../../../../store/store';
import ExitsAndEntriesSlide from '../../../../Tour/components/ExitsAndEntriesSlide/ExistsAndEntriesSlide';
import { ReportRangeLastName, ReportRangeType } from '../../../constants/ReportRangeType';
import { useCurrentAndPreviousDays } from '../../../hooks/useCurrentAndPreviousDays';
import { useGroupedDoorStats } from '../../../hooks/useGroupedDoorStats';

interface PetDashboardMovementProps {
  data: GroupReportPointModel<MappedMovementReportDataModel>[];
  onGeneratePDFReport: () => void;
  onChangeViewedDays: (data: ReportChangeViewDayPayload) => void;
  rangeType: ReportRangeType;
  currentDate: number;
}

export const PetDashboardMovement = ({
  data,
  onGeneratePDFReport,
  onChangeViewedDays,
  rangeType,
  currentDate,
}: PetDashboardMovementProps) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(1);
  const toast = useCustomToast();
  const isFocused = useIsFocused();
  const viewedDays = useViewDays(rangeType);
  const { loading } = useBoundStore(s => s.reportStore);

  const initTour = useTour({
    uniqueId: 'v0-graph-movements-dashboard',
    components: [<ExitsAndEntriesSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const activityDetails = useCurrentAndPreviousDays<MappedMovementReportDataModel>(
    currentDate,
    data,
    viewedDays,
  );
  const convertedActivityDetails = useMemo(
    () => fillEmptyPoints(activityDetails.rangeData, viewedDays, currentDate),
    [activityDetails, viewedDays, currentDate],
  );
  const stats = useGroupedDoorStats(data, activityDetails?.currentDay?.date);

  const pointDetailsRenderItem = useCallback(($event: { item: MappedMovementReportDataModel }) => {
    //@ts-ignore
    return (
      <MovementEventReport
        data={$event.item}
        index={$event.index}
      />
    );
  }, []);

  const renderItem = useCallback(
    ({
      item,
      index,
    }: {
      item: GroupReportPointModel<MappedMovementReportDataModel>;
      index: number;
    }) => {
      if (!item) {
        return;
      }
      const isActive = index + 1 === activeItem;
      const isVisibleLeftTitle = () => {
        if (viewedDays === ReportRangeType.SevenDays) {
          return TimeService.toLocal(item?.date).toFormat(DayWeekMonthShortFormat);
        }
        if (viewedDays === ReportRangeType.TwentyEightDays) {
          if (index === 0 || isActive || index % 7 === 0) {
            return TimeService.toLocal(item?.date).toFormat(DayWeekMonthShortFormat);
          }
          return null;
        }
      };

      const endedTrips = item.points.filter(item => !item?.active);
      return (
        <DurationLineChart
          isActive={!!(item.points.length && isActive)}
          points={item.points}
          setActive={() => {
            item.points.length
              ? setActiveItem(index + 1)
              : toast.show({
                  description: t('no_events_on_this_day'),
                  isError: true,
                });
          }}
          lowState={viewedDays === ReportRangeType.TwentyEightDays}
          leftTitle={isVisibleLeftTitle()}
          rightTitle={`${endedTrips.length} ${t('point_visits')}`}
          pointDetailsRenderItem={pointDetailsRenderItem}
        />
      );
    },
    [activeItem, viewedDays],
  );
  const dateNavigationData = {
    name: t(
      ReportRangeLastName[
        viewedDays === ReportRangeType.TwentyEightDays
          ? ReportRangeType.TwentyEightDays
          : ReportRangeType.SevenDays
      ],
      {
        value:
          viewedDays === ReportRangeType.TwentyEightDays
            ? ReportRangeType.TwentyEightDays
            : ReportRangeType.SevenDays,
      },
    ),
    viewedDays,
  };
  const ListHeader = useCallback(
    () => (
      <View>
        {convertedActivityDetails.length > 0 &&
          convertedActivityDetails?.[0]?.date === TimeService.toLocal().endOf('day').toMillis() && (
            <SpText
              style={{ marginBottom: -20, paddingHorizontal: 24 }}
              fontFamily="Rubik_Medium"
            >
              {t('today')}
            </SpText>
          )}
      </View>
    ),
    [convertedActivityDetails],
  );

  return (
    <SpView style={styles.container}>
      {stats ? (
        <LineGraphModalWrapper
          data={stats}
          modal="LineGraph"
        />
      ) : null}
      <Header onGeneratePDFReport={onGeneratePDFReport} />
      <DateNavigation
        title={dateNavigationData.name}
        currentDate={TimeService.parseDate(currentDate).toJSDate()}
        viewedDays={viewedDays - 1}
        setViewedDays={(days: number) => {
          onChangeViewedDays({ days, date: TimeService.endOfDay().toMillis() });
        }}
        setDate={date => {
          onChangeViewedDays({ date });
        }}
        withButtons
      />

      {loading ? (
        <SpView style={styles.spinner}>
          <LoadingSpinner />
        </SpView>
      ) : (
        <>
          <SpView style={styles.averageContainer}>
            <SpView style={styles.averageWrap}>
              <PetAverageStatsMovement data={convertedActivityDetails} />
            </SpView>
          </SpView>
          <SpView style={styles.flatlistContainer}>
            <FlatList
              style={styles.flatlist}
              data={convertedActivityDetails}
              renderItem={renderItem}
              ListHeaderComponent={ListHeader}
              keyExtractor={item => `${item.date.toString() + Math.random()}movement`}
            />
          </SpView>
        </>
      )}
    </SpView>
  );
};

const screenHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  flatlistContainer: {
    flex: 1,
    paddingTop: 56,
    paddingBottom: screenHeight < 810 ? 57 : 0,
    marginHorizontal: -24,
    paddingHorizontal: 24,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
  },
  flatlist: {
    flex: 1,
  },
  averageContainer: {
    marginTop: 8,
    position: 'relative',
    height: 48,
    zIndex: 9999,
  },
  averageWrap: {
    paddingHorizontal: 24,
    width: '100%',
    position: 'absolute',
    justifyContent: 'space-between',
  },
  spinner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
  },
});
