import { DeviceType } from '@constants/Device';
import { useMemo } from 'react';

import useBoundStore from '../../../store/store';
import { ReportType } from '../../Dashboard/constants/ReportType';

export const useNotificationReportTypes = (): ReportType[] => {
  const { allDeviceData } = useBoundStore(state => state.deviceStore);

  return useMemo(() => {
    const types: ReportType[] = [];

    if (
      allDeviceData.find(device =>
        [DeviceType.CatFlapConnect, DeviceType.PetDoorConnect].includes(device.product_id),
      )
    ) {
      types.push(ReportType.Movement);
    }

    if (
      allDeviceData.find(device =>
        [DeviceType.FeederConnect, DeviceType.Cerberus].includes(device.product_id),
      )
    ) {
      types.push(ReportType.Feeding);
    }

    if (
      allDeviceData.find(device =>
        [DeviceType.FelaquaConnect, DeviceType.Cerberus].includes(device.product_id),
      )
    ) {
      types.push(ReportType.Drinking);
    }

    return types;
  }, [allDeviceData]);
};
