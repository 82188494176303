import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { SpView } from 'src/components/SpView';
import SpTitle from 'src/components/SpTitle';
import i18 from '@utils/i18n';

import { userPermissionLevelDefinerReverse } from '@utils/permissionLevelDefiner';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DeviceEventEmitter, SafeAreaView, StyleSheet, View } from 'react-native';
import useBoundStore from 'src/store/store';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import SelectButton from 'src/components/SpSelectButton';
import { SpText } from '../../../components/SpText/SpText';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { useCustomToast } from '@hooks/useToast';
import { cloneDeep } from 'lodash-es';
import { HouseholdStackParamList } from '..';

type Props = NativeStackScreenProps<HouseholdStackParamList, 'HouseholdUserPermissionSelect'>;

const HouseholdUserPermissionSelect = ({ route }: Props) => {
  const { selectedPermissionLevelId, options, id, role } = route.params;
  const [activePermissionId, setActivePermissionId] = useState(selectedPermissionLevelId);

  const navigation = useNavigation();
  const toast = useCustomToast();
  const { t } = useTranslation();
  const chosenHousehold = route?.params?.household;
  const { updateUser, loadingHouseholdUser } = useBoundStore((state) => state.householdUserStore);
  const { updateInvite, loadingHouseholdInvite, updateProperty } = useBoundStore(
    state => state.householdInviteStore,
  );
  useEffect(() => {
    return () => {
      DeviceEventEmitter.removeAllListeners('changePermission');
    };
  }, []);

  const updatePermission = useCallback(async () => {
    if (selectedPermissionLevelId === activePermissionId) {
      navigation.goBack();
      return;
    }
    try {
      if (role === 'user') {
        const certainUser = chosenHousehold.users.find((elem) => elem.id === id);
        if (!certainUser) return;
        const rights = userPermissionLevelDefinerReverse(activePermissionId);
        const newUser = cloneDeep(certainUser);

        newUser.owner = rights.owner;
        newUser.write = rights.write;

        await updateUser(id, newUser, chosenHousehold.id);
        updateProperty('currentPermissionLevelId', activePermissionId);
      }
      if (role === 'invite') {
        await updateInvite(id, activePermissionId);
      }
      toast.show({
        description: t('successfully_updated_permission'),
      });
    } catch (err) {
      toast.show({
        description: t('error_updating_user_permission'),
        isError: true,
      });
    } finally {
      navigation.goBack();
    }
  }, [role, updateUser, updateInvite, activePermissionId]);

  const isLoading = useMemo(() => {
    if (role === 'user') {
      return loadingHouseholdUser;
    }
    if (role === 'invite') {
      return loadingHouseholdInvite;
    }
    return false;
  }, [loadingHouseholdInvite, loadingHouseholdUser]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.inner}>
        <SpView marginBottom={24}>
          <SpText
            fontFamily="Rubik_SemiBold"
            color={colors.greyText.color}
            size="xxl">
            {t('permission_level')}
          </SpText>
        </SpView>

        <View style={styles.checkboxWrapper}>
          {options.map(option => {
            const modifiedOption = { ...option, name: option?.title };
            return (
              <View key={modifiedOption.name}>
                <SelectButton
                  option={modifiedOption}
                  setActive={setActivePermissionId}
                  withIcon
                  isActive={activePermissionId === modifiedOption.id}
                  isLoading={isLoading}
                />
              </View>
            );
          })}
        </View>
        <View style={styles.buttonWrap}>
          <SpRoundedHeaderButton
            backgroundColor={colors.primary.color}
            h={56}
            title={t('save')}
            onPress={updatePermission}
            isLoading={isLoading}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  buttonWrap: {
    position: 'absolute',
    bottom: 34,
    right: 19,
    left: 19,
    shadowColor: colors.greyText.color,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8.95,
    elevation: 18,
  },
  inner: {
    flex: 1,
    paddingHorizontal: 24,
    paddingTop: 24,
  },
  checkboxWrapper: {
    // paddingHorizontal: 20,
  },
  button: {
    marginTop: 'auto',
    // marginBottom: 34
  },
});

export default HouseholdUserPermissionSelect;
