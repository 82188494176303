import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { SpView } from 'src/components/SpView';
import { testProperties } from '@utils/testProperties';
import colors from '@styles/colors';
import DropShadow from 'react-native-drop-shadow';
import DropdownSVG from 'src/components/SvgIcons/DropdownSVG';

interface HouseholdSelectorProps {
  householdName: string;
  action: () => void;
}

export const HouseholdSelector = ({ householdName, action }: HouseholdSelectorProps) => (
  <SpView style={styles.outer}>
    <DropShadow style={styles.cardShadow}>
      <Pressable
        accessible={false}
        style={styles.container}
        onPress={action}>
        <View style={styles.contentWrap}>
          <Text
            {...testProperties(householdName, 'text')}
            style={styles.label}>
            {householdName}
          </Text>
        </View>
        <View {...testProperties('faAngleRight', 'icon', householdName)}>
          <DropdownSVG color={colors.greyText.color} />
        </View>
      </Pressable>
    </DropShadow>
  </SpView>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 50,
    borderColor: colors.greyBorder.color,
    backgroundColor: colors.white.color,
    height: 70,
    borderWidth: 1,
    paddingLeft: 24,
    paddingRight: 26,
  },
  contentWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconSVG: { alignItems: 'center', justifyContent: 'space-between' },
  label: {
    fontSize: 18,
    fontFamily: 'Rubik_Medium',
    fontWeight: 'bold',
    lineHeight: 20,
    marginLeft: 16,
  },
  outer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  cardShadow: {
    width: '90%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
  },
});
