import { PetModel } from '@models/Pet';
import useBoundStore from 'src/store/store';
import { ReportType } from '../pages/Dashboard/constants/ReportType';

export const useGetAllPetReports = (pet: PetModel) => {
  const petStatistic = useBoundStore(state => state.petStore.getPetStatistics(pet?.id));
  const currentDevices: Record<ReportType, boolean> = {
    [ReportType.Movement]: !!petStatistic?.movement,
    [ReportType.Feeding]: !!petStatistic?.feeding,
    [ReportType.Drinking]: !!petStatistic?.drinking,
    [ReportType.Location]: false,
  };

  return Object.keys(currentDevices)
    .filter(item => currentDevices[item as unknown as ReportType])
    .map(Number);
};
