import { StyleSheet, View } from 'react-native';
import { AppImages } from '@constants/AppImages';
import SpTitle from 'src/components/SpTitle';
import { SpText } from '../../../../components/SpText/SpText';
import React from 'react';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import GraphLinesMock from '../GraphLinesMock/GraphLinesMock';
import mockLine from '../GraphLinesMock/mockLineData';
import TourSwipeSVG from 'src/components/SvgIcons/TourSwipeSVG';
import { ScrollView } from 'react-native-gesture-handler';

const TimeOutsideGraphsSlide = () => {
  const { t } = useTranslation();
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <GraphLinesMock
        imageStyle={styles.iconStyle}
        mockLine={mockLine}
        iconSource={AppImages.doorExit}
      />
      <SpTitle
        align="center"
        text={t('time_outside_graphs')}
      />
      <SpText style={styles.contentText}>{t('time_outside_graphs_tour_text1')}</SpText>
      <View style={styles.iconWrapper}>
        <TourSwipeSVG />
      </View>
      <SpText style={[styles.contentText, styles.blueText]}>
        {t('time_outside_graphs_tour_text2')}
      </SpText>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  graphWrapper: {
    marginTop: 32,
    width: 208,
    height: 112,
    borderBottomWidth: 2.5,
    borderBottomColor: '#2db58e',
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    columnGap: 5,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  contentText: {
    fontSize: 16,
    paddingHorizontal: 19,
    textAlign: 'center',
    paddingBottom: 24,
    color: colors.greyText.color,
  },
  graphLine: {
    backgroundColor: '#2db58e',
    width: 10,
    borderTopLeftRadius: 126,
    borderTopRightRadius: 126,
  },
  doorIcon: {
    width: 40,
    height: 40,
    position: 'absolute',
    bottom: -20,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  iconStyle: {
    transform: [{ rotateY: '180deg' }],
  },
  blueText: {
    color: '#03A2B1',
    fontSize: 14,
    lineHeight: 20,
    marginTop: 12,
    fontFamily: 'Rubik_SemiBold',
  },
});

export default TimeOutsideGraphsSlide;
