import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import CustomHeader from 'src/components/CustomHeader';
import { lastScreenOptions } from '@constants/Screen';
import { useTranslation } from 'react-i18next';
import { DeviceType } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';

import CDBHelpFailedPairing from './DogBowl/Modals/CDBHelpFailedPairing';
import CDBHelpFailedOnboardingPairing from './DogBowl/Modals/CDBHelpFailedPairing';

import CDBHelpInsertBatteries from './DogBowl/Modals/CDBHelpInsertBatteries';
import CDBHelpPairingMode from './DogBowl/Modals/CDBHelpPairingMode';
import CDBHelpWifiNetwork from './DogBowl/Modals/CDBHelpWifiNetwork';
import HavingIssuesFindingWiFi from './DogBowl/Modals/HavingIssuesFindingWiFi';
import QRWiFiPassword from './DogBowl/QRWiFiPassword';
import { SelectSSID } from './DogBowl/SelectSSID';
import AssignPet from './Generic/AssignPet';
import SelectProduct from './SelectProduct';
import CreatePetProfile from './StepForms/CreatePetProfile/CreatePetProfile';
import CreatePetProfileDetails from './StepForms/CreatePetProfile/CreatePetProfileDetails';
import AddCatFlap from './StepForms/Devices/AddCatFlap';
import AddFeeder from './StepForms/Devices/AddFeeder';
import AddFelaqua from './StepForms/Devices/AddFelaqua';
import { DeviceAssignPet } from '../Dashboard/Products/Device/DeviceSetting/components/DeviceAssignPet';
import AddPetDoor from './StepForms/Devices/AddPetDoor';
import AddDogBowl from './StepForms/Devices/RootDevices/AddDogBowl';
import AddHub from './StepForms/Devices/RootDevices/AddHub';
import IncorrectProduct from './StepForms/IncorrectProduct';
import PetBowlSetup from './StepForms/PetBowlSetup';
import { RootStackParamList } from '..';
import AddPets from '../Flows/Generic/AddPets';
import { navigationRef } from '../RootNavigation';
import FactoryResetCDB from './DogBowl/FactoryResetCDB';
import CDBHowToFactoryReset from './DogBowl/Modals/CDBHowToFactoryReset';

interface IncorrectProductProps {
  foundDeviceType: DeviceType;
  foundDevice: number;
}

export type FlowStackParamList = {
  Personal: undefined;
  Preferences: undefined;
  ChooseSetup: undefined;
  SelectProduct: undefined;
  AddHub: undefined;
  CDBHelpInsertBatteries: undefined;
  CDBHelpPairingMode: undefined;
  CDBHelpWifiNetwork: undefined;
  CDBHelpFailedPairing: undefined;
  AddPet: undefined;
  AddFeeder: {
    step?: number;
    deviceId?: number;
  };
  AddPetDoor: {
    step?: number;
    deviceId?: number;
  };
  AddCatFlap: {
    step?: number;
    deviceId?: number;
  };
  AddDogBowl: {
    step?: number;
    deviceId?: number;
  };
  AddFelaqua: {
    step?: number;
    deviceId?: number;
  };
  AssignPet: undefined;
  AddPets: {
    deviceId?: DeviceModel['id'];
    deviceType?: DeviceType;
    existingDevice?: boolean;
    comingFromSettings?: boolean;
    comingFromProfile?: boolean;
    comingFromIncompatibleAssignPage?: boolean;
    petIncompatibleAssociated?: number;
    step: number;
    loading: boolean;
    noProducts?: boolean;
    returnToStep?: number;
  };
  SelectSSID: {
    label: string;
    inputName: string;
    inputValue: any;
  };
  IncorrectProduct: IncorrectProductProps;
  CreatePetProfile: {
    id?: number;
    device_id?: number;
    existingDevice?: boolean;
    foundJustOnePet?: boolean;
    deviceType?: DeviceType;
    cdb: boolean;
    returnToStep?: number;
    nextPage?: {
      stack: string;
      screen: string;
    };
  };
  CreatePetProfileDetails: {
    label: string;
    inputName: string;
    inputValue: any;
    handleChange: React.Dispatch<any>;
    anotherTypeBreed?: number;
    anotherSpecie?: number;
  };
  PetBowlSetup: undefined;
  DeviceAssignPet: undefined;
  QRWiFiPassword: undefined;
  HavingIssuesFindingWiFi: undefined;
  FactoryResetCDB: undefined;
  CDBHowToFactoryReset: undefined;
};

const Stack = createStackNavigator<FlowStackParamList>();

export default function FlowNavigation() {
  const { t } = useTranslation();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();

  return (
    <Stack.Navigator initialRouteName="SelectProduct">
      <Stack.Screen
        name="AddPetDoor"
        options={{ headerShown: false }}
        component={AddPetDoor}
      />
      <Stack.Screen
        name="AddCatFlap"
        options={{
          headerShown: false,
          headerTitle: undefined,
        }}
        component={AddCatFlap}
      />
      <Stack.Screen
        name="AssignPet"
        options={{
          headerShown: false,
          headerTitle: undefined,
        }}
        component={AssignPet}
      />
      <Stack.Screen
        name="AddFeeder"
        options={{ headerShown: false }}
        component={AddFeeder}
      />
      <Stack.Screen
        name="AddFelaqua"
        options={{ headerShown: false }}
        component={AddFelaqua}
      />
      <Stack.Screen
        name="AddDogBowl"
        options={{ headerShown: false }}
        component={AddDogBowl}
      />
      <Stack.Screen
        name="AddPets"
        options={{ headerShown: false }}
        component={AddPets}
      />
      <Stack.Screen
        name="AddHub"
        options={{ headerShown: false }}
        component={AddHub}
      />
      <Stack.Screen
        name="SelectProduct"
        component={SelectProduct}
        options={{
          header: () =>
            CustomHeader({
              withCross: true,
              title: t('add_product'),
              goBackFunc: () =>
                rootNavigation.reset({
                  index: 0,
                  routes: [{ name: 'DashboardNavigation' }],
                }),
            }),
        }}
      />
      <Stack.Screen
        name="SelectSSID"
        component={SelectSSID}
        options={{ ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }) }}
      />
      <Stack.Screen
        name="QRWiFiPassword"
        component={QRWiFiPassword}
        options={{ ...lastScreenOptions() }}
      />
      <Stack.Screen
        name="CDBHelpInsertBatteries"
        component={CDBHelpInsertBatteries}
        options={{
          header: () =>
            CustomHeader({
              withTitle: false,
              withCross: true,
              goBackFunc: () => navigationRef.goBack(),
              styleHeader: {
                backgroundColor: colors.helpScreenColor.color,
                color: colors.white.color,
                colorIcon: colors.white.color,
              },
            }),
        }}
      />
      <Stack.Screen
        name="CDBHelpPairingMode"
        component={CDBHelpPairingMode}
        options={{
          header: () =>
            CustomHeader({
              withTitle: false,
              withCross: true,
              goBackFunc: () => navigationRef.goBack(),
              styleHeader: {
                backgroundColor: colors.helpScreenColor.color,
                color: colors.white.color,
                colorIcon: colors.white.color,
              },
            }),
        }}
      />
      <Stack.Screen
        name="CDBHelpWifiNetwork"
        component={CDBHelpWifiNetwork}
        options={{
          header: () =>
            CustomHeader({
              withTitle: false,
              withCross: true,
              goBackFunc: () => navigationRef.goBack(),
              styleHeader: {
                backgroundColor: colors.helpScreenColor.color,
                color: colors.white.color,
                colorIcon: colors.white.color,
              },
            }),
        }}
      />
      <Stack.Screen
        name="CDBHelpFailedOnboardingPairingMode"
        component={CDBHelpFailedOnboardingPairing}
        options={{
          header: () =>
            CustomHeader({
              withTitle: false,
              withCross: true,
              goBackFunc: () => navigationRef.goBack(),
              styleHeader: {
                backgroundColor: colors.helpScreenColor.color,
                color: colors.white.color,
                colorIcon: colors.white.color,
              },
            }),
        }}
      />
      <Stack.Screen
        name="HavingIssuesFindingWiFi"
        component={HavingIssuesFindingWiFi}
        options={{
          header: () =>
            CustomHeader({
              withTitle: false,
              withCross: true,
              goBackFunc: () => navigationRef.goBack(),
              styleHeader: {
                backgroundColor: colors.helpScreenColor.color,
                color: colors.white.color,
                colorIcon: colors.white.color,
              },
            }),
        }}
      />
      <Stack.Screen
        name="CDBHelpFailedPairing"
        component={CDBHelpFailedPairing}
        options={{
          header: () =>
            CustomHeader({
              withTitle: false,
              withCross: true,
              goBackFunc: () => navigationRef.goBack(),
              styleHeader: {
                backgroundColor: colors.helpScreenColor.color,
                color: colors.white.color,
                colorIcon: colors.white.color,
              },
            }),
        }}
      />
      <Stack.Screen
        name="IncorrectProduct"
        component={IncorrectProduct}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CreatePetProfile"
        options={{ headerShown: false }}
        component={CreatePetProfile}
      />
      <Stack.Screen
        name="CreatePetProfileDetails"
        options={{ ...lastScreenOptions() }}
        component={CreatePetProfileDetails}
      />
      <Stack.Screen
        name="DeviceAssignPet"
        component={DeviceAssignPet}
        options={{ ...lastScreenOptions() }}
      />
      <Stack.Screen
        name="PetBowlSetup"
        options={{ headerShown: false }}
        component={PetBowlSetup}
      />
      <Stack.Screen
        name="FactoryResetCDB"
        options={{ headerShown: false }}
        component={FactoryResetCDB}
      />
      <Stack.Screen
        name={'CDBHowToFactoryReset'}
        options={{ ...lastScreenOptions({ goBackFunc: () => navigationRef.goBack() }) }}
        component={CDBHowToFactoryReset}
      />
    </Stack.Navigator>
  );
}
