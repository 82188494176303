import { AppImages } from '@constants/AppImages';
import { DeviceType } from '@constants/Device';
import { useDevicesSearchService } from '@hooks/useDeviceService';
import { StepFormStepsObj } from '@models/FormikTypes';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { navigateToProducts } from '@utils/navigationShortcuts';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, Linking, View } from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import StepFormCreator from 'src/components/StepFormCreator';
import AddProduct from 'src/pages/Flows/Generic/AddProduct/AddProduct';
import useValidation from 'src/pages/validation';
import useBoundStore from 'src/store/store';

import { RootStackParamList } from '../../../index';
import { BeforeYouContinue } from '../../Generic/BeforeYouContinue';
import { CompleteSetupProduct } from '../../Generic/CompleteSetupProduct';
import NameForm from '../../Generic/NameForm';
import { FlowStackParamList } from '../../index';

type Props = NativeStackScreenProps<FlowStackParamList, 'AddPetDoor'>;

const AddPetDoor = ({ route }: Props) => {
  const { loadDevice, updateNameRequest, getDeviceById } = useBoundStore(s => s.deviceStore);
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [submitValues, setSubmitValues] = useState<any>();
  const [tryTimes, setTryTimes] = useState(0);
  const [step, setStep] = useState(route?.params?.step || 1);
  const [deviceIdentifier, setDeviceIdentifier] = useState(route?.params?.deviceId);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [name, setName] = useState<string>('');
  const { deviceSearchService, connected, otherDevices, maxRetriesError } = useDevicesSearchService(
    DeviceType.PetDoorConnect,
    setSelectedDevice,
  );

  useEffect(() => {
    (async () => {
      await loadDevice(true);
      if (!selectedDevice && deviceIdentifier) {
        setSelectedDevice(getDeviceById(deviceIdentifier));
      }
    })();
  }, [selectedDevice, deviceIdentifier]);

  const { commonNameSchema } = useValidation();

  const appStateRef = useRef(false);

  useEffect(() => {
    if (AppState.currentState !== 'active') {
      deviceSearchService.stop();
      appStateRef.current = true;
    }

    if (appStateRef.current && step === 2 && !maxRetriesError) {
      deviceSearchService.start();
      appStateRef.current = false;
    }
  }, [AppState.currentState]);

  useEffect(() => {
    return () => {
      deviceSearchService?.stop();
    };
  }, []);

  useEffect(() => {
    if (step === 2 && !maxRetriesError) {
      deviceSearchService.start();
    }
    return () => {
      return deviceSearchService ? deviceSearchService.stop() : null;
    };
  }, [step]);

  const tryAgain = () => {
    if (tryTimes > 1) {
      setStep(1);
      Linking.openURL(t('get_help_url'));
      return;
    }
    deviceSearchService.maxRetriesHandler(false);
    setTryTimes(old => ++old);
    setStep(2);
    deviceSearchService.start();
  };

  const foundDeviceStep = useMemo((): StepFormStepsObj => {
    return maxRetriesError
      ? {
          ui: (
            <AddProduct
              connected={connected}
              searchMsg={t('pet_door_looking_msg')}
              subtitle={t('pet_door_subtitle')}
              connectedMsg={maxRetriesError ? t('pet_door_not_found') : t('pet_door_connected_msg')}
              tip={t('pet_door_tip')}
              imageUrl={AppImages.pairPetDoor}
              maxRetriesError={maxRetriesError}
            />
          ),
          backBottomButton: true,
          hideButton: false,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          buttonText: tryTimes > 1 ? t('get_help') : t('sorry_try_again'),
          forcePressHandler: tryAgain,
        }
      : {
          ui: (
            <View style={{ marginLeft: -10, flex: 1 }}>
              <AddProduct
                connected={connected}
                searchMsg={t('pet_door_looking_msg')}
                subtitle={t('pet_door_subtitle')}
                connectedMsg={t('pet_door_connected_msg')}
                tip={t('pet_door_tip')}
                imageUrl={AppImages.pairPetDoor}
                maxRetriesError={maxRetriesError}
              />
            </View>
          ),
          hideButton: !connected,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
          },
          interceptor: () => {
            if (connected && otherDevices.length === 0) {
              return true;
            }
            deviceSearchService.start();
            return false;
          },
        };
  }, [maxRetriesError, connected, deviceSearchService, tryTimes]);

  const onSubmit = useCallback(
    async (setCreated: (arg: boolean) => void) => {
      try {
        await updateNameRequest(selectedDevice?.id, {
          name: submitValues.name,
        });
        await loadDevice(true);

        setCreated(true);
      } catch (err) {
        console.log(err);
      }
    },
    [submitValues, selectedDevice],
  );

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('add_product')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      leaveButtonAction={() => {
        deviceSearchService?.stop();
        navigation.navigate('DashboardNavigation', { screen: 'Pets' });
      }}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        1: {
          ui: <BeforeYouContinue productId={DeviceType.PetDoorConnect} />,
          effect: () => {
            // setSearchTimeOut(false);
          },
          backBottomButton: true,
          customHeaderProps: {
            rightButtonText: t('get_help'),
            withRightButton: true,
            withCross: true,
            withLeaveButton: false,
            withArrowBack: false,
          },
          interceptor: () => {
            deviceSearchService.maxRetriesHandler(false)
            return true;
          },
        },
        2: foundDeviceStep,
        3: {
          ui: NameForm,
          props: {
            setName,
            label: t('product_name'),
            title: t('name_petdoor_title'),
            subtitle: t('subtitle {{productName}} {{example}}', {
              example: t('name_catflap_example'),
              productName: t('pet_door'),
            }),
            subtitle2: t('subtitle2'),
            disableKeyboardAwoiding: true,
            hideSubtitleOnFocus: false,
          },
          formik: { schema: commonNameSchema('Petdoor name'), names: ['name'] },
          customHeaderProps: {
            withLeaveButton: true,
          },
          keyboardAwoidingProps: {
            androidAdjustType: 'custom',
          },
          buttonDisabled: name === '',
        },
        4: {
          ui: (
            <CompleteSetupProduct
              productId={DeviceType.PetDoorConnect}
              onLoadedCallback={onSubmit}
              deviceId={selectedDevice?.id}
              showFooter
              hideButton
              hideLink
            />
          ),
          invisibleHeader: true,
          textAboveButton: t('lets_add_pet_now'),
          interceptor: () => {
            navigation.navigate('AddPets', {
              deviceId: selectedDevice?.id,
              deviceType: DeviceType.PetDoorConnect,
              step: 3,
            });
            return false;
          },
          handleAndroidBackPress: () => {
            navigateToProducts();
            return true;
          },
        },
      }}
    />
  );
};

export default AddPetDoor;
