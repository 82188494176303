import { useMemo } from 'react';

import { DeviceModel } from '../models/Device';
import useBoundStore from '../store/store';

export const useDevicesByHousehold = (): DeviceModel[] => {
  const activeHousehold = useBoundStore(s => s.householdStore.activeHousehold);
  const deviceData = useBoundStore(s => s.deviceStore.deviceData);

  return useMemo(() => {
    // AnalyticsService.logEvent("useDevicesByHousehold - useMemo");
    if (!activeHousehold || !activeHousehold.id) return [];
    return deviceData.filter(item => item.household_id === activeHousehold?.id);
  }, [activeHousehold?.id, deviceData]);
};
