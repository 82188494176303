import { SpText } from '../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { PetDoorLockingMode } from '@constants/Device';
import { useTimeTransform } from '@hooks/useTimeTransform';
import { useUserTimeUnits } from '@hooks/useUserTimeUnits';
import { TimelineEventModel } from '@models/Timeline';
import colors from '@styles/colors';
import { get } from 'lodash-es';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useTimelineDeviceName } from '../../hooks/useTimelineDeviceName';
import { TimelineCard } from './TimelineCard';
import { TimelineUserPhoto } from './TimelineUserPhoto';
import { TimelinePetPhoto } from './TimelinePetPhoto';
import TimelineDevicePhoto from './TimelineDevicePhoto';

export const DoorLockingMode = ({
  event,
  active,
}: {
  event: TimelineEventModel;
  active: boolean;
}) => {
  const { t } = useTranslation();
  const deviceName = useTimelineDeviceName(event.devices);
  const userName = get(event, 'users[0].name', '');
  const userTimeFormat = useUserTimeUnits();
  const { timeToFormat } = useTimeTransform();

  const getUnlockTime = useCallback(
    (data: any) => {
      return timeToFormat(
        data.curfew && data.curfew.length === 1 ? data.curfew[0].unlock_time : data.unlock,
        userTimeFormat,
      );
    },
    [userTimeFormat],
  );

  const getLockTime = useCallback(
    (data: any) => {
      return timeToFormat(
        data.curfew && data.curfew.length === 1 ? data.curfew[0].lock_time : data.lock,
        userTimeFormat,
      );
    },
    [userTimeFormat],
  );

  const parsedData = useMemo(() => {
    return JSON.parse(event.data);
  }, [event.data]);

  const timeline_device_curfew = t('timeline_device_curfew', {
    user: userName,
    locktime: getLockTime(parsedData),
    unlocktime: getUnlockTime(parsedData),
    devicename: deviceName,
  });

  const timeline_device_unlocked = t('timeline_device_unlocked', {
    user: userName,
    devicename: deviceName,
  });

  const timeline_device_locked = t('timeline_device_ulocked', {
    user: userName,
    devicename: deviceName,
  });

  const devicePhotoCases = [
    timeline_device_curfew,
    timeline_device_unlocked,
    timeline_device_locked,
  ];

  const text = useMemo(() => {
    if (!(event?.devices || []).length) {
      return t('timeline_device_is_deleted');
    }

    if (parsedData.mode === PetDoorLockingMode.normal) {
      let translationKey = '';

      if (event.users) {
        translationKey =
          parsedData.last_mode === PetDoorLockingMode.curfew
            ? 'timeline_device_curfew_unlocked_by_user'
            : 'timeline_device_unlocked_by_user';
      } else {
        translationKey =
          parsedData.last_mode === PetDoorLockingMode.curfew
            ? 'timeline_device_curfew_unlocked_manually'
            : 'timeline_device_unlocked';
      }

      return t(translationKey, {
        user: userName,
        devicename: deviceName,
      });
    }

    if (parsedData.mode === PetDoorLockingMode.inside_only) {
      const translationKey = event.users
        ? 'timeline_device_locked_inside_by_user'
        : 'timeline_device_locked_inside';

      return t(translationKey, {
        user: userName,
        devicename: deviceName,
      });
    }

    if (parsedData.mode === PetDoorLockingMode.outside_only) {
      const translationKey = event.users
        ? 'timeline_device_locked_outside_by_user'
        : 'timeline_device_locked_outside';

      return t(translationKey, {
        user: userName,
        devicename: deviceName,
      });
    }

    if (parsedData.mode === PetDoorLockingMode.both) {
      return t(
        event.users ? 'timeline_device_locked_both_by_user' : 'timeline_device_locked_both',
        {
          user: userName,
          devicename: deviceName,
        },
      );
    }

    if (parsedData.mode === PetDoorLockingMode.curfew) {
      if (event.users) {
        if (!parsedData.curfew || parsedData.curfew.length === 1) {
          return t('timeline_device_curfew_by_user', {
            user: userName,
            locktime: getLockTime(parsedData),
            unlocktime: getUnlockTime(parsedData),
            devicename: deviceName,
          });
        }

        return (
          <SpView>
            <SpText color={colors.lightBlack.color}>
              {t('timeline_device_multicurfew_by_user', {
                user: userName,
                devicename: deviceName,
              })}
            </SpText>
            {parsedData.curfew.map((item, index) => {
              return (
                <SpView
                  key={index}
                  flexDirection="row"
                  alignItems="center">
                  <SpView style={styles.dot} />
                  <SpText color={colors.lightBlack.color}>
                    {timeToFormat(item.lock_time, userTimeFormat)}
                  </SpText>
                  <SpText color={colors.lightBlack.color}>-</SpText>
                  <SpText color={colors.lightBlack.color}>
                    {timeToFormat(item.unlock_time, userTimeFormat)}
                  </SpText>
                </SpView>
              );
            })}
          </SpView>
        );
      }

      return t('timeline_device_curfew', {
        user: userName,
        locktime: getLockTime(parsedData),
        unlocktime: getUnlockTime(parsedData),
        devicename: deviceName,
      });
    }

    return '';
  }, [parsedData, deviceName, t]);

  const image = devicePhotoCases.includes(String(text)) ? (
    <TimelineDevicePhoto
      active={active}
      event={event}
    />
  ) : (
    <TimelineUserPhoto
      active={active}
      event={event}
      showPhoto
    />
  );

  return (
    <TimelineCard
      active={active}
      image={image}
      text={text}
      date={event.created_at}
    />
  );
};

const styles = StyleSheet.create({
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    marginRight: 4,
    marginLeft: 2,
    backgroundColor: colors.lightBlack.color,
  },
});
