import { DeviceType, SubstanceType } from '@constants/Device';
import { DeviceFeederControlModel, DeviceModel, DevicePetDoorControlModel } from '@models/Device';
import React, { useMemo } from 'react';
import { SpDeviceOffline } from 'src/components/SpDeviceOffline';
import { getFillPercentage } from 'src/pages/Dashboard/Products/Device/utils/getFillingPercentage';

import { FeederStatus } from './FeederStatus';
import { FelaquaStatus } from './FelaquaStatus';
import { PetDoorStatus } from './PetDoorStatus';

interface DeviceIndicatorsProps {
  data: DeviceModel;
}

export const DeviceIndicators = ({ data }: DeviceIndicatorsProps) => {
  const online = data?.status.online;
  const cerberus = useMemo(() => {
    return data.product_id === DeviceType.Cerberus;
  }, [data]);

  if (!online) {
    return (
      <SpDeviceOffline
        deviceName={data.name}
        useImage
      />
    );
  }

  if (
    data.product_id === DeviceType.PetDoorConnect ||
    data.product_id === DeviceType.CatFlapConnect
  ) {
    return <PetDoorStatus data={data.control as DevicePetDoorControlModel} />;
  }

  if (
    data.product_id === DeviceType.FelaquaConnect ||
    (cerberus && data.control?.substance_type === SubstanceType.water)
  ) {
    const percentage = getFillPercentage(data as any, true);
    return <FelaquaStatus fillLevel={percentage} />;
  }

  if (
    data.product_id === DeviceType.FeederConnect ||
    (cerberus && data.control?.substance_type === SubstanceType.food)
  ) {
    const percentage = getFillPercentage(data as any, true);
    return (
      <FeederStatus
        fillPercentages={percentage}
        data={data.control as DeviceFeederControlModel}
      />
    );
  }

  return null;
};
