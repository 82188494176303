import * as React from 'react';
import i18n from '@utils/i18n';
import AccessSVG from 'src/components/SvgIcons/AccessSVG';
import DrinkingSVG from 'src/components/SvgIcons/DrinkingSVG';
import { SvgProps } from 'react-native-svg';
import FeedingSVG from 'src/components/SvgIcons/FeedingSVG';
import FeedingIconSVG from 'src/components/SvgIcons/FeedingIconSVG';

export enum ReportType {
  Movement = 1,
  Drinking,
  Feeding,
  Location, // TODO
}

export const ReportName = {
  [ReportType.Drinking]: 'drinking',
  [ReportType.Feeding]: 'feeding',
  [ReportType.Movement]: 'access',
  [ReportType.Location]: 'Location',
};

export const ReportTypeIcon: Record<ReportType, React.ReactNode> = {
  [ReportType.Drinking]: <DrinkingSVG viewBox="0 0 24 26" />,
  [ReportType.Feeding]: <FeedingSVG viewBox="0 0 24 26" />,
  [ReportType.Movement]: <AccessSVG viewBox="0 0 24 26" />,
  [ReportType.Location]: null,
};

export const ReportTypeIconWithProps: Record<ReportType, React.FC<SvgProps>> = {
  [ReportType.Drinking]: DrinkingSVG,
  [ReportType.Feeding]: FeedingIconSVG,
  [ReportType.Movement]: AccessSVG,
  [ReportType.Location]: null,
};
