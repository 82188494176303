import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpText } from '../../../../../../components/SpText/SpText';
import { SpView } from '../../../../../../components/SpView';
import text from '@styles/text';
import { StyleSheet } from 'react-native';

const NoProducts = () => {
  const { t } = useTranslation();
  return (
    <SpView>
      <SpText style={[styles.noProducts]}>{t('no_products_in_household')}</SpText>
      <SpText style={[styles.noProductsDescription]}>
        {t('no_products_in_household_description')}
      </SpText>
    </SpView>
  )
}

export default NoProducts;

const styles = StyleSheet.create({
  noProducts: {
    ...text.title,
    marginTop: 24,
  },
  noProductsDescription: {
    ...text.defaultText,
    textAlign: 'center',
    marginTop: 24,
  },
})
