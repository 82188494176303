import { useMemo } from 'react';

import { TimeService } from '../../../services/TimeService';
import { ReportRangeType } from '../constants/ReportRangeType';

export function useGroupedRangeReportStats(
  date: number,
  range: ReportRangeType,
): { start: number; finish: number } {
  return useMemo(() => {
    switch (range) {
      case ReportRangeType.OneDay:
        return {
          start: TimeService.parseDate(date).toMillis(),
          finish: TimeService.parseDate(date).toMillis(),
        };
      case ReportRangeType.SevenDays:
        return {
          start: TimeService.parseDate(date).minus({ days: 6 }).toMillis(),
          finish: TimeService.parseDate(date).toMillis(),
        };
      case ReportRangeType.TwentyEightDays:
        return {
          start: TimeService.parseDate(date).minus({ days: 27 }).toMillis(),
          finish: TimeService.parseDate(date).toMillis(),
        };
      case ReportRangeType.SixMonths:
        return {
          start: TimeService.parseDate(date).minus({ months: 5 }).startOf('month').toMillis(),
          finish: TimeService.parseDate(date).toMillis(),
        };
      case ReportRangeType.OneYear:
        return {
          start: TimeService.parseDate(date).minus({ months: 11 }).startOf('month').toMillis(),
          finish: TimeService.parseDate(date).toMillis(),
        };
      default:
        return {
          start: 0,
          finish: TimeService.parseDate(date).toMillis(),
        };
    }
  }, [date, range]);
}
