import { Pressable, StyleSheet, Text, View, TouchableOpacity, Platform } from 'react-native';
import React, { useCallback } from 'react';
import SpModal from 'src/components/SpModal';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { closeModal } from 'src/services/ModalBox';
import useBoundStore from 'src/store/store';
import colors from '@styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPaw } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { PetStackParamList } from '../../Pets/pages';
import { ReportType } from '../../constants/ReportType';

const PetOptionsModal = () => {
  const { activePet } = useBoundStore(state => state.petStore);
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<PetStackParamList>>();

  const onProfilePress = useCallback(() => {
    closeModal('PetOptions');
    navigation.navigate('PetProfile', { id: activePet.id });
  }, [navigation, activePet, closeModal]);

  const onReportsPress = useCallback(
    (type: ReportType) => {
      closeModal('PetOptions');
      navigation.navigate('PetDashboardReport', { type });
    },
    [navigation, activePet, closeModal],
  );

  return (
    <SpModal
      modalName="PetOptions"
      backdropColor="transparent">
      <Pressable
        accessible={false}
        style={styles.modalContent}>
        <View style={styles.optionsWrapper}>
          <TouchableOpacity
            onPress={onProfilePress}
            style={styles.contentWrapper}>
            <FontAwesomeIcon
              icon={faPaw}
              size={20}
              color={colors.white.color}
            />
            <Text style={styles.text}>{t('profile')}</Text>
          </TouchableOpacity>
        </View>
      </Pressable>
    </SpModal>
  );
};

export default PetOptionsModal;

const styles = StyleSheet.create({
  modalContent: {
    width: '90%',
    marginTop: Platform.OS === 'ios' ? 44 : 64,
    borderRadius: 10,
    gap: 20,
    backgroundColor: colors.greyText.color,
  },
  optionsWrapper: {
    alignItems: 'center',
    gap: 20,
  },
  text: {
    color: colors.white.color,
    fontSize: 16,
    fontFamily: 'Rubik',
  },
  contentWrapper: {
    width: '100%',
    paddingVertical: 25,
    justifyContent: 'center',
    gap: 12,
    flexDirection: 'row',
  },
});
