import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import colors from '@styles/colors';
import text from '@styles/text';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import SelectButtonsWrapper from 'src/components/SelectButtonsWrapper';
import { SpView } from 'src/components/SpView';

import { SpDivider } from '../../../components/SpDivider';
import { SpText } from '../../../components/SpText/SpText';

interface ThereSeemsToBeAProblemProps {
  bowlFlashingOrange: number;
  setIsFlashingOrange: (bowlFlashingOrange: number) => void;
}

export const ThereSeemsToBeAProblem = ({
  bowlFlashingOrange,
  setIsFlashingOrange,
}: ThereSeemsToBeAProblemProps) => {
  const { t } = useTranslation();
  enum dogBowlStillFlashingOptions {
    yes_it_is = 1,
    no_its_not = 0,
  }
  return (
    <SpView style={[styles.stepWrapper, styles.center]}>
      <SpView style={{ flexDirection: 'column' }}>
        <SpView style={[{ flex: 1 }, styles.center]}>
          <SpView
            {...testProperties('cdbPairingHousehold', 'image')}
            style={styles.circle}>
            <FontAwesomeIcon
              color="white"
              size={36}
              icon={faXmark}
            />
          </SpView>
          <SpText style={[text.title]}>{t('there_seems_to_have_been_a_problem')}</SpText>
          <SpText style={[text.body, styles.text]}>{t('cdb_is_flashing_orange')}</SpText>
          <SpDivider style={{ height: '10%' }} />
          <SelectButtonsWrapper
            optionsEnum={dogBowlStillFlashingOptions}
            handleChange={setIsFlashingOrange}
            selectedOption={dogBowlStillFlashingOptions[bowlFlashingOrange]}
          />
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  stepWrapper: {
    marginTop: '10%',
    marginBottom: 100,
  },
  center: {
    alignItems: 'center',
  },
  text: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  circle: {
    height: 56,
    width: 56,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.errorRed.color,
    borderRadius: 50,
    marginBottom: 16,
  },
});

export default ThereSeemsToBeAProblem;
