import { DeviceType } from '@constants/Device';
import { useEffect, useState } from 'react';
import { DeviceModel } from '@models/Device';
import { useDevicesByHousehold } from './useDevicesByHousehold';

export const useDevicesWithoutHubByHousehold = (): DeviceModel[] => {
  const [filteredData, setFilteredData] = useState<DeviceModel[]>([]);
  const devices = useDevicesByHousehold();

  useEffect(() => {
    setFilteredData(
      devices.filter(
        item => item.product_id !== DeviceType.Hub && item.product_id !== DeviceType.Cerberus,
      ),
    );
  }, [devices]);

  return filteredData;
};
