import { PetModel } from '@models/Pet';
import { useEffect, useState } from 'react';

import useBoundStore from '../../../store/store';

export const usePetActions = (pet: PetModel) => {
  const [petActions, setPetActions] = useState<any>(null);
  const { getPetStatistics, todaysPetStatisticsLoading } = useBoundStore((s) => s.petStore);

  useEffect(() => {
    //only do this when petStatisticsLoading is false/has finished loading, make sure we know the id we want
    if (!todaysPetStatisticsLoading && pet?.id !== 1) {
      const x = getPetStatistics(pet?.id);
      setPetActions(x);
    }
  }, [todaysPetStatisticsLoading, pet?.id]);

  return { petActions };
};
