import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import useBoundStore from 'src/store/store';
import { permissionLevelOptions, userPermissionLevelDefiner } from '@utils/permissionLevelDefiner';
import { NativeStackScreenProps } from 'react-native-screens/native-stack';
import SpTextButton from 'src/components/SpTextButton';
import { SpView } from 'src/components/SpView';
import colors from '@styles/colors';
import SpEditMenuItem from 'src/components/SpEditMenuItem';
import { SpDivider } from 'src/components/SpDivider';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import { SpProfileImage } from 'src/components/SpProfileImage';
import SpAlert from '@utils/alert';
import { SpLoading } from 'src/components/SpLoading';
import { useCustomToast } from '@hooks/useToast';
import { HouseholdStackParamList } from '..';

type Props = NativeStackScreenProps<HouseholdStackParamList, 'HouseholdManageUser'>;

const HouseholdManageUser = ({ route }: Props) => {
  const {
    user: { name, email, permissionLevelId, img, role, id, household },
  } = route.params;

  const { t } = useTranslation();
  const { show } = useCustomToast();
  const [activePermissionLevelId, setactivePermissionLevelId] = useState(permissionLevelId);

  const { activeHousehold } = useBoundStore(state => state.householdStore);

  useEffect(() => {
    const currentUser = [...activeHousehold.users, ...activeHousehold.invites].find(elem => elem.id === id);
    if(currentUser) {
      setactivePermissionLevelId(userPermissionLevelDefiner(currentUser.owner, currentUser.write));
    }
  }, [activeHousehold, id]);

  const { resendInvite, deleteInvite, loadingHouseholdInvite, updateProperty } = useBoundStore(
    state => state.householdInviteStore,
  );
  const { deleteUser, loadingHouseholdUser } = useBoundStore(state => state.householdUserStore);

  const navigation = useNavigation<NavigationProp<HouseholdStackParamList>>();
  const deleteHandler = useCallback(async () => {
    const isInvite = role === 'invite';
    const getInvite = (isError: boolean): string => {
      if (isError) {
        return isInvite ? 'error_delete_invite' : 'error_remove_user';
      }
      return isInvite ? 'success_delete_invite' : 'success_remove_user';
    };
    return SpAlert(
      t(isInvite ? 'delete_invite' : 'remove_user'),
      t(isInvite ? 'delete_invite_message' : 'delete_user_message'),
      [
        {
          text: isInvite ? t('delete') : t('remove'),
          onPress: async () => {
            const result = isInvite ? await deleteInvite(id) : await deleteUser(id);
            show({
              description: t(getInvite(!result)),
              isError: !result,
            });
            navigation.goBack();
          },
          style: 'destructive',
        },
        {
          text: t('cancel'),
          style: 'cancel',
        },
      ],
    );
  }, [role, deleteInvite, deleteUser]);

  const deleteButtonText = useMemo(() => {
    return (role === 'invite' && t('delete_invite')) || (role === 'user' && t('remove_user'));
  }, [role]);

  useEffect(() => {
    updateProperty('currentPermissionLevelId', permissionLevelId);
    return () => {
      updateProperty('currentPermissionLevelId', null);
    };
  }, []);

  if (loadingHouseholdInvite || loadingHouseholdUser || permissionLevelId === null) {
    return (
      <View style={styles.loadingOffset}>
        <SpLoading />
      </View>
    );
  }
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.inner}>
        <SpView style={styles.userInfoWrapper}>
          <SpProfileImage
            isUser
            width={92}
            height={92}
            imageUrl={img}
          />
          <SpView style={styles.textInfo}>
            {role === 'user' && (
              <Text
                {...testProperties(name, 'text')}
                numberOfLines={1}
                style={styles.name}
              >
                {name}
              </Text>
            )}
            <Text
              {...testProperties(email, 'text')}
              numberOfLines={1}
              style={styles.email}
            >
              {email || ''}
            </Text>
          </SpView>
        </SpView>
        <SpDivider />
        <SpEditMenuItem
          label={t('permission_level')}
          value={permissionLevelOptions[activePermissionLevelId - 1]?.title}
          action={() =>
            navigation.navigate('HouseholdUserPermissionSelect', {
              selectedPermissionLevelId: activePermissionLevelId,
              options: permissionLevelOptions,
              id,
              role,
              userName: name || email,
              household,
            })
          }
        />
        {role === 'invite' && (
          <SpView style={styles.resendButton}>
            <SpRoundedHeaderButton
              backgroundColor={colors.primary.color}
              h={40}
              title={t('resend_invite')}
              onPress={() => resendInvite(id)}
              borderRadius={10}
              paddingHorizontal={0}
              textStyles={styles.resendButtonText}
            />
          </SpView>
        )}
        <SpTextButton
          text={deleteButtonText}
          textStyle={styles.deleteButtonText}
          onPress={deleteHandler}
          containerStyle={styles.deleteButton}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  deleteButton: {
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 34,
  },
  userInfoWrapper: {
    alignItems: 'center',
    marginBottom: 47,
  },
  textInfo: {
    alignItems: 'center',
  },
  name: {
    marginTop: 8,
    fontSize: 20,
    color: colors.black.color,
    fontFamily: 'Rubik_Medium',
  },
  email: {
    marginTop: 8,
    fontSize: 16,
    color: colors.greyDisabled.color,
    fontFamily: 'Rubik',
    lineHeight: 24,
  },
  resendButton: {
    marginTop: 48,
    width: 143,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  resendButtonText: {
    fontSize: 16,
    fontFamily: 'Rubik_Medium',
    color: colors.white.color,
  },
  deleteButtonText: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Rubik_Medium',
    color: colors.greyText.color,
  },
  inner: { flex: 1, paddingHorizontal: 19 },
  loadingOffset: {
    marginTop: -200,
  },
});

export default HouseholdManageUser;
