import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
const InfoRedSVG = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    {...props}>
    <G>
      <Path
        d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0Z"
        fill="#ED0055"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 25.5999C14.8267 25.5999 13.8667 24.6399 13.8667 23.4666C13.8667 22.2933 14.8267 21.3333 16 21.3333C17.1734 21.3333 18.1334 22.2933 18.1334 23.4666C18.1334 24.6399 17.1734 25.5999 16 25.5999Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4426 9.0559L17.7279 17.6106C17.6533 18.5066 16.9066 19.1999 15.9999 19.1999C15.0933 19.1999 14.3466 18.5066 14.2719 17.6106L13.5573 9.0559C13.4399 7.62657 14.5599 6.3999 15.9999 6.3999C17.3653 6.3999 18.4533 7.50924 18.4533 8.85324C18.4533 8.91724 18.4533 8.9919 18.4426 9.0559Z"
        fill="white"
      />
    </G>
  </Svg>
);
export default InfoRedSVG;
