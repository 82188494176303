/* eslint-disable no-useless-catch */
import { StateCreator } from 'zustand';
import { lens } from '@dhmk/zustand-lens';
import { HouseholdInviteModel } from '@models/HouseholdInvite';
import { userPermissionLevelDefinerReverse } from '@utils/permissionLevelDefiner';
import HouseholdInviteApi from '../../api/HouseholdInviteApi';
import { HouseholdInviteSlice, MergedInterfaces } from '../models';

const createHouseholdInviteSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  HouseholdInviteSlice
> = (set, get) => {
  return {
    householdInviteStore: lens((subSet, subGet) => ({
      currentPermissionLevelId: null,
      loadingHouseholdInvite: false,
      errorResendingInvite: false,
      successResendingInvite: false,
      sendInvite: async (invite: HouseholdInviteModel) => {
        subSet({ loadingHouseholdInvite: true });
        try {
          const householdId = get().householdStore.activeHousehold.id;
          await HouseholdInviteApi.sendHouseholdInvite(householdId, invite);
          await get().householdStore.getHouseholds();
        } catch (err) {
          throw err;
        } finally {
          subSet({ loadingHouseholdInvite: false });
        }
      },
      resendInvite: async inviteId => {
        subSet({ loadingHouseholdInvite: true });
        try {
          const householdId = get().householdStore.activeHousehold.id;
          await HouseholdInviteApi.resendHouseholdInvite(householdId, inviteId);
          await get().householdStore.getHouseholds();
          subSet({ successResendingInvite: true });
        } catch (err) {
          subSet({ errorResendingInvite: true });
        } finally {
          subSet({ loadingHouseholdInvite: false });
        }
      },
      updateInvite: async (inviteId: number, activePermissionId: 1 | 2 | 3) => {
        const invite = userPermissionLevelDefinerReverse(activePermissionId);
        subSet({ loadingHouseholdInvite: true });
        try {
          const householdId = get().householdStore.activeHousehold.id;
          await HouseholdInviteApi.updateHouseholdInvite(householdId, inviteId, invite as any);
          await get().householdStore.getHouseholds();
          subSet({ currentPermissionLevelId: activePermissionId });
        } catch (error) {
          throw error;
        } finally {
          subSet({ loadingHouseholdInvite: false });
        }
      },
      deleteInvite: async inviteId => {
        subSet({ loadingHouseholdInvite: true });
        try {
          const householdId = get().householdStore.activeHousehold.id;
          await HouseholdInviteApi.deleteHouseholdInvite(householdId, inviteId);
          await get().householdStore.getHouseholds();
          subSet({ loadingHouseholdInvite: false });
          return true;
        } catch (err) {
          subSet({ loadingHouseholdInvite: false });
          return false;
        }
      },
      reset: () => {
        subSet({
          errorResendingInvite: false,
          successResendingInvite: false,
        });
      },
      updateProperty: <T>(property: keyof T, value: T[keyof T]) => {
        subSet({ [property]: value });
      },
    })),
  };
};

export default createHouseholdInviteSlice;
