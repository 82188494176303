import { DeviceFeederBowlType, FeederFoodType } from '@constants/Device';
import { DeviceFeederBowlModel, DeviceFeederBowlSettingModel } from '@models/Device';
import { enumToDescriptedArray } from '@utils/enumToDescriptedArray';
import i18n from '@utils/i18n';
import React from 'react';
import { EditMenuItem } from '@models/Menu';
import SpEditMenuItemValue from 'src/components/SpEditMenuItemValue';
import { BothBowls } from '../components/DeviceBowlSetup/BothBowls';
import { SingleBowl } from '../components/DeviceBowlSetup/SingleBowl';

const getFoodType = (foodType: FeederFoodType): string => {
  return foodType === FeederFoodType.wet ? i18n.t('wet') : i18n.t('dry');
};

export const getActions = (
  data: DeviceFeederBowlModel,
  changeFood: (
    $event: DeviceFeederBowlSettingModel,
    direction: 'left' | 'right' | 'double',
  ) => void,
  changeBowl: () => void,
): EditMenuItem[] => {
  if (!data?.type) {
    return [
      {
        label: i18n.t('bowl_setup'),
        value: <SpEditMenuItemValue value={i18n.t('not_selected')} />,
        action: () => changeBowl(),
      },
    ];
  }

  if (data?.type === DeviceFeederBowlType.Half) {
    return [
      {
        label: i18n.t('bowl_setup'),
        value: <BothBowls />,
        loadingKey: 'bowls.type',
        action: () => changeBowl(),
      },
      {
        label: i18n.t('left_bowl_food_type'),
        value: getFoodType(data?.settings[0]?.food_type),
        action: () => changeFood(data?.settings[0], 'left'),
      },
      {
        label: i18n.t('right_bowl_food_type'),
        value: getFoodType(data?.settings[1]?.food_type),
        action: () => changeFood(data?.settings[1], 'right'),
      },
    ];
  }

  return [
    {
      label: i18n.t('bowl_setup'),
      value: <SingleBowl />,
      action: () => changeBowl(),
    },
    {
      label: i18n.t('food_type'),
      value: getFoodType(data?.settings[0].food_type),
      action: () => changeFood(data?.settings[0], 'double'),
    },
  ];
};

export const BowlTypeOptions = enumToDescriptedArray(DeviceFeederBowlType);
