import { StyleSheet } from 'react-native';
import DropShadow from 'react-native-drop-shadow';
import { ConsumptionInsightByDay, ConsumptionInsightOutcome } from '@models/ConsumptionInsight';
import React, { useMemo, useState } from 'react';
import { SpView } from '../../../../../../../components/SpView';
import { SpText } from '../../../../../../../components/SpText/SpText';
import { SpButton } from '../../../../../../../components/SpButton';
import { ReportType } from '../../../../../constants/ReportType';
import InfoRedSVG from '../../../../../../../components/SvgIcons/InfoRedSVG';
import InfoYellowSVG from '../../../../../../../components/SvgIcons/InfoYellowSVG';
import CheckGreenSVG from '../../../../../../../components/SvgIcons/CheckGreenSVG';
import { useInsightAlertStatus } from '../../hooks/useInsighAlertStatus';
import { InsightAlertsBottomSheet } from '../InsightAlertsBottomSheet';
import { useTranslation } from 'react-i18next';
import colors from '@styles/colors';
import { useStatusVisible } from 'src/pages/Dashboard/hooks/useStatusVisible';

interface InsightsAlertStatusProps {
  data: Record<number, ConsumptionInsightByDay>;
  type?: ReportType;
  onSelectAlert: (date: string) => void;
}

const InsightsAlertStatus = ({
  data = {},
  type = ReportType.Drinking,
  onSelectAlert,
}: InsightsAlertStatusProps) => {
  const [alertsOpened, setAlertsOpened] = useState(false);
  const alertsWithHabits = useInsightAlertStatus(data);
  const isStatusVisible = useStatusVisible(data);
  const { t } = useTranslation();

  const title = useMemo(() => {
    return {
      [ReportType.Drinking]: t('ci_drinking_normal'),
      [ReportType.Movement]: 'TBD: not used atm 0',
      [ReportType.Feeding]: 'TBD: not used atm 1',
      [ReportType.Location]: 'TBD: not used atm 2',
    };
  }, [t]);

  const alertsLength = useMemo(() => {
    return alertsWithHabits.alerts.length + alertsWithHabits.habits.length;
  }, [alertsWithHabits]);

  const icon = useMemo(() => {
    if (alertsWithHabits.alerts.length) {
      return (
        <InfoRedSVG
          width={32}
          height={32}
          viewBox="0 0 32 32"
          color="#dedede"
        />
      );
    }

    if (alertsWithHabits.habits.length) {
      return (
        <InfoYellowSVG
          width={32}
          height={32}
          viewBox="0 0 32 32"
          color="#dedede"
        />
      );
    }

    return (
      <CheckGreenSVG
        width={32}
        height={32}
        viewBox="0 0 32 32"
        color="#dedede"
      />
    );
  }, [alertsWithHabits]);

  return (
    <SpView style={styles.wrapper}>
      {/*// for v1 we are not showing the 'Drinking is normal' status*/}
      {isStatusVisible && alertsLength > 0 && (
        <>
          <DropShadow style={styles.cardShadow}>
            <SpView style={styles.card}>
              <SpView
                flexDirection="row"
                alignItems="center"
              >
                <SpView style={styles.iconWrapper}>{icon}</SpView>
                <SpView>
                  {alertsLength ? (
                    <SpText
                      size="xl"
                      fontFamily="Rubik_Medium"
                    >
                      {alertsLength} {t('ci_alerts')}
                    </SpText>
                  ) : (
                    <SpView>
                      <SpText fontFamily="Rubik_Medium">{title[type]}</SpText>
                      <SpText
                        size="sm"
                        color="#676c79"
                      >
                        {t('ci_no_alerts_for_period')}
                      </SpText>
                    </SpView>
                  )}
                </SpView>
              </SpView>
              {!!alertsLength && (
                <SpButton
                  paddingHorizontal={20}
                  title={t('details')}
                  color={colors.darkturquoise.color}
                  onPress={() => setAlertsOpened(true)}
                />
              )}
            </SpView>
          </DropShadow>
          <InsightAlertsBottomSheet
            alerts={alertsWithHabits.alerts}
            habits={alertsWithHabits.habits}
            opened={alertsOpened}
            onSelect={onSelectAlert}
            onDismiss={() => setAlertsOpened(false)}
          />
        </>
      )}
    </SpView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    // marginTop: 0,
    marginBottom: 32,
    minHeight: 64,
  },
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
    width: 320,
    paddingHorizontal: 16,
    borderRadius: 10,
    backgroundColor: '#ffffff',
    minHeight: 64,
  },
  cardShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
  },
  iconWrapper: {
    marginRight: 16,
  },
});

export default InsightsAlertStatus;
