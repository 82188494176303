export const getNestedProperty = (obj, keys) => {
  let result;
  const splitKeys = keys.split('.');
  if (obj[splitKeys[0]]) {
    let value = obj;
    splitKeys.forEach(key => {
      value = value && value[key];
      if (value === undefined) {
        result = null;
      } else result = true;
    });
    return result;
  }
  return false;
};
